import React, { Component } from 'react';
import { CourseIcon, DownloadIcon } from 'shared/icons/icons';

export class DownloadContent extends Component{
  constructor(props){
    super(props);
  }

  render(){
      const { data, download, openPaywall } = this.props;
    return (
      <>
        <div className="assignment-list px-5 pt-3 pb-5" >
          <div className="align-items-center ">
            <CourseIcon mainClass="mr-2 assignment-icon transform-none" /> <span className="font-weight-bold font-24" >Course Work</span>
          </div>
          <div className="font-18 mt-1 mb-4 pb-1">Download the course work for personal use.</div>
          <div className="px-5 py-4 d-flex align-items-center assignment-list assignment-list-inner mb-4">
            <img src={require('assets/images/logos/main-logo.png')} className="mr-4" />

            {download ? <a download={`${data.heading}.pdf`} href={data.document_url ? data.document_url : "javascript:void(0)"}>
              <button className="btn btn-sm btn-primary btn-half-rounded font-weight-bold font-16 btn-with-download-icon" ><DownloadIcon mainClass="download-white-icon"  />Download PDF</button>
            </a> :
            <button onClick={openPaywall} className="btn btn-sm btn-primary btn-half-rounded font-weight-bold font-16 btn-with-download-icon" >Download PDF</button>
            }
          </div>
        </div>
        <div className="mt-5 p-1" />
      </>
    );
  }
}
