import React, { Component } from 'react';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import * as Utility from 'utils/utility';
import { SIGN_UP, VERIFY_OTP, CHECK_USER_EXISTANCE, RESEND_OTP, CREATE_PASSWORD } from 'config/constants/api_endpoints';
import { First } from './first_last';
import { PhoneNo } from './phone_no';
import { Otp } from './otp';
import { Email } from './email';
import { CreatePasswordPWA } from './password';
import { BACKSPACE } from 'config/constants';
import { CLIENT_ID } from 'config/constants/config';
import { PofileImage } from './profile_image';
import { parsePhoneNumber } from 'react-phone-number-input';

const steps = {
  'name': 1,
  'phone_no': 2,
  'otp': 3,
  'email': 4,
  'password': 5
};

const validateFields = ['first_name', 'last_name', 'password', 'country_code', 'confirm_password', 'phone_cellular', 'email'];

const validateOtp = ['otp1', 'otp2', 'otp3', 'otp4'];

class SignUpContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: steps['name'],
      formData: this.createFormData(),
      showLoader: false,
      countries: [],
      showPasswordValidation: false,
      password: null,
      isFormValid: false,
      showImageUploader: false,
    };
  }

  createFormData = () => {
    return{
      first_name: '',
      last_name: '',
      phone_cellular: '',
      password: '',
      country_code: ''
    };
  }

  handleOnKeyUp = (e, type) => {
    if (type !== "otp4" && e.keyCode !== BACKSPACE) {
     this.refs['otp'].refs[type].refs[type].nextSibling.nextSibling.focus();
   } else if (e.keyCode === BACKSPACE && type !== "otp1") {
     this.refs['otp'].refs[type].refs[type].previousSibling.previousSibling.focus();
   }
  }

  signUp = () => {
    this.setState({ showLoader: true });

    const params = this.getSignUpParams();

    return new Promise((resolve, reject) => {
      Utility.sendRequest(SIGN_UP, 2, params, (error, response, body) => {
        body = JSON.parse(body);

        this.setState({ showLoader: false });

        if (!body.error) {
          this.setState({ currentStep: this.state.currentStep + 1 });
          resolve(true);
        } else {
          toast.error(body.error.message, { containerId: 'public' });
          reject(false);
        }
      });
    });
  }

  getSignUpParams = () => {
    const formData = Utility.deepClone(this.state.formData);
    const countryCode = formData.country_code;
    const mobileNo = formData.phone_cellular.slice(formData.country_code.length, formData.phone_cellular.length);

    return{
      first_name: formData.first_name || '',
      last_name: formData.last_name || '',
      phone_cellular: mobileNo || '',
      password: formData.password || '',
      source:  'mobile',
      country_code: countryCode,
      client_id: CLIENT_ID,
    };
  }

  verifyOtp = (formData, otp) => {
    const params = {
      country_code: formData.country_code,
      phone_cellular: formData.phone_cellular.slice(formData.country_code.length, formData.phone_cellular.length),
      client_id: CLIENT_ID,
      code: otp,
    };

    this.setState({ showLoader: true });

    return new Promise((resolve, reject) => {
      Utility.sendRequest(VERIFY_OTP, 2, params, (error, response, body) => {
        body = JSON.parse(body);

        this.setState({ showLoader: false });

        if (!body.error) {
          this.setState({ currentStep: this.state.currentStep + 1 });
          resolve(true);
        } else {
          toast.error(body.error.message, { containerId: 'public' });
          reject(false);
        }
      });
    });
  }

  nextPage = async (e, check) => {
    e.preventDefault();
    let next = true;

    if (check === 'first_page') {
      next = false;

      if (!Utility.showFormErrors(this.refs['signUp'], validateFields))  return;

      next = true;
    }

    if (check === 'phone_cellular') {
      next = false;

      const countryCodeField = document.getElementsByClassName('PhoneInputCountrySelect');
      const phoneNoField = Utility.getElementById('phone_cellular');
      const selectCountryCode = Utility.getElementById('country_code_error');
      const showError = Utility.getElementById('phone_cellular_error');

      if (countryCodeField[0].value === 'ZZ') {
        if (selectCountryCode) selectCountryCode.textContent = 'Select country code';
        return;
      } else if (Utility.isEmpty(phoneNoField.value)) {
        if (showError) showError.textContent = 'Enter your mobile number';
        return;
      } else {
        selectCountryCode.textContent = '';
      }

      this.signUp().then((success) => {
        next = success; }).catch((error) => {next = error; });
    }

    if (check === 'otp') {
      next = false;
      const validateField = await this.cunstomValidation();

      if (!(validateField.isFormValid)) {
        const errorMessage = 'All fields are required';
        this.displayError(check, errorMessage);
        return;
      }

      this.verifyOtp(this.state.formData, validateField.otp).then((success) => {
        next = success; }).catch((error) => { next = error; });
    }

    if (check === 'email') {
      next = false;

      if (!Utility.showFormErrors(this.refs['signUp'], validateFields, 'pwa_sign_up'))  return;

      await this.checkUserExistance(this.state.formData).then((success) => {
        next = success; }).catch((error) => { next = error; });
    }

    if (next) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  }

  checkUserExistance = (formData) => {
    this.setState({ showLoader: true });

    const params = {
      client_id: CLIENT_ID,
      email: formData.email
    };

    return new Promise((resolve, reject) => {
      Utility.sendRequest(CHECK_USER_EXISTANCE, 2, params, (error, response, body) => {
        body = JSON.parse(body);

        this.setState({ showLoader: false });

        if (!body.error && Utility.isEmpty(body)) {
          resolve(true);
        } else {
          toast.error(body.error.message, { containerId: 'public' });
          reject(false);
        }
      });
    });
  }

  handleChange = (e, type) => {
    let formData = { ...this.state.formData };
    let validPassword = {}, showPasswordValidation = false;

    formData = Utility.validateAndReturnFormData(this.refs['signUp'], formData, e, validateFields, 'pwa_sign_up');

    if (validateOtp.includes(type)) {
      this.displayError('otp', '');
    }

    if (!Utility.isEmpty(formData.password)) {
      validPassword = Utility.validatePassword(formData.password);
      showPasswordValidation = true;
    }

    if (type === 'country_code') {
      formData.country_code = e.target.options[parseInt(formData.country_code)].textContent;
    }

    if (type === 'password' || type === 'confirm_password') this.validatePassword(type);

    this.setState({ formData, validPassword, showPasswordValidation });
  }

  cunstomValidation = () => {
    const inputs = document.querySelectorAll('input');
    let isFormValid = true;
    let otp = '';

    inputs.forEach((input) => {
      if (Utility.isEmpty(input.value)) isFormValid = false;
      otp = otp.concat(input.value);
    });

    return { isFormValid, otp };
  }

  displayError = (id, errorMessage) => {
    const error = Utility.getElementById(`${id}Error`);
    const data = this.state.isFormValid ? '' : errorMessage;
    error.textContent = data;
  }

  validatePassword = (type) => {
    const password = Utility.getElementById('password');
    const confirmPassword = Utility.getElementById('confirm_password');
    const error = Utility.getElementById(`${'confirm_password'}Error`);

    if (!Utility.isEmpty(confirmPassword.value)) {
      if (password.value === confirmPassword.value) {
        error.textContent = null;
        return;
      }
    } else {
      error.textContent = null;
      return;
    }

    if (type === 'confirm_password' || (!Utility.isEmpty(confirmPassword.value) && type === 'password')) {
      error.textContent = `Confirm password doesn't match`;
    }
  }

  submit = (e) => {
    e.preventDefault();
    const error = Utility.getElementById('confirm_passwordError');

    if (!Utility.showFormErrors(this.refs['signUp'], validateFields, 'pwa_sign_up'))  return;

    if (this.state.formData.password !== this.state.formData.confirm_password) {
      error.textContent = `Confirm password doesn't match`;
      return;
    }

    if (!Utility.isEmpty(this.state.validPassword)) {
      const validatePassword = Object.values(this.state.validPassword).map((value) => {
        return value;
      });
      if (validatePassword.includes(false)) return;
    }

    this.updateUserData();
  }

  createUpdateParams = () => {
    const formData = Utility.deepClone(this.state.formData);
    const countryCode = formData.country_code;
    const mobileNo = formData.phone_cellular.slice(formData.country_code.length, formData.phone_cellular.length);

    return{
      first_name: formData.first_name || '',
      last_name: formData.last_name || '',
      phone_cellular: mobileNo || '',
      email: formData.email || '',
      password: formData.password || '',
      source: 'mobile',
      country_code: countryCode,
      client_id: CLIENT_ID
    };
  }

  updateUserData = () => {
    const params = this.createUpdateParams();

    this.setState({ showLoader: true });

    Utility.sendRequest(CREATE_PASSWORD, 2, params, (err, response, body) => {
      body = JSON.parse(body);

      this.setState({ showLoader: false });

      if (!body.error) {
        this.setState({ currentStep: this.state.currentStep + 1 });

        cookie.save('sessionToken', body.token, { path: '/' });
      } else {
        toast.error(JSON.parse(body).error.message, { containerId: 'private' });
      }
    });
  }

  goBack = (name) => {
    const step = name === 'email' ? 2 : 1;

    this.setState({ currentStep: this.state.currentStep - step }, () => {
      if (this.state.currentStep === 0) this.props.history.push('/');
    });
  }

  resendVerificationCode = () => {
    const formData = Utility.deepClone(this.state.formData);
    formData.phone_cellular = formData.phone_cellular.slice(formData.country_code.length, formData.phone_cellular.length);
    formData.client_id = CLIENT_ID;

    this.setState({ showLoader: true });

    Utility.sendRequest(RESEND_OTP, 3, formData, (error, response, body) => {
      body = JSON.parse(body);

      this.setState({ showLoader: false });

      if (!body.error) {
        toast.success(body.message, { containerId: 'public' });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  skip = () => {
    this.props.history.push('/home');
  }

  setValue = (value) => {
    const element = Utility.getElementById('country_code_error');
    if (element) element.textContent = '';

    const formData = { ...this.state.formData };
    formData.phone_cellular = value;

    if (!Utility.isEmpty(value)) {
      const data = parsePhoneNumber(value);

      if (data) {
        const error = Utility.getElementById('phone_cellular_error');
        if (error) error.textContent = '';

        formData.country_code = `+${data.countryCallingCode}`;

        this.setState({ formData });
      }
    }
  }

  render() {
    return(
      <>
      {
        this.state.currentStep === 1 &&
        <First
          {...this.state.formData}
          ref="signUp"
          nextPage={this.nextPage}
          handleChange={this.handleChange}
          goBack={this.goBack}
          skip={this.skip}
        />
      }
      {
        this.state.currentStep === 2 &&
        <PhoneNo
          {...this.state.formData}
          ref="signUp"
          nextPage={this.nextPage}
          goBack={this.goBack}
          showLoader={this.state.showLoader}
          countries={this.state.countries}
          skip={this.skip}
          setValue={this.setValue}
        />
      }
      {
        this.state.currentStep === 3 &&
        <Otp
          ref="otp"
          nextPage={this.nextPage}
          handleChange={this.handleChange}
          goBack={this.goBack}
          formData={this.state.formData}
          resendVerificationCode={this.resendVerificationCode}
          showLoader={this.state.showLoader}
          onKeyUp = {this.handleOnKeyUp}
          show={true}
          showSkipButton={true}
        />
      }
      {
        this.state.currentStep === 4 &&
        <Email
          {...this.state.formData}
          ref="signUp"
          nextPage={this.nextPage}
          handleChange={this.handleChange}
          goBack={this.goBack}
          showLoader={this.state.showLoader}
          skip={this.skip}
        />
      }
      {
        this.state.currentStep === 5 &&
        <CreatePasswordPWA
          {...this.state.formData}
          ref="signUp"
          submit={this.submit.bind(this)}
          handleChange={this.handleChange}
          goBack={this.goBack}
          showLoader={this.state.showLoader}
          passwordValidation={this.state.validPassword}
          showPasswordValidation={this.state.showPasswordValidation}
        />
      }
      {
        this.state.currentStep === 6 &&
        <PofileImage
          {...this.state.formData}
          goBack={this.goBack}
          skip={this.skip}
        />
      }
      </>
    );
  }
}

export default SignUpContainer;
