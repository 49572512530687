import React, { Component } from "react";
import { toast } from "react-toastify";
import { Utility } from "utils";
import { CAMPAIGNS_TOP_STORIES } from "config/constants/api_endpoints";
import { CLIENT_ID } from "config/constants/config";
import { SuggestedReadsCard } from "shared/components/suggested_reads";
import CourseContainerCard from "./CourseContainer";

export default class SuggestedCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignCourses: [],
    };
  }

  componentDidMount = () => {
    this.getCampaignCourses();
  };

  componentDidUpdate = (nextProps) => {
    if (this.props.refresh !== nextProps.refresh) this.getCampaignCourses();

    if (this.props.userData !== nextProps.userData) this.getCampaignCourses();
  };

  getCampaignCourses = (seeAll = false) => {
    const params = {
      publisher_network_id: CLIENT_ID,
      limit: this.props.limit,
      is_course: true,
      show_featured : true
    };

    if (seeAll) {
      delete params.range;
    }

    if (!Utility.isEmpty(this.props.userData)) {
      Utility.sendRequest(
        CAMPAIGNS_TOP_STORIES,
        1,
        params,
        (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            if (body.length) this.setState({ campaignCourses: body });
          }
        }
      );
    }
  };

  render() {
    return this.state.campaignCourses.length ? (
      <div className="article-section slider-mob px-3 px-lg-0">
        {this.props.showHeading && (
          <h1 className="font-24-bold mb-25 home-page-heading">
            {this.props.showHeading && this.props.heading}
            {this.props.seeAll && this.props.fromMedia && (
              <span
                className="see-all-text display-7"
                onClick={() => this.props.setSeeAll("courses")}
              >
                See All
              </span>
            )}
          </h1>
        )}
        <div className="d-flex article-section-outer flex-lg-wrap slider-mob-inner mb-42">
          {/* {console.log({ campaignCourses: this.state.campaignCourses })}
          <SuggestedReadsCard
            loadedFrom={this.props.loadedFrom}
            handleCampaignIdChange={this.props.handleCampaignIdChange}
            stories={this.state.campaignCourses}
          /> */}
            <CourseContainerCard
              isHome = {true}
              courseData={this.state.campaignCourses && this.state.campaignCourses.filter(data => {return data.ranking === 5})}
              PanalTitle={""}
              colorCode={""}
              type={""}
              categoriesList={''}
            />
        </div>
      </div>
    ) : (
      <></>
    );
  }
}
