import React from 'react';
import AppLayout from 'shared/components/layout';
import AppRoute from './app_route';

class CustomAppRoute extends React.Component{
    render(){
        const createHistory = this.props.history;
        return(
            <>
            </>
        );
    }
}


export default CustomAppRoute;