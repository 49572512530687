import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { Utility } from 'utils';
import { MdClose } from "react-icons/md";
import { ChevronLeftIcon } from 'shared/icons/icons';

const ScreenView = (props) => {
  return (
    <Col xs={12} xl={9} lg={8} className="h-100 top-on-sm ModalOpen p-4 d-none d-lg-block" id={props.id} >
      <div className="campaings-layout overflow-auto h-100 p-sm-3 p-0 shar-form scroll-y">
        <div className="float-right">
          <div className="pr-sm-4">
            <div className="close-button" onClick={props.closeSummary}>
              <Button variant="link" className="btn-close"><MdClose/></Button>
            </div>
          </div>
        </div>
        <Col xs={12} className="p-0">
          <div className="align-items-center mt-1">
            <div className="round-circle mr-2"></div>
            <h3 className="font-30-bold mb-0" >
            {!Utility.isEmpty(props.label) ? props.label : '' }</h3>
          </div>
          {props.children}
        </Col>
      </div>
    </Col>
  );
};

export const ScreenViewShop = (props) => {
  return (
    <Col xs={12} xl={9} lg={8} className="h-100 top-on-sm px-4 py-5 d-none d-lg-block scroll-y overflow-auto" id={props.id} >
      <div className="px-4 shar-form shop-container">
        <Col xs={12} className="p-0 shops-tab">
          <div className="d-flex flex-wrap align-items-lg-center align-items-baseline w-100 mb-4" onClick={props.closeSummary}> 
              <ChevronLeftIcon
                mainClass="mr-3 ml-lg-n4 cursor-pointer"
              />
              <span className="font-20 b-600">{!Utility.isEmpty(props.name)?props.name:"Create and manage your Products"}</span>
              <div className="font-16 w-100 mt-1 hide-on-empty">{!Utility.isEmpty(props.name)?"Create and manage your Products":""}</div>
          </div>
          {props.children}
        </Col>
      </div>
    </Col>
  );
};

export default ScreenView;
