import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class GCSocialSharing extends Component {
  shareGcfgToFacebook = (url, redirectUrl) => {
    // https://www.facebook.com/dialog/feed?app_id=<%= APP_ID %>','<%= FB_SHARING_URL%>
    url = 'https://www.facebook.com/dialog/feed?app_id=' + process.env.REACT_APP_GC_FB_APP_ID;
    redirectUrl = process.env.REACT_APP_GC_FB_APP_REDIRECT_URL;
    const text = this.props.urlLocation;
    const brandName = encodeURIComponent(this.props.brandName);
    let charityName = this.props.clientname;
    charityName = encodeURIComponent(charityName);
    const say = 'Gift Cards! for ' + charityName;
    const url1 = this.props.urlLocation;
    const imgUrl = 'https://sharemeister.com/assets/gcfg/fb_sharing_logo.png';
    const desc = 'I just used my ' + brandName + ' Gift Card to support ' + charityName + '. Super simple! %23giftcards!.';
    const arg = '&link=' + url1 + '&name=' + say + '&picture=' + imgUrl + '&caption=' + text + '&description=' + desc + '&redirect_uri=' + redirectUrl + '/public_pages/close_one_for_all_sharing';
    url = url + arg;
    const win = window.open(url, '_blank');

    if(win) win.focus();
  }

  shareGcfgToTwitter = (url) => {
    const text = 'Gift Cards!';
    const say = this.props.brandName;
    let url1 = this.props.urlLocation;

    if (!(url1.indexOf('http://') === 0) || !(url1.indexOf('https://') === 0)) {
      url1 = 'https://' + url1;
    }

    const arg = '?url=' + url1 + '&text=' + text + '&via=' + say;
    url = url + arg;
    const win = window.open(url, '_blank');

    if(win) win.focus();
  }

  shareGcfgToLinkedin = (url) => {
    const title = '@GiftCardsForGood';
    const description = this.props.brandName;
    let url1 = this.props.urlLocation;

    if (!(url1.indexOf('http://') === 0) || !(url1.indexOf('https://') === 0)) {
      url1 = 'https://' + url1;
    }

    const arg = url1 + '&title=' + title + '&summary=' + description;
    url = url + arg;
    const win = window.open(url, '_blank');

    if(win) win.focus();
  }

  render(){
    return(
      <div className="social-media">
        <Link to={{}} onClick={(e) => this.shareGcfgToFacebook(e)} className="giftcard-social-icon" ><img src={require('assets/images/gift_card/fb-icon.png')} alt="facebook"/></Link>
        <Link to={{}} onClick={() => this.shareGcfgToTwitter('https://twitter.com/share')} className="giftcard-social-icon" ><img src={require('assets/images/gift_card/tw-icon.png')} alt="twitter"/></Link>
        <Link to={{}} onClick={() => this.shareGcfgToLinkedin('https://www.linkedin.com/shareArticle?mini=true&url=')} className="giftcard-social-icon" ><img src={require('assets/images/gift_card/in-icon.png')} alt="linked-in"/></Link>
      </div>
    );
  }
}
