import React from "react";
import { toast } from "react-toastify";
import { Utility } from "utils";
import { TickFilledIcon } from "shared/icons/icons";
import { MESSAGES, UPDATE_USER_CLIENT } from "config/constants/api_endpoints";

const ToolboxCard = (props) => {
  const additionalDetails = props.userData.additional_details
    ? JSON.parse(props.userData.additional_details)
    : {
        toolbox: [],
      };
  const sendMessage = (message, friend) => {
    if (!message || message.trim() === "") return;

    const senderPrivateKey = props.userData.private_key;
    const receiverPrivateKey = friend.private_key;

    const encryptedMessage = Utility.encryptedMessage(
      message,
      senderPrivateKey,
      receiverPrivateKey
    );

    const params = {
      from_user_client_id: props.userData.id,
      to_user_client_id: friend.id,
      new_message: props.chatPrivateKey === "",
      media: "",
      message: encryptedMessage,
      user_id: props.userData.user_id,
      source: friend.isAudience ? "audience" : "network",
      chat_private_key: senderPrivateKey,
    };

    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        body.message = message;
        props.updateIsChatFormOpen(true);
      }
    });
  };

  const updateUserDataForToolbox = (id) => {
    const objIndex = getToolboxData().findIndex((obj) => obj.id == id);
    getToolboxData()[objIndex].checked = !getToolboxData()[objIndex].checked;

    const updatedUserData = Utility.deepClone(props.userData);
    updatedUserData.additional_details = JSON.stringify(additionalDetails);

    const params = {
      data: { additional_details: updatedUserData.additional_details },
      whereClause: { id: props.userData.id },
    };

    Utility.sendRequest(
      UPDATE_USER_CLIENT,
      3,
      params,
      (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          props.updateUserData(updatedUserData);
        }
      }
    );

    return updatedUserData;
  };

  const handleOptionClick = (id, message) => {
    props.updateIsChatFormOpen(false);
    if (!Utility.isEmpty(props.supportUsers)) {
      props.updateChatFriend(props.supportUsers[0]);
      sendMessage(message, props.supportUsers[0]);
      updateUserDataForToolbox(id);
    }
  };

  const handleCheckBoxClick = (id) => {
    updateUserDataForToolbox(id);
  };

  function stripHtml(html) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const getToolboxData = () => {
    return additionalDetails ? additionalDetails.toolbox : [];
  };

  return (
    <>
      {getToolboxData().map((e, index) => {
        return (
          <div
            key={index}
            className="col-xl-4 col-md-6 d-flex flex-column align-items-center side-menu-shadow p-4 toolbox-card mt-4 mb-4"
          >
            <div className="d-flex justify-content-between w-100">
              <span className="text-primary font-24">{e.id}</span>
              <label className="cursor-pointer">
                <input
                  type="checkbox"
                  onClick={() => handleCheckBoxClick(e.id)}
                  className="toolbox-card-checkbox"
                  checked={e.checked}
                />
                <div className="toolbox-card-checkbox-outer">
                  <TickFilledIcon fillClass="fill-primary" />
                </div>
              </label>
            </div>
            <img src={e.imgPath} className="toolbox-card-img" alt="" />
            <div
              className="mt-4 mb-5 font-18 text-center"
              dangerouslySetInnerHTML={{ __html: e.innerText }}
            />
            <button
              className={`btn btn-theme btn-alternate ${
                !e.checked && "btn-alternate-outlined"
              } mb-3 mt-auto`}
              onClick={() => handleOptionClick(e.id, stripHtml(e.innerText))}
            >
              Select
            </button>
          </div>
        );
      })}
    </>
  );
};

export default ToolboxCard;
