import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Utility } from "utils";
import { toast, ToastContainer } from "react-toastify";
import { FORGOT_PASSWORD, ACTIVATION_CODE_VERIFICATION, CREATE_PASSWORD, USER_AUTH } from "config/constants/api_endpoints";
import { FORGOTPASSWORD, BACKSPACE } from 'config/constants';
import { CLIENT_ID } from 'config/constants/config';
import { updateUserData } from 'shared/actions';
import ForgotEmail from './forgot_email';
import Otp from './otp';
import UpdatePassword from './update_password';
import cookie from 'react-cookies';
import CloseToast from 'shared/components/close_toast';

const validateFieldsEmail = ["email"];
const validateFieldsUpadtePassword = ['password'];

class ForgotPassword extends Component {
  constructor(props){
    super(props);
      this.state = {
        currentStep: FORGOTPASSWORD.send_email,
        emailData: {
          email: this.props.location.state ? this.props.location.state.email : "",
        },
        passwordData: {
         password: '',
        },
        otpData: {
          otp1: '',
          otp2: '',
          otp3: '',
          otp4: '',
        },
        showPasswordValidation: false,
        confirm_password:'',
      };
   }

   handleOnKeyUp = (e, type) => {
     if (type !== "otp4" && e.keyCode !== BACKSPACE) {
      this.refs['otp'].refs[type].refs[type].nextSibling.nextSibling.focus();
    } else if (e.keyCode === BACKSPACE && type !== "otp1") {
      this.refs['otp'].refs[type].refs[type].previousSibling.previousSibling.focus();
    }
   }

   handleEmailChange = (e) => {
    let emailData = this.state.emailData;
    emailData = Utility.validateAndReturnFormData(this.refs['email'], emailData, e, validateFieldsEmail, 'pwa_sign_up');

    this.setState({ emailData });
   }

   goSignIn = () => {
     this.props.history.push('/users/sign_in');
   }

   goSignUp = () => {
     this.props.history.push('/users/sign_up');
   }

   goBack = () => {
     this.setState({ currentStep:1 });
   }

   forgotPasswordEmail = (e) => {
    e.preventDefault();
    const { emailData } = this.state;
      emailData.client_id = CLIENT_ID.toString();
    if (!Utility.showFormErrors(this.refs['email'], validateFieldsEmail, 'pwa_sign_up')) {
      return;
    }

    Utility.sendRequest(FORGOT_PASSWORD, 2, emailData, (error, response, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'public' });
      } else {
        toast.success(body.message, { containerId: 'public' });
        this.setState({ currentStep:2 });
      }
    });
   }

   resendVerificationCode = (e) => {
     e.preventDefault();
     const resendData = {};
     resendData.email = this.state.emailData.email;
     resendData.client_id = CLIENT_ID.toString();
     Utility.sendRequest(FORGOT_PASSWORD, 2, resendData, (error, response, body) => {
       body = JSON.parse(body);
       if (body.error) {
         toast.error(body.error.message, { containerId: 'public' });
       } else {
         toast.success(body.message, { containerId: 'public' });
       }
     });
   }


   handleOtpChange = (e) => {
    const { otpData } = this.state;
    const allData = {
      ...otpData,
      [e.target.name]: e.target.value
    };
    this.setState({ otpData: allData }, () => {
      this.displayError();
    });
   }

   displayError = () => {
     let sum = '';
     const error = document.getElementById('otp_error');
     for(const otp in this.state.otpData){
       sum = sum + this.state.otpData[otp];
     }
     if(sum.length < 4){
       error.textContent = "All fields are required";
     }else{
       error.textContent = "";
     }
   }

   handleOtpVerify = (e) => {
    e.preventDefault();
    let sum = "";
    const formData = {};
    const error = document.getElementById('otp_error');

    for (const otp in this.state.otpData) {
      sum = sum + `${this.state.otpData[otp]}`;
    }

    if(sum.length < 4){
      error.textContent = "All fields are required";
    }else{
      error.textContent = "";
      formData.code = sum;
      formData.client_id =  CLIENT_ID.toString();
      formData.email = this.state.emailData.email;
      Utility.sendRequest(ACTIVATION_CODE_VERIFICATION, 2, formData, (error, response, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'public' });
        } else {

          this.setState({ currentStep:3 });
        }
      });
    }
   }

   handleChangePassword = (e, type) => {
    let formData = this.state.passwordData;
    let password = {}, showPasswordValidation = false;
    const error = document.getElementById(`confirm_passwordError`);
    formData = Utility.validateAndReturnFormData(this.refs['password'], formData, e, validateFieldsUpadtePassword, 'pwa_sign_up');

    if (!Utility.isEmpty(formData.password)) {
      password = Utility.validatePassword(formData.password);
      showPasswordValidation = true;
    }

    if(type === 'password'){
      this.setState({ formData, showPasswordValidation, password }, () => {
        if(!this.verifyPassword()){

          if(this.state.confirm_password && !(this.state.confirm_password === formData)){
          error.textContent = "Confirm Password doesn't match";
          }
        }else{
          error.textContent = " ";
        }
      });
    }else {
      this.setState({ confirm_password:e.target.value }, () => {
        if(Utility.isEmpty(this.state.confirm_password)){
          error.textContent = "Confirm password is a required field";
        }else{
          if(!this.verifyPassword()){
            error.textContent = "Confirm password doesn't match";
          }else{
            error.textContent = " ";
          }
        }

      });
    }
   }

   verifyPassword = () => {
    return this.state.passwordData.password === this.state.confirm_password;
   }

   authUser = (body) => {
     cookie.save('sessionToken', body.token, { path: '/' });
     Utility.sendRequest(USER_AUTH, 1, body.token, (error, response, body) => {
       body = JSON.parse(body);
       if (!body.error) {
         this.props.updateUserData(body);
         this.props.history.push('/home');
       } else {
         toast.error(body.error.message, { containerId: 'public' });
       }
     });
   }

   updatePassword = (e) => {
    e.preventDefault();
     const error = document.getElementById("confirm_passwordError");
     if (Utility.isEmpty(this.state.confirm_password)) {
        error.textContent = "Confirm password is a required field";
     }else{
         error.textContent = " ";
     }

     if (!Utility.showFormErrors(this.refs['password'], validateFieldsUpadtePassword, 'pwa_sign_up')) {
       return;
     }

     if (this.verifyPassword()) {
       const formData = this.state.passwordData;
       formData.email = this.state.emailData.email;
       formData.client_id = CLIENT_ID.toString();

       Utility.sendRequest(CREATE_PASSWORD, 2, formData, (error, response, body) => {
         body = JSON.parse(body);
         if (body.error) {
           toast.error(body.error.message, { containerId: 'public' });
         } else {
           toast.success(body.message, { containerId: 'public' });
           this.authUser(body);
         }
       });
     }
     else{
        error.textContent = "Confirm password doesn't match";
     }
   }

   render(){
     return(
       <>
       {
         this.state.currentStep === FORGOTPASSWORD.send_email &&
         <ForgotEmail
            handleEmailChange={this.handleEmailChange}
            forgotPasswordEmail={this.forgotPasswordEmail}
            email={this.state.emailData.email}
            goSignIn={this.goSignIn}
            goSignUp={this.goSignUp}
            ref="email"
            firstName={this.props.location.state && this.props.location.state.firstName}
            lastName={this.props.location.state && this.props.location.state.lastName}
            profileImgUrl={this.props.location.state && this.props.location.state.profileImgUrl}
            role={this.props.location.state && this.props.location.state.role}
         />
       }
       {
         this.state.currentStep === FORGOTPASSWORD.verify_otp &&
         <Otp
            handleOtpChange={this.handleOtpChange}
            handleOtpVerify={this.handleOtpVerify}
            resendVerificationCode={this.resendVerificationCode}
            email={this.state.emailData.email}
            onKeyUp={this.handleOnKeyUp.bind(this)}
            goBack={this.goBack}
            ref="otp"
         />
       }
       {
         this.state.currentStep === FORGOTPASSWORD.update_password &&
         <UpdatePassword
           handleChangePassword={this.handleChangePassword}
           handleVerfyPassword={this.handleVerfyPassword}
           handleCreatePassword={this.handleCreatePassword}
           showPasswordValidation={this.state.showPasswordValidation}
           updatePassword={this.updatePassword}
           password={this.state.passwordData.password}
           goSignUp={this.goSignUp}
           firstName={this.props.location.state && this.props.location.state.firstName}
           lastName={this.props.location.state && this.props.location.state.lastName}
           profileImgUrl={this.props.location.state && this.props.location.state.profileImgUrl}
           role={this.props.location.state && this.props.location.state.role}
           ref="password"
         />
       }
       </>
     );
   }
}

const mapActionToProps =  { updateUserData };
const mapStateToProps = (state) => ({
  userData: state.userData
});
export default connect(mapStateToProps, mapActionToProps)(ForgotPassword);
