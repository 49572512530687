import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Button } from 'react-bootstrap';
import { ReactComponent as SmLogo } from 'assets/images/logos/s-icon-sharp.svg';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import Collapse from 'react-bootstrap/Collapse';
import cookie from 'react-cookies';

export class SubDomainHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  render() {
    const subDomain = cookie.load('subDomain');

    return(
      <>
        <div className="sub-domain-conatainer mx-auto py-5 px-md-0 px-3 ">
          <div className="text-center">
            <img src={subDomain.crm_logo_url} alt="sharemeister network join" className="sub-domain-logo" />
          </div>
          <h2 className="mt-4 mb-5 text-center text-sm-secondary">Join {subDomain.crm_name} as a network partner!</h2>
          <div className="text-center">
            <img src={require("assets/images/sm-pipeline-illustration-grey.png")} alt="sharemeister network join graphics" />
          </div>
          <div className="text-center pl-4">
            <ul className="list-unstyled sub-domain-feature-list mt-5 mb-4 display-6 display-sm-8 font-bold text-muted d-inline-block text-left">
              <li>Unlock your network for media distribution</li>
              <li>Create and distribute content with {subDomain.crm_name}</li>
              <li>Watch Your audience become your most passionate advocates</li>
            </ul>
          </div>
          <div className="text-center mb-4">
            <NavLink to={'/users/sign_in'} className="btn btn-md btn-primary mr-4 mt-3">Login</NavLink>
            <NavLink to={'/users/sign_up'} className="btn btn-md btn-alternate mr-4 mt-3">Get Started</NavLink>
          </div>
        </div>
        <footer className="row shar-footer py-4 px-3 no-gutters font-bold" >
          <Col md={4} className="align-items-center align-self-baseline flex-nowrap" >
            <div className="sub-domain-footer-logo">
              <SmLogo />
            </div>
            <span className="text-white ml-2 mt-1 display-8 display-sm-9" >BROUGHT TO YOU BY SHAREMEISTER</span>
          </Col>
          <Col md={4} className="text-center text-white my-md-0 my-3" >
            <Button
                onClick={() => this.setState({ open: !this.state.open })}
                aria-controls="example-collapse-text"
                aria-expanded={this.state.open}
                className="btn-link link-primary sub-domain-collapsed"
              >
               Learn More
                { this.state.open ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/> }
             </Button>
             <Collapse in={this.state.open}>
               <div id="example-collapse-text" className="pt-md-5 pt-3" >
                 <span>13355 Noel Road, Suite 1100, Dallas, TX 75240</span><br/>
                 <span>972.348.5989 | hello@sharemeister.com</span><br/>
                 <div className="sub-domain-collapsed-logo mx-auto mt-3 mb-md-0 mb-3">
                  <SmLogo />
                 </div>
               </div>
             </Collapse>
          </Col>
          <Col md={4} className="text-md-right text-center display-8 display-sm-9">
            <NavLink to="/privacy_policy" target="_blank" className="link-alternate mr-3">PRIVACY POLICY</NavLink>
            <NavLink to="/terms_of_services" target="_blank" className="link-alternate">TERMS OF SERVICE</NavLink>
          </Col>
        </footer>
      </>
    );
  }
}
