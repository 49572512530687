import React, { Component } from 'react';
import cookie from 'react-cookies';
import VerifyUser from 'scenes/users/components/verifyUser';
import UploadFile from 'shared/components/file_upload';
import PasswordValidationFrame from 'shared/components/password_validation_frame';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { Form, Spinner } from 'react-bootstrap';
import { InputField, DropDown } from 'shared/components/form_elements';
import { CircleOutlinedAddRoundedIcon, WhiteFilledAddLargeIcon, ToolboxIcon, CloseIcon, TickFilledIcon } from 'shared/icons/icons';
import { Utility } from 'utils';
import { STATES, COUNTRIES, NEIGHBORHOOD_SIGN_UP, VERIFY_CODE, RESEND_VERIFICATION_CODE, CLIENTS, SWITCH_ACCOUNT, USER_AUTH, GET_ACCOUNTS, USERS, VERIFY_OTP, SEND_VERIFICATION_CODE, MESSAGES } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import { ROLE_IDS } from 'config/constants';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SmTermsOfService } from 'shared/components/sm_terms_of_service';
import { UserProfilePicture } from 'shared/components/user_profile_picture';
import Avatar from 'react-avatar';

const validateFields = ['email', 'name', 'country_id', 'state_id', 'zip_code', 'address1', 'city', 'phone', 'first_name', 'last_name'];
const validateUpdateFields = ['first_name', 'name'];

export class NeighborhoodSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      formName: 'neighborhood_form',
      imagePreviewUrl: '',
      imageFile:undefined,
      bannerFile: "",
      bannerPreviewUrl: '',
      isUserRegistered: false,
      showConfirmationScreen: false,
      countries: [],
      states: [],
      logoUrl: '',
      spinner: false,
      verifyCodeParams: {},
      resendVerificationParams: {},
      userClientId: '',
      verifyPhoneNumber: false,
      is_mobile_verified: false,
      showTos : false,
      showPp : false,
      showAup: false,
      showDa: false,
      showModal: false,
      newUserClientDetails: {},
      showSmTosModal: false,
      isCropping:false,
      countryId:"",
    };
  }

  componentDidMount = () => {
    this.getAllCountries();
    if (!Utility.isEmpty(this.props.userData) && !Utility.isEmpty(this.props.id) && this.props.edit && ROLE_IDS.USER !== this.props.neighborhoodState.roleId) this.getClientData();
    if (!Utility.isEmpty(this.props.userData) && this.props.edit && (ROLE_IDS.USER === this.props.neighborhoodState.roleId)) this.setDefaultUserData();

    if (!this.props.edit) this.setDefaultClientData();
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.neighborhoodState !== this.props.neighborhoodState &&
      !Utility.isEmpty(this.props.userData) &&
      this.props.edit &&
      ROLE_IDS.CLIENT_OWNER === this.props.neighborhoodState.roleId
    ) this.getClientData();

    if (
      prevProps.neighborhoodState !== this.props.neighborhoodState &&
      !Utility.isEmpty(this.props.userData) &&
      this.props.edit &&
      ROLE_IDS.USER === this.props.neighborhoodState.roleId
    ) this.setDefaultUserData();

    if (prevProps.neighborhoodState !== this.props.neighborhoodState && !this.props.edit) this.setDefaultClientData();
  }

  getClientData = () => {
    Utility.sendRequest(`${CLIENTS}/${this.props.id}`, 1, {}, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.getStateByCountry(body.country_id);
        this.setDefaultClientData(body);
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  setDefaultUserData = () => {
    const { userData } = this.props;
    const mobileNo = !Utility.isEmpty(userData.country_code) ?
    `${userData.phone_cellular.replace(/\s/g, '')}` : '';
    const data = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      city: userData.user_city,
      email: userData.email,
      country_code: userData.country_code,
      phone_cellular: mobileNo,
      zip_code: userData.user_zip_code,
      country_id: userData.user_country_id,
      state_id: userData.user_state_id,
      address1: userData.user_address1,
      password: userData.password,
      image_url: userData.profile_image_url,
      website: userData.website
    };

    if (userData.user_country_id) this.getStateByCountry(userData.user_country_id);
    this.setState({ formData: data, imagePreviewUrl: userData.profile_image_url, bannerPreviewUrl: userData.user_banner_image_url });
  }

  setDefaultClientData = (data) => {
    const userData = !Utility.isEmpty(data);
    const setFormData = {
      name: userData ? data.name : '',
      website: userData ? data.website : 'https://www.',
      city: userData ? data.city : '',
      email: userData ? data.email : '',
      zip_code: userData ? data.zip_code : '',
      country_id: userData ? data.country_id : '',
      suite: userData ? data.suite : '',
      state_id: userData ? data.state_id : '',
      address1: userData ? data.address1 : '',
      password: userData ? data.password : '',
      phone: userData ? data.phone : '',
      image_url: userData ? data.image_url : '',
      logo_url: userData ? data.logo_url : '',
      banner_image_url: userData ? data.banner_image_url : ''
    };

    this.setState({ formData: setFormData, imagePreviewUrl: setFormData.image_url, bannerPreviewUrl: setFormData.banner_image_url });

  }

  handleChange = (e) => {
    let formData = this.state.formData;
    let password = {}, showPasswordValidation = false;
    let addPassword = this.props.edit ? validateUpdateFields : validateFields;

    if (formData.password || formData.existing_password || formData.confirm_password || !this.props.edit) {
      addPassword = ['password', 'existing_password', 'confirm_password', ...addPassword];
    }

    formData = Utility.validateAndReturnFormData(this, formData, e, addPassword, this.state.formName);

    if (e.target.name === 'country_id') {
      this.getStateByCountry(e.target.value);
    }

    if (!Utility.isEmpty(formData.password)) {
      password = Utility.validatePassword(formData.password);
      showPasswordValidation = true;
    }

    if (e.target.name === 'password' || e.target.name === 'confirm_password') this.validatePassword(e.target.name);

    this.setState({ formData, showPasswordValidation, password });
  }

  validatePassword = (type) => {
    const password = Utility.getElementById('password');
    const confirmPassword = Utility.getElementById('confirm_password');
    const error = Utility.getElementById(`${'confirm_password'}Error`);

    if (!Utility.isEmpty(confirmPassword.value)) {
      if (password.value === confirmPassword.value) {
        error.textContent = null;
        return;
      }
    } else {
      error.textContent = null;
      return;
    }

    if (type === 'confirm_password' || (!Utility.isEmpty(confirmPassword.value) && type === 'password')) {
      error.textContent = `Confirm password doesn't match`;
    }
  }

  getAllCountries = () => {
    Utility.sendRequest(COUNTRIES, 1, { limit: 300, order: 'name' }, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        body.data.unshift({ id: '', name: 'Select country' });

        this.setState({ countries: body.data });
        const US_id = body.data.find(element => element.name==="United States").id;
        this.setState({countryId:US_id});
        if(US_id){
          this.getStateByCountry(US_id);
        }
      } else {
        toast.error(JSON.parse(body).error.message, { containerId: 'private' });
      }
    });
  }

  getStateByCountry = (countryId) => {
    Utility.sendRequest(STATES, 1, { country_id: countryId, limit: 200, order: 'name' }, (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        body.data.unshift({ id: '', name: 'Select state' });

        this.setState({ states: body.data });
      } else {
        toast.error(JSON.parse(body).error.message, { containerId: 'private' });
      }
    });
  }

  handleFileChange = (inputId, previewURL, file, name) => {
    const formData = { ...this.state.formData };
    if (name === 'image_url') {
      this.setState({ imagePreviewUrl: previewURL, imageFile: file, formData }, () => {
        Utility.isFilePathEmpty('image_url', this.state.imagePreviewUrl, this.state.formName);
      });
    } else if (name === 'banner_image_url') {
      this.setState({ bannerPreviewUrl: previewURL, bannerFile: file, formData }, () => {
        Utility.isFilePathEmpty('image_url', this.state.bannerPreviewUrl, this.state.formName);
      });
    }
  }

  removeUploadedFile = (inputId, name, e) => {
    e.preventDefault();
    if (name === 'image_url') {
      const formData = { ...this.state.formData };
      formData.image_url = '';
      this.setState({ imageFile: '', imagePreviewUrl: '', formData });
    } else if (name === 'banner_image_url') {
      this.setState({ bannerFile: '', bannerPreviewUrl: '' });
    }
  }

  uploadFiles = async (id, imageSignedURL, bannerSignedURL) => {
  
    let imageURL = this.state.imagePreviewUrl;
    let bannerURL = this.state.bannerPreviewUrl;

    if (this.state.imageFile && !Utility.isEmpty(this.state.imageFile.size)) {
      imageURL = await Utility.uploadFile(this.state.imageFile, imageSignedURL);
    }

    if (!Utility.isEmpty(this.state.bannerFile.size)) {
      bannerURL = await Utility.uploadFile(this.state.bannerFile, bannerSignedURL);
    }

    this.updateClientLogoUrl(id, imageURL, bannerURL);
  }

  updateClientLogoUrl = (id, imageURL, bannerURL) => {
    const params  = {
      id,
      image_url: imageURL,
      logo_url: imageURL,
      banner_image_url: bannerURL
    };

    this.setState({ logoUrl: imageURL });

    Utility.sendRequest(CLIENTS, 3, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if (this.props.edit) this.apiCalling(this.props.edit);
      }
    });
  }

  createSignUpParams = (formData) => {
    return {
      first_name: formData.first_name,
      last_name: formData.last_name,
      website: formData.website,
      name: formData.name || (formData.first_name + " " + formData.last_name),
      city: formData.city,
      email: formData.email,
      zip_code: formData.zip_code,
      parent_id: this.props.forCityAccount ? null : CLIENT_ID,
      country_id: formData.country_id || this.state.countryId,
      suite: formData.suite,
      state_id: formData.state_id,
      address1: formData.address1,
      password: formData.password,
      phone: formData.phone,
      user_id: this.props.userData.user_id,
      source: 'web',
      imageFile: this.state.imageFile,
      bannerFile: this.state.bannerFile,
      is_active: this.props.forCityAccount ? true : false,
    };
  }

  updateNeighborhoodParams = (formData) => {
    return {
      first_name: formData.first_name,
      last_name: formData.last_name,
      website: formData.website,
      name: formData.name || (formData.first_name + " " + formData.last_name),
      city: formData.city,
      email: formData.email,
      zip_code: formData.zip_code,
      suite: formData.suite,
      country_id: formData.country_id,
      state_id: formData.state_id,
      address1: formData.address1,
      password: formData.password,
      phone: formData.phone,
      phone_cellular: formData.phone,
      existing_password: formData.existing_password,
      imageFile: this.state.imageFile,
      bannerFile: this.state.bannerFile
    };
  }

  updateCitizenParams = (formData) => {
    const mobileNo = !Utility.isEmpty(formData.country_code) ? formData.phone_cellular.slice(formData.country_code.length, formData.phone_cellular.length) : undefined;
    return {
      first_name: formData.first_name,
      last_name: formData.last_name,
      city: formData.city,
      email: formData.email,
      zip_code: formData.zip_code,
      country_id: formData.country_id,
      state_id: formData.state_id,
      address1: formData.address1,
      password: formData.password,
      phone_cellular: mobileNo,
      country_code: formData.country_code,
      existing_password: formData.existing_password,
      imageFile: this.state.imageFile,
      bannerFile: this.state.bannerFile,
      website: formData.website
    };
  }

  signUp = (params) => {
    Utility.sendRequest(NEIGHBORHOOD_SIGN_UP, 2, params, async (error, response, body) => {
      body = JSON.parse(body);

      this.setState({ spinner: false });

      if (!body.error) {
        this.setState({ spinner: true });
        if ((!Utility.isEmpty(this.state.imagePreviewUrl) && body.imageSignedURL) || (!Utility.isEmpty(this.state.bannerPreviewUrl) && body.bannerSignedURL)) {
          await this.uploadFiles(body.UserClients[0].id, body.imageSignedURL, body.bannerSignedURL);
        }

        const verifyCodeParams = {
          email: body.email,
          client_id: this.props.forCityAccount ? body.id : body.parent_id,
          for_city_account: this.props.forCityAccount,
          phone_cellular: this.state.formData.phone,
          country_code: this.state.formData.country_code,
        };

          const resendVerificationParams = {
            email: body.email,
            client_id: body.id,
          };

        body.image_url = this.state.logoUrl;
        this.setState({
          isUserRegistered: true,
          verifyCodeParams,
          resendVerificationParams,
          userClientId: body.UserClients[0].id,
          newUserClientDetails: body,
        });
        this.setState({ spinner: false });
        this.startChat(body.UserClients[0], `Hi ${body.name}. What idea do you want to scale?`);
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  getAllAccount = () => {
    this.props.showLoader(true);

    return new Promise((resolve, reject) => {
      Utility.sendRequest(GET_ACCOUNTS, 1, {}, (error, response, body) => {
        body = JSON.parse(body);

        this.props.showLoader(false);

        if (!body.error) {
          this.props.updateAccountList(body);
          resolve(true);
        } else {
          toast.error(body.error.message, { containerId: 'public' });
          reject(false);
        }
      });
    });
  }

  updateClientDetails = (params) => {
    Utility.sendRequest(CLIENTS, 3, params, async (error, response, body) => {
      body = JSON.parse(body);

      this.setState({ spinner: false });

      if (!body.error) {
        this.setState({ spinner: true });
        if (!Utility.isEmpty(this.state.imagePreviewUrl && body.imageSignedURL) || !Utility.isEmpty(this.state.bannerPreviewUrl && body.bannerSignedURL)) {
          await this.uploadFiles(this.props.userData.id, body.imageSignedURL, body.bannerSignedURL);
        } else {
          this.apiCalling(true);
        }

        this.setState({ spinner: false });

        toast.success(body.message, { containerId: 'public' });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  handleFormErrors = (fields) => {
    const isValid = Utility.showFormErrors(this, fields, this.state.formName);
    let validatePassword = true;
    if (fields.includes('password')) {
      if (!Utility.isEmpty(this.state.password)) {
        validatePassword = Object.values(this.state.password).map((value) => {
          return value;
        });
        if (validatePassword.includes(false)) validatePassword = false;
      }
    }

    return ((isValid && validatePassword) ? true : false);
  }

  submit = (e) => {
    e.preventDefault();
    const formData = Utility.deepClone(this.state.formData);
    let params = {};
    let addPassword = this.props.edit ? validateUpdateFields : validateFields;
    // const prevNumber = !Utility.isEmpty(this.props.userData.country_code) ? `${this.props.userData.country_code}${this.props.userData.phone_cellular}` : '';
    let prevNumber = !Utility.isEmpty(this.props.userData.country_code) ? `${this.props.userData.phone_cellular}` : '';
    prevNumber = prevNumber.replace(" ","");

    if (formData.password || formData.existing_password || formData.confirm_password || !this.props.edit) {
      addPassword = ['password', 'existing_password', 'confirm_password', ...addPassword];
    }

    const isFormValid = this.handleFormErrors(addPassword);

    if (!isFormValid && !this.props.edit) {
      toast.error('Fill all the necessary details', { containerId: 'public' });
      return;
    }

    if (formData.password !== formData.confirm_password) {
      toast.error(`Confirm password doesn't match`, { containerId: 'public' });
      return;
    }

    this.setState({ spinner: true });
   
    if (!this.props.edit) {
      params = this.createSignUpParams(formData);
      this.signUp(params);
    } else {
      if (ROLE_IDS.USER !== this.props.neighborhoodState.roleId) {
        params = this.updateNeighborhoodParams(formData);
        this.updateClientDetails(params);
      } else {
        if (formData.phone_cellular !== prevNumber) {
          if (!this.state.is_mobile_verified) {
            this.setState({ spinner: false });
            toast.error('Click on update to verify your new number', { containerId: 'public' });
            return;
          }
        }
      params = this.updateCitizenParams(formData);
      this.updateCitizenData(params);
      }
    }
  }

  showConfirmationScreen = () => {
    this.apiCalling();
    if(this.props.forCityAccount){
      this.props.setCityFormData(this.state.formData);
      this.props.closeForm();
      this.props.showPurchaseCityAccount(this.state.newUserClientDetails);
    }
    else
      this.setState({ showConfirmationScreen: true });
  }

  switchAccount = () => {
    Utility.sendRequest(SWITCH_ACCOUNT, 1, { id: this.state.userClientId }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'public' });
      } else {
        cookie.save('sessionToken', body.token, { path: '/' });

        Utility.sendRequest(USER_AUTH, 1, { id: this.state.userClientId }, (error, response, body) => {
          body = JSON.parse(body);

          this.props.showLoader(false);

          if (!body.error) {
            this.props.updateUserData(body);
            this.props.history.push('/profile');
            this.props.closeForm();
          } else {
            toast.error(body.error.message, { containerId: 'public' });
          }
        });
      }
    });
  }

  getUpdatedUserDetails = () => {
    return new Promise((resolve, reject) => {
      Utility.sendRequest(USER_AUTH, 1, {}, (error, response, body) => {
        body = JSON.parse(body);

        this.props.showLoader(false);

        if (!body.error) {
          this.props.updateUserData(body);
          resolve(true);
        } else {
          reject(false);
          toast.error(body.error.message, { containerId: 'public' });
        }
      });
    });
  }

  setValue = (value) => {
    const element = Utility.getElementById('country_code_error');
    if (element) element.textContent = '';

    const formData = { ...this.state.formData };
    formData.phone_cellular = value;

    if (!Utility.isEmpty(value)) {
      const data = parsePhoneNumber(value);

      if (data) {
        const error = Utility.getElementById('phone_cellular_error');
        if (error) error.textContent = '';

        formData.country_code = `+${data.countryCallingCode}`;

        this.setState({ formData });
      }
    }
  }

  backToForm = () => {
    this.setState({ isUserRegistered: false, showConfirmationScreen: false, is_mobile_verified: true,
    verifyPhoneNumber: false });
  }

  verifyPhoneNumber = () => {
    const verifyCodeParams = {
      phone_cellular: this.state.formData.phone_cellular.slice(this.state.formData.country_code.length, this.state.formData.phone_cellular.length),
      country_code: this.state.formData.country_code,
      client_id: CLIENT_ID,
      email: this.state.formData.email,
      updatePhoneNumber: true
    };
    const resendVerificationParams = {
      phone_cellular: this.state.formData.phone_cellular.slice(this.state.formData.country_code.length, this.state.formData.phone_cellular.length),
      country_code: this.state.formData.country_code,
      client_id: CLIENT_ID,
      email: this.state.formData.email,
    };

    Utility.sendRequest(SEND_VERIFICATION_CODE, 3, verifyCodeParams, async (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.setState({ verifyPhoneNumber: true, verifyCodeParams, resendVerificationParams });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  uploadUserFiles = async (id, imageSignedURL, bannerSignedURL) => {
    let imageURL = this.state.imagePreviewUrl;
    let bannerURL = this.state.bannerPreviewUrl;

    if (this.state.imageFile && !Utility.isEmpty(this.state.imageFile.size) && !Utility.isEmpty(imageSignedURL)) {
      imageURL = await Utility.uploadFile(this.state.imageFile, imageSignedURL);
    }

    if (!Utility.isEmpty(this.state.bannerFile.size) && !Utility.isEmpty(bannerSignedURL)) {
      bannerURL = await Utility.uploadFile(this.state.bannerFile, bannerSignedURL);
    }

    this.updateUserLogoUrl(id, imageURL, bannerURL);
  }

  updateUserLogoUrl = (id, imageURL, bannerURL) => {
    const params  = {
      id,
      profile_image_url: imageURL,
      banner_image_url: bannerURL,
    };

    Utility.sendRequest(USERS, 3, params, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.apiCalling(true);
      }
    });
  }

  updateCitizenData = (params) => {
    Utility.sendRequest(USERS, 3, params, async (error, response, body) => {
      body = JSON.parse(body);
      this.setState({ spinner: false });
      if (!body.error) {
        this.setState({ spinner: true });
        if (!Utility.isEmpty(this.state.imagePreviewUrl && body.imageSignedURL) || !Utility.isEmpty(this.state.bannerPreviewUrl && body.bannerSignedURL)) {
          await this.uploadUserFiles(this.props.userData.id, body.imageSignedURL, body.bannerSignedURL);
        } else {
          this.apiCalling(true);
        }

        this.setState({ spinner: false });

        toast.success(body.message, { containerId: 'public' });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  apiCalling = async (closeForm) => {
    let next;

    await this.getAllAccount().then((success) => {
      next = success; }).catch((error) => { next = error; });
    await this.getUpdatedUserDetails().then((success) => {
      next = success; }).catch((error) => { next = error; });

    if (closeForm) this.props.closeForm();
  }

  sendMessage = (supportUser, message, friend) => {
    if(!message || message.trim() === '')  return;

    const senderPrivateKey = supportUser.private_key;
    const receiverPrivateKey = friend.private_key;

    const encryptedMessage = Utility.encryptedMessage(message, senderPrivateKey, receiverPrivateKey);

    const params = {
      from_user_client_id: supportUser.id,
      to_user_client_id: friend.id,
      new_message: this.props.chatPrivateKey === '',
      media: '',
      message: encryptedMessage,
      user_id: supportUser.user_id,
      source: friend.isAudience ? 'audience' : 'network',
      chat_private_key: senderPrivateKey
    };

    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        body.message = message;
      }
    });
  };

  startChat = (userClientObj, message) => {
    if(!Utility.isEmpty(this.props.supportUsers)) {
      this.sendMessage(this.props.supportUsers[0], message, userClientObj);
    }
  };

  paintAccountType = () => {
    if(ROLE_IDS.USER === this.props.neighborhoodState.roleId)
      return 'Citizen';
    else if(ROLE_IDS.CLIENT_OWNER === this.props.neighborhoodState.roleId)
      return 'Neighborhood';
    else if(ROLE_IDS.PARENT_OWNER === this.props.neighborhoodState.roleId)
      return 'City';
    else if(this.props.isNeighborhoodFormOpen === true)
      return 'Neighborhood';
    else
      return 'City';
  }
  setCropping= (bool)=>{
    this.setState({
      isCropping:bool,
    })
  }

  render() {
    const userData = this.props.userData;
    const profileImgUrl = !Utility.isEmpty(this.props.userData) ? this.props.userData.profile_image_url : '';
    const cityImgUrl = !Utility.isEmpty(userData) ? userData.city_logo_url : '';
    const logoUrl = this.state.logoUrl;
    let countryName = '';

    if (this.state.formData.country_id) {
      this.state.countries.forEach((item) => {
        if (item.id === this.state.formData.country_id) countryName = item.name;
      });
    }

    return(
      <>
        <div className="col-5 px-0 position-fixed create-campaign-margin bg-white page-sidebar-outer gil-pwa-callout pwa-account-callout">
           <div className="pwa-callout-decorator-sec py-3 d-lg-none" >
            <div  onClick={this.props.closeForm} className="pwa-callout-decorator" />
          </div>
          <div className="page-sidebar-inner h-100 overflow-auto scroll-y shar-form search-form-pwa">
            {
              !this.state.isUserRegistered && !this.state.showConfirmationScreen && !this.state.verifyPhoneNumber &&
              <>
              <div className="page-sidebar-head border-bottom align-items-center">
                <CircleOutlinedAddRoundedIcon />
                <span className="font-bold display-8 ml-2">{this.props.edit ? 'Update' : 'Create'} Account</span>
                <div onClick={this.props.closeForm} className="ml-auto cursor-pointer">
                  <CloseIcon />
                </div>
              </div>
              <div className="page-sidebar-body">
                <Form className="shar-form search-form search-form-pwa sidebar-form sidebar-form-width pb-4 pb-md-0">
                  <Form.Group className="mb-4 pb-1">
                    <Form.Label className="font-bold display-8 mb-3">Account Type</Form.Label>
                    <div className="form-type-selected align-items-center form-control py-0 px-3">
                      {
                        this.props.forCityAccount ?
                        <>
                          <ToolboxIcon mainClass="profile-update-toolbox-icon" fillClass="fill-primary" />
                          {/* <img src={require('assets/images/profile/new-profile/security-key.svg')} className="notification-img rounded-circle" /> */}
                          <span className="font-bold display-8 ml-2 text-dark">
                            City Account
                          </span>
                        </> :
                        <>
                        {ROLE_IDS.PARENT_OWNER === this.props.neighborhoodState.roleId ?
                          <img src={require('assets/images/profile/new-profile/security-key.svg')} className="notification-img rounded-circle" /> :
                          (!Utility.isEmpty(cityImgUrl) ?
                          <img src={!Utility.isEmpty(cityImgUrl) ? cityImgUrl : require("assets/images/users/user_no_image.png")} className="notification-img rounded-circle" /> :
                          <Avatar
                          name={userData.client_name}
                          round={true}
                          size={'50'}
                          textSizeRatio={1.75}
                          color='#007bb3'
                          maxInitials={1}
                        />)}
                          <span className="font-bold display-8 ml-2 text-dark">
                            {this.paintAccountType()} Account
                          </span>
                        </>
                      }
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-4 pb-1">
                    <Form.Label className="font-bold display-8 mb-3" >Account Owner</Form.Label>
                    <div className="form-type-selected align-items-center form-control py-0 px-3">
                      <UserProfilePicture
                        image = {profileImgUrl}
                        userName = {this.props.userData.user_name}
                      />
                      {ROLE_IDS.USER !== this.props.neighborhoodState.roleId ?
                      <span className="font-bold display-8 ml-3 text-dark text-capitalize">
                        {`${Utility.capitalize(this.props.userData.user_name)}`}
                      </span>
                      :
                      <span className="font-bold display-8 ml-3 text-dark text-capitalize">
                        {`${Utility.capitalize(this.props.userData.first_name)} `}
                        {!Utility.isEmpty(this.props.userData.last_name) ? Utility.capitalize(this.props.userData.last_name) : ''}
                      </span>
                      }
                    </div>
                  </Form.Group>
                  {
                    ROLE_IDS.USER !== this.props.neighborhoodState.roleId && this.props.edit &&
                    <Form.Group className="mb-4 pb-1" >
                      <Form.Label className="font-bold display-8 mb-3" >Organization Name</Form.Label>
                      <InputField
                        required={true}
                        type="text"
                        name="name"
                        ref="name"
                        value={this.state.formData.name}
                        placeholder="Enter Organization Name..."
                        handleChange={this.handleChange}
                      />
                    </Form.Group>
                  }
                   {
                    (this.props.forCityAccount || this.props.isNeighborhoodFormOpen) &&
                    <Form.Group className="mb-4 pb-1" >
                      <Form.Label className="font-bold display-8 mb-3" >Organization Name</Form.Label>
                      <InputField
                        required={true}
                        type="text"
                        name="name"
                        ref="name"
                        value={this.state.formData.name}
                        placeholder="Enter Organization Name..."
                        handleChange={this.handleChange}
                      />
                    </Form.Group>
                  }
                  {
                    (ROLE_IDS.USER === this.props.neighborhoodState.roleId) && !this.props.forCityAccount &&
                    <Form.Group className="mb-4 pb-1" >
                      <Form.Label className="font-bold display-8 mb-3" >First Name</Form.Label>
                      <InputField
                        required={true}
                        type="text"
                        name="first_name"
                        ref="first_name"
                        value={this.state.formData.first_name}
                        placeholder="Enter Organization Name..."
                        handleChange={this.handleChange}
                      />
                    </Form.Group>
                  }
                  {
                    (ROLE_IDS.USER === this.props.neighborhoodState.roleId) && !this.props.forCityAccount &&
                    <Form.Group className="mb-4 pb-1" >
                      <Form.Label className="font-bold display-8 mb-3" >Last Name</Form.Label>
                      <InputField
                        required={true}
                        type="text"
                        name="last_name"
                        ref="last_name"
                        value={this.state.formData.last_name}
                        placeholder="Enter Organization Name..."
                        handleChange={this.handleChange}
                      />
                    </Form.Group>
                  }
                  <Form.Group className="mb-4 pb-1">
                    <Form.Label className="font-bold display-8 mb-3">{this.props.edit ? 'Edit Primary Image' : 'Add an Image or Logo'}</Form.Label>
                     <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload rounded-circle">
                        <label className="post-uplodad-label mb-0 cursor-pointer">
                          <UploadFile
                            name='image_url'
                            inputId="image_url"
                            removeText="Remove image"
                            categoryName="neighborhood_branc_image"
                            acceptTypes="image/x-png,image/gif,image/jpeg"
                            handleFileChange={this.handleFileChange}
                            uploadedFilePreviewUrl={this.state.imagePreviewUrl}
                            removeUploadedFile={this.removeUploadedFile}
                            setCropping={this.setCropping}
                            fromEditUser={true}
                          />
                           {/* {!this.state.isCropping &&  */}
                            <>
                              <div className="error errorIcon" id={'image_urlError'} />
                              <WhiteFilledAddLargeIcon mainClass="add-icon-upper"/>
                            </>
                          {/* } */}
                        </label>
                      </div>
                      
                    </Form.Group>
                  {CLIENT_ID !== "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5" &&
                  <Form.Group className="mb-4 pb-1">
                    <Form.Label className="font-bold display-8 mb-3">Edit Background Image </Form.Label>
                    <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
                      <label className="post-uplodad-label mb-0 cursor-pointer">
                        <UploadFile
                          name='banner_image_url'
                          inputId="image_url"
                          removeText="Remove image"
                          categoryName="user_banner_image"
                          acceptTypes="image/x-png,image/gif,image/jpeg"
                          uploadedFilePreviewUrl={this.state.bannerPreviewUrl}
                          handleFileChange={this.handleFileChange}
                          removeUploadedFile={this.removeUploadedFile}
                        />
                        <div className="error errorIcon" id={'image_urlError'} />
                        <WhiteFilledAddLargeIcon mainClass="add-icon-upper"/>
                      </label>
                    </div>
                  </Form.Group>}
                  <Form.Group className="mb-4 pb-1">
                    <Form.Label className="font-bold display-8 mb-3">Organization Email</Form.Label>
                    <InputField
                      required={true}
                      disabled={this.props.edit}
                      type="email"
                      name="email"
                      ref="email"
                      placeholder="Enter Organization Email..."
                      value={this.state.formData.email}
                      handleChange={this.handleChange}
                    />
                  </Form.Group>

                  {
                    this.props.edit && (ROLE_IDS.USER === this.props.neighborhoodState.roleId) &&
                    <Form.Group className="mb-4 pb-1 mobile-input create-neighborhood-mobile-input">
                      <Form.Label className="font-bold display-8 mb-3">Enter Mobile Number</Form.Label>
                      <PhoneInput
                        id="phone_cellular"
                        placeholder="Mobile Number"
                        className="res-font-size-3-5 phone-num-color"
                        defaultCountry={'US'}
                        value={this.state.formData.phone_cellular}
                        onChange={phone => this.setValue(phone)}
                      />
                      <div className="error errorIcon" id="country_code_error"/>
                      <div className="error errorIcon" id="phone_cellular_error"/>
                      <div className="text-right form-field-width pr-3" >
                        <span className="font-bold cursor-pointer"
                          onClick={this.verifyPhoneNumber}
                        >
                          Verify
                        </span>
                      </div>
                    </Form.Group>
                  }
                  {
                    <Form.Group className="mb-4 pb-1">
                      <Form.Label className="font-bold display-8 mb-3">Organization Phone Number</Form.Label>
                      <InputField
                        required={true}
                        type="text"
                        name="phone"
                        ref="phone"
                        value={CLIENT_ID==="0bc8dc7c-b5a3-4344-b313-819ppzhau6b5"? '(+1) 855-384-6766' : this.state.formData.phone}
                        placeholder="Enter Organization Phone..."
                        handleChange={this.handleChange}
                        onKeyPress={Utility.handleNumber}
                      />
                    </Form.Group>
                  }

                  <Form.Group className="mb-4 pb-1">
                    <Form.Label className="font-bold display-8 mb-3">Website</Form.Label>
                    <InputField
                      type="text"
                      name="website"
                      ref="website"
                      value={this.state.formData.website}
                      placeholder="https://www.website.com"
                      handleChange={this.handleChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="country_id">
                    <Form.Label className="font-bold display-8 mb-3">
                      Where are you located
                    </Form.Label>
                    <DropDown
                      id="country_id"
                      required={true}
                      optionId="id"
                      optionValue="name"
                      name="country_id"
                      ref="country_id"
                      
                      value={this.state.formData.country_id ? this.state.formData.country_id : this.state.countryId}
                      options={this.state.countries}
                      handleChange={this.handleChange}
                    />
                  </Form.Group>
                  {
                    !Utility.isEmpty(this.state.states) &&
                    <Form.Group className="mb-4 form-element-mb-20" controlId="state_id">
                      <DropDown
                        id="state_id"
                        required={true}
                        optionId="id"
                        optionValue="name"
                        name="state_id"
                        ref="state_id"
                        value={this.state.formData.state_id}
                        options={this.state.states}
                        handleChange={this.handleChange}
                      />
                    </Form.Group>
                  }
                  <Form.Group className="mb-4 pb-1" >
                    <InputField
                      required={true}
                      type="text"
                      name="address1"
                      ref="address1"
                      value={this.state.formData.address1}
                      placeholder="Add location..."
                      handleChange={this.handleChange}
                    />
                    {
                      (ROLE_IDS.USER !== this.props.neighborhoodState.roleId) &&
                      <InputField
                        required={true}
                        type="text"
                        name="suite"
                        ref="suite"
                        value={this.state.formData.suite}
                        placeholder="Suite #"
                        handleChange={this.handleChange}
                      />
                    }
                    <InputField
                      required={true}
                      type="text"
                      name="city"
                      ref="city"
                      value={this.state.formData.city}
                      placeholder="City"
                      handleChange={this.handleChange}
                    />
                    <InputField
                      required={true}
                      type="text"
                      name="zip_code"
                      ref="zip_code"
                      value={this.state.formData.zip_code}
                      onKeyPress={Utility.handleAlphaNumeric}
                      handleChange={this.handleChange}
                      placeholder="Zip Code"
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className="font-bold display-8 mb-3">
                      {this.props.edit ? 'Update Password' : 'Create Password'}
                    </Form.Label>
                    {
                      this.props.edit &&
                      <InputField
                        required={true}
                        type="password"
                        name="existing_password"
                        ref="existing_password"
                        placeholder="Enter Existing Password..."
                        handleChange={this.handleChange}
                      />
                    }
                    <InputField
                      required={!this.props.edit}
                      id="password"
                      type="password"
                      name="password"
                      ref="password"
                      placeholder={this.props.edit ? "Enter New Password..." : "Enter Password..."}
                      handleChange={this.handleChange}
                    />
                    {
                      this.state.showPasswordValidation &&
                      <PasswordValidationFrame password={this.state.password}/>
                    }
                    <InputField
                      required={!this.props.edit}
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      ref="confirm_password"
                      placeholder={this.props.edit ? "Confirm New Password..." : "Confirm Password..."}
                      handleChange={this.handleChange}
                    />
                  </Form.Group>
                  <p className="font-medium display-8 ml-1 pt-4 mb-4 pb-3 mr-md-n4" >
                  {
                    this.props.forCityAccount ?
                    <>
                    {!this.props.edit &&
                     <>
                     By selecting, “Create”, you agree to the <span onClick={() => this.setState({ showSmTosModal: true, showTos: true })} className="text-primary font-medium cursor-pointer">Terms of Service</span>, <span
                      onClick={() => this.setState({ showSmTosModal: true, showPp: true })} className="text-primary font-medium cursor-pointer">Privacy Policy</span>, <span onClick={() => this.setState({ showSmTosModal: true, showDa: true })} className="text-primary font-medium cursor-pointer">Development Agreement</span>, and <span onClick={() => this.setState({ showSmTosModal: true, showAup: true })} className="text-primary font-medium cursor-pointer">Acceptable Use Policy</span>
                    </>}
                    </>
                    :
                    <>
                    {!this.props.edit && <> By selecting, “Create”, you agree to the <Link to="/terms_of_services" target="_blank" className="text-primary font-medium" >Terms of Service</Link> and <Link to="/privacy_policy" target="_blank" className="text-primary font-medium">Privacy Policy</Link>.</>}
                    </>
                  }
                  </p>
                  <button
                    className="btn btn-theme btn-primary btn-get-started position-relative mx-auto mb-4"
                    onClick={(e) => this.submit(e)}
                    disabled={this.state.spinner}
                  >
                    {
                      this.state.spinner
                      ? <Spinner animation="border" role="status"/>
                      : this.props.edit ? 'UPDATE' : 'CREATE'
                    }
                  </button>
                </Form>
              </div>
              </>
            }
            {
              ((this.state.isUserRegistered && !this.state.showConfirmationScreen) || this.state.verifyPhoneNumber) &&
              <>
                <div className="page-sidebar-head border-bottom align-items-center">
                  <CircleOutlinedAddRoundedIcon /><span className="font-bold display-8 ml-2">Confirm OTP</span>
                  <div onClick={this.props.closeForm} className="ml-auto cursor-pointer">
                    <CloseIcon />
                  </div>
                </div>
                <VerifyUser
                  platform={this.props.forCityAccount ? this.state.formData.email : (ROLE_IDS.USER === this.props.neighborhoodState.roleId) ? this.state.formData.phone_cellular : this.state.formData.email}
                  history={this.props.history}
                  showLogo={false}
                  forCityAccount={this.props.forCityAccount}
                  verifyCodeAPIName={(ROLE_IDS.USER === this.props.neighborhoodState.roleId) ? VERIFY_OTP : VERIFY_CODE}
                  resendActivationCodeAPI={!this.props.edit ? RESEND_VERIFICATION_CODE : SEND_VERIFICATION_CODE}
                  showNextScreen={!this.props.edit}
                  editDetails={this.props.edit}
                  backToForm={this.backToForm}
                  showConfirmationScreen={this.showConfirmationScreen}
                  verifyCodeParams={this.state.verifyCodeParams}
                  resendVerificationParams={this.state.resendVerificationParams}
                />
              </>
            }
            {
              this.state.showConfirmationScreen && !this.props.forCityAccount &&
              <>
                <div className="page-sidebar-head border-bottom align-items-center">
                  <TickFilledIcon fillClass={'fill-primary'} /><span className="font-bold display-8 ml-2">Confirmation</span>
                  <div onClick={this.props.closeForm} className="ml-auto cursor-pointer">
                    <CloseIcon />
                  </div>
                </div>
                <div className="page-sidebar-body">
                  <div className="account-created-successful align-items-center flex-nowrap border-bottom" >
                    <img src={!Utility.isEmpty(cityImgUrl) ? cityImgUrl : require("assets/images/users/user_no_image.png")} className="rounded-circle account-created-img" alt="" />
                    <div className="display-8 font-medium ml-4">
                      You have successfully created <br />
                      a <span className="text-primary font-bold" >Neighborhood Account</span>
                    </div>
                  </div>
                  <div className="py-4 border-bottom mb-3">
                    <div className="font-14 font-medium mb-3">Neighborhood Account</div>
                    <div className="align-items-center flex-nowrap">
                      <img src={!Utility.isEmpty(logoUrl) ? logoUrl : require("assets/images/users/user_no_image.png")} className="img-product rounded-circle" alt="" />
                      <div className="ml-3" >
                        <div className="font-bold display-7 mb-1">{this.state.formData.name}</div>
                        <div className="font-medium font-14">{this.state.formData.address1}, {this.state.formData.city}, {countryName}</div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-primary-pwa mt-5 font-14 px-4"
                    onClick={this.switchAccount}
                  >
                    GO TO PROFILE
                  </button>
                </div>
              </>
            }
          </div>
        </div>

        {this.state.showSmTosModal &&
          <SmTermsOfService
            showTos={this.state.showTos}
            showPp={this.state.showPp}
            showDa={this.state.showDa}
            showAup={this.state.showAup}
            handleClose={() => this.setState(
              {
                showSmTosModal: false,
                showTos: false,
                showPp: false,
                showDa: false,
                showAup: false
              }
            )}
          />
        }
      </>
    );
  }
}
