import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { MdForum, MdPeople } from "react-icons/md";
import * as Utility from "utils/utility";
import MyCommunity from "shared/components/my_community";
import { showLoader } from "shared/actions";
import { Form } from "react-bootstrap";
import { InputField } from "shared/components/form_elements";
import {
  GET_FOLLOWERS_FOLLOWING,
  GET_USERS_BY_NETWORKS,
} from "../../../../../config/constants/api_endpoints";
import { toast } from "react-toastify";
import { CLIENT_ID } from "config/constants/config";
import { connect, useSelector } from "react-redux";
import PeersCard from "./PeersCard";
import { AiOutlineSearch } from "react-icons/ai";

const Partcipants = (props) => {
  const userData = useSelector((state) => state.userData);
  const [isApiResponse, setIsApiResponse] = useState(false);
  const [users, setUsers] = useState([]);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [search, setSearch] = useState("");
  let debouncedFn = null;

  function compare(a, b) {
    if (a.user_name < b.user_name) {
      return -1;
    }
    if (a.user_name > b.user_name) {
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    console.log(props);
  }, []);

  const setDetailsFollowerfollowing = (followingId) => {
    const { id } = userData;
    if (id !== followingId) {
      const params = {
        followerId: id,
        followingId: followingId,
        clientId: CLIENT_ID,
      };

      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        2,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            props.getAllMembers();
          }
        }
      );
    }
  };

  useEffect(() => {
    if (search) props.getAllMembers(search);
    else props.getAllMembers();
  }, [search]);

  const handleSearch = (ev) => {
    ev.persist();
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };
  return (
    <>
  {!props.hideHeading && <div className="course_panal_header">
        <MdPeople className="courseSidebarIcon" />
        <div className="header_title">Peers</div>
      </div>}

      <div className="ml-4">
        <div className="mt-3">
          <div className="d-flex">
            <Form className="shar-form search-form form-inline">
              <div className="course-input-group input-group">
                <InputField
                  type="text"
                  placeholder="Search Peers"
                  className='h-25 headerSearchBar'
                  handleChange={(ev) => handleSearch(ev)}
                />
                <div className="course-community-search input-group-append">
                  <span
                    className="input-group-text course-search-icon bg-white"
                    id="basic-addon2"
                  >
                    <AiOutlineSearch />
                  </span>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Form className="shar-form search-form search-form-pwa sidebar-form">
        <Form.Group className="mb-0"></Form.Group>
      </Form>

      <div className="d-flex ml-3 mt-5 flex-lg-wrap community-section-inner slider-mob-inner mb-42">
        {!Utility.isEmpty(props.allMembers) ? (
          props.allMembers.map((user) => {
            return (
              <PeersCard
              openProfile={props.openProfile}
              openProfilePopUp={props.openProfilePopUp}
                key={user.id}
                item={user}
                handleOnClick={setDetailsFollowerfollowing}
                showCharFollowButtons={true}
                fromSeeAll={Utility.pwaModeEnabled()}
                closeCallout={props.closeCallout}
              />
            );
          })
        ) : (
          <Spinner animation="border" size="sm" role="status" />
        )}
      </div>
    </>
  );
};
const mapActionToProps = { showLoader };

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionToProps)(Partcipants);
