import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { CircleOutlinedAddIcon, DragIcon, ChevronDownIcon, ChevronLeftIcon } from 'shared/icons/icons';
import { Utility } from 'utils';
import { GET_ASSIGNMENTS, CREATE_EDIT_QUESTIONS, SWAP_ASSIGNMENT_IDS } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { showLoader } from 'shared/actions';
import CreateAssignments from './create_assignment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campId: this.props.campId,
      openAssignment: false,
      lessonList: [],
      assignments: [],
      edit_assignment: false,
      openQuizForm: false,
      assignment_id: '',
      assignment_name: '',
      campaign_id: '',
      parent_id: this.props.campId,
      question_id: "",
      question_title: "",
      question_type: "",
      question_type_name: "",
      question_options_attributes: [],
      question_lists: [],
      edit_quiz: false,
      user_id:this.props.userData.user_id,
      questionsAdded: false,
      lesson_name_error: '',
      assignment_name_error: '',
      question_error: '',
      question_name_error: '',
      question_correct_answer_error: '',
      question_answer_error: ''
    };
  }


  componentDidMount() {
    this.getAssignments();
  }

  componentDidUpdate(prevProps) {
  if (prevProps.updateLessons !== this.props.updateLessons) {
      this.setState({
        lessonList: this.props.updateLessons
      });
  }
}

  getAssignments = () => {
    const params = {
      parent_id: this.state.campId
    };

    Utility.sendRequest(GET_ASSIGNMENTS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        this.props.showLoader(false);
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.props.showLoader(false);
        if(body.data){
          this.setState({
            assignments: body.data
          });
        }
      }
    });
  }

  openAssignment = () => {
    this.setState({
      openAssignment : !this.state.openAssignment,
      openQuizForm: false
    }, () => {
      this.resetState();
    });
  }

  resetState = () => {
    this.setState({
      edit_assignment: false,
      assignment_name: '',
      assignment_id: '',
      question_id: "",
      campaign_id:'',
      question_title: "",
      question_type: "",
      question_type_name: "",
      question_options_attributes: [],
      question_lists: [],
      questionsAdded: false,
      lesson_name_error: '',
      assignment_name_error: '',
      question_error: '',
      question_name_error: '',
      question_correct_answer_error: '',
      question_answer_error: ''
    });
  }

  setAssignment = (evt) => {
    if(evt.target.value !== '') {
      this.setState({ assignment_name_error: '' });
    }
    this.setState({
      assignment_name: evt.target.value
    });
  }

  saveAssignment = () => {
    const { edit_assignment, questionsAdded, assignment_name, campaign_id, question_title, question_type, question_options_attributes } = this.state;

    if(campaign_id === '') {
      this.setState({ lesson_name_error: 'Lesson is required' });
      return;
    } else if (assignment_name === '') {
      this.setState({ assignment_name_error: 'Assignment name is required' });
      return;
    } else if ((!edit_assignment && question_options_attributes.length === 0 && question_title === '' && question_type === '')) {
      this.setState({ question_error: 'Add at least one question for assignment.' });
      return;
    }

    this.props.showLoader(true);

    const reqUri = edit_assignment || questionsAdded ? `${GET_ASSIGNMENTS}/${this.state.assignment_id}` : GET_ASSIGNMENTS;
    const method = edit_assignment || questionsAdded ? 3 : 2;

      const data = {
        parent_id: this.state.campId,
        campaign_id: this.state.campaign_id,
        assignment_name: this.state.assignment_name,
        client_id: this.state.user_id
      };

      Utility.sendRequest(reqUri, method, data, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          this.props.showLoader(false);
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          this.resetAssignMentState();
          this.getAssignments();
        }
      });
  }

  resetAssignMentState = () => {
    this.setState({
      openAssignment: false,
      edit_assignment: false,
      assignment_name: '',
      campaign_id:'',
      assignment_id: '',
      question_id: "",
      question_title: "",
      question_type: "",
      question_type_name: "",
      question_options_attributes: [],
      question_lists: [],
      questionsAdded: false,
      lesson_name_error: '',
      assignment_name_error: '',
      question_error: '',
      question_name_error: '',
      question_correct_answer_error: '',
      question_answer_error: '',
      openQuizForm: false,
      edit_quiz: false
    });
  }

  editAssignment = (details) => {
    this.setState({
      campaign_id: details.campaign_id,
      edit_assignment: true,
      openAssignment : !this.state.openAssignment,
      assignment_name: details.assignment_name,
      assignment_id: details.id,
      client_id: this.state.user_id
    }, () => {
      this.getQuestionList();
    });
  }

  deleteAssignment = (details) => {
    Utility.sendRequest(GET_ASSIGNMENTS + '/' + details.id, 4, {}, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
       else {
         const updatedAssignment = this.state.assignments.filter(assignment => assignment.id !== details.id);
          this.setState({ assignments: updatedAssignment });
         this.getAssignments();
      }
    });
  }

  handleDropdown = (evt) => {
    if(evt.split(',')[1] !== '') {
      this.setState({ lesson_name_error: '' });
  }

    this.setState({
      campaign_id: evt.split(',')[1]
    });
  }

  getQuizValues = (type) => {
    if(type === 1) {
      return [
        {
          option_value: "",
          is_correct: false
        },
        {
          option_value: "",
          is_correct: false
        },
        {
          option_value: "",
          is_correct: false
        },
        {
          option_value: "",
          is_correct: false
        }
      ];
    }
    else if (type === 2){
      return [
        {
          option_value: "",
          is_correct: false
        },
        {
          option_value: "",
          is_correct: false
        }
      ];
    }else{
      return [
        {
          option_value: "Correct",
          is_correct: true
        }
      ];
    }
  }

  openQuiz = (type) => {
    switch (type) {
      case 1:
        this.setState({ question_type_name: 'Multiple Choice' });
        break;
        case 2:
        this.setState({ question_type_name: 'True Or False' });
        break;
      case 3:
        this.setState({ question_type_name: 'Essay' });
        break;
      default:
        break;
    }
    this.setState({
        edit_quiz: false,
        question_type: type,
        openQuizForm: true,
        question_title: '',
        question_options_attributes: this.getQuizValues(type),
        question_error: '',
        question_name_error: '',
        question_correct_answer_error: '',
        question_answer_error: ''
    });
  }

  handleChange = (evt, i) => {
    const question_options_attributes = [...this.state.question_options_attributes];
    const item = { ...question_options_attributes[i] };

    if(evt.target.value !== '' || evt.target.checked) {
      this.setState({ question_error : '', question_answer_error: '' });
    }

    item.option_value = evt.target.value;
    question_options_attributes[i] = item;
    this.setState({ question_options_attributes });
  }

  onToggleClick = (evt, i) => {
    const question_options_attributes = [...this.state.question_options_attributes];
    const item = { ...question_options_attributes[i] };
    this.setState({ question_correct_answer_error : '' });
    item.is_correct = evt.target.checked;
    question_options_attributes[i] = item;
    this.setState({ question_options_attributes }, () => {
      this.setState(state => {
        const question_options_attributes = state.question_options_attributes.map((item, index) => {
            item.is_correct = index != i ? false : true;
            return item;
        });

        return {
          question_options_attributes,
        };
      });
    });
  }

  questionChange = (evt) => {
    if(evt.target.value !== '') {
      this.setState({ question_error : '', question_name_error: '' });
    }

    this.setState({ question_title : evt.target.value,  });
  }

  prepareQuizParams = (id) => {
    return {
      assignment_id:id,
      question_title: this.state.question_title,
      question_type: this.state.question_type,
      question_options_attributes: this.state.question_options_attributes,
      client_id: this.state.user_id
    };
  }

  saveQuizRequest = (params) => {
    Utility.sendRequest(CREATE_EDIT_QUESTIONS, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({
          openQuizForm: false
        }, () => {
          this.getQuestionList();
        });
      }
    });
  }

  saveQuiz = () => {
    const { edit_assignment, assignment_id, assignment_name, campaign_id, question_type, question_title, question_options_attributes } = this.state;

    if(campaign_id === '') {
      this.setState({ lesson_name_error: 'Lesson is required.' });
      return;
    } else if(assignment_name === '') {
      this.setState({ assignment_name_error: 'Assignment name is required.' });
      return;
    } else if(question_title === ''){
      this.setState({ question_name_error : 'Question is required.' });
      return;
    } else if(question_type === 1 || question_type === 2){
      if(question_options_attributes.filter(answer => answer.option_value !== "").length === 0){
        this.setState({ question_answer_error : 'Please add all answer.' });
        return;
       }else if(question_options_attributes.filter(answer => answer.is_correct === true).length === 0){
        this.setState({ question_correct_answer_error : 'Please check the correct answer.' });
        return;
       }
    }

    const data = {
      parent_id: this.state.campId,
      campaign_id: this.state.campaign_id,
      assignment_name: this.state.assignment_name,
      client_id: this.state.user_id
    };

    if (!edit_assignment) {
      if (!assignment_id) {
        Utility.sendRequest(GET_ASSIGNMENTS, 2, data, (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: 'private' });
          } else {
            const prepareQuiz = this.prepareQuizParams(body.data.id);
            this.setState({ questionsAdded: true, assignment_id : body.data.id }, () => {
              this.saveQuizRequest(prepareQuiz);
            });
          }
        });
      }else {
        const prepareQuiz = this.prepareQuizParams(assignment_id);
              this.saveQuizRequest(prepareQuiz);
      }
    } else {
      const prepareQuiz = this.prepareQuizParams(assignment_id);
      this.saveQuizRequest(prepareQuiz);
    }
  }

  closeQuiz = () => {
    this.setState({
      openQuizForm: false,
      edit_quiz: false,
      question_type: '',
      question_title: '',
      question_type_name: '',
      question_options_attributes: '',
      question_id: '',
      question_name_error: '',
      question_correct_answer_error: '',
      question_answer_error: ''
    });
  }

  updateQuiz = () => {
    const prepareQuiz = {
      assignment_id: this.state.assignment_id,
      question_title: this.state.question_title,
      question_type: this.state.question_type,
      question_options_attributes: this.state.question_options_attributes
    };

    Utility.sendRequest(`${CREATE_EDIT_QUESTIONS}/${this.state.question_id}`, 3, prepareQuiz, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.getQuestionList();
        this.setState({
          openQuizForm: false,
          edit_quiz: false
        });
      }
    });
  }

  getQuestionList = () => {
    const params = { assignment_id: this.state.assignment_id };
    Utility.sendRequest(CREATE_EDIT_QUESTIONS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({
          question_lists : body.data
        });
      }
    });
  }

  editQuiz = (item) => {
    switch (item.question_type) {
      case 1:
        this.setState({ question_type_name: 'Multiple Choice' });
        break;
        case 2:
        this.setState({ question_type_name: 'True Or False' });
        break;
      case 3:
        this.setState({ question_type_name: 'Essay' });
        break;
      default:
        break;
    }
    this.setState({
        question_type: item.question_type,
        openQuizForm: true,
        question_title: item.question_title,
        question_options_attributes: item.question_options_attributes,
        question_id: item.id,
        edit_quiz: true
    });
  }

  submitQuiz = () => {
    const { edit_quiz } = this.state;
    if (edit_quiz) {
      this.updateQuiz();
    } else {
      this.saveQuiz();
    }
  }

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: 2,
    width: 100
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "whitesmoke" : "white",
    ...draggableStyle
  });

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || (source.index === destination.index)) {
      return;
    }

    const { assignments } = this.state;
    const actualAssignmentList = assignments;

    const params = {
      sourceId: actualAssignmentList[source.index].seq_id,
      destinationId: actualAssignmentList[destination.index].seq_id
    };

    const assignment = Array.from(assignments);

    assignment[source.index].seq_id =  params.destinationId;
    assignment[destination.index].seq_id =  params.sourceId;
    assignment.sort((a, b) => {
      return a.seq_id - b.seq_id;
    });

    this.setState({ assignments: assignment });

    Utility.sendRequest(SWAP_ASSIGNMENT_IDS, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
}

  render() {
    const { openAssignment, assignments, openQuizForm } = this.state;
    return(
      <>
      {openAssignment &&
        <CreateAssignments
          {...this.state}
          openAssignment = {this.openAssignment}
          setAssignment = {this.setAssignment}
          saveAssignment = {this.saveAssignment}
          openQuiz = {this.openQuiz}
          handleChange = {this.handleChange}
          saveQuiz= {this.saveQuiz}
          questionChange= {this.questionChange}
          editQuiz = {this.editQuiz}
          submitQuiz = {this.submitQuiz}
          onToggleClick = {this.onToggleClick}
          handleDropdown = {this.handleDropdown}
          closeQuiz = {this.closeQuiz}
         />}
      {!openAssignment && !openQuizForm &&
          <div className="py-5 w-100">
           {!Utility.isEmpty(this.state.lessonList) ?
           <>
           <div className="align-items-center font-weight-bold font-18 mb-4 d-inline-flex cursor-pointer" onClick = {() => this.openAssignment()}>
              <CircleOutlinedAddIcon mainClass="mr-3" /> Add Assignment
            </div>
            <div className="registants-table">
              <div className="table-responsive">
                <table className="table table-sm table-hover assignment-table-draggable">
                  <DragDropContext onDragEnd={this.onDragEnd}>
                  <thead>
                    <tr className="font-18">
                      <th className="border-top-0 border-bottom-0">Assignments </th>
                      <th className="border-top-0 border-bottom-0 icons-position">Q&amp;As</th>
                      <th className="border-top-0 border-bottom-0">
                        <div className="d-flex justify-content-between" >
                          Action
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId={`assignment`}>
                    {(provided, snapshot) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}  style={this.getListStyle(snapshot.isDraggingOver)}>
                  {assignments && assignments.length > 0 && assignments.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index} >
                    {(provided, snapshot) => (
                      <tr  {...provided.dragHandleProps} ref={provided.innerRef} {...provided.draggableProps} style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                      <td className="py-3">
                        <div className="align-items-center">
                          <DragIcon mainClass="mr-2 ml-1"/>
                          <div className="d-flex flex-column">
                            <span className="font-weight-bold font-18 text-dark">{item.assignment_name}</span>
                            <span className="font-14">{item.lesson}</span>
                          </div>
                        </div>
                      </td>
                      <td className="px-3 py-4 font-18">{item.qcnt}</td>
                      <td className="action-td-chat pt-4">
                        <div className="d-flex flex-nowrap align-items-center">
                          <Dropdown>
                            <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                              <span>
                                ...
                              </span>
                              <ChevronDownIcon/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu >
                              <Dropdown.Item onClick = {() =>  this.editAssignment(item)}>Edit</Dropdown.Item>
                              <Dropdown.Item onClick = {() =>  this.deleteAssignment(item)}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className="bg-primary rounded-circle lesson-img-arrow flex-center mr-3 cursor-pointer" >
                            <ChevronLeftIcon fillClass="fill-white" />
                          </div>
                        </div>
                      </td>
                    </tr>
                     )}
                    </Draggable>
                  ))}
                   {provided.placeholder}
                  </tbody>
                  )}
                  </Droppable>
                  </DragDropContext>
                </table>
              </div>
            </div>
            </>
            : <div>
              Create course lessons to add an assignment.
            </div> }
          </div>
        }
      </>
    );
  }
}

const mapActionToProps =  { showLoader };

const mapStateToProps = (state) => ({
  userData: state.userData,
  publicUserData: state.publicUserData,
  neighborhoodState: state.neighborhoodState,
  isLoading: state.isLoading,
  isChatFormOpen: state.isChatFormOpen,
  seeAll: state.seeAll,
  updateLessons: state.updateLessons
});

export default connect(mapStateToProps, mapActionToProps)(Assignment);
