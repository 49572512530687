import React from 'react';
import moment from 'moment';
import Avatar from 'react-avatar';

export const SingleCampaign = (props) => {

  const paintCreatedAt = (createdAt) => {
    const date = moment(createdAt).format('ll');
    return `${date}`;
  };

  const paintDiv = () => {
    return(
      props.id ?
      <div className="border-top existing-article-outer d-flex py-3 cursor-pointer align-items-center"
        onClick={() => props.handleClick(props.id)}>
        {paintInnerDiv()}
      </div>
      :
      <div className="border-top existing-article-outer d-flex py-3 cursor-pointer align-items-center"
        onClick={props.handleClick}>
        {paintInnerDiv()}
      </div>
    );
  };

  const paintInnerDiv = () => {
    return(
      <>
        <div className="existing-article-img-outer">
          {/* {
            ((props.imageUrl) || !props.id) &&
            <img src={props.imageUrl || require("assets/images/users/user_no_image.png")} className="existing-article-img bb"  />
          } */}
          { props.imageUrl ? 
              <img src={props.imageUrl} className="existing-article-img"  />
            : 
             <Avatar
              name={props.heading}
              round={15}
              size={'50'}
              textSizeRatio={1.75}
              color='#007bb3'
              maxInitials={1}
              className="existing-article-avatar"
            />
            
          }
        </div>
        <div className="existing-article-body ml-3" >
          <div className="existing-article-heading font-bold font-15 text-break" >
            <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={props.heading}>
              {(props.heading.replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, "").replace(/^(.{20}[^\s]*).*/, "$1")) || ''}
            </span>
          </div>
          {
            props.id &&
            <div className="font-12 text-muted" >
              {paintCreatedAt(props.createdAt)}
            </div>
          }
        </div>
      </>
    );
  };

  return(
    paintDiv()
  );
};
