import React from 'react';
import { Table } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { NoRecordFound } from 'shared/components/no_record_found';
import TableRowsComm from './table_rows_comm';
import { Utility } from 'utils';

export default function TableListComm(props){
  const showLoader = props.showLoader;

  return (
    <>
    <Table striped responsive size="sm" className="shar-table">
      <TableTitles
      tableTitles={props.tableTitles}/>
    {
      props.data && !showLoader &&
      <TableRowsComm
        data={props.data}
        onActionClick={props.handleAction}
        userData={props.userData}
        actions={props.actions}
        handleClick={props.handleClick}
        selectedTab={props.selectedTab}
      />
    }
    </Table>
    {
      Utility.isEmpty(props.data) && !showLoader &&
      <NoRecordFound />
    }
    {
      showLoader &&
      <span className="justify-content-center"><Spinner animation="border" role="status"/></span>
    }
    </>
  );
}

export const TableTitles = props => {
  return (
    <>
    <thead>
          <tr>
            {
              props.tableTitles.map((item, index) => {
                return(
                  <th className="border-top-0 border-bottom-0" key={index}>{item}</th>
                );
              })
            }
          </tr>
      </thead>
    </>
  );
};
