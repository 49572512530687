import React from 'react';
import { InputField } from 'shared/components/form_elements';
import { Form } from 'react-bootstrap';
import { Utility } from 'utils';
import { toast } from 'react-toastify';
import { GET_FOLLOWERS_FOLLOWING } from 'config/constants/api_endpoints';
import { FOLLOWERS_FOLLOWING } from 'config/constants';
import { NoRecordFound } from 'shared/components/no_record_found';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateIsChatFormOpen, updateChatFriend } from 'shared/actions';

 class FollowersFollowing extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      follower: [],
      following: [],
    };
  }

  componentDidMount() {
    if(this.props.userClientId) {
      this.getApiResult();
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.userClientId !== this.props.userClientId){
      if(this.props.userClientId) {
        this.getApiResult();
      }
    }
  }

  getApiResult = () => {
    const params = { userClientId : this.props.userClientId, type: this.props.type, clientId: this.props.clientId } ;

    Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.setState({ [params.type]: body[params.type] });
      }
    });
  }

  handleSearchChange = (e) => {
  const params = { type: this.props.type, search: e.target.value, userClientId: this.props.userClientId, clientId: this.props.clientId  };

   if(e.target.value){
     Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 1, params, (error, response, body) => {
       body = JSON.parse(body);

       if (!body.error) {
         this.setState({ [params.type]: body[params.type] });
       }
     });
   }else {
     this.getApiResult();
   }
  }

  setDetailsFollowerfollowing = (followingId) => {
    const params = { followerId: followingId, followingId: this.props.userClientId };

    Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }else{
        this.getApiResult();
      }
    });

  }

  getPlaceHolder = () => {
    const { type } = this.props;

    return `Search ${FOLLOWERS_FOLLOWING[type]} ...`;
  }

  handleOnClick =(id) => {
    this.setDetailsFollowerfollowing(id);
  }

  followerFollowingLength = () => {
    const { type } = this.props;
    const { follower, following } = this.state;

    if(type === 'follower') {
      return follower.length;
    }else if(type === 'following'){
      return following.length;
    }
  }

  noResultFound = () => {
    return (
      <div className="follower-info" >
          <div className="align-items-center border-top justify-content-center" >
          <NoRecordFound/>
          </div>
      </div>
    );
  }

  checkIcon = (item) => {
    if(this.props.type === 'follower') {
      return !item.is_followed && <img
         onClick={() => this.handleOnClick(item.id)}
         src={require('assets/images/icons/person_plus.svg')}
         className="mr-4" alt=""
        />;
    }else if(this.props.type === 'following'){
      return '';
    }
  }

  profileImage = (item) => {
    return (
        <div className="align-items-center" >
        <Link to={'/profile'} key={item.id}>
          <img src={item && item.profile_image_url
          ? item.profile_image_url
          : require("assets/images/users/user_no_image.png")}
          className="rounded-circle img-product"  alt=""/>
        </Link>
          <div className="lh-1 ml-10 mx-2" >
            <div className="font-bold display-7 mb-2 text-truncate">
              {Utility.splitString(item.user_name, 15)}
            </div>
          <div className="font-med font-14 text-truncate">Content Creator</div>
        </div>
      </div>
  );}

  profile = (item) => {
   return (
     <div className="follower-info" key={item.id} >
         <div className="align-items-center justify-content-between border-top border-bottom py-3" >
            {this.profileImage(item)}
           <div className="mr-20">
           <img
             onClick={() => {
               this.props.updateIsChatFormOpen(true);
               this.props.updateChatFriend(this.props.item);
             }}
             src={require('assets/images/icons/chat_icon_dark.svg')}
             className="mr-3 community-chat-icon"
             alt="" />
           </div>
         </div>
     </div>
   );
  }

  profileId = (item) => {
    return (
      <div className="follower-info" key={item.id} >
          <div className="align-items-center justify-content-between border-top border-bottom py-3" >
          <Link to={{
            pathname: `/profile/${item.id}`,
            state: { followUnfollow: true }
          }} key={item.id}>
            {this.profileImage(item)}
           </Link>
            <div className="mr-20">
              { item && this.checkIcon(item)}
              <img
                onClick={() => {
                  this.props.updateIsChatFormOpen(true);
                  this.props.updateChatFriend(item);
                }}
                src={require('assets/images/icons/chat_icon_dark.svg')}
                className="mr-3 community-chat-icon"
                alt="" />
            </div>
          </div>
      </div>
    );
  }

  cardList = () => {
    const listitems = this.state[this.props.type];
    const id = this.props.userData.id;

    return listitems.map(item =>
      (item.id === id) ? this.profile(item) : this.profileId(item));
  }

  render(){
    const { follower, following } =  this.state;

    return(
      <>
        <div className="followers-outer" >
          <h1 className="font-bold display-6 mb-4" >{FOLLOWERS_FOLLOWING[this.props.type]}
          <span className="font-light-italic" >
            {(follower || following) && ` (${this.followerFollowingLength()})`}
          </span></h1>
          <Form className="shar-form seacrh-form" >
            <Form.Group>
              <InputField
                required={true}
                type="text"
                placeholder={this.getPlaceHolder()}
                handleChange={this.handleSearchChange}
              />
            </Form.Group>
          </Form>
          <div className="py-3" >
            {(follower.length || following.length) ?
              this.cardList() :  this.noResultFound()}
          </div>
        </div>
      </>
    );
  }
}

const mapActionToProps =  { updateIsChatFormOpen, updateChatFriend };

const mapStateToProps = (state) => ({
  userData: state.userData
});

export default connect(mapStateToProps, mapActionToProps)(FollowersFollowing);
