import React, { Component } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { ATTRIBUTES, TAGS } from 'config/constants/api_endpoints';
import * as Utility from 'utils/utility';
import { DropDown, CheckboxNew, MultiDropDown } from 'shared/components/form_elements';
import InputRange from 'react-input-range';
import { toast } from 'react-toastify';
import { FiFilter } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import "react-input-range/lib/css/index.css";

export default class FilterProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagList: [],
      attributeList: [],
      selectedTagFilters: this.props.selectedFilterTags,
      toggle: this.props.toggleStatus,
      initialRangeValue: { min: 0, max: 0 },
      value: { min: 0, max: 0 },
      isRangeDirty: false,
      showLoader: true
    };

    this.newAttributeFilter = !Utility.isEmpty(this.props.selectedFilters) ? this.props.selectedFilters : {};
  }

  componentDidMount = () => {
    this.getTagList();
    this.getAttributes();
  }

  isAnyFilterSelected = () => {
    let flag = false;
    if (this.newAttributeFilter) {
      Object.keys(this.newAttributeFilter).map((e) => {
        if(Object.values(this.newAttributeFilter[e]).length > 0){
          flag = true;
        }
      });
    }
    return flag;
  }

  getTagList = () => {
    const params = { client_id: this.props.userData.Client.id, order: 'tag_name' };
    Utility.sendRequest(TAGS, 1, params, (err, response, body) => {
      body = JSON.parse(body);
      if(!body.error) {
        this.setState({ tagList: body.data });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  getAttributes(){
    const params = {
      client_id: this.props.userData.Client.id,
    };

    Utility.sendRequest(ATTRIBUTES, 1, params, (err, response, body) => {
      body = JSON.parse(body);

      this.setState({ showLoader: false });

      if(!body.error) {
        this.setState({ attributeList: body }, () => {
          this.state.attributeList.map(item => {
            const value = {};
            if (item.web_field_type === 'range') {
              this.props.updateSelectedFilterId(item.id, item.web_field_type);
              value.min = parseInt(item.min_value);
              value.max = parseInt(item.max_value);
              this.setState({ initialRangeValue: value, value });
              this.props.updateValueState(value);
            }
          });
        });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  handleRangeFilter = (value, id, name, rate, maxValue) => {
    const valueState = JSON.parse(JSON.stringify(this.state.value));

    if (this.state.toggle) {
      valueState.min = (valueState.min / rate).toFixed();
      valueState.max = (valueState.max / rate).toFixed();

      if (valueState.max > maxValue) valueState.max = maxValue;
    }

    const object = {
      id,
      value: [valueState.min, valueState.max]
    };

    this.newAttributeFilter["range"] = { [object.id] : object.value };

    if(this.state.isRangeDirty &&
      (object.value[0] === this.state.initialRangeValue.min &&
      object.value[1] === this.state.initialRangeValue.max))
      delete this.newAttributeFilter['range'];

    this.props.handleSelectedAttributeFilters(this.newAttributeFilter);
    this.setState({ isRangeDirty: true });
  }

  handleDropdownFilter = (ev) => {
    if (ev && ev.length) {
      this.newAttributeFilter['dropdown'] = {
        ...this.newAttributeFilter['dropdown'],
        ...{ [ev[0].id] : ev.map(e => {
            return e.value;
        })
      }
      };
    }else{
      delete this.newAttributeFilter['dropdown'];
    }

    this.props.handleSelectedAttributeFilters(this.newAttributeFilter);
  }

  handleAttributeFilter = (ev) => {
    const selectedAttributeFilters = Utility.getSelectedOptions(ev);

    if (selectedAttributeFilters.length) {
      this.newAttributeFilter[ev.target.type] = {
        ...this.newAttributeFilter[ev.target.type],
        ...{ [selectedAttributeFilters[0].id] : selectedAttributeFilters.map(e => {
            return e.value;
        })
      }
    };
    }
    else {
      delete this.newAttributeFilter[ev.target.type][ev.target.id.split("_")[0]];

      if (Object.keys(this.newAttributeFilter[ev.target.type]).length === 0) {
        delete this.newAttributeFilter[ev.target.type];
      }
    }

    this.props.handleSelectedAttributeFilters(this.newAttributeFilter);
  }

  filterProductList = (filterId, filterType = '', index = -1) => {
    const selectedFilters = [...this.state.selectedTagFilters];
    const tagFilter = document.getElementById(`tagFilter${filterId}`);

    if (selectedFilters.includes(filterId)) {
      const index = selectedFilters.indexOf(filterId);

      if (index > -1) {
        selectedFilters.splice(index, 1);

        if (filterType === 'tags') {
          tagFilter.classList.remove('active');
        }
      }
    } else {
      selectedFilters.push(parseInt(filterId));

      if (filterType === 'tags') {
        tagFilter.classList.add('active');
      }
    }

    this.setState({
      selectedTagFilters: selectedFilters
    }, () => { this.props.updateSelectedTagsFilter(this.state.selectedTagFilters); });
  }

  onToggleClick = (event, rate) => {
    const value =  { ...this.state.value };

    value.max = event.target.checked ? (value.max * parseFloat(rate)).toFixed() : (value.max / parseFloat(rate)).toFixed();

    this.props.updateToggleState(event.target.checked);

    this.setState({ toggle: event.target.checked ? true : false, value });
  }

  handleFilterClose = () => {
    this.props.updateSelectedFilters(this.newAttributeFilter);
    this.props.close();
  }

  getSelected = (arg, attId, value = null) => {
    switch (arg) {
      case 'checkbox': {
        if(this.isAnyFilterSelected() &&
        this.newAttributeFilter.checkbox &&
        this.newAttributeFilter.checkbox[attId])
        return this.newAttributeFilter.checkbox[attId].includes(value);
        break;
      }
      case 'select-many': {
        if(this.isAnyFilterSelected() &&
        this.newAttributeFilter.dropdown &&
        this.newAttributeFilter.dropdown[attId])
        return this.newAttributeFilter.dropdown[attId];
        break;
      }
      case 'range': {
        if(this.isAnyFilterSelected() &&
        this.newAttributeFilter.range &&
        this.newAttributeFilter.range[attId]
      ){
          const a =  {
            min: parseInt(parseInt(this.newAttributeFilter.range[attId][0]) * (this.state.toggle ? 1.34 : 1)),
            max: parseInt(parseInt(this.newAttributeFilter.range[attId][1]) * (this.state.toggle ? 1.34 : 1))
          };

          return a;
        }
        else{
          return this.state.value;
        }
      }
      case 'tags': {
        if (!Utility.isEmpty(this.state.selectedTagFilters)) {
          this.state.selectedTagFilters.forEach((item) => {
            const tagFilter =  Utility.getElementById(`tagFilter${item}`);

            if (tagFilter) tagFilter.classList.add('active');
          });
        }
      }
    }
  }

  render() {
    return(
      <>
       <div className="filter-side-bar h-md-100 scroll-y top-on-sm bg-white py-4  px-4 position-absolute side-modal-inner col-xl-4 col-lg-8 col-12">
        <div className="side-modal-header align-items-center justify-content-between mb-2">
          <h5 className="font-bold mb-0 align-items-center">  <FiFilter className="mr-2" /> Filter Options</h5>
          <Button
            variant="link"
            className="close-btn-position side-modal-close"
            onClick={this.handleFilterClose}>
              <MdClose/>
          </Button>
        </div>
        {
          this.state.showLoader
          ? <center><Spinner animation="border" role="status"/></center>
          : <>
            {
              this.state.attributeList.map(e => {
                return(
                  <Form.Group className="filter-section mb-4" key={e.id} controlId={e.id}>
                  <div className="d-flex justify-content-between">
                    <h6 className="font-regular">{e.title}</h6>
                    {
                      !Utility.isEmpty(e.conversion_rate) &&
                      <div className="toggle-switch">
                        <input type="checkbox" id="chkTest" name="chkTest" checked={this.state.toggle} onClick={(ev) => this.onToggleClick(ev, e.conversion_rate)}/>
                        <label htmlFor="chkTest" className="d-inline-flex" >
                          { e.conversion_name } <span className="toggle-track mx-2" /> {e.title}
                        </label>
                      </div>
                    }
                  </div>
                    {
                      e.web_field_type === 'select-one' &&
                      <DropDown
                        required={true}
                        optionId="id"
                        optionValue="name"
                        isMultiple={false}
                        name={e.title}
                        handleChange={(ev) => this.handleAttributeFilter(ev, e.title)}
                        options={(ev) => this.handleAttributeFilter(ev, e.title)}
                      />
                    }
                    {
                      e.web_field_type === 'select-many' &&
                      <MultiDropDown
                        id={e.id}
                        isMulti={true}
                        options={e.attribute_values}
                        placeholder={"Select " + e.title}
                        defaultValues = {this.getSelected("select-many", e.id)}
                        handleChange={(ev) => this.handleDropdownFilter(ev, e.title)}
                      />
                    }
                    {
                      e.web_field_type === 'range' &&
                      <>
                        <div className="input-range__track-max-w">
                          <InputRange
                            id={2}
                            type={e.web_field_type}
                            name={e.id}
                            minValue={parseInt(e.min_value)}
                            maxValue={parseInt(e.max_value * (this.state.toggle ? e.conversion_rate : 1))}
                            value={this.getSelected("range", e.id)}
                            onChange={value => this.setState({ value })}
                            onChangeComplete={(ev) => this.handleRangeFilter(ev, e.id, e.title, e.conversion_rate, e.max_value)}
                          />
                        </div>
                      </>
                    }
                    {
                      e.web_field_type === 'checkbox' && <>
                        {e.attribute_values.map(element => {
                          return(
                            <CheckboxNew
                              required={true}
                              key={element.id}
                              id={e.id + "_" + element.id}
                              name={e.title}
                              label={element.name}
                              value={element.id}
                              checked={this.getSelected("checkbox", e.id, element.id)}
                              handleChange={(ev) => this.handleAttributeFilter(ev, e.title)}
                            />
                          );
                        })}
                      </>
                    }
                  </Form.Group>
                );
              })
            }
            <div className="filter-section mb-4">
              <h6 className="font-regular">Tag Selector</h6>
              <div className="tag-panel">
                {
                  this.state.tagList.map((tag, index) => {
                    return (
                      <div className="tag-name" key={"tag" + index}
                        id={`tagFilter${tag.id}`}
                        checked={this.getSelected("tags", tag.id)}
                        onClick={() => { this.filterProductList(parseInt(tag.id), 'tags', index); }}>{tag.tag_name}</div>
                    );
                  })
                }
              </div>
            </div>
          </>
        }
        </div>
      </>
    );
  }
}
