import React, { Component } from 'react';
import { Col, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
// import { FaSistrix } from "react-icons/fa";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { MESSAGES, MESSAGE_MARK_AS_READ } from 'config/constants/api_endpoints';
import { Textarea } from 'shared/components/form_elements';
import { Utility, Date } from 'utils';
import Avatar from "react-avatar";

class ConversationOld extends Component {
  constructor(props) {
    super(props);
    this.socket = null;
    this.state = {
      messages: [],
      currentMessage: {},
      chatPrivateKey: '',
      showLoader: true
    };

    if (Utility.isEmpty(props.userData)) {
      props.history.replace("/communications");
    } else {
      this.getAllMessages();
      this.markAsRead();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.userData && prevProps.friend.id !== this.props.friend.id) {
      this.setState({ messages: [], showLoader: true });
      this.getAllMessages();
      this.markAsRead();
    }
  }

  componentDidMount = () => {
    this.props.onRef(this);
  }

  componentWillUnmount = () => {
    this.props.onRef(undefined);
  }

  updateMessages = (data) => {
    if (this.state.chatPrivateKey !== '') {
      data.message = Utility.decryptMessages(data.message, this.state.chatPrivateKey, this.props.friend.private_key, this.props.userData.private_key);
      this.updateMessageList(data);
    } else {
      this.getAllMessages();
    }

    this.markAsRead();
  }

  markAsRead = () => {
    const params = {
      is_read: true,
      qs: {
        to_user_client_id: this.props.userData.id,
        from_user_client_id: this.props.friend.id
      }
    };

    Utility.sendRequest(MESSAGE_MARK_AS_READ, 3, params, (error, response, body) => {
      body = JSON.parse(body);
      if (body.error)
        toast.error(body.error.message, { containerId: 'private' });
    });
  }

  getAllMessages = () => {
    const params = {
      to_user_client_id: this.props.friend.id,
      from_user_client_id: this.props.userData.id,
      isGroup: false
    };

    Utility.sendRequest(MESSAGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      let messages = [];
      let chatPrivateKey = '';

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if (body.data.length > 0) {
            messages = Utility.decryptMessages(body.data[0].messages, body.data[0].chat_private_key, this.props.friend.private_key, this.props.userData.private_key);
            chatPrivateKey = body.data[0].chat_private_key;
        }
      }

      this.setState({ messages: messages, chatPrivateKey: chatPrivateKey, showLoader: false }, () => {
        this.scrollMessages();
      });
    });
  }

  updateMessageList = (msg) => {
    if (this.state.chatPrivateKey !== '') {
      const messages = this.state.messages;
      messages.push(msg);

      this.setState({ messages }, () => {
        this.scrollMessages();
      });
    } else {
      this.getAllMessages();
    }
  }

  scrollMessages = () => {
    var element = document.getElementById("messageList");
    if(element !== null) element.scrollTop = element.scrollHeight;
  }

  render() {
    const friend = this.props.friend;

    return (
      <React.Fragment>
        <Col xs={12} lg={this.props.isRecentChatView ? 4 : 8} xl={this.props.isRecentChatView ? 6 : 9}  className="h-100 direct-message-container p-4">
          <div className="overflow-hidden h-100">
            <div className="pb-3 pt-sm-3 px-sm-3">
              <div className="d-flex flex-wrap align-items-center">
                <Col md={4} sm={2} className="px-0">
                  { !this.props.isRecentChatView &&
                    <Button variant="link" onClick={this.props.goBack} className="color-primary text-left"><img className="width-10 align-baseline mr-3" src={require("assets/images/chevron_left.svg")} alt="left-icon" />
                    <span className="display-xl-7 display-8 font-medium">Back</span><span className="display-web display-xl-7 display-8 font-medium"> to Direct Messages</span></Button>
                  }
                </Col>
                <Col md={this.props.isRecentChatView ? 12 : 4} sm={this.props.isRecentChatView ? 12 : 8} className="member-status">
                  <div className="d-flex align-items-center py-2 justify-content-center">
                    {/* <div className="icon-active mr-1"></div> */}
                    <img className="sm-round-circle" src={friend.image_url} alt=""/>
                    <div className="font-bold display-xl-6 display-7 pl-2">{friend.name}</div>
                  </div>
                </Col>
                <div className="display-6">
                  {/* <Button variant="link" className="px-2"><FaSistrix/></Button>
                  <Button variant="link" className="px-2"><img className="width-20" src={require("assets/images/storychat_icon.svg")} alt="storychat -icon" /></Button>
                  <Button variant="link" className="px-2"><img className="width-20" src={require("assets/images/comment.svg")} alt="left-icon" /></Button>
                  <Button variant="link"className="px-2"><img className="width-20" src={require("assets/images/groups_icon.svg")} alt="group-icon" /></Button>
                  <Button variant="link" className="px-2"><img className="width-20" src={require("assets/images/info_icon.svg")} alt="info-icon" /></Button>
                  <Button variant="link" className="px-2"><img className="width-20" src={require("assets/images/menu_vertical.svg")} alt="menu-icon" /></Button> */}
                </div>
                {/* <Button variant="link" className="tool">
                  <div className="tool-container text-center p-1">
                    <img src={require("assets/images/tip_icon.svg")} alt="tool-icon" />
                    <div className="text-white font-12">Tools</div>
                  </div>
                </Button> */}
              </div>
            </div>
            <div className="border-bottom ml-sm-3"></div>
            <div className="chat-details mgs-header-padding message-box-view px-0">
              { this.state.showLoader
                ? <center><Spinner animation="border" role="status"/></center>
                : <MessageList key={friend.id} friend={friend} userData={this.props.userData} messages={this.state.messages}/>
              }
              <SendMessage friend={friend} userData={this.props.userData} updateMessageList={this.updateMessageList}
                chatPrivateKey={this.state.chatPrivateKey}/>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData
});

export default connect(mapStateToProps, {})(ConversationOld);

class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { currentMessage: '' };
  }

  sendMessage = (e) => {
    e.preventDefault();
    const message = this.state.currentMessage;

    if(!message || message.trim() === '')  return;

    this.setState({ currentMessage: '' }, () => { this.autoresize(); });

    let senderPrivateKey = this.props.userData.private_key;
    let receiverPrivateKey = this.props.friend.private_key;

    if (this.props.chatPrivateKey !== '' && senderPrivateKey !== this.props.chatPrivateKey) {
      receiverPrivateKey = senderPrivateKey;
      senderPrivateKey = this.props.chatPrivateKey;
    }

    const encryptedMessage = Utility.encryptedMessage(message, senderPrivateKey, receiverPrivateKey);

    const params = {
      from_user_client_id: this.props.userData.id,
      to_user_client_id: this.props.friend.id,
      new_message: this.props.chatPrivateKey === '', //this.props.newMessage,
      media: '',
      message: encryptedMessage,
      user_id: this.props.userData.id,
      source: this.props.friend.isAudience ? 'audience' : 'network',
      chat_private_key: senderPrivateKey
    };

    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        this.setState({ currentMessage: message });

        toast.error(body.error.message, { containerId: 'private' });
      } else {
        body.message = message;
        this.props.updateMessageList(body);
      }
    });
  }
  onHandleChange = (e) => {
    this.autoresize();
    this.setState({ currentMessage: e.target.value });
  }

   autoresize = () => {
    const elements = document.getElementsByName('text_message');

    if(!(elements && elements.length > 0)) return;

    const element = elements[0];
    element.style.height = '29px';
    element.style.height = element.scrollHeight + 'px';
    element.scrollTop = element.scrollHeight;
    window.scrollTo(window.scrollLeft, (element.scrollTop + element.scrollHeight));
}

  onKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      this.sendMessage(event);
    }
  }

  render() {
    return(
      <Form className="input-msg-box shar-form">
        <InputGroup>
          {/* <InputGroup.Prepend>
            <InputGroup.Text id="inputGroupPostpend"><img className="" src={require("assets/images/arrow-o-circle-left.svg")} alt="arrow-icon" /></InputGroup.Text>
          </InputGroup.Prepend> */}
          <Textarea required={true} type="text" name="text_message" ref="text_message"
            placeholder="Type something here…"
            handleChange={this.onHandleChange}
            value={this.state.currentMessage}
            onKeyDown={this.onKeyDown}
            autoresize={"true"}
            className="scroll-y"
          />
          <div className="send-icons-position">
            {/* <Button variant="link"><span className="pr-2">
              <img className="width-20" src={require("assets/images/happiness.svg")} alt="emoji-icon" /></span></Button> */}
            <Button variant="link" onClick = {this.sendMessage}>
              <span><img className="width-20"  src={require("assets/images/send_icon.svg")} alt="send-icon"/></span>
            </Button>
          </div>
        </InputGroup >
      </Form>
    );
  }
}

const MessageList = (props) => {
  return(
    <Col xs={12} className="p-0 h-100 scroll-y pl-3" id="messageList">
      <div className="mt-4"/>
      { props.messages.map((message) => {
          const imageUrl = message.from_user_client_id === props.friend.id ?
          props.friend.image_url : props.userData.profile_image_url;
          const name = message.from_user_client_id === props.friend.id ? props.friend.name : (props.userData.first_name + ' ' + props.userData.last_name);
          return(
            /*<div key={'ms' + message.id} className="media mb-3 pr-lg-5">
              <div className="media-left">
                <img className="chat-circle-medium" src={imageUrl} alt=""/>
              </div>
              <div className="media-body pl-3">
                <h6 className="font-bold display-xl-7 display-8 pr-2">{name}
                  <small className="display-10 ml-2 font-medium text-time">{Date.localTime(message.updated_at)}</small>
                </h6>
                <p>{message.message}</p>
              </div>
            </div>
            */
            <div key={'ms' + message.id} className="messages">
              <ul>
                {
                  message.from_user_client_id === props.friend.id ?
                  <li className="sent">
                    {
                      imageUrl ? 
                      <img src={imageUrl} alt="" /> :
                      <Avatar
                        name={name}
                        round={true}
                        color="#007bb3"
                        maxInitials={1}
                        size={"30"}
                        textSizeRatio={1.75}
                        className='float-left mt-2 mr-2'
                      />
                    }
                    <p>{message.message}</p>
                  </li> :
                  <li className="replies">
                    {
                      imageUrl ? 
                      <img src={imageUrl} alt="" /> :
                      <Avatar
                          name={name}
                          round={true}
                          color="#007bb3"
                          maxInitials={1}
                          size={"30"}
                          textSizeRatio={1.75}
                          className='float-right mt-2 ml-2'
                        />
                    }
                    <p>{message.message}</p>
                  </li>
                }
              </ul>
            </div>
          );
        })
      }
    </Col>
  );
};
