import React from 'react';
import { Table } from 'react-bootstrap';

const TableView = (props) => {
  const getKeys = () => { return Object.keys(props.data[0]); };

  const getHeader = () => {
    const keys = props.headers;

    return keys.map((key) => {
      return <th className="border-top-0 border-bottom-0" key={key}>{key}</th>;
    });
  };

  const getRowsData = () => {
    const items = props.data;
    const keys = getKeys();

    return items.map((row, index) => {
      return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>;
    });
  };

  return (
    <div>
      <Table striped responsive size="sm" className="shar-table">
        <thead>
          <tr>{getHeader()}</tr>
        </thead>
        <tbody>
          {getRowsData()}
        </tbody>
      </Table>
    </div>
  );
};

const RenderRow = (props) => {
  return props.keys.map((key) => {
    return <td key={props.data[key]}>{props.data[key]}</td>;
  });
};

export default TableView;
