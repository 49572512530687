import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';

class ClientGoals extends Component {
  render() {
    const clientGoals = this.props.clientGoals.map(clientGoal => clientGoal.sustainable_development_goal_id);
    return (
      <div className="container-fluid dev-goals px-0">
        <h6 className="display-9">Sustainable Development Goals:</h6>
        <ul className="row no-gutters cleint-goals-width">
          {
            this.props.sdGoals.map((item) => {
              return (<li className="col" key={item.id}>
                  <Tooltip title={item.goal_name}>
                  {
                    clientGoals.includes(parseInt(item.id))
                    ? <div className="col-cont" style={{ "backgroundColor": item.color_code }}/>
                    : <div className="bg-grey col-cont"> </div>
                  }
                  </Tooltip>
              </li>);
            })
          }
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sdGoals: state.sdGoals,
});

export default connect(mapStateToProps, {})(ClientGoals);
