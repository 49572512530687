import React, { useEffect, useState } from "react";
import { Discussion } from "./Discussion";
import { Discussions } from "./CommentMockdata";
import { MdForum } from "react-icons/md";
import RichTextEditor from "../../../rich_text_editor";
import * as Utility from "utils/utility";
import { COMMENT_TOOL_BAR_OPTION } from "../../../../../config/constants";
import CommentTool from "./CommentTool";
import { toast } from "react-toastify";
import { GET_DISCUSSION_COMMENTS } from "../../../../../config/constants/api_endpoints";
import { showLoader } from "../../../../actions";
import { useDispatch } from "react-redux";
const UserCommentContainer = (props) => {
  const [commentData, setcommentData] = useState("");
  const [imageData, setImageData] = useState(null);
  const [video, setVideoData] = useState(null);
  const [StartDiscuss, setStartDiscuss] = useState(true)
  const [postAndComments, setPostAndComments] = useState([]);
  const formName = "add_primary_product";
  const changeDescription = (data, id) => {
    var dataHash = commentData;
    dataHash = data;
    Utility.isRichTextEmpty(id, data, formName);
    setcommentData(dataHash);
  };
  const dispatch = useDispatch()

  const getCommentData = () => {
    const params = { lesson_id: props.ActiveLessonId };
    dispatch(showLoader(true))
    Utility.sendRequest(
      GET_DISCUSSION_COMMENTS,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          dispatch(showLoader(false))
        } else {
          if(body.data.length === 0)
          {
            setStartDiscuss(false)
          }
          setPostAndComments(body.data);
          
          dispatch(showLoader(false))

        }
      }
    );
  };


  useEffect(() => {
    getCommentData();
  }, [props.ActiveLessonId]);

  const handleFileInput = (e) => {
    return;
  };

  const handleStartDiscuss = () => {
    setStartDiscuss(!StartDiscuss)
  }
  
  return (
    <>
      <div className="course_panal_header d-flex">
        <MdForum className="courseSidebarIcon" />
        <div className="header_title">Discussions</div>
        {postAndComments && postAndComments.length === 0 && <button
              className="btn btn-primary btn-half-rounded ml-auto px-5 font-weight-bold"
              onClick={() => handleStartDiscuss()}
            >
              Start Discussion
            </button>}
      </div>
      {postAndComments && postAndComments.length > 0 &&
        postAndComments.map((data) => {
          return (
            <div>
              <Discussion  getCommentData={getCommentData} commentData={data} />

              {data.reply && data.reply.length > 0 &&
              data.reply.map((comments, index) => {
                return (
                  <div className="post-comments ml-auto">
                    <Discussion getCommentData={getCommentData} commentData={comments} />
                    {comments.reply &&
                      comments.reply.length > 0 &&
                      comments.reply.map((reply) => {
                        return (
                          <div className="comment-replies">
                            <Discussion hideReplis={true} getCommentData={getCommentData} commentData={reply} />
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          );
        })}
      
      {StartDiscuss && <div className="comment-input w-75 mx-auto">
        <RichTextEditor
          id="comment"
          name="comment"
          placeholder="Start a Conversation ....."
          data={commentData}
          changeDescription={changeDescription}
          isComment={true}
          toolbar={COMMENT_TOOL_BAR_OPTION}
        />
        <CommentTool parent_id ={props.ActiveLessonId} getCommentData={getCommentData} commentData={commentData} changeDescription={changeDescription}/>
      </div>}
    </>
  );
};

export default UserCommentContainer;
