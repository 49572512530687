import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { InputField, DropDown } from 'shared/components/form_elements';
import { Utility } from 'utils';

const validateFields = ['brand_id', 'card_number', 'pin_number', 'card_balance'];

export default class GiftCardForm  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      helpInfo: false,
      formData: {
        brand_id: '',
        card_number: '',
        pin_number: '',
        card_balance: '',
        community_id: ''
      },
    };
  }

  handleChange = (e) => {
    let formData = this.state.formData;

    formData = Utility.validateAndReturnFormData(this, formData, e, validateFields);

    this.setState({ formData });
  }

  submit = (e) => {
    e.preventDefault();
    const formData = this.state.formData;

    if (!Utility.showFormErrors(this, validateFields)) return;

    this.props.saveGiftCard(formData);
  }

  render(){
    const giftCardBrands = [ ...this.props.giftCardBrands ] ;
    const beneficiaries = [ ...this.props.beneficiaries ];

    giftCardBrands.unshift({ brand_id: '', brand_name: "Select Card Type" });

    if(Utility.isEmpty(this.props.beneficiaryNickName)) {
      beneficiaries.forEach((value) => { value.community_name = value.Client.name; });
      beneficiaries.unshift({ community_id: '', community_name: "Select Beneficiary" });
      validateFields.push('community_id');
    }

    return(
      <div className="giftCard-container mx-auto">
        <div className="giftCard-donate text-center border-radius-20">
          <div className="giftCard-donate-header p-4">
            <img src={require("assets/images/handle.png")} alt="giftCard handle" />
            <h1 className="my-3"  style={{ color: this.props.colorCode }}>DONATE <br/> GIFT CARD</h1>
            <p className="font-medium" >Donate the value of <br/> your gift card to benefit</p>
            {
              !Utility.isEmpty(this.props.beneficiaryNickName) &&
              <div className="text-center col-sm-12">
                <div className="" style={{ fontSize: '18px' }}>{this.props.beneficiaryNickName}</div>
              </div>
            }
          </div>
          <div className="giftCard-donate-body p-4 border-radius-20" style={{ background: this.props.colorCode }}>
            <Form className="giftCard-form mx-auto shar-form">
              {
                (Utility.isEmpty(this.props.beneficiaryNickName) && !Utility.isEmpty(this.props.beneficiaries)) &&
                <Form.Group controlId="">
                  <DropDown
                    required={true}
                    name="community_id"
                    ref="community_id"
                    options={beneficiaries}
                    optionId="community_id"
                    optionValue="community_name"
                    handleChange={this.handleChange}
                  />
                </Form.Group>
              }
              { !Utility.isEmpty(giftCardBrands) &&
                <Form.Group controlId="">
                  <DropDown
                    required={true}
                    name="brand_id"
                    ref="brand_id"
                    options={giftCardBrands}
                    optionId="brand_id"
                    optionValue="brand_name"
                    handleChange={this.handleChange}
                  />
                </Form.Group>
              }
              <Form.Group controlId=""  className="mt-3" >
                <InputField required={true} name="card_number" ref="card_number" handleChange={this.handleChange}
                  type="number" placeholder="Gift Cards #" value={this.state.formData.card_number}
                />
              </Form.Group>
              <Row className="giftCard-input-row no-gutters">
                <Col sm={4}>
                  <Form.Group controlId="">
                    <InputField required={true} name="pin_number" ref="pin_number" handleChange={this.handleChange}
                      type="number" placeholder="Pin #" value={this.state.formData.pin_number}
                    />
                  </Form.Group>
                </Col>
                <Col sm={7} className="offset-1">
                  <Form.Group controlId="">
                    <InputField required={true} name="card_balance" ref="card_balance" handleChange={this.handleChange}
                      type="number" placeholder="$ Estimated Balance" value={this.state.formData.card_balance}
                    />
                  </Form.Group>
                  <span className="giftCard-help position-absolute font-bold">
                    <span className="cursor-pointer display-8" onClick={() => {this.setState({ helpInfo: true }); }}>?</span>
                    <div className={"giftCard-helpInfo position-absolute p-3 font-12 text-left text-offline font-14 font-regular" + (this.state.helpInfo ? " d-block" : "")} id="helpInfo">
                      <div className="giftCard-helpInfo-close text-right mb-3 cursor-pointer" onClick = {() => {this.setState({ helpInfo: false }); }} >X</div>
                      <p>
                        Submit your best estimate. Our system will autocheck the actual card balance available for donation. Minimum estimated balance should be $5.
                      </p>
                      <p>By clicking Donate, I acknowledge and agree to the validation of the current balance on the card presented and affirm that the full balance on the card will be donated to the charity listed on this page. In addition, I affirm to forego any right to the redemption of the remaining value on this card and agree to destroy the card. An email confirmation will be sent to the address provided confirming your donation.
                      </p>
                    </div>
                  </span>
                </Col>
              </Row>
              <Button variant="text" type="submit" className="btn-validate" onClick={(e) => this.submit(e)}>Validate</Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
