import { css } from "@emotion/core";
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import { Utility } from "utils";
import {
  CAMPAIGNS,
  CATEGORIES_BY_MODULE_ID,
  CAMPAIGNS_BY_ID,
} from "config/constants/api_endpoints";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import { ChevronLeftIcon } from "shared/icons/icons";
import Section from "./sections";
import Assignment from "./assignment";
import { CourseForm } from "./course_form";
import { showLoader } from "shared/actions";
import ClipLoader from "react-spinners/ClipLoader";
import { GIL_CLIENT_ID, AHURA_CLIENT_ID } from "../../../config/constants";

const validateFields = {
  courses: [
    "embedded_image_link",
    "image_url",
    "category_id",
    "description",
    "heading",
  ],
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
`;

class CourseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "courses",
      categoriesList: [],
      formStates: this.initialState(),
      newCampaign: this.getFormData(),
      isLoading: this.props.isLoading,
    };
  }

  initialState = () => {
    return {
      selectedCampaignId: this.props.editCampaignId,
      showDropdown: false,
      isEdit: false,
      isCreate: true,
      isNewChap: false,
      isChapToggle: false,
      isCreateOrEdit: false,
      search: null,
      imageFile: "",
      imagePreviewUrl: "",
      videoFile: "",
      ranking: 10,
      videoPreviewUrl: "",
    };
  };

  getFormData = () => {
    const userData = { ...this.props.userData };

    return {
      heading: "",
      sub_heading: "",
      description: "",
      location: "",
      video_url: "",
      embedded_video_link: "",
      image_url: "",
      embedded_image_link: "",
      imageSignedURL: "",
      videoSignedURL: "",
      ranking: 10,
      parent_image_url: "",
      parent_heading: "",
      category_id: null,
      parent_id: null,
      non_profits_id: null,
      campaign_type_id: "39ab8d70-3d6b-4f3f-a702-8ead10a90bcf",
      author_name: userData.user_name,
      author_email: userData.email,
      author_id: userData.user_id,
      created_by: userData.user_id,
      publisher_network_id: CLIENT_ID,
      updated_by: userData.user_id,
      user_client_id: userData.id,
      schedule_start: new Date(),
      client_id: CLIENT_ID,
      non_profits: [],
      organizers: [],
      groups: [],
      is_public: true,
      corporate_id: userData.client_id || ""
    };
  };

  getCategoriesByModuleId = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      campaign_type_id: CLIENT_ID===GIL_CLIENT_ID?"39ab8d70-3d6b-4f3f-a702-8ead10a90bcf":this.props.campaignSelectedTab,
    };

    if (this.props.campaignSelectedTab !== "group") {
      Utility.sendRequest(
        CATEGORIES_BY_MODULE_ID,
        1,
        params,
        (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            this.setState({ categoriesList: body.data });
          }
        }
      );
    }
  };

  handleDropdown = (evKey) => {
    const formData = { ...this.state.newCampaign };
    formData[evKey.split(",")[0]] = evKey.split(",")[1];

    Utility.getElementById("category_idError").innerText = "";

    this.setState({
      newCampaign: formData,
    });
  };

  handleInputChange = (ev) => {
    let formData = { ...this.state.newCampaign };
    formData = Utility.validateAndReturnFormData(
      this.refs[this.state.formName],
      formData,
      ev,
      validateFields[this.state.formName],
      this.state.formName
    );
    this.setState({ newCampaign: formData });
  };

  changeDescription = (data) => {
    const dataHash = this.state.newCampaign;
    dataHash["description"] = data;

    this.setState({ newCampaign: dataHash });
  };

  getCampaignsById = () => {
    const formStates = { ...this.state.formStates };

    if (this.state.formStates.selectedCampaignId) {
      const params = {
        campaign_id: this.state.formStates.selectedCampaignId,
        client_id: CLIENT_ID,
        user_client_id: this.props.userData.id,
      };

      Utility.sendRequest(CAMPAIGNS_BY_ID, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (body.data) {
            this.setState(
              {
                newCampaign: {
                  ...body.data,
                  start_at: moment.utc(body.start_at).local(),
                  end_at: moment.utc(body.end_at).local(),
                },
              },
              () => {
                formStates.imagePreviewUrl = this.state.newCampaign.image_url;
                formStates.videoPreviewUrl = this.state.newCampaign.video_url;
                formStates.isEdit = true;
                formStates.isCreate = false;
                formStates.isNewChap = false;
                formStates.isCreateOrEdit = true;
                formStates.showDropdown = false;
                this.setState({
                  formStates,
                });
              }
            );
          }
        }
      });
    }
  };

  componentDidMount = () => {
    this.getCategoriesByModuleId();
    this.getCampaignsById();
  };

  handleFileChange = (inputId, previewURL, file) => {
    const formStates = { ...this.state.formStates };

    if (inputId === "image_url") {
      formStates.imagePreviewUrl = previewURL;
      formStates.imageFile = file;
    } else if (inputId === "video_url") {
      formStates.videoPreviewUrl = previewURL;
      formStates.videoFile = file;
    }

    this.setState({ formStates }, () => {
      if (Utility.isEmpty(this.state.newCampaign.embedded_image_link))
        Utility.isFilePathEmpty(
          "image_url",
          this.state.formStates.imagePreviewUrl,
          this.state.formName
        );
    });
  };

  removeUploadedFile = (inputId) => {
    const formStates = { ...this.state.formStates };
    const newCampaign = { ...this.state.newCampaign };

    if (inputId === "image_url") {
      formStates.imagePreviewUrl = "";
      formStates.imageFile = "";
      newCampaign.image_url = "";
    } else {
      formStates.videoPreviewUrl = "";
      formStates.videoFile = "";
      newCampaign.video_url = "";
    }
    this.setState({ formStates }, () => {
      this.setState({ newCampaign });
    });
  };

  handleIsFeatured = (value) => {

    const newCampaign = { ...this.state.newCampaign };
    if (value) {
      newCampaign.ranking = 5;
      this.setState({
        newCampaign: newCampaign,
      });
    } else {
      newCampaign.ranking = 10;
      this.setState({
        newCampaign: newCampaign,
      });
    }
  };
  handlePublicPrivate = (value) => {
    const newCampaign = { ...this.state.newCampaign };

    newCampaign.is_public = value;
    this.setState({
      newCampaign: newCampaign,
    });
  };

  handleFormErrors = () => {
    const category = Utility.getElementById("category_id");
    const isCategoryDirty =
      category && category.innerText.toLowerCase() !== "select category";
    let isFilePathEmpty;

    if (!isCategoryDirty) {
      const error = Utility.getElementById("category_idError");
      if (error) error.innerText = "Category is a required field";
    }

    const isValid = Utility.showFormErrors(
      this.refs[this.state.formName],
      validateFields[this.state.formName],
      this.state.formName
    );
    if (Utility.isEmpty(this.state.newCampaign.embedded_image_link))
      isFilePathEmpty = Utility.isFilePathEmpty(
        "image_url",
        this.state.formStates.imagePreviewUrl,
        this.state.formName
      );

    return isValid && !isFilePathEmpty && (!category || isCategoryDirty)
      ? true
      : false;
  };

  createCampaign = () => {
    this.setState({ showLoader: true });
    this.props.showLoader(true);
    Utility.sendRequest(
      CAMPAIGNS,
      2,
      this.state.newCampaign,
      async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          const campData = body;
          const afterSuccessCampaign = body;
          afterSuccessCampaign.createdOrUpdated = " created ";
          afterSuccessCampaign.image_url =
            this.state.formStates.imagePreviewUrl;
          afterSuccessCampaign.author_image_url =
            this.props.userData.profile_image_url;
          afterSuccessCampaign.campaign_category =
            this.state.categoriesList.map((e) => {
              return e.id == afterSuccessCampaign.category_id ? e.name : "";
            });
          this.state.categoriesList.forEach((e) => {
            if (e.id === afterSuccessCampaign.category_id)
              afterSuccessCampaign.category_color = e.color_code;
          });

          if (
            !Utility.isEmpty(this.state.formStates.imagePreviewUrl) ||
            !Utility.isEmpty(this.state.formStates.videoPreviewUrl)
          ) {
            if(CLIENT_ID === AHURA_CLIENT_ID){
              await this.uploadFiles(campData.id, campData.imageSignedURL, campData.videoSignedURL, this.state.newCampaign.is_public);
            }
            else{
              await this.uploadFiles(
                campData.id,
                campData.imageSignedURL,
                campData.videoSignedURL
              );
            }
          }
          this.setState(
            {
              newCampaign: this.getFormData(),
              formStates: this.initialState(),
            },
            () => {
              this.setState({ afterSuccessCampaign }, () => {
                this.props.loadStories(this.props.campaignSelectedTab);
                this.props.setIsFormOpen(false);
              });
            }
          );
        }
        this.setState({ showLoader: false });
        this.props.showLoader(false);
      }
    );
  };

  updateCampaign = () => {
    this.setState({ showLoader: true });
    this.props.showLoader(true);
    Utility.sendRequest(
      CAMPAIGNS + "/" + this.state.formStates.selectedCampaignId,
      3,
      this.state.newCampaign,
      async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (
            !Utility.isEmpty(this.state.newCampaign.imageSignedURL) &&
            !Utility.isEmpty(this.state.newCampaign.videoSignedURL)
          ){
            if(CLIENT_ID === AHURA_CLIENT_ID){
              await this.uploadFiles(this.state.formStates.selectedCampaignId, this.state.newCampaign.imageSignedURL, this.state.newCampaign.videoSignedURL, this.state.newCampaign.is_public);    
            }
            else
            {
              await this.uploadFiles(
                this.state.formStates.selectedCampaignId,
                this.state.newCampaign.imageSignedURL,
                this.state.newCampaign.videoSignedURL
              );
            }
          }
          const afterSuccessCampaign = body;
          afterSuccessCampaign.createdOrUpdated = " updated ";
          afterSuccessCampaign.image_url =
            this.state.formStates.imagePreviewUrl;
          afterSuccessCampaign.author_image_url =
            this.props.userData.profile_image_url;
          afterSuccessCampaign.campaign_category =
            this.state.categoriesList.map((e) => {
              return e.id == afterSuccessCampaign.category_id ? e.name : "";
            });
          this.state.categoriesList.forEach((e) => {
            if (e.id === afterSuccessCampaign.category_id)
              afterSuccessCampaign.category_color = e.color_code;
          });
          this.setState(
            {
              newCampaign: this.getFormData(),
              formStates: this.initialState(),
            },
            () => {
              this.setState({ afterSuccessCampaign }, () => {
                this.setState({ showSuccess: true });
              });
            }
          );
        }
        this.setState({ showLoader: false });
        this.props.showLoader(false);
      }
    );
  };

  uploadFiles = async (id, imageSignedURL, videoSignedURL, is_public) => {
    let imageURL = this.state.formStates.imagePreviewUrl;
    let videoURL = this.state.formStates.videoPreviewUrl;

    if (!Utility.isEmpty(this.state.formStates.imageFile.size)) {
      imageURL = await Utility.uploadFile(
        this.state.formStates.imageFile,
        imageSignedURL
      );
    }

    if (!Utility.isEmpty(this.state.formStates.videoFile.size)) {
      videoURL = await Utility.uploadFile(
        this.state.formStates.videoFile,
        videoSignedURL
      );
    }

    if(CLIENT_ID === AHURA_CLIENT_ID)
      await this.updateCampaignMediaUrl(id, imageURL, videoURL, is_public);
    else
      await this.updateCampaignMediaUrl(id, imageURL, videoURL);
  };

  updateCampaignMediaUrl = (campId, imageURL, videoURL, is_public) => {
    let params = {};
    if(AHURA_CLIENT_ID === CLIENT_ID){
      params  = {
        image_url: imageURL,
        video_url: videoURL,
        is_public: is_public,
      };
    }
    else{
      params = {
        image_url: imageURL,
        video_url: videoURL,
      };
    }

    Utility.sendRequest(
      CAMPAIGNS + "/" + campId,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.props.loadStories(this.props.campaignSelectedTab);
          this.props.setIsFormOpen(false);
        }
      }
    );
  };

  submit = (ev) => {
    ev.preventDefault();
    const isFormValid = this.handleFormErrors();

    if (!isFormValid) {
      var elmnt = document.getElementById("form");
      if (elmnt) elmnt.scrollIntoView();
      return;
    }
    // console.log(this.state);
    if(this.state.formStates.isCreate || this.state.formStates.isNewChap)
      this.createCampaign();
    else
      this.updateCampaign();
  };

  render() {
    const { selectedCampaignId } = this.state.formStates;
    const { heading } = this.state.newCampaign;
    return (
      <>
        {this.state.isLoading && (
          <div className="sweet-loading loader">
            Loading ...
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={80}
              color={"#0db6ea"}
              loading={true}
            />
          </div>
        )}
        <div className="h-100 scroll-y top-on-sm p-5 d-none d-lg-block col-xl-9 col-lg-8 col-12">
          <div className="">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="align-items-center w-100 mb-4">
                <ChevronLeftIcon
                  mainClass="mr-3 ml-n4 cursor-pointer"
                  handleClick={() => this.props.setIsFormOpen(false)}
                />
                <span className="font-20 font-medium">
                  {heading || "Create Course"}
                </span>
              </div>
              <div className="align-items-center mt-2 mt-sm-0 w-100">
                <div className="d-block w-100 courses-tab">
                  <Tabs id="uncontrolled-tab-example">
                    <Tab eventKey="course" title="Course Syllabus">
                      <CourseForm
                        {...this.state}
                        ref={this.state.formName}
                        key={"course"}
                        handleDropdown={this.handleDropdown}
                        setIsFormOpen={this.props.setIsFormOpen}
                        handleFileChange={this.handleFileChange}
                        removeUploadedFile={this.removeUploadedFile}
                        handleChange={this.handleInputChange}
                        submit={this.submit}
                        handleIsFeatured= {this.handleIsFeatured}
                        changeDescription={this.changeDescription}
                        handlePublicPrivate={this.handlePublicPrivate}
                      />
                    </Tab>
                    <Tab eventKey="section" title="Sections & Lessons">
                      <div className="row mx-0 justify-content-between">
                        {selectedCampaignId ? (
                          <Section campId={selectedCampaignId} />
                        ) : (
                          <p>
                            You will have to create course first to enable this
                            tab.
                          </p>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="assignment" title="Assignments">
                      {selectedCampaignId ? (
                        <Assignment campId={selectedCampaignId} />
                      ) : (
                        <p>
                          You will have to create course first to enable this
                          tab.
                        </p>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
  userData: state.userData,
  publicUserData: state.publicUserData,
  neighborhoodState: state.neighborhoodState,
  isLoading: state.isLoading,
  isChatFormOpen: state.isChatFormOpen,
  seeAll: state.seeAll,
  campaignSelectedTab: state.campaignSelectedTab,
  campaignId: state.campaignFormSelectedId,
});

export default connect(mapStateToProps, mapActionToProps)(CourseContainer);
