import React, { useState, useEffect } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { Utility } from 'utils';
import { toast } from 'react-toastify';
import { NoRecordFound } from 'shared/components/no_record_found';
import { NOTIFICATIONS_IDS, NOTIFICATIONS_TYPES, CLIENT_ID } from 'config/constants/config';
import { CLIENT_NOTIFICATIONS, API_BASE_URL, NOTIFICATION_MARK_AS_READ, USER_PROFILE, GETALLJOINEDGROUPS } from 'config/constants/api_endpoints';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updateIsChatFormOpen, updateChatFriend, updateIsAdminAccessRequestFormOpen, updateIsAcceptedAdminRequestFormOpen } from 'shared/actions';
import Avatar from 'react-avatar';
import socket from 'utils/sockets.js';
import { AHURA_CLIENT_ID } from 'config/constants';
import ScheduleTime from 'shared/components/ScheduleTime';

export default function WebNotification(props){
  const [notificationData, setNotificationData] = useState([]);
  const [openScheduleTime, setScheduleTime] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const userData = useSelector(state => state.userData);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getAllNotifications();
    socket.on('notifications', listenForNotifications);

    return () => socket.off('notifications', listenForNotifications);
  }, [userData.id]);

  useEffect(() => {
    if(!Utility.isEmpty(notificationData))
      socket.on('notifications', listenForNotifications);
  }, []);

  useEffect(() => {
    if(Utility.pwaModeEnabled() && openNotifications) {
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('body').scroll = "no";
    }
    else {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').scroll = "yes";
    }

    return () => {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').scroll = "yes";
    };
  }, [openNotifications]);


  const listenForNotifications = (newNotification) => {
    if (!Utility.isEmpty(newNotification))
      getAllNotifications();
  };

  const markAsRead = (id) => {
    Utility.sendRequest(NOTIFICATION_MARK_AS_READ, 3, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error)
        toast.error(body.error.message, { containerId: 'public' });
      else
        getAllNotifications();
    });
  };

  const openSelectedGroup = (group_id) => {
    const params = {
      user_client_id: userData.id
    };

    Utility.sendRequest(GETALLJOINEDGROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        const group = body.data.filter(item => item.group_id === group_id);
        if (group)
          dispatch(updateIsChatFormOpen(true));
          dispatch(updateChatFriend(group[0]));
      }
    });
  };

  function getAllNotifications(){
    const params = { ':id': userData.id };
    const baseURL = Utility.getAPIEndpointWithParams(CLIENT_NOTIFICATIONS, params);

    Utility.sendRequest(baseURL, 1, {}, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setNotificationData(body.data);
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  const addLinksToNoti = async (data) => {
    if(NOTIFICATIONS_IDS.NEW_DIRECT_MESSAGE === data.notification_id) {
      await Utility.sendRequest(USER_PROFILE, 2,  { userClientId: userData.id, publicUserClientId: data.ref_record_id }, (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          dispatch(updateChatFriend(body));
          dispatch(updateIsChatFormOpen(true));
          markAsRead(data.id);
        }
      });
    } else if(NOTIFICATIONS_IDS.ADMIN_ACCESS_REQUEST === data.notification_id) {
      await Utility.sendRequest(USER_PROFILE, 2,  { userClientId: userData.id, publicUserClientId: data.ref_record_id }, (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          const notificationCalloutData = {
            open: true,
            data: body
          };
          dispatch(updateIsAdminAccessRequestFormOpen(notificationCalloutData));
          markAsRead(data.id);
        }
      });
    } else if(NOTIFICATIONS_IDS.ACCEPT_ADMIN_REQUEST === data.notification_id) {
      await Utility.sendRequest(USER_PROFILE, 2,  { userClientId: userData.id, publicUserClientId: data.ref_record_id }, (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          const notificationCalloutData = {
            open: true,
            data: body
          };
          dispatch(updateIsAcceptedAdminRequestFormOpen(notificationCalloutData));
          markAsRead(data.id);
        }
      });
    }
    else if((NOTIFICATIONS_IDS.ADD_MEMBER_PERMISSION === data.notification_id)
      || (NOTIFICATIONS_IDS.EDIT_GROUP_PERMISSION === data.notification_id) || (NOTIFICATIONS_IDS.GROUP_MESSAGE === data.notification_id) ||
       (NOTIFICATIONS_IDS.REMOVE_ADD_MEMBER_PERMISSION === data.notification_id) || (NOTIFICATIONS_IDS.REMOVE_EDIT_GROUP_PERMISSION === data.notification_id))
       {
        openSelectedGroup(data.ref_record_id);
        markAsRead(data.id);
    }
    else {
      markAsRead(data.id);
      return history.push(NOTIFICATIONS_TYPES[data.notification_type] + data.ref_record_id);
    }
  };

  const paintNotification = () => {
    return !Utility.isEmpty(notificationData) ?
      notificationData.map(e => {
        return (
          <div className={`${e.is_read ? '' : 'unread'} align-items-center flex-nowrap notification-item-inner`} key={e.id}>

            {!Utility.isEmpty(e.notification_image) ?
              <img
              className="notification-img img-border rounded-circle"
              src={e.notification_image || require('assets/images/users/user_no_image.png')} alt=""
            /> :
            <Avatar
              name={e.notification_message_plain}
              round={true}
              size={"50"}
              textSizeRatio={1.75}
              color='#007bb3'
              maxInitials={1}
            />}
            <Dropdown.Item key={e.id} bsPrefix='text-dark' onClick={() => addLinksToNoti(e)}>
              <div className="align-items-center ml-3" >
                <div dangerouslySetInnerHTML={{ __html:  e.notification_message }} key={e.id}/>
              </div>
              <div className="text-right font-weight-bold position-relative" >
                <span className="font-12 time-ago">{Utility.returnDuration(e.created_at)}</span>
              </div>
            </Dropdown.Item>
            {/*<div className="ml-auto position-relative" >
              <Dropdown>
                <Dropdown.Toggle
                  className="notification-dropdown"
                  variant="none" id="dropdown-notify">
                  <img src={require('assets/images/icons/menu_horizontal_icon.svg')} className="notification-horizontal-menu-img" alt="horizontal menu" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="notification-dropdown-inner" >
                  <NotificationGear
                    id={e.id}
                    markAsRead={markAsRead}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>*/}
          </div>
        );
      }) : <NoRecordFound />;
  };

  const showBellIndicator = () => {
    let flag = false;
    !Utility.isEmpty(notificationData) &&
    notificationData.map(e => {
      if(!e.is_read)
        flag = true;
    });
    return flag;
  };

  const openSetting = () => {
    setScheduleTime(true);
  };

  return(
    <>
    <div className={`notifications-outer position-relative ${Utility.pwaModeEnabled() ? "" : "web-notifications-outer"}`}>
      <div className={`${showBellIndicator() && 'notification-indicator'} rounded-circle position-absolute`} />
      <Dropdown onToggle = {(e) => setOpenNotifications(e)}>
        <Dropdown.Toggle variant="none" id="dropdown-basic">
          <img src={require("assets/images/icons/notification.svg")} alt="notifications bell" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="notification-outer" >
          <div className="d-lg-none notification-close-callout" >
              <div onClick={() => document.querySelector('#dropdown-basic').click() } className="pwa-callout-decorator mb-4 mt-3"  />
          </div>
          <div className="scroll-y notification-overflow">
            {CLIENT_ID === AHURA_CLIENT_ID &&
              <div className='d-flex py-2 px-3 justify-content-between border-bottom'>
                <div className='mr-3 font-weight-bold'>Notifications</div>
                <div className="cursor-pointer" onClick={openSetting}>
                  <img src={require('assets/images/setting.svg')}  alt="settings icon" />
                </div>
              </div>
            }
            <div className="d-lg-none" >
              <div className="d-flex align-items-center justify-content-between border-bottom">
                <h1 className="font-21-bold d-flex mb-4 pb-2 px-4">
                   <img
                    src={require('assets/images/icons/notification.svg')}
                    alt="bell icon"
                    className="mr-4"
                  />
                  Notifications</h1>
              </div>
            </div>
            {paintNotification()}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
    {openScheduleTime &&
      <Modal className="modal-backdrop-darker" show={openScheduleTime} onHide={() => setScheduleTime(false)} animation={false}>
      <div className='ethos-onboarding ethos-purchase-inner ethos-modal-inner'>
        <ScheduleTime
          isUpdate
          close={() => setScheduleTime(false)}
          skip={() => setScheduleTime(false)}
        />
      </div>
    </Modal>
    }
    </>
  );
}
