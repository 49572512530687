import React, { Component } from "react";
import CarouselContainer from "./components/carousel";
import { CREATE_EDIT_QUESTIONS ,GET_DISCUSSION_COMMENTS } from "config/constants/api_endpoints";

import {
  WhiteFilledAddLargeIcon,
  MemberGroupIcon,
  ChevronLeftIcon,
  AddCalendarIcon,
  ClockIcon,
  LocationIcon,
  AgendaIcon,
  PassportIcon
} from "shared/icons/icons";
import { CampaignChat } from "./components/campaign_chat";
import { FollowerInfo } from "./components/follower_info";
import { Events } from "./components/events";
import { ViewingMembers } from "./components/viewing_members";
import SendMoney from "./components/send_money";
import { ViewAgendaList } from "./components/view_agenda_list";
import { Share } from "./components/share";
import AssigmentList from "./components/assignment_list";
import { Certificate } from "./components/certificate";
import { DownloadContent } from "./components/downloadContent";
import { CourseDetails } from "./components/course_details";
import MyCommunity from "shared/components/my_community";
import Communities from "../communities";
import ChatCallout from "shared/components/chat_callout.js";
import {
  showLoader,
  updatePlanUpgraded,
  updateUserDistracted,
  updateCampaignVideoPlaying,
  updateIsCourseSidebar,
  updateIsPageCourse,
} from "shared/actions";
import { toast } from "react-toastify";
import moment from "moment";
import { connect } from "react-redux";
import pluralize from "pluralize";
import { Utility } from "utils";
import Avatar from "react-avatar";
import {
  CAMPAIGN_DETAILS,
  GET_FOLLOWERS_FOLLOWING,
  CAMPAIGN_SENTIMENT_DETAILS,
  CAMPAIGN_IS_LIKED,
  CAMPAIGN_VIEWS,
  GET_USERS_BY_NETWORKS,
  EVENT_MEMBERS_LIST,
  CALENDAR_DETAILS,
  ALL_PARENT_CAMPAIGNS,
  GET_ASSIGNMENTS_BY_CAMPGAIN,
  CAMPAIGNS_TOP_STORIES,
  TRANSACTION_DETAILS,
  USER_ASSIGNMENT_DETAILS,
  GET_ALL_ASSIGNMENTS,
  USER_BADGES,
} from "config/constants/api_endpoints";
import { AssignmentCheckIcon } from "shared/icons/icons";
import SideCallout from "shared/components/templates/side_callout";
import { USER_ASSIGNMENT } from "config/constants/api_endpoints";
import {
  CLIENT_ID,
  RECOMMENDED_READS_LIMIT,
  NAMES_MAPPING,
  CRM_MODULES,
  ACCOUNT_TYPE,
} from "config/constants/config";
import {
  CAMPAIGN_TYPES_IDS,
  ETHOS_CLIENT_ID,
  AHURA_CLIENT_ID,
  GIL_CLIENT_ID,
  COURSE_BADGE_ID,
} from "config/constants";
// import {
//   updateIsPageCourse
// } from "../../../actions";
import { SuggestedReadsCard } from "shared/components/suggested_reads_articles";
import Purchase from "scenes/users/components/Purchase";
import UpgradePlanPurchased from "shared/components/UpgradePlanPurchased";
import ExpandableOptions from "./expandable_options";
import AccessPass from "shared/components/AccessPass";
import { Modal } from "react-bootstrap";
import CourseSideBar from "./CourseSideBar";
import CourseHeader from "./CourseHeader";
import _, { has, filter } from "lodash";
import AssignmentQuestion from "./components/assignment_question";
import AssignmentResult from "./components/assignment_result";
import AssignmentEdit from "./components/assignment_edit";
import { CourseCertificate } from "./CourseCertificate";
import UserCommentContainer from "./components/UserCommentContainer";
import CourseDetailsHeader from "./CourseDetailsHeader";
import Assignments from "./components/Assignments";
import Partcipants from "./components/Partcipants";
import { MdForum } from "react-icons/md";
import {
  CourseLessonIcon,
  LessonIcon,
  LessonTickFilledIcon,
} from "../../../icons/icons";
import CourseDetailsPage from "./CourseDetailsPage";
import { GET_ASSIGNMENT_BY_LESSON } from "../../../../config/constants/api_endpoints";
import { CircleFilled, TickFilledIcon } from "../../../icons/icons";
import { CourseShare } from "./components/CourseShare";
import { Date as date } from "utils";
import { HiDotsHorizontal } from "react-icons/hi";
// import Profile from "../test";
import ProfileContainer from '../../../../scenes/profile';

const isChatOpen = Utility.pwaModeEnabled() ? "none" : "chat";

class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRightSection: props.activeRightSection
        ? props.activeRightSection
        : isChatOpen,
      loadedFrom: props.loadedFrom,
      campaignDetails: [],
      index: 0,
      isFollowed: false,
      commentsCount: 0,
      likesCount: 0,
      allMembers: [],
      nonProfits: [],
      organizers: [],
      creator: null,
      isLiked: false,
      campaignId: props.campaignId,
      calledFrom: props.calledFrom,
      isEvent: false,
      isAgenda: false,
      closeForm: false,
      membersCount: 0,
      is_calendar_added: false,
      is_active: false,
      refresh: 0,
      refreshCampaignApi: props.refreshCampaignApi,
      isCourse: false,
      sectionList: [],
      courseDetails: [],
      assignmentList: [],
      campaignsTopStories: [],
      assignmentCampainId: null,
      paywall: false,
      paywallConfirmation: false,
      transactionData: {},
      isTransactionSuccessful: false,
      paywallFor: "DOCUMENT",
      userAssignmentDetails: [],
      openModal: false,
      allAssignments: [],
      isDicuss: false,
      campaignId: props.campaignId,
      openCallout: false,
      assignmentId: null,
      questionLists: [],
      selectedQuestion: {},
      assignmentResult: false,
      assignmentEdit: false,
      user_id: this.props.userData.id,
      count: 1,
      courseCompleteSuccessCallout: false,
      userBadgeforCourse: [],
      editQuestion: false,
      isCertificate: false,
      ActiveLessonId: null,
      isCourseCompleted: false,
      CourseAssignment: [],
      selectedItem: "About",
      activePanal: "",
      isLesson: false,
      openProfile: false,
      LessonAllAssignments : [],
      CommentCount:[],
    };
  }

  componentDidMount = () => {
    this.getCampaignDetails();
    this.getCampaignsTopStoriesDetails();
    this.getCampaignLiked();
    this.getAllMembers();
    this.props.updateIsCourseSidebar(false);
    this.state.loadedFrom !== "campaignModule" && this.setCampaignViewed();
    this.getAttendingMembersCount();
    if (!Utility.isEmpty(this.props.userData)) {
      this.getTransactionDetails();
    }

    // if(this.state.openModal) {
    //   document.querySelector('body').classList.add('overflow-hidden');
    // }
    // else {
    //   document.querySelector('body').classList.remove('overflow-hidden');
    // }
    if(this.state.CommentCount.length===0 && this.state.sectionList.length!==0&&CLIENT_ID === GIL_CLIENT_ID)
    this.getCommentCounts(this.state.sectionList);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.isEvent !== this.state.isEvent) this.getAllMembers();
    if (prevState.refresh !== this.state.refresh) {
      this.getCampaignDetails();
      this.getAttendingMembersCount();
    }
    if (prevProps.refreshCampaignApi !== this.props.refreshCampaignApi) {
      this.getCampaignDetails();
      this.getAttendingMembersCount();
    }
    if (prevProps.isPlanUpgraded !== this.state.isTransactionSuccessful) {
      this.getTransactionDetails();
    }
    if (prevState.activeRightSection !== this.state.activeRightSection) {
      if (this.state.activeRightSection !== "course")
        document.querySelector("body").style.overflow = "hidden";
      document.querySelector("body").scroll = "no";
      //document.querySelector('body').classList.add('overflow-hidden');
      if (this.state.activeRightSection === "none") {
        document.querySelector("body").style.overflow = "auto";
        document.querySelector("body").scroll = "yes";
        //document.querySelector('body').classList.remove('overflow-hidden');
      }
    }
    if (prevState.openModal !== this.state.openModal) {
      if (this.state.openModal) {
        document.querySelector("body").style.overflow = "hidden";
        document.querySelector("body").scroll = "no";
        //document.querySelector('body').classList.add('overflow-hidden');
      } else {
        document.querySelector("body").style.overflow = "auto";
        document.querySelector("body").scroll = "yes";
        //document.querySelector('body').classList.remove('overflow-hidden');
      }
    }
    if(this.state.CommentCount.length===0 &&  this.state.sectionList.length!==0&&CLIENT_ID === GIL_CLIENT_ID)
    this.getCommentCounts(this.state.sectionList);
  };

  handleChangepanal = (id) => {
    this.setState({
      activePanal: id,
    });
  };

  getTransactionDetails = () => {
    const params = { userClientId: this.props.userData.id };

    Utility.sendRequest(TRANSACTION_DETAILS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const isPlanUpgraded =
          body.data && body.data.length !== 0 ? true : false;
        this.props.updatePlanUpgraded(isPlanUpgraded);
        this.setState({ isTransactionSuccessful: isPlanUpgraded });
      }
    });
  };

  getCampaignsTopStoriesDetails = () => {
    const params = {
      publisher_network_id: CLIENT_ID,
      limit: RECOMMENDED_READS_LIMIT,
    };

    if (!Utility.isEmpty(this.props.userData)) {
      Utility.sendRequest(
        CAMPAIGNS_TOP_STORIES,
        1,
        params,
        (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            if (body.length) this.setState({ campaignsTopStories: body });
          }
        }
      );
    }
  };

  componentWillUpdate = (nextProps) => {
    if (this.state.campaignId !== nextProps.campaignId) {
      this.setState(
        {
          index: 0,
          campaignId: nextProps.campaignId,
          isEvent:
            this.props.selectedFilterItem.name === CAMPAIGN_TYPES_IDS.event,
          calledFrom: nextProps.calledFrom,
        },
        () => {
          this.getCampaignDetails();
          this.getCampaignLiked();
          this.handleScroll();
        }
      );
    }
  };

  handleScroll = () => {
    const element = document.getElementById("article-detail");
    if (element)
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 200);
  };

  incrementCommentCount = () => {
    this.setState({ commentsCount: parseInt(this.state.commentsCount) + 1 });
  };

  handleLikesCount = () => {
    this.setState({
      likesCount: this.state.isLiked
        ? parseInt(this.state.likesCount) + 1
        : parseInt(this.state.likesCount) - 1,
    });
  };

  getAllMembers = (search = null) => {
    this.props.showLoader(true);

    const params = {
      user_client_id: this.props.userData.id,
      campaign_id: this.state.campaignId,
      search,
    };

    if (this.state.isEvent) {
      Utility.sendRequest(EVENT_MEMBERS_LIST, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({ allMembers: body });
        }

        this.props.showLoader(false);
      });
    } else {
      Utility.sendRequest(CAMPAIGN_VIEWS, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({ allMembers: body });
        }

        this.props.showLoader(false);
      });
    }
  };

  getUserDetails = () => {
    const selectedIds =
      this.state.campaignDetails[this.state.index].non_profits;
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      forCommunity: true,
      selectedIds: !Utility.isEmpty(selectedIds) ? selectedIds : [null],
    };

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.registrants)
          this.setState({
            nonProfits: body.registrants,
          });
      }
    });
  };

  getCampaignDetails = (id = "", activeRightSection) => {
    this.props.showLoader(true);
    const params = {
      user_client_id: this.props.userData.id,
      campaign_id: id != "" ? id : this.state.campaignId,
      publisher_network_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_DETAILS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data.length > 0) {
          // TODO
          // Hiding sections data for courses.
          const campaigns_data = body.data.filter(
            (item) => item.campaign_type_id !== CAMPAIGN_TYPES_IDS.section
          );
          this.setState({ campaignDetails: campaigns_data }, () => {
            if (id === "") {
              this.setState({
                courseDetails: campaigns_data,
              });
            }
            const currentCampaign =
              this.state.campaignDetails[this.state.index];
            this.setState({
              commentsCount: currentCampaign.comments_count,
              likesCount: currentCampaign.likes_count,
              isFollowed: currentCampaign.is_followed,
            });
            if (body.data[0].campaign_type_id === CAMPAIGN_TYPES_IDS.event) {
              this.setState({ isEvent: true });
              if (body.data[0].campaign_parent_id !== null) {
                this.setState({ isAgenda: true });
              }
            }
            if (body.data[0].campaign_type_id === CAMPAIGN_TYPES_IDS.courses) {
              if (
                activeRightSection !== "chat" &&
                activeRightSection !== "share"
              ) {
                this.setState(
                  { isCourse: true, activeRightSection: "course" },
                  () => {
                    this.getUserAssignmentDetails();
                  }
                );
              }
            }
            if (body.data[0].campaign_type_id === CAMPAIGN_TYPES_IDS.lesson) {
              if (
                activeRightSection !== "chat" &&
                activeRightSection !== "share"
              ) {
                this.setState(
                  { isLesson: true, activeRightSection: "course" },
                  () => {
                    this.getAssignmentDetails(id);
                  }
                );
              }
            }
            this.getCreatorDetails();
            this.getUserDetails();
            this.getOrganizersDetails();
            this.getAllParentCampaigns();
            this.getAllAssignment();
          });
        }
      }

      this.props.showLoader(false);
    });
  };

  getAssignmentDetails = (id) => {
    this.setState({ assignmentList: [] });
    const params = {
      campaign_id: id,
    };

    Utility.sendRequest(
      GET_ASSIGNMENTS_BY_CAMPGAIN,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (body.data.length > 0) {
            this.setState({
              assignmentList: body.data,
            });
          }
        }
      }
    );
  };

  getUserAssignmentDetails = () => {
    const params = {
      created_by: this.props.userData.id,
    };

    Utility.sendRequest(
      USER_ASSIGNMENT_DETAILS,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (body.data.length > 0) {
            this.setState({
              userAssignmentDetails: body.data,
            });
          }
        }
      }
    );
  };

  getAllParentCampaigns = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      // user_client_id: this.props.userData.id,
      parent_id: this.state.campaignId,
      campaign_type_id: CAMPAIGN_TYPES_IDS.section,
      sub_campgain: "lessons",
    };

    Utility.sendRequest(ALL_PARENT_CAMPAIGNS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.setState({ sectionList: body.data });
        this.getCourseAssignment(body.data);
        // this.getCommentCounts(body.data);
      }
    });
  };

  getAllAssignment = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      // user_client_id: this.props.userData.id,
      parent_id: this.state.campaignId,
      campaign_type_id: CAMPAIGN_TYPES_IDS.section,
      sub_campgain: "lessons",
      id: this.props.userData.id,
    };
    var Data = [];
    Utility.sendRequest(GET_ALL_ASSIGNMENTS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        body.data &&
          body.data.map((data) => {
            // console.log("dddddddddd",data);
            data.lessons && Object.keys(data.lessons).length !== 0 &&
              data.lessons.map((Lesson) => {
                Data.push({
                  SectionHeading: data.heading,
                  lessonHeading: Lesson.heading,
                  assignmentList: Lesson.assignment,
                });
              });
          });
      }
    });
    this.setState({
      allAssignments: Data,
    });
  };

  getOrganizersDetails = () => {
    const selectedIds = this.state.campaignDetails[this.state.index].organizers;
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      forCommunity: true,
      selectedIds: !Utility.isEmpty(selectedIds) ? selectedIds : [null],
    };

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.registrants)
          this.setState({
            organizers: body.registrants,
          });
      }
    });
  };

  getCreatorDetails = () => {
    const data = this.state.campaignDetails[this.state.index];
    this.setState({
      creator: {
        id: data.creator_id,
        user_name: data.creator_name,
        profile_image_url: data.creator_profile_image_url,
        city: data.creator_city,
        state: data.creator_state,
        is_followed: data.is_followed,
      },
    });
  };

  setDetailsFollowerfollowing = (followingId) => {
    const id = this.props.userData.id;
    if (id !== followingId) {
      const params = {
        followerId: id,
        followingId: followingId,
        clientId: CLIENT_ID,
      };

      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        2,
        params,
        (err, res, body) => {
          body = { ...JSON.parse(body) };
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            this.getUserDetails();
            this.getOrganizersDetails();
          }
        }
      );
    }
  };

  handleOnClick = (id) => {
    this.setDetailsFollowerfollowing(id);
  };

  setCampaignViewed = () => {
    const params = {
      user_client_id: this.props.userData.id,
      campaign_id: this.state.campaignId,
    };

    Utility.sendRequest(CAMPAIGN_VIEWS, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      }
    });
  };

  setFollowerfollowing = (followingId = null) => {
    const params = {
      followerId: this.props.userData.id,
      followingId: followingId || this.state.campaignDetails[0].user_client_id,
    };

    Utility.sendRequest(
      GET_FOLLOWERS_FOLLOWING,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          followingId && this.getAllMembers();
          if (this.state.campaignDetails[0].user_client_id === followingId)
            this.setState({ isFollowed: true });

          this.getCampaignDetails();
        }
      }
    );
  };

  getSentimentDetails = () => {
    const params = {
      campaign_id: this.state.campaignId,
      user_client_id: this.props.userData.id,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(
      CAMPAIGN_SENTIMENT_DETAILS,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({ isLiked: body.is_active }, () =>
            this.handleLikesCount()
          );
        }
      }
    );
  };

  getCampaignLiked = () => {
    this.props.showLoader(true);

    const params = {
      campaign_id: this.state.campaignId,
      user_client_id: this.props.userData.id,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_IS_LIKED, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body) this.setState({ isLiked: body.is_active });
      }

      this.props.showLoader(false);
    });
  };

  addToCalendar = () => {
    const params = {
      campaign_id: this.state.campaignId,
      user_client_id: this.props.userData.id,
      created_by: this.props.userData.id,
      updated_by: this.props.userData.id,
      is_active: true,
    };

    if (this.state.isAgenda)
      params.parent_campaign_id =
        this.state.campaignDetails[this.state.index].campaign_parent_id;

    Utility.sendRequest(CALENDAR_DETAILS, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body) {
          this.setState(
            {
              refresh: Math.random(),
            },
            () => {
              if (this.state.activeRightSection === "viewAgenda") {
                this.setState(
                  {
                    activeRightSection: "none",
                  },
                  () => {
                    this.setState({ activeRightSection: "viewAgenda" });
                  }
                );
              }
            }
          );
          const message = body.message
            ? body.message
            : "Event successfully added to the calendar";
          toast.success(message, { containerId: "private" });
        }
      }
    });
  };

  removeFromCalendar = () => {
    const params = {
      id: this.state.campaignDetails[this.state.index].calendar_detail_id,
      is_active: false,
      user_client_id: this.props.userData.id,
      campaign_id: this.state.campaignId,
    };

    Utility.sendRequest(CALENDAR_DETAILS, 3, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body) {
          this.setState(
            {
              refresh: Math.random(),
            },
            () => {
              if (this.state.activeRightSection === "viewAgenda") {
                this.setState(
                  {
                    activeRightSection: "none",
                  },
                  () => {
                    this.setState({ activeRightSection: "viewAgenda" });
                  }
                );
              }
            }
          );
          toast.success("Event is removed from the calendar", {
            containerId: "private",
          });
        }
      }
    });
  };

  getAttendingMembersCount = () => {
    this.props.showLoader(true);

    const params = {
      is_active: true,
      campaign_id: this.state.campaignId,
    };
    Utility.sendRequest(
      `${CALENDAR_DETAILS}/count`,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({ membersCount: body.count });
        }

        this.props.showLoader(false);
      }
    );
  };

  switchRightSection = (key) => {
    if (key === "course") {
      this.setState({
        activeRightSection: key,
        isCourse: true,
        isLesson: false,
        openModal: true,
      });
      this.getCampaignDetails();
    } else {
      this.setState({
        isCourse: false,
        isLesson: false,
        activeRightSection: key,
        openModal: true,
      });
    }
  };

  goBack = () => {
    if (this.props.calledFrom === "direct_message") {
      this.props.history.push({
        pathname: "/home",
        calledFrom: "direct_message",
      });
    } else window.history.back();
  };

  handleSelect = (index) => {
    this.setState({ index });
  };

  paintUpdateAt = (updatedAt, isCourse) => {
    let date = isCourse
      ? moment(updatedAt).format("DD MMM YYYY")
      : moment(updatedAt).format("ll");
    const time = moment(updatedAt).format("hh:mm A");
    date = date.split(",")[0];
    return `Updated at ${time}, ${date}`;
  };

  getCampTypeName = () => {
    return this.state.campaignDetails.length > 0 &&
      this.state.campaignDetails[0].campaign_type_id ===
        CAMPAIGN_TYPES_IDS.story
      ? Utility.capitalize(NAMES_MAPPING.CAMPAIGN.story.parent)
      : Utility.capitalize(NAMES_MAPPING.CAMPAIGN.event.parent);
  };

  cardList = () => {
    const { nonProfits } = this.state;
    return nonProfits.map((item) => (
      <MyCommunity
        key={item.id}
        item={item}
        handleOnClick={this.handleOnClick}
        showCharFollowButtons={true}
      />
    ));
  };

  organizersCardList = () => {
    const { organizers } = this.state;
    return organizers.map((organizer) => (
      <MyCommunity
        key={organizer.id}
        item={organizer}
        handleOnClick={this.handleOnClick}
        showCharFollowButtons={true}
      />
    ));
  };

  paintCreator = () => {
    const { creator, isEvent } = this.state;
    return creator && isEvent ? (
      <div className="community-section">
        <div className="mb-42 content-creator-outer">
          <FollowerInfo
            id={creator.id}
            selectedUser={creator}
            name={creator.user_name}
            imageUrl={creator.profile_image_url}
            isFollowed={this.state.isFollowed}
            setFollowerfollowing={this.setFollowerfollowing}
          />
        </div>
      </div>
    ) : (
      <></>
    );
  };

  paintCommunityCauses = () => {
    return this.state.nonProfits.length && this.state.isEvent ? (
      <div className="community-section">
        <h1 className="display-7 font-bold mb-4">Community Causes</h1>
        <div className="d-flex flex-lg-wrap community-section-inner slider-mob-inner mb-42">
          {this.state.nonProfits && this.cardList()}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  paintOrganizers = () => {
    return this.state.organizers.length && this.state.isEvent ? (
      <div className="community-section">
        <h1 className="display-7 font-bold mb-4">
          Experts, Speakers and Presenters
        </h1>
        <div className="d-flex flex-lg-wrap community-section-inner slider-mob-inner mb-42">
          {this.state.organizers && this.organizersCardList()}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  getTime = (dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  getMonthName = (dateString) => {
    return new Date(dateString)
      .toLocaleString("default", { month: "short" })
      .toLocaleUpperCase();
  };

  paintUpdateAtWrapper = (data) => {
    return (
      <div className={"d-flex pr-3 article-updated-time"}>
        {data.creator_profile_image_url ? (
          <>
            <img
              src={data.creator_profile_image_url}
              className="article-pic rounded-circle"
              alt=""
            />
            <div className="font-12 text-secondary">
              {this.paintUpdateAt(data.updated_at, this.state.isCourse)}
            </div>
          </>
        ) : (
          <>
            <Avatar
              name={data.creator_name}
              round={true}
              size={"20"}
              textSizeRatio={1.75}
              color="#007bb3"
              maxInitials={1}
            />
            <div className="font-12 text-secondary ml-2">
              {this.paintUpdateAt(data.updated_at, this.state.isCourse)}
            </div>
          </>
        )}
      </div>
    );
  };

  eventOrAgendaHeading = (record) => {
    return this.props.calledFrom === "AgendaList"
      ? record.sub_heading
      : record.heading;
  };

  paintDirectMessageCallout = () => {
    return this.props.isChatFormOpen && <ChatCallout />;
  };
  handleDiscuss = (data, Selectedfrom = "lesson") => {
    if (Selectedfrom !== "lesson") {
      this.loadLession(data);
    }
    this.setState({
      activePanal: Selectedfrom,
      isDicuss: true,
      ActiveLessonId: data.id,
    });
    return;
  };

  handleLessonAssignment = (lesson_id, purpose = null) => {
    this.props.showLoader(true);
    const params = {
      lesson_id,
    };

    Utility.sendRequest(
      GET_ASSIGNMENT_BY_LESSON,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (purpose === "CourseAssignment") {
            this.state.CourseAssignment.push(body);
          } else {
            this.setState({
              LessonAllAssignments: body,
            });
          }

        }

        this.props.showLoader(false);
      }
    );
    return;
  };

  loadLession = (details) => {
    if (Utility.pwaModeEnabled()) {
      this.setState({ openModal: false });
    }
    // this.setState({
    //   isDicuss :false
    // })
    if (CLIENT_ID === AHURA_CLIENT_ID && !this.props.isPlanUpgraded) {
      // NOTE: remove paywall for ahura client
      this.setState({ paywall: false }); // change state to #true for active paywall
    } else {
      this.getCampaignDetails(details.id);
      this.handleDiscuss(details);
      this.handleLessonAssignment(details.id);
    }
  };

  isPageCourseCheck = (isPageCourse) => {
    this.props.updateIsPageCourse(isPageCourse);
  };

  openCallout = () => {
    this.setState({
      openCallout: true,
    });
  };

  handleCloseSidebar = (e) => {
    e.preventDefault();
    this.props.updateIsCourseDropdown(false);
    this.setState({
      isParticipants: false,
      isLessonChapterOpen: false,
    });
    this.props.updateIsCourseSidebar(false);
  };
  handleparticipants = () => {
    if (this.state.isParticipants || this.props.isCourseDropdown) {
      this.props.updateIsCourseDropdown(false);
      this.setState({
        isParticipants: false,
      });
    } else {
      this.setState({
        isParticipants: !this.state.isParticipants,
      });
    }
  };
  setIsCertificate = (IsCertificate) => {
    this.setState({ isCertificate: IsCertificate });
  };
  setIsCourseCompleted = (IsCourseCompleted) => {
    this.setState({ isCourseCompleted: IsCourseCompleted });
  };
  changeEssay = (data) => {
    const { selectedQuestion } = this.state;
    const questionLists = [...this.state.questionLists];
    const currentIndex = questionLists.findIndex(
      (x) => x.id === selectedQuestion.id
    );
    const question_options_attributes = [
      ...questionLists[currentIndex].question_options_attributes,
    ];

    let plainEssayContent = data;
    plainEssayContent = plainEssayContent.replace(/<[^>]+>/g, "");

    question_options_attributes[0].essayContent = data;
    question_options_attributes[0].plainEssayContent = plainEssayContent;

    this.setState({
      questionLists: questionLists,
      selectedQuestion: questionLists[currentIndex],
    });
  };

  onToggleClick = (evt, i) => {
    const { selectedQuestion } = this.state;
    const questionLists = [...this.state.questionLists];
    const currentIndex = questionLists.findIndex(
      (x) => x.id === selectedQuestion.id
    );

    const question_options_attributes = [
      ...questionLists[currentIndex].question_options_attributes,
    ];

    for (var index = 0; index < question_options_attributes.length; index++) {
      if (i != index) {
        question_options_attributes[index].selected_answer = false;
      } else {
        question_options_attributes[index].selected_answer = evt.target.checked;
      }
    }

    this.setState({
      questionLists: questionLists,
      selectedQuestion: questionLists[currentIndex],
    });
  };

  nextQuestion = () => {
    const { selectedQuestion, questionLists } = this.state;
    const index = questionLists.findIndex((x) => x.id === selectedQuestion.id);

    if (this.state.editQuestion !== true) {
      if (index > -1 && questionLists.length - 1 > index) {
        this.setState({
          selectedQuestion: questionLists[index + 1],
          count: this.state.count + 1,
        });
      } else {
        this.setState({
          assignmentEdit: true,
        });
      }
    } else {
      this.setState({
        editQuestion: false,
        assignmentEdit: true,
      });
    }
  };

  previousQuestion = () => {
    const { selectedQuestion, questionLists } = this.state;
    const index = questionLists.findIndex((x) => x.id === selectedQuestion.id);
    if (index > -1) {
      this.setState({
        selectedQuestion: questionLists[index - 1],
        count: this.state.count - 1,
      });
    }
  };

  submitQuestion = () => {
    this.props.showLoader(true);
    const { selectedQuestion, questionLists, assignmentId, user_id } =
      this.state;
    const currentIndex = selectedQuestion.question_options_attributes.findIndex(
      (x) => x.selected_answer === true || x.is_essay === true
    );
    const questionList = [...questionLists];
    const index = questionList.findIndex((x) => x.id === selectedQuestion.id);
    const question_options_attributes = [
      ...questionList[index].question_options_attributes,
    ];

    if (
      selectedQuestion.question_type === 3 &&
      (question_options_attributes[0].essayContent === undefined ||
        question_options_attributes[0].essayContent === "" ||
        question_options_attributes[0].essayContent === null)
    ) {
      this.props.showLoader(false);
      toast.error("Answer is empty", { containerId: "private" });
      return;
    }
    if (currentIndex > -1) {
      const selectOption =
        selectedQuestion.question_options_attributes[currentIndex];
      const params = {
        assignment_id: assignmentId,
        question_id: selectedQuestion.id,
        option_id: selectOption.id,
        is_correct: selectOption.is_correct,
        user_id: user_id,
        essay_content:
          selectedQuestion.question_type === 3
            ? question_options_attributes[0].essayContent
            : null,
        plain_essay_content:
          selectedQuestion.question_type === 3
            ? question_options_attributes[0].plainEssayContent
            : null,
      };

      const reqUrl = has(selectedQuestion, "answer_id")
        ? `${USER_ASSIGNMENT}/${selectedQuestion.answer_id}`
        : USER_ASSIGNMENT;
      const method = has(selectedQuestion, "answer_id") ? 3 : 2;
      Utility.sendRequest(reqUrl, method, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          this.props.showLoader(false);
        } else {
          if (!has(selectedQuestion, "answer_id")) {
            const { selectedQuestion } = this.state;
            const questionLists = [...this.state.questionLists];
            const currentIndex = questionLists.findIndex(
              (x) => x.id === selectedQuestion.id
            );
            questionLists[currentIndex].answer_id = body.data.id;
            this.setState({ questionLists: questionLists });
          }
          this.props.showLoader(false);
          this.nextQuestion();
        }
      });
    } else {
      this.props.showLoader(false);
      toast.error("Please select at least one answer", {
        containerId: "private",
      });
    }
  };

  handleStartAssignment = (e, id, campaign_id) => {
    e.preventDefault();
    this.props.showLoader(true);
    const params = { assignment_id: id };
    Utility.sendRequest(CREATE_EDIT_QUESTIONS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
        this.props.showLoader(false);
      } else {
        this.props.showLoader(false);
        const questionList = body.data.filter((data) => {
          return data.question_type === 3;
        });
        this.setState({
          assignmentId: id,
          // questionLists: body.data,
          questionLists: body.data.filter((questionsData) => {
            return questionsData.question_type === 3;
          }),
          assignmentCampainId: campaign_id,
          openCallout: true,
          selectedQuestion:
            body.data.filter((questionsData) => {
              return questionsData.question_type === 3;
            }) &&
            body.data.filter((questionsData) => {
              return questionsData.question_type === 3;
            }).length > 0
              ? body.data.filter((questionsData) => {
                  return questionsData.question_type === 3;
                })[0]
              : {},
        });
      }
    });
  };

  courseCompleted = () => {
    let totalLessons = 0;
    this.state.sectionList.forEach((section) => {
      if (section.lessons === null) totalLessons += 0;
      else totalLessons += section.lessons.length;
    });

    const list = _.flattenDeep(
      this.state.sectionList.map((section) => section.lessons)
    );
    const completedLessonLength = this.state.userAssignmentDetails.filter(
      (assignment) =>
        list.find((lesson) => lesson.id === assignment.campaign_id)
    ).length;

    if (totalLessons && completedLessonLength) {
      if (
        completedLessonLength + 1 === totalLessons &&
        this.state.userBadgeforCourse.length === 0 &&
        CLIENT_ID === GIL_CLIENT_ID
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  editQuestion = (item) => {
    const { questionLists } = this.state;
    const index = questionLists.findIndex((x) => x.id === item.id);
    if (index > -1) {
      this.setState({
        editQuestion: true,
        selectedQuestion: questionLists[index],
        assignmentEdit: false,
      });
    }
  };

  getCourseAssignment = (SectionData) => {
    SectionData.map(
      (Sections, index) =>
        Sections.lessons &&
        Sections.lessons.map((LessonData) => {
          this.handleLessonAssignment(LessonData.id, "CourseAssignment");
        })
    );
  };

  submitAssignmentDetails = () => {
    this.props.showLoader(true);
    const {
      selectedQuestion,
      questionLists,
      assignmentId,
      user_id,
      campaignId,
    } = this.state;
    let totalCorrectAnswers = 0;
    questionLists.forEach(function (val, index) {
      const details = filter(
        val.question_options_attributes,
        (value) =>
          (value.is_correct === 1 && value.selected_answer === true) ||
          value.is_essay === true
      );
      if (details.length > 0) {
        totalCorrectAnswers = totalCorrectAnswers + 1;
      }
    });

    const score = (totalCorrectAnswers * 100) / questionLists.length;

    const params = {
      assignment_id: assignmentId,
      user_id: user_id,
      campaign_id: this.state.assignmentCampainId,
      total_attempted_questions: questionLists.length,
      total_correct_answers: totalCorrectAnswers,
      score: score,
      status: 3,
    };

    Utility.sendRequest(
      USER_ASSIGNMENT_DETAILS,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          this.props.showLoader(false);
        } else {
          this.setState({
            assignmentResult: true,
            assignmentEdit: false,
            score: score,
          });
          const showSuccessScreen = this.courseCompleted();
          if (showSuccessScreen === true) {
            const params = {
              user_client_id: this.props.userData.id,
              badge_id: COURSE_BADGE_ID,
            };

            Utility.sendRequest(
              USER_BADGES,
              2,
              params,
              (error, response, body) => {
                body = JSON.parse(body);
                if (!body.error) {
                  this.setState({ courseCompleteSuccessCallout: true });
                  this.props.showLoader(false);
                }
              }
            );
          }
          this.props.showLoader(false);
        }
      }
    );
  };

  closeAssignment = () => {
    this.setState({
      assignmentResult: false,
      openCallout: false,
      assignmentEdit: false,
    });
    this.getCampaignDetails();
    this.setState({ isLesson: false });
    this.setState({ activePanal: "" });
  };

  closeProfile = () => {
    this.setState({
      openProfile: false,
    });
  };

  openProfilePopUp = () => {
    this.setState({
      openProfile: true,
    });
  };

  handleCourseDetailItem = (selected) => {
    this.setState({ selectedItem: selected });
  };

  previewPanal = () => {
    switch (this.state.activePanal) {
      case "assignments":
        return (
          <Assignments
            getAllParentCampaigns={this.getAllParentCampaigns}
            lessondata={this.state.LessonAllAssignments}
            submitAssignmentDetails={this.submitAssignmentDetails}
            handleLessonAssignment={this.handleLessonAssignment}
            getUserAssignmentDetails={this.getUserAssignmentDetails}
            {...this.state}
          />
        );
      case "discussions":
        return <UserCommentContainer {...this.state} />;
      case "peers":
        return (
          <Partcipants getAllMembers={this.getAllMembers} {...this.state}  openProfilePopUp={this.openProfilePopUp}/>
        );
      default:
        return <></>;
    }
  };

  getCommentCounts = (SectionData) => {
    SectionData.
    map(
      (Sections, index) =>
        Sections.lessons &&
        Sections.lessons
        .map((LessonData) => {
           const params = { lesson_id: LessonData.id };
           this.props.showLoader(true);
           Utility.sendRequest(
             GET_DISCUSSION_COMMENTS,
             1,
             params,
             (err, res, body) => {
               body = JSON.parse(body);
               if (body.error) {
                 toast.error(body.error.message, { containerId: "private" });
               } else {
                 this.state.CommentCount.push({ lesson_id:LessonData.id, lesson_heading:LessonData.heading, count:body.data.length,seq_id:LessonData.seq_id }); 
               }
              // this.props.showLoader(false);
             }
           );
        })
    );
  };
 
  render() {
    const { isCourse } = this.state;
    const data =
      this.state.campaignDetails.length > 0 &&
      this.state.campaignDetails[this.state.index];
    const camTypeName = this.getCampTypeName();
    const pageHeading =
      this.props.calledFrom === "AgendaList"
        ? "Agenda Details"
        : this.state.isEvent
        ? "Event Details"
        : Utility.capitalize(data.heading || "");
    const campaignDetails = this.state.campaignDetails[this.state.index];
    const isPageCourse =
      data.campaign_type_id === CAMPAIGN_TYPES_IDS.courses ||
      data.campaign_type_id === CAMPAIGN_TYPES_IDS.lesson ||
      data.campaign_type_id === CAMPAIGN_TYPES_IDS.section;

    let totalAssign = 0;
    let totalCompletedAssign = 0;
    let totalLesson = 0;
    let totalCompletedLesson = 0;
    var d1 = new Date(0);
    const CourseAssignment=_.flattenDeep( this.state.CourseAssignment );
  const discussionCommentsCounts = [...new Map(this.state.CommentCount.map(item =>
    [item['lesson_id'], item])).values()];
  

    this.state.allAssignments
      .map((data) => data)
      .forEach((list) => {
        var temp = 0;
        if (list.assignmentList !== null) {
          totalAssign = totalAssign + list.assignmentList.length;
          list.assignmentList
            .map((list) => list)
            .forEach((data) => {
              if (data.score != 0) {
                totalCompletedAssign += 1;
                temp = temp + 1;
                let d2 = new Date(data.score_last_updated);
                if (d1.getTime() < d2.getTime()) d1 = d2;
              }
            });
          if (temp === list.assignmentList.length) totalCompletedLesson += 1;
        }

        if (list.lessonHeading !== null) totalLesson += 1;
      });

    const Score = Math.round(
      ((totalCompletedLesson + totalCompletedAssign) * 100) /
        (totalLesson + totalAssign)
    );
    const year = d1.getFullYear();
    const day = d1.getDate();
    const month = date.monthNameByNumber(d1.getMonth() + 1);
    return (
      <>
        {CLIENT_ID === GIL_CLIENT_ID && isPageCourse ? (
          <CourseHeader {...this.state} pageHeading={pageHeading} />
        ) : (
          ""
        )}
        {isPageCourse
          ? this.isPageCourseCheck(true)
          : this.isPageCourseCheck(false)}
        {this.state.campaignDetails.length > 0 && (
          <div
            className={
              CLIENT_ID === GIL_CLIENT_ID && isPageCourse
                ? "row flex-lg-nowrap mx-0 h-100 mt-5"
                : "row flex-lg-nowrap mx-0 h-100 scroll-y"
            }
          >
            {CLIENT_ID === GIL_CLIENT_ID && isPageCourse ? (
              <>
                <CourseSideBar
                  {...this.state}
                  openProfilePopUp={this.openProfilePopUp}
                  loadLession={this.loadLession}
                  handleDiscuss={this.handleDiscuss}
                  setIsCertificate={this.setIsCertificate}
                  handleStartAssignment={this.handleStartAssignment}
                  handleOpenCourseSidebar={this.handleOpenCourseSidebar}
                  handleCloseCourseSidebar={this.handleCloseCourseSidebar}
                  className={
                    this.state.isCourseSidebar && "courseSideBarExpanded"
                  }
                  getAllMembers={this.getAllMembers}
                  history={this.props.history}
                ></CourseSideBar>

                {this.state.openCallout &&
                  this.state.questionLists &&
                  this.state.questionLists.length > 0 && (
                    <SideCallout
                      position="position-absolute assignment-callout"
                      closeCallOut={this.closeAssignment}
                      calloutHeading={"Assignment"}
                      calloutHeadingSvg={
                        <AssignmentCheckIcon mainClass="assignment-icon" />
                      }
                      isCourseAssignment={true}
                      fromPlusIcon={true}
                    >
                      {this.state.questionLists &&
                        this.state.questionLists.length > 0 &&
                        this.state.questionLists.map((item, index) => (
                          <>
                            <div
                              className={`item ${
                                this.state.selectedQuestion.id === item.id
                                  ? "show-quiz"
                                  : "hide-quiz"
                              }`}
                              key={`question_${index}`}
                            >
                              {this.state.openCallout && (
                                <AssignmentQuestion
                                  {...item}
                                  {...this.state}
                                  closeCallOut={this.closeAssignment}
                                  changeEssay={this.changeEssay}
                                  essayContent={this.state.essayContent}
                                  nextQuestion={this.submitQuestion}
                                  onToggleClick={this.onToggleClick}
                                  previousQuestion={this.previousQuestion}
                                  closeCallOut={this.closeAssignment}
                                />
                              )}
                            </div>
                          </>
                        ))}
                    </SideCallout>
                  )}
                {this.state.assignmentResult && (
                  <SideCallout
                    position="position-absolute assignment-callout"
                    closeCallOut={this.closeAssignment}
                    calloutHeading={"Assignment"}
                    isCourseAssignment={true}
                    calloutHeadingSvg={
                      <AssignmentCheckIcon mainClass="assignment-icon" />
                    }
                    fromPlusIcon={true}
                  >
                    <AssignmentResult
                      {...this.state}
                      {...this.props}
                      ShowCertificate={false}
                      courseCompleteSuccessCallout={
                        this.state.courseCompleteSuccessCallout
                      }
                      goToHome={() => this.props.history.push("/")}
                      goToCourse={() => {
                        this.closeAssignment();
                        this.setState({ courseCompleteSuccessCallout: false });
                      }}
                      closeCallOut={() =>
                        this.setState({ courseCompleteSuccessCallout: false })
                      }
                    />
                  </SideCallout>
                )}

                {this.state.assignmentEdit && (
                  <SideCallout
                    position="position-absolute assignment-callout"
                    closeCallOut={this.closeAssignment}
                    isCourseAssignment={true}
                    calloutHeading={"Assignment"}
                    calloutHeadingSvg={
                      <AssignmentCheckIcon mainClass="assignment-icon" />
                    }
                    fromPlusIcon={true}
                  >
                    <AssignmentEdit
                      {...this.state}
                      {...this.props}
                      editQuestion={this.editQuestion}
                      submitAssignmentDetails={this.submitAssignmentDetails}
                      closeCallOut={this.closeAssignment}
                    />
                  </SideCallout>
                )}
              </>
            ) : (
              ""
            )}

            {isPageCourse ? (
              CLIENT_ID === GIL_CLIENT_ID && this.state.isCertificate ? (
                <CourseCertificate
                  {...this.state}
                  Score={Score}
                  year={year}
                  day={day}
                  month={month}
                  isCourseSidebar={this.props.isCourseSidebar}
                  userData={this.props.userData}
                  setIsCourseCompleted={this.setIsCourseCompleted}
                />
              ) : (
                <>
                  <div
                    className={`article-page-outer course-page-outer ${
                      this.state.openModal ? "article-page-outer-sm" : ""
                    }
                  ${this.props.isCourseSidebar && "CoursePageExpantion"}
                `}
                    id="article-detail"
                  >
                    {/* {console.log("this===", this.props.isCourseSidebar)} */}
                    {this.state.loadedFrom !== "campaignModule" && (
                      <div
                        className="article-page-back cursor-pointer"
                        onClick={this.goBack}
                      >
                        {CLIENT_ID !== GIL_CLIENT_ID && (
                          <WhiteFilledAddLargeIcon fillClass="fill-primary" />
                        )}
                      </div>
                    )}
                    <div className="position-relative article-page-inner-body">
                      <CarouselContainer
                        campaignDetails={this.state.campaignDetails}
                        isUserDistracted={this.props.isUserDistracted}
                        updateUserDistracted={() =>
                          this.props.updateUserDistracted(false)
                        }
                        campaignId={data.campaign_id}
                        userClientId={this.props.userData.id}
                        setFollowerfollowing={this.setFollowerfollowing}
                        switchRightSection={this.switchRightSection}
                        commentsCount={this.state.commentsCount}
                        getSentimentDetails={this.getSentimentDetails}
                        isLiked={this.state.isLiked}
                        likesCount={this.state.likesCount}
                        handleLikesCount={this.handleLikesCount}
                        handleSelect={this.handleSelect}
                        index={this.state.index}
                        calledFrom={this.state.calledFrom}
                        isFollowed={this.state.isFollowed}
                        isIndicators={
                          parseInt(this.state.campaignDetails.length) > 1
                        }
                        download={
                          ACCOUNT_TYPE["student"] !==
                            this.props.userData.category_id ||
                          this.state.isTransactionSuccessful ||
                          this.props.isPlanUpgraded
                        }
                        openPaywall={(videoPaywall) =>
                          this.setState({
                            paywallFor: videoPaywall,
                            paywall: true,
                          })
                        }
                        isStudent={
                          ACCOUNT_TYPE["student"] ===
                          this.props.userData.category_id
                        }
                        isLesson={this.state.isLesson}
                      />
                    </div>
                    {CLIENT_ID === GIL_CLIENT_ID && !this.state.isLesson && (
                      <div className="d-flex pt-2 pl-4 pb-2 align-items-center">
                        <div className="p-2 bd-highlight">
                          <h5>
                            {this.state.courseDetails[0] !== undefined &&
                              this.state.courseDetails[0].heading}
                          </h5>
                        </div>
                        <div className="ml-auto mr-2">
                          <HiDotsHorizontal />
                        </div>
                      </div>
                    )}
                    {CLIENT_ID === GIL_CLIENT_ID && !this.state.isLesson && (
                      <CourseDetailsPage
                        {...this.state}
                        handleChangepanal={this.handleChangepanal}
                        handleCourseDetailItem={this.handleCourseDetailItem}
                        totalAssign={totalAssign}
                        totalCompletedAssign={totalCompletedAssign}
                        totalCompletedLesson={totalCompletedLesson}
                        totalLesson={totalLesson}
                        getCommentCounts={this.getCommentCounts}
                      />
                    )}
                    {this.state.isLesson && CLIENT_ID === GIL_CLIENT_ID && (
                      <>
                        <CourseDetailsHeader
                          {...this.state}
                          handleChangepanal={this.handleChangepanal}
                        />
                        {this.state.activePanal === "lesson" && (
                          <div className="course_panal_header">
                            <CourseLessonIcon className="courseSidebarIcon" />
                            <div className="header_title">Lessons</div>
                          </div>
                        )}
                        <div className="commentScreen">
                          {this.previewPanal()}
                        </div>
                      </>
                    )}

                    {
                      <>
                        {((CLIENT_ID !== GIL_CLIENT_ID &&
                          !this.state.isLesson &&
                          this.state.selectedItem === "About") ||
                          this.state.activePanal === "lesson" ||
                          CLIENT_ID !== GIL_CLIENT_ID) && (
                          <div className="article-data-outer course-data-outer">
                            <div className="article-page-inner-head">
                              <div className="d-flex">
                                <div
                                  className="d-block d-md-none mr-3 pt-1 courses-back-btn"
                                  onClick={this.goBack}
                                >
                                  <img
                                    src={require("assets/images/icons/back.svg")}
                                    alt="back icon"
                                  />
                                </div>
                                <div>
                                  <div className="d-flex flex-wrap flex-lg-nowrap position-relative">
                                    <h1 className="font-24-bold mb-0 ml-lg-n1 ml-3">
                                      {pageHeading}
                                    </h1>
                                  </div>
                                  {!this.state.isEvent && (
                                    <div className="font-14 mt-lg-2 mb-3 article-page-pills">
                                      <span
                                        className="mr-4 font-bold"
                                        style={{ color: data.category_color }}
                                      >
                                        {data.campaign_category}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {CLIENT_ID !== GIL_CLIENT_ID &&
                              this.paintUpdateAtWrapper(data)}
                          </div>
                        )}
                        <div className="article-data-outer course-data-outer  pt-0">
                          {((CLIENT_ID === GIL_CLIENT_ID &&
                            !this.state.isLesson &&
                            this.state.selectedItem === "About") ||
                            this.state.activePanal === "lesson" ||
                            CLIENT_ID !== GIL_CLIENT_ID) && (
                            <>
                              {" "}
                              {!this.state.isLesson && (
                                <p className="pt-3 display-7 mb-3 font-bold pr-2 mb-0">
                                  {Utility.capitalize(
                                    data.sub_heading || "Course Description"
                                  )}
                                </p>
                              )}
                              <div className="article-description">
                                <p
                                  className="mb-5 font-14 pr-4 word-break-word"
                                  dangerouslySetInnerHTML={{
                                    __html: data.description,
                                  }}
                                />
                              </div>
                              {CLIENT_ID === GIL_CLIENT_ID &&
                                this.paintUpdateAtWrapper(data)}
                            </>
                          )}
                          {CLIENT_ID === GIL_CLIENT_ID &&
                            !this.state.isLesson &&
                            this.state.selectedItem === "Certificate" && (
                              <CourseCertificate
                                {...this.state}
                                Score={Score}
                                year={year}
                                day={day}
                                month={month}
                                isCourseSidebar={this.props.isCourseSidebar}
                                userData={this.props.userData}
                                setIsCourseCompleted={this.setIsCourseCompleted}
                              />
                            )}

                          {CLIENT_ID === GIL_CLIENT_ID &&
                            !this.state.isLesson &&
                            this.state.selectedItem === "Lessons" &&
                            this.state.sectionList
                              // .sort((section1, section2) =>
                              //   section1.heading.localeCompare(section2.heading)
                              // )
                              .map(
                                (Sections, index) =>
                                  Sections &&
                                  Sections.lessons &&
                                  Sections.lessons.length > 0 && (
                                    <React.Fragment key={index}>
                                      <div className="">
                                        <div className="row align-items-center mt-3 ml-3 mb-3">
                                          <div className="mb-3">
                                            {Sections && Sections.heading && (
                                              <div className="course-section-heading">
                                                {Sections.heading}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {Sections &&
                                        Sections.lessons &&
                                        Sections.lessons
                                          .sort(
                                            (lesson1, lesson2) =>
                                              lesson1.seq_id - lesson2.seq_id
                                          )
                                          .map((data, index) => {
                                            return (
                                              <div
                                                key={index}
                                                onClick={() =>
                                                  this.loadLession(data)
                                                }
                                                className="sub-chapter-item  mt-3 mb-4 ml-3"
                                              >
                                                <div className="section-data">
                                                  <div className="chapterSection mt-3 mb-4">
                                                    {this.state.userAssignmentDetails.find(
                                                      (completedLesson) =>
                                                        completedLesson.campaign_id ===
                                                        data.id
                                                    ) ? (
                                                      <LessonTickFilledIcon
                                                        mainClass="mr-2"
                                                        fillClass="fill-primary ml-3"
                                                      />
                                                    ) : (
                                                      <CircleFilled
                                                        mainclass="mr-2"
                                                        color={"lightgrey"}
                                                      />
                                                    )}{" "}
                                                    {data.heading}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                    </React.Fragment>
                                  )
                              )}
                          {CLIENT_ID === GIL_CLIENT_ID &&
                            !this.state.isLesson &&
                            this.state.selectedItem === "Share" &&
                            // <CourseShare {...this.state} />
                            ""}

                          {CLIENT_ID === GIL_CLIENT_ID &&
                            this.state.openProfile && (
                              // <CourseShare {...this.state}/>
                              <SideCallout
                                position="position-absolute assignment-callout"
                                closeCallOut={this.closeProfile}
                                isProfilePage={true}
                              >
                                <ProfileContainer
                                  closeCallOut={this.closeProfile}
                                  location=""
                                  id={this.props.userProfileId}
                                />
                              </SideCallout>
                            )}

                          {CLIENT_ID === GIL_CLIENT_ID &&
                            !this.state.isLesson &&
                            this.state.selectedItem === "Assignments" &&
                            this.state.sectionList
                              // .sort((section1, section2) =>
                              //   section1.heading.localeCompare(section2.heading)
                              // )
                              .map(
                                (Sections, index) =>
                                  Sections &&
                                  Sections.lessons &&
                                  Sections.lessons.length > 0 && (
                                    <React.Fragment key={index}>
                                      {Sections &&
                                        Sections.lessons &&
                                        Sections.lessons
                                        .sort((lesson1, lesson2) =>
                                        lesson1.heading.localeCompare(lesson2.heading)
                                      )
                                          .map((data, index) => {
                                            return (
                                              <div key={index}>
                                                {CourseAssignment.map((assignment) => {
                                                  // return  <>{assignment}</>;
                                                  if (
                                                    assignment.campaign_id ===
                                                    data.id
                                                  )
                                                    return (
                                                      <React.Fragment>
                                                        <Assignments
                                                          getAllParentCampaigns={
                                                            this
                                                              .getAllParentCampaigns
                                                          }
                                                          lessondata={[
                                                            assignment,
                                                          ]}
                                                          submitAssignmentDetails={
                                                            this
                                                              .submitAssignmentDetails
                                                          }
                                                          handleLessonAssignment={
                                                            this
                                                              .handleLessonAssignment
                                                          }
                                                          getUserAssignmentDetails={
                                                            this
                                                              .getUserAssignmentDetails
                                                          }
                                                          {...this.state}
                                                        />
                                                      </React.Fragment>
                                                    );
                                                })}
                                              </div>
                                            );
                                          })}
                                    </React.Fragment>
                                  )
                              )}

                          {CLIENT_ID === GIL_CLIENT_ID &&
                            !this.state.isLesson &&
                            this.state.selectedItem === "Discussions" &&
                            this.state.sectionList.length !== 0 &&
                            // this.state.CommentCount.length!==0 &&
                            
                            this.state.sectionList.map((Sections, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <div className="">
                                    <div className="row align-items-center mt-3 ml-3 mb-3">
                                      <div className="mb-3">
                                        {Sections && Sections.heading && (
                                          <div className="course-section-heading">
                                            {Sections.heading}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {Sections &&
                                    Sections.lessons &&
                                    Sections.lessons
                                      .sort(
                                        (lesson1, lesson2) =>
                                          lesson1.seq_id - lesson2.seq_id
                                      )
                                      .map((data, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="sub-chapter-item  mt-3 mb-4 "
                                          >
                                            <div className="section-data d-flex flex-row">
                                              <div
                                                onClick={() =>
                                                  this.handleDiscuss(
                                                    data,
                                                    "discussions"
                                                  )
                                                }
                                                className="cursor-pointer course-chapterSection"
                                              >
                                                {/* <img
                                                className="discuss-wea-logo"
                                                src={require("assets/images/city_specific_images/passportIcon.svg")}
                                                alt=""
                                              />{" "} */}
                                                <PassportIcon />
                                                {data.heading} Discussion
                                              </div>
                                              <div className="ml-auto">
                                                <span className="discussionCount rounded text-white px-3 p-1">
                                                  
                                                  {discussionCommentsCounts.map(
                                                    (lesson) => {
                                                      if (
                                                        lesson.lesson_id ===
                                                        data.id
                                                      )
                                                        return (
                                                          <>{lesson.count}</>
                                                        );
                                                    }
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                </React.Fragment>
                              );
                            })}
                          {CLIENT_ID === GIL_CLIENT_ID &&
                            !this.state.isLesson &&
                            this.state.selectedItem === "Peers" && (
                              <Partcipants
                                getAllMembers={this.getAllMembers}
                                hideHeading={true}
                                {...this.state}
                                openProfilePopUp={this.openProfilePopUp}
                              />
                            )}
                          {!this.state.isEvent && (
                            <>
                              {!isCourse && !this.state.isLesson && (
                                <div className="mb-42 content-creator-outer">
                                  <FollowerInfo
                                    id={data.user_client_id}
                                    selectedUser={data}
                                    name={data.creator_name}
                                    imageUrl={data.creator_profile_image_url}
                                    isFollowed={this.state.isFollowed}
                                    setFollowerfollowing={
                                      this.setFollowerfollowing
                                    }
                                  />
                                </div>
                              )}

                              {!isCourse && !this.state.isLesson && (
                                <div className="pt-3 article-page-suggested-reads">
                                  <h1 className="display-7 font-bold mb-4">
                                    Recommended{" "}
                                    {pluralize(
                                      Utility.capitalize(camTypeName || "")
                                    )}
                                  </h1>
                                  <div className="home-page-city-ethos">
                                    <div className="landing-page-stories d-flex flex-wrap">
                                      <SuggestedReadsCard
                                        stories={this.state.campaignsTopStories}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {CLIENT_ID !== GIL_CLIENT_ID &&
                                this.state.isLesson &&
                                this.state.assignmentList.map((item, index) => (
                                  <AssigmentList
                                    key={index}
                                    {...this.state}
                                    item={item}
                                    key={index}
                                    platform_Id={
                                      this.props.userData.platform_id
                                    }
                                    history={this.props.history}
                                    refreshCourse={() => {
                                      this.getCampaignDetails();
                                      this.setState({ isLesson: false });
                                    }}
                                  />
                                ))}
                              {isCourse && !this.state.isLesson && (
                                <Certificate
                                  platform_Id={this.props.userData.platform_id}
                                  {...this.state}
                                />
                              )}
                              {this.props.userData.platform_id ===
                                ETHOS_CLIENT_ID &&
                                !Utility.isEmpty(data.document_url) && (
                                  <DownloadContent
                                    data={data}
                                    download={
                                      ACCOUNT_TYPE["student"] !==
                                        this.props.userData.category_id ||
                                      this.state.isTransactionSuccessful ||
                                      this.props.isPlanUpgraded
                                    }
                                    openPaywall={() =>
                                      this.setState({ paywall: true })
                                    }
                                  />
                                )}
                            </>
                          )}
                          {this.paintCreator()}
                          {this.paintCommunityCauses()}
                          {this.paintOrganizers()}
                          {campaignDetails.is_calendar_added &&
                            campaignDetails.is_active &&
                            this.state.isEvent && (
                              <div
                                className="text-center mb-42"
                                onClick={this.removeFromCalendar}
                              >
                                <span className="text-primary font-bold display-7 cursor-pointer">
                                  Remove Event
                                </span>
                              </div>
                            )}
                        </div>
                      </>
                    }
                  </div>
                  {isPageCourse && CLIENT_ID !== GIL_CLIENT_ID && (
                    <ExpandableOptions
                      closeModal={(data) => this.setState({ openModal: data })}
                      openModal={this.state.openModal}
                      activeTab={this.state.activeRightSection}
                      switchRightSection={this.switchRightSection}
                      isCmapaignVideoPlaying={this.props.isCmapaignVideoPlaying}
                      updateCampaignVideoPlaying={
                        this.props.updateCampaignVideoPlaying
                      }
                      isCourseType={
                        isPageCourse ||
                        data.campaign_type_id === CAMPAIGN_TYPES_IDS.lesson
                      }
                    >
                      {this.state.activeRightSection !== "none" && (
                        <div
                          className={`expandable-inner ${
                            isCourse ? "cs-page-side-outer" : ""
                          }`}
                        >
                          {this.state.activeRightSection === "chat" && (
                            <CampaignChat
                              loadedFrom={this.state.loadedFrom}
                              campaignId={data.campaign_id}
                              user_client_id={this.props.userData.id}
                              incrementCommentCount={this.incrementCommentCount}
                              switchRightSection={this.switchRightSection}
                              getCampaignDetails={() =>
                                this.getCampaignDetails("chat")
                              }
                              campaignChatHeading={
                                this.state.isEvent
                                  ? "Event Comments"
                                  : "Article Comments"
                              }
                            />
                          )}

                          {this.state.activeRightSection === "course" && (
                            <CourseDetails
                              {...this.state}
                              loadLession={this.loadLession}
                              refreshCourse={() => {
                                this.getCampaignDetails();
                                this.setState({ isLesson: false });
                                Utility.pwaModeEnabled() &&
                                  this.setState({ openModal: false });
                              }}
                            />
                          )}

                          {this.state.activeRightSection === "share" && (
                            <Share
                              loadedFrom={this.state.loadedFrom}
                              switchRightSection={this.switchRightSection}
                              campaignId={data.campaign_id}
                              closeForm={() =>
                                this.setState({
                                  activeRightSection:
                                    this.state.loadedFrom === "campaignModule"
                                      ? "none"
                                      : "chat",
                                })
                              }
                              userData={this.props.userData}
                              groups={data.groups}
                              getCampaignDetails={() =>
                                this.getCampaignDetails("share")
                              }
                              isEvent={this.state.isEvent}
                            />
                          )}
                          {/* {
                          console.log(this.state.allMembers)
                        } */}
                          {this.state.activeRightSection ===
                            "viewingMembers" && (
                            <ViewingMembers
                              campaignId={data.campaign_id}
                              allMembers={this.state.allMembers}
                              getAllMembers={this.getAllMembers}
                              loadedFrom={this.state.loadedFrom}
                              setFollowerfollowing={this.setFollowerfollowing}
                              switchRightSection={this.switchRightSection}
                              heading={
                                this.state.isEvent
                                  ? "Members Attending"
                                  : "Viewing Members"
                              }
                            />
                          )}
                          {this.state.activeRightSection === "viewAgenda" && (
                            <ViewAgendaList
                              campaignId={data.campaign_id}
                              switchRightSection={this.switchRightSection}
                              closeForm={() =>
                                this.setState({ activeRightSection: "chat" })
                              }
                              userData={this.props.userData}
                              isAgenda={this.state.isAgenda}
                              parentId={data.campaign_parent_id}
                              eventDate={data.start_at}
                            />
                          )}
                        </div>
                      )}
                    </ExpandableOptions>
                  )}
                </>
              )
            ) : (
              <>
                <div
                  className={`article-page-outer ${
                    this.state.isEvent ? "event-page-outer" : ""
                  } `}
                  id="article-detail"
                >
                  <div className="article-page-inner-head">
                    <div className="d-flex flex-wrap flex-lg-nowrap position-relative">
                      {this.state.loadedFrom !== "campaignModule" && (
                        <div
                          className={`article-page-back p-4 cursor-pointer position-absolute ${
                            this.state.isEvent ? "event-close-btn" : ""
                          }`}
                          onClick={this.goBack}
                        >
                          <ChevronLeftIcon />
                        </div>
                      )}
                      <h1 className="font-24-bold mb-0 ml-lg-n1 ml-3">
                        {pageHeading}
                      </h1>
                      {(!campaignDetails.is_calendar_added ||
                        !campaignDetails.is_active) &&
                        this.state.isEvent &&
                        new Date(data.start_at) >= new Date() && (
                          <button
                            className="btn btn-primary btn-sm btn-primary-pwa ml-auto mr-3 border-radius-8 btn-add-event"
                            onClick={this.addToCalendar}
                          >
                            <AddCalendarIcon mainClass="let-2" />
                            ADD
                          </button>
                        )}
                    </div>
                    {!this.state.isEvent && (
                      <div className="font-14 mt-lg-2 mb-3 article-page-pills">
                        <span
                          className="mr-4 font-bold"
                          style={{ color: data.category_color }}
                        >
                          {data.campaign_category}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="event-details-outer">
                    {this.state.isEvent && (
                      <div className="event-details-head mb-4 mt-4 pt-2 align-items-center">
                        <div className="additional-card-head position-relative">
                          <div className="additional-card-date text-center py-2 px-3 lh-1">
                            <div className="font-14 font-bold mb-1">
                              {this.getMonthName(data.start_at)}
                            </div>
                            <div className="font-32-bold">
                              {new Date(data.start_at).getDate()}
                            </div>
                          </div>
                        </div>
                        <div className="ml-2 pl-1">
                          <div
                            style={{ color: data.category_color }}
                            className="font-bold font-14 mb-2"
                          >
                            {data.campaign_category}
                          </div>
                          <div className="font-24-bold">
                            {this.eventOrAgendaHeading(data)}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.isEvent && (
                      <div className="d-flex flex-wrap text-muted font-semi-bold ml-2 display-8 event-info-icons-setter">
                        <div className="align-items-center lh-1 mb-3 w-100">
                          <ClockIcon mainClass="mr-2" />
                          <span className="pl-1">
                            {this.getTime(data.start_at)}
                          </span>
                        </div>
                        {data.location && (
                          <div className="align-items-center lh-1 mb-3 w-100">
                            <LocationIcon mainClass="mr-2" />
                            <span className="pl-1">{data.location}</span>
                          </div>
                        )}
                        <div
                          className="align-items-center lh-1 mb-3 w-100"
                          onClick={() =>
                            this.switchRightSection("viewingMembers")
                          }
                        >
                          <MemberGroupIcon
                            fillClass="fill-agenda"
                            mainClass="mr-2 icon-agenda"
                          />
                          <span className="pl-1">
                            {this.state.membersCount} Members Attending
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.paintUpdateAtWrapper(data)}
                  <div className="position-relative article-page-inner-body">
                    <CarouselContainer
                      campaignDetails={this.state.campaignDetails}
                      campaignId={data.campaign_id}
                      userClientId={this.props.userData.id}
                      setFollowerfollowing={this.setFollowerfollowing}
                      switchRightSection={this.switchRightSection}
                      commentsCount={this.state.commentsCount}
                      getSentimentDetails={this.getSentimentDetails}
                      isLiked={this.state.isLiked}
                      likesCount={this.state.likesCount}
                      handleLikesCount={this.handleLikesCount}
                      handleSelect={this.handleSelect}
                      index={this.state.index}
                      calledFrom={this.state.calledFrom}
                      isFollowed={this.state.isFollowed}
                      isIndicators={
                        parseInt(this.state.campaignDetails.length) > 1
                      }
                      download={
                        ACCOUNT_TYPE["student"] !==
                          this.props.userData.category_id ||
                        this.state.istransactionSuccessful ||
                        this.props.isPlanUpgraded
                      }
                      openPaywall={(videoPaywall) =>
                        this.setState({
                          paywallFor: videoPaywall,
                          paywall: true,
                        })
                      }
                      isStudent={
                        ACCOUNT_TYPE["student"] ===
                        this.props.userData.category_id
                      }
                      isArticle={true}
                    />
                  </div>
                  {(data.campaign_type_id !== CAMPAIGN_TYPES_IDS.lesson ||
                    data.campaign_type_id !== CAMPAIGN_TYPES_IDS.courses) && (
                    <div
                      className="cursor-pointer article-page-members font-14 text-primary pt-4 pb-3 border-bottom col-lg-6"
                      onClick={() => {
                        const section = this.state.isEvent
                          ? "viewAgenda"
                          : "viewingMembers";
                        this.switchRightSection(section);
                      }}
                    >
                      {this.state.isEvent ? (
                        <AgendaIcon fillClass="fill-primary" />
                      ) : (
                        <MemberGroupIcon
                          mainClass="article-page-members-img"
                          fillClass="fill-primary"
                        />
                      )}
                      <span className="font-bold ml-2">
                        {this.state.isEvent
                          ? "See Agenda"
                          : this.state.allMembers.length > 1
                          ? "Viewing Members"
                          : "Viewing Member"}{" "}
                      </span>
                      {!this.state.isEvent && (
                        <span className="font-italic">
                          ({this.state.allMembers.length})
                        </span>
                      )}
                    </div>
                  )}
                  <p className="pt-4 display-7 font-bold pr-4">
                    {Utility.capitalize(data.sub_heading || "")}
                  </p>
                  <div className="article-description">
                    <p
                      className="mb-5 font-14 pr-4 word-break-word"
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  </div>

                  {!this.state.isEvent && (
                    <>
                      {!isCourse && !this.state.isLesson && (
                        <div className="mb-42 content-creator-outer">
                          <FollowerInfo
                            id={data.user_client_id}
                            selectedUser={data}
                            name={data.creator_name}
                            imageUrl={data.creator_profile_image_url}
                            isFollowed={this.state.isFollowed}
                            setFollowerfollowing={this.setFollowerfollowing}
                          />
                        </div>
                      )}

                      {!isCourse && !this.state.isLesson && (
                        <div className="pt-3 article-page-suggested-reads">
                          <h1 className="display-7 font-bold mb-4">
                            Recommended{" "}
                            {pluralize(Utility.capitalize(camTypeName || ""))}
                          </h1>
                          <div className="home-page-city-ethos">
                            <div className="landing-page-stories d-flex flex-wrap">
                              <SuggestedReadsCard
                                stories={this.state.campaignsTopStories}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.isLesson &&
                        this.state.assignmentList.map((item, index) => (
                          <AssigmentList
                            key={index}
                            {...this.state}
                            item={item}
                            history={this.props.history}
                            refreshCourse={() => {
                              this.getCampaignDetails();
                              this.setState({ isLesson: false });
                            }}
                          />
                        ))}
                      {isCourse && !this.state.isLesson && (
                        <Certificate
                          platform_Id={this.props.userData.platform_id}
                          {...this.state}
                        />
                      )}
                      {this.props.userData.platform_id === ETHOS_CLIENT_ID &&
                        !Utility.isEmpty(data.document_url) && (
                          <DownloadContent
                            data={data}
                            download={
                              ACCOUNT_TYPE["student"] !==
                                this.props.userData.category_id ||
                              this.state.istransactionSuccessful ||
                              this.props.isPlanUpgraded
                            }
                            openPaywall={() => this.setState({ paywall: true })}
                          />
                        )}
                    </>
                  )}
                  {this.paintCreator()}
                  {this.paintCommunityCauses()}
                  {this.paintOrganizers()}
                  {campaignDetails.is_calendar_added &&
                    campaignDetails.is_active &&
                    this.state.isEvent && (
                      <div
                        className="text-center mb-42"
                        onClick={this.removeFromCalendar}
                      >
                        <span className="text-primary font-bold display-7 cursor-pointer">
                          Remove Event
                        </span>
                      </div>
                    )}
                </div>
                {CLIENT_ID === GIL_CLIENT_ID
                  ? this.state.activeRightSection !== "none" && (
                      <div className="article-page-side-outer scroll-y overflow-auto w-25">
                        {this.state.activeRightSection === "chat" && (
                          <CampaignChat
                            loadedFrom={this.state.loadedFrom}
                            campaignId={data.campaign_id}
                            user_client_id={this.props.userData.id}
                            incrementCommentCount={this.incrementCommentCount}
                            switchRightSection={this.switchRightSection}
                            getCampaignDetails={() =>
                              this.getCampaignDetails("chat")
                            }
                            campaignChatHeading={
                              this.state.isEvent
                                ? "Event Comments"
                                : "Article Comments"
                            }
                          />
                        )}

                        {this.state.activeRightSection === "share" && (
                          <Share
                            loadedFrom={this.state.loadedFrom}
                            switchRightSection={this.switchRightSection}
                            campaignId={data.campaign_id}
                            closeForm={() =>
                              this.setState({
                                activeRightSection:
                                  this.state.loadedFrom === "campaignModule"
                                    ? "none"
                                    : "chat",
                              })
                            }
                            userData={this.props.userData}
                            groups={data.groups}
                            getCampaignDetails={() =>
                              this.getCampaignDetails("share")
                            }
                            isEvent={this.state.isEvent}
                          />
                        )}
                        {this.state.activeRightSection === "viewingMembers" && (
                          <ViewingMembers
                            campaignId={data.campaign_id}
                            allMembers={this.state.allMembers}
                            getAllMembers={this.getAllMembers}
                            loadedFrom={this.state.loadedFrom}
                            setFollowerfollowing={this.setFollowerfollowing}
                            switchRightSection={this.switchRightSection}
                            heading={
                              this.state.isEvent
                                ? "Members Attending"
                                : "Viewing Members"
                            }
                          />
                        )}
                        {this.state.activeRightSection === "sendMoney" && (
                          <SendMoney
                            campaignCreator={data}
                            nonProfits={this.state.nonProfits}
                            loadedFrom={this.state.loadedFrom}
                            switchRightSection={this.switchRightSection}
                            getCampaignDetails={this.getCampaignDetails}
                          />
                        )}
                        {this.state.activeRightSection === "viewAgenda" && (
                          <ViewAgendaList
                            campaignId={data.campaign_id}
                            switchRightSection={this.switchRightSection}
                            closeForm={() =>
                              this.setState({ activeRightSection: "none" })
                            }
                            userData={this.props.userData}
                            isAgenda={this.state.isAgenda}
                            parentId={data.campaign_parent_id}
                            eventDate={data.start_at}
                          />
                        )}
                      </div>
                    )
                  : this.state.activeRightSection !== "none" && (
                      <div className="article-page-side-outer scroll-y overflow-auto">
                        {this.state.activeRightSection === "chat" && (
                          <CampaignChat
                            loadedFrom={this.state.loadedFrom}
                            campaignId={data.campaign_id}
                            user_client_id={this.props.userData.id}
                            incrementCommentCount={this.incrementCommentCount}
                            switchRightSection={this.switchRightSection}
                            getCampaignDetails={() =>
                              this.getCampaignDetails("chat")
                            }
                            campaignChatHeading={
                              this.state.isEvent
                                ? "Event Comments"
                                : "Article Comments"
                            }
                          />
                        )}

                        {this.state.activeRightSection === "share" && (
                          <Share
                            loadedFrom={this.state.loadedFrom}
                            switchRightSection={this.switchRightSection}
                            campaignId={data.campaign_id}
                            closeForm={() =>
                              this.setState({
                                activeRightSection:
                                  this.state.loadedFrom === "campaignModule"
                                    ? "none"
                                    : "chat",
                              })
                            }
                            userData={this.props.userData}
                            groups={data.groups}
                            getCampaignDetails={() =>
                              this.getCampaignDetails("share")
                            }
                            isEvent={this.state.isEvent}
                          />
                        )}
                        {this.state.activeRightSection === "viewingMembers" && (
                          <ViewingMembers
                            campaignId={data.campaign_id}
                            allMembers={this.state.allMembers}
                            getAllMembers={this.getAllMembers}
                            loadedFrom={this.state.loadedFrom}
                            setFollowerfollowing={this.setFollowerfollowing}
                            switchRightSection={this.switchRightSection}
                            heading={
                              this.state.isEvent
                                ? "Members Attending"
                                : "Viewing Members"
                            }
                          />
                        )}
                        {this.state.activeRightSection === "sendMoney" && (
                          <SendMoney
                            campaignCreator={data}
                            nonProfits={this.state.nonProfits}
                            loadedFrom={this.state.loadedFrom}
                            switchRightSection={this.switchRightSection}
                            getCampaignDetails={this.getCampaignDetails}
                          />
                        )}
                        {this.state.activeRightSection === "viewAgenda" && (
                          <ViewAgendaList
                            campaignId={data.campaign_id}
                            switchRightSection={this.switchRightSection}
                            closeForm={() =>
                              this.setState({ activeRightSection: "none" })
                            }
                            userData={this.props.userData}
                            isAgenda={this.state.isAgenda}
                            parentId={data.campaign_parent_id}
                            eventDate={data.start_at}
                          />
                        )}
                      </div>
                    )}
              </>
            )}
          </div>
        )}

        {this.state.paywall && CLIENT_ID === ETHOS_CLIENT_ID && (
          <div className="ethos-onboarding ethos-purchase-inner">
            <Purchase
              paywall={true}
              paywallFor={this.state.paywallFor}
              handleClose={() => this.setState({ paywall: false })}
              userData={this.props.userData}
              campaignDetails={
                this.state.campaignDetails && this.state.campaignDetails[0]
              }
              showAfterPurchase={(data) =>
                this.setState({
                  transactionData: data,
                  paywall: false,
                  paywallConfirmation: true,
                })
              }
            />
          </div>
        )}
        {this.state.paywall && CLIENT_ID === AHURA_CLIENT_ID && (
          <Modal
            className="modal-backdrop-darker"
            show={this.state.paywall}
            animation={false}
          >
            <div className="ethos-onboarding ethos-purchase-inner">
              <AccessPass
                paywall
                close={() => {
                  this.getCampaignDetails();
                  this.setState({ isLesson: false, paywall: false });
                }}
                userData={this.props.userData}
                showAfterPurchase={(data) =>
                  this.setState({
                    transactionData: data,
                    paywall: false,
                    paywallConfirmation: true,
                  })
                }
              />
            </div>
          </Modal>
        )}
        {this.state.paywallConfirmation && (
          <Modal
            className="modal-backdrop-darker"
            show={this.state.paywallConfirmation}
            animation={false}
          >
            <div className="p-lg-5 p-3 upgrade-plan-outer">
              <UpgradePlanPurchased
                afterPurchase={() => {
                  this.setState({ paywallConfirmation: false });
                }}
                transactionData={this.state.transactionData}
                handleClose={() =>
                  this.setState({ paywallConfirmation: false })
                }
                history={this.props.history}
                upgradeExistingPlan
              />
            </div>
          </Modal>
        )}
        {this.paintDirectMessageCallout()}
      </>
    );
  }
}
const mapActionToProps = {
  showLoader,
  updatePlanUpgraded,
  updateUserDistracted,
  updateCampaignVideoPlaying,
  updateIsCourseSidebar,
  updateIsPageCourse,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  isChatFormOpen: state.isChatFormOpen,
  refreshCampaignApi: state.refreshCampaignApi,
  isPlanUpgraded: state.isPlanUpgraded,
  isUserDistracted: state.isUserDistracted,
  isCourseSidebar: state.isCourseSideBar,
  isPageCourse: state.isPageCourse,
  isCmapaignVideoPlaying: state.isCmapaignVideoPlaying,
  userProfileId: state.userProfileId,
});

export default connect(mapStateToProps, mapActionToProps)(CampaignDetail);
