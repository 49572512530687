import React, { Component } from 'react';
import ScreenView from 'shared/components/templates/screen_view';
import CategoryForm from 'shared/components/category_form';
export default class CreateCategoryForm extends Component {

  render() {
    return (

      <>
        <ScreenView
          id={this.props.name + 'netork-category'}
          label={this.props.name + ' Category'}
          formType='Category'
          closeSummary={ this.props.onClose }>
          <CategoryForm
            closeSummary={this.props.onClose}
            isUpdateCategory={this.props.isUpdateCategory}
            data={this.props.data}
            getMenuDetails={this.props.getMenuDetails}
          />
          </ScreenView>
      </>
    );
  }
}
