import { Utility } from 'utils';
import { PUSH_NOTIFICATION } from 'config/constants/api_endpoints';

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription, userClientId) {
  if(subscription && userClientId) {
    return Utility.sendRequest(PUSH_NOTIFICATION, 2,
      { subscriptionObj: JSON.stringify(subscription), userClientId }, (err, res, body) => {
      console.log(body);
    });
  }
}

export function subscribeUser(userClientId) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function(registration) {
      if (!registration.pushManager) {
        console.log('Push manager unavailable.');
        return;
      }

      registration.pushManager.getSubscription().then(function(existedSubscription) {
        if (existedSubscription === null) {
          console.log('No subscription detected, make a request.');
          registration.pushManager.subscribe({
            applicationServerKey: convertedVapidKey,
            userVisibleOnly: true,
          }).then(function(newSubscription) {
            console.log('New subscription added.', JSON.stringify(newSubscription));
            sendSubscription(newSubscription, userClientId);
          }).catch(function(e) {
            if (Notification.permission !== 'granted') {
              console.log('Permission was not granted.');
            } else {
              console.error('An error ocurred during the subscription process.', e);
            }
          });
        } else {
  
          sendSubscription(existedSubscription, userClientId);
        }
      });
    })
      .catch(function(e) {
        console.error('An error ocurred during Service Worker registration.', e);
      });
  }
}
