import React, { useState, useEffect } from 'react';
import { Utility } from 'utils';
import Avatar from 'react-avatar';

export default function TileSelection (props){
  const [selected, setSelected] = useState(props.selected || []);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const handleSelect = (id) => {
    if(props.forSharing){
      props.getSelectedIds(id);
    } else {
      const tempArr = [...selected];
      tempArr.push(id);
      setSelected(tempArr);
      props.getSelectedIds(tempArr);
    }
  };

  const handleUnselect = (id) => {
    const tempArr = [...selected];
    const arr = tempArr.filter(item => item !== id);
    setSelected(arr);
    props.getSelectedIds(arr);
  };

  return(
    <>
    <div className="tiles-container" >
      {
        !Utility.isEmpty(props.items) && props.items.map(e => {
          if(selected && !selected.includes(e.id) && !props.inCallout)
            return <></>;
          return(
            <div className="align-items-center justify-content-between group-section-outer" key={e.id} >
              <div className="w-100 align-items-center border-top border-bottom py-2" >
                {!Utility.isEmpty(e.profile_image_url) || !Utility.isEmpty(e.image_url) ?
                <img src={e.profile_image_url || e.image_url} className="rounded-circle img-product"  alt="" />
                :
                <Avatar
                    name={e.user_name || e.name}
                    round={true}
                    size="60"
                    textSizeRatio={1.75}
                    color="#007bb3"
                    maxInitials={1}
                  />
              }
                <div className="lh-1 ml-10 mx-2 member-info-section-outer" >
                  <div className="font-bold display-7 text-truncate" >{e.user_name || e.name}</div>
                  <div className="font-medium font-14 text-truncate member-info-section-data">
                    {e.city ? e.city : ''}
                    {e.city && e.state ? ', ' : ''}
                    {e.state ? e.state : ''}
                  </div>
                </div>
                {
                  selected && selected.includes(e.id) ?
                  <div
                    className="mr-20 ml-auto"
                    onClick={props.forSharing ? () => {} : () => handleUnselect(e.id)}>
                    <button
                      className={`btn btn-link btn-inside-group text-primary font-14 ${props.forSharing && 'disabled'}`}>
                      {props.forSharing ? 'Sent' : 'UNSELECT'}
                    </button>
                  </div>
                  :
                  <div
                    className="mr-20 ml-auto"
                    onClick={() => handleSelect(e.id)}>
                    <button
                      className="btn btn-primary btn-primary-pwa btn-inside-group font-14" >
                      {props.forSharing ? 'Send' : 'SELECT'}
                    </button>
                  </div>
                }
              </div>
            </div>
          );
        })
      }
      </div>
      { props.inCallout &&
        <>
        { (props.isSearch && props.items.length >= 5 && props.followersLimit) ?
          <h6 className="text-primary text-center mt-3 cursor-pointer font-14" onClick={props.showAll}>
            SEE MORE
          </h6> :
          <h6 className="mt-3"></h6>
        }
        { props.showClose &&
          <button
            className="btn btn-primary btn-primary-pwa font-14 mt-3"
            onClick={() => {
              props.getSelectedIds(selected);
              props.close();
            }}>
            CLOSE
          </button>
        }
      </>}
    </>
  );
}
