import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as Utility from 'utils/utility';
import { GROUPS } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import GroupCard from 'shared/components/cards/group_card';
import {  ChevronLeftIcon } from 'shared/icons/icons';
import { showLoader } from 'shared/actions';
import { connect } from 'react-redux';

function Groups(props) {
  const userData = useSelector(state => state.userData);
  const refreshCampaignApi = useSelector(state => state.refreshCampaignApi);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [isApiResponse, setIsApiResponse] = useState(false);
  let debouncedFn = null;

  useEffect(() => {
    getGroups();
  }, [search, refreshCampaignApi]);

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const getData = (params, url) => {
    props.showLoader(true);
    Utility.sendRequest(url, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      setIsApiResponse(true);
      props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
         setData(body);
      }
    });
  };

  const getGroups = () => {
    const params = {
      clientId: CLIENT_ID,
      userClientId: userData.id,
      forMyGroups: true,
      search,
    };

    getData(params, GROUPS);
  };

  const goBackLink = () => {
    if (props.type === 'profileGroups') {
      return '/profile';
    }
    else
      return '/groups';
  };

  const paintGroups = () => {
    const arr = [];
    data.map(e => {
      arr.push(
        <GroupCard
          key={e.id}
          id={e.id}
          link={`/groups/${e.id}`}
          imageUrl={e.image_url}
          name={e.name}
          description={e.description}
          goBackLink={goBackLink()}
          calledFrom={props.type}
        />
      );
    });

    return (
      <div className="h-md-100 scroll-y dashboard-page-container gil-dashboard">
        <div className="home-page-padding">
          <div className="group-container d-flex flex-lg-wrap overflow-auto" >
          {arr}
          </div>
        </div>
      </div>
    );
  };

  return(
    <div className="pwa-callout-body">
      <div className="pb-4 px-4" >
        <h1 className="font-24-bold mb-4" >
          <ChevronLeftIcon
            handleClick={() => props.closeCallout(null)}
            mainClass="cursor-pointer see-all-back"
          />
          {props.heading}
        </h1>
        <Form className="shar-form search-form search-form-pwa sidebar-form" >
            <Form.Group className="mb-0" >
              <InputField
                required={true}
                type="text"
                placeholder="Search Groups..."
                handleChange={(ev) => handleSearch(ev)}
              />
            </Form.Group>
        </Form>
        {Utility.isEmpty(data) && isApiResponse &&
          <div className="d-flex align-items-center justify-content-center mt-5" >
            <div className="px-5" >
              <span className="font-bold display-6">No record found</span>
            </div>
          </div>
        }
        {paintGroups()}
      </div>
    </div>
  );
}

const mapActionToProps = { showLoader };

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, mapActionToProps)(Groups);
