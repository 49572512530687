import React, { useState } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { MenuCount } from 'shared/components/menu_count';
const DisplayShopsAction = (props) => {

    const [isActionMenuDisplayed, showActionMenu] = useState(false)
    return (
        <>
            <li onClick={() => {props.handleMenuItemClick(props.item);}} className="py-2 pl-2 pr-3 d-flex justify-content-between font-14" style={{ marginLeft: "-45px" }} key={`${props.item.name}`}>
                <Col xs={8} className={props.isActive ? "text-primary" : ""}>{props.item.name_for_shops}</Col>
                <Col xs={2}>
                    <div className="text-right">
                        <MenuCount count={props.item.count} isShops={true} />
                    </div>
                </Col>

                <Col xs={2} className="postion-relative">

                    {isActionMenuDisplayed &&
                        <Row className="no-gutters bg-white mx-1 mt-1 p-3 mb-0 sub-option-menu">
                            {props.actionItem.map((list, index) => {
                                return (
                                    <ActionItem
                                        key={"list-item-action" + index}
                                        actionItem={list}
                                        handleActions={props.handleActions}
                                        item={props.item}
                                        showActionMenu={showActionMenu}
                                        isActionMenuDisplayed={isActionMenuDisplayed}
                                    />
                                );
                            })}

                        </Row>
                    }
                    <span className="ml-auto d-block text-right" onClick={(event)=>{event.preventDefault();event.stopPropagation(); showActionMenu(!isActionMenuDisplayed);}} >
                        <Image src={require("assets/images/icons/menu_vertical_icon.svg")} alt="menu_vertical_icon" className="vertical-menu category-display" />
                    </span>
                </Col>
            </li>
        </>
    )
}



const ActionItem = (props) => {
    const list = props.actionItem;
    const item = props.item;

    return (
        <Col xs={6} className="p-0 align-items-center cursor-pointer" onClick={() => { props.handleActions(item.id, list.id); props.showActionMenu(!props.isActionMenuDisplayed) }}>
            <span className="pr-2">
                <Image className="hx-20 approve-action-icon" src={require("assets/images/icons/" + list.iconName)} alt={item.name + " icon"} />
            </span>
            <span className="display-xl-8 display-9 primary-link line-height-1">{list.name}</span>
        </Col>
    );
};

export default DisplayShopsAction;