import React from 'react';
import Avatar from 'react-avatar';
import { Utility } from 'utils';

const UserProfilePicture = (props) => {
  const { image, userName, size, className }  = props;
  return (
    !Utility.isEmpty(image) ?
      <img src = {image}  className={className || "notification-img rounded-circle"} />
      : <Avatar
          name={userName}
          round={true}
          size={size || "50"}
          textSizeRatio={1.75}
          color='#007bb3'
          maxInitials={1}
          className = {className || ''}
        />

  );
};
export { UserProfilePicture };
