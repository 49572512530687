export default function validate(values) {
  const errors = {};

  if (!values.groupName) {
    errors.groupName = "Group name is required";
  }

  if (!values.groupDescription) {
    errors.groupDescription = "Group description is required";
  }

  if (!values.groupDescription) {
    errors.groupDescription = "Group description is required";
  }

  return errors;
}
