import React, { useState } from 'react';
import { Skip, Image } from 'shared/components/pwa';
import { Form } from 'react-bootstrap';
import { PwaImageUpload }  from 'shared/components/pwa_image_upload';

export const PofileImage = (props) => {
  const [isProfileUploaded, setIsProfileUploaded] = useState(false);

  return(
    <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column">
      <div className="d-flex">
        <Image />
      </div>
      <h1 className="font-20-bold text-center my-5 " >Welcome to our community!</h1>
      <Form className="pwa-form">
        <Form.Group className="text-center" controlId="formBasicUpload">
          {isProfileUploaded ?
            <div className="font-16-bold mb-4 pb-3">Click on image to edit</div> :
            <div className="font-16-bold mb-4 pb-3">Upload a Profile Photo</div>
          }
          <PwaImageUpload setIsProfileUploaded={setIsProfileUploaded} />
          {isProfileUploaded &&
            <>
              <div className="font-16-bold mb-3 mt-4 pt-2">It is a pleasure to meet you</div>
              <div className="font-24-bold mb-3">
                {props.first_name + ' ' + props.last_name}
              </div>
            </>
          }
        </Form.Group>
      </Form>
      <div className="text-center mt-auto">
        <button
          className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
          onClick={props.skip}
        >
          CONTINUE
        </button>
        <Skip skip={props.skip} name={isProfileUploaded ? 'GO TO HOME' : 'SKIP FOR NOW'}/>
      </div>
    </div>
  );
};
