import React, { Component } from 'react';
import { AssignmentCheckIcon, RedoIcon, TickFilledIcon, DownloadIcon, WrongAnswerIcon } from 'shared/icons/icons';
import SideCallout from 'shared/components/templates/side_callout';
import { GIL_CLIENT_ID } from 'config/constants';

export class Certificate extends Component{
  constructor(props){
    super(props);
    this.state = {
      comment: "",
      allComments: {},
      campaignId: props.campaignId,
      openCallout: false
    };
  }



  render(){
    return (
      <>
        <div className='pb-5'></div>
        {/* <div className="assignment-list px-5 pt-3 pb-5" >
          <div className="align-items-center ">
            <AssignmentCheckIcon mainClass="mr-2 assignment-icon" /> <span className="font-weight-bold font-24" >Certificate</span>
          </div>
          <div className="font-18 mt-1 mb-4 pb-1">Download the Certificate when you complete the course</div>
          <div className="px-5 py-4 d-flex justify-content-between align-items-center assignment-list assignment-list-inner mb-4">
           {this.props.platform_Id === GIL_CLIENT_ID && <img src={process.env.PUBLIC_URL + '/icons/icon-72x72.png'} />}
            <span className="font-bold font-18">Certificate</span>
            <button className="btn btn-sm btn-primary btn-half-rounded font-weight-bold font-16" disabled="true">Download</button>
          </div>
        </div>
        <div className="mt-5 p-1" /> */}
      </>
    );
  }
}
