import React from 'react';

const CloseToast = ({ closeToast }) => {
  return (
    <img
      src="/toast_icon/close_icon.png"
      onClick={closeToast}
      height="20px"
      width="20px"
      className=""
    />
  );
};

export default CloseToast;
