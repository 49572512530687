import React, { useState } from "react";
import Avatar from "react-avatar";
import { withRouter } from "react-router-dom";
import { GIL_CLIENT_ID } from "../../../config/constants";
import {
  CLIENT_ID
} from "config/constants/config";
const CourseContainer = (props) => {
  const handleArticleClick = (data) => {
    props.history.push({
      pathname: `/campaign/${data.parent_id || data.id}`,
      calledFrom: props.type,
      goBackLink: goBackLink(),
    });
  };

  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "myArticles") return "/profile";
  };
  function sum(arr) {
    let sum = 0; // initialize sum
    for (let i = 0; i < arr.length; i++) sum += arr[i];

    return sum;
  }
  return (
    <>
      {console.log("========>", props.courseData)}
      {/* <div>{props.courseData[0].id}</div> */}
      {props.courseData && props.courseData.length > 0 && (
        <div
          className="course-panal-container cursor-pointer"
          style={CLIENT_ID===GIL_CLIENT_ID && props.isArticles ? { width: props.width }:{ width: "100%" }}
        >
          {props.PanalTitle && (
            <h1
              className={`course-text-${props.category} ml-4 font-24-bold mb-4`}
              style={{ color: props.colorCode ? props.colorCode : "#000" }}
            >
              {props.PanalTitle}
            </h1>
          )}
          <div className="card-container d-flex">
            {props.courseData.map((data, index) => {
              return (
                <div
                  key={data.id}
                  onClick={() => handleArticleClick(data)}
                  className={`course-card  ${
                    props.isHome ? index !== 0 && "ml-3" : "ml-3"
                  }`}
                  style={CLIENT_ID === GIL_CLIENT_ID && { width: "253px" }}
                >
                  {data.image_url ? (
                    <span className={"image-container"}>
                      <img
                        className={
                          CLIENT_ID === GIL_CLIENT_ID
                            ? "course-image article-card-image"
                            : "course-image"
                        }
                        src={data.image_url}
                        alt="#"
                      />
                    </span>
                  ) : (
                    <div className=" d-flex no-image-container align-items-center h-50">
                      <Avatar
                        name={data.author_name}
                        round={true}
                        size={"20"}
                        textSizeRatio={1.75}
                        color="#007bb3"
                        maxInitials={1}
                        className={"article-pic"}
                      />
                    </div>
                  )}
                  <div className="course-card-content">
                    <span
                      className={`course-text-${props.category} course-category-container`}
                      style={{
                        color: props.colorCode
                          ? props.colorCode
                          : data.category_color,
                      }}
                    >
                      {data.campaign_category}
                    </span>
                    <span
                      className={
                        CLIENT_ID === GIL_CLIENT_ID
                          ? "course-title-container m-0"
                          : "course-title-container"
                      }
                    >
                      {CLIENT_ID === GIL_CLIENT_ID
                        ? data.heading.length > 34
                          ? data.heading.slice(0, 34) + '...'
                          : data.heading
                        : data.heading}
                    </span>
                  </div>
                  {!props.isArticles && (
                    <span className="course-lesson-container">
                      {" "}
                      {sum(
                        data.sub_campaign_count.map((data) => {
                          return data !== null ? parseInt(data) : 0;
                        })
                      )}{" "}
                      Lesson
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const CourseContainerCard = withRouter(CourseContainer);

export default CourseContainerCard;
