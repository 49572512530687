import React from "react";
import { div } from "react-router-dom";
import {
  updateSeeAll,
  updateIsCourseSidebar,
  updateIsCourseDropdown,
  showLoader,
  updateUserProfileId,
} from "shared/actions";
import { connect } from "react-redux";
import { CREATE_EDIT_QUESTIONS } from "config/constants/api_endpoints";
import {
  CircleFilled,
  CourseLessonIcon,
  DropArrow,
  DropDownArrow,
  TickFilledIcon,
} from "../../../icons/icons";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdPeople,
  MdForum,
} from "react-icons/md";
import { PassportIcon } from "shared/icons/icons";
import { AiOutlineFileDone } from "react-icons/ai";
import { FaAward, FaThinkPeaks } from "react-icons/fa";
import Avatar from "react-avatar";
import { Utility } from "utils";
import { Form } from "react-bootstrap";
import { InputField } from "shared/components/form_elements";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";

export const mockData = [
  {
    section: "Section 1",
    Data: ["chapter 1", "chapter 1", "chapter 1", "chapter 1"],
  },
  {
    section: "Section 1",
    Data: ["chapter 1", "chapter 1", "chapter 1", "chapter 1"],
  },
  {
    section: "Section 1",
    Data: ["chapter 1", "chapter 1", "chapter 1", "chapter 1"],
  },
];

class CourseSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLessonChapterOpen: false,
      isAssignment: false,
      isDiscussion: false,
      isParticipants: false,
      isCertificate: false,
      questionLists: [],
      selectedQuestion: [],
      AssignmentsData: [],
      openCallout: false,
      assignmentResult: false,
      assignmentEdit: false,
      user_id: this.props.userData.id,
      count: 1,
      courseCompleteSuccessCallout: false,
      userBadgeforCourse: [],
      editQuestion: false,
      totalA: 0,
    };
    this.handleClick.bind(this);
    this.handleCertificate.bind(this);
  }
  handleClick = (isCourseSidebar) => {
    this.props.setIsExpanded(isCourseSidebar);
  };
  componentDidMount = () => {
    this.props.updateIsCourseDropdown(false);
  };

  // componentDidUpdate() {
  // this.getQuestionsHandler()
  // }
  handleLessonChapter = () => {
    if (this.state.isLessonChapterOpen || this.props.isCourseDropdown) {
      this.props.updateIsCourseDropdown(false);
      this.setState({
        isLessonChapterOpen: false,
      });
    } else {
      this.setState({
        isLessonChapterOpen: !this.state.isLessonChapterOpen,
      });
    }
    this.handleCertificate(false);
  };

  handleSearch = (ev) => {
    ev.persist();

    if (!this.debouncedFn) {
      this.debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        this.props.getAllMembers(searchString);
      }, 500);
    }
    this.debouncedFn();
  };

  handleCertificate = (IsCertificate) => {
    this.props.setIsCertificate(IsCertificate);
  };

  handleAssignmentChapter = () => {
    if (this.state.isAssignment || this.props.isCourseDropdown) {
      this.props.updateIsCourseDropdown(false);
      this.setState({
        isAssignment: false,
      });
    } else {
      this.setState({
        isAssignment: !this.state.isAssignment,
      });
      this.handleCertificate(false);
    }
  };
  handleDiscuss = () => {
    if (this.state.isDiscussion || this.props.isCourseDropdown) {
      this.props.updateIsCourseDropdown(false);
      this.setState({
        isDiscussion: false,
      });
    } else {
      this.setState({
        isDiscussion: !this.state.isDiscussion,
      });
      this.handleCertificate(false);
    }
    this.handleCertificate(false);
    return;
  };

  getQuestionsHandler = () => {
    this.props.allAssignments.map((data, index) => {
      data.assignmentList !== null &&
        data.assignmentList.map(async (assignmentData) => {
          const Data = [];
          await Utility.sendRequest(
            CREATE_EDIT_QUESTIONS,
            1,
            { assignment_id: assignmentData.assignment_id },
            (err, res, body) => {
              body = JSON.parse(body);
              if (body.error) {
                toast.error(body.error.message, { containerId: "private" });
              } else {
                Data.push({
                  ...data,
                  assignmentList: [
                    {
                      ...assignmentData,
                      assignmentQuestions: body.data,
                      isCompleted: body.isComplete,
                    },
                  ],
                });
              }
            }
          );
        });
    });
    this.handleCertificate(false);
  };

  handleCloseSidebar = (e) => {
    e.preventDefault();
    this.props.updateIsCourseDropdown(false);
    this.setState({
      isParticipants: false,
      isLessonChapterOpen: false,
      isAssignment: false,
      isDiscussion: false,
    });
    this.props.updateIsCourseSidebar(false);
  };
  handleparticipants = () => {
    if (this.state.isParticipants || this.props.isCourseDropdown) {
      this.props.updateIsCourseDropdown(false);
      this.setState({
        isParticipants: false,
      });
    } else {
      this.setState({
        isParticipants: !this.state.isParticipants,
      });
    }
    this.handleCertificate(false);
  };

  handleProfilePopUp = (id) => {
    this.props.updateSeeAll({
      isOpen: null,
      calledFrom: null,
    });
    this.props.updateUserProfileId(id);
    this.props.openProfilePopUp();
  };

  render() {
    console.log("MYData", this.props.allAssignments);
    return (
      <div
        className={`Course-Sidebar ${
          this.props.isCourseSidebar ? "Sidebar" : "Sidebar collapsed"
        }`}
      >
        <div className="sidebar-items">
          <div className="item">
            <div className={"item-panal"}>
              <div
                className="row panal-head d-flex align-items-center"
                onClick={this.props.isCourseSidebar && this.handleLessonChapter}
              >
                {this.props.isCourseSidebar &&
                  (this.state.isLessonChapterOpen ||
                  this.props.isCourseDropdown ? (
                    <DropDownArrow />
                  ) : (
                    <DropArrow />
                  ))}
                <div className={this.props.isCourseSidebar ? "" : "px-3"}>
                  {<CourseLessonIcon />}
                </div>
                <div
                  className={`${
                    this.props.isCourseSidebar
                      ? "d-block col-6 font-weight-bold"
                      : "d-none"
                  }`}
                >
                  Lessons
                </div>
              </div>

              {(this.state.isLessonChapterOpen ||
                this.props.isCourseDropdown) &&
                this.props.sectionList.length !== 0 &&
                this.props.sectionList
                // .sort(
                //   (lesson1, lesson2) =>
                //     lesson1.seq_id - lesson2.seq_id
                // )
                  .map((Sections, index) => {
                    return (
                      Sections &&
                      Sections.lessons &&
                      Sections.lessons.length > 0 && (
                        <React.Fragment key={index}>
                          <div className="sub-item">
                            {" "}
                            <div className="row align-items-center">
                              <div className="Sub-heading-section">
                                {Sections && Sections.heading && (
                                  <div className="section-heading">
                                    {Sections.heading}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {console.log(Sections.lessons)}
                          {Sections &&
                            Sections.lessons &&
                            Sections.lessons
                              .sort(
                                (lesson1, lesson2) =>
                                  lesson1.seq_id - lesson2.seq_id
                              )
                              .map((data, index) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => this.props.loadLession(data)}
                                    className="sub-chapter-item"
                                  >
                                    <div className="section-data">
                                      <div className="chapterSection">
                                        {this.props.userAssignmentDetails.find(
                                          (completedLesson) =>
                                            completedLesson.campaign_id ===
                                            data.id
                                        ) ? (
                                          <TickFilledIcon fillClass="fill-primary" />
                                        ) : (
                                          <CircleFilled />
                                        )}{" "}
                                        {data.heading}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                        </React.Fragment>
                      )
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="sidebar-items">
          <div className="item">
            <div className={"item-panal"}>
              <div
                className="row panal-head d-flex align-items-center"
                onClick={
                  this.props.isCourseSidebar && this.handleAssignmentChapter
                }
              >
                {this.props.isCourseSidebar &&
                  (this.state.isAssignment || this.props.isCourseDropdown ? (
                    <DropDownArrow />
                  ) : (
                    <DropArrow />
                  ))}
                <div className={this.props.isCourseSidebar ? "" : "px-3"}>
                  <AiOutlineFileDone className="courseSidebarIcon" />
                </div>
                <div
                  className={`${
                    this.props.isCourseSidebar
                      ? "d-block col-6 font-weight-bold"
                      : "d-none"
                  }`}
                >
                  Assignment
                </div>
              </div>
              {(this.state.isAssignment || this.props.isCourseDropdown) &&
                this.props.allAssignments.length !== 0 &&
                this.props.allAssignments
                  .sort((assignment1, assignment2) =>
                    assignment1.lessonHeading.localeCompare(
                      assignment2.lessonHeading
                    )
                  )
                  .map((Sections, index) => {
                    return (
                      <React.Fragment key={index}>
                        {Sections.assignmentList !== null && (
                          <div className="sub-item">
                            {" "}
                            <div className="row align-items-center">
                              <div className="Sub-heading-section">
                                <div className="section-heading">
                                  {Sections.lessonHeading}
                                </div>
                                <div className="section-sub-heading">
                                  {Sections.SectionHeading}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {Sections.assignmentList !== null &&
                          Sections.assignmentList
                            .sort((assignment1, assignment2) =>
                              assignment1.assignment_name.localeCompare(
                                assignment2.assignment_name
                              )
                            )
                            .map((data, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={(e) =>
                                    data.score === 0
                                      ? this.props.handleStartAssignment(
                                          e,
                                          data.id,
                                          data.campaign_id
                                        )
                                      : toast.error(
                                          "Answer is Already Submitted",
                                          {
                                            containerId: "private",
                                          }
                                        )
                                  }
                                  className="sub-chapter-item"
                                >
                                  <div className="section-data">
                                    <div className="chapterSection">
                                      {data.score === 0 ? (
                                        <CircleFilled />
                                      ) : (
                                        <TickFilledIcon fillClass="fill-primary" />
                                      )}
                                      {data.assignment_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                      </React.Fragment>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="sidebar-items">
          <div className="item">
            <div className={"item-panal"}>
              <div
                className="row panal-head d-flex align-items-center"
                onClick={this.props.isCourseSidebar && this.handleDiscuss}
              >
                {this.props.isCourseSidebar &&
                  (this.state.isDiscussion || this.props.isCourseDropdown ? (
                    <DropDownArrow />
                  ) : (
                    <DropArrow />
                  ))}
                <div className={this.props.isCourseSidebar ? "" : "px-3"}>
                  <MdForum className="courseSidebarIcon" />
                </div>
                <div
                  className={`${
                    this.props.isCourseSidebar
                      ? "d-block col-6 font-weight-bold"
                      : "d-none"
                  }`}
                >
                  Discussion
                </div>
              </div>

              {(this.state.isDiscussion || this.props.isCourseDropdown) &&
                this.props.sectionList.length !== 0 &&
                this.props.sectionList.map((Sections, index) => {
                  return (
                    <React.Fragment key={index}>
                      {Sections &&
                        Sections.lessons &&
                        Sections.lessons.map((data, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                this.props.handleDiscuss(data, "discussions")
                              }
                              className="sub-chapter-item"
                            >
                              <div className="section-data">
                                <div className="chapterSection d-flex align-items-center">
                                  {/* <img className="discuss-wea-logo" src={require('assets/images/city_specific_images/passportIcon.svg')} alt="" />
                                  {" "} */}
                                  <div className="px-1 py-1">
                                    <PassportIcon />
                                  </div>
                                  Discussion of {data.heading}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="sidebar-items">
          <div className="item">
            <div className={"item-panal"}>
              <div
                className="row panal-head d-flex align-items-center"
                onClick={this.props.isCourseSidebar && this.handleparticipants}
              >
                {this.props.isCourseSidebar &&
                  (this.props.isCourseDropdown || this.state.isParticipants ? (
                    <DropDownArrow />
                  ) : (
                    <DropArrow />
                  ))}
                <div className={this.props.isCourseSidebar ? "" : "px-3"}>
                  <MdPeople className="courseSidebarIcon" />
                </div>
                <div
                  className={`${
                    this.props.isCourseSidebar
                      ? "d-block col-6 font-weight-bold"
                      : "d-none"
                  }`}
                >
                  Participants
                </div>
              </div>
              {this.props.isCourseDropdown || this.state.isParticipants ? (
                <div className="d-flex justify-content-center mt-4 mb-2">
                  <Form className="shar-form search-form">
                    <div className="input-group">
                      <InputField
                        required={true}
                        type="text"
                        className="h-25 headerSearchBar w-50"
                        placeholder="Search Members..."
                        handleChange={this.handleSearch}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-white"
                          id="basic-addon2"
                        >
                          <AiOutlineSearch />
                        </span>
                      </div>
                    </div>
                  </Form>
                </div>
              ) : (
                ""
              )}
              {(this.props.isCourseDropdown || this.state.isParticipants) &&
                this.props.allMembers
                  .sort((participants1, participants2) =>
                    participants1.user_name.localeCompare(
                      participants2.user_name
                    )
                  )
                  .slice(0, 10)
                  .map((Sections, index) => {
                    return (
                      <>
                        <React.Fragment key={index}>
                          <div className="sub-item" key={index}>
                            <Link
                              onClick={() =>
                                this.handleProfilePopUp(Sections.user_client_id)
                              }
                            >
                              <div className="d-flex bd-highlight align-content-center flex-wrap">
                                <div className="p-1 bd-highlight">
                                  {Sections.image_url ? (
                                    <img
                                      src={Sections.image_url}
                                      className="rounded-circle"
                                      width="40px"
                                      height="40px"
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar
                                      name={Sections.user_name}
                                      round={true}
                                      size={"40"}
                                      textSizeRatio={1.75}
                                      color="#007bb3"
                                      maxInitials={1}
                                    />
                                  )}
                                </div>
                                <div className="p-1 bd-highlight align-self-center">
                                  {Sections.user_name}
                                </div>
                              </div>
                            </Link>
                          </div>
                        </React.Fragment>
                      </>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="sidebar-items">
          <div className="item">
            <div className={"item-panal"}>
              <div
                className="row panal-head d-flex align-items-center"
                onClick={() => {
                  this.handleCertificate(true);
                }}
              >
                {this.props.isCourseSidebar &&
                  (this.state.isCertificate ? (
                    <DropDownArrow />
                  ) : (
                    <DropArrow />
                  ))}
                <div className={this.props.isCourseSidebar ? "" : "px-3"}>
                  <FaAward className="courseSidebarIcon" />
                </div>
                <div
                  className={`${
                    this.props.isCourseSidebar
                      ? "d-block col-6 font-weight-bold"
                      : "d-none"
                  }`}
                >
                  Certificate
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="item-panal">
            {this.props.isCourseSidebar ? (
              <div
                className="text-dark"
                onClick={(e) => this.handleCloseSidebar(e)}
              >
                <div className="row align-items-center">
                  <MdKeyboardArrowLeft className="sidebarIcon" />

                  <div
                    className={
                      this.props.isCourseSidebar ? "d-inline col-8" : "d-none"
                    }
                  >
                    <small>Close Sidebar</small>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="text-dark"
                onClick={() => this.props.updateIsCourseSidebar(true)}
              >
                <div className="row align-items-center justify-content-center">
                  {" "}
                  <MdKeyboardArrowRight className="sidebarIcon" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isCourseSidebar: state.isCourseSideBar,
  isCourseDropdown: state.isCourseDropDown,
  userData: state.userData,
  userProfileId: state.userProfileId,
});

const mapActionToProps = {
  updateSeeAll,
  updateIsCourseSidebar,
  updateIsCourseDropdown,
  showLoader,
  updateUserProfileId,
};

export default connect(mapStateToProps, mapActionToProps)(CourseSidebar);
