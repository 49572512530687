import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { STATUS } from "config/constants";
import {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
} from "shared/actions";
import { FollowerInfo } from "shared/components/v2/campaign/components/follower_info.js";
import Avatar from "react-avatar";
import { updateUserProfileId } from "shared/actions";
import { CLIENT_ID } from "config/constants/config";
import { GIL_CLIENT_ID } from "config/constants";

class PeersCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChatCallout: false,
    };
  }

  userProfile = (id) => {
    // if (CLIENT_ID === GIL_CLIENT_ID) {
    //   this.props.updateUserProfileId(id);
    //   this.props.updateSeeAll({
    //     isOpen: "profile",
    //     calledFrom: "profile",
    //   });
    // } else {
    //   this.props.updateSeeAll({
    //     //...this.props.seeAll,
    //     isOpen: null,
    //   });
    //   if (id === this.props.userData.id)
    //     return this.props.history.push("/profile");
    //   this.props.history.push({
    //     pathname: `/profile/${id}`,
    //     state: { otherUserProfile: true },
    //   });
    // }
    this.props.updateSeeAll({
      isOpen: null,
      calledFrom: null,
    });
    this.props.updateUserProfileId(id);
    this.props.openProfilePopUp();
  };

  checkIcon = (props) => {
    if (props.type === "follower") {
      return (
        !this.props.item.is_followed && (
          <img
            onClick={(e) => this.handleFollowClick(e, props)}
            src={require("assets/images/icons/person_plus.svg")}
            className="mr-4 cursor-pointer"
            alt=""
          />
        )
      );
    } else if (props.type === "following") return "";
  };

  handleFollowClick = (e, props) => {
    e.stopPropagation();
    this.props.handleOnClick(props.item.user_client_id);
  };

  handleChatClick = (e) => {
    this.props.updateSeeAll({
      ...this.props.seeAll,
      isOpen: null,
    });
    e.stopPropagation();
    this.props.updateIsChatFormOpen(true);
    this.props.updateChatFriend(this.props.item);
  };

  interestAndChatFollow = () => (
    <>
      {this.props.showCharFollowButtons &&
        this.props.item.id !== this.props.userData.id && (
          <div>
            <img
              onClick={(e) => this.handleChatClick(e)}
              src={require("assets/images/icons/chat_icon_dark.svg")}
              className="mr-3 community-chat-icon cursor-pointer"
              alt=""
            />
            {this.props.type ? (
              this.checkIcon(this.props)
            ) : this.props.item.is_followed ? (
              ""
            ) : (
              <img
                onClick={(e) => this.handleFollowClick(e, this.props)}
                src={require("assets/images/icons/person_plus.svg")}
                className="mr-4 cursor-pointer"
                alt=""
              />
            )}
          </div>
        )}
    </>
  );

  interestAndChatFollowDisabled = () => (
    <>
      <div className="group-invitation-pending">Invitation Pending</div>
    </>
  );

  hideOrShowInterest = () => {
    if (this.props.status) {
      if (this.props.status === STATUS.pending)
        return this.interestAndChatFollowDisabled();

      if (this.props.status === STATUS.approved)
        return this.interestAndChatFollow();
    } else return this.interestAndChatFollow();
  };

  paintUserName = (userName) => {
    if (parseInt(userName.length) < 19) {
      return this.props.item.user_name;
    } else {
      return this.props.item.user_name.substring(0, 16) + " ...";
    }
  };

  render() {
    return this.props.fromSeeAll === undefined || !this.props.fromSeeAll ? (
      <div
        className="community-outer overflow-hidden mb-3 position-relative cursor-pointer"
        onClick={() => this.userProfile(this.props.item.user_client_id)}
      >
        <img
          src={
            this.props.item.creator_banner_image_url ||
            require("assets/images/profile/user-profile-bg.jpg")
          }
          className="community-card-bg"
          alt=""
        />
        {this.props.item && this.props.item.profile_image_url ? (
          <img
            src={this.props.item.profile_image_url}
            className="community-card-pic ml-3"
            alt=""
          />
        ) : (
          <Avatar
            name={this.props.item.user_name}
            round={true}
            size={"61"}
            textSizeRatio={1.75}
            color="#007bb3"
            maxInitials={1}
            className={"community-avatar"}
          />
        )}

        {/*<div className="expertise-pill font-bold bg-dark-purple card-pill">Unity Expert</div>*/}
        <div className="community-field-text">
          <div className="display-8 font-bold mb-1">
            {this.props.item &&
              this.paintUserName(this.props.item.user_name.trim())}
          </div>
          <div className="font-12">
          {this.props.item.city ? this.props.item.city : ''}
              {this.props.item.city &&  this.props.item.country ? ', ' : ''}
              {this.props.item.country ? this.props.item.country  : ''}
          </div>
          {this.hideOrShowInterest()}
        </div>
      </div>
    ) : (
      <FollowerInfo
        id={this.props.item.id}
        selectedUser={this.props.item}
        name={this.props.item.user_name}
        imageUrl={this.props.item.profile_image_url}
        isFollowed={this.props.item.is_followed}
        closeCallout={this.props.closeCallout}
        fromSeeAll={this.props.fromSeeAll}
        setFollowerfollowing={() =>
          this.props.handleOnClick(this.props.item.id)
        }
      />
    );
  }
}

const mapActionToProps = {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
  updateUserProfileId,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
  userProfileId: state.userProfileId,
});

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(PeersCard)
);
