import React, { Component } from 'react';
import cookie from 'react-cookies';

export default class JoinLayout extends Component {
  constructor(props){
		super(props);

    const token = cookie.load('sessionToken');

    if (token) this.props.history.push('/home');
	}

  render(){
    return(
      <div className="main-container">
        {this.props.children}
      </div>
    );
  }
}
