import React, { Component } from 'react';
import { InputField, DropDown } from 'shared/components/form_elements';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Utility } from 'utils';
import UploadFile from 'shared/components/file_upload';
import RichTextEditor from 'shared/components/rich_text_editor';
import CustomDropDownList from 'shared/components/custom_drop_down_list';
import PayoutScheduleCheckbox from 'shared/components/payout_schedule_checkbox';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';

export default class InitiativeForm extends Component {

  resetFormData = (event) => {
    event.preventDefault();

    document.getElementById('agree-terms').checked = false;
    // this.refs.publisher_network_id.refs.publisher_network_id.selectedIndex = 0;
    // this.refs.non_profits.refs.non_profits.selectedIndex = 0;
    this.props.resetFormData();
  }

  render () {
    const formData = this.props.formData;

    return (
      <div className="pt-lg-5 pt-3 create-story-form">
        <Row>
          <Col xs={12}>
            <Form.Group controlId="heading">
              <Form.Label className="font-16-medium">What is the name of your initiative?</Form.Label>
              <InputField
                type="text"
                placeholder="Enter the name"
                name="heading"
                ref="heading"
                required={true}
                value={formData.heading}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="image_url" className="pt-3">
              <Form.Label className="font-16-medium">Select an image or video to upload</Form.Label>
              <Row className="target-input mx-0">
                <div className="d-inline w-md-100 mr-lg-4">
                  <label className="add-file-height">
                    <UploadFile
                      inputId="image_url"
                      selectTitle="Select an image to upload"
                      removeText="Remove image"
                      categoryName="publisher_campaign_image"
                      acceptTypes="image/x-png,image/gif,image/jpeg"
                      uploadedFilePreviewUrl={this.props.imagePreviewUrl}
                      handleFileChange={this.props.handleFileChange}
                      removeUploadedFile={this.props.removeUploadedFile}
                    />
                  </label>
                  <div className="error errorIcon" id={'image_urlError'} />
                  <div className="text-center font-16-medium pb-2">or</div>
                  <InputField
                    type="text"
                    placeholder="Paste embedded image link here…"
                    name="embedded_image_link"
                    ref="embedded_image_link"
                    value={formData.embedded_image_link}
                    handleChange={this.props.handleChange}
                  />
                </div>
                <div className="d-inline w-md-100 mt-lg-0 mt-4">
                  <label className="add-file-height">
                    <UploadFile
                      inputId="video_url"
                      selectTitle="Select a video to upload (optional)"
                      removeText="Remove video"
                      acceptTypes="video/mp4"
                      categoryName="publisher_campaign_video"
                      uploadedFilePreviewUrl={this.props.videoPreviewUrl}
                      handleFileChange={this.props.handleFileChange}
                      removeUploadedFile={this.props.removeUploadedFile}
                    />
                  </label>
                  <div className="error errorIcon" id={'video_urlError'} />
                  <div className="text-center font-16-medium pb-2">or</div>
                  <InputField
                    type="text"
                    placeholder="Paste embedded video link here…"
                    name="embedded_video_link"
                    ref="embedded_video_link"
                    value={formData.embedded_video_link}
                    handleChange={this.props.handleChange}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="story-content-input" >
            <Form.Group className="pt-3">
              <Form.Label className="font-16-medium">Describe the initiative</Form.Label>
                <RichTextEditor
                  id="description"
                  name="description"
                  data={formData.description}
                  changeDescription={this.props.changeDescription}
                  placeholder="What do you want people to do and what is the goal..."
                />
            </Form.Group>
          </Col>
        </Row>
        {/*
        <Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="publisher_network_id" className="pt-3">
              <Form.Label className="font-16-medium">What network do you want to post this to?</Form.Label>
              <DropDown
                optionId="id"
                optionValue="name"
                required={true}
                name="publisher_network_id"
                ref="publisher_network_id"
                options={this.props.publisherNetworks}
                handleChange={this.props.handleChange}
                disabled={this.props.campId ? true : false}
              />
            </Form.Group>
          </Col>
        </Row>
      */}
        <Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="hashtags" className="pt-3">
              <Form.Label className="font-16-medium">Add Tags <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>
              <InputField
                required={true}
                type="text"
                placeholder="Enter tags separated by commas…"
                name="hashtags"
                ref="hashtags"
                value={formData.hashtags}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        {/*
          <Row>
            <Col xl={7} xs={12}>
              <Form.Group controlId="non_profits" className="pt-3">
                <Form.Label className="font-16-medium">Who is the beneficiary of this initiative? <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>
                <DropDown
                  optionId="id"
                  optionValue="name"
                  name="non_profits"
                  ref="non_profits"
                  options={this.props.nonProfits}
                  handleChange={this.props.handleChange}
                />
                <CustomDropDownList
                  dataList={this.props.campaignNonProfits}
                  removeSelectedItem={this.props.removeSelectedNonprofits}
                />
              </Form.Group>
            </Col>
          </Row>
        */}
        <Col xs={12} className="p-0 pt-4">
          <PayoutScheduleCheckbox
            sourceId="create-initiative"
            linkName="payout schedule"
          />
          <Button variant="link" type="submit" className="mx-sm-5 mx-3 font-light" onClick={(e) => this.resetFormData(e)}> Cancel </Button>
          <Button variant="primary" onClick={(e) => this.props.submitFormData(CAMPAIGN_TYPES_IDS.initiative, false, e)}>
           {Utility.isEmpty(this.props.campId) ? 'Publish' : 'Update' }</Button>
        </Col>
      </div>
    );
  }
}
