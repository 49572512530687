import React from "react";
import { ChevronLeftIcon } from "shared/icons/icons";
import { useSelector } from 'react-redux';
import { GIL_CLIENT_ID, ROLE_IDS } from 'config/constants';
import { CLIENT_ID } from "config/constants/config";

export default function HomeContainer(props) {
  const userData = useSelector(state => state.userData);
  const goBack = () => {
    if (
      props.calledFrom === "profileGroups" ||
      props.calledFrom === "publicGroups"
    ) {
      props.history.push({
        pathname: props.goBackLink,
        calledFrom: "profileGroupsSeeAll",
      });
    } else if (props.goBackLink) props.history.push(props.goBackLink);
    else window.history.back();
  };

  return (
    <>
      <div className={`h-md-100 scroll-y overflow-auto ${props.className}`}>
        <div className="cursor-pointer d-flex align-items-center event-head">
          {CLIENT_ID  === GIL_CLIENT_ID &&
            userData.role_id === ROLE_IDS.USER && (props.name === "Groups" || props.name === "Events") ? (
            <h1
              className="font-20-bold mb-0"
              style={{ color: props.color && props.color }}
            >
              {props.name}
            </h1>
          ) : (
            <>
              <ChevronLeftIcon handleClick={goBack} />{" "}
              <h1
                className="font-20-bold mb-0 ml-4"
                style={{ color: props.color && props.color }}
              >
                {props.name}
              </h1>
            </>
          )}
        </div>

        {props.children}
      </div>
    </>
  );
}
