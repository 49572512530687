import React from 'react';
import { SingleCampaign } from './single_campaign';
import { Utility } from 'utils';
import { NoRecordFound } from 'shared/components/no_record_found';

export const DropdownInside = (props) => {
  return(
    <>
      {
        (!props.isSearch && Utility.isEmpty(props.dataList))
        && <NoRecordFound />
      }

      {
        !Utility.isEmpty(props.dataList) &&
        props.dataList.map(e => {
          return(
            <SingleCampaign
              key={e.id}
              id={e.id}
              imageUrl={e.image_url}
              heading={e.name}
              createdAt={e.created_at}
              handleClick={() => props.handleEditClick(e.id)}
            />
          );
        })
      }

      { props.isSearch &&
        <SingleCampaign
          imageUrl={props.imageUrl}
          heading={props.name}
          createdAt={props.groupFormData.created_at}
          handleClick={props.handleCreateClick}
        />
      }
    </>
  );
};
