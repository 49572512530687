import React, { Component } from "react";
import { connect } from "react-redux";
import { Utility } from "utils";
import _ from "lodash";
import {
  GET_ASSIGNMENTS,
  CREATE_EDIT_QUESTIONS,
  USER_ASSIGNMENT,
  USER_ASSIGNMENT_DETAILS,
  USER_BADGES,
} from "config/constants/api_endpoints";
import { toast } from "react-toastify";
import {
  AssignmentCheckIcon,
  RedoIcon,
  TickFilledIcon,
  DownloadIcon,
  WrongAnswerIcon,
} from "shared/icons/icons";
import SideCallout from "shared/components/templates/side_callout";
import AssignmentQuestion from "./assignment_question";
import AssignmentResult from "./assignment_result";
import AssignmentEdit from "./assignment_edit";
import { showLoader } from "shared/actions";
import { has, filter } from "lodash";
import { CLIENT_ID } from "config/constants/config";
import { COURSE_BADGE_ID, GIL_CLIENT_ID } from "config/constants";

class AssigmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: props.campaignId,
      openCallout: false,
      assignmentId: props.item.id,
      questionLists: [],
      selectedQuestion: {},
      assignmentResult: false,
      assignmentEdit: false,
      user_id: this.props.userData.id,
      count: 1,
      courseCompleteSuccessCallout: false,
      userBadgeforCourse: [],
      editQuestion: false,
    };
  }

  componentDidMount = () => {
    this.getQuestionList();
    this.getUserBadges();
  };

  componentDidUpdate = (prevProps, prevStates) => {
    if (prevProps.userData !== this.props.userData) {
      this.getUserBadges();
    }
  };

  getQuestionList = () => {
    const params = { assignment_id: this.state.assignmentId };
    Utility.sendRequest(CREATE_EDIT_QUESTIONS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.setState({
          questionLists: body.data,
          selectedQuestion:
            body.data && body.data.length > 0 ? body.data[0] : {},
        });
      }
    });
  };

  getUserBadges = () => {
    const params = {
      userClientId: this.props.userData.id,
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(USER_BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        const FirstTimeCourseCompleted = body.filter(
          (item) => item.name === "Course"
        );
        this.setState({ userBadgeforCourse: FirstTimeCourseCompleted });
      }
    });
  };

  openCallout = () => {
    this.setState({
      openCallout: true,
    });
  };

  nextQuestion = () => {
    const { selectedQuestion, questionLists } = this.state;
    const index = questionLists.findIndex((x) => x.id === selectedQuestion.id);

    if (this.state.editQuestion !== true) {
      if (index > -1 && questionLists.length - 1 > index) {
        this.setState({
          selectedQuestion: questionLists[index + 1],
          count: this.state.count + 1,
        });
      } else {
        this.setState({
          assignmentEdit: true,
        });
      }
    } else {
      this.setState({
        editQuestion: false,
        assignmentEdit: true,
      });
    }
  };

  editQuestion = (item) => {
    const { questionLists } = this.state;
    const index = questionLists.findIndex((x) => x.id === item.id);
    if (index > -1) {
      this.setState({
        editQuestion: true,
        selectedQuestion: questionLists[index],
        assignmentEdit: false,
      });
    }
  };

  previousQuestion = () => {
    const { selectedQuestion, questionLists } = this.state;
    const index = questionLists.findIndex((x) => x.id === selectedQuestion.id);
    if (index > -1) {
      this.setState({
        selectedQuestion: questionLists[index - 1],
        count: this.state.count - 1,
      });
    }
  };

  onToggleClick = (evt, i) => {
    const { selectedQuestion } = this.state;
    const questionLists = [...this.state.questionLists];
    const currentIndex = questionLists.findIndex(
      (x) => x.id === selectedQuestion.id
    );

    const question_options_attributes = [
      ...questionLists[currentIndex].question_options_attributes,
    ];

    for (var index = 0; index < question_options_attributes.length; index++) {
      if (i != index) {
        question_options_attributes[index].selected_answer = false;
      } else {
        question_options_attributes[index].selected_answer = evt.target.checked;
      }
    }

    this.setState({
      questionLists: questionLists,
      selectedQuestion: questionLists[currentIndex],
    });
  };

  submitQuestion = () => {
    this.props.showLoader(true);
    const { selectedQuestion, questionLists, assignmentId, user_id } =
      this.state;
    const currentIndex = selectedQuestion.question_options_attributes.findIndex(
      (x) => x.selected_answer === true || x.is_essay === true
    );
    const questionList = [...questionLists];
    const index = questionList.findIndex((x) => x.id === selectedQuestion.id);
    const question_options_attributes = [
      ...questionList[index].question_options_attributes,
    ];

    if (
      selectedQuestion.question_type === 3 &&
      (question_options_attributes[0].essayContent === undefined ||
        question_options_attributes[0].essayContent === "" ||
        question_options_attributes[0].essayContent === null)
    ) {
      this.props.showLoader(false);
      toast.error("Answer is empty", { containerId: "private" });
      return;
    }
    if (currentIndex > -1) {
      const selectOption =
        selectedQuestion.question_options_attributes[currentIndex];
      const params = {
        assignment_id: assignmentId,
        question_id: selectedQuestion.id,
        option_id: selectOption.id,
        is_correct: selectOption.is_correct,
        user_id: user_id,
        essay_content:
          selectedQuestion.question_type === 3
            ? question_options_attributes[0].essayContent
            : null,
        plain_essay_content:
          selectedQuestion.question_type === 3
            ? question_options_attributes[0].plainEssayContent
            : null,
      };
      const reqUrl = has(selectedQuestion, "answer_id")
        ? `${USER_ASSIGNMENT}/${selectedQuestion.answer_id}`
        : USER_ASSIGNMENT;
      const method = has(selectedQuestion, "answer_id") ? 3 : 2;
      Utility.sendRequest(reqUrl, method, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          this.props.showLoader(false);
        } else {
          if (!has(selectedQuestion, "answer_id")) {
            const { selectedQuestion } = this.state;
            const questionLists = [...this.state.questionLists];
            const currentIndex = questionLists.findIndex(
              (x) => x.id === selectedQuestion.id
            );
            questionLists[currentIndex].answer_id = body.data.id;
            this.setState({ questionLists: questionLists });
          }
          this.props.showLoader(false);
          this.nextQuestion();
        }
      });
    } else {
      this.props.showLoader(false);
      toast.error("Please select at least one answer", {
        containerId: "private",
      });
    }
  };

  courseCompleted = () => {
    let totalLessons = 0;
    this.props.sectionList.forEach((section) => {
      if (section.lessons === null) totalLessons += 0;
      else totalLessons += section.lessons.length;
    });

    const list = _.flattenDeep(
      this.props.sectionList.map((section) => section.lessons)
    );
    const completedLessonLength = this.props.userAssignmentDetails.filter(
      (assignment) =>
        list.find((lesson) => lesson.id === assignment.campaign_id)
    ).length;

    if (totalLessons && completedLessonLength) {
      if (
        completedLessonLength + 1 === totalLessons &&
        this.state.userBadgeforCourse.length === 0 &&
        CLIENT_ID === GIL_CLIENT_ID
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  submitAssignmentDetails = () => {
    this.props.showLoader(true);
    const {
      selectedQuestion,
      questionLists,
      assignmentId,
      user_id,
      campaignId,
    } = this.state;
    let totalCorrectAnswers = 0;
    questionLists.forEach(function (val, index) {
      const details = filter(
        val.question_options_attributes,
        (value) =>
          (value.is_correct === 1 && value.selected_answer === true) ||
          value.is_essay === true
      );
      if (details.length > 0) {
        totalCorrectAnswers = totalCorrectAnswers + 1;
      }
    });

    const score = (totalCorrectAnswers * 100) / questionLists.length;
    const params = {
      assignment_id: assignmentId,
      user_id: user_id,
      campaign_id: this.props.campaignDetails[0].campaign_id,
      total_attempted_questions: questionLists.length,
      total_correct_answers: totalCorrectAnswers,
      score: score,
      status: 3,
    };

    Utility.sendRequest(
      USER_ASSIGNMENT_DETAILS,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          this.props.showLoader(false);
        } else {
          this.setState({
            assignmentResult: true,
            assignmentEdit: false,
            score: score,
          });
          const showSuccessScreen = this.courseCompleted();
          if (showSuccessScreen === true) {
            const params = {
              user_client_id: this.props.userData.id,
              badge_id: COURSE_BADGE_ID,
            };

            Utility.sendRequest(
              USER_BADGES,
              2,
              params,
              (error, response, body) => {
                body = JSON.parse(body);
                if (!body.error) {
                  this.setState({ courseCompleteSuccessCallout: true });
                  this.props.showLoader(false);
                }
              }
            );
          }
          this.props.showLoader(false);
        }
      }
    );
  };

  changeEssay = (data) => {
    const { selectedQuestion } = this.state;
    const questionLists = [...this.state.questionLists];
    const currentIndex = questionLists.findIndex(
      (x) => x.id === selectedQuestion.id
    );
    const question_options_attributes = [
      ...questionLists[currentIndex].question_options_attributes,
    ];

    let plainEssayContent = data;
    plainEssayContent = plainEssayContent.replace(/<[^>]+>/g, "");

    question_options_attributes[0].essayContent = data;
    question_options_attributes[0].plainEssayContent = plainEssayContent;

    this.setState({
      questionLists: questionLists,
      selectedQuestion: questionLists[currentIndex],
    });
  };

  closeAssignment = () => {
    this.setState({
      assignmentResult: false,
      openCallout: false,
      assignmentEdit: false,
    });
    this.props.refreshCourse();
  };

  render() {
    const { userAssignmentDetails, item } = this.props;
    const isCompleted =
      (userAssignmentDetails &&
        userAssignmentDetails.some(
          (assignment) => assignment.campaign_id === item.campaign_id
        )) ||
      false;
    return (
      <>
        <div className="assignment-list px-5 pt-3 pb-5">
          <div className="align-items-center ">
            <AssignmentCheckIcon mainClass="mr-2 assignment-icon" />{" "}
            <span className="font-weight-bold font-24">Assignment</span>
          </div>
          <div className="font-18 mt-1 mb-4 pb-1">
            Start the assignment to begin
          </div>
          <div className="px-5 py-4 d-flex justify-content-between align-items-center assignment-list assignment-list-inner mb-4">
            {this.props.platform_Id === GIL_CLIENT_ID && (
              <img src={process.env.PUBLIC_URL + "/icons/icon-72x72.png"} />
            )}
            <span className="font-bold font-18">
              {this.props.item.assignment_name}
            </span>
            <button
              className="btn btn-sm btn-primary btn-half-rounded font-weight-bold font-16"
              onClick={this.openCallout}
              disabled={isCompleted}
            >
              {isCompleted ? "Completed" : "Start"}
            </button>
          </div>
        </div>
        <div className="mt-5 p-1" />
        {this.state.openCallout && (
          <SideCallout
            position="position-absolute assignment-callout"
            closeCallOut={this.closeAssignment}
            isCourseAssignment={true}
            calloutHeading={"Assignment"}
            calloutHeadingSvg={
              <AssignmentCheckIcon mainClass="assignment-icon" />
            }
            fromPlusIcon={true}
          >
            {this.state.questionLists &&
              this.state.questionLists.length > 0 &&
              this.state.questionLists.map((item, index) => (
                <div
                  className={`item ${
                    this.state.selectedQuestion.id === item.id
                      ? "show-quiz"
                      : "hide-quiz"
                  }`}
                  key={`question_${index}`}
                >
                  {this.state.openCallout && (
                    <AssignmentQuestion
                      {...item}
                      {...this.props}
                      {...this.state}
                      changeEssay={this.changeEssay}
                      essayContent={this.state.essayContent}
                      nextQuestion={this.submitQuestion}
                      onToggleClick={this.onToggleClick}
                      previousQuestion={this.previousQuestion}
                      closeCallOut={this.closeAssignment}
                    />
                  )}
                </div>
              ))}
          </SideCallout>
        )}

        {this.state.assignmentResult && (
          <SideCallout
            position="position-absolute assignment-callout"
            closeCallOut={this.closeAssignment}
            calloutHeading={"Assignment"}
            isCourseAssignment={true}
            calloutHeadingSvg={
              <AssignmentCheckIcon mainClass="assignment-icon" />
            }
            fromPlusIcon={true}
          >
            <AssignmentResult
              {...this.state}
              {...this.props}
              courseCompleteSuccessCallout={
                this.state.courseCompleteSuccessCallout
              }
              goToHome={() => this.props.history.push("/")}
              goToCourse={() => {
                this.closeAssignment();
                this.setState({ courseCompleteSuccessCallout: false });
              }}
              closeCallOut={() =>
                this.setState({ courseCompleteSuccessCallout: false })
              }
            />
          </SideCallout>
        )}

        {this.state.assignmentEdit && (
          <SideCallout
            position="position-absolute assignment-callout"
            closeCallOut={this.closeAssignment}
            calloutHeading={"Assignment"}
            isCourseAssignment={true}
            calloutHeadingSvg={
              <AssignmentCheckIcon mainClass="assignment-icon" />
            }
            fromPlusIcon={true}
          >
            <AssignmentEdit
              {...this.state}
              {...this.props}
              editQuestion={this.editQuestion}
              submitAssignmentDetails={this.submitAssignmentDetails}
              closeCallOut={this.closeAssignment}
            />
          </SideCallout>
        )}
      </>
    );
  }
}

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
  userData: state.userData,
  publicUserData: state.publicUserData,
  neighborhoodState: state.neighborhoodState,
  isLoading: state.isLoading,
  isChatFormOpen: state.isChatFormOpen,
  seeAll: state.seeAll,
  campaignSelectedTab: state.campaignSelectedTab,
  campaignId: state.campaignFormSelectedId,
});

export default connect(mapStateToProps, mapActionToProps)(AssigmentList);
