import React, { memo } from 'react';
import { CloseIcon } from 'shared/icons/icons';
import { Utility } from 'utils';
import { CLIENT_ID } from 'config/constants/config';
import { GIL_CLIENT_ID } from 'config/constants';

const pwaModeEnabled = Utility.pwaModeEnabled();

let SideCallout = (props) => {
  return (
    <div className={CLIENT_ID===GIL_CLIENT_ID&&(props.isCourseAssignment || props.isProfilePage ) ? `px-0 profile-position position-fixed bg-white w-100 h-100 ${!props.isProfilePage ? 'assignmentPage' : 'profilePopUpPage'}` : `col-5 px-0 ${props.position
      ? props.position
      : 'position-absolute '}
      bg-white page-sidebar-outer `}>
      <div className="pwa-callout-decorator-sec py-3 d-xl-none" >
        <div onClick={props.closeCallOut} className="pwa-callout-decorator" />
      </div>
      <div className={`page-sidebar-inner h-100 overflow-auto scroll-y shar-form search-form-pwa sidebar-form search-form ${props.innerPosition}`}>
        <div className="page-sidebar-head border-bottom align-items-center page-sidebar-head-article">
          {props.calloutHeadingSvg}
          <img src={props.calloutHeadingImg} alt="" className="sidebar-header-icon" />
          <span className="font-bold display-8 ml-2">{props.calloutHeading}</span>
          <div className="ml-auto cursor-pointer" onClick={props.closeCallOut}>
            { pwaModeEnabled ? props.calloutCloseIcon : <CloseIcon /> }
          </div>
        </div>
        <div className={CLIENT_ID===GIL_CLIENT_ID && !props.isProfilePage && "page-sidebar-body"}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SideCallout = memo(SideCallout);
