import React, { useState, useEffect } from 'react';
import { Utility } from 'utils';
import { toast } from 'react-toastify';
import { CLIENT_ID } from 'config/constants/config';
import { GROUP_MEMBERS, CAMPAIGNS_TOP_STORIES, GET_USERS_BY_NETWORKS } from 'config/constants/api_endpoints';
import { NoRecordFound } from 'shared/components/no_record_found';
import CreatebleCard from 'shared/components/cards/creatable_card';
import { updateCampaignFormSelectedId } from 'shared/actions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { STATUS } from 'config/constants';


export default function Testimonies(props) {
  const [groupMembers, setGroupMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [testimonyData, setTestimonyData] = useState([]);
  const dispatch = useDispatch();
  const groupId = props.groupId;

  useEffect(() => {
    getGroupMember();
  }, []);

  useEffect(() => {
    getTestimony();
  }, [selectedMember]);

  const getGroupMember = () => {
    Utility.sendRequest(GROUP_MEMBERS, 1, { groupId, status: STATUS.approved }, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        getUserDetails(body.map(e => e.member_id));
      }
    });
  };

  const getUserDetails = (selectedIds) => {
    const params = {
      client_id: CLIENT_ID,
      type: 'all',
      forGroupMembers: true,
      selectedIds: !Utility.isEmpty(selectedIds) ? selectedIds : [null],
    };

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.registrants)
          setGroupMembers(body.registrants);
          setSelectedMember(body.registrants[0]);
      }
    });
  };

  const getTestimony = () => {
    const params = {
      publisher_network_id: CLIENT_ID,
      forTestimony: true,
      groupId,
      memberId: selectedMember.id,
    };

    Utility.sendRequest(CAMPAIGNS_TOP_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.length)
          setTestimonyData(body);
      }
    });
  };

  const handleTestimonyClick = (campaign) => {
    dispatch(updateCampaignFormSelectedId(campaign.id));
  };

  const returnCreatebleCard = (e) => (
    <CreatebleCard
      key={e.id}
      data={e}
      className="article-outer"
      useUpload={false}
      handleClick={() => handleTestimonyClick(e)}
      title={!Utility.isEmpty(e.heading)? e.heading : ''}
      description={e.description}
      forTestimony={true}
    />
  );

  const paintMembersList = () => {
    return (
      <>
        <div className="d-flex testimonies-outer" >
          { groupMembers.map(e => {
            return (
              <div className="testimonies-info" key={e.id}>
                <img
                  src={e.profile_image_url
                    || require("assets/images/users/user_no_image.png")}
                  onClick={() => setSelectedMember(e)}
                  className={
                    `testimonies-img rounded-circle
                    ${e.id === selectedMember.id
                    && 'pwa-profile-testimony-user-img'}`}
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const paintTestimony = () => {
    let flag = false;
    testimonyData.map(i => {
      if(i.image_url) flag = true;
    });
    if(testimonyData.length && flag) {
      return testimonyData.map(e => {
        return (
          e.created_at !== e.updated_at &&
          <Link to={{
            pathname: `/campaign/${e.id}`,
            goBackLink: `/groups/${groupId}`,
            calledFrom: 'Testimony' }}>
            {returnCreatebleCard(e)}
          </Link>
        );
      });
    } else if(!flag)
      return <NoRecordFound />;
  };

  return (
    <>
      {paintMembersList()}

      <h1 className="font-24-bold mb-25">
        About {selectedMember.user_name}
      </h1>

      {paintTestimony()}
    </>
  );
}
