import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { updateUserData, showLoader, updateIsNeighborhoodFormOpen, updatePublicUserData, updateIsChatFormOpen, updateChatFriend, updateSeeAll, openNeighborhoodForm, updateAccountList } from 'shared/actions';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { Utility } from 'utils';
import { ALL_PARENT_CAMPAIGNS, USER_BADGES, GET_INVOICE_LICENSE_DATA, GET_FOLLOWERS_FOLLOWING, USER_PROFILE, GROUPS, USER_AUTH, LICENSE, SUPPORT_USER } from 'config/constants/api_endpoints';
import ChatCallout from 'shared/components/chat_callout.js';
import GroupCard from 'shared/components/cards/group_card';
import { toast } from 'react-toastify';
import { ROLE_IDS, CAMPAIGN_TYPES_IDS } from 'config/constants';
import { CLIENT_ID, GROUPS_LIMIT } from 'config/constants/config';
import SeeAll from 'shared/components/v2/see_all';
import { NeighborhoodSignUp } from 'scenes/users/components/neighborhood_sign_up';
import { Dropdown } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import moment from 'moment-timezone';
import Avatar from 'react-avatar';
import CreditCard from 'shared/components/credit_card';
import {  ToolboxIcon, NetworkIcon, FinancesIcon } from 'shared/icons/icons';
import UserLicensesSummary from 'shared/components/user_licenses_summary';
import GetStarted from './components/GetStarted';
import PurchaseCityAccount from './components/PurchaseCityAccount';
import GetKeysToYourCity from './components/GetKeysToYourCity';
import SupportTeamCard from './components/SupportTeamCard';
import AfterPurchase from './components/AfterPurchase';
import ToolboxCard from './components/ToolboxCard';


class ProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myArticles: [],
      currentSelectKey: 'about',
      listFollowerActive: false,
      userData: {},
      passportData: [],
      groupsData: [],
      userBadges: [],
      showGetStarted: false,
      showKeyCallout: false,
      purchaseCityAccount: false,
      afterPurchase: false,
      transactionData: {},
      cityFormData: {},
      licenses: [],
      newUserClientDetails: {},
      showLoader: false,
      accountTabText: "Account Summary",
      accountTabComponent: "3",
      supportUsers: [],
      selectedUser: {},
      isEdit: false,
      toolboxMessageId: 1,
      clientLicenseInvoice: {}
    };

    this.clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.today = moment().tz(this.clientTimezone).format();
  }

  componentDidMount = () => {
    const { match: { params } } = this.props;
    this.props.updateIsNeighborhoodFormOpen(false);
    this.props.openNeighborhoodForm({});
    if (!Utility.isEmpty(params.id)) {
      this.profileData(params.id);
    } else {
      this.setState({ userData: this.props.userData });
      if (!Utility.isEmpty(this.props.userData)) {
        this.getMyArticles(this.props.userData);
        this.getGroups();
        this.getUserBadges(this.props.userData);
      }
    }

    if (this.props.seeAll.calledFrom === 'community')
      this.props.updateSeeAll({ isOpen: null, calledFrom: this.props.seeAll.calledFrom });
    else
      this.props.updateSeeAll({ isOpen: this.props.seeAll.calledFrom, calledFrom: this.props.seeAll.calledFrom });

    this.getSupportTeamMembers();
    this.getUserInvoiceData();
    this.getLicenses();
  }

  componentDidUpdate = (prevProps, prevStates) => {
    let userData = {};
    if ((prevStates.myArticles.length !== this.state.myArticles.length) ||
        (prevProps.userData !== this.props.userData) ||
        (prevProps.match.params.id !== this.props.match.params.id)) {
      if (this.props.location.state && this.props.location.state.otherUserProfile) {
        const { match: { params } } = this.props;

        if (!Utility.isEmpty(params.id)) {
          this.profileData(params.id);
        }
      }
      else {
        const { match: { params } } = this.props;
        userData = params.id ? this.props.publicUserData : this.props.userData;

        this.setState({
          userData: userData,
          currentSelectKey: 'about'
        });

        this.getMyArticles(userData);
        this.getGroups();
        this.getUserBadges(userData);
      }
    }
    if (prevProps.refreshCampaignApi !== this.props.refreshCampaignApi) {
      this.getMyArticles(this.props.userData);
      this.getGroups();
      this.getUserBadges(this.props.userData);
    }
  }

  getUserInvoiceData = () => {
    Utility.sendRequest(GET_INVOICE_LICENSE_DATA, 1, { user_client_id: this.props.userData.id, conn_to_another_city_id: this.props.userData.conn_to_another_city_id }, (err, res, body) => {
      body = JSON.parse(body);

      if(!Utility.isEmpty(body)) {
        this.setState({ clientLicenseInvoice: body });
      }
    });
  }

  setCityFormData = (data) => {
    this.setState({ cityFormData: data });
  }

  profileData = (id) => {
    const params = { userClientId: this.props.userData.id, publicUserClientId: id };

    this.props.showLoader(true);
    Utility.sendRequest(USER_PROFILE, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      this.props.showLoader(false);

      if (!body.error) {
        this.props.updatePublicUserData(body);
        this.setState({ userData: body, currentSelectKey: 'about' });

      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }

      if (!Utility.isEmpty(body)) {
        this.getMyArticles(body);
        this.getUserBadges(body);
      }
    });
  }

  getMyArticles = (userData) => {
    this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
    };

    Utility.sendRequest(ALL_PARENT_CAMPAIGNS, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);

      if (!body.error) {
        if (!Utility.isEmpty(body.data)) {
          body.data.forEach((item) => {
            item.author_image_url = userData.profile_image_url;
            item.author_name = userData.user_name;
          });
        }

        this.setState({ myArticles: body.data });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  getLicenses = () => {
    this.props.showLoader(true);
    const params = {
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(LICENSE, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);

      if (!body.error) {
        this.setState({ licenses: body });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  handleFollowUnfollow = () => {
    this.setFollowerfollowing(this.state.userData.id, this.props.userData.id, this.props.userData.client_id);
  }

  setFollowerfollowing = (followingId, followerId, clientId) => {
    const params = { followerId, followingId, clientId, };

    Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      else {
        const userData = { ...this.state.userData };
        userData.is_followed = !userData.is_followed;
        this.setState({ userData: userData });
      }
    });
  }

  getGroups = () => {
    const params = {
      clientId: CLIENT_ID,
      userClientId: this.props.userData.id,
      limit: GROUPS_LIMIT,
      forMyGroups: true,
    };

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({ groupsData: body });
      }
    });
  };

  getSupportTeamMembers = () => {
    const params = {
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(SUPPORT_USER, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({ supportUsers: body });
      }
    });
  };

  getUserBadges = (userData) => {
    const params = {
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(USER_BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) this.setState({ userBadges: body });
    });
  };

  paintGroups = () => {
    const arr = [];
    this.state.groupsData.length &&
      this.state.groupsData.map(e => {
        arr.push(
          <GroupCard
            key={e.id}
            id={e.id}
            link={`/groups/${e.id}`}
            imageUrl={e.image_url}
            name={e.name}
            calledFrom='profileGroups'
            goBackLink={`/profile/${this.props.userData.id}`}
            description={e.description}
          />
        );
      });
    return (
      !Utility.isEmpty(arr) &&
      <div className="group-section group-container mb-42">
        <h1 className="mb-25 home-page-heading" >
          <img
            src={require('assets/images/profile/badges/gm-badge.svg')}
            alt=""
          />
          <span className="font-24-bold flex-auto ml-2">My Groups</span>
          {this.state.groupsData && this.state.groupsData.length > 2 &&
            <span
              className="see-all-text display-7"
              onClick={() =>
                this.props.updateSeeAll({ isOpen: 'profileGroups', calledFrom: 'profileGroups' })
              }>
              See All
            </span>
          }
        </h1>
        <div className="d-flex flex-wrap">
          {arr}
        </div>
      </div>
    );
  }

  handleKey = (key) => {
    this.setState({ currentSelectKey: key });
  }

  returnBadges = () => {
    if (!Utility.isEmpty(this.state.userBadges)) {
      return this.state.userBadges.map(e => {
        return (
          <div key={e.name} className="earned-badge mr-3" >
            {
              !Utility.isEmpty(this.state.userBadges) &&
                e.have_badge ?
                <div className="badge-earned text-center" >
                  <img src={require('assets/images/profile/badges/gm-badge.svg')} alt="" />
                  <div className="display-8 text-primary font-medium mt-2" >{e.name}</div>
                </div>
                :
                <div className="badge-earned text-center" >
                  <img src={require('assets/images/profile/badges/empty-badge.svg')} alt="" />
                  <div className="display-8 text-primary font-medium mt-2" >{e.name}</div>
                </div>
            }
          </div>
        );
      });
    }
  };

  openNeighborhoodForm = (userData) => {
    const data = {
      showForm: true,
      edit: userData ? true : false,
      id: userData && userData.id,
      roleId: userData && userData.role_id
    };

    this.props.openNeighborhoodForm(data);
  };

  getUpdatedUserDetails = () => {
    return new Promise((resolve, reject) => {
      Utility.sendRequest(USER_AUTH, 1, {}, (error, response, body) => {
        body = JSON.parse(body);

        this.props.showLoader(false);

        if (!body.error) {
          this.setState({ userData: body });
          resolve(true);
        } else {
          reject(false);
          toast.error(body.error.message, { containerId: 'public' });
        }
      });
    });
  }

  editCloseForm = () => {
    this.setState({ isEdit: false });
    this.props.openNeighborhoodForm({});
    this.getUpdatedUserDetails();
  }

  handleAccountDropdown = (evKey, evObj) => {
    this.setState({ accountTabText: evObj.currentTarget.innerText, accountTabComponent: evKey });
  }

  render() {
    const { match: { params } } = this.props;
    const { id, client_id } = this.state.userData;
    const userData = this.state.userData || '';
    const accountList = this.props.accountList;
    const profileImgUrl = !Utility.isEmpty(userData) ? userData.profile_image_url : '';
    const country = !Utility.isEmpty(userData.country) ? userData.country : '';
    const city = !Utility.isEmpty(userData.creator_city) ? `${userData.creator_city}, ` : '';
    const bannerImage = (ROLE_IDS.PARENT_OWNER === userData.role_id || ROLE_IDS.CLIENT_OWNER === userData.role_id) ? userData.client_banner_image_url : userData.user_banner_image_url;

    return (
      <>
        <div className="client-profile-changed h-100 mt-3 mt-lg-0">
          <div className="client-profile-outer scroll-y h-lg-100">
            <div className="client-profile-header-outer">
              <div className="client-profile-header mx-auto pt-md-0 pt-3 pb-0">
                {(params && !params.id) &&
                  <img
                    src={require('assets/images/icons/menu_vertical_icon_white.svg')}
                    className={`profile-edit-verticle`}
                    alt="menu-icon"
                    onClick={() => {
                      this.setState({ isEdit: true }, () => this.openNeighborhoodForm(userData));
                      }
                    }
                  />
                }
                <div className="client-profile-header-bg">
                  <img src={!Utility.isEmpty(bannerImage) ? bannerImage : require('assets/images/profile/default-bg.png')}
                    className=""
                    alt=""
                  /></div>
                <div className="client-profile-info text-center">
                  <div className="client-profile-img">
                    {
                      !Utility.isEmpty(profileImgUrl) ? <Image className="rounded-circle client-profile-dropdown-img" src={profileImgUrl} alt="Profile Picture" />
                    : <Avatar
                        name={userData.user_name}
                        round={true}
                        size="220"
                        textSizeRatio={1.75}
                        color='#007bb3'
                        maxInitials={1}
                      />
                  }
                  </div>
                  <div className="client-profile-info p-md-3 mt-4 mt-lg-0" >
                    <h1 className="font-20-bold mb-2 text-capitalize" >{userData.user_name}</h1>
                    <div className="client-profile-location align-items-center justify-content-center" >
                      <span className="display-6">
                        {city}
                        {country}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="client-profile-inner-wrap">
              <div className="client-profile-inner mx-auto pl-md-0 pt-2">
                <Tabs id="uncontrolled-tab-example" activeKey={this.state.currentSelectKey} onSelect={(key) => this.handleKey(key)}>
                  <Tab eventKey="about" title={<><ToolboxIcon fillClass="fill-current" /><span>Toolbox</span></>}>
                    <div className="row mx-0 justify-content-between" >
                      <ToolboxCard
                        updateUserData={this.props.updateUserData}
                        userData={Utility.isEmpty(this.state.userData) ? this.props.userData : this.state.userData}
                        supportUsers={this.state.supportUsers}
                        updateToolboxMessageId={(id) => this.setState({ toolboxMessageId: id })}
                        {...this.props}
                      />
                    </div>
                  </Tab>
                  <Tab eventKey="followers" title={<><NetworkIcon fillClass="fill-current" /><span>Network</span></>}>
                    <div className="d-flex mt-2 pt-2 flex-wrap flex-md-nowrap support-team-tab">
                      <Dropdown>
                        <Dropdown.Toggle variant="support-team" className="events-none" id="dropdown-basic">
                          Support Team
                        <div className="ml-auto toggle-icon" >
                            {/*<img
                              src={require('assets/images/icons/chevron_down.svg')}
                              alt="drodown-icon"
                            />*/}
                          </div>
                        </Dropdown.Toggle>

                        {/*<Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>*/}
                      </Dropdown>

                      <div className="ml-auto d-flex">
                        <InputField
                          type="text"
                          className="input-form-size search-follower"
                          placeholder="Search Followers…"
                        />
                        {/*<img
                          src={require('assets/images/profile/new-profile/setting-icon.svg')}
                          alt="drodown-icon"
                          className="ml-3"
                        />*/}
                      </div>
                    </div>
                    <SupportTeamCard {...this.props} data={this.state.supportUsers} />
                  </Tab>
                  <Tab eventKey="following" title={<><FinancesIcon fillClass="fill-current" /><span>Account</span></>}>
                    {ROLE_IDS.USER === userData.role_id ?
                      <>
                        <div className="font-20 b-700 mt-3">Licenses</div><br />
                        <div className="font-24 b-700">Citizen</div><br />
                        <div className="citizen-box p-4 box">
                          <Row className="city-details profile-text-dark align-items-center">
                            <Col md={4} className="border-right">
                              <div className="d-flex flex-column">
                                <div className="text-uppercase b-600">Citizen</div>
                                <div className="text-uppercase">account</div>
                              </div>
                              <div className="mt-3">Base License</div>
                              <div className="mt-3 text-primary font-24 b-700">FREE</div>
                              <p className="mt-3">You have been a Citizen since {" "}
                                {moment(userData.user_client_created_at).format('MMMM')} {moment(userData.user_client_created_at).format('D')}, {moment(userData.user_client_created_at).format('Y')}.
                              </p>
                            </Col>
                            <Col md={8}>
                              <div className="d-flex">
                                <div className="key-icon">
                                  <Image src={require('assets/images/profile/new-profile/security-key.svg')} alt="" />
                                </div>
                                <div className="key-detail">
                                  <div className="font-26 b-700 text-dark">Time to upgrade!</div>
                                  <p className="mb-4 mt-2">Get the latest platform software to help you crate your own digital community experience.</p>
                                  <button
                                    onClick={() => this.setState({ GetKeysToYourCity: true })}
                                    className="btn btn-theme mr-4 btn-primary px-3 py-3 btn-get-started d-flex align-items-center position-relative justify-content-center" >
                                    <Image
                                      src={require('assets/images/profile/new-profile/toolbox-white.svg')}
                                      alt="drodown-icon"
                                      className="mr-2 feed-profile" />
                                      Purchase Key
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    :
                      <>
                        <div className="d-flex mt-2 pt-2 flex-wrap flex-md-nowrap support-team-tab">
                          <Dropdown onSelect={this.handleAccountDropdown}>
                            <Dropdown.Toggle variant="support-team" id="dropdown-basic">
                              {this.state.accountTabText}
                            <div className="ml-auto toggle-icon" >
                                <img
                                  src={require('assets/images/icons/chevron_down.svg')}
                                  alt="drodown-icon"
                                />
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="3" active={this.state.accountTabComponent === '3'}>
                                Account Summary
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="1" active={this.state.accountTabComponent === '1'}>
                                Statements
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="2" active={this.state.accountTabComponent === '2'}>Billing</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>

                          <div className="ml-auto d-flex">
                            <InputField
                              type="text"
                              className="input-form-size search-follower"
                              placeholder="Search Transactions…"
                            />
                            {/*<img
                              src={require('assets/images/profile/new-profile/setting-icon.svg')}
                              alt="drodown-icon"
                              className="ml-3"
                            />*/}
                          </div>
                        </div>
                        { this.state.accountTabComponent === '1' &&
                          <div className="statement">
                            <Col lg={4} md={12}>
                              <div className="font-18 b-700 my-4">Statements</div>
                              <div className="box overflow-hidden mb-4">
                                <div className="d-flex">
                                  <div className="statement-icon">
                                    <img
                                      src={require('assets/images/profile/new-profile/statements.svg')}
                                      alt="drodown-icon"
                                    />

                                  </div>
                                  <div className="statement-details flex-column justify-content-center">
                                    <div className="font-16 b-700 profile-text-dark">Monthly License</div>
                                    <p className="font-12 m-0 profile-text-dark">Receipt #234434</p>
                                    <p className="font-12 m-0 profile-text-dark">${Utility.paintUptoTwoDecimalPlaces(517.50)} - January 1, 2021</p>
                                  </div>
                                </div>
                              </div>
                              <div className="box overflow-hidden mb-4">
                                <div className="d-flex">
                                  <div className="statement-icon">
                                    <img
                                      src={require('assets/images/profile/new-profile/statements.svg')}
                                      alt="drodown-icon"
                                    />

                                  </div>
                                  <div className="statement-details flex-column justify-content-center">
                                    <div className="font-16 b-700 profile-text-dark">Monthly License</div>
                                    <p className="font-12 m-0 profile-text-dark">Receipt #234434</p>
                                    <p className="font-12 m-0 profile-text-dark">${Utility.paintUptoTwoDecimalPlaces(517.50)} - January 1, 2021</p>
                                  </div>
                                </div>
                              </div>
                              <div className="box overflow-hidden mb-4">
                                <div className="d-flex">
                                  <div className="statement-icon">
                                    <img
                                      src={require('assets/images/profile/new-profile/statements.svg')}
                                      alt="drodown-icon"
                                    />

                                  </div>
                                  <div className="statement-details flex-column justify-content-center">
                                    <div className="font-16 b-700 profile-text-dark">Monthly License</div>
                                    <p className="font-12 m-0 profile-text-dark">Receipt #234434</p>
                                    <p className="font-12 m-0 profile-text-dark">${Utility.paintUptoTwoDecimalPlaces(517.50)} - January 1, 2021</p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </div>
                        }
                        { this.state.accountTabComponent === '2' &&
                          <CreditCard
                            userData={userData}
                            marginTop="mt-5 pt-2"
                          />
                        }

                        { this.state.accountTabComponent === '3' &&
                          <UserLicensesSummary
                            licenseData={this.state.clientLicenseInvoice}
                            userData={userData}
                            accountList={accountList}
                          />
                        }
                      </>
                    }
                  </Tab>
                </Tabs>
                {
                  Utility.isEmpty(params.id) && ROLE_IDS.USER === userData.role_id &&
                  <button
                    onClick={() => this.setState({ GetKeysToYourCity: true })}
                    className="btn btn-theme btn-primary px-4 py-3 mt-3 btn-get-started d-flex align-items-center" >
                    <img
                      src={require('assets/images/logos/s-icon-sharp.svg')}
                      alt="drodown-icon"
                      className="mr-3 btn-icon-inside" />
                    Get Started
                  </button>
                }
                {this.state.showGetStarted &&
                  <GetStarted
                    handleClose={() => this.setState({ showGetStarted: false })}
                    showKeyCallout={() => this.setState({ showKeyCallout: true })}
                    userData={Utility.isEmpty(this.state.userData) ? this.props.userData : this.state.userData}
                    supportUsers={this.state.supportUsers}
                    {...this.props}
                  />
                }
                {this.state.GetKeysToYourCity &&
                  <GetKeysToYourCity
                    openNeighborhoodForm={this.openNeighborhoodForm}
                    userData={userData}
                    licenses={this.state.licenses}
                    handleClose={() => this.setState({ GetKeysToYourCity: false })}
                  />
                }
                {
                  this.state.purchaseCityAccount &&
                  <PurchaseCityAccount
                    newUserClientDetails={this.state.newUserClientDetails}
                    handleClose={() => this.setState({ purchaseCityAccount: false })}
                    showAfterPurchase={(data) => this.setState({ afterPurchase: true, transactionData: data })}
                    cityFormData={this.state.cityFormData}
                    userData={Utility.isEmpty(this.state.userData) ? this.props.userData : this.state.userData}
                    logoUrl={this.state.logoUrl}
                  />
                }

                {
                  this.state.afterPurchase &&
                  <AfterPurchase
                    transactionData={this.state.transactionData}
                    handleClose={() => this.setState({ afterPurchase: false })}
                    showLoader={this.props.showLoader}
                    updateUserData={this.props.updateUserData}
                    history={this.props.history}
                    logoUrl={this.state.logoUrl}
                    supportUsers={this.state.supportUsers}
                    {...this.props}
                  />
                }

                <div className="connect-to">
                  <div className="d-flex">
                    <div className="d-flex">
                      {params.id &&
                        <>
                          <a
                            onClick={() => {
                              this.props.updateIsChatFormOpen(true);
                              this.props.updateChatFriend(this.props.publicUserData);
                            }}>
                            <span className="icon">
                              <img src={require('assets/images/icons/chat-bubble-blue.svg')} alt="Chat" />
                            </span>
                            <span>Chat</span>
                          </a>

                          {!Utility.isEmpty(userData.phone_cellular) &&
                            <a target="_blank"
                              className="icon"
                              href={`https://api.whatsapp.com/send?phone=${userData.phone_cellular.replace(/ /g, '')}`}>
                              <span className="icon">
                                <img src={require('assets/images/icons/whatsApp-black.svg')} alt="whatsapp" />
                              </span>
                              <span>WhatsApp</span>
                            </a>
                          }
                        </>
                      }
                      {/*<a href="">
                      <span className="icon"><img src={require('assets/images/icons/contract.svg')} alt="Contract" /></span>
                      <span>Contract</span>
                    </a>
                    */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              this.props.seeAll.isOpen &&
              <SeeAll
                closeCallout={() =>
                  this.props.updateSeeAll({ isOpen: null, calledFrom: null })
                }
                calledFrom={this.props.seeAll.calledFrom}
                width={'100%'}
              />
            }
          </div>
        </div>
        { this.props.isChatFormOpen &&
          <ChatCallout key={this.state.toolboxMessageId}/>
        }

        {
          !Utility.isEmpty(this.props.neighborhoodState) &&
          this.props.neighborhoodState.showForm &&
          this.state.isEdit &&
          <NeighborhoodSignUp
            userData={userData}
            history={this.props.history}
            neighborhoodState={this.props.neighborhoodState}
            edit={this.props.neighborhoodState.edit}
            id={this.props.neighborhoodState.id}
            openNeighborhoodForm={this.props.openNeighborhoodForm}
            closeForm={this.editCloseForm}
            showLoader={this.props.showLoader}
            updateAccountList={this.props.updateAccountList}
            updateUserData={this.props.updateUserData}
          />
        }

        {
          !Utility.isEmpty(this.props.neighborhoodState) &&
          this.props.neighborhoodState.showForm &&
          !this.showPurchaseCityAccount &&
          !this.state.isEdit &&
          <NeighborhoodSignUp
            forCityAccount={true}
            showPurchaseCityAccount={(data) => this.setState({ purchaseCityAccount: true, newUserClientDetails: data })}
            userData={userData}
            history={this.props.history}
            neighborhoodState={this.props.neighborhoodState}
            edit={false}
            id={this.props.neighborhoodState.id}
            openNeighborhoodForm={this.props.openNeighborhoodForm}
            closeForm={this.editCloseForm}
            showLoader={this.props.showLoader}
            updateAccountList={this.props.updateAccountList}
            updateUserData={this.props.updateUserData}
            setCityFormData={this.setCityFormData}
            supportUsers={this.state.supportUsers}
            {...this.props}
          />
        }
      </>
    );
  }
}

const mapActionToProps = { showLoader, updateUserData, updateIsNeighborhoodFormOpen, updatePublicUserData, updateChatFriend, updateIsChatFormOpen, openNeighborhoodForm, updateAccountList, updateSeeAll };

const mapStateToProps = (state) => ({
  userData: state.userData,
  publicUserData: state.publicUserData,
  neighborhoodState: state.neighborhoodState,
  isLoading: state.isLoading,
  isChatFormOpen: state.isChatFormOpen,
  seeAll: state.seeAll,
  refreshCampaignApi: state.refreshCampaignApi,
  chatFriend: state.chatFriend,
  accountList: state.accountList,
});

export default connect(mapStateToProps, mapActionToProps)(ProfileContainer);
