import React, { useEffect, useState } from 'react';
import { Utility } from 'utils';
import SideCallout from 'shared/components/templates/side_callout';
import Conversation from 'scenes/communications/components/conversation';
import { ChatOvalIcon, ChevronUpIcon, AttachIcon, ChevronLeftIcon } from 'shared/icons/icons';
import { useSelector, useDispatch } from 'react-redux';
import { updateIsChatFormOpen, updateIsCampSelFormOpen, updateCampaignSelectedTab, updateCampaignFormOpen, updateChatFriend, updateCategoryListingInfo, updateSeeAll } from 'shared/actions';
import { GET_USERS_BY_NETWORKS, GROUP_MEMBERS, LEAVE_GROUP, CAMPAIGNS_SHARED_IN_CHAT, ADD_PERMISSION, REMOVE_PERMISSION, GETALLJOINEDGROUPS, GROUPS } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import { InputField } from 'shared/components/form_elements';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import moment from 'moment';
import { STATUS } from 'config/constants';

export default function ChatCallout(props) {
  const selectedUser = useSelector(state => state.chatFriend);
  //const isCampaignFormOpen = useSelector(state => state.isCampaignFormOpen);
  const userData = useSelector(state => state.userData);
  const seeAll = useSelector(state => state.seeAll);
  const category_listing_info = useSelector(state => state.category_listing_info);
  const dispatch = useDispatch();
  const [isGroupSettingPageOpen, setIsGroupSettingPageOpen] = useState(false);
  const [openInviteForm, setInviteForm] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [allGroupMembers, setAllGroupMembers] = useState([]);
  const [approvedMembers, setApprovedMembers] = useState([]);
  const [openCategoryListingpage, setOpenCategoryListingpage] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [users, setAllUsers] = useState([]);
  const [showMoreOrLess, setShowMoreOrLess] = useState('Show More');
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [openAddMemberForm, setOpenAddMemberForm] = useState(false);
  const [openGroupPermissionForm, setOpenGroupPermissionForm] = useState(false)
  const [selectedCampaignData, setSelectedCampaignData] = useState([]);
  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [groupMemberCount, setGroupMemberCount] = useState([]);
  const [groupStatus, setGroupStatus] = useState(false);
  const [addMemberPermissionCount, setAddMemberPermissionCount] = useState(0);
  const [editGroupPermissionCount, setEditGroupPermissionCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (selectedUser.group_id) {
      getUserDetails();
      getAllCampaignsSharedInChat();
      getGroupMember();
      setGroupStatus(selectedUser.is_private);
    }
    else {
      getAllCampaignsSharedInChat(selectedUser.id);
    }

  }, [selectedUser]);

  useEffect(() => {
    if (!Utility.isEmpty(users)) {
      const nonAddedMembers = users.filter(d => !allGroupMembers.includes(d.id));
      setAllUsers(nonAddedMembers);
    }
  }, [allGroupMembers, selectedUser]);

  useEffect(() => {
    if (selectedUser.group_id)
      getAllGroupMembers(search);
  }, [search, selectedUser]);

  useEffect(() => {
    if (!Utility.isEmpty(category_listing_info.selectedCategory) && !Utility.isEmpty(categoryList)) {
      const campaign_type = category_listing_info.selectedCategory;
      setSelectedCampaignName(campaign_type);
      if (campaign_type === 'articles')
        setSelectedCampaignData(categoryList.articles);
      else if (campaign_type === 'events')
        setSelectedCampaignData(categoryList.events);
      else
        setSelectedCampaignData(categoryList.courses);
    }

    return () => {
      dispatch(updateCategoryListingInfo({
        ...category_listing_info,
        isListingPageOpen: false,
        isCampaignDisplayFormOpen: false
      }));
      dispatch(updateCampaignFormOpen(false));
    };
  }, [categoryList]);

  useEffect(() => {
    document.querySelector('#root').classList.add('chat-page-main')

    return function cleanup() {
      document.querySelector('#root').classList.remove('chat-page-main')
    }
  });

  const getGroupMember = (search = '', limit = 5) => {
    const params = {
      groupId: selectedUser.group_id,
      search,
      forGroupChat: true
    };
    if (limit === 5) {
      params.limit = limit;
    }

    Utility.sendRequest(GROUP_MEMBERS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setGroupMembers(body);
      }
    });
  };

  const getAllGroupMembers = (search = '') => {
    const params = {
      groupId: selectedUser.group_id,
      search
    };

    Utility.sendRequest(GROUP_MEMBERS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        const approvedMember = body.filter(items => items.status === 'approved' && items.member_id !== userData.id);
        setApprovedMembers(approvedMember);
        if (!search) {
          setAllGroupMembers(body.map(e => e.member_id));
          const allMembersCount = body.filter(items => items.status === 'approved');
          setGroupMemberCount(allMembersCount.length);
          const addMemArr = approvedMember.filter(item => item.member_add_permission === true);
          const editGroupArr = approvedMember.filter(item => item.edit_group_permissions === true);
          setAddMemberPermissionCount(addMemArr.length);
          setEditGroupPermissionCount(editGroupArr.length);
        }
      }
    });
  };


  const getAllCampaignsSharedInChat = (friend_user_client_id = '') => {
    const params = {};

    if (!Utility.isEmpty(friend_user_client_id)) {
      params.friend_client_id = friend_user_client_id;
      params.client_id = userData.id;
    }
    else {
      params.group_id = selectedUser.group_id;
      params.forGroup = true;
    }

    Utility.sendRequest(CAMPAIGNS_SHARED_IN_CHAT, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setCategoryList(body);
      }
    });
  };

  const removeGroupMember = () => {
    const answer = window.confirm("leave this group?");
    if (answer) {
      const params = {
        member_id: userData.id,
        group_id: selectedUser.group_id
      };

      Utility.sendRequest(LEAVE_GROUP, 3, params, (err, res, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          toast.success("You have succesfully left this group!", { containerId: 'private' });
          dispatch(updateIsChatFormOpen(false));
        }
      });
    }
    else {
      return;
    }
  };


  const getUserDetails = (search = '') => {
    const params = {
      client_id: CLIENT_ID,
      type: 'all',
      search,
      forCommunity: true
    };

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        console.log(body.error);
      } else {
        if (body.registrants) {
          setAllUsers(body.registrants);
          getAllGroupMembers();
        }
      }
    });
  };

  const sendInvite = (user_client_id, status = STATUS.pending) => {
    const params = {
      groupId: selectedUser.group_id,
      userClientIds: [user_client_id],
      status
    };

    Utility.sendRequest(GROUP_MEMBERS, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if (body.data) {
          getAllGroupMembers();
          if (status === 'approved') {
            toast.success("You have successfully joined this group", { containerId: 'private' });
            getGroupsYouHaveJoined(user_client_id, selectedUser.group_id);
          }
          else
            toast.success(body.message, { containerId: 'private' });
        }
      }
    });
  };

  const getGroupsYouHaveJoined = (user_client_id, group_id) => {
    const params = {
      user_client_id
    };

    Utility.sendRequest(GETALLJOINEDGROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        const group = body.data.filter(item => item.group_id === group_id);
        if (group)
          dispatch(updateChatFriend(group[0]));
      }
    });
  };

  const AddPermissionStatus = (id, permission_for = 'add_members') => {
    const params = {
      memberIds: [id],
      permission_for,
      group_id: selectedUser.group_id,
      group_name: selectedUser.name,
      from_user_client_id: userData.id
    };

    Utility.sendRequest(ADD_PERMISSION, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        getAllGroupMembers(search);
        if (permission_for === 'add_members')
          toast.success("Successfully assign Add Members Permission ", { containerId: 'private' });
        else
          toast.success("Successfully assign Edit Group Permission ", { containerId: 'private' });
      }
    });
  };

  const removePermissionStatus = (id, permission_for = 'add_members') => {
    const params = {
      memberIds: [id],
      permission_for,
      group_id: selectedUser.group_id,
      group_name: selectedUser.name,
      from_user_client_id: userData.id
    };

    Utility.sendRequest(REMOVE_PERMISSION, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        getAllGroupMembers(search);
        if (permission_for === 'add_members')
          toast.success("Successfully remove Add Members Permission ", { containerId: 'private' });
        else
          toast.success("Successfully remove Edit Group Permission ", { containerId: 'private' });
      }
    });
  }

  const openCategorySection = (e) => {
    e.stopPropagation();
    getAllCampaignsSharedInChat();
    setOpenCategoryListingpage(true);
    dispatch(updateCategoryListingInfo({
      ...category_listing_info,
      isListingPageOpen: true,
    }));
  };

  const showSharedCampaigns = (campaign_type) => {
    setShowCampaigns(true);
    dispatch(updateCategoryListingInfo({
      ...category_listing_info,
      isCampaignDisplayFormOpen: true,
      selectedCategory: campaign_type
    }));
    setSelectedCampaignName(campaign_type);
    if (campaign_type === 'articles')
      setSelectedCampaignData(categoryList.articles);
    else if (campaign_type === 'events')
      setSelectedCampaignData(categoryList.events);
    else
      setSelectedCampaignData(categoryList.courses);
  };

  const handleChange = (e) => {
    if (openInviteForm)
      getUserDetails(e.target.value);
    else
      getGroupMember(e.target.value);
  };

  const handleCampaignClick = (id) => {
    dispatch(updateIsChatFormOpen(false));
    if (props.history) {
      props.history.push({
        pathname: `/campaign/${id}`,
        calledFrom: "direct_message",
      });
    }
  };

  const openEditGroupForm = () => {
    setIsGroupSettingPageOpen(false);
    dispatch(updateIsCampSelFormOpen(false));
    dispatch(updateCampaignSelectedTab("group"));
    dispatch(updateCampaignFormOpen(true));
  };

  const closeCategoryListingPage = () => {
    setOpenCategoryListingpage(false);
    dispatch(updateCategoryListingInfo({
      ...category_listing_info,
      isListingPageOpen: false,
      selectedCategory: ''
    }));
  };

  const closeCampaignDisplayPage = () => {
    setShowCampaigns(false);
    dispatch(updateCategoryListingInfo({
      ...category_listing_info,
      isCampaignDisplayFormOpen: false,
      selectedCategory: ''
    }));
  };

  const toggleShowMoreOrLess = () => {
    if (showMoreOrLess === 'Show More') {
      setShowMoreOrLess('Show Less');
      getGroupMember('', 0);
    }
    else
      setShowMoreOrLess('Show More');
    getGroupMember('');
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    //getAllGroupMembers(e.target.value);
  };

  const handleStatusChange = () => {
    updateGroupStatus(selectedUser.group_id, !groupStatus);
  };

  const updateGroupStatus = (selectedGroupId, status) => {
    const payload = {
      isPrivate: status,
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };
    Utility.sendRequest(GROUPS + '/' + selectedGroupId, 3, payload, async (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setGroupStatus(!groupStatus);
      }
    });
  };

  const closeChatWindow = () => {
    dispatch(updateIsChatFormOpen(false));
    dispatch(updateChatFriend({}));
    if (!seeAll.isOpen && seeAll.calledFrom === 'category_groups') {
      dispatch(updateSeeAll({
        ...seeAll,
        isOpen: 'category_groups'
      }));
    }
  };

  let calloutHeading;
  if (selectedUser.creator_name && selectedUser.creator_name === "Sharemeister")
    calloutHeading = selectedUser.creator_name;
  else calloutHeading = selectedUser.user_name || selectedUser.name || `${selectedUser.first_name} ${selectedUser.last_name}`;
  return (
    <>
      {!Utility.pwaModeEnabled() ?
        !Utility.isEmpty(selectedUser) &&
        <SideCallout
          position='position-fixed direct-message-callout mt-5'
          closeCallOut={() => { dispatch(updateIsChatFormOpen(false)); dispatch(updateChatFriend({})); }}
          calloutHeading={calloutHeading+""+`${selectedUser.category_name ? `(${selectedUser.category_name})` : ""}`}
          calloutCloseIcon={
            <ChevronUpIcon />
          }
          calloutHeadingSvg={
            <ChatOvalIcon fillClass="fill-less-black" />
          }>
          <Conversation
            friend={selectedUser}
            isGroupChat={selectedUser.group_id ? true : false}
          />
        </SideCallout>
        :
        <>
          <div className="chat-as-page" >
            <div className="group-chat-outer" >
              <div onClick={() => setIsGroupSettingPageOpen(true)} className={`group-chat-header bg-primary text-white py-2 px-3 header cursor-pointer`}>
                <div onClick={(e) => {
                  e.stopPropagation();
                  closeChatWindow();
                }} className="mr-2" >
                  <ChevronLeftIcon fillClass="fill-white" />
                </div>

                {selectedUser.image_url || selectedUser.profile_image_url ?
                  <img alt="group image" className="group-chat-dp rounded-circle" src={selectedUser.group_id ? selectedUser.image_url : selectedUser.profile_image_url} />
                  :
                  <Avatar
                    name={calloutHeading}
                    round={true}
                    size={'30'}
                    textSizeRatio={1.75}
                    color='#007bb3'
                    maxInitials={1}
                    className="chat-as-page-avatar"
                  />

                }

                <div className="font-bold ml-3 display-7" >{calloutHeading}</div>
                <div className="ml-auto mr-3" >
                  {
                    !Utility.isEmpty(selectedUser.phone_cellular) &&
                    <a className="d-flex flex-column"
                      href={`tel:${selectedUser.phone_cellular.replace(/ /g, '')}`}>
                      <img alt="call icon" className="group-chat-call" src={require('assets/images/icons/call-white.svg')} />
                    </a>
                  }
                </div>

                <div onClick={(e) => openCategorySection(e)}>
                  <img alt="more icon" className="group-chat-more" src={require('assets/images/icons/menu_vertical_icon_white.svg')} />
                </div>
              </div>
              <div className="group-chat-gil" >
                {!Utility.isEmpty(selectedUser.group_id) &&
                  <div className="text-center group-chat-gil-first" >
                    {selectedUser.image_url ?
                      <img className="group-chat-main-img rounded-circle mx-auto" src={selectedUser.image_url} />
                      :
                      <Avatar
                        name={selectedUser.name}
                        round={true}
                        size={'150'}
                        textSizeRatio={1.75}
                        color='#007bb3'
                        maxInitials={1}
                      />
                    }
                    <div className="font-bold display-7 my-3">{selectedUser.name}</div>
                    <p className="display-7 px-4" >{selectedUser.description}</p>
                    {(selectedUser.member_add_permission || selectedUser.owner_id === userData.id) &&
                      <button className="btn btn-sm text-primary invite-btn-sm" onClick={() => setInviteForm(true)}>Invite Friends</button>
                    }
                    {selectedUser.fromTopics && selectedUser.owner_id !== userData.id &&
                      <button className="btn btn-sm btn-primary invite-btn-sm " style={{ lineHeight: '1px' }} onClick={() => sendInvite(userData.id, STATUS.approved)}>JOIN</button>
                    }
                  </div>
                }
                {!Utility.isEmpty(selectedUser) &&
                  <div className="group-chat-body chat-as-page-body" >
                    <Conversation
                      friend={selectedUser}
                      isGroupChat={selectedUser.group_id ? true : false}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
          {
            !Utility.isEmpty(selectedUser.group_id) && isGroupSettingPageOpen &&
            <SideCallout
              closeCallOut={() => setIsGroupSettingPageOpen(false)}
              position="position-fixed gil-pwa-callout gil-pwa-callout-passport"
              calloutHeadingImg='assets/images/profile/passport-icon.svg'
              calloutCloseIcon={<ChevronUpIcon />}
            >
              <div className="text-center pt-2 position-relative">
                {(selectedUser.edit_group_permissions || selectedUser.owner_id === userData.id) &&
                  <div onClick={() => openEditGroupForm()} className="position-absolute" style={{ right: -15 }} >
                    <EditIcon />
                  </div>
                }
                {selectedUser.image_url ?
                  <img className="group-chat-main-img rounded-circle mx-auto" src={selectedUser.image_url} />
                  :
                  <Avatar
                    name={selectedUser.name}
                    round={true}
                    size={'150'}
                    textSizeRatio={1.75}
                    color='#007bb3'
                    maxInitials={1}
                  />
                }
                <div className="font-bold display-7 my-3">{selectedUser.name}</div>
                <p className="display-7">{selectedUser.description}</p>
                {(selectedUser.member_add_permission || selectedUser.owner_id === userData.id) &&
                  <button className="btn btn-sm text-primary invite-btn-sm" onClick={() => setInviteForm(true)}>Invite Friends</button>
                }
                <div className="text-left mt-20" >
                  {/* <div className="border-top  py-3 d-flex align-items-center justify-content-between group-chat-option" >
                    <div className="group-chat-option-label display-7 font-medium" >Mute Notifications</div>
                    <div className="group-chat-option-setting font-14 text-primary font-medium" >Off</div>
                  </div>

                  <div className="border-top  py-3 d-flex align-items-center justify-content-between group-chat-option" >
                    <div className="group-chat-option-label display-7 font-medium" >Custom Notifications</div>
                    <div className="group-chat-option-setting font-14 text-primary font-medium" >Off</div>
                  </div>

                  <div className="border-top  py-3 d-flex align-items-center justify-content-between group-chat-option" >
                    <div className="group-chat-option-label display-7 font-medium" >Mentions</div>
                    <div className="group-chat-option-setting font-14 text-primary font-medium" >Always Notify Me</div>
                  </div> */}
                  {selectedUser.owner_id === userData.id &&
                    <>
                      <div onClick={() => setOpenAddMemberForm(true)} className="border-top  py-3 d-flex align-items-center justify-content-between group-chat-option cursor-pointer" >
                        <div className="group-chat-option-label display-7 font-medium" >Add Members</div>
                        <div className="group-chat-option-setting font-14 text-primary font-medium" >{`${addMemberPermissionCount} Members`}</div>
                      </div>

                      <div onClick={() => setOpenGroupPermissionForm(true)} className="border-top  py-3 d-flex align-items-center justify-content-between group-chat-option cursor-pointer" >
                        <div className="group-chat-option-label display-7 font-medium" >Edit Group Info</div>
                        <div className="group-chat-option-setting font-14 text-primary font-medium" >{`${editGroupPermissionCount} Members`}</div>
                      </div>
                    </>
                  }

                  <div className="border-top  py-3 d-flex align-items-center justify-content-between group-chat-option" >
                    <div className="group-chat-option-label display-7 font-medium" >Member Requests &amp; Invites </div>
                  </div>

                  <div className="border-top  py-3 d-flex align-items-center justify-content-between group-chat-option" >
                    <div className="group-chat-option-label display-7 font-medium" >Group Status</div>
                    {
                       selectedUser.owner_id !== userData.id ?
                    <div className="group-chat-option-setting font-14 text-primary font-medium" >{selectedUser.is_private ? "Private" : "Public"}</div>
                    :
                    <div className="toggle-switch-container align-items-center" >
                      <div className="toggle-switch toggle-switch-sm align-items-center">
                        <input
                          type="checkbox"
                          name="isPrivate"
                          id="isPrivate"
                          checked={groupStatus}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="isPrivate" className="d-inline-flex mb-0">
                          <span className="toggle-track" />
                        </label>
                      </div>
                      <span className="display-7 font-italic ml-3" >Private</span>
                    </div>
                   }
                  </div>
                  <div className="border-top py-3 d-flex align-items-center justify-content-between group-chat-option">
                    <div className="font-medium display-7 w-100 mb-2" >{`${groupMemberCount} members`}</div>
                    <div className="position-relative shar-form search-form search-form-pwa sidebar-form px-0 pwa-search-outer" >
                      <InputField
                        required={true}
                        type="text"
                        placeholder="Enter name or number"
                        handleChange={handleChange}
                      />
                      <img style={{ right: 30 }} className="pwa-chat-icon position-absolute" src={require('assets/images/icons/search-icon.png')} alt="" />
                    </div>
                    {!Utility.isEmpty(groupMembers) ?
                      groupMembers.map((items, index) => {
                        return (
                          <>
                            <div key={index} className="pwa-chat-view w-100" >
                              <div className="px-2 pwa-chat-unit align-items-center flex-nowrap position-relative" >
                                {!Utility.isEmpty(items.profile_image_url) ?
                                  <img className="pcu-img rounded-circle" src={items.profile_image_url} alt="" />
                                  :
                                  <Avatar
                                    name={items.user_name}
                                    round={true}
                                    size={"60"}
                                    textSizeRatio={1.75}
                                    color="#007bb3"
                                    maxInitials={1}
                                  />
                                }
                                <div onClick={() => { }} className="pcu-text-outer ml-2">
                                  <div className="font-weight-bold text-truncate display-8" >{items.user_name}</div>
                                  <div className="text-truncate font-14 b-500" >{!Utility.isEmpty(items.phone_cellular) ? items.phone_cellular : ''}</div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                      :
                      Utility.pwaModeEnabled() && Utility.isEmpty(users) &&
                      <div className="font-weight-bold display-7 my-4 text-center text-muted" >No Result Found</div>
                    }
                    {groupMembers.length > 4 &&
                      <div onClick={() => toggleShowMoreOrLess()} className="text-center cursor-pointer font-medium font-14 w-100" >{showMoreOrLess}</div>
                    }
                  </div>
                  {
                    selectedUser.owner_id !== userData.id && !selectedUser.fromTopics &&
                    <div className="border-top border-bottom py-3 d-flex align-items-center justify-content-between group-chat-option" >
                      <div onClick={() => removeGroupMember()} className="group-chat-option-label display-7 font-medium text-alternate-light cursor-pointer" >Leave Group</div>
                    </div>
                  }
                </div>
              </div>
            </SideCallout>
          }
          {
            (openCategoryListingpage || category_listing_info.isListingPageOpen) &&
            <SideCallout fromPlusIcon={true} closeCallOut={() => closeCategoryListingPage()} position="position-absolute gil-pwa-callout gil-pwa-callout-slideMore plus-icon-height">
              <div className="mt-n4 mx-n3" >
                <div className="font-weight-bold display-7 flex-center pb-4 position-relative">
                  <img src={require('assets/images/icons/menu_vertical_icon.svg')} className="position-absolute" style={{ width: 5, left: 50 }} />
                  Select a category
                </div>
                <div className='text-left'>
                  <div className="cursor-pointer align-items-center px-4 py-3" onClick={() => showSharedCampaigns("articles")}>
                    <img src={process.env.PUBLIC_URL + '/icons/icon-72x72.png'} alt="" style={{ width: 50 }} />
                    <div className="ml-4" >
                      <h6 className="display-7 font-bold" >Articles</h6>
                      <span className="font-14">{categoryList.articles && categoryList.articles.length}</span>
                    </div>
                  </div>
                  <div className="cursor-pointer align-items-center px-4 py-3" onClick={() => showSharedCampaigns("events")}>
                    <img src={process.env.PUBLIC_URL + '/icons/icon-72x72.png'} alt="" style={{ width: 50 }} />
                    <div className="ml-4" >
                      <h6 className="display-7 font-bold">Events</h6>
                      <span className="font-14">{categoryList.events && categoryList.events.length}</span>
                    </div>
                  </div>
                  <div className="cursor-pointer align-items-center px-4 py-3" onClick={() => showSharedCampaigns("courses")}>
                    <img src={process.env.PUBLIC_URL + '/icons/icon-72x72.png'} alt="" style={{ width: 50 }} />
                    <div className="ml-4" >
                      <h6 className="display-7 font-bold">Courses</h6>
                      <span className="font-14">{categoryList.courses && categoryList.courses.length}</span>
                    </div>
                  </div>
                </div>
              </div>
            </SideCallout>
          }

          { (showCampaigns || category_listing_info.isCampaignDisplayFormOpen) &&
            <>
              <SideCallout
                calloutHeading={Utility.capitalize(selectedCampaignName)}
                fromPlusIcon={true}
                calloutCloseIcon={<ChevronUpIcon />}
                closeCallOut={() => closeCampaignDisplayPage()}
                position="position-absolute gil-pwa-callout gil-conversation-callout">
                {
                  !Utility.isEmpty(selectedCampaignData) ?
                    selectedCampaignData.map(items => {
                      return (
                        <>
                          <div className="group-chat-article">
                            <div className="article-section-outer pr-3" >
                              <div onClick={() => { handleCampaignClick(items.id); }} className="article-outer overflow-hidden mb-3 cursor-pointer">
                                <div className="article-main-img-wrap">
                                  <img src={items.image_url} alt="article image" className="group-chat-article-pic" />
                                </div>
                                <div className="px-3 py-4 ">
                                  <div className="font-14 mb-20 font-bold" style={{ color: 'rgb(29, 77, 25)' }}>{items.campaign_category}</div>
                                  <div className="font-bold display-6 article-title pb-1 text-dark">{items.heading}</div>
                                  <div className="d-flex mt-3">
                                    {!Utility.isEmpty(items.author_image_url) ?
                                      <img src={items.author_image_url} alt="" className="article-pic rounded-circle" />
                                      :
                                      <Avatar
                                        name={items.author_name}
                                        round={true}
                                        size={'20'}
                                        textSizeRatio={1.75}
                                        color='#007bb3'
                                        maxInitials={1}
                                      />
                                    }
                                    <div className='ml-2'>
                                      <div className="font-bold font-12 text-dark text-capitalize">{items.author_name}</div>
                                      <div className="font-12 text-secondary">{moment(items.campaign_created_at).format("MMMM")}{" "}{moment(items.campaign_created_at).format("D")},{moment(items.campaign_created_at).format("Y")}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                    :
                    <div className="text-center">
                      No Record found
                  </div>
                }
              </SideCallout>
            </>
          }

          <div className="pwa-chat-outer py-3 pwa-chat-outer" >
            {!Utility.isEmpty(selectedUser.group_id) && openInviteForm &&
              <>
                <SideCallout
                  fromPlusIcon={true}
                  closeCallOut={() => {
                    setInviteForm(false);
                    getUserDetails();
                  }}
                  position="position-absolute gil-pwa-callout gil-conversation-callout">
                  <div className="pwa-callout-body" >
                    <div className="d-flex align-items-center flex-nowrap mb-2" >
                      <div style={{ minWidth: 60 }} className="pencil-icon-outer flex-center" >
                        <PencilIcon color="#272262" />
                      </div>
                      <h5 className="font-weight-bold display-7 text-primary" >Select a person to invite to the group.</h5>
                    </div>
                    <h6 className="d-none" >Community</h6>
                    <div className="position-relative shar-form search-form search-form-pwa sidebar-form px-2 px-lg-0 pwa-search-outer" >
                      <InputField
                        required={true}
                        type="text"
                        placeholder="Enter name or number"
                        handleChange={handleChange}
                      />
                      <img style={{ right: 30 }} className="pwa-chat-icon position-absolute" src={require('assets/images/icons/search-icon.png')} alt="" />
                    </div>
                    {!Utility.isEmpty(users) ?
                      users.map((items, index) => {
                        return (
                          <>
                            <div key={index} className="pwa-chat-view" >
                              <div className="pwa-chat-unit align-items-center flex-nowrap position-relative" >
                                {!Utility.isEmpty(items.profile_image_url) ?
                                  <img className="pcu-img rounded-circle" src={items.profile_image_url} alt="" />
                                  :
                                  <Avatar
                                    name={items.user_name}
                                    round={true}
                                    size={"60"}
                                    textSizeRatio={1.75}
                                    color="#007bb3"
                                    maxInitials={1}
                                  />
                                }
                                <div className="pcu-text-outer ml-2">
                                  <div className="font-weight-bold text-truncate display-8" >{items.user_name}</div>
                                  <div className="text-truncate font-14 b-500" >{!Utility.isEmpty(items.phone_cellular) ? items.phone_cellular : ''}</div>
                                </div>
                                <div
                                  className="mr-20 ml-auto"
                                  onClick={() => sendInvite(items.id)}>
                                  <button
                                    className="btn btn-primary btn-primary-pwa btn-inside-group-chat font-14" >
                                    SEND
                                    </button>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                      :
                      Utility.pwaModeEnabled() && Utility.isEmpty(users) &&
                      <div className="font-weight-bold display-7 my-4 text-center text-muted" >No Result Found</div>
                    }
                  </div>
                </SideCallout>
              </>
            }
          </div>

          {!Utility.isEmpty(selectedUser.group_id) && openAddMemberForm &&
            <>
              <SideCallout
                fromPlusIcon={true}
                closeCallOut={() => {
                  setOpenAddMemberForm(false);
                  // !Utility.isEmpty(selectedMembers) && updatePermissionStatus();
                }}
                position="position-absolute gil-pwa-callout gil-conversation-callout">
                <div className="pwa-callout-body" >
                  <div className="d-flex align-items-center flex-nowrap mb-4" >
                    <div style={{ minWidth: 60 }} className="pencil-icon-outer flex-center" >
                      <PencilIcon color="#272262" />
                    </div>
                    <h5 className="font-weight-bold display-7 text-primary" >Select person to add members to the group.</h5>
                  </div>
                  <div className="position-relative shar-form search-form search-form-pwa sidebar-form px-2 px-lg-0 pwa-search-outer" >
                    <InputField
                      required={true}
                      type="text"
                      placeholder="Search"
                      handleChange={handleSearch}
                    />
                    <img style={{ right: 30 }} className="pwa-chat-icon position-absolute" src={require('assets/images/icons/search-icon.png')} alt="" />
                  </div>
                  {!Utility.isEmpty(approvedMembers) ?
                    approvedMembers.map((items, index) => {
                      return (
                        <>
                          <div key={index} className="pwa-chat-view" >
                            <div className="pwa-chat-unit align-items-center flex-nowrap position-relative" >
                              {!Utility.isEmpty(items.profile_image_url) ?
                                <img className="pcu-img rounded-circle" src={items.profile_image_url} alt="" />
                                :
                                <Avatar
                                  name={items.user_name}
                                  round={true}
                                  size={"60"}
                                  textSizeRatio={1.75}
                                  color="#007bb3"
                                  maxInitials={1}
                                />
                              }
                              <div className="pcu-text-outer ml-2">
                                <div className="font-weight-bold text-truncate display-8" >{items.user_name}</div>
                              </div>
                              {items.member_add_permission || selectedUser.owner_id === items.member_id ?
                                <div
                                  className="mr-20 ml-auto"
                                  onClick={() => removePermissionStatus(items.member_id, "add_members")}>
                                  <button
                                    className="btn btn-link btn-inside-group-chat text-primary font-14 disabled">
                                    UNSELECT
                                    </button>
                                </div>
                                :
                                <div
                                  className="mr-20 ml-auto"
                                  onClick={() => AddPermissionStatus(items.member_id, "add_members")}>
                                  <button
                                    className="btn btn-primary btn-primary-pwa btn-inside-group-chat font-14" >
                                    SELECT
                                    </button>
                                </div>
                              }
                            </div>
                          </div>
                        </>
                      );
                    })
                    :
                    Utility.pwaModeEnabled() && Utility.isEmpty(users) &&
                    <div className="font-weight-bold display-7 my-4 text-center text-muted" >No Result Found</div>
                  }
                </div>
              </SideCallout>
            </>
          }

          {!Utility.isEmpty(selectedUser.group_id) && openGroupPermissionForm &&
            <>
              <SideCallout
                fromPlusIcon={true}
                closeCallOut={() => {
                  setOpenGroupPermissionForm(false);
                  // !Utility.isEmpty(selectedMembers) && updatePermissionStatus("edit_groups");
                }}
                position="position-absolute gil-pwa-callout gil-conversation-callout">
                <div className="pwa-callout-body" >
                  <div className="d-flex align-items-center flex-nowrap mb-4" >
                    <div style={{ minWidth: 60 }} className="pencil-icon-outer flex-center" >
                      <PencilIcon color="#272262" />
                    </div>
                    <h5 className="font-weight-bold display-7 text-primary" >Select person to edit the group.</h5>
                  </div>
                  <div className="position-relative shar-form search-form search-form-pwa sidebar-form px-2 px-lg-0 pwa-search-outer" >
                    <InputField
                      required={true}
                      type="text"
                      placeholder="Search"
                      handleChange={handleSearch}
                    />
                    <img style={{ right: 30 }} className="pwa-chat-icon position-absolute" src={require('assets/images/icons/search-icon.png')} alt="" />
                  </div>
                  {!Utility.isEmpty(approvedMembers) ?
                    approvedMembers.map((items, index) => {
                      return (
                        <>
                          <div key={index} className="pwa-chat-view" >
                            <div className="pwa-chat-unit align-items-center flex-nowrap position-relative" >
                              {!Utility.isEmpty(items.profile_image_url) ?
                                <img className="pcu-img rounded-circle" src={items.profile_image_url} alt="" />
                                :
                                <Avatar
                                  name={items.user_name}
                                  round={true}
                                  size={"60"}
                                  textSizeRatio={1.75}
                                  color="#007bb3"
                                  maxInitials={1}
                                />
                              }
                              <div className="pcu-text-outer ml-2">
                                <div className="font-weight-bold text-truncate display-8" >{items.user_name}</div>
                              </div>
                              {items.edit_group_permissions || selectedUser.owner_id === items.member_id ?
                                <div
                                  className="mr-20 ml-auto"
                                  onClick={() => removePermissionStatus(items.member_id, "edit_group")}>
                                  <button
                                    className="btn btn-link btn-inside-group-chat text-primary font-14 disabled">
                                    UNSELECT
                              </button>
                                </div>
                                :
                                <div
                                  className="mr-20 ml-auto"
                                  onClick={() => AddPermissionStatus(items.member_id, "edit_group")}>
                                  <button
                                    className="btn btn-primary btn-primary-pwa btn-inside-group-chat font-14" >
                                    SELECT
                              </button>
                                </div>
                              }
                            </div>
                          </div>
                        </>
                      );
                    })
                    :
                    Utility.pwaModeEnabled() && Utility.isEmpty(users) &&
                    <div className="font-weight-bold display-7 my-4 text-center text-muted" >No Result Found</div>
                  }
                </div>
              </SideCallout>
            </>
          }

        </>
      }
    </>
  );
}

const ImgUpload = (props) => {
  return (
    <svg width="25px" height="24px" viewBox="0 0 25 24">
      <g id="PWA-2021" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="7.1-Group-Page-1" transform="translate(-67.000000, -1576.000000)" fill="#272262" fill-rule="nonzero">
          <g id="Group-5" transform="translate(0.001162, 1563.000000)">
            <g id="bx:bx-image-add" transform="translate(66.998838, 13.000000)">
              <path d="M2.5,2.5 L18.75,2.5 L18.75,11.25 L21.25,11.25 L21.25,2.5 C21.25,1.12125 20.12875,0 18.75,0 L2.5,0 C1.12125,0 0,1.12125 0,2.5 L0,17.5 C0,18.87875 1.12125,20 2.5,20 L12.5,20 L12.5,17.5 L2.5,17.5 L2.5,2.5 Z" id="Path"></path>
              <polygon id="Path" points="7.5 10 3.75 15 17.5 15 12.5 7.5 8.75 12.5"></polygon>
              <polygon id="Path" points="21.25 13.75 18.75 13.75 18.75 17.5 15 17.5 15 20 18.75 20 18.75 23.75 21.25 23.75 21.25 20 25 20 25 17.5 21.25 17.5"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const PencilIcon = (props) => {
  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26">
      <g id="PWA-2021" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="5.1A" transform="translate(-34.000000, -190.000000)" fill={props.color}>
          <g id="Group-5" transform="translate(-1.000000, 143.000000)">
            <g id="Icons/Start-Message" transform="translate(35.142857, 47.142857)">
              <path d="M22.381907,3.33487342 C23.3641352,4.31836539 23.3641352,6.03336968 22.381907,7.01686165 L21.4575325,7.94250115 L17.7728909,4.25922731 L18.6998367,3.33487342 C19.6833505,2.35266706 21.3983931,2.35138145 22.381907,3.33487342 L22.381907,3.33487342 Z M3.05489483,22.6601692 L3.81985007,19.6081301 L6.10700198,21.8978022 L3.05489483,22.6601692 Z M15.9562829,6.07836605 L19.6370675,9.75906866 L8.621712,20.7741787 L4.94092734,17.0934761 L15.9562829,6.07836605 Z M24.1972293,1.51573468 C23.2201436,0.537385154 21.9203625,2.62900812e-13 20.5370149,2.62900812e-13 C19.1562385,2.62900812e-13 17.8551718,0.537385154 16.8780861,1.51573468 L2.21022996,16.1819783 C2.21022996,16.1819783 2.20894432,16.1858351 2.20765868,16.1871207 C2.10352192,16.2925408 1.92481808,16.5702326 1.87210688,16.7797871 L0.0387855705,24.1141946 C-0.0717793894,24.5513021 0.0580701566,25.0154075 0.376908646,25.3342389 L0.378194285,25.3355245 L0.380765563,25.3368101 C0.699604052,25.6556415 1.16243412,25.7854881 1.60083704,25.6762112 L8.93412229,23.8416452 C9.14368146,23.7889351 9.42266514,23.6102353 9.5268019,23.5061008 C9.52808754,23.5048152 9.53194446,23.5035296 9.53194446,23.5035296 L24.1998006,8.83600039 C25.1768863,7.85893647 25.7155691,6.55918434 25.7142834,5.17715314 C25.7142834,3.79383634 25.1768863,2.49536982 24.1998006,1.51702029 L24.1972293,1.51573468 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const EditIcon = (props) => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
    >
      <g fill="#272262" fillRule="nonzero">
        <path d="M15.988.012l3 3L16.701 5.3l-3-3zM5 14h3l7.287-7.287-3-3L5 11z" />
        <path d="M16 17H5.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H2V3h6.847l2-2H2C.897 1 0 1.896 0 3v14c0 1.104.897 2 2 2h14a2 2 0 002-2V8.332l-2 2V17z" />
      </g>
    </svg>
  );
};
