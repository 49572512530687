import React, { Component } from 'react';
import AttachFriend from 'scenes/communications/components/attach_friend';
import ConversationOld from 'scenes/communications/components/conversation_old';
import RecentChats from 'scenes/communications/components/recent_chats';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import socket from 'utils/sockets.js';

export default class DirectMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConversation: props.isConversation,
      friend: props.friend,
      isRecentChatView: props.isRecentChatView
    };

    this.child = React.createRef();
  }

  componentDidMount = () => {
    socket.on('message', this.listenForMessage);
  }

  componentWillUnmount = () => {
    socket.off('message', this.listenForMessage);
  }

  componentDidUpdate = (prevProps) => {
    const list = document.getElementById('menu-list');
    if (list) list.classList.add('d-block');

    if ((prevProps.dropdownAction !== this.props.dropdownAction) || (prevProps.selectedMenuItem.id !== this.props.selectedMenuItem.id)) {
      this.setState({ isConversation: false, friend: {}, isRecentChatView: true });
    }

    if (prevProps.friend !== this.props.friend) {
      this.setState({ friend: this.props.friend, isConversation: true, isRecentChatView: false });
    }
  }

  listenForMessage = (newMessage) => {
    if (newMessage.from_user_client_id !== parseInt(this.state.friend.id))
      this.props.updateUnreadMessageCount(1);

    if (!Utility.isEmpty(this.state.friend) && this.conversation && (newMessage.from_user_client_id === this.state.friend.id))
      this.conversation.updateMessages(newMessage);

    if (this.recentChats)
      this.recentChats.updateUnreadCount(newMessage.from_user_client_id);
  }

  startConversation = (friend) => {
    if (Object.keys(friend).length > 0) {
      if (friend.unread_count) {
        this.props.updateUnreadMessageCount(- parseInt(friend.unread_count));
      }

      this.setState({ friend, isConversation: true, isRecentChatView: false });
    } else {
      toast.error('Please select friend first.', { containerId: 'private' });
    }
  }

  back = () => {
    this.setState({ isConversation: !(this.props.dropdownAction === 2),
      friend: this.props.dropdownAction === 2 ? {} : this.state.friend, isRecentChatView: true });
  }

  render() {
    if (!this.state.isConversation && this.props.dropdownAction === 2)
      return (<AttachFriend startConversation={this.startConversation} goBack={this.props.reset}/>);

    return(
      <>
        { this.props.selectedMenuItem.id === 3 && this.state.isRecentChatView &&
            <RecentChats onRef={ref => (this.recentChats = ref)} userData={this.props.userData} selectedMenuItem={this.props.selectedMenuItem}
              startConversation={this.startConversation}
            />
        }

        { this.state.isConversation && this.props.userData &&
            <ConversationOld onRef={ref => (this.conversation = ref)} friend={this.state.friend} goBack={this.back} isRecentChatView={this.state.isRecentChatView} history={this.props.history}/>
        }
      </>
    );
  }
}
