import React from 'react';
import { Form } from 'react-bootstrap';
import { InputField } from "shared/components/form_elements";
import { Image } from 'shared/components/pwa';
import { CLIENT_NAME } from 'config/constants/config';

export default class ForgotEmail extends React.Component{
  render(){
    return (
      <>
        <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column" >
          <div className="d-flex" >
            <Image />
          </div>
          <h1 className="font-24-bold text-center my-5 " >Jump back into the <br /> {CLIENT_NAME} Community!</h1>
          <h1 className="font-18-bold text-center mb-5" >Enter your email and we<br /> will send you a new password!</h1>
          <Form className="pwa-form" >
            <Form.Group controlId="formBasicEmail">
              <InputField required={true}  placeholder="Email Address" type="email" name="email" ref="email" value={this.props.email} handleChange={this.props.handleEmailChange} />
            </Form.Group>
          </Form>
          <div className="text-center mt-auto">
                <button className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100" onClick ={(e) => this.props.forgotPasswordEmail(e)}>SEND </button>
                <button className="btn btn-link btn-cancel lg-btn w-100" onClick={() => this.props.goSignIn()} >LOGIN </button>
            </div>
        </div>
      </>
    );
  }
}
