import React, { Component, Fragment } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import cookie from 'react-cookies';
import { TiStarFullOutline } from "react-icons/ti";
import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { Image } from 'react-bootstrap';
import { Utility } from 'utils';
import { NETWORK, NOTIFICATIONS_IDS, NOTIFICATIONS_TYPES } from 'config/constants/config';
import { showLoader, updatePublicUserData, updateAccountList, updateUserData, openNeighborhoodForm, updateCampaignFormOpen, updateChatFriend, updateIsChatFormOpen, updateSeeAll } from 'shared/actions';
import { connect } from "react-redux";
import { CLIENT_ID, GROUPS_LIMIT } from 'config/constants/config';
import { CHECK_USER_EXISTANCE, SEND_OTP, UPDATE_USER, USER_AUTH, GET_ACCOUNTS,
  SWITCH_ACCOUNT, CAMPAIGNS, GET_FOLLOWERS_FOLLOWING, USER_PROFILE, GROUPS,
  CLIENT_NOTIFICATIONS, NOTIFICATION_MARK_AS_READ, API_BASE_URL, USER_BADGES } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { Otp } from 'scenes/users/components/pwa/sign_up/otp';
import { BACKSPACE, ROLE_IDS, CAMPAIGN_TYPES_IDS } from 'config/constants';
import { PwaImageUpload }  from 'shared/components/pwa_image_upload';
import { BackButton } from 'shared/components/pwa';
import  FollowersFollowing  from 'scenes/profile/pwa/follower_following';
import { SuggestedReadsCard } from 'shared/components/suggested_reads';
import { NeighborhoodSignUp } from 'scenes/users/components/neighborhood_sign_up';
import { AddBoldSmallIcon, StarIcon } from 'shared/icons/icons';
import GroupCard from 'shared/components/cards/group_card';
import CreateCampaign from 'scenes/users/components/create_campaign';
import SideCallout from 'shared/components/templates/side_callout';
import ChatCallout from 'shared/components/chat_callout.js';
import { NotificationGear } from 'shared/components/notification_gear';
import { NoRecordFound } from 'shared/components/no_record_found';
import { Dropdown } from 'react-bootstrap';
import socket from 'utils/sockets.js';
import SeeAll from 'shared/components/v2/see_all';

const validateFields = ['first_name', 'last_name', 'email', 'password'];

const validateOtp = ['otp1', 'otp2', 'otp3', 'otp4'];

class PwaProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      showAllAccountList: false,
      editData: false,
      acitveAccountData: {},
      showOtpForm: false,
      showNotification: false,
      is_mobile_verified: false,
      currentSelectKey:'about',
      listFollowerActive:false,
      myArticles: [],
      showNeighborhood: false,
      userData: {},
      neighborhoodData: {},
      passportData: [],
      groupsData: [],
      notificationData: [],
      userBadges: [],
      showNotificationIcon: true,
      refreshImage: 0,
    };
  }

  componentDidMount = () => {
    const { match: { params } } = this.props;
    this.props.updateIsChatFormOpen(false);

    this.getAllNotifications();
    if(!Utility.isEmpty(this.state.notificationData))
      socket.on('notifications', this.listenForNotifications);

    if (!Utility.isEmpty(params.id)) {
      this.setState({ showNotificationIcon: false });
      this.profileData(params.id);
    } else {
      this.setState({ userData: this.props.userData });
      if (!Utility.isEmpty(this.props.userData)) {
        this.getMyArticles(this.props.userData);
        this.getUserBadges(this.props.userData);
        this.getGroups();
      }
    }

    if(this.props.seeAll.calledFrom === 'community')
      this.props.updateSeeAll({ isOpen: null, calledFrom: this.props.seeAll.calledFrom });
    else
      this.props.updateSeeAll({ isOpen: this.props.seeAll.calledFrom, calledFrom: this.props.seeAll.calledFrom });
  }

  componentDidUpdate = (prevProps, prevStates) => {
    const { match: { params } } = this.props;
    const userData = params.id ? this.props.publicUserData : this.props.userData;

    if(params.id && Utility.isEmpty(this.props.publicUserData)) {
      this.profileData(params.id);
    }

    if ((prevStates.myArticles.length !== this.state.myArticles.length) || (prevProps.userData.role !== this.props.userData.role) || (prevProps.match.params.id !== this.props.match.params.id)) { //|| (prevStates.userData.is_followed !== this.state.userData.is_followed)
      if (this.props.location.state && this.props.location.state.followUnfollow) {
        const { match: { params } } = this.props;

        if (!Utility.isEmpty(params.id)) {
          this.profileData(params.id);
        }
      } else {
        this.setState({
          userData: userData,
          currentSelectKey: 'about'
        });

        this.getMyArticles(userData);
        this.getGroups();
        this.getUserBadges(userData);
      }
    }
    if (prevStates.refreshImage !== this.state.refreshImage) {
      this.setState({ userData });
      this.getAllAccount();
    }
  }

  listenForNotifications = (newNotification) => {
    if (!Utility.isEmpty(newNotification))
      this.getAllNotifications();
  };

  markAsRead = (id) => {
    Utility.sendRequest(NOTIFICATION_MARK_AS_READ, 3, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error)
        toast.error(body.error.message, { containerId: 'public' });
      else
        this.getAllNotifications();
    });
  };

  getUserBadges = (userData) => {
    const params = {
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(USER_BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) this.setState({ userBadges: body });
    });
  };

  getAllNotifications = () => {
    const params = { ':id': this.props.userData.id };
    const baseURL = Utility.getAPIEndpointWithParams(CLIENT_NOTIFICATIONS, params);

    Utility.sendRequest(baseURL, 1, {}, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.setState({ notificationData: body.data });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  getMyArticles = (userData) => {
    this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
    };

    Utility.sendRequest(CAMPAIGNS, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);

      if (!body.error) {
        if (!Utility.isEmpty(body.data)) {
          body.data.forEach((item) => {
            item.image_url = item.preview_image_url;
            item.author_image_url = userData.profile_image_url;
            item.author_name = userData.user_name;
          });
        }

        this.setState({ myArticles: body.data });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  getGroups = () => {
    const params = {
      clientId: CLIENT_ID,
      userClientId: this.props.userData.id,
      limit: GROUPS_LIMIT,
      forMyGroups: true,
    };

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({ groupsData: body });
      }
    });
  };

  setDefaultData = () => {
    const data = this.state.userData;

    return {
      email: data.email || '',
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      phone_cellular: `${data.country_code}${data.phone_cellular}` || '',
      country_code: data.country_code || ''
    };
  }

  handleChange = (e, type) => {
    let formData = this.state.formData;

    if (validateOtp.includes(type)) {
      this.displayError('otp', '');
    }

    formData = Utility.validateAndReturnFormData(this, formData, e, validateFields);

    this.setState({ formData });
  }

  goToHome = () => {
    if(this.props.seeAll.calledFrom) {
      window.history.back();
      this.props.updateSeeAll(
        { isOpen: this.props.seeAll.calledFrom, calledFrom: this.props.seeAll.calledFrom }
      );
    }
    else window.history.back();
  }

  accountSection = () => {
    this.setState((prevState) => {
      return { showAllAccountList: !prevState.showAllAccountList, editData: false };
    });
  }

  editAccount = (id) => {
    if (id === this.props.userData.id)
    this.setState({ editData: true, formData: this.setDefaultData() });
  }

  setValue = (value) => {
    const element = Utility.getElementById('country_code_error');
    if (element) element.textContent = '';

    const formData = { ...this.state.formData };
    formData.phone_cellular = value;

    if (!Utility.isEmpty(value)) {
      const data = parsePhoneNumber(value);

      if (data) {
        const error = Utility.getElementById('phone_cellular_error');
        if (error) error.textContent = '';

        formData.country_code = `+${data.countryCallingCode}`;

        this.setState({ formData });
      }
    }
  }

  checkUserExistance = (e) => {
    e.preventDefault();

    this.props.showLoader(true);

    const params = {
      client_id: CLIENT_ID,
      country_code: this.state.formData.country_code,
      phone_cellular: this.state.formData.phone_cellular.slice(this.state.formData.country_code.length, this.state.formData.phone_cellular.length)
    };

    Utility.sendRequest(CHECK_USER_EXISTANCE, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      this.props.showLoader(false);

      if (!body.error) {
        this.sendOtp();
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  sendOtp = () => {
    const formData = Utility.deepClone(this.state.formData);
    const params = {
      phone_cellular: formData.phone_cellular.slice(formData.country_code.length, formData.phone_cellular.length),
      country_code: formData.country_code
    };

    this.props.showLoader(true);

    Utility.sendRequest(SEND_OTP, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      this.props.showLoader(false);

      if (!body.error) {
        this.setState({ showOtpForm: true, otp: body.code });
        toast.success(body.message, { containerId: 'public' });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  goBack = () => {
    this.setState({ showOtpForm: false });
  }

  handleOnKeyUp = (e, type) => {
    if (type !== "otp4" && e.keyCode !== BACKSPACE) {
     this.refs['otp'].refs[type].refs[type].nextSibling.nextSibling.focus();
   } else if (e.keyCode === BACKSPACE && type !== "otp1") {
     this.refs['otp'].refs[type].refs[type].previousSibling.previousSibling.focus();
   }
  }

  verifyOtp = async (e) => {
    e.preventDefault();
    const validateField = await this.cunstomValidation();

    if (!(validateField.isFormValid)) {
      const errorMessage = 'All fields are required';
      this.displayError('otp', errorMessage);

      return;
    }

    if (validateField.otp === this.state.otp.toString()) {
      this.setState({ showOtpForm: false, is_mobile_verified: true });
    } else {
      toast.error('Invalid OTP', { containerId: 'public' });
    }
  }

  cunstomValidation = () => {
    const inputs = document.querySelectorAll('input');
    let isFormValid = true;
    let otp = '';

    inputs.forEach((input) => {
      if (Utility.isEmpty(input.value)) isFormValid = false;
      otp = otp.concat(input.value);
    });

    return { isFormValid, otp };
  }

  displayError = (id, errorMessage) => {
    const error = Utility.getElementById(`${id}Error`);
    if (error) error.textContent = errorMessage;
  }

  updateUserData = () => {
    const params = this.createUpdateParams();
    this.props.showLoader(true);

    return new Promise((resolve, reject) => {
      Utility.sendRequest(UPDATE_USER, 3, params, (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);

        if (!body.error) {
          resolve(true);
        } else {
          reject(false);
          toast.error(body.error.message, { containerId: 'public' });
        }
      });
    });
  }

  fetchUserDetails = () => {
    this.props.showLoader(true);

    return new Promise((resolve, reject) => {
      Utility.sendRequest(USER_AUTH, 1, {}, async (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);

        if (!body.error) {
          await this.props.updateUserData(body);
          this.accountSection();
          resolve(true);
        } else {
          reject(false);
          toast.error(body.error.message, { containerId: 'private' });
        }
      });
    });
  }

  submitForm = (event) => {
    event.preventDefault();

    if (!Utility.showFormErrors(this, validateFields)) {
      return;
    }

    const formData = Utility.deepClone(this.state.formData);
    const phoneNumber = `${this.props.userData.country_code}${this.props.userData.phone_cellular}`;

    if (formData.phone_cellular !== phoneNumber) {
      if (!this.state.is_mobile_verified) {
        toast.error('Click on update to verify your new number', { containerId: 'public' });
        return;
      }
    }

    this.apiCalling(formData);
  }

  createUpdateParams = () => {
    const formData = Utility.deepClone(this.state.formData);
    const countryCode = formData.country_code;
    const mobileNo = formData.phone_cellular.slice(formData.country_code.length, formData.phone_cellular.length);

    return{
      first_name: formData.first_name || '',
      last_name: formData.last_name || '',
      phone_cellular: mobileNo || '',
      email: formData.email || '',
      country_code: countryCode,
      client_id: CLIENT_ID
    };
  }

  getAllAccount = () => {
    this.props.showLoader(true);

    return new Promise((resolve, reject) => {
      Utility.sendRequest(GET_ACCOUNTS, 1, {}, (error, response, body) => {
        body = JSON.parse(body);

        this.props.showLoader(false);

        if (!body.error) {
          this.props.updateAccountList(body);
          resolve(true);
        } else {
          reject(false);
          toast.error(body.error.message, { containerId: 'public' });
        }
      });
    });
  }

  apiCalling = async () => {
    let next = false;

    await this.updateUserData().then((success) => {
      next = success; }).catch((error) => { next = error; });

    if (next) {
      await this.fetchUserDetails().then((success) => {
        next = success; }).catch((error) => { next = error; });
    }
    if (next) {
      await this.getAllAccount().then((success) => {
        next = success; }).catch((error) => { next = error; });
    }
  }

  cancelForm = (e) => {
    e.preventDefault();
    this.accountSection();
  }

  switchAccount = (id, data) => {
    if ((ROLE_IDS.PARENT_OWNER === data.role_id
      && data.client_id !== CLIENT_ID)) {
       const url = data.client_source_domain;
       url && window.open(url, '_blank');
    }
    else {
      this.props.showLoader(true);

      Utility.sendRequest(SWITCH_ACCOUNT, 1, { id }, (error, response, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'public' });
        } else {
          cookie.save('sessionToken', body.token, { path: '/' });

          Utility.sendRequest(USER_AUTH, 1, { id }, (error, response, body) => {
            body = JSON.parse(body);

            this.props.showLoader(false);

            if (!body.error) {
              this.props.updateUserData(body);
              if (data.network !== 'Parents') {
                this.props.history.push({
                pathname: '/profile',
                state: { data }
              });
              } else {
                window.location.reload();
              }
            } else {
              toast.error(body.error.message, { containerId: 'public' });
            }
          });
        }
      });
    }
  }

  handleKey = (key) => {
    this.setState({ currentSelectKey: key });
  }

  openNeighborhoodForm = (userData) => {
    const data = {
      showForm: true,
      edit: userData ? true : false,
      id: userData && userData.id,
      roleId:  userData && userData.role_id
    };

    this.setState({ showNeighborhood: true, neighborhoodData: data });
  }

  closeForm = () => {
    this.setState({ showNeighborhood: false, neighborhoodData: {} });
  }

  handleFollowUnfollow = () => {
    this.setFollowerfollowing(this.state.userData.id, this.props.userData.id, this.props.userData.client_id);
  }

  setFollowerfollowing = (followingId, followerId, CLIENT_ID) => {
      const params = { followerId: followerId, followingId: followingId, clientId: CLIENT_ID, };

      Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 2, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        }
        else {
          const userData = { ...this.state.userData };
          userData.is_followed = !userData.is_followed;
          this.setState({ userData: userData });
        }
      });
  }

  profileData = (id) => {
    const params = { userClientId: this.props.userData.id, publicUserClientId: id };

    this.props.showLoader(true);
    Utility.sendRequest(USER_PROFILE, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      this.props.showLoader(false);

      if (!body.error) {
        this.props.updatePublicUserData(body);
        this.setState({ userData: body, currentSelectKey: 'about' });

      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }

      if (!Utility.isEmpty(body)) {
        this.getMyArticles(body);
        this.getUserBadges(body);
      }
    });
  }

  returnBadges = () => {
    if(!Utility.isEmpty(this.state.userBadges)) {
      return this.state.userBadges.map(e => {
        return (
          <div key={e.name} className="earned-badge mr-3" >
            {
              !Utility.isEmpty(this.state.userBadges) &&
              e.have_badge ?
              <div className="badge-earned text-center" >
                <img src={require('assets/images/profile/badges/gm-badge.svg')} alt="" />
                <div className="display-8 text-primary font-medium mt-2" >{e.name}</div>
              </div>
              :
              <div className="badge-earned text-center" >
                <img src={require('assets/images/profile/badges/empty-badge.svg')} alt="" />
                <div className="display-8 text-primary font-medium mt-2" >{e.name}</div>
              </div>
            }
          </div>
        );
      });
    }
  };

  paintGroups = () => {
    const arr = [];
    this.state.groupsData.length &&
    this.state.groupsData.map(e => {
      arr.push(
        <GroupCard
          key={e.id}
          id={e.id}
          link={`/groups/${e.id}`}
          imageUrl={e.image_url}
          name={e.name}
          description={e.description}
        />
      );
    });
    return(
      !Utility.isEmpty(arr) &&
      <div className="group-section">
        <h1 className="mb-25 home-page-heading" >
        <img
        src={require('assets/images/profile/badges/gm-badge.svg')}
        alt="" className="heading-badge-img"
        />
        <span className="font-24-bold flex-auto ml-2">My Groups</span>
        <span
          className="see-all-text display-7"
          onClick={() =>
            this.props.updateSeeAll({ isOpen: 'profileGroups', calledFrom: 'profileGroups' })
          }>
          See All
        </span>
        </h1>
        <div className="group-container d-flex flex-wrap">
          {arr}
        </div>
      </div>
    );
  }

  addLinksToNoti = async (data) => {
    if(NOTIFICATIONS_IDS.NEW_DIRECT_MESSAGE === data.notification_id) {
      await Utility.sendRequest(USER_PROFILE, 2,  { userClientId: this.props.userData.id, publicUserClientId: data.ref_record_id }, (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          this.props.updateChatFriend(body);
          this.props.updateIsChatFormOpen(true);
          this.markAsRead(data.id);
        }
      });
    }
    else {
      this.markAsRead(data.id);
      return this.props.history.push({
        pathname: NOTIFICATIONS_TYPES[data.notification_type] + data.ref_record_id,
        state: { fromPwaNotification: true },
      });
    }
  };

  paintNotification = () => {
    return !Utility.isEmpty(this.state.notificationData) ?
      this.state.notificationData.map(e => {
        return (
            <div className={`${e.is_read ? '' : 'unread'} align-items-center flex-nowrap notification-item-inner`}  key={e.id}>
              <img
                className="notification-img img-border rounded-circle"
                src={e.notification_image || require('assets/images/users/user_no_image.png')} alt="" />
              <div className="align-items-center ml-3 flex-nowrap">
                <div onClick={() => this.addLinksToNoti(e)} dangerouslySetInnerHTML={{ __html:  e.notification_message }} key={e.id}/>
                  {/*<Dropdown>
                    <Dropdown.Toggle className="notification-dropdown" variant="none" id="dropdown-notify">
                      <img src={require('assets/images/icons/menu_horizontal_icon.svg')} className="notification-horizontal-menu-img" alt="horizontal menu" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="notification-dropdown-inner" >
                      <NotificationGear
                        id={e.id}
                        markAsRead={this.markAsRead}
                      />
                    </Dropdown.Menu>
                  </Dropdown>*/}
                  <span className="font-12 time-ago">{Utility.returnDuration(e.created_at)}</span>
              </div>
            </div>
        );
      }) : <NoRecordFound />;
  };

  showBellIndicator = () => {
    let flag = false;
    !Utility.isEmpty(this.state.notificationData) &&
    this.state.notificationData.map(e => {
      if(!e.is_read)
        flag = true;
    });
    return flag;
  };

  render() {
    const { match: { params } } = this.props;
    const { id, actual_id, client_id } = this.state.userData;
    const { userData } = this.state;
    const profileImgUrl = !Utility.isEmpty(userData) ? userData.profile_image_url : require("assets/images/users/user_no_image.png");
    const name = !Utility.isEmpty(userData) ? userData.user_name : '';
    const showFollowUnfollow = !Utility.isEmpty(userData);
    const bannerImage = ROLE_IDS.CLIENT_OWNER === userData.role_id ? userData.client_banner_image_url : userData.user_banner_image_url;

    return(
      <div className="pwa-profile-outer">
        {
          !this.state.showNeighborhood &&
          <div className="pwa-profile-head">
            <div className="d-flex flex-wrap justify-content-between">
              <div>
                  <img src={require('assets/images/icons/back_icon_white.svg')}  alt="back icon" onClick={this.goToHome}/>
              </div>
              <div className="text-center text-white">
                <PwaImageUpload
                  removeEditInfoText={true}
                  key={profileImgUrl}
                  imageURL={profileImgUrl}
                  updateUserData={this.props.updateUserData}
                  refreshImage={() => this.setState({ refreshImage: Math.random() })}
                />
                <div className="font-bold display-6 mt-3 mb-1 text-capitalize">{name}</div>
                {/*<div className="display-8" >Dallas, TX, USA</div>*/}
              </div>
              <div className="position-relative pwa-notification d-flex flex-column">
              { this.state.showNotificationIcon &&
                <>
                  <div className={`${this.showBellIndicator() && 'notification-indicator'} rounded-circle position-absolute`} />
                  <img
                    src={require('assets/images/icons/bell_icon_white.svg')}
                    alt="bell icon"
                    onClick={() => this.setState({ showNotification: !this.state.showNotification })}
                  />
                  <div className={`text-center mt-auto mb-4 pwa-profile-toggle ${this.state.showAllAccountList ? 'open' : ''}`}>
                    <img src={require('assets/images/icons/down_icon_white.svg')}
                    alt="down icon"
                    onClick={this.accountSection}/>
                  </div>
                </>
              }
              </div>

            </div>
            <img src={!Utility.isEmpty(bannerImage) ? bannerImage : require('assets/images/profile/user-profile-bg.jpg')}
              className="client-profile-header-bg"
              alt=""
            />
          </div>
        }
        <div className="pwa-profile-inner">
          { this.state.showNotification &&
            <div className={`pwa-profile-accounts px-3 pb-4`}>
            <div className="d-flex align-items-center justify-content-between">
            <h1 className="font-21-bold">Notifications</h1>
            </div>
            {this.paintNotification()}
            </div>
          }

          {!this.state.showAllAccountList && !this.state.showNeighborhood && !this.state.showNotification &&
            <div className="pwa-profile-section">
              <div className="text-center">
                {params && params.id && showFollowUnfollow &&
                  <button onClick={this.handleFollowUnfollow} className="btn btn-primary pwa-sm-btn">{this.state.userData.is_followed ? 'UNFOLLOW' : 'FOLLOW'}</button>
                }
                <div className="d-flex flex-wrap align-items-end  justify-content-center px-3 mt-20 social-contact-list">
                  {params.id &&
                    <>
                      {
                      !Utility.isEmpty(userData.phone_cellular) &&
                      <div className="d-flex flex-column mr-last-4 mb-0" >
                        <a target="_blank"
                          className="d-flex flex-column"
                          href={`https://api.whatsapp.com/send?phone=${userData.phone_cellular.replace(/ /g, '')}`}>
                          <div className="pwa-profile-comm-icons">
                            <img src={require('assets/images/icons/whatsApp-blue.svg')} alt="whatsapp" />
                          </div>
                          <div className="font-11-bold text-primary mt-1" >WhatsApp</div>
                        </a>
                        </div>
                      }
                    </>
                  }
                  { params.id &&
                    <div className="d-flex flex-column mr-last-4 mb-0" >
                      <a target="_blank" href={`mailto:${userData.email}`} className="d-flex flex-column">
                        <div className="pwa-profile-comm-icons">
                          <img src={require('assets/images/icons/email-blue.svg')} alt="message" className="pwa-email-icon"/>
                        </div>
                        <span className="font-11-bold text-primary mt-1" >Email</span>
                      </a>
                  </div>
                  }
                  { params.id &&
                    <>
                      {
                        !Utility.isEmpty(userData.phone_cellular) &&
                        <div className="d-flex flex-column mr-last-4 mb-0" >
                          <a className="d-flex flex-column"
                            href={`tel:${userData.phone_cellular.replace(/ /g, '')}`}>
                            <div className="pwa-profile-comm-icons">
                              <img src={require('assets/images/icons/call-blue.svg')} alt="call" />
                            </div>
                            <div className="font-11-bold text-primary mt-1" >Call</div>
                          </a>
                        </div>
                      }
                    </>
                  }
                  <div className="d-flex flex-column mr-last-4 mb-0" >
                    { params.id &&
                        <div
                          onClick={() => {
                            this.props.updateIsChatFormOpen(true);
                            this.props.updateChatFriend(this.props.publicUserData);
                          }}
                          className="d-flex flex-column" >
                          <div className="pwa-profile-comm-icons">
                            <img className="m-auto" src={require('assets/images/icons/chat-blue.svg')} alt="chat" />
                          </div>
                          <div className="font-11-bold text-primary mt-1">Chat</div>
                      </div>
                    }
                  </div>
                  {/*
                  <div className="d-flex flex-column d-none" >
                    <img src={require('assets/images/icons/whatsApp-blue.svg')} alt="gift" />
                    <span className="font-11-bold text-primary mt-1" >Send</span>
                  </div>
                  */}
                </div>
            </div>
              <div className="client-profile-inner p-0">
              <Tabs id="uncontrolled-tab-example" activeKey={this.state.currentSelectKey} onSelect={(key) => this.handleKey(key)}>
                <Tab eventKey="about" title="ABOUT">
                {/*-----------------  Might need in future -------------------
                  <div className="passport-section">
                    <h1 className="font-21-bold mb-25 mb-sm-16">
                      <img src={require('assets/images/profile/passport-img.svg')} alt="" className="pwa-heading-img" />  Passport
                    </h1>
                    <div className="d-flex flex-wrap mb-42">
                      {
                        !Utility.isEmpty(this.state.passportData) &&
                        this.state.passportData.map((item, i) => {
                          return (
                            <div className="passport-info-outer" key={i} onClick={() => this.openEditPassportForm(item && item.id)}>
                              <div className="display-8 font-bold mb-4">{item && item.name}</div>
                              <div className="d-flex flex-wrap">
                                {
                                  item && !Utility.isEmpty(item.Tags) &&
                                  item.Tags.map((tag, i) => {
                                    return (
                                      <div className="passport-pill bg-primary text-white font-14 font-bold px-4 mb-2 mr-2" key={i}>
                                        {tag.tag_name}
                                      </div>
                                    );
                                  })
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  */}
                  <div className="mb-42 badges-outer" >
                    <h1 className="font-24-bold mb-25" >Passport</h1>
                    <div className="badges-container d-inline-flex align-items-center" >
                      {this.returnBadges()}
                    </div>
                  </div>
                  {this.paintGroups()}
                  {/*}<div className="interest-section" >
                    <h1 className="font-21-bold mb-25 mb-sm-16" >Interests</h1>
                    <div className="d-flex flex-wrap mb-42" >
                        <div className="interest-inner overflow-hidden" >
                          <img src={require('assets/images/profile/service1.jpg')} alt="img" />
                          <div className="display-8 font-bold interest-text" >Service</div>
                        </div>
                        <div className="interest-inner overflow-hidden" >
                          <img src={require('assets/images/profile/service2.jpg')} alt="" />
                          <div className="display-8 font-bold interest-text" >Leadership</div>
                        </div>
                        <div className="interest-inner overflow-hidden" >
                          <img src={require('assets/images/profile/service3.jpg')} alt="" />
                          <div className="display-8 font-bold interest-text" >Partnership</div>
                        </div>
                    </div>
                  </div>*/}
                  {
                    !Utility.isEmpty(this.state.myArticles) &&
                    <div className="article-section">
                    <h1 className="mb-25 home-page-heading" >
                      <img
                      src={require('assets/images/profile/badges/gm-badge.svg')}
                      alt="" className="heading-badge-img"
                      />
                      <span className="font-24-bold flex-auto ml-2">My Articles</span>
                      <span
                        className="see-all-text display-7"
                        onClick={() =>
                          this.props.updateSeeAll({ isOpen: 'profileMyArticle', calledFrom: 'profileMyArticle' })
                        }>
                        See All
                      </span>
                      </h1>
                      <div className="d-flex flex-wrap mb-42">
                        {
                          this.state.myArticles &&
                          <SuggestedReadsCard stories={this.state.myArticles}/>
                        }
                      </div>
                    </div>
                  }
                </Tab>
                <Tab eventKey="followers" title="FOLLOWERS">
                  { (id && this.state.currentSelectKey === 'followers') &&
                    <FollowersFollowing
                      isFollowerActive={this.state.currentSelectKey}
                      userClientId={id}
                      clientId={client_id}
                      type="follower"
                    />
                  }
                </Tab>
                <Tab eventKey="following" title="FOLLOWING">
                  { (id && this.state.currentSelectKey === 'following') &&
                    <FollowersFollowing
                      isFollowerActive={this.state.currentSelectKey}
                      userClientId={id}
                      clientId={client_id}
                      type="following"
                    />
                  }
                </Tab>
                {/*
                  <Tab eventKey="badges" title="BADGES">
                    badges Tab
                  </Tab>
                */}
              </Tabs>
            </div>
          </div>
          }
          {
            this.state.showAllAccountList && !this.state.showNotification &&
            <div className={`pwa-profile-accounts px-3 pb-4 ${this.state.showOtpForm}`}>
              <div className="d-flex align-items-center justify-content-between">
                <h1 className="font-21-bold">Account Settings</h1>
                <div>
                  <img
                    src={require('assets/images/icons/close-black.svg')}
                    alt="close icon"
                    onClick={this.accountSection} />
                </div>
              </div>
              {
                Object.keys(NETWORK).map((item, i) => {
                  return(
                    <Fragment key={i}>
                      <div className={`client-profile-toggle-outer mt-4 ${item !== 'Users' && this.state.editData ? 'd-none' : ''}`}>
                        <div className="align-items-center justify-content-between">
                        {
                          (!Utility.isEmpty(this.props.accountList[item]) || item === 'Clients') &&
                          <div className="display-8 font-bold mt-2 mb-3" >
                            {NETWORK[item]}
                          </div>
                        }
                        {
                          item === 'Clients' &&
                          <AddBoldSmallIcon
                            mainClass="pwa-profile-action-icon"
                            fillClass="fill-primary"
                            handleClick={() => this.openNeighborhoodForm()}
                          />
                        }
                      </div>
                      {
                        !Utility.isEmpty(this.props.accountList) && this.props.accountList[item].map((data, index) => {
                          return(
                            <Item
                              Key={index}
                              index={index}
                              data={data}
                              userData={this.props.userData}
                              editAccount={this.editAccount}
                              switchAccount={this.switchAccount}
                              editData={this.state.editData}
                              openNeighborhoodForm={this.openNeighborhoodForm}
                            />
                          );
                        })
                      }
                      </div>
                    </Fragment>
                  );
                })
              }
            </div>
          }
          {
            !Utility.isEmpty(this.state.formData) && this.state.editData && !this.state.showOtpForm &&
            <div className="pwa-profile-account-change px-3">
              <div className="mt-4">
                <div className="display-8 font-bold mt-2 mb-3" >Account Type</div>
                {/* <div className="text-center text-primary display-8 font-bold my-4" >THIS IS YOUR DEFAULT ACCOUNT</div> */}
                <button className="btn btn-primary w-100 lg-btn align-items-center justify-content-center" > <StarIcon mainClass="mr-3" /> SET AS DEFAULT</button>
              </div>
              <div className="mt-4">
                <div className="display-8 font-bold mt-2 mb-3" >Edit Information</div>
                <div className="d-flex justify-content-center my-4">
                <PwaImageUpload
                  imageURL={!Utility.isEmpty(profileImgUrl) ? profileImgUrl : false}
                  updateUserData={this.props.updateUserData}
                  refreshImage={() => this.setState({ refreshImage: Math.random() })} />
                </div>
              </div>
              <Form className="pwa-form shar-form">
                <Form.Group controlId="first_name">
                  <InputField
                    required={true}
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    ref="first_name"
                    value={this.state.formData.first_name}
                    handleChange={(e) => this.handleChange(e)}
                  />
                </Form.Group>
                <Form.Group controlId="last_name">
                  <InputField
                    required={true}
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    ref="last_name"
                    value={this.state.formData.last_name}
                    handleChange={(e) => this.handleChange(e)}
                  />
                </Form.Group>
                <Form.Group className="position-relative mobile-input" controlId="formBasicNumber">
                  <PhoneInput
                    id="phone_cellular"
                    placeholder="Mobile Number"
                    className="res-font-size-3-5"
                    defaultCountry="US"
                    value={this.state.formData.phone_cellular}
                    onChange={phone => this.setValue(phone)}
                  />
                  <div className="error errorIcon" id="country_code_error"/>
                  <div className="error errorIcon" id="phone_cellular_error"/>
                </Form.Group>
                <div className="text-right mb-3">
                  <button
                    className="btn btn-link text-primary btn-italic"
                    onClick={(e) => this.checkUserExistance(e)}
                  >
                    Update
                  </button>
                </div>
                <Form.Group controlId="email">
                  <InputField
                    required={true}
                    type="email"
                    placeholder="Email address"
                    ref="email"
                    name="email"
                    value={this.state.formData.email}
                    handleChange={(e) => this.handleChange(e)}
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <InputField
                    disabled={true}
                    type="password"
                    placeholder="Enter Password"
                    ref="password"
                    value="12345678"
                    handleChange={(e) => this.handleChange(e)}
                  />
                </Form.Group>
                <div className="text-center">
                  <button
                    className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
                    onClick={(e) => this.submitForm(e)}
                  >
                    Save Changes
                  </button>
                  <button
                    className="btn btn-link btn-login lg-btn mb-3 w-100"
                    onClick={(e) => this.cancelForm(e)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          }
          {
            this.state.showOtpForm &&
            <ContainerComponent
              goBack={this.goBack}
              text={'Confirm Mobile Number'}
            >
              <Otp
                ref="otp"
                nextPage={this.verifyOtp}
                handleChange={this.handleChange}
                formData={this.state.formData}
                resendVerificationCode={this.sendOtp}
                showLoader={this.state.showLoader}
                onKeyUp = {this.handleOnKeyUp}
                showCancelButton={true}
                cancel={this.goBack}
              />
            </ContainerComponent>
          }
          {
            this.state.showNeighborhood && this.state.neighborhoodData.showForm &&
            <NeighborhoodSignUp
              userData={this.props.userData}
              history={this.props.history}
              neighborhoodState={this.state.neighborhoodData}
              edit={this.state.neighborhoodData.edit}
              id={this.state.neighborhoodData.id}
              openNeighborhoodForm={this.props.openNeighborhoodForm}
              closeForm={this.closeForm}
              showLoader={this.props.showLoader}
              updateAccountList={this.props.updateAccountList}
              updateUserData={this.props.updateUserData}
            />
          }
          {
            this.props.isCampaignFormOpen &&
            <CreateCampaign
              position='position-absolute'
              loadedFrom='pwaCampaign'
              isFormOpen={this.props.isCampaignFormOpen}
              setIsFormOpen={(arg) => this.props.updateCampaignFormOpen(arg)}
            />
          }

          {
            this.props.seeAll.isOpen &&
            <SeeAll
              closeCallout={() =>
                this.props.updateSeeAll({ isOpen: null, calledFrom: null })
              }
              calledFrom={this.props.seeAll.calledFrom}
              width={'100%'}
            />
          }

          { this.props.isChatFormOpen &&
            <ChatCallout />
          }
        </div>
      </div>
    );
  }
}

const Item = (props) => {
  return(
    <div className={`pwa-profile-ac-item overflow-hidden ${props.userData.actual_id === props.data.id ? 'bg-light' : ''} ${props.editData ? 'ac-activated-border' : ''}` }
      onClick={() => props.switchAccount(props.data.id, props.data)}
      key={props.index}
    >
      <div className="align-items-center flex-nowrap py-3 px-2">
        <div
          className="client-profile-dropdown position-relative"
          onClick={() => props.data.network === 'Users' ? props.editAccount(props.data.id) : ''}
          >
          {/*<div className={`notification-indicator rounded-circle position-absolute
            ${props.data.id === props.userData.id ? '' : 'd-none'}`} />*/}
          <Image
            className="rounded-circle pwa-profile-dropdown-img-ac img-border ml-2"
            src={!Utility.isEmpty(props.data.profile_image_url) ? props.data.profile_image_url : require("assets/images/users/user_no_image.png")}
           alt="Profile Picture" />
          <div className="ml-3 pwa-profile-ac-info">
            <div className="font-bold display-7 text-truncate text-capitalize">{props.data.user_name}</div>
            <div className="font-14">
              {!Utility.isEmpty(props.data.creator_address1) ? `${props.data.creator_address1},` : ''}
              {!Utility.isEmpty(props.data.creator_city) ? ` ${props.data.creator_city},` : ''}
              {!Utility.isEmpty(props.data.creator_country) ? ` ${props.data.creator_country}` : ''}
            </div>
          </div>
          <div className={`ml-auto text-primary display-5 ${props.data.network === 'Users' ? '' : 'd-none'}`}>
            <TiStarFullOutline />
          </div>
        </div>
        <div className={`ml-auto text-primary display-5 ${props.data.network !== 'Users' ? '' : 'd-none'}`}>
          <img
            src={require('assets/images/icons/menu_vertical_icon.svg')}
            className="pwa-profile-more-icon"
            alt="menu-icon"
            onClick={() => props.data.network === 'Clients' ? props.openNeighborhoodForm(props.data) : ''}
          />
        </div>
      </div>
    </div>
  );
};

const ContainerComponent = (props) => {
  return(
    <>
      <div className="d-flex">
        <BackButton action={props.goBack}/>
        {
          !Utility.isEmpty(props.text) &&
          <>
            <h4>{props.text}</h4>
          </>
        }
      </div>
      {props.children}
    </>
  );
};

const mapActionToProps =  { updateUserData, updatePublicUserData, showLoader, updateAccountList, openNeighborhoodForm, updateCampaignFormOpen, updateChatFriend, updateIsChatFormOpen, updateSeeAll };

const mapStateToProps = (state) => ({
  userData: state.userData,
  isChatFormOpen: state.isChatFormOpen,
  publicUserData: state.publicUserData,
  isLoading: state.isLoading,
  accountList: state.accountList,
  neighborhoodState: state.neighborhoodState,
  isCampaignFormOpen: state.isCampaignFormOpen,
  seeAll: state.seeAll,
});

export default connect(mapStateToProps, mapActionToProps)(PwaProfileContainer);
