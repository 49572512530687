import {
  UPDATE_CRM_MODULES,
  UPDATE_USER_DATA,
  SHOW_LOADER,
  UPDATE_CLIENT_TYPES,
  UPDATE_SD_GOALS,
  UPDATE_SELECTED_MODULE,
  UPDATE_SELECTED_MENU_ITEM,
  UPDATE_SELECTED_GROUP,
  EDIT_COMPONENT,
  UPDATE_PRODUCTED_ID,
  UPDATE_SELECTED_MODULE_MENU,
  UPDATE_SELECTED_FILTERS,
  UPDATE_ACCOUNT_LIST,
  OPEN_NEIGHBORHOOD_FORM,
  UPDATE_PUBLIC_USER_DATA,
  IS_CAMPAIGN_FORM_OPEN,
  IS_NEIGHBORHOOD_FORM_OPEN,
  IS_CAMP_SEL_FORM_OPEN,
  CAMPAIGN_SELECTED_TAB,
  CAMPAIGN_FORM_SELECTED_ID,
  IS_CHAT_FORM_OPEN,
  CHAT_FRIEND,
  SEE_ALL,
  REFRESH_CAMPAIGN_API,
  OPEN_CITY_PURCHASE,
  UPDATE_LESSONS,
  PLAN_UPGRADE,
  ADMIN_ACCESS_REQUEST,
  ACCEPT_ADMIN_REQUEST,
  OPEN_PLUS_ICON,
  CURRENT_FOOTER_LINK,
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  UPDATE_GUEST_DATA,
  USER_DISTRACTED,
  EXT_TOPIC,
  SESSION_START,
  EXTENSION_DATA,
  IS_CAMPAIGN_VIDEO_PLAYING,
  CATEGORY_LISTING_INFO,
  CART_REMOVE_ALL,
  IS_SESSION_MODAL_OPEN,
  NEW_CORPORATE_JOINED,
  SHOW_EXTENSION_MODAL_REPORT,
} from "config/constants/action_types";
import {
  IS_COURSE_DROPDOWN,
  IS_COURSE_SIDEBAR,
  IS_PAGE_COURSE,
  IS_CITIZEN_SIDEBAR_OPEN,
  HEADER_SEARCH_BAR,
  USER_PROFILE_ID
} from "../../config/constants/action_types";

const updateUserData = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_USER_DATA:
      return payload.userData;
    default:
      return state;
  }
};

const updatePublicUserData = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_PUBLIC_USER_DATA:
      return payload.publicUserData;
    default:
      return state;
  }
};

const updateClientTypes = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_CLIENT_TYPES:
      return payload.clientTypes;
    default:
      return state;
  }
};

const showLoader = (state = [], { type, payload }) => {
  switch (type) {
    case SHOW_LOADER:
      return payload.isLoading;
    default:
      return state;
  }
};

const updateSDGoals = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_SD_GOALS:
      return payload.goals;
    default:
      return state;
  }
};

const updateCrmModules = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_CRM_MODULES:
      return payload.crmModules;
    default:
      return state;
  }
};

const updateSelectedModule = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_SELECTED_MODULE:
      return payload.selectedModuleItem;
    default:
      return state;
  }
};

const IsCourseSidebar = (state = false, { type, payload }) => {
  switch (type) {
    case IS_COURSE_SIDEBAR:
      return payload;
    default:
      return state;
  }
};

const IsCourseDropdown = (state = false, { type, payload }) => {
  switch (type) {
    case IS_COURSE_DROPDOWN:
      return payload;
    default:
      return state;
  }
};

const IsPageCourse = (state = false, { type, payload }) => {
  switch (type) {
    case IS_PAGE_COURSE:
      return payload;
    default:
      return state;
  }
};

const IsCitizenSidebarOpen = (state = false, { type, payload }) => {
  switch (type) {
    case IS_CITIZEN_SIDEBAR_OPEN:
      return payload;
    default:
      return state;
  }
};

const HeaderSearchBar = (state = {}, { type, payload }) => {
  switch (type) {
    case HEADER_SEARCH_BAR:
      return payload;
    default:
      return state;
  }
};

const UserProfileId = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_PROFILE_ID:
      return payload;
    default:
      return state;
  }
};

const updateSelectedMenuItem = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_SELECTED_MENU_ITEM:
      return payload.selectedMenuItem;
    default:
      return state;
  }
};

const updateSelectedGroup = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_SELECTED_GROUP:
      return payload.selectedGroup;
    default:
      return state;
  }
};

const editComponent = (state = [], { type, payload }) => {
  switch (type) {
    case EDIT_COMPONENT:
      return payload.edit;
    default:
      return state;
  }
};

const updateProductID = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_PRODUCTED_ID:
      return payload.productId;
    default:
      return state;
  }
};

const updateSelectedFilters = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_SELECTED_FILTERS:
      return payload.selectedFilters;
    default:
      return state;
  }
};

const updateSelectedModuleMenu = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_SELECTED_MODULE_MENU:
      return payload.selectedModuleMenu;
    default:
      return state;
  }
};

const updateAccountList = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_ACCOUNT_LIST:
      return payload.accountList;
    default:
      return state;
  }
};

const openNeighborhoodForm = (state = [], { type, payload }) => {
  switch (type) {
    case OPEN_NEIGHBORHOOD_FORM:
      return payload.state;
    default:
      return state;
  }
};

const updateCampaignFormOpen = (state = [], { type, payload }) => {
  switch (type) {
    case IS_CAMPAIGN_FORM_OPEN:
      return payload.state;
    default:
      return state;
  }
};

const updateIsNeighborhoodFormOpen = (state = [], { type, payload }) => {
  switch (type) {
    case IS_NEIGHBORHOOD_FORM_OPEN:
      return payload.state;
    default:
      return state;
  }
};

const updateCampaignSelectedTab = (state = [], { type, payload }) => {
  switch (type) {
    case CAMPAIGN_SELECTED_TAB:
      return payload.state;
    default:
      return state;
  }
};

const updateOpenCityPurchase = (state = [], { type, payload }) => {
  switch (type) {
    case OPEN_CITY_PURCHASE:
      return payload.state;
    default:
      return state;
  }
};

const updateCampaignFormSelectedId = (state = [], { type, payload }) => {
  switch (type) {
    case CAMPAIGN_FORM_SELECTED_ID:
      return payload.state;
    default:
      return state;
  }
};

const updateIsCampSelFormOpen = (state = [], { type, payload }) => {
  switch (type) {
    case IS_CAMP_SEL_FORM_OPEN:
      return payload.state;
    default:
      return state;
  }
};

const updateIsChatFormOpen = (state = [], { type, payload }) => {
  switch (type) {
    case IS_CHAT_FORM_OPEN:
      return payload.state;
    default:
      return state;
  }
};

const updateIsSessionModalOpen = (state = [], { type, payload }) => {
  switch (type) {
    case IS_SESSION_MODAL_OPEN:
      return payload.state;
    default:
      return state;
  }
};

const updateChatFriend = (state = [], { type, payload }) => {
  switch (type) {
    case CHAT_FRIEND:
      return payload.state;
    default:
      return state;
  }
};

const updateSeeAll = (state = [], { type, payload }) => {
  switch (type) {
    case SEE_ALL:
      return payload.state;
    default:
      return state;
  }
};

const updateRefreshCampaignAPI = (state = [], { type, payload }) => {
  switch (type) {
    case REFRESH_CAMPAIGN_API:
      return payload.state;
    default:
      return state;
  }
};

const updateLessons = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_LESSONS:
      return payload.state;
    default:
      return state;
  }
};

const updatePlanUpgraded = (state = [], { type, payload }) => {
  switch (type) {
    case PLAN_UPGRADE:
      return payload.state;
    default:
      return state;
  }
};

const updateIsAdminAccessRequestFormOpen = (state = [], { type, payload }) => {
  switch (type) {
    case ADMIN_ACCESS_REQUEST:
      return payload.state;
    default:
      return state;
  }
};

const updateIsAcceptedAdminRequestFormOpen = (
  state = [],
  { type, payload }
) => {
  switch (type) {
    case ACCEPT_ADMIN_REQUEST:
      return payload.state;
    default:
      return state;
  }
};

const openOrClosePlusIcon = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_PLUS_ICON:
      return payload.state;
    default:
      return state;
  }
};

const updateFooterLink = (state = "home", { type, payload }) => {
  switch (type) {
    case CURRENT_FOOTER_LINK:
      return payload.state;
    default:
      return state;
  }
};

// Cart Reducer
const cartReducer = (state = { cartItems: [] }, { type, payload }) => {
  switch (type) {
    case CART_ADD_ITEM:
      const item = payload;
      const exist = state.cartItems.find((i) => i.product === item.product);
      if (exist) {
        return {
          ...state,
          cartItems: state.cartItems.map((i) =>
            i.product === exist.product ? item : i
          ),
        };
      } else {
        return { ...state, cartItems: [...state.cartItems, item] };
      }
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.product !== payload),
      };
    case CART_REMOVE_ALL:
      return payload;
    default:
      return state;
  }
};

// Update Guest User Reducer
const updateGuestData = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_GUEST_DATA:
      return payload.guestData;
    default:
      return state;
  }
};

const updateUserDistracted = (state = [], { type, payload }) => {
  switch (type) {
    case USER_DISTRACTED:
      return payload.isUserDistracted;
    default:
      return state;
  }
};

const updateExtTopic = (state = [], { type, payload }) => {
  switch (type) {
    case EXT_TOPIC:
      return payload.extTopic;
    default:
      return state;
  }
};

const updateSessionStart = (state = [], { type, payload }) => {
  switch (type) {
    case SESSION_START:
      return payload.isSessionStart;
    default:
      return state;
  }
};

const updateExtUserId = (state = [], { type, payload }) => {
  switch (type) {
    case EXTENSION_DATA:
      return payload.extUserId;
    default:
      return state;
  }
};

const updateCampaignVideoPlaying = (state = false, { type, payload }) => {
  switch (type) {
    case IS_CAMPAIGN_VIDEO_PLAYING:
      return payload.isVideoPlayed;
    default:
      return state;
  }
};

const updateCategoryListingInfo = (state = {}, { type, payload }) => {
  switch (type) {
    case CATEGORY_LISTING_INFO:
      return payload.state;
    default:
      return state;
  }
};

const updateNewCorporateJoined = (state = [], { type, payload }) => {
  switch (type) {
    case NEW_CORPORATE_JOINED:
      return payload.state;
    default:
      return state;
  }
};

const updateExtensionModalReport = (state = [], { type, payload }) => {
  switch (type) {
    case SHOW_EXTENSION_MODAL_REPORT:
      return payload.state;
    default:
      return state;
  }
};

export {
  updateExtTopic,
  updateSessionStart,
  updateCrmModules,
  updateUserData,
  updateClientTypes,
  updateSDGoals,
  showLoader,
  updateSelectedModule,
  updateSelectedMenuItem,
  updateSelectedGroup,
  editComponent,
  updateProductID,
  updateSelectedModuleMenu,
  updateSelectedFilters,
  updateAccountList,
  openNeighborhoodForm,
  updatePublicUserData,
  updateCampaignFormOpen,
  updateIsNeighborhoodFormOpen,
  updateIsCampSelFormOpen,
  updateCampaignSelectedTab,
  updateCampaignFormSelectedId,
  updateIsChatFormOpen,
  updateChatFriend,
  updateOpenCityPurchase,
  updateSeeAll,
  updateRefreshCampaignAPI,
  updateLessons,
  updatePlanUpgraded,
  updateIsAdminAccessRequestFormOpen,
  updateIsAcceptedAdminRequestFormOpen,
  openOrClosePlusIcon,
  updateFooterLink,
  cartReducer,
  updateGuestData,
  updateUserDistracted,
  updateExtUserId,
  updateCampaignVideoPlaying,
  updateCategoryListingInfo,
  updateIsSessionModalOpen,
  updateNewCorporateJoined,
  updateExtensionModalReport,
  IsCourseSidebar,
  IsCourseDropdown,
  IsPageCourse,
  IsCitizenSidebarOpen,
  HeaderSearchBar,
  UserProfileId
};
