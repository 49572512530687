import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import { CAMPAIGNS_BY_CATEGORY_NAME, GROUPS_BY_CATEGORY_NAME, GETALLJOINEDGROUPS, GET_USERS_BY_NETWORKS, GET_FOLLOWERS_FOLLOWING, CATEGORIES } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';
import HomeContainer from 'shared/components/home_container';
//import { SuggestedReadsCard } from 'shared/components/suggested_reads_articles';
import { SuggestedReadsCard } from 'shared/components/suggested_reads';
import MyCommunity from 'shared/components/my_community';
import GroupCard from 'shared/components/cards/group_card';
import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import Avatar from 'react-avatar';
import { showLoader, updateSeeAll, updateIsChatFormOpen, updateChatFriend, updateCampaignFormOpen, updateRefreshCampaignAPI } from 'shared/actions';
import { connect, useSelector, useDispatch } from 'react-redux';
import SeeAll from 'shared/components/v2/see_all';
import CreateCampaign from 'scenes/users/components/create_campaign';
import { MdPhoto } from 'react-icons/md';
import EventCard from 'shared/components/cards/event_card';

function TopicDetail(props) {
  //const topicName = props.match.params.id;
  const [articles, setArticles] = useState([]);
  const [courses, setCourses] = useState([]);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState(null);
  const [groups, setGroups] = useState([]);
  const [joinedGroups, setJoinedGroups] = useState([]);
  const [isApiResponse, setIsApiResponse] = useState(false);
  const refreshCampaignApi = useSelector(state => state.refreshCampaignApi);
  const isChatFormOpen = useSelector(state => state.isChatFormOpen);
  const userData = useSelector(state => state.userData);
  const [topicExperts, setTopicExperts] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const dispatch = useDispatch();
  let debouncedFn = null;

  useEffect(() => {
    categoryById(props.match.params.id);
  }, [search, refreshCampaignApi, isChatFormOpen]);

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  // useEffect(() => {
  //   if (!seeAll.isOpen && seeAll.calledFrom === 'category_experts') {
  //     console.log("inside useEffect");
  //     dispatch(updateSeeAll({
  //       ...seeAll,
  //       isOpen: 'category_experts'
  //     }));
  //   }
  // }, []);

  const getAllCampaigns = (topicName) => {
    const params = {
      client_id: CLIENT_ID,
      category_name: topicName,
    };

    if (search)
      params.search = search;

    props.showLoader(true);
    Utility.sendRequest(CAMPAIGNS_BY_CATEGORY_NAME, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      props.showLoader(false);
      setIsApiResponse(true);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setEvents(body.events);
        setArticles(body.articles);
        setCourses(body.courses);
      }
    });
  };

  const getAllGroupsByCategory = (topicName) => {
    const params = {
      client_id: CLIENT_ID,
      category_name: topicName,
      user_client_id: userData.id
    };

    if (search)
      params.search = search;

    props.showLoader(true);
    Utility.sendRequest(GROUPS_BY_CATEGORY_NAME, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      props.showLoader(false);
      setIsApiResponse(true);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setGroups(body);
        getGroupsYouHaveJoined();
      }
    });
  };

   const categoryById = (category_id) => {

    Utility.sendRequest(CATEGORIES + '/' + category_id, 1, {}, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setCategoryData(body);
        getAllCampaigns(body.name);
        getAllGroupsByCategory(body.name);
        getCategoryExperts(body.name);
      }
    });
   };

  const getGroupsYouHaveJoined = () => {
    const params = {
      user_client_id: props.userData.id,
    };

    Utility.sendRequest(GETALLJOINEDGROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        setJoinedGroups(body.data);
      }
    });
  };


  const getCategoryExperts = (topicName) => {
    const params = {
      client_id: CLIENT_ID,
      forGroupMembers: true,
      category_name: topicName,
      limit: 3
    };
    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setTopicExperts(body.registrants);
      }
    });
  };

  const handleGroupClick = (selectedGroup) => {
    const group = joinedGroups.filter(item => item.group_id === selectedGroup.group_id);
    if (!Utility.isEmpty(group)) {
      props.updateIsChatFormOpen(true);
      props.updateChatFriend(group[0]);
    }
    else {
      props.updateIsChatFormOpen(true);
      props.updateChatFriend({
        ...selectedGroup,
        fromTopics: true
      });
    }

  };

  const decrypteGroupMessages = (data) => {
    const actualMessage = Utility.decryptAllMessages(
      data.recent_message,
      "",
      "",
      data.group_private_key
    );
    return actualMessage;
  };

  const setDetailsFollowerfollowing = (followingId) => {
    const { id } = userData;
    if(id !== followingId){
      const params = { followerId: id, followingId: followingId, clientId: CLIENT_ID, };

      Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 2, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        }
        else {
          getCategoryExperts();
        }
      });
    }
  };


  return (
    <>
      <HomeContainer
        name={categoryData.name}
        className="event-home"
        goBackLink="/home"
        history={props.history}
        color={categoryData.color_code}
      >
        {
          Utility.pwaModeEnabled() &&
          <div className="topics-image-outer">
            <img className="w-100" src={categoryData.image_url} alt="topics image" />
          </div>
        }
        <div className="dashboard-page-container gil-dashboard">
          <div className="home-page-padding">

          <div className="d-flex article-section-outer flex-wrap topics-outer mb-42" >
              {
                !Utility.isEmpty(topicExperts) ?
                  <>
                    <h4 className="mb-3 w-100 d-flex align-items-center justify-content-between media-heading-outer">{categoryData.name} Experts
                   {Utility.pwaModeEnabled() &&
                        <span className="see-all-text display-7" onClick={() => dispatch(updateSeeAll({ isOpen: 'category_experts', calledFrom: 'category_experts' }))}>
                          See All
                        </span>
                      }
                    </h4>
                    <div className='d-flex article-section-outer flex-lg-wrap slider-mob-inner media-group-pwa'>
                      {
                        topicExperts.map(item => {
                          return(
                            <MyCommunity
                            key={item.id}
                            item={item}
                            handleOnClick={setDetailsFollowerfollowing}
                           // type ={this.props.type}
                            showCharFollowButtons={true}
                          />
                          );
                        })
                      }
                    </div>
                  </>
                  :
                  Utility.isEmpty(topicExperts) &&
                  <div className="px-5 mx-auto">
                    <span className="font-bold display-7 text-muted">
                      No Experts found
            </span>
                  </div>
              }
            </div>

            <div className="d-flex article-section-outer flex-wrap topics-outer mb-42" >
              {
                !Utility.isEmpty(articles) ?
                  <>
                    <h4 className="mb-3 w-100 d-flex align-items-center justify-content-between media-heading-outer">{categoryData.name} Articles
         {Utility.pwaModeEnabled() &&
                        <span className="see-all-text display-7" onClick={() => dispatch(updateSeeAll({ isOpen: 'category_articles', calledFrom: 'category_articles' }))}>
                          See All
            </span>
                      }
                    </h4>
                    <div className='d-flex article-section-outer flex-lg-wrap slider-mob-inner media-group-pwa'>
                      <SuggestedReadsCard
                        stories={articles}
                      />
                    </div>
                  </>
                  :
                  Utility.isEmpty(articles) &&
                  <div className="px-5 mx-auto">
                    <span className="font-bold display-7 text-muted">
                      No Article found
            </span>
                  </div>
              }
            </div>
            <div className="d-flex article-section-outer flex-wrap topics-outer mb-42" >
              {
                !Utility.isEmpty(courses) ?
                  <>
                    <h4 className="mb-3 w-100 d-flex align-items-center justify-content-between media-heading-outer">{categoryData.name} Courses
                {Utility.pwaModeEnabled() &&
                        <span className="see-all-text display-7" onClick={() => dispatch(updateSeeAll({ isOpen: 'category_courses', calledFrom: 'category_courses' }))}>
                          See All
                  </span>
                      }
                    </h4>
                    <div className='d-flex article-section-outer flex-lg-wrap slider-mob-inner media-group-pwa'>
                      <SuggestedReadsCard
                        stories={courses}
                      />
                    </div>
                  </>
                  :
                  Utility.isEmpty(courses) &&
                  <div className="px-5 mx-auto">
                    <span className="font-bold display-7 text-muted">
                      No Courses found
            </span>
                  </div>
              }
            </div>

            <div className="d-flex article-section-outer flex-wrap topics-outer mb-42" >
              {
                !Utility.isEmpty(events) ?
                  <>
                    <h4 className="mb-3 w-100 d-flex align-items-center justify-content-between media-heading-outer">{categoryData.name} Events
               {Utility.pwaModeEnabled() &&
                        <span className="see-all-text display-7" onClick={() => dispatch(updateSeeAll({ isOpen: 'category_events', calledFrom: 'category_events' }))}>
                          See All
                  </span>
                      }
                    </h4>
                    <div className='d-flex article-section-outer flex-lg-wrap slider-mob-inner media-group-pwa'>
                      {events.map(e => {
                        return (
                          <EventCard
                            {...e}
                            key={e.id}
                            pageLoad={() => { }}
                            eventDetailLink={true}
                            goBackLink={() => window.history.back()} />
                        )
                      })
                      }
                    </div>
                  </>
                  :
                  Utility.isEmpty(events) &&
                  <div className="px-5 mx-auto">
                    <span className="font-bold display-7 text-muted">
                      No events found
            </span>
                  </div>
              }
            </div>

            <div className="d-flex article-section-outer flex-wrap topics-outer mb-42">
              {!Utility.isEmpty(groups) &&
                <h4 className="mb-3 w-100 d-flex flex-nowrap align-items-center justify-content-between media-heading-outer">{categoryData.name} Groups
                {Utility.pwaModeEnabled() &&
                    <span className="see-all-text display-7" onClick={() => dispatch(updateSeeAll({ isOpen: 'category_groups', calledFrom: 'category_groups' }))}>
                      See All
                  </span>
                  }
                </h4>
              }
              {!Utility.isEmpty(groups) && groups.map(items => {
                return (
                  <>
                    <div className="pwa-chat-view w-100">
                      <div onClick={() => handleGroupClick(items)} className="pwa-chat-unit align-items-center flex-nowrap position-relative cursor-pointer" >
                      {!Utility.isEmpty(items.image_url) ?
                        <img className="pcu-img rounded-circle" src={items.image_url} alt="" />
                        :
                        <Avatar
                          name={items.name}
                          round={true}
                          size={"60"}
                          className="pcu-img"
                          textSizeRatio={1.75}
                          color="#007bb3"
                          maxInitials={1}
                        />
                      }
                        <div className="pcu-text-outer" style={{ marginLeft: 8 }} >
                          <div className="font-weight-bold text-truncate display-8" >{items.name}</div>
                          <div className="text-truncate font-14 b-500" >{items.recent_message ? decrypteGroupMessages(items) :
                            <>
                              {items.recent_message_time &&
                                <>
                                  <div className="media-image">
                                    <MdPhoto />
                                  </div>
                         Media
                         </>
                              }
                            </>
                          }
                          </div>
                        </div>
                        {items.unread_messages_count &&
                          <div className="align-self-end bg-primary text-white pcu-count flex-center b-500" >{items.unread_messages_count}</div>
                        }
                        <div className="font-12 position-absolute pcu-time" >{items.recent_message_time && Utility.returnDuration(items.recent_message_time)}</div>
                      </div>
                    </div>
                  </>
                );
              })
              }

              {props.isCampaignFormOpen &&
                Utility.pwaModeEnabled() &&
                <CreateCampaign
                  doRefresh={() => props.updateRefreshCampaignAPI(Math.random())}
                  position='position-absolute gil-pwa-callout gil-pwa-campaign-create'
                  loadedFrom='webCampaign'
                  isFormOpen={props.isCampaignFormOpen}
                  setIsFormOpen={(arg) => props.updateCampaignFormOpen(arg)}
                />
              }
              {
                Utility.isEmpty(groups) &&
                <div className="px-5 mx-auto">
                  <span className="font-bold display-7 text-muted">
                    No groups found
            </span>
                </div>
              }
            </div>
          </div>
        </div>

        {Utility.pwaModeEnabled() &&
          props.seeAll.isOpen &&
          <SeeAll
            closeCallout={(value) => {
              props.updateSeeAll({ isOpen: value, calledFrom: value });
            }}
            calledFrom={props.seeAll.isOpen}
            width={'100%'}
            category_name={categoryData.name}
          />
        }
      </HomeContainer>
    </>
  );
}

const mapActionToProps = { showLoader, updateSeeAll, updateIsChatFormOpen, updateChatFriend, updateCampaignFormOpen, updateRefreshCampaignAPI };

const mapStateToProps = (state) => ({
  userData: state.userData,
  isLoading: state.isLoading,
  seeAll: state.seeAll,
  isCampaignFormOpen: state.isCampaignFormOpen,
  refreshCampaignApi: state.refreshCampaignApi,
});

export default connect(mapStateToProps, mapActionToProps)(TopicDetail);
