import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Utility } from 'utils';
import UserLicensesSummary from 'shared/components/user_licenses_summary';

export const ReceiptView = props => (
  <div className="receipt-screen p-3">
    {
      Utility.isEmpty(props.licenseData)
      ? <center><Spinner animation="border" role="status"/></center>
      : <>
          <UserLicensesSummary
            licenseData={props.licenseData}
            userData={props.userData}
            accountList={props.accountList}
            fromFinanceModule={true}
          />;
        </>
    }
  </div>
);
