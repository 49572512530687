import React, { memo } from "react";
import { Table } from "react-bootstrap";
// import { IoIosArrowDown } from 'react-icons/io';
import { Spinner } from "react-bootstrap";
import { NoRecordFound } from "shared/components/no_record_found";
import TableRows from "./table_rows";
import { Utility } from "utils";
import { BsArrowLeftShort } from "react-icons/bs";

let TableList = (props) => {
  const showLoader = props.showLoader;
  return (
    <>
      {props.selectedViewData.active === "NeighborhoodUsers" && <span onClick={()=>props.handleBack()} className="cursor-pointer font-20"><BsArrowLeftShort size={35}/>Back</span>}
      <Table striped responsive size="sm" className="shar-table">
        <TableTitles tableTitles={props.tableTitles} />
        {props.data && !showLoader && (
          <TableRows
            removeUserFromNeighborhood={props.removeUserFromNeighborhood}
            alterAdminStatusInNeighborhood={
              props.alterAdminStatusInNeighborhood
            }
            data={props.data}
            onActionClick={props.openChat}
            onToggleClick={props.onToggleClick}
            setAdminAfterToggle={props.setAdminAfterToggle}
            userData={props.userData}
            selectedMenuItemName={props.selectedMenuItemName}
            editStory={props.editStory}
            editRanking={props.editRanking}
            selectedActionItem={props.selectedActionItem}
            selectedMenuItemId={props.selectedMenuItemId}
            history={props.history}
            allCategories={props.allCategories}
            handleSelectCategory={props.handleSelectCategory}
            remove={props.removeCampaign}
            activateDeactivateUser={props.activateDeactivateUser}
            subCategoriesData={props.subCategoriesData}
            handleSelectSubCategory={props.handleSelectSubCategory}
            selectedViewData={props.selectedViewData}
            handleSetSelectedViewData={props.handleSetSelectedViewData}
          />
        )}
      </Table>
      {Utility.isEmpty(props.data) && !showLoader && <NoRecordFound />}
      {(showLoader) && (
        <span className="justify-content-center">
          <Spinner animation="border" role="status" />
        </span>
      )}
    </>
  );
};

export const TableTitles = (props) => {
  return (
    <>
      <thead>
        <tr>
          {props.tableTitles.map((item, index) => {
            return (
              <th className="border-top-0 border-bottom-0" key={index}>
                {item}{" "}
                {/*<IoIosArrowDown className="table-heading-sort ml-2" />*/}{" "}
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
};

export default TableList = memo(TableList);
