import React, { Component } from 'react';
import { SearchBar } from 'shared/components/templates/menu_list.js';
import { PRODUCTS, GET_PRODUCTS_BY_CATEGORY, GET_PRODUCTS_BY_TYPES } from 'config/constants/api_endpoints';
import { Col, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ProductItem from './product_item';
import * as Utility from 'utils/utility';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Pagination from 'material-ui-flat-pagination';
import FilterProduct from 'scenes/shops/components/filter_products';
import { SEARCH_MINIMUM_LENGTH } from 'config/constants';

const theme = createMuiTheme();

export default class MyProductList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      myProductList: [],
      totalProductsCount: null,
      selectedAttributeFilters: null,
      limit: 10,
      offset: 0,
      filterComponent: false,
      value: { min: 0, max: 0 },
      sliderFilterId: null,
      sliderId: null,
      selectedFilterTags: [],
      showLoader: true,
      toggleStatus: false
    };
  }

  componentDidMount = () => {

    if (!Utility.isEmpty(this.props.selectedMenuItem)) {
      this.getProductList('', this.props.selectedMenuItem);
    }
    else if (!Utility.isEmpty(this.props.allProductTypes)) {
      const firstProductType = this.props.allProductTypes[0];
      this.getProductList('', firstProductType)
    }
    else {
      this.getProductList();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.selectedMenuItem !== this.props.selectedMenuItem) {
      this.getProductList('', this.props.selectedMenuItem);
    }
    if (prevProps.allProductTypes !== this.props.allProductTypes) {

      if (!Utility.isEmpty(this.props.allProductTypes)) {
        const firstProductType = this.props.allProductTypes[0];
        this.getProductList('', firstProductType);
        this.props.handleMenuItemClick(firstProductType);
      }

    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  handleSelectedAttributeFilters = (selectedAttributeFilters) => {
    this.setState({ selectedAttributeFilters: selectedAttributeFilters },
      () => this.getProductList());
  }

  handlePageClick = data => {
    this.setState({
      offset: (data + 1)
    }, () => {
      this.getProductList();
    });
  }

  filterMyProductList = (event) => {
    if (event.target.value.length >= SEARCH_MINIMUM_LENGTH || event.target.value.length === 0) this.getProductList(event.target.value.trim());
  }

  getProductList = (searchText = '', selectedMenu = 1) => {
    if (selectedMenu === 1) {
      // TODO : can be used in future.
      // const params = {
      //   module_type_id: 8,
      //   client_id: this.props.userData.id,
      //   is_hidden: false,
      //   limit: this.state.limit,
      //   offset: this.state.offset,
      //   slider_id: parseInt(this.state.sliderFilterId),
      //   fliterList: this.state.filterComponent
      // };

      // if (!Utility.isEmpty(searchText)) {
      //   params.search_text = searchText;
      // }

      // Utility.sendRequest(PRODUCTS, 1, params, (err, response, body) => {
      //   body = JSON.parse(body);

      //   this.setState({ showLoader: false });

      //   if (!body.error) {
      //     this.setState({ myProductList: body.data, totalProductsCount: body.count });
      //   } else {
      //     toast.error(body.error.message, { containerId: 'private' });
      //   }
      // });
    }
    else if (selectedMenu.id && selectedMenu.menu_name) {
      const params = {
        product_type_id: selectedMenu.id,
      }
      Utility.sendRequest(GET_PRODUCTS_BY_TYPES, 1, params, (err, response, body) => {
        body = JSON.parse(body);

        this.setState({ showLoader: false });

        if (!body.error) {
          this.setState({ myProductList: body.data, totalProductsCount: body.count });
        } else {
          toast.error(body.error.message, { containerId: 'private' });
        }
      });
    }
    else {
      if (!this.props.selectedMenuItem.menu_name) {
        const params = {
          category_id: this.props.selectedMenuItem.id,
        }

        Utility.sendRequest(GET_PRODUCTS_BY_CATEGORY, 1, params, (err, response, body) => {
          body = JSON.parse(body);

          this.setState({ showLoader: false });

          if (!body.error) {
            this.setState({ myProductList: body.data, totalProductsCount: body.count });
          } else {
            toast.error(body.error.message, { containerId: 'private' });
          }
        });
      }
      else {
        const params = {
          product_type_id: this.props.selectedMenuItem.id,
        }
        Utility.sendRequest(GET_PRODUCTS_BY_TYPES, 1, params, (err, response, body) => {
          body = JSON.parse(body);

          this.setState({ showLoader: false });

          if (!body.error) {
            this.setState({ myProductList: body.data, totalProductsCount: body.count });
          } else {
            toast.error(body.error.message, { containerId: 'private' });
          }
        });
      }
    }
  }

  deleteProductItem = (productItem, index) => {
    Utility.sendRequest(PRODUCTS + '/' + productItem, 4, {}, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        let list = []
        list = this.state.myProductList.filter(x => x.id !== productItem)
        this.setState({ myProductList: list });
        toast.success('Record deleted successfully', { containerId: 'private' });
        this.props.refreshCategories();
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  handleListItemAction = (id, listId) => {
    if (listId === 2) {
      const ans = window.confirm("Delete this item?")
      if (ans === true)
        this.deleteProductItem(id);
    }
    else {
      this.props.editProductItem(id)
    }
  }

  showFilterComponent = () => {
    this.setState({ filterComponent: true });
  }

  closeFilterOptions = () => {
    this.setState({ filterComponent: false });
  }

  updateSelectedTagsFilter = (selectedFilterTags) => {
    this.setState({ selectedFilterTags }, () => {
      this.getProductList();
    });
  }

  updateValueState = (value) => {
    this.setState({ value });
  }

  updateSelectedFilterId = (id, web_field_type) => {
    if (web_field_type === 'range') {
      this.setState({ sliderFilterId: id });
    }
  }

  updateToggleState = (status) => {
    this.setState({ toggleStatus: status });
  }

  render() {
    return (
      <>
        <Col xl={9} lg={8} id="my-products" className="ModalOpen scroll-y h-100 top-on-sm d-none d-lg-block px-4 bg-white py-4 py-md-0" >
          <div className="align-items-center justify-content-between network-view mb-3 mb-sm-0 product-head" >
            <div className="d-flex align-items-center">
              <div className="round-circle mr-2"></div>
              <span className="font-20-bold">Products</span>
            </div>
            <div className="d-flex align-items-center product-searchbar surch-filter">
              <SearchBar placeholder="Search" handleKeyUp={this.filterMyProductList} />
              <img
                src={require('assets/images/profile/new-profile/setting-icon.svg')}
                alt="drodown-icon"
                className="cursor-pointer"
                onClick={this.showFilterComponent}
                style={{ display: "none" }}
              />
            </div>
          </div>

          <div className="product-table position-relative">
            <Table className="table-hover" >
              <thead>
                <tr className="border-top-0" >
                  <th colSpan="2" className="product-img-head pl-0" >Product Name</th>
                  <th>Category</th>
                  <th className="actions-container">Actions</th>
                </tr>
              </thead>

              {!Utility.isEmpty(this.state.myProductList) ?
                <tbody className="display-7">
                  {
                    this.state.myProductList.map((item, index) => {
                      return (
                        <ProductItem
                          item={item}
                          index={index}
                          key={"product" + index}
                          handleListItemAction={this.handleListItemAction}
                          editProductItem={this.props.editProductItem}
                        />
                      );
                    })
                  }
                </tbody>
                :
                <div className="text-center mt-3 font-20 position-absolute w-100">No Products Available..</div>
              }

            </Table>

            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              {
                !Utility.isEmpty(this.state.myProductList) &&
                <Pagination
                  limit={this.state.limit}
                  offset={this.state.offset}
                  total={this.state.totalProductsCount}
                  onClick={(e, offset) => this.handlePageClick(offset)}
                  otherPageColor='default'
                  style={{ textAlign: 'center' }}
                />
              }
            </MuiThemeProvider>
          </div>
        </Col>
        <div>
          {
            this.state.filterComponent &&
            <FilterProduct className='filter-side-bar'
              userData={this.props.userData}
              close={this.closeFilterOptions}
              getProductList={this.getProductList}
              updateSeletedFilter={this.updateSeletedFilter}
              updateValueState={this.updateValueState}
              updateSelectedFilterId={this.updateSelectedFilterId}
              updateSelectedTagsFilter={this.updateSelectedTagsFilter}
              handleSelectedAttributeFilters={this.handleSelectedAttributeFilters}
              filterComponent={this.state.filterComponent}
              updateSelectedFilters={this.props.updateSelectedFilters}
              selectedFilters={this.props.selectedFilters}
              selectedFilterTags={this.state.selectedFilterTags}
              toggleStatus={this.state.toggleStatus}
              updateToggleState={this.updateToggleState}
            />
          }
        </div>
      </>
    );
  }
}
