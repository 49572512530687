import React from 'react';
import { Form } from 'react-bootstrap';
import { Utility } from 'utils';
import { InputField } from "shared/components/form_elements";
import PasswordValidationFrame from 'shared/components/password_validation_frame';
import { Image } from 'shared/components/pwa';
import { CLIENT_NAME } from 'config/constants/config';

export default class UpdatePassword extends React.Component{
  render(){
    return(
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column" >
        <div className="d-flex" >
          <Image />
        </div>
        <h1 className="font-28-bold text-center my-5 " >Jump back into the <br /> {CLIENT_NAME} Community!</h1>
        <h1 className="font-20-bold text-center mb-5" >Time to update <br /> your password!</h1>
        <Form className="pwa-form" >
          <Form.Group controlId="formGroupPassword1">
            <InputField required={true}
              type="password"
              placeholder="Enter password"
              name="password"
              ref="password"
              handleChange={(e) => this.props.handleChangePassword(e, 'password')}
              />
              {
                this.props.showPasswordValidation &&
                <PasswordValidationFrame password={ Utility.validatePassword(this.props.password)}/>
              }
          </Form.Group>
          <Form.Group controlId="formGroupPassword2">
            <InputField required={true}
              type="password" placeholder="Confirm password" name="confirm_password"
              handleChange={(e) => this.props.handleChangePassword(e, 'confirm_password')}
              />
          </Form.Group>
        </Form>
        <div className="text-center mt-auto">
              <button className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"  onClick={(e) => this.props.updatePassword(e)}>UPDATE</button>
              <button className="btn btn-link btn-cancel lg-btn w-100"></button>
          </div>
      </div>
    );
  }
  }
