import React, { useState, useRef } from 'react';
import { Modal, Button, Col, Spinner } from 'react-bootstrap';
import { MdExpandMore, MdImage } from "react-icons/md";
import { makeStyles } from '@material-ui/core/styles';
import { MEDIA_SIGNED_URL, USERS } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import { connect } from 'react-redux';
import { updateUserData } from 'shared/actions';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import AvatarEditor from 'react-avatar-editor';
import cookie from 'react-cookies';

const recentPhotos = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: { transform: 'rotate(180deg)' }
}));

const yourPhotos = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: { transform: 'rotate(180deg)' }
}));

const ImageUpload = (props) => {
  const [cropStep, setCropStep] = useState(false);
  const [originalImage, setOriginalImage] = useState(false);
  const [spinner, showSpinner] = useState(false);

  const handleFileChange = (imagePreview) => {
    setOriginalImage(imagePreview);
    setCropStep(true);
  };

  const uploadImage = (croppedImage) => {
    showSpinner(true);
    const blob = Utility.splitImageDataAndConvertToBlob(croppedImage);
    generateSignedUrlAndUpload(blob);
  };

  const generateSignedUrlAndUpload = (file) => {
    Utility.sendRequest(MEDIA_SIGNED_URL, 1, {}, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      const url = body.imageSignedUrl;
      const imageURL = await Utility.uploadFile(file, url);

      Utility.sendRequest(USERS, 3, { profile_image_url: imageURL }, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          toast.success('Profile picture updated successfully', { containerId: 'private' });
          props.history.push('/home');
          // const userData = { ...props.userData };
          // userData.Client.image_url = imageURL;
          // props.updateUserData(userData);
          // props.closeImageUploader();
        }
      });
    });
  };

  return (
    <>
      <Modal show={true} onHide={props.closeImageUploader} size="lg" className="shar-modal" centered>
        {
          !cropStep
          ? <StepOne setCropStep={setCropStep} handleFileChange={handleFileChange}/>
          : <StepTwo setCropStep={setCropStep} imagePreview={originalImage} uploadImage={uploadImage} spinner={spinner}/>
        }
      </Modal>
    </>
  );
};

const StepOne = (props) => {
  const [message, setMessage] = useState(false);

  const handleFileChange = (event) => {
    setMessage('');
    const reader = new FileReader();
    const originalFile = event.target.files[0];

    if (!originalFile) return;

    if (['image/png', 'image/gif', 'image/jpeg'].includes(originalFile.type)) {
      const file = new File([originalFile], 'profile_image');
      reader.onloadend = () => { props.handleFileChange(reader.result);};
      reader.readAsDataURL(file);
    } else {
      setMessage('Select a valid image');
    }
  };

  return (
    <>
      <Modal.Header className="justify-content-center border-bottom-0" closeButton>
        <Modal.Title className="display-8 font-bold">Update Profile Picture</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-lg-5 px-3">
        <div className="upload-or-drag">
          <form className="pb-4 border-bottom">
            <label htmlFor="file" className="shar-img-upload flex-center position-relative">
              <input type="file" id="file" accept="image/png,image/gif,image/jpeg" className="upload-drag-file cursor-pointer" multiple onChange={(e) => handleFileChange(e)}/>
              <span className="upload-text align-items-center flex-column">
                <span className="upload-plus-icon rounded-circle d-inline-block position-relative" ></span>
                <span className="display-9 mt-3">Upload or Drop Photo Here</span>
              </span>
            </label>
            <div className="error errorIcon">{message}</div>
          </form>
        </div>
        {
          1 === 2 &&
          <>
            <RecentPhotos useImage={props.setCropStep}/>
            <YourPhotos useImage={props.setCropStep}/>
          </>
        }
      </Modal.Body>
    </>
  );
};

const StepTwo = (props) => {
  const [scale, setScale] = useState(1);
  const cropper = useRef(null);

  const getCroppedImage = () => {
    const image = cropper.current.getImage().toDataURL();
    props.uploadImage(image);
  };

  return (
    <>
      <Modal.Header className="justify-content-center border-bottom-0" closeButton={!props.spinner}>
        <Modal.Title className="display-8 font-bold">Edit Thumbnail</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-5 mt-3">
        <div className="edit-img text-center">
          <AvatarEditor
            image={props.imagePreview}
            width={190}
            height={190}
            border={0}
            borderRadius={100}
            scale={scale}
            ref={cropper}
          />
        </div>
        <div className="text-center display-10 my-4">Resize & Crop</div>
        <Col md={6} className="edit-img-resizer mx-auto">
          <div className="resizer">
            <input type="range" min="1" max="5" defaultValue="1" className="w-100" onChange={(e) => setScale(parseInt(e.target.value))}/>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className="display-10"> <MdImage/></span>
            <span className="display-8 line-height-1"> <MdImage/></span>
          </div>
        </Col>
        <div className="text-right pt-5 mt-5">
          <Button variant="link" disabled={props.spinner} className={`mr-3 font-light border-0 ${props.spinner ? 'btn-disabled' : ''}`} onClick={() => props.setCropStep(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => getCroppedImage()}>
            {
              props.spinner
              ? <Spinner animation="border" role="status"/>
              : 'Submit'
            }
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

const RecentPhotos = (props) => {
  const [show, setShow] = useState(false);
  const classes = recentPhotos();

  return (
    <div className="recent-images pb-3 border-bottom">
      <div className="dispaly-9 my-3 ml-2">Recent Profile Photos</div>
      <div className="justify-content-center">
        {
          [...Array(6).keys()].map((item, index) => {
            return (
              <div key={`profile${index}`} className="photo-thumb mx-2 cursor-pointer" onClick={() => props.useImage(true)}>
                <img src={require("assets/images/digital-graphic.png")} alt="profile pic" />
              </div>
            );
          })
        }
      </div>
      <div className="display-10 more-btn text-right py-3 cursor-pointer" onClick={() => setShow(!show)} aria-expanded={show} aria-label="Show more">
        See More
        <IconButton className={clsx(classes.expand, { [classes.expandOpen]: show })}>
          <MdExpandMore />
        </IconButton>
      </div>
      <Collapse in={show} timeout="auto" unmountOnExit>
        <div className="justify-content-center">
          {
            [...Array(6).keys()].map((item, index) => {
              return (
                <div key={`profile${index}`} className="photo-thumb mx-2 cursor-pointer" onClick={() => props.useImage(true)}>
                  <img src={require("assets/images/digital-graphic.png")} alt="profile pic" />
                </div>
              );
            })
          }
        </div>
      </Collapse>
    </div>
  );
};

const YourPhotos = (props) => {
  const [show, setShow] = useState(false);
  const classes = yourPhotos();

  return (
    <div className="your-images pb-3 border-bottom">
      <div className="dispaly-9 my-3 ml-2">Your Photos</div>
      <div className="justify-content-center">
        {
          [...Array(6).keys()].map((item, index) => {
            return (
              <div key={`profile${index}`} className="photo-thumb mx-2 cursor-pointer" onClick={() => props.useImage(true)}>
                <img src={require("assets/images/digital-graphic.png")} alt="profile pic" />
              </div>
            );
          })
        }
      </div>
      <div className="display-10 more-btn text-right py-3 cursor-pointer" onClick={() => setShow(!show)} aria-expanded={show} aria-label="Show more Photos">
        See More
        <IconButton className={clsx(classes.expand, { [classes.expandOpen]: show })}>
          <MdExpandMore />
        </IconButton>
      </div>
      <Collapse in={show} timeout="auto" unmountOnExit>
        <div className="justify-content-center">
          {
            [...Array(6).keys()].map((item, index) => {
              return (
                <div key={`profile${index}`} className="photo-thumb mx-2 cursor-pointer" onClick={() => props.useImage(true)}>
                  <img src={require("assets/images/digital-graphic.png")} alt="profile pic" />
                </div>
              );
            })
          }
        </div>
      </Collapse>
    </div>
  );
};

const mapActionToProps = { updateUserData };
const mapStateToProps = (state) => ({
  userData: state.userData
});

export default connect(mapStateToProps, mapActionToProps)(ImageUpload);
