import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { DropDownList } from 'shared/components/drop_down_list';
import { Utility } from 'utils';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { CHATS } from 'config/constants/api_endpoints';
import { SEARCH_MINIMUM_LENGTH } from  'config/constants';
import { MenuTitle, SearchBar, ListItem } from 'shared/components/templates/menu_list';
import { NoRecordFound } from 'shared/components/no_record_found';
import MenuList from 'shared/components/templates/menu_list';

const FILTER_OPTIONS = [{ id: 0, name: 'All Chats' },
                        { id: 1, name: 'Network Chats' },
                        { id: 2, name: 'Audience Chats' }];

class RecentChats extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      chats: [],
      showLoader: true,
      searchText: '',
      selectedFilterId: 0
    };

    this.recentChats();
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.props.onRef(this);
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.props.onRef(undefined);
  }

  updateUnreadCount = (clientId) => {
    const chats = this.state.chats;
    const ind = chats.findIndex((item) => item.id === clientId);

    if (ind !== -1) {
      chats[ind].unread_count = chats[ind].unread_count ? parseInt(chats[ind].unread_count) + 1 : 1;

      this.setState({ chats });
    }
  }

  handleKeyUp = (e) => {
    if (e.target.value.length < SEARCH_MINIMUM_LENGTH && e.target.value.length !== 0) {

      if(this.state.searchText.length >= SEARCH_MINIMUM_LENGTH) this.setState({ searchText: '', showLoader: true });

      return;
    }

    this.setState({ showLoader: true, chats: [], searchText: e.target.value });
    this.recentChats(e.target.value, this.state.selectedFilterId);
  }

  recentChats = (searchText = '', selectedFilterId = 0) => {
    const params = {
      from_user_client_id: this.props.userData.id,
      search_text: searchText.trim(),
      filter: selectedFilterId
    };

    Utility.sendRequest(CHATS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      let data = [];

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        data = body.data;
      }

      if (this._isMounted) this.setState({ chats: data, showLoader: false });
    });
  }

  handleListItemClick = (clientId) => {
    const ind = this.state.chats.findIndex(item => item.id === clientId);
    this.props.startConversation(this.state.chats[ind]);
  }

  handleDropdownAction = (selectedFilterId) => {
    this.setState({ selectedFilterId, chats: [], showLoader: true });
    this.recentChats(this.state.searchText, selectedFilterId);
  }

  render() {
    return (
      <>
        <MenuList
          id="attach-friend"
          className="h-100 ModalOpen direct-message-container d-none d-lg-block col-xl-9 col-lg-8 col-12">
          <div className="network-box-shadow h-100 scroll-y">
            <div className="network-item-header">
              <div className="round-circle mr-2">
              </div>
              <MenuTitle menuName={this.props.selectedMenuItem.menu_name}/>
            </div>
            <SearchBar placeholder="Search" handleKeyUp={this.handleKeyUp}/>

            <div className="select-add-network">
              <DropDownList
                dropDownClassName="custom-dropdown"
                toggleButtonClassName="border-transparent"
                menuClassName="lists-position"
                handleDropdownAction={this.handleDropdownAction}
                dropdownItems={FILTER_OPTIONS}
              />
              {/* <div className="add-network"><span className="display-6"><TiFolderAdd/></span></div> */}
            </div>

            <div className="members-list">
              <ul className="pb-3">
                { !Utility.isEmpty(this.state.chats)
                  ? this.state.chats.map((item, index) => {
                      const detail = {
                        image_url: item.image_url,
                        id: item.id,
                        name: item.name,
                        count: item.unread_count,
                        updated_at: item.updated_at
                      };
                      return (
                        <ListItem
                          key={index + "list"}
                          item={detail}
                          selectListItem={this.handleListItemClick}
                        />
                      );
                    })
                  : this.state.showLoader ? <center><Spinner animation="border" role="status"/></center> : <NoRecordFound/>
                }
              </ul>
            </div>
          </div>
        </MenuList>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData
});
export default connect(mapStateToProps, {})(RecentChats);
