import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineFileDone } from "react-icons/ai";
import { FaAward, FaBookReader, FaShare } from "react-icons/fa";
import { MdPeople, MdForum } from "react-icons/md";

class CourseDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleCourseDetail.bind();
  }
  handleCourseDetail = (selectedItem) => {
    this.props.handleCourseDetailItem(selectedItem);
  };

  componentDidMount = () => {
    this.handleSelected();
  };
  LessonIcon = () => {
    return (
      <svg width="90" height="90" viewBox="0 0 17 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 9.08C15.67 9.03 15.34 9 15 9C12.3922 8.99813 9.99993 10.447 8.79352 12.759C7.58711 15.0709 7.76692 17.862 9.26 20H2C0.9 20 0 19.1 0 18V2C0 0.9 0.9 0 2 0H14C15.1 0 16 0.9 16 2V9.08ZM4.25377 8.5581C4.41151 8.64734 4.6052 8.64425 4.76 8.55L6.5 7.5L8.24 8.55C8.3948 8.64425 8.58849 8.64734 8.74623 8.5581C8.90396 8.46885 9.00106 8.30123 9 8.12V2H4V8.12C3.99894 8.30123 4.09604 8.46885 4.25377 8.5581Z"
          fill={
            this.props.selectedItem === "" ||
            this.props.selectedItem === "Lessons"
              ? "#ffffff"
              : "#000000"
          }
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 16C10 13.24 12.24 11 15 11C17.76 11 20 13.24 20 16C20 18.76 17.76 21 15 21C12.24 21 10 18.76 10 16ZM14.51 13.98C14.18 13.77 13.75 14.01 13.75 14.4V17.6C13.75 17.99 14.18 18.23 14.51 18.03L17.07 16.43C17.38 16.23 17.38 15.78 17.07 15.58L14.51 13.98Z"
          fill={
            this.props.selectedItem === "" ||
            this.props.selectedItem === "Lessons"
              ? "#ffffff"
              : "#000000"
          }
        />
      </svg>
    );
  };

  handleSelected = () => {
    if (this.props.selectedItem !== "") {
      var SidebarFlex = document.getElementById("courseSidebarFlex");
      var sidebarItems =
        SidebarFlex.getElementsByClassName("courseSidebarCard");
      for (let i = 0; i < sidebarItems.length; i++)
        sidebarItems[i].classList.remove("courseSidebarToggle");
      for (let i = 0; i < sidebarItems.length; i++) {
        if (sidebarItems[i].id !== this.props.selectedItem)
          sidebarItems[i].classList.add("courseSidebarToggle");
      }
    }
  };

  handleClick = (menuItem) => {
    // this.setState({ selectedMenu: menuItem });
    this.handleCourseDetail(menuItem);
    var SidebarFlex = document.getElementById("courseSidebarFlex");
    var sidebarItems = SidebarFlex.getElementsByClassName("courseSidebarCard");
    for (let i = 0; i < sidebarItems.length; i++)
      sidebarItems[i].classList.remove("courseSidebarToggle");

    for (let i = 0; i < sidebarItems.length; i++) {
      if (sidebarItems[i].id !== menuItem)
        sidebarItems[i].classList.add("courseSidebarToggle");
    }
    // if(this.props.selectedItem !== "")
    // document.getElementById(this.props.selectedItem).classList.add('courseSidebarToggle');
  };

  render() {
    return (
      <div
        className="d-flex overflow-auto courseSidebarFlex"
        id="courseSidebarFlex"
      >
        <Link>
          <div
            className="d-flex flex-column bd-highlight pb-2 align-items-center courseSidebarCard "
            onClick={() => this.handleClick("About")}
            id="About"
          >
            <div className="p-2 pt-4 bd-highlight">
              {<FaBookReader className="courseSidebarCardIcon" />}
            </div>
            <div className="p-2 pb-0 bd-highlight">
              <h5>{"About"}</h5>
            </div>
            <div className="p-2 m-1 bd-highlight text-center">
              {"Learn about what this course has to offer."}
            </div>
          </div>
        </Link>
        <Link>
          <div
            className="d-flex flex-column bd-highlight pb-2 align-items-center courseSidebarCard "
            onClick={(e) => {this.handleClick("Lessons")}}
            id="Lessons"
          >
            <div className="p-2 pt-4 bd-highlight">{this.LessonIcon()}</div>
            <div className="p-2 pb-0 bd-highlight">
              <h5>{"Lessons"}</h5>
            </div>
            <div className="p-2 m-1 bd-highlight text-center">
              {`Check out the ${this.props.totalLesson - this.props.totalCompletedLesson} lessons to complete in the course.`}
            </div>
          </div>
        </Link>
        <Link>
          <div
            className="d-flex flex-column bd-highlight pb-2 align-items-center courseSidebarCard "
            onClick={() => this.handleClick("Assignments")}
            id="Assignments"
          >
            <div className="p-2 pt-4 bd-highlight">
              {<AiOutlineFileDone className="courseSidebarCardIcon" />}
            </div>
            <div className="p-2 pb-0 bd-highlight">
              <h5>{"Assignments"}</h5>
            </div>
            <div className="p-2 m-1 bd-highlight text-center">
              {`Complete ${this.props.totalAssign - this.props.totalCompletedAssign} tasks designed to help you digest the course.`}
            </div>
          </div>
        </Link>
        <Link>
          <div
            className="d-flex flex-column bd-highlight pb-2 align-items-center courseSidebarCard "
            onClick={() => this.handleClick("Discussions")}
            id="Discussions"
          >
            <div className="p-2 pt-4 bd-highlight">
              {<MdForum className="courseSidebarCardIcon" />}
            </div>
            <div className="p-2 pb-0 bd-highlight">
              <h5>{"Discussions"}</h5>
            </div>
            <div className="p-2 m-1 bd-highlight text-center">
              {`Learn with ${this.props.allMembers ? this.props.allMembers.length : 0} of your peers who are already in progress.`}
            </div>
          </div>
        </Link>
        <Link>
          <div
            className="d-flex flex-column bd-highlight pb-2 align-items-center courseSidebarCard "
            onClick={() => this.handleClick("Peers")}
            id="Peers"
          >
            <div className="p-2 pt-4 bd-highlight">
              {<MdPeople className="courseSidebarCardIcon" />}
            </div>
            <div className="p-2 pb-0 bd-highlight">
              <h5>{"Peers"}</h5>
            </div>
            <div className="p-2 m-1 bd-highlight text-center">
              {"View people from around the world attending the event."}
            </div>
          </div>
        </Link>
        <Link>
          <div
            className="d-flex flex-column bd-highlight pb-2 align-items-center courseSidebarCard "
            onClick={() => this.handleClick("Certificate")}
            id="Certificate"
          >
            <div className="p-2 pt-4 bd-highlight">
              {<FaAward className="courseSidebarCardIcon" />}
            </div>
            <div className="p-2 pb-0 bd-highlight">
              <h5>{"Certificate"}</h5>
            </div>
            <div className="p-2 m-1 bd-highlight text-center">
              {"Earn your certificate when you complete the course."}
            </div>
          </div>
        </Link>
        <Link>
          <div
            className="d-flex flex-column bd-highlight pb-2 align-items-center courseSidebarCard "
            onClick={() => this.handleClick("Share")}
            id="Share"
          >
            <div className="p-2 pt-4 bd-highlight">
              {<FaShare className="courseSidebarCardIcon" />}
            </div>
            <div className="p-2 pb-0 bd-highlight">
              <h5>{"Share"}</h5>
            </div>
            <div className="p-2 m-1 bd-highlight text-center">
              {"Invite your friends to participate in your learning journey."}
            </div>
          </div>
        </Link>
        {/* <Link
          className="sidebar-flex-items"
          onClick={() => this.handleClick("About")}
          id="About"
        >
          <CourseSidebarCard
            icon={<FaBookReader className="courseSidebarCardIcon" />}
            description={"Learn about what this course has to offer"}
            heading={"About"}
          />
        </Link>
        <Link
          className="sidebar-flex-items"
          onClick={() => this.handleClick("Lessons")}
          id="Lessons"
        >
          <CourseSidebarCard
            icon={this.LessonIcon()}
            description={"Check out the 25 lessons to complete in the course"}
            heading={"Lessons"}
          />
        </Link>
        <Link
          className="sidebar-flex-items"
          onClick={() => this.handleClick("Assignments")}
          id="Assignments"
        >
          <CourseSidebarCard
            icon={<AiOutlineFileDone className="courseSidebarCardIcon" />}
            description={
              "Complete 10 tasks designed to help you digest the course"
            }
            heading={"Assignments"}
          />
        </Link>
        <Link
          className="sidebar-flex-items"
          onClick={() => this.handleClick("Discussions")}
          id="Discussions"
        >
          <CourseSidebarCard
            icon={<MdForum className="courseSidebarCardIcon" />}
            description={
              "Learn with 250 of your peers who are already in progress"
            }
            heading={"Discussions"}
          />
        </Link>
        <Link
          className="sidebar-flex-items"
          onClick={() => this.handleClick("Peers")}
          id="Peers"
        >
          <CourseSidebarCard
            icon={<MdPeople className="courseSidebarCardIcon" />}
            description={"Learn about what this course has to offer"}
            heading={"Peers"}
          />
        </Link>
        <Link
          className="sidebar-flex-items"
          onClick={() => this.handleClick("Certificate")}
          id="Certificate"
        >
          <CourseSidebarCard
            icon={<FaAward className="courseSidebarCardIcon" />}
            description={"Earn your certificate when you complete the course"}
            heading={"Certificate"}
          />
        </Link>
        <Link
          className="sidebar-flex-items"
          onClick={() => this.handleClick("Share")}
          id="Share"
        >
          <CourseSidebarCard
            icon={<FaShare className="courseSidebarCardIcon" />}
            description={"Learn about what this course has to offer"}
            heading={"Share"}
          />
        </Link> */}
      </div>
    );
  }
}

export default CourseDetailsPage;
