import React, { useState, useEffect } from "react";
import { SuggestedReadsCard } from "shared/components/suggested_reads";
import { Form } from "react-bootstrap";
import { InputField } from "shared/components/form_elements";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as Utility from "utils/utility";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import {
  CAMPAIGNS_TOP_STORIES,
  ALL_PARENT_CAMPAIGNS,
  GROUP_CAMPAIGNS,
  COURSES_BY_CATEGORY_ID,
  CAMPAIGNS_BY_CATEGORY_NAME,
  GROUPS_BY_CATEGORY_NAME,
  GETALLJOINEDGROUPS,
  GET_USERS_BY_NETWORKS,
  GET_FOLLOWERS_FOLLOWING,
  CATEGORIES_BY_MODULE_ID,
} from "config/constants/api_endpoints";
import { CAMPAIGN_TYPES_IDS, GIL_CLIENT_ID, ROLE_IDS } from "config/constants";
import GroupCard from "shared/components/cards/group_card";
import Avatar from "react-avatar";
import MyCommunity from "shared/components/my_community";
import { ChevronLeftIcon } from "shared/icons/icons";
import {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";
import { connect } from "react-redux";
import { MdPhoto } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import CourseContainer from "./CourseContainer";
import CourseContainerCard from "./CourseContainer";

function Articles(props) {
  const userData = useSelector((state) => state.userData);
  const refreshCampaignApi = useSelector((state) => state.refreshCampaignApi);
  const userId = props.userId ? props.userId : userData.id;
  const [campaignsTopStories, setCampaignsTopStories] = useState([]);
  const [search, setSearch] = useState("");
  const [isApiResponse, setIsApiResponse] = useState(false);
  const [heading, setHeading] = useState(props.heading);
  const [joinedGroups, setAllJoinedGroups] = useState([]);
  const [topicExperts, setTopicExperts] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  let debouncedFn = null;

  useEffect(() => {
    fetchData();
  }, [search, refreshCampaignApi]);
  useEffect(() => {
    getCategoriesByModuleId();
  }, []);

  const fetchData = () => {
    if (props.type === "suggestedReads") {
      getCampaignsTopStoriesDetails();
    } else if (props.type === "myArticles") {
      getMyArticles();
    } else if (props.type === "groupArticles") {
      getGroupCampaigns();
    } else if (props.type === "courses") {
      if (!Utility.isEmpty(props.category_id)) getCourses();
      else getAllCourses();
    } else if (props.type === "category_articles") {
      getAllCampaigns(props.category_name, "articles");
      setHeading("Articles");
    } else if (props.type === "category_events") {
      getAllCampaigns(props.category_name, "events");
      setHeading("Events");
    } else if (props.type === "category_courses") {
      getAllCampaigns(props.category_name, "courses");
      setHeading("Courses");
    } else if (props.type === "category_groups") {
      getAllGroupsByCategory(props.category_name, "groups");
      setHeading("Groups");
    } else if (props.type === "courses") {
      if (!Utility.isEmpty(props.category_id)) getCourses();
      else getAllCourses();
    } else if (props.type === "category_experts") {
      getCategoryExperts(props.category_name);
      setHeading("Experts");
    }
  };

  const getData = (params, url) => {
    params.search = search;
    params.user_client_id = props.userData.id;

    props.showLoader(true);
    Utility.sendRequest(url, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      setIsApiResponse(true);
      props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (url === ALL_PARENT_CAMPAIGNS) setCampaignsTopStories(body.data);
        else if (url === COURSES_BY_CATEGORY_ID) setCampaignsTopStories(body);
        else setCampaignsTopStories(body);
      }
    });
  };

  const getCampaignsTopStoriesDetails = () => {
    const params = {
      publisher_network_id: CLIENT_ID,
    };

    if (!Utility.isEmpty(userData)) {
      getData(params, CAMPAIGNS_TOP_STORIES);
    }
  };

  const getAllCampaigns = (topicName, type) => {
    const params = {
      client_id: CLIENT_ID,
      category_name: topicName,
      all: true,
    };
    setCampaignsTopStories([]);

    if (search) params.search = search;

    props.showLoader(true);
    Utility.sendRequest(
      CAMPAIGNS_BY_CATEGORY_NAME,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        props.showLoader(false);
        setIsApiResponse(true);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (type === "articles") {
            setCampaignsTopStories(body.articles);
          } else if (type === "events") {
            setCampaignsTopStories(body.events);
          } else if (type === "courses") {
            setCampaignsTopStories(body.courses);
          }
        }
      }
    );
  };

  const setDetailsFollowerfollowing = (followingId) => {
    const { id } = userData;
    if (id !== followingId) {
      const params = {
        followerId: id,
        followingId: followingId,
        clientId: CLIENT_ID,
      };

      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        2,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            getCategoryExperts(props.category_name);
          }
        }
      );
    }
  };

  const getCategoryExperts = (topicName = props.category_name) => {
    const params = {
      client_id: CLIENT_ID,
      category_name: topicName,
      forGroupMembers: true,
    };
    if (search) params.search = search;

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setTopicExperts(body.registrants);
      }
    });
  };

  const getAllGroupsByCategory = (topicName) => {
    const params = {
      client_id: CLIENT_ID,
      category_name: topicName,
      all: true,
    };

    if (search) params.search = search;

    props.showLoader(true);
    Utility.sendRequest(
      GROUPS_BY_CATEGORY_NAME,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        props.showLoader(false);
        setIsApiResponse(true);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setCampaignsTopStories(body);
          getGroupsYouHaveJoined();
        }
      }
    );
  };

  const getGroupsYouHaveJoined = () => {
    const params = {
      user_client_id: userData.id,
    };

    Utility.sendRequest(GETALLJOINEDGROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        setAllJoinedGroups(body.data);
      }
    });
  };

  const handleGroupClick = (selectedGroup) => {
    const group = joinedGroups.filter(
      (item) => item.group_id === selectedGroup.group_id
    );
    if (!Utility.isEmpty(group)) {
      props.updateIsChatFormOpen(true);
      props.updateChatFriend(group[0]);
    } else {
      props.updateIsChatFormOpen(true);
      props.updateChatFriend({
        ...selectedGroup,
        fromTopics: true,
      });
    }
    props.updateSeeAll({
      ...props.seeAll,
      isOpen: null,
    });
  };

  const getCourses = () => {
    const params = {
      client_id: CLIENT_ID,
      category_id: props.category_id,
    };
    getData(params, COURSES_BY_CATEGORY_ID);
  };

  const getMyArticles = () => {
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userId,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
      all: true,
    };

    getData(params, ALL_PARENT_CAMPAIGNS);
  };

  const getGroupCampaigns = () => {
    const params = {
      groupId: props.groupId,
      clientId: CLIENT_ID,
    };
    getData(params, GROUP_CAMPAIGNS);
  };

  const getAllCourses = () => {
    const params = {
      publisher_network_id: CLIENT_ID,
      is_course: true,
    };
    params.search = search;
    props.showLoader(true);
    setIsApiResponse(true);
    Utility.sendRequest(CAMPAIGNS_TOP_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setCampaignsTopStories(body);
      }
    });
  };

  const getCategoriesByModuleId = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      campaign_type_id: props.type === "suggestedReads" ?CAMPAIGN_TYPES_IDS.story : CAMPAIGN_TYPES_IDS.courses,
    };

    Utility.sendRequest(
      CATEGORIES_BY_MODULE_ID,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setCategoriesList(body.data);
        }
      }
    );
  };

  const goBackLink = () => {
    //props.updateSeeAll({ isOpen: props.type, calledFrom: props.type });
    return `/topics/${props.category_name}`;
  };

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const decrypteGroupMessages = (data) => {
    const actualMessage = Utility.decryptAllMessages(
      data.recent_message,
      "",
      "",
      data.group_private_key
    );
    return actualMessage;
  };

  return (
    <div className="pwa-callout-body">
      {CLIENT_ID !== GIL_CLIENT_ID && props.type === "courses" ? (
        <>
          <div className="home-page-padding">
            <h1 className="font-24-bold mb-4">
              {CLIENT_ID === GIL_CLIENT_ID &&
              userData.role_id === ROLE_IDS.USER &&
              props.type === "courses" ? (
                ""
              ) : (
                <ChevronLeftIcon
                  handleClick={() => props.closeCallout(null)}
                  mainClass="cursor-pointer see-all-back"
                />
              )}
              {heading}
            </h1>
            <Form className="shar-form search-form search-form-pwa sidebar-form">
              <Form.Group className="mb-0">
                <InputField
                  required={true}
                  type="text"
                  placeholder={`Search ${heading}`}
                  handleChange={(ev) => handleSearch(ev)}
                />
              </Form.Group>
            </Form>
            {Utility.isEmpty(campaignsTopStories) && isApiResponse && (
              <div className="d-flex align-items-center justify-content-center mt-5">
                <div className="px-5">
                  <span className="font-bold display-6">No record found</span>
                </div>
              </div>
            )}
          </div>
          <div className="dashboard-page-container gil-dashboard">
            <div className="home-page-padding">
              {props.type !== "category_groups" &&
                props.type !== "category_experts" && (
                  <div className="d-flex article-section-outer flex-lg-wrap slider-mob-inner mb-42">
                    <SuggestedReadsCard
                      stories={campaignsTopStories}
                      type={props.type}
                    />
                  </div>
                )}
              {CLIENT_ID === GIL_CLIENT_ID &&
                (props.type === "category_experts" ? (
                  <div className="d-flex article-section-outer flex-lg-wrap slider-mob-inner mb-42">
                    {topicExperts.map((item) => {
                      return (
                        <MyCommunity
                          key={item.id}
                          item={item}
                          handleOnClick={setDetailsFollowerfollowing}
                          // type ={this.props.type}
                          showCharFollowButtons={true}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="pwa-group-list">
                    {campaignsTopStories.map((items) => {
                      return (
                        <>
                          <div className="pwa-chat-view">
                            <div
                              onClick={() => handleGroupClick(items)}
                              className="pwa-chat-unit align-items-center flex-nowrap position-relative cursor-pointer"
                            >
                              {!Utility.isEmpty(items.image_url) ? (
                                <img
                                  className="pcu-img rounded-circle"
                                  src={items.image_url}
                                  alt=""
                                />
                              ) : (
                                <Avatar
                                  name={items.name}
                                  round={true}
                                  size={"60"}
                                  className="pcu-img"
                                  textSizeRatio={1.75}
                                  color="#007bb3"
                                  maxInitials={1}
                                />
                              )}
                              <div
                                className="pcu-text-outer"
                                style={{ marginLeft: 8 }}
                              >
                                <div className="font-weight-bold text-truncate display-8">
                                  {items.name}
                                </div>
                                <div className="text-truncate font-14 b-500">
                                  {items.recent_message ? (
                                    decrypteGroupMessages(items)
                                  ) : (
                                    <>
                                      {items.recent_message_time && (
                                        <>
                                          <div className="media-image">
                                            <MdPhoto />
                                          </div>
                                          Media
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {items.unread_messages_count && (
                                <div className="align-self-end bg-primary text-white pcu-count flex-center b-500">
                                  {items.unread_messages_count}
                                </div>
                              )}
                              <div className="font-12 position-absolute pcu-time">
                                {items.recent_message_time &&
                                  Utility.returnDuration(
                                    items.recent_message_time
                                  )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="home-page-padding">
            <div className="mt-0">
              <div className="d-flex">
                <Form className="shar-form search-form form-inline">
                  <div className="course-input-group input-group">
                    <InputField
                      type="text"
                      className="h-25 headerSearchBar"
                      placeholder={`Search ${heading}`}
                      handleChange={(ev) => handleSearch(ev)}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text course-search-icon bg-white"
                        id="basic-addon2"
                      >
                        <AiOutlineSearch />
                      </span>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          {props.type !== "category_groups" &&
            props.type !== "category_experts" && (
              <>
                <div className="mt-4">
                  <CourseContainerCard
                    courseData={campaignsTopStories.filter(data => {return data.ranking >= 1 && data.ranking <= 5})}
                    // courseData={props.type === "suggestedReads" ? campaignsTopStories : campaignsTopStories.filter(data => {return data.ranking === 5})}
                    PanalTitle={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads"?"Featured Articles":"Featured Courses"}
                    category="featured"
                    type={props.type}
                    categoriesList={categoriesList}
                    isArticles={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads"?true:false}
                    // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                  />
                </div>
                {categoriesList.map((categoryDetails) => {
                  return (
                    <div key={categoryDetails.id} className="mt-4">
                      
                      <CourseContainerCard
                        courseData={campaignsTopStories.filter((data) => {
                          return (
                            data.campaign_category === categoryDetails.name
                          );
                        })}
                        PanalTitle={categoryDetails.name}
                        colorCode={categoryDetails.color_code}
                        type={props.type}
                        categoriesList={categoriesList}
                        isArticles={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads"?true:false}
                        // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                      />
                    </div>
                  );
                })}
                {/* <div className="mt-4">
                  <CourseContainer
                    courseData={campaignsTopStories}
                    PanalTitle="Freatured Course"
                    category="featured"
                    type={props.type}
                  />
                </div> */}
                {/* <div className="mt-4">
                  <CourseContainer
                    courseData={campaignsTopStories.filter((data) => {
                      return data.campaign_category === "Courses";
                    })}
                    PanalTitle="Courses"
                    category="courses"
                    type={props.type}
                  /> */}
                {/* campaignsTopStories.filter(data => {
              return data.campaign_category === "Courses"
            }) */}
                {/* </div> */}
                {/* <div className="mt-4">
                  <CourseContainer
                    courseData={campaignsTopStories.filter((data) => {
                      return data.campaign_category === "Course Test";
                    })}
                    PanalTitle="Course Test"
                    category="course-test"
                    type={props.type}
                  />
                  
                </div> */}
                {/* <div className="mt-4">
                  <CourseContainer
                    courseData={campaignsTopStories.filter((data) => {
                      return data.campaign_category === "Sustainable";
                    })}
                    PanalTitle="Sustainable"
                    category="sustainable"
                    type={props.type}
                  />
                  
                </div> */}
              </>
            )}
        </>
      )}
    </div>
  );
}

const mapActionToProps = {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
});

export default connect(mapStateToProps, mapActionToProps)(Articles);
