import React, { useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import Form from "react-bootstrap/Form";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import { Button, Spinner, Image, Dropdown } from "react-bootstrap";
import { Utility } from "utils";
import {
  GET_CLIENT_LOGO_URL,
  GET_TAX_RATE,
  ACCOUNT_SUBSCRIPTION,
  CLIENT_PAYMENT_DETAILS,
  LICENSE
} from "config/constants/api_endpoints";
import { DEFAULT_TAX_PERCENTAGE, CITY_PURCHASE_AMOUNT, PAYMENT_RECEIVER_USER_CLIENT_ID } from "config/constants";
import { SmTermsOfService } from "shared/components/sm_terms_of_service";
import StripeCardElement from "shared/components/v2/campaign/components/stripe_card_elements";
import SideCallout from "shared/components/templates/side_callout";
import { CLIENT_ID, PLAN } from 'config/constants/config';

const PurchaseCityAccount = (props) => {
  const [fullName, setFullName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [showTos, setShowTos] = useState(false);
  const [showPp, setShowPp] = useState(false);
  const [showAup, setShowAup] = useState(false);
  const [showDa, setShowDa] = useState(false);
  const [showSmTosModal, setShowSmTosModal] = useState(false);
  const [finalAmount, setFinalAmount] = useState(null);
  const [licenses, setlicenses] = useState([]);
  const [selectedLicense, setselectedLicense] = useState([]);
  const license = useRef({});

  useEffect(() => {
    getClientLogo();
  }, [props.newUserClientDetails]);

  const getClientLogo = () => {
    if (props.newUserClientDetails) {
      Utility.sendRequest(
        GET_CLIENT_LOGO_URL,
        1,
        { clientId: props.newUserClientDetails.id },
        (error, response, body) => {
          body = JSON.parse(body);

          if (!body.error) {
            setLogoUrl(body.logo_url);
            localStorage.setItem("logoUrl", body.logo_url);
          }
        }
      );
    }
  };

  const getLicenses = () => {
    const params = { clientId: CLIENT_ID };

    Utility.sendRequest(LICENSE, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setlicenses(body);
        setselectedLicense((body && body[0]) || {});
        license.current = (body && body[0]) || {};
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  };

  useEffect(() => {
    getLicenses();
  }, []);

  const handleSelect = e => {
    const setLicense =
      licenses && licenses.filter(item => item.period === parseInt(e))[0];
    setselectedLicense(setLicense);
    license.current = setLicense;
  };

  const handleSubmit = (stripe, cardNumber) => {
    setShowLoader(true);
    stripe
      .createToken(cardNumber)
      .then(function (result) {
        if (result.error) {
          const errorElement = document.getElementById("card-errors");
          if (errorElement) errorElement.textContent = result.error.message;
          setShowLoader(false);
        } else {
          paymentDetailAPI(result, license.current);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getTaxAmount = () => {
    if (props.cityFormData.zip_code) {
      const params = { zip_code: props.cityFormData.zip_code };
      Utility.sendRequest(GET_TAX_RATE, 1, params, (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          const getTaxPercentage = body.rate
            ? body.rate.state_rate != "0.0"
              ? body.rate.state_rate
              : DEFAULT_TAX_PERCENTAGE
            : null;
          const percentageAmount =
            (CITY_PURCHASE_AMOUNT * getTaxPercentage) / 100;
          localStorage.setItem('taxPercentage', getTaxPercentage);
          const totalAmount = CITY_PURCHASE_AMOUNT + percentageAmount;
          localStorage.setItem('taxAmount', totalAmount);
          setFinalAmount(totalAmount);
        } else {
          const defaultAmount =
            CITY_PURCHASE_AMOUNT +
            (CITY_PURCHASE_AMOUNT * DEFAULT_TAX_PERCENTAGE) / 100;
            localStorage.setItem('taxAmount', defaultAmount);
          setFinalAmount(defaultAmount);
        }
      });
    }
  };

  useEffect(() => {
    getTaxAmount();
  }, [props.cityFormData.zip_code]);

  const paymentDetailAPI = (result, license) => {
    const newUserClientDetails = props.newUserClientDetails;
    const userData = props.userData;

     // using local storage for taxAmount
     //TODO: need to fix local state not update issue for taxAmount
    const taxamount = localStorage.getItem("taxAmount") || 0;
    const amount = `$${Utility.paintUptoTwoDecimalPlaces(taxamount)}`;
    const stripe_plan_id = license.stripe_plan_id;
    const paymentDetails = {
      isCardSaved: true, // card need to be saved for subscription
      amount: parseFloat(amount.split("$")[1]),
      is_active: true,
      token: result.token,
      user_id: userData.user_id,
      user_email: newUserClientDetails.email,
      client_id: userData.client_id,
      sender_user_client_id: newUserClientDetails["UserClients"][0].id,
      receiver_user_client_id: PAYMENT_RECEIVER_USER_CLIENT_ID,
      campaign_id: null,
      forCityAccount: true,
      newPurchasedClientId: newUserClientDetails.id,
      stripe_plan_id: stripe_plan_id,
      license_id: license.id,
      totalTaxAmount: localStorage.getItem("percentageAmount"),
      totalInvoiceAmount: taxamount,
      taxPercentage: localStorage.getItem("taxPercentage"),
    };

    console.log(paymentDetails);

    const cityDetails = {
      client_name: newUserClientDetails.name,
      client_image_url:
        newUserClientDetails.logo_url || newUserClientDetails.image_url,
      client_city: newUserClientDetails.city,
      client_api_key: newUserClientDetails.client_api_key,
    };

    Utility.sendRequest(
      ACCOUNT_SUBSCRIPTION,
      2,
      paymentDetails,
      (error, response, body) => {
        body = JSON.parse(body);

        if (body.error) {
          setShowLoader(false);
          toast.error(body.error.message, { containerId: "private" });
        } else {
          props.handleClose();
          setShowLoader(false);
          props.showAfterPurchase(
            buildSuccessMessage({ ...paymentDetails, ...cityDetails }, body.id)
          );
        }
      }
    );
  };

  const buildSuccessMessage = (paymentDetails, transactionId) => {
    const dateTime = generateDateAndTime();
    return {
      date:
        dateTime.date.toString() +
        " | " +
        dateTime.time.toString() +
        " " +
        dateTime.zone.toString(),
      transaction: transactionId,
      amount: paymentDetails.amount,
      card:
        paymentDetails.token.card.brand.toUpperCase() +
        " ending in xxxx-" +
        paymentDetails.token.card.last4,
      sender: props.userData.user_name,
      client_name: paymentDetails.client_name,
      client_image_url: paymentDetails.client_image_url,
      client_city: paymentDetails.client_city,
      client_api_key: paymentDetails.client_api_key,
      sender_user_client_id: paymentDetails.sender_user_client_id,
    };
  };

  const generateDateAndTime = () => {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const today = moment().tz(clientTimezone).format();
    return {
      date: moment(today).format("ll"),
      time: moment(today).format("hh:mm:ss A"),
      zone: moment.tz(today, clientTimezone).format("z"),
    };
  };

  return (
    <>
      <SideCallout
        closeCallOut={props.handleClose}
        position="position-fixed mt-5"
        calloutHeading="Create Account"
        calloutHeadingImg={process.env.PUBLIC_URL + '/toast_icon/city_logo.png'}
      >
        <div className="citizen-details">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="citizen-details-img mr-2">
                {!Utility.isEmpty(logoUrl) ? (
                  <Image
                    className="rounded-circle client-profile-dropdown-img"
                    src={logoUrl}
                    alt="Profile Picture"
                  />
                ) : (
                  <Avatar
                    name={props.newUserClientDetails.name}
                    round={true}
                    size="40"
                    textSizeRatio={1.75}
                    color="#007bb3"
                    maxInitials={1}
                  />
                )}
              </div>
              <div className="font-24 b-600">
                {props.newUserClientDetails.name}
              </div>
            </div>
            <div className="ml-auto d-none">
              <Image
                src={require("assets/images/profile/new-profile/more.svg")}
                alt=""
              />
            </div>
          </div>
          <div className="pl-5 mt-3">
            <div className="d-flex mb-2">
              <div className="icon mr-2">
                <Image
                  src={require("assets/images/profile/new-profile/location.svg")}
                  alt=""
                />
              </div>
              <span className="font-14">
                {props.cityFormData.address1}
                <br />
                {props.cityFormData.suite}
                <br />
                {props.cityFormData.city}
              </span>
            </div>
            <div className="d-flex mb-2">
              <div className="icon mr-2">
                <Image
                  src={require("assets/images/profile/new-profile/email.svg")}
                  alt=""
                />
              </div>
              <span className="font-14">{props.cityFormData.email}</span>
            </div>
            <div className="d-flex mb-2">
              <div className="icon mr-2">
                <Image
                  src={require("assets/images/profile/new-profile/call-incoming.svg")}
                  alt=""
                />
              </div>
              <span className="font-14">{props.cityFormData.phone}</span>
            </div>
            <div className="d-flex mb-2">
              <div className="icon mr-2">
                <Image
                  src={require("assets/images/profile/new-profile/website.svg")}
                  alt=""
                />
              </div>
              <span className="font-14">{props.cityFormData.website}</span>
            </div>
            <div className="d-flex mb-2">
              <div className="icon mr-2">
                <Image
                  src={
                    props.userData.profile_image_url ||
                    require("assets/images/users/user_no_image.png")
                  }
                  className="rounded-circle"
                  alt=""
                />
              </div>
              <span className="font-14 text-capitalize">
                Owned by {props.userData.first_name} {props.userData.last_name}
              </span>
            </div>
          </div>
        </div>
        <div className="px-5 mt-4">
          <div className="font-16 b-700 mb-3 pt-2">Software License</div>
          <Dropdown>
            <Dropdown.Toggle className="city-license">
              <div className="d-flex p-2">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/security-key.svg")}
                    alt=""
                  />
                </div>
                <div className="city-license-detail">
                  <div className="font-16 b-700">City License</div>
                  <div className="font-16">Monthly</div>
                </div>
              </div>
            </Dropdown.Toggle>
          </Dropdown>
          <div className="font-16 b-700 mb-3 pt-2 mt-4">Enter amount</div>
          <div className="d-flex flex-column mb-4">
            <div className="mr-auto text-right pl-4">
              <div className="font-36 b-700 text-primary">
                {" "}
                ${Utility.paintUptoTwoDecimalPlaces(finalAmount)}
              </div>
              <div className="font-16">Tax Included</div>
            </div>
          </div>
          <div className="font-16 b-700 mb-3 pt-2 ">Enter card details</div>
          <Form className="card-detail" id="payment-form">
            <StripeCardElement
              paymentForm="payment-form"
              setFullName={setFullName}
              setZipCode={setZipCode}
              handleSubmit={handleSubmit}
            />
            {/* <div className="check-msg">
              <input type="checkbox" id="isCardSaved" name="isCardSaved" />
              <label className="font-16" htmlFor="isCardSaved">
                Save card to file
              </label>
            </div> */}
            <Form.Text className="font-16 my-4">
              <span>
                By selecting, “Purchase”, you agree to the{" "}
                <span
                  onClick={() => {
                    setShowSmTosModal(true);
                    setShowTos(true);
                  }}
                  className="text-primary font-medium cursor-pointer"
                >
                  Terms of Service
                </span>
                ,{" "}
                <span
                  onClick={() => {
                    setShowSmTosModal(true);
                    setShowPp(true);
                  }}
                  className="text-primary font-medium cursor-pointer"
                >
                  Privacy Policy
                </span>
                ,{" "}
                <span
                  onClick={() => {
                    setShowSmTosModal(true);
                    setShowDa(true);
                  }}
                  className="text-primary font-medium cursor-pointer"
                >
                  Development Agreement
                </span>
                , and{" "}
                <span
                  onClick={() => {
                    setShowSmTosModal(true);
                    setShowAup(true);
                  }}
                  className="text-primary font-medium cursor-pointer"
                >
                  Acceptable Use Policy
                </span>{" "}
                and the card on file will be billed on the 1st day of each
                calendar month.
              </span>
            </Form.Text>
            <Button
              disabled={showLoader}
              type="submit"
              variant="primary"
              className={`btn btn-theme btn-primary px-4 btn-get-started position-relative mt-3`}
            >
              {showLoader ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {` PURCHASING...`}
                </>
              ) : (
                "PURCHASE"
              )}
            </Button>
          </Form>
        </div>
      </SideCallout>

      {showSmTosModal && (
        <SmTermsOfService
          showTos={showTos}
          showPp={showPp}
          showDa={showDa}
          showAup={showAup}
          handleClose={() => {
            setShowSmTosModal(false);
            setShowTos(false);
            setShowPp(false);
            setShowDa(false);
            setShowAup(false);
          }}
        />
      )}
    </>
  );
};

export default PurchaseCityAccount;
