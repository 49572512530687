import React, { Component, useEffect, useState } from 'react';
import { Col, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { SmileIcon, SendIcon, CircleFilledAddLargeIcon, EditIconWithLine, TrashIcon } from 'shared/icons/icons';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { MESSAGES, MESSAGE_MARK_AS_READ, API_BASE_URL, MESSAGE_IMAGE_URL, GROUP_MESSAGES_MARK_READ, UPDATE_MESSAGES } from 'config/constants/api_endpoints';
import { Textarea } from 'shared/components/form_elements';
import { Utility } from 'utils';
import { InputField } from "shared/components/form_elements";
import { Link } from "react-router-dom";
import { UserProfilePicture } from 'shared/components/user_profile_picture';
import socket from 'utils/sockets.js';
import { MAX_DOCUMENT_SIZE, CAMPAIGN_TYPES_IDS } from 'config/constants';
import { AttachIcon, ChevronLeftIcon, AddIcon } from 'shared/icons/icons';
import { updateCampaignFormOpen, updateCampaignSelectedTab, updateIsCampSelFormOpen } from 'shared/actions';
import { MESSAGE_TYPE_IDS } from "config/constants";
import UploadFile from 'shared/components/file_upload';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import Avatar from 'react-avatar';
import { useHistory } from "react-router-dom";
import { updateIsChatFormOpen } from 'shared/actions';
import SideCallout from "shared/components/templates/side_callout";
import { AiFillDelete } from "react-icons/ai";
import { MdEdit, MdSend, MdClose } from "react-icons/md";

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      groupedMessage: [],
      currentMessage: {},
      chatPrivateKey: '',
      showLoader: true,
      imageFile: {},
      mediaURL: '',
      media_mime: '',
      message_type_id: '',
      documentName: '',
      documentFile: {},
      currentEmoji: ""
    };

    if (!Utility.isEmpty(props.userData) && !this.props.friend.fromTopics) {
      this.getAllMessages();
      if (!this.props.friend.group_id)
        this.markAsRead();
      else
        this.markGroupMessagesRead(this.props.friend.group_id);
    }
  }

  componentDidMount = () => {
    socket.on('message', this.listenForMessage);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.userData && prevProps.friend.id !== this.props.friend.id) {
      this.setState({ messages: [], showLoader: true });
      this.getAllMessages();
    }
  }

  componentWillUnmount = () => {
    socket.off('message', this.listenForMessage);
  }

  listenForMessage = (newMessage) => {
    if (!Utility.isEmpty(this.props.friend) && (newMessage.from_user_client_id === this.props.friend.id)) {
      this.updateMessages(newMessage);
    }
    else
      this.getAllMessages();
  }


  updateMessages = (data) => {
    if (this.state.chatPrivateKey !== '') {
      data.message = Utility.decryptMessages(
        data.message, this.state.chatPrivateKey,
        this.props.friend.private_key,
        this.props.userData.private_key);
      this.updateMessageList(data);
    } else {
      this.getAllMessages();
    }
    if (!this.props.friend.group_id)
      this.markAsRead();
  }

  markAsRead = () => {
    const params = {
      is_read: true,
      qs: {
        to_user_client_id: this.props.userData.id,
        from_user_client_id: this.props.friend.id
      }
    };

    Utility.sendRequest(MESSAGE_MARK_AS_READ, 3, params, (error, response, body) => {
      body = JSON.parse(body);
      if (body.error)
        toast.error(body.error.message, { containerId: 'private' });
    });
  }

  markGroupMessagesRead = (group_id) => {
    const params = {
      qs: {
        user_client_id: this.props.userData.id,
        group_id
      }
    };

    Utility.sendRequest(GROUP_MESSAGES_MARK_READ, 3, params, (error, response, body) => {
      body = JSON.parse(body);
      if (body.error)
        toast.error(body.error.message, { containerId: 'private' });
      else
        console.log(body);
    });
  }

  groupedDays = (messages) => {
    return messages.reduce((acc, el, i) => {
      const messageDay = moment.utc(el.created_at).local().format("ll");
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }

  getAllMessages = () => {
    const params = {
      to_user_client_id: this.props.friend.id,
      from_user_client_id: this.props.userData.id,
      isGroup: this.props.friend.group_id ? true : false,
      group_id: this.props.friend.group_id
    };

    Utility.sendRequest(MESSAGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      let messagesArr = [];
      let chatPrivateKey = '';
      const group_private_key = params.isGroup && this.props.friend.group_private_key;

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if (!Utility.isEmpty(body.data)) {
          const { messages, chat_private_key } = body.data[0];
          if (params.isGroup) {
            messagesArr = Utility.decryptAllMessages(
              messages,
              "",
              "",
              group_private_key
            );
          }
          else {
            messagesArr = Utility.decryptMessages(
              messages,
              chat_private_key,
              this.props.friend.private_key,
              this.props.userData.private_key);
            chatPrivateKey = chat_private_key;
          }
        }
      }
      const groupedMessage = this.groupedDays(messagesArr);
      this.setState({ messages: messagesArr, chatPrivateKey: chatPrivateKey, showLoader: false, groupedMessage: groupedMessage }, () => {
        this.scrollMessages();
      });
    });
  }

  deleteMessage = (message) => {
    Utility.sendRequest(MESSAGES + '/' + message.id, 4, {}, async (error, response, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      else {
        this.getAllMessages();
      }
    });
  }

  editMessage = (message, new_message) => {
    const encryptedMessage = this.encryptedEditedMessage(new_message);
    const params = {
      qs: {
        id: message.id,
        message: encryptedMessage
      }
    };
    Utility.sendRequest(UPDATE_MESSAGES, 3, params, async (error, response, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      else {
        this.getAllMessages();
      }
    });
  }

  encryptedEditedMessage = (message) => {
    let encryptedMessage;
    let senderPrivateKey;
    let receiverPrivateKey;
    if (!this.props.friend.group_id) {
      senderPrivateKey = this.props.userData.private_key;
      receiverPrivateKey = this.props.friend.private_key;

      if (this.state.chatPrivateKey !== '' && senderPrivateKey !== this.state.chatPrivateKey) {
        receiverPrivateKey = senderPrivateKey;
        senderPrivateKey = this.state.chatPrivateKey;
      }

      if (message)
        encryptedMessage = Utility.encryptedMessage(message, senderPrivateKey, receiverPrivateKey);
    }
    else {
      if (message) {
        const group_private_key = this.props.friend.group_private_key;
        encryptedMessage = Utility.encryptedMessage(message, '', '', group_private_key);
      }
    }
    return encryptedMessage;
  }

  updateMessageList = (msg) => {
    if (this.state.chatPrivateKey !== '') {
      const messages = this.state.messages;
      messages.push(msg);
      const updatedMessages = this.groupedDays(messages);
      this.setState({ messages, groupedMessage: updatedMessages }, () => {
        this.scrollMessages();
      });
    } else {
      this.getAllMessages();
    }
  }

  scrollMessages = () => {
    var element = document.getElementById("messageList");
    if (element !== null) element.scrollTop = element.scrollHeight;
  }

  onEmojiClick = (e, emojiObject) => {
    this.setState({ currentEmoji: emojiObject.emoji });
  }


  render() {
    const friend = this.props.friend;

    return (
      <React.Fragment>
        {!friend.fromTopics &&
          <div className="chat-details mgs-header-padding message-box-view px-0">
            {this.state.showLoader
              ? <center><Spinner animation="border" role="status" /></center>
              : <GroupedMessageList
                key={friend.id}
                friend={friend}
                userData={this.props.userData}
                messages={this.state.groupedMessage}
                deleteMessage={this.deleteMessage}
                editMessage={this.editMessage}
              />
            }
            <SendMessage
              friend={friend}
              userData={this.props.userData}
              updateMessageList={this.updateMessageList}
              chatPrivateKey={this.state.chatPrivateKey}
              emoji={this.state.currentEmoji}
              updateCampaignFormOpen={this.props.updateCampaignFormOpen}
              updateCampaignSelectedTab={this.props.updateCampaignSelectedTab}
              updateIsCampSelFormOpen={this.props.updateIsCampSelFormOpen}
              markGroupMessagesRead={this.markGroupMessagesRead}
            />
          </div>
        }
      </React.Fragment>
    );
  }
}

const mapActionToProps = { updateCampaignFormOpen, updateCampaignSelectedTab, updateIsCampSelFormOpen };

const mapStateToProps = (state) => ({
  userData: state.userData
});

export default connect(mapStateToProps, mapActionToProps)(Conversation);

class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMessage: '',
      isInputExpanded: false,
      choosenEmoji: false,
      isPlusIconClicked: false
    };
  }
  componentDidMount = () => {
    this.setState({ currentMessage: this.props.emoji });
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.emoji !== this.props.emoji) {
      this.setState({ currentMessage: this.state.currentMessage + "" + this.props.emoji });
    }
  }

  sendMessage = (e) => {
    // e.preventDefault();
    const message = this.state.currentMessage;

    if ((!message || message.trim() === '') && !this.state.mediaURL) return;

    this.setState({ currentMessage: '' }, () => { this.autoresize(); });
    let encryptedMessage;
    let senderPrivateKey;
    let receiverPrivateKey;
    if (!this.props.friend.group_id) {
      senderPrivateKey = this.props.userData.private_key;
      receiverPrivateKey = this.props.friend.private_key;

      if (this.props.chatPrivateKey !== '' && senderPrivateKey !== this.props.chatPrivateKey) {
        receiverPrivateKey = senderPrivateKey;
        senderPrivateKey = this.props.chatPrivateKey;
      }

      if (message)
        encryptedMessage = Utility.encryptedMessage(message, senderPrivateKey, receiverPrivateKey);
    }
    else {
      if (message) {
        const group_private_key = this.props.friend.group_private_key;
        encryptedMessage = Utility.encryptedMessage(message, '', '', group_private_key);
      }
    }

    const params = {
      from_user_client_id: this.props.userData.id,
      group_id: this.props.friend.group_id,
      new_message: this.props.chatPrivateKey === '', //this.props.newMessage,
      media: this.state.mediaURL,
      message: encryptedMessage,
      media_mime: this.state.media_mime,
      message_type_id: this.state.message_type_id,
      user_id: this.props.userData.user_id,
      document_name: this.state.documentName,
      source: this.props.friend.isAudience ? 'audience' : 'network',
      chat_private_key: senderPrivateKey
    };
    if (!this.props.friend.group_id) {
      params.to_user_client_id = this.props.friend.id;
    }
    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        this.setState({ currentMessage: message });

        toast.error(body.error.message, { containerId: 'private' });
      } else {
        body.message = message;
        this.props.updateMessageList(body);
        this.resetState();
      }
    });
  }

  onHandleChange = (e) => {
    this.autoresize();
    this.setState({ currentMessage: e.target.value });
  }
  onEmojiClick = (e, emojiObject) => {
    this.setState({ currentMessage: this.state.currentMessage + "" + emojiObject.emoji });
  }

  resetState = () => {
    this.setState({ mediaURL: '', media_mime: '', message_type_id: '', documentName: '', documentFile: {}, imageFile: {}, isInputExpanded: false });
    // document.getElementById('image_upload').value = null;
    // document.getElementById('file_upload').value = null;
    if(document.getElementById('file_upload')!==null)document.getElementById('file_upload').value = null;

  }

  handleFileChange = async (name, previewURL, file) => {
    if (name === "document_url") {
      this.setState({ documentName: file.name, documentFile: file }, () => {
        this.uploadMedia("document");
      });
    }
    else {
      this.setState({ imageFile: file }, () => {
        this.uploadMedia("image");
      });
    }
  }

  handleDocumentUpload = (event) => {
    const DOCUMENT_URL = "document_url";
    if (event.target.files[0]) {
      const reader = new FileReader();
      const originalFile = event.target.files[0];

      if (originalFile.size > MAX_DOCUMENT_SIZE) {
        const errText = 'Document must be less than ' + (MAX_DOCUMENT_SIZE / 1048576) + 'MB.';
        toast.error(errText, { containerId: "public" });
        event.target.value = '';
        return;
      } else if (originalFile.type !== "application/pdf") {
        toast.error("Document must be PDF file.", { containerId: "public" });
        return;
      }
      reader.readAsDataURL(originalFile);
      reader.onloadend = () => { this.handleFileChange(DOCUMENT_URL, reader.result, originalFile); };
    }
  }

  uploadMedia = (type) => {
    Utility.sendRequest(MESSAGE_IMAGE_URL, 1, {}, async (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        console.log(body.error);
      } else {
        await this.uploadFiles(body.path, type);
      }
    });
  }


  uploadFiles = async (imageSignedURL, type) => {
    const imageFile = this.state.imageFile;
    const documentFile = this.state.documentFile;

    if (type === 'image' && !Utility.isEmpty(imageFile.size)) {
      const imageURL = await Utility.uploadFile(imageFile, imageSignedURL);
      this.setState({ mediaURL: imageURL, media_mime: 'image/jpeg', message_type_id: MESSAGE_TYPE_IDS.MEDIA }, () => {
        this.sendMessage();
      });
    }
    if (type == 'document' && !Utility.isEmpty(documentFile.size)) {
      const documentURL = await Utility.uploadFile(documentFile, imageSignedURL);
      this.setState({ mediaURL: documentURL, media_mime: 'application/pdf', message_type_id: MESSAGE_TYPE_IDS.MEDIA }, () => {
        this.sendMessage();
      });
    }

  };

  autoresize = () => {
    const elements = document.getElementsByName('text_message');

    if (!(elements && elements.length > 0)) return;

    const element = elements[0];
    element.style.height = '29px';
    element.style.height = element.scrollHeight + 'px';
    element.scrollTop = element.scrollHeight;
    window.scrollTo(window.scrollLeft, (element.scrollTop + element.scrollHeight));
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      this.sendMessage(event);
    }
  }

  MarkGroupMessageRead = () => {
    if (this.props.friend.group_id)
      this.props.markGroupMessagesRead(this.props.friend.group_id);
  }

  handleTabSelectionClick = (arg) => {
    this.props.updateIsCampSelFormOpen(false);
    this.props.updateCampaignSelectedTab(arg);
    this.props.updateCampaignFormOpen(true);
    this.setState({ isPlusIconClicked: false });
  };

  render() {
    return (
      !Utility.pwaModeEnabled() ?
        <>
          <Form className="input-msg-box shar-form">
            <InputGroup>
              <Textarea
                required={true}
                type="text" name="text_message"
                placeholder="Type something here…"
                handleChange={this.onHandleChange}
                value={this.state.currentMessage}
                onKeyDown={this.onKeyDown}
                autoresize={"true"}
                className="scroll-y"
              />
              <div className="send-icons-position">
                {/*<SmileIcon
              mainClass="mr-3 cursor-pointer"
              fillClass="fill-primary"
              strokeClass="stroke-primary"
            />*/}
                <Button
                  variant="link"
                  onClick={this.sendMessage}>
                  <SendIcon
                    mainClass="ml-1 mt-n1 cursor-pointer"
                    fillClass="fill-primary"
                  />
                </Button>
              </div>
            </InputGroup >
          </Form>
        </>
        :
        <>
          {this.state.choosenEmoji &&
            <Picker onEmojiClick={this.onEmojiClick} skinTone={SKIN_TONE_MEDIUM_DARK} className="gil-chat-emoji" />
          }
          <div className={`group-chat-footer position-fixed bg-white w-100 align-items-center border-top ${!this.state.isInputExpanded ? 'input-expanded' : ''}`} >
            <div onClick={() => this.setState({ isPlusIconClicked: true })}>
              <CircleFilledAddLargeIcon strokeClass="stroke-primary" mainClass="group-chat-add" />
            </div>
            <div className="ml-4 chat-upload-sec" >
              <label className="chat-upload-outer" >
                <ImgUpload />
                <UploadFile
                  name='image_url'
                  id='image_upload'
                  inputId="image_url"
                  removeText="Remove image"
                  acceptTypes="image/x-png,image/gif,image/jpeg"
                  handleFileChange={this.handleFileChange}
                />
              </label>
            </div>
            <label className="mx-4 attach-upload-outer" >
              <AttachIcon fillClass="fill-primary" />
              <InputField
                handleChange={this.handleDocumentUpload}
                type="file"
                id='file_upload'
                accept="application/pdf"
              />
            </label>
            <div className="ml-auto" onClick={() => this.setState({ isInputExpanded: !this.state.isInputExpanded })} >
              <ChevronLeftIcon fillClass="fill-black" />
            </div>
            <div className="position-relative" >
              <input
                type="text"
                required={true}
                name="text_message"
                placeholder="Type message here..."
                onChange={this.onHandleChange}
                onKeyDown={this.onKeyDown}
                autoComplete="off"
                onClick={() => this.MarkGroupMessageRead()}
                value={this.state.currentMessage}
                className="group-chat-input mr-1 ml-2"
              />
              <div className="group-chat-emoji position-absolute" onClick={() => this.setState({ choosenEmoji: !this.state.choosenEmoji })} >
                <SmileIcon fillClass="fill-primary" strokeClass="stroke-primary" />
              </div>
            </div>
            <div className="bg-primary rounded-circle flex-center group-chat-send" onClick={this.sendMessage}>
              <SendIcon fillClass="fill-white" />
            </div>
          </div>

          {this.state.isPlusIconClicked &&
            <SideCallout fromPlusIcon={true} closeCallOut={() => this.setState({ isPlusIconClicked: false })} position="position-absolute gil-pwa-callout gil-pwa-callout-slideMore plus-icon-height">
              <div className="mt-n4 mx-n3" >
                <div className="font-weight-bold display-7 flex-center pb-4" ><AddIcon mainClass="mr-3" />What do you want to share?</div>

                <div onClick={() => this.handleTabSelectionClick(CAMPAIGN_TYPES_IDS.event)} className="border-bottom py-3 align-items-center px-5" >
                  {/* <img className="ml-2" src={this.state.myEvents.length > 0 ? require('assets/images/profile/badges/gm-badge.svg') : require('assets/images/profile/badges/empty-badge.svg')} style={{ width: 25 }} alt="" /> */}
                  <div className="display-7 ml-3 b-500" >Share Event</div>
                </div>
                <div className="border-bottom py-3 align-items-center px-5" >
                  <div className="display-7 ml-3 b-500" >Share Course</div>
                </div>
                <div onClick={() => this.handleTabSelectionClick(CAMPAIGN_TYPES_IDS.story)} className="border-bottom py-3 align-items-center px-5" >
                  {/* <img className="ml-2" src={this.state.myArticles.length > 0 ? require('assets/images/profile/badges/gm-badge.svg') : require('assets/images/profile/badges/empty-badge.svg')} style={{ width: 25 }} alt="" /> */}
                  <div className="display-7 ml-3 b-500" >Share Article</div>
                </div>
              </div>
            </SideCallout>
          }
        </>
    );
  }
}

const GroupedMessageList = (props) => {
  return (
    <Col xs={12} className="p-0 h-100 scroll-y pl-md-3" id="messageList">
      { Object.keys(props.messages).map(e => {
        return <>
          <div className="text-center font-12 font-med mt-4">
            {moment.utc(e).local().format("LL")}
          </div>
          <MessageList
            friend={props.friend}
            userData={props.userData}
            messages={props.messages[e]}
            deleteMessage={props.deleteMessage}
            editMessage={props.editMessage}
          />
        </>;
      })
      }
    </Col>
  );
};

const MessageList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFullImage, setIsFullImage] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [selectedMessage, setSelectedMessage] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [showEditDeleteOptions, setShowDeleteOptions] = useState(false);
  const handleArticleClick = (id) => {
    dispatch(updateIsChatFormOpen(false));
    history.push({
      pathname: `/campaign/${id}`,
      calledFrom: "direct_message",
    });
  };

  const userProfile = (id) => {
    dispatch(updateIsChatFormOpen(false));
    if (id === props.userData.id)
      return history.push({
        pathname: '/profile',
        calledFrom: "direct_message",
      });

    history.push({
      pathname: `/profile/${id}`,
      calledFrom: "direct_message",
      state: { otherUserProfile: true },
    });
  };


  // let clickHoldTimer = null;

  // const handleMouseDown = (message) => {
  //   if (message.from_user_client_id === props.userData.id) {
  //     clickHoldTimer = setTimeout(() => {
  //       setIsModalOpen(true);
  //       setSelectedMessage(message);
  //       // const result = window.confirm("Delete this message?");
  //       //     if (result == true)
  //       //     props.deleteMessage(message);
  //     }, 1000);
  //   }
  // };

  // const handleMouseUp = () => {
  //   clearTimeout(clickHoldTimer);
  // };
 
  const handleMessageClick = (message) => {
    setShowDeleteOptions(!showEditDeleteOptions);
    setSelectedMessage(message);
  };

  const handleEditClick = (message) => {
    setIsEdit(true);
    setCurrentMessage(message.message);
  };

  const handleEditMessage = (e) => {
    setCurrentMessage(e.target.value);
  };

  const handleSendButton = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsEdit(false);
    props.editMessage(selectedMessage, currentMessage);
  };

  const actualMessage = (message) => {
    if (message.message_type_id === MESSAGE_TYPE_IDS.TEXT_MESSAGES || Utility.isEmpty(message.message_type_id)) {
      return (
        <div className="msg-edit-option" onClick={() => handleMessageClick(message)}>
          {isEdit && selectedMessage.id === message.id ?
            <>
              <input value={currentMessage} onChange={handleEditMessage} autoFocus/>
              <div className="display-7 d-flex justify-content-end w-50 pr-5" >
                <span className="text-primary ml-2 mr-3" onClick={(e) => handleSendButton(e)}><MdSend /></span>
                <span className="text-danger" onClick={() => setIsEdit(false)}><MdClose /></span>
              </div>
            </>
            :
            <p>
              {message.message}
            </p>
          }
        </div>
      );
    }
    else if (message.message_type_id === MESSAGE_TYPE_IDS.MEDIA) {
      if (message.media_mime === 'image/jpeg') {
        return (
          <div className="chat-img-inside" onClick={() => setIsFullImage(!isFullImage)}>
            <img src={message.media} className={isFullImage ? 'img-full-size' : ''} />
            {isFullImage && !isModalOpen && <div className="img-backdrop" />}
          </div>
        );
      }
      else {
        return (
          <div className="group-chat-attachment d-flex">
            <div className="bg-primary flex-center group-chat-attachment-icon-outer" >
              <AttachIcon fillClass="fill-white" />
            </div>
            <div className="group-chat-attachment-data" >
              <div className="font-bold text-primary display-8" >{message.document_name || "Document Name"}</div>
              <div className="font-12" >PDF</div>
            </div>
            <div className="ml-auto align-self-end group-chat-attachment-download-outer" >
              <a href={message.media}>
                <img src={require('assets/images/icons/download_icon.svg')} alt="attachment icon" className="group-chat-attachment-download" />
              </a>
            </div>
          </div>
        );
      }
    }
    else if (message.message_type_id === MESSAGE_TYPE_IDS.CAMPAIGNS) {
      return (
        <div className="group-chat-article">
          <div className="article-section-outer pr-3">
            <div onClick={() => { handleArticleClick(message.campaign_id); }} className="article-outer overflow-hidden mb-3 cursor-pointer">
              <div className="article-main-img-wrap">
                <img src={message.image_url} alt="article image" className="group-chat-article-pic" />
              </div>
              <div className="px-3 py-4 ">
                <div className="font-14 mb-20 font-bold" style={{ color: 'rgb(29, 77, 25)' }}>{message.campaign_category}</div>
                <div className="font-bold display-6 article-title pb-1 text-dark">{message.heading}</div>
                <div className="d-flex mt-3">
                  {!Utility.isEmpty(message.author_image_url) ?
                    <img src={message.author_image_url} alt="" className="article-pic rounded-circle" />
                    :
                    <Avatar
                      name={message.author_name}
                      round={true}
                      size={'30'}
                      textSizeRatio={1.75}
                      color='#007bb3'
                      maxInitials={1}
                    />
                  }
                  <div className='ml-2'>
                    <div className="font-bold font-12 text-dark text-capitalize">{message.author_name}</div>
                    <div className="font-12 text-secondary">{moment(message.campaign_created_at).format("LT")}, {" "}{moment(message.campaign_created_at).format("MMM")} {moment(message.campaign_created_at).format("D")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <Col>
        <div className="mt-4" />
        {props.messages.map((message) => {
          let imageUrl;
          let loggedInUserOrChatUserId;
          let sentOrRecieveMessageCondition;
          if (Utility.isEmpty(message.group_id)) {
            imageUrl = message.from_user_client_id === props.friend.id ?
              (props.friend.creator_profile_image_url || props.friend.profile_image_url) :
              (props.userData.profile_image_url);

            loggedInUserOrChatUserId = props.friend.id;
            sentOrRecieveMessageCondition = message.from_user_client_id === loggedInUserOrChatUserId;
          }
          else {
            imageUrl = message.sender_profile_pic;
            loggedInUserOrChatUserId = props.userData.id;
            sentOrRecieveMessageCondition = message.from_user_client_id !== loggedInUserOrChatUserId;
          }

          return (
            <>
              <div key={'ms' + message.id} className="messages">
                <div className="text-center font-12 font-med mt-4">
                </div>
                <ul>
                  {
                    sentOrRecieveMessageCondition ?
                      <li className="sent">
                        <div>
                          <div className='cursor-pointer' onClick={() => userProfile(message.from_user_client_id)}>
                            <UserProfilePicture
                              image={imageUrl}
                              userName={message.group_id ? message.sender_name : (props.friend.user_name || props.friend.name)}
                              className="message-profile-sent"
                              size={30}
                            />
                          </div>
                          {actualMessage(message)}
                        </div>
                        <div style={{ marginLeft: '78%' }}>
                          <span className="font-12 font-light" >
                            {moment.utc(message.updated_at).local().format("hh:mm A")}
                          </span>
                        </div>
                      </li>
                      :
                      <li className="replies">
                        <div className='cursor-pointer' onClick={() => userProfile(message.group_id ? loggedInUserOrChatUserId : props.userData.id)}>
                          <UserProfilePicture
                            image={imageUrl}
                            userName={message.group_id ? message.sender_name : props.userData.user_name}
                            className="message-profile-picture"
                            size={30}
                          />
                        </div>
                        {actualMessage(message)}
                        {!(isEdit && selectedMessage.id === message.id) &&
                          <div className="msg-edit-options display-7 text-right pr-5" >
                            {message.message_type_id === MESSAGE_TYPE_IDS.TEXT_MESSAGES && showEditDeleteOptions && selectedMessage.id === message.id &&
                              <>
                                <span className="text-primary mr-3" onClick={() => handleEditClick(message)}><MdEdit /></span>
                                <span className="text-danger" onClick={() => props.deleteMessage(message)}><AiFillDelete /></span>
                              </>
                            }
                            {message.message_type_id !== MESSAGE_TYPE_IDS.TEXT_MESSAGES &&
                              <span className="text-danger" onClick={() => props.deleteMessage(message)}><AiFillDelete /></span>
                            }
                          </div>
                        }
                        <div style={{ marginLeft: '40px' }} >
                          <span className="font-12 font-light" >
                            {moment.utc(message.updated_at).local().format("hh:mm A")}
                          </span>
                        </div>
                      </li>
                  }
                </ul>
              </div>
            </>
          );
        })
        }
      </Col>
    </>
  );
};

const ImgUpload = (props) => {
  return (
    <svg width="25px" height="24px" viewBox="0 0 25 24">
      <g id="PWA-2021" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="7.1-Group-Page-1" transform="translate(-67.000000, -1576.000000)" fill="#272262" fill-rule="nonzero">
          <g id="Group-5" transform="translate(0.001162, 1563.000000)">
            <g id="bx:bx-image-add" transform="translate(66.998838, 13.000000)">
              <path d="M2.5,2.5 L18.75,2.5 L18.75,11.25 L21.25,11.25 L21.25,2.5 C21.25,1.12125 20.12875,0 18.75,0 L2.5,0 C1.12125,0 0,1.12125 0,2.5 L0,17.5 C0,18.87875 1.12125,20 2.5,20 L12.5,20 L12.5,17.5 L2.5,17.5 L2.5,2.5 Z" id="Path"></path>
              <polygon id="Path" points="7.5 10 3.75 15 17.5 15 12.5 7.5 8.75 12.5"></polygon>
              <polygon id="Path" points="21.25 13.75 18.75 13.75 18.75 17.5 15 17.5 15 20 18.75 20 18.75 23.75 21.25 23.75 21.25 20 25 20 25 17.5 21.25 17.5"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
