import React from 'react';
import { Row, Col, Image, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Utility } from 'utils';
import cookie from 'react-cookies';

export default function welcome(props) {
  let buttonText;
  const subDomain = cookie.load('subDomain');
  buttonText = props.isActivated ? 'Go to Profile' : 'Go to Login';

  if (props.signUp) buttonText = 'Resend Confirmation Email';

  return (
    <React.Fragment>
      <div className="container">
        <Row className="align-items-center min-h new-landing-theme">
          <Col xs={12} lg={6} className="mx-auto">
            <Row className="align-items-center text-center h-100 py-5">
              <Col xs={12}>
                {
                  Utility.isEmpty(subDomain) &&
                  <>
                    <div className="pb-4 welcome-font">Welcome To</div>
                    <div className="pb-4">
                      <Image  src={require("assets/images/welcome-left.png")} alt="Logo-sharemeister" className="d-inline-block mxh-70"/>
                      <Image  src={require("assets/images/logos/main-logo.png")} alt="Logo-sharemeister" className="d-inline-block mxw-65"/>
                      <Image  src={require("assets/images/welcome-right.png")} alt="Logo-sharemeister" className="d-inline-block mxh-70"/>
                    </div>
                  </>
                }
                <div className="pb-4 color-primary text-orange">
                  {!Utility.isEmpty(props.message) && props.message}
                </div>
                <div className="mx-auto">
                  <button className="btn btn-primary btn-login" onClick={(e) => props.submit(e)}>
                    {
                      props.showLoader
                      ? <Spinner animation="border" size="sm" role="status"/>
                      : buttonText
                    }
                  </button>
                  {
                    props.supportText &&
                    <div>
                      <br/>
                      {props.supportText} <Link to={{}}>{props.email}</Link>
                    </div>
                  }
                  <span className="ss-right d-inline-block align-middle pl-3"></span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6} className="d-lg-block d-none">
            <Row className="align-items-center text-center h-100 no-gutters">
              <Col xs={12}>
                {
                  !Utility.isEmpty(subDomain)
                  ? <figure className="d-inline-block p-4 ">
                      <div className="wl-partner-logo m-auto pb-4 text-center">
                        <img src={subDomain.crm_logo_url} alt="partner-logo" className="pt-4" />
                      </div>
                      <div className="pb-5">
                        <img src={require("assets/images/sm-pipeline-illustration-grey.png")} alt="CRM" />
                      </div>
                    </figure>
                  : <figure className="d-inline-block p-4">
                      <Image className="w-75"  src={require("assets/images/thank-you.png")} alt="thank-you" />
                      <Row className="mt-4 mx-0 text-center display-7 founder-names">
                        <Col className="pl-4 ml-5">Marlon!</Col>
                        <Col className="pr-5 mr-4 text-left">Jonathan!</Col>
                      </Row>
                    </figure>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
