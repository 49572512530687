import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateSelectedGroup } from 'shared/actions';
import CreditCard from 'shared/components/credit_card';
import { MenuTitle } from 'shared/components/templates/menu_list';
import { Row, Col } from 'react-bootstrap';

class Billing extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return  (
      <>
        <Col xl={9} lg={8} xs={12} className="ModalOpen top-on-sm h-100 p-4 overflow-auto scroll-y" >
          <Row className="mx-0 p-sm-3">
            <div className="network-box-shadow h-100 mt-3 mt-sm-0">
              <div className="network-item-header d-flex align-items-center">
                <div className="round-circle mr-2">
                </div>
                <MenuTitle menuName={this.props.selectedMenuItem.menu_name}/>
              </div>
            </div>
          </Row>
          <div className="credit-card-white" >
            <CreditCard
              userData={this.props.userData}
              />
          </div>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedMenuItem: state.selectedMenuItem,
  selectedGroup: state.selectedGroup
});

const mapActionToProps = { updateSelectedGroup };

export default connect(mapStateToProps, mapActionToProps)(Billing);
