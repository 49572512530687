import React, { Component } from 'react';
import { Row, Col, Image, Card, Spinner, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Utility } from 'utils';
import { OVERVIEW_STATS, AUDIENCES_STATS, OVERVIEW_STATS_LIST, CAMPAIGNS_STATS } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { updateSelectedMenuItem } from 'shared/actions';

class StatisticsOverview extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      overviewStats: {},
      audiencesStats: {},
      campaignsStats: {},
    };

    if (!Utility.isEmpty(this.props.userData)) {
      const params = { client_id: this.props.userData.client_id };

      this.overviewStats(params);
      this.audiencesStats(params);
      this.campaignsStats(params);
    }
  }

  componentDidMount = () => this._isMounted = true;

  componentWillUnmount = () => this._isMounted = false;

  overviewStats = (params) => {
    Utility.sendRequest(OVERVIEW_STATS, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (this._isMounted) {
        if (!body.error) {
          body.return_of_investment = '$' + Utility.toLocaleString(body.return_of_investment);
          body.proof_of_impact = '$' + Utility.toLocaleString(body.proof_of_impact);

          this.setState({ overviewStats: body });
        } else {
          this.setState({ overviewStats: { return_of_investment: '$0.00', proof_of_impact: '$0.00', transactions: 0 } });

          toast.error(body.error.message, { containerId: 'private' });
        }
      }
    });
  }

  audiencesStats = (params) => {
    Utility.sendRequest(AUDIENCES_STATS, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (this._isMounted) {
        if (!body.error) {
          this.setState({ audiencesStats: body });
        } else {
          this.setState({ audiencesStats: { registrations: 0, donation_count: 0, pledges: 0, volunteers: 0, gift_cards: 0, sentiments: 0 } });
          toast.error(body.error.message, { containerId: 'private' });
        }
      }
    });
  }

  campaignsStats = (params) => {
    Utility.sendRequest(CAMPAIGNS_STATS, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (this._isMounted) {
        if (!body.error) {
          this.setState({ campaignsStats: body });
        } else {
          this.setState({ campaignsStats: { campaigns: 0, donation_count: 0, pledges: 0 } });
          toast.error(body.error.message, { containerId: 'private' });
        }
      }
    });
  }

  getCardList = (param, selectedCard) => {
    if (['registrations', 'pledges', 'volunteers'].includes(selectedCard.toLowerCase())) {
      const endPoint = (selectedCard.toLowerCase().slice(0, -1)) + '_list';
      const params = { client_id: param.client_id };

      if (!Utility.isEmpty(endPoint)) {
        Utility.sendRequest(`${OVERVIEW_STATS_LIST}/${endPoint}`, 1, params, (error, response, body) => {
          body = JSON.parse(body);

          this.props.handleMenuItemClick({}, body, selectedCard);
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Col xs={12} xl={9} lg={8} className="p-md-4 p-3 top-on-sm ModalOpen h-md-100 scroll-y d-none d-lg-block" id="statistics-overview">
            <div className="d-block d-lg-none position-relative bg-white px-3 py-4 border-bottom back-button-sm">
            <Button variant="link" className="color-primary text-left align-items-center" onClick={() => this.props.handleMenuItemClick({}, '', '')}><img className="width-10 align-baseline mr-3" src={require("assets/images/chevron_left.svg")} alt="left-icon" />
              <span className="display-7 font-bold">Back to Statistics</span>
            </Button>
          </div>
          <div className="overview p-sm-3">
            <div className="d-flex mb-3 mt-1">
              <div className="round-circle mr-2"></div>
              <h3 className="font-30-bold">Overview</h3>
            </div>
            <div className="overview-cards-data">
              {/* <Row className="no-gutters align-items-center">
                <Col xs={12} lg={6}>
                  <div className="font-18-medium">Select a category to filter down specific statistics.</div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="d-flex align-items-center justify-content-lg-end pt-lg-0 pt-4">
                    <div className="font-16-light-italic">Sort by</div>
                    <Col lg={6} xs={10}>
                      <Form.Group controlId="formBasicEmail" className="mb-0">
                        <Form.Control as="select" className="round font-16-medium">
                          <option>Last 30 days</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </div>
                </Col>
              </Row> */}
              <div className="font-20-bold pt-3">Overview</div>
              <Col xs={12} className="p-0">
                <Row className="no-gutters">
                  <Col xs={12} lg={4} className="py-3 pr-0 pr-lg-3">
                    <StatisticsCard title="Return on Investment"
                      value={this.state.overviewStats.return_of_investment}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3">
                    <StatisticsCard title="Proof of Impact"
                      value={this.state.overviewStats.proof_of_impact}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3">
                    <StatisticsCard title="Transactions"
                      value={this.state.overviewStats.transactions}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                </Row>
              </Col>
              <div className="font-20-bold pt-3">Mobile/Website</div>
              <Col xs={12}  className="p-0">
                <Row className="no-gutters">
                  <Col xs={12} lg={4} className="py-3 pr-0 pr-lg-3 clickable">
                    <StatisticsCard title="Registrations"
                      value={this.state.audiencesStats.registrations}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3">
                    <StatisticsCard title="Donations"
                      value={this.state.audiencesStats.donation_count}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3 clickable">
                    <StatisticsCard title="Pledges"
                      value={this.state.audiencesStats.pledges}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3 clickable">
                    <StatisticsCard title="Volunteers"
                      value={this.state.audiencesStats.volunteers}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3">
                    <StatisticsCard title="Gift Cards"
                      value={this.state.audiencesStats.gift_cards}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3">
                    <StatisticsCard title="Sentiments"
                      value={this.state.audiencesStats.sentiments}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                </Row>
              </Col>
              <div className="font-20-bold pt-3">Campaigns</div>
              <Col xs={12} className="p-0">
                <Row className="no-gutters">
                  <Col xs={12} lg={4} className="py-3 pr-0 pr-lg-3">
                    <StatisticsCard title="# of Campaigns"
                      value={this.state.campaignsStats.campaigns}
                      getCardList={this.getCardList}
                      params={this.props.userData}/>
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3">
                    <StatisticsCard title="Donations"
                      value={this.state.campaignsStats.donation_count}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                  <Col  xs={12} lg={4} className="py-3 pr-0 pr-lg-3 clickable">
                    <StatisticsCard title="Pledges"
                      value={this.state.campaignsStats.pledges}
                      getCardList={this.getCardList}
                      params={this.props.userData}
                    />
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

const mapActionToProps = { updateSelectedMenuItem };
const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, mapActionToProps)(StatisticsOverview);

const StatisticsCard = function(props) {
  return(
    <Card className="h-100" onClick={ () => props.getCardList(props.params, props.title) } >
      <Card.Body>
        <Card.Title className="display-xl-6 display-8">{props.title}</Card.Title>
        {
          Utility.isEmpty(props.value)
          ? <Spinner animation="border" role="status"/>
          : <span className="text-revenue card-text">{props.value}</span>
        }
        <Image className="float-right" src={require("assets/images/progress_overview.svg")} alt="progress_overview"  />
      </Card.Body>
    </Card>
  );
};
