import React from 'react';
import { MdClose } from 'react-icons/md';
import { Row, Button } from 'react-bootstrap';

export const Receipt = props => (
  <div className="side-modal-outer offset-lg-3 offset-sm-2 position-fixed px-0 d-none d-block h-100" id="receipt">
    <div className="side-modal-inner bg-white p-sm-4 p-3 ml-sm-1 h-100">
      <div className="side-modal-header align-items-center justify-content-between">
        <h5 className="font-bold mb-0">{props.header}</h5>
        <Button variant="link" className="close-btn-position side-modal-close" onClick={() => props.hideReceipt()} ><MdClose/></Button>
      </div>
      <Row className="no-gutters">
        {props.children}
        {/* <Col lg={7} className="py-4 pl-xl-4 p-3">
          <h2>All Conversations</h2>
          <div className="align-items-center justify-content-between">
            <Dropdown>
                <Dropdown.Toggle variant="link" className="shar-dropdown align-items-center" >
                  All Conversations
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">General</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Group Chat</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Swag Talk</Dropdown.Item>
                </Dropdown.Menu>
             </Dropdown>
            <img src={require("assets/images/icons/add_icon_black.svg")} alt="Add icon" className="mr-3" />
          </div>
          <div className="border-bottom-dotted pt-4"></div>
          <div className="members-list">
            <ul className="mr-4">
              <li className="mt-4">
                <a href="#" className="text-networks">
                  <div className="d-flex member pl-1">
                    <div className="fix-width">
                      <div className="round-circle-medium bg-grey">
                        <div className="round-circle-sm bg-alternate text-light font-bold flex-center">1</div>
                      </div>
                    </div>
                    <div className="networks-name w-100 text-truncate pr-4">
                      <div className="pl-3">
                        <span className="font-20-bold">General</span>
                        <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                      </div>
                      <div className="pl-3">
                        <div className="font-14 text-truncate"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  standard dummy text ever since the 1500s, when ... </div>
                      </div>
                    </div>
                    <div className="align-items-center ml-auto flex-nowrap">
                      <img src={require("assets/images/icons/group_icon.svg")} alt="Doument icon" />
                      <span className="ml-2">5</span>
                    </div>
                  </div>
                </a>
              </li>
              <li className="mt-4">
                <a href="#" className="text-networks">
                  <div className="d-flex member pl-1">
                    <div className="fix-width">
                      <div className="round-circle-medium bg-grey">
                        <div className="round-circle-sm bg-alternate text-light font-bold flex-center">4</div>
                      </div>
                    </div>
                    <div className="networks-name w-100 text-truncate pr-4">
                      <div className="pl-3">
                        <span className="font-20-bold">Group Chat</span>
                        <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                      </div>
                      <div className="pl-3">
                        <div className="font-14 text-truncate"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  standard dummy text ever since the 1500s, when ... </div>
                      </div>
                    </div>
                    <div className="align-items-center ml-auto flex-nowrap">
                      <img src={require("assets/images/icons/group_icon.svg")} alt="Doument icon" />
                      <span className="ml-2">7</span>
                    </div>
                  </div>
                </a>
              </li>
              <li className="mt-4">
                <a href="#" className="text-networks">
                  <div className="d-flex member pl-1">
                    <div className="fix-width">
                      <div className="round-circle-medium bg-grey">
                        <div className="round-circle-sm bg-alternate text-light font-bold flex-center">12</div>
                      </div>
                    </div>
                    <div className="networks-name w-100 text-truncate pr-4">
                      <div className="pl-3">
                        <span className="font-20-bold">Swag Talk</span>
                        <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                      </div>
                      <div className="pl-3">
                        <div className="font-14 text-truncate"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  standard dummy text ever since the 1500s, when ... </div>
                      </div>
                    </div>
                    <div className="align-items-center ml-auto flex-nowrap">
                      <img src={require("assets/images/icons/group_icon.svg")} alt="Doument icon" />
                      <span className="ml-2">3</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </Col> */}
      </Row>
    </div>
  </div>
);
