import React, { Component } from 'react';
import StatisticsOverview from './components/statistics_overview';
import OverviewStatsList from './components/overview_stats_list';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { Utility } from 'utils';
import { MENU, DROPDOWN_ACTIONS, SM_CLIENT_ID } from  'config/constants';
import { updateSelectedMenuItem } from 'shared/actions';
import Menu from 'shared/components/menu';
import { WelcomeOverlays } from 'shared/components/welcome_overlays';

const tableHeaders = {
  registrations: [ 'Name', 'Email' ],
  pledges: [ 'Name', 'Email', 'Story', 'Category' ],
  volunteers: [ 'Name', 'Email', 'Story', 'Beneficiary' ]
};

class StatisticsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedCard : null,
      headers: null
    };

    props.updateSelectedMenuItem(MENU.statistics[0]);
  }

  handleMenuItemClick = (selectedMenuItem, body, selectedCard) => {
    let headers = [];

    this.props.updateSelectedMenuItem(selectedMenuItem);

    if (Utility.isEmpty(selectedCard)) this.setState({ selectedCard: {} });

    if (!Utility.isEmpty(selectedCard)){
      headers = tableHeaders[selectedCard.toLowerCase()];

      this.setState({ data: body.data, selectedCard: selectedCard, headers: headers });
    }

    Utility.showComponent(true, 'statistics-overview');
  }

  componentDidUpdate = () => {
    const list = document.getElementById('menu-list');

    if (list) list.classList.add('d-block');
  }

  render() {
    const { is_super_owner, platform_id } = this.props.userData;
    if (is_super_owner || platform_id !== SM_CLIENT_ID) {
    return (
      <Row className="no-gutters h-100">
        {
          !Utility.isEmpty(this.props.userData) &&
          !Utility.isEmpty(this.props.selectedModule) &&
          <Menu
            selectedModule={this.props.selectedModule}
            clientName={this.props.userData.Client.name}
            userName={`${this.props.userData.first_name} ${this.props.userData.last_name}`}
            dropdownItems={DROPDOWN_ACTIONS.statistics}
            handleDropdownAction={this.handleDropdownAction}
            handleMenuItemClick={this.handleMenuItemClick}
            selectedMenuItemId={this.props.selectedMenuItem.id}
            menus={MENU.statistics}
            isButton= {false}
            isShowButton= {false}
          />
        }
        { !Utility.isEmpty(this.props.userData) &&
          this.props.selectedMenuItem.id === 1 &&
          <StatisticsOverview handleMenuItemClick={this.handleMenuItemClick} />
        }
        {
          Utility.isEmpty(this.props.selectedMenuItem) && !Utility.isEmpty(this.state.selectedCard) &&
            <OverviewStatsList
              body={this.state.data}
              headers={this.state.headers}
              userData={this.props.userData}
              updateSelectedMenuItem={this.props.updateSelectedMenuItem}
              selectedCard={this.state.selectedCard + ' List'}
            />
        }
      </Row>
    );
  }else {
    return (
      <WelcomeOverlays
        heading="Welcome to Statistics!"
        subHeading="Manage real-time data from your digital community experience."
        mainText="Ask for a customized DEMO to learn how all the available features of this module can be used to serve the requirements of your digital application."
        btnText="See example!"
      />
    );
  }
  }
}

const mapActionToProps = { updateSelectedMenuItem };

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedModule: state.selectedModule,
  selectedMenuItem: state.selectedMenuItem
});

export default connect(mapStateToProps, mapActionToProps)(StatisticsContainer);
