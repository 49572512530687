import React, { Component, Fragment } from "react";
import cookie from "react-cookies";
import {
  updateUserData,
  showLoader,
  updateAccountList,
  updateCampaignFormOpen,
  openNeighborhoodForm,
  updateSeeAll,
  updateIsNeighborhoodFormOpen,
  openOrClosePlusIcon,
  updateCampaignSelectedTab,
  updateIsCampSelFormOpen,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";
import { connect } from "react-redux";
import { Utility } from "utils";
import {
  GET_ACCOUNTS,
  USER_AUTH,
  SWITCH_ACCOUNT,
  CLIENT_NOTIFICATIONS,
  API_BASE_URL,
} from "config/constants/api_endpoints";
import {
  ALL_PARENT_CAMPAIGNS,
  GET_USER_TAGS,
  GET_TAGS_BY_CATEGORIES,
  GET_FOLLOWERS_FOLLOWING,
  GROUPS,
  GET_MY_DONATIONS,
  GET_USERS_BY_NETWORKS,
} from "config/constants/api_endpoints";
import { css } from "@emotion/core";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { Dropdown, Image } from "react-bootstrap";
import { NETWORK, CLIENT_ID } from "config/constants/config";
import { NeighborhoodSignUp } from "scenes/users/components/neighborhood_sign_up";
import {
  ROLE_IDS,
  SM_CLIENT_ID,
  CAMPAIGN_TYPES_IDS,
  PASSPORT_TOOL_ID,
} from "config/constants";
import WebNotification from "./web_notification";
import {
  PersonOutlinedIcon,
  AddIcon,
  TickBoldIcon,
  AddBoldSmallIcon,
} from "shared/icons/icons";
import CampaignSelectionForm from "../../scenes/users/components/create_campaign/campaign_selection_form";
import ChatCallout from "shared/components/chat_callout.js";
import { ChevronUpIcon } from "shared/icons/icons";
import EditPassport from "scenes/users/components/pwa/passport/edit_possport";
import Avatar from "react-avatar";
import socket from "utils/sockets.js";
import SideCallout from "shared/components/templates/side_callout";
import { Invite } from "scenes/networks/components/invite.js";
import { InputField } from "shared/components/form_elements";
import { Link } from "react-router-dom";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pwaEnable: false,
      isEdit: false,
      isNeighborhood: false,
      isUserLogedIn: cookie.load("sessionToken") !== undefined ? true : false,
      accountList: {},
      notificationData: {},
      showArticles: false,
      showMyCalling: false,
      showGroups: false,
      showMyEvents: false,
      showMyCourses: false,
      showMyDonations: false,
      showInviteForm: false,
      isEditPassportFormOpen: false,
      showMyFollowersFollowing: false,
      showAllPeople: false,
      myArticles: [],
      myEvents: [],
      myCourses: [],
      myDonations: [],
      groupsData: [],
      passportData: [],
      tagByCategories: [],
      allMembers: [],
      isBageEarnedForPassport: false,
    };

    if (this.state.isUserLogedIn) this.getAllAccount();
  }

  componentDidMount = () => {
    this.enablePwa();

    this.getAllNotifications();

    socket.on("notifications", this.listenForNotifications);

    let lastKnownScrollY = 0;
    let currentScrollY = 0;
    let ticking = false;
    const idOfHeader = "header";
    let eleHeader = null;
    const classes = {
      pinned: "header-pin",
      unpinned: "header-unpin",
    };

    const onScroll = () => {
      currentScrollY = window.pageYOffset;
      requestTick();
    };

    const requestTick = () => {
      if (!ticking) {
        requestAnimationFrame(update);
      }
      ticking = true;
    };

    const update = () => {
      if (currentScrollY < lastKnownScrollY) {
        pin();
      } else if (currentScrollY > lastKnownScrollY && lastKnownScrollY > 100) {
        unpin();
      }
      lastKnownScrollY = currentScrollY;
      ticking = false;
    };

    const pin = () => {
      if (eleHeader.classList.contains(classes.unpinned)) {
        eleHeader.classList.remove(classes.unpinned);
        eleHeader.classList.add(classes.pinned);
      }
    };

    const unpin = () => {
      if (
        eleHeader.classList.contains(classes.pinned) ||
        !eleHeader.classList.contains(classes.unpinned)
      ) {
        eleHeader.classList.remove(classes.pinned);
        eleHeader.classList.add(classes.unpinned);
      }
    };

    if (!Utility.isEmpty(this.props.userData)) {
      this.getMyArticles(this.props.userData);
      this.getMyCourses(this.props.userData);
      this.getAllCategories(this.props.userData);
      this.getMyEvents(this.props.userData);
      this.getMyDonations(this.props.userData);
      this.getGroups();
      this.getAllUsers();
    }

    window.addEventListener("scroll", () => {
      eleHeader = document.getElementById(idOfHeader);
      onScroll();
    });
  };

  componentDidUpdate = (prevState, prevProps) => {
    if (prevState.showInviteForm !== this.state.showInviteForm) {
      if (this.state.showInviteForm || this.state.showMyCalling) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    }

    if (prevProps.isPlusIconClicked !== this.props.isPlusIconClicked) {
      if (this.props.isPlusIconClicked) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    }
  };

  listenForNotifications = (newNotification) => {
    if (!Utility.isEmpty(newNotification)) this.getAllNotifications();
  };

  enablePwa = () => {
    const pwaEnable = Utility.pwaModeEnabled();

    this.setState({ pwaEnable });
  };

  getAllAccount = () => {
    this.props.showLoader(true);

    Utility.sendRequest(GET_ACCOUNTS, 1, {}, (error, response, body) => {
      body = JSON.parse(body);

      this.props.showLoader(false);

      if (!body.error) {
        if (this.props.userData.platform_id === SM_CLIENT_ID) {
          const smData = body;
          smData.Clients = [];
          this.setState({ accountList: smData });
          this.props.updateAccountList(smData);
        } else {
          this.setState({ accountList: body });
          this.props.updateAccountList(body);
        }
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  gotToProfile = () => {
    this.props.history.push("/profile");
  };

  gotToHome = () => {
    if (this.state.pwaEnable) this.showMenu();
    this.props.history.push("/");
  };

  action = (check) => {
    if (check === "Login") {
      this.props.history.push(
        this.state.pwaEnable ? "/sign_in" : "/users/sign_in"
      );
      return;
    }

    this.props.updateUserData({});
    this.props.showLoader(true);
    Utility.clearSessionAndRedirectToHomePage();
  };

  switchAccount = (id, data) => {
    // if ((ROLE_IDS.PARENT_OWNER === data.role_id
    //   && data.client_id !== CLIENT_ID)) {
    //   const url = data.client_source_domain;
    //   url && window.open(url, '_blank');
    // }
    // else {
    this.setState({ isEdit: false });
    this.setState({ isNeighborhood: false });
    this.props.updateIsNeighborhoodFormOpen(false);
    this.props.openNeighborhoodForm({});
    this.props.showLoader(true);

    Utility.sendRequest(SWITCH_ACCOUNT, 1, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "public" });
      } else {
        cookie.save("sessionToken", body.token, { path: "/" });

        Utility.sendRequest(USER_AUTH, 1, { id }, (error, response, body) => {
          body = JSON.parse(body);

          this.props.showLoader(false);

          if (!body.error) {
            this.props.updateUserData(body);
            // if (data.network !== 'Parents') {
            this.props.history.push({
              pathname: "/profile",
              state: { data },
            });

            this.closeDropDown();
            // } else {
            // window.location.reload();
            // }
          } else {
            toast.error(body.error.message, { containerId: "public" });
          }
        });
      }
    });
    // }
  };

  getAllNotifications = () => {
    const params = { ":id": this.props.userData.id };
    const baseURL = Utility.getAPIEndpointWithParams(
      CLIENT_NOTIFICATIONS,
      params
    );

    Utility.sendRequest(baseURL, 1, {}, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.setState({ notificationData: body.data });
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  openNeighborhoodForm = (userData) => {
    const element = document.getElementsByClassName("btn-primary");
    const data = {
      showForm: !userData && true,
      edit: userData ? true : false,
      id: userData && userData.id,
      roleId: userData && userData.role_id,
    };

    if (element) element[0].click();
    this.props.openNeighborhoodForm(data);
    this.props.updateIsNeighborhoodFormOpen(this.state.isNeighborhood);
  };

  editCloseForm = () => {
    this.setState({ isEdit: false });
    this.setState({ isNeighborhood: false });
    this.props.updateIsNeighborhoodFormOpen(false);
    this.props.openNeighborhoodForm({});
  };

  showMenu = () => {
    const body = document.getElementsByTagName("body")[0];

    if (body.classList.contains("overflow-hidden"))
      body.classList.remove("overflow-hidden");
  };

  showBellIndicator = () => {
    let flag = false;
    !Utility.isEmpty(this.state.notificationData) &&
      this.state.notificationData.map((e) => {
        if (!e.is_read) flag = true;
      });
    return flag;
  };

  closeDropDown = () => {
    const elem = document.getElementById("dropdown-basic-header");
    if (elem) elem.click();
  };

  setPlusIcon = () => {
    if (!this.props.isPlusIconClicked) this.props.openOrClosePlusIcon(true);
    else this.props.openOrClosePlusIcon(false);
  };

  handleTabSelectionClick = (arg) => {
    this.props.updateIsCampSelFormOpen(false);
    this.props.updateCampaignSelectedTab(arg);
    this.props.updateCampaignFormOpen(true);
    this.props.openOrClosePlusIcon(false);
  };

  getGroups = () => {
    const params = {
      clientId: CLIENT_ID,
      userClientId: this.props.userData.id,
      forMyGroups: true,
    };

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.setState({ groupsData: body });
      }
    });
  };

  getMyArticles = (userData) => {
    this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
    };

    Utility.sendRequest(
      ALL_PARENT_CAMPAIGNS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);

        if (!body.error) {
          this.setState({ myArticles: body.data });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  getMyCourses = (userData) => {
    this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.courses,
    };

    Utility.sendRequest(
      ALL_PARENT_CAMPAIGNS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);

        if (!body.error) {
          this.setState({ myCourses: body.data });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  getMyEvents = (userData) => {
    this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.event,
    };

    Utility.sendRequest(
      ALL_PARENT_CAMPAIGNS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);

        if (!body.error) {
          this.setState({ myEvents: body.data });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  getMyDonations = (userData) => {
    this.props.showLoader(true);
    const params = {
      platform_id: userData.platform_id,
      user_client_id: userData.id,
    };

    Utility.sendRequest(
      GET_MY_DONATIONS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);

        if (!body.error) {
          this.setState({ myDonations: body });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  getAllCategories = (userData) => {
    const params = {
      client_id: CLIENT_ID,
      tool_id: PASSPORT_TOOL_ID,
      user_client_id: userData.id,
    };

    Utility.sendRequest(GET_USER_TAGS, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.setState({ passportData: body.data }, () => {
          this.state.passportData.map((item) => {
            if (item.Tags.length > 0) {
              this.setState({ isBageEarnedForPassport: true });
            }
          });
        });
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  getTagsByCategories = (id) => {
    const params = {
      client_id: CLIENT_ID,
      tool_id: PASSPORT_TOOL_ID,
      category_id: id,
    };

    Utility.sendRequest(
      GET_TAGS_BY_CATEGORIES,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          this.setState({ tagByCategories: body.data });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };
  openEditPassportForm = (id) => {
    this.getTagsByCategories(id);
    this.setState({ isEditPassportFormOpen: true });
  };

  getAllUsers = (search = "") => {
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      search,
      forCommunity: true,
    };

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        console.log(body.error);
      } else {
        if (body.registrants) this.setState({ allMembers: body.registrants });
      }
    });
  };

  handleMemberSearch = (e) => {
    this.getAllUsers(e.target.value);
  };

  handleChatClick = (item) => {
    this.props.updateIsChatFormOpen(true);
    this.props.updateChatFriend(item);
  };

  HomeIcon() {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.4286 13.7143C20.3715 13.7143 21.1429 14.4869 21.1429 15.4286C21.1429 16.3703 20.3715 17.1429 19.4286 17.1429C18.4858 17.1429 17.7144 16.3703 17.7144 15.4286C17.7144 14.4869 18.4858 13.7143 19.4286 13.7143Z"
          fill="#232323"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.7144 24H17.1429V20.5714C17.1429 19.3097 18.1669 18.2857 19.4287 18.2857C20.6904 18.2857 21.7144 19.3097 21.7144 20.5714V24Z"
          fill="#232323"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5715 13.7143C13.5143 13.7143 14.2857 14.4869 14.2857 15.4286C14.2857 16.3703 13.5143 17.1429 12.5715 17.1429C11.6286 17.1429 10.8572 16.3703 10.8572 15.4286C10.8572 14.4869 11.6286 13.7143 12.5715 13.7143Z"
          fill="#232323"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.8572 24H10.2858V20.5714C10.2858 19.3097 11.3098 18.2857 12.5715 18.2857C13.8332 18.2857 14.8572 19.3097 14.8572 20.5714V24Z"
          fill="#232323"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.71429 13.7143C6.65714 13.7143 7.42857 14.4869 7.42857 15.4286C7.42857 16.3703 6.65714 17.1429 5.71429 17.1429C4.77143 17.1429 4 16.3703 4 15.4286C4 14.4869 4.77143 13.7143 5.71429 13.7143Z"
          fill="#232323"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.00002 24H3.42859V20.5714C3.42859 19.3097 4.45259 18.2857 5.7143 18.2857C6.97602 18.2857 8.00002 19.3097 8.00002 20.5714V24Z"
          fill="#232323"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.2863 12.3214L12.5721 3.74995L1.85663 12.3214L0.429199 10.5362L12.5721 0.821945L24.7138 10.5362L23.2863 12.3214Z"
          fill="#232323"
        />
      </svg>
    );
  }

  EventIcon() {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 12.5C0 5.59476 5.59476 0 12.5 0C19.4052 0 25 5.59476 25 12.5C25 19.4052 19.4052 25 12.5 25C5.59476 25 0 19.4052 0 12.5ZM9.46069 18.8004L18.3317 13.7097C19.1583 13.251 19.1583 12.0565 18.3317 11.5927L9.46069 6.1996C8.65927 5.75605 7.66129 6.33065 7.66129 7.25806V17.7419C7.66129 18.6744 8.66431 19.244 9.46069 18.8004Z"
          fill="#232323"
        />
      </svg>
    );
  }

  MediaIcon() {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.94444 6.94444C7.32798 6.94444 7.63889 6.63353 7.63889 6.25V2.08333C7.63889 1.6998 7.32798 1.38889 6.94444 1.38889C6.56091 1.38889 6.25 1.6998 6.25 2.08333V6.25C6.25 6.63353 6.56091 6.94444 6.94444 6.94444Z"
          fill="#272262"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.625 4.16666C15.625 7.04314 17.9569 9.37499 20.8333 9.37499C21.817 9.37601 22.7806 9.09657 23.6111 8.56944V20.9653C23.6167 21.2931 23.4918 21.6098 23.2639 21.8455C23.0359 22.0812 22.7237 22.2167 22.3958 22.2222H2.60417C2.27632 22.2167 1.96408 22.0812 1.73616 21.8455C1.50824 21.6098 1.38332 21.2931 1.3889 20.9653V5.42361C1.38325 5.08975 1.5129 4.76781 1.74835 4.53105C1.9838 4.2943 2.30502 4.16286 2.6389 4.16666H5.41667V6.24999C5.41667 7.09376 6.10068 7.77777 6.94445 7.77777C7.78822 7.77777 8.47223 7.09376 8.47223 6.24999V4.16666H15.625ZM11.2986 18.2292L7.6389 14.5417L11.2986 18.2292ZM7.6389 14.5417C7.30229 14.1714 7.31284 13.603 7.66294 13.2455L7.6389 14.5417ZM7.66294 13.2455C8.01305 12.888 8.58119 12.8656 8.95834 13.1944L7.66294 13.2455ZM8.95834 13.1944L11.3056 15.5417L8.95834 13.1944ZM11.3056 15.5417L16.6667 10.1805L11.3056 15.5417ZM16.6667 10.1805C16.9061 9.94113 17.255 9.84763 17.5821 9.93526L16.6667 10.1805ZM17.5821 9.93526C17.9092 10.0229 18.1646 10.2784 18.2522 10.6054L17.5821 9.93526ZM18.2522 10.6054C18.3398 10.932 18.2466 11.2805 18.0079 11.5198L18.2522 10.6054Z"
          fill="#232323"
        />
        <circle cx="20.8333" cy="4.16667" r="3.47222" fill="#232323" />
      </svg>
    );
  }

  GroupIcon() {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.68182 14.7413V17.0432C4.27159 17.4435 3.40909 17.9662 3.40909 18.5411C3.40909 19.768 7.31062 20.7666 12.1874 20.8077L12.5 20.809C17.5216 20.809 21.5909 19.7942 21.5909 18.5411C21.5909 17.9965 20.8168 17.4987 19.5363 17.1074L19.3182 17.0432V14.7424C22.7364 15.5509 25 16.9479 25 18.5411C25 21.0472 19.4023 23.0769 12.5 23.0769C5.59659 23.0769 0 21.0472 0 18.5411C0 16.9479 2.26364 15.5509 5.68182 14.7413ZM18.1818 6.80367C19.4364 6.80367 20.4545 7.81969 20.4545 9.07156V13.6073H15.9091V9.07156C15.9091 7.81969 16.9273 6.80367 18.1818 6.80367ZM6.81818 6.80367C8.07273 6.80367 9.09091 7.81969 9.09091 9.07156V13.6073H4.54545V9.07156C4.54545 7.81969 5.56364 6.80367 6.81818 6.80367ZM12.5 4.53578C13.7545 4.53578 14.7727 5.5518 14.7727 6.80367V11.3395H10.2273V6.80367C10.2273 5.5518 11.2455 4.53578 12.5 4.53578ZM18.1818 2.26789C19.1193 2.26789 19.8864 3.03444 19.8864 3.96881C19.8864 4.90318 19.1193 5.66973 18.1818 5.66973C17.2443 5.66973 16.4773 4.90318 16.4773 3.96881C16.4773 3.03444 17.2443 2.26789 18.1818 2.26789ZM6.81818 2.26789C7.75568 2.26789 8.52273 3.03444 8.52273 3.96881C8.52273 4.90318 7.75568 5.66973 6.81818 5.66973C5.88068 5.66973 5.11364 4.90318 5.11364 3.96881C5.11364 3.03444 5.88068 2.26789 6.81818 2.26789ZM12.5 0C13.4375 0 14.2045 0.766547 14.2045 1.70092C14.2045 2.63529 13.4375 3.40184 12.5 3.40184C11.5625 3.40184 10.7955 2.63529 10.7955 1.70092C10.7955 0.766547 11.5625 0 12.5 0Z"
          fill="#232323"
        />
      </svg>
    );
  }

  community() {
    return (
      <svg
        width="29"
        height="22"
        viewBox="0 0 29 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.206 0.0502167C24.6245 0.0502167 25.7851 1.21712 25.7851 2.6395C25.7851 4.06188 24.6245 5.22879 23.206 5.22879C21.7875 5.22879 20.627 4.06188 20.627 2.6395C20.627 1.21712 21.7875 0.0502167 23.206 0.0502167Z"
          fill="black"
        />
        <path
          d="M26.6448 17.3121H19.7673V10.4074C19.7673 8.50164 21.3079 6.95497 23.2061 6.95497C25.1043 6.95497 26.6448 8.50164 26.6448 10.4074V17.3121Z"
          fill="black"
        />
        <path
          d="M14.6092 3.50259C16.0277 3.50259 17.1883 4.6695 17.1883 6.09188C17.1883 7.51426 16.0277 8.68117 14.6092 8.68117C13.1907 8.68117 12.0302 7.51426 12.0302 6.09188C12.0302 4.6695 13.1907 3.50259 14.6092 3.50259Z"
          fill="black"
        />
        <path
          d="M18.0479 20.7645H11.1704V13.8597C11.1704 11.954 12.711 10.4074 14.6091 10.4074C16.5073 10.4074 18.0479 11.954 18.0479 13.8597V20.7645Z"
          fill="black"
        />
        <path
          d="M6.0124 0.0502167C7.43088 0.0502167 8.59145 1.21712 8.59145 2.6395C8.59145 4.06188 7.43088 5.22879 6.0124 5.22879C4.59392 5.22879 3.43335 4.06188 3.43335 2.6395C3.43335 1.21712 4.59392 0.0502167 6.0124 0.0502167Z"
          fill="black"
        />
        <path
          d="M9.45108 17.3121H2.57361V10.4074C2.57361 8.50164 4.11416 6.95497 6.01234 6.95497C7.91053 6.95497 9.45108 8.50164 9.45108 10.4074V17.3121Z"
          fill="black"
        />
        <rect
          x="0.69043"
          y="17.1238"
          width="27.619"
          height="4.14286"
          fill="black"
        />
      </svg>
    );
  }

  render() {
    let loader = "";
    const profileImgUrl = !Utility.isEmpty(this.props.userData)
      ? this.props.userData.profile_image_url
      : "";
    const name = !Utility.isEmpty(this.props.userData)
      ? this.props.userData.user_name
      : "";
    const id = !Utility.isEmpty(this.props.userData.actual_id)
      ? this.props.userData.actual_id
      : "";

    if (this.props.isLoading) {
      loader = (
        <div className="sweet-loading loader">
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={80}
            color={"#0db6ea"}
            loading={true}
          />
        </div>
      );
    }

    return (
      <>
        <header
          id="header"
          className={`header px-lg-4 px-sm-3 p-2 ${
            this.state.activeClass ? "scrolled" : ""
          } ${this.state.pwaEnable ? "" : "bg-white"}`}
        >
          {loader}
          <div
            className="header-logo align-items-center cursor-pointer"
            onClick={this.gotToHome}
          >
            {this.state.pwaEnable ? (
              <Image
                onClick={() =>
                  this.props.updateSeeAll({ isOpen: null, calledFrom: null })
                }
                src={require("assets/images/logos/main-logo-white.png")}
                alt="Home"
              />
            ) : (
              <Image
                onClick={() =>
                  this.props.updateSeeAll({ isOpen: null, calledFrom: null })
                }
                src={require("assets/images/logos/main-logo.png")}
                alt="Home"
              />
            )}
          </div>
          {/* {
            <div className="col-4 ml-5">
              <div className="d-flex justify-content-around">
                <Link
                  onClick={() => {
                    changeLinks("home");
                  }}
                  className={`pwa-footer-link flex-center w-25 flex-column`}
                  to={"/home"}
                >
                  {this.HomeIcon()}
                  <div className="pwa-footer-menu" style={{ top: 7 }}>
                    Home
                  </div>
                </Link>

                <Link
                  onClick={() => {
                    changeLinks("media");
                  }}
                  className={`pwa-footer-link flex-center w-25 flex-column 
                `}
                  to={"/campaigns"}
                >
                  {this.EventIcon()}

                <MediaIcon fillClass="fill-current" /> 
                   <div style={{ top: -3 }} className="pwa-footer-menu">
                    Courses
                  </div> 
                </Link>

                <Link
                  onClick={() =>
                    dispatch(openOrClosePlusIcon(!plusIconClicked))
                  }
                  className={`pwa-footer-link flex-center w-25 flex-column todo-link`}
                  to={"/events"}
                >
                  {this.MediaIcon()}

                  <div style={{ top: -8 }} className="pwa-footer-menu">
                    To Do
                  </div>
                </Link>

                <Link
                to={"/groups"}
                  className={`pwa-footer-link flex-center w-25 flex-column `}
                >
                  {this.GroupIcon()}
                </Link>

                <Link
                  className={`pwa-footer-link flex-center w-25 flex-column `}
                  to={"/groups"}
                  onClick={
                    () => {
                      this.props.updateSeeAll({ isOpen: "community", calledFrom: "community" })
                    }
                  }
                >
                  {this.community()}
                </Link>
              </div>
            </div>
          } */}
          {this.state.pwaEnable && (
            <>
              <div className="ml-auto">
                <WebNotification />
              </div>
              <Dropdown
                className={`${
                  parseInt(this.props.userData.notificationCount) > 0 &&
                  "header-admin-outer"
                }`}
              >
                <Dropdown.Toggle className="header-admin header-admin-dropdown dropdown-toggle">
                  <div
                    className={`${
                      this.showBellIndicator() &&
                      "notification-indicator-profile-icon"
                    } rounded-circle position-absolute`}
                  />
                  {this.state.isUserLogedIn &&
                  !Utility.isEmpty(profileImgUrl) ? (
                    <Image
                      className="profile-width rounded-circle"
                      src={profileImgUrl}
                      alt="Profile Picture"
                      onClick={this.gotToProfile}
                    />
                  ) : (
                    <Avatar
                      name={name}
                      round={true}
                      size={"37"}
                      textSizeRatio={1.75}
                      color="#007bb3"
                      maxInitials={1}
                      onClick={this.gotToProfile}
                    />
                  )}
                  <Image
                    className="pl-2 d-none d-lg-inline"
                    src={require("assets/images/chevron_down_icon.svg")}
                    alt="Options"
                  />
                </Dropdown.Toggle>
              </Dropdown>
            </>
          )}
          {!this.state.pwaEnable && (
            <>
              {(this.props.userData.platform_id !== SM_CLIENT_ID ||
                this.props.userData.is_super_owner === true) && (
                <span
                  onClick={() => this.props.updateCampaignFormOpen(true)}
                  className="mr-40 ml-auto"
                >
                  <AddBoldSmallIcon
                    mainClass="create-circle-icon cursor-pointer"
                    fillClass="fill-primary"
                  />
                </span>
              )}
              <WebNotification />
              <Dropdown className="client-profile-toggle overflow-hidden">
                {this.state.isUserLogedIn && (
                  <>
                    <Dropdown.Toggle id="dropdown-basic-header">
                      <div className="align-items-center">
                        <div className="client-profile-dropdown">
                          {!Utility.isEmpty(profileImgUrl) ? (
                            <Image
                              className="rounded-circle client-profile-dropdown-img"
                              src={
                                !Utility.isEmpty(profileImgUrl)
                                  ? profileImgUrl
                                  : require("assets/images/users/user_no_image.png")
                              }
                              alt="Profile Picture"
                            />
                          ) : (
                            <Avatar
                              name={name}
                              round={true}
                              size={"37"}
                              textSizeRatio={1.75}
                              color="#007bb3"
                              maxInitials={1}
                            />
                          )}

                          <span className="font-bold ml-3">{name}</span>
                        </div>
                        <div className="ml-auto mr-2 toggle-icon">
                          <img
                            src={require("assets/images/icons/chevron_down.svg")}
                            alt="drodown-icon"
                          />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="client-profile-toggle-outer">
                        {Object.keys(NETWORK).map((item, i) => {
                          return (
                            <Fragment key={i}>
                              <div className="d-flex justify-content-between network-type-name pl-1 pr-3">
                                {(!Utility.isEmpty(
                                  this.props.accountList[item]
                                ) ||
                                  (ROLE_IDS.USER ===
                                    this.props.userData.role_id &&
                                    item === "Clients")) && (
                                  <span className="font-12 font-semi-bold px-2 b-600">
                                    {item === "Clients" &&
                                    this.props.userData.platform_id ===
                                      SM_CLIENT_ID
                                      ? ""
                                      : NETWORK[item]}
                                  </span>
                                )}
                                {ROLE_IDS.USER ===
                                  this.props.userData.role_id &&
                                  this.props.userData.platform_id !==
                                    SM_CLIENT_ID &&
                                  item === "Clients" && (
                                    <AddBoldSmallIcon
                                      mainClass="pwa-profile-action-icon"
                                      fillClass="fill-primary"
                                      handleClick={() => {
                                        this.setState(
                                          { isNeighborhood: true },
                                          () => this.openNeighborhoodForm()
                                        );
                                      }}
                                    />
                                  )}
                                {ROLE_IDS.USER ===
                                  this.props.userData.role_id &&
                                  this.props.userData.platform_id ===
                                    SM_CLIENT_ID &&
                                  item === "Parents" && (
                                    <AddBoldSmallIcon
                                      mainClass="pwa-profile-action-icon"
                                      fillClass="fill-primary"
                                      handleClick={() =>
                                        this.openNeighborhoodForm()
                                      }
                                    />
                                  )}
                              </div>

                              {ROLE_IDS.USER === this.props.userData.role_id &&
                                this.props.userData.platform_id !==
                                  SM_CLIENT_ID &&
                                Utility.isEmpty(this.props.accountList[item]) &&
                                item === "Clients" && (
                                  <Dropdown.Item
                                    className="client-profile-toggle-item align-items-center"
                                    onClick={() => {
                                      this.setState(
                                        { isNeighborhood: true },
                                        () => this.openNeighborhoodForm()
                                      );
                                    }}
                                  >
                                    <AddIcon mainClass="mr-3 ml-q4" />
                                    <span className="font-medium display-8">
                                      Create a Neighborhood Account
                                    </span>
                                  </Dropdown.Item>
                                )}
                              {ROLE_IDS.USER === this.props.userData.role_id &&
                                this.props.userData.platform_id ===
                                  SM_CLIENT_ID &&
                                Utility.isEmpty(this.props.accountList[item]) &&
                                item === "Parents" && (
                                  <Dropdown.Item
                                    className="client-profile-toggle-item align-items-center"
                                    onClick={() => this.openNeighborhoodForm()}
                                  >
                                    <AddIcon mainClass="mr-3 ml-4" />
                                    <span className="font-medium display-8">
                                      Create a City Account
                                    </span>
                                  </Dropdown.Item>
                                )}

                              {!Utility.isEmpty(this.props.accountList) &&
                                this.props.accountList[item].map(
                                  (data, index) => {
                                    return (
                                      <div
                                        onClick={() =>
                                          this.switchAccount(data.id, data)
                                        }
                                        className={`client-profile-toggle-item ${
                                          id !== data.id ? "" : "active"
                                        }`}
                                        key={index}
                                      >
                                        <div className="align-items-center">
                                          <div className="client-profile-dropdown">
                                            <TickBoldIcon
                                              fillClass="fill-primary"
                                              strokeClass="stroke-primary"
                                              mainClass={
                                                id !== data.id
                                                  ? "visibility-hidden"
                                                  : ""
                                              }
                                            />
                                            {!Utility.isEmpty(
                                              data.profile_image_url
                                            ) ? (
                                              <Image
                                                className="rounded-circle client-profile-dropdown-img img-border ml-2"
                                                src={
                                                  !Utility.isEmpty(
                                                    data.profile_image_url
                                                  )
                                                    ? data.profile_image_url
                                                    : require("assets/images/users/user_no_image.png")
                                                }
                                                alt="Profile Picture"
                                              />
                                            ) : (
                                              <Avatar
                                                name={data.user_name}
                                                round={true}
                                                size={"39"}
                                                textSizeRatio={1.75}
                                                color="#007bb3"
                                                maxInitials={1}
                                                className="ml-1"
                                              />
                                            )}
                                            <span
                                              className={`ml-3 text-capitalize ${
                                                id !== data.id
                                                  ? "font-medium b-500"
                                                  : "font-bold"
                                              }`}
                                            >
                                              {data.user_name}
                                            </span>
                                          </div>
                                          <div className="ml-auto mr-2">
                                            <img
                                              src={require("assets/images/icons/menu_vertical_icon.svg")}
                                              className={`client-profile-more-icon px-2 ${
                                                id !== data.id
                                                  ? "visibility-hidden"
                                                  : ""
                                              }`}
                                              alt="menu-icon"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({ isEdit: true });
                                                this.openNeighborhoodForm(data);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </Fragment>
                          );
                        })}
                      </div>
                      <Dropdown.Item
                        className="font-medium b-500 mt-auto client-profile-toggle-item align-items-center px-3 mb-0"
                        onClick={() => this.action("Logout")}
                      >
                        <PersonOutlinedIcon mainClass="mr-4 ml-2" />
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                )}
                {!this.state.isUserLogedIn && (
                  <Dropdown.Item
                    className="p-3"
                    onClick={() => this.action("Login")}
                  >
                    <Image
                      className="mr-2 header-dropdown-icon"
                      src={require("assets/images/icons/logout_icon.svg")}
                      alt="Logout"
                    />
                    Login
                  </Dropdown.Item>
                )}
              </Dropdown>
            </>
          )}
        </header>
        {this.state.isEdit && (
          <div className="header-NeighborhoodSignUp-callout">
            <NeighborhoodSignUp
              userData={this.props.userData}
              history={this.props.history}
              neighborhoodState={this.props.neighborhoodState}
              edit={this.state.isEdit}
              id={this.props.neighborhoodState.id}
              closeForm={this.editCloseForm}
              showLoader={this.props.showLoader}
              updateAccountList={this.props.updateAccountList}
              updateUserData={this.props.updateUserData}
            />
          </div>
        )}
        {this.props.isNeighborhoodFormOpen && this.state.isNeighborhood && (
          <div className="header-NeighborhoodSignUp-callout">
            <NeighborhoodSignUp
              userData={this.props.userData}
              history={this.props.history}
              neighborhoodState={this.props.neighborhoodState}
              id={this.props.neighborhoodState.id}
              edit={false}
              openNeighborhoodForm={this.props.openNeighborhoodForm}
              isNeighborhoodFormOpen={this.props.isNeighborhoodFormOpen}
              closeForm={this.editCloseForm}
              showLoader={this.props.showLoader}
              updateAccountList={this.props.updateAccountList}
              updateUserData={this.props.updateUserData}
            />
          </div>
        )}
        {!this.state.pwaEnable && this.props.isCampaignFormOpen && (
          <CampaignSelectionForm
            position="position-fixed create-campaign-margin"
            userData={this.props.userData}
          />
        )}
        {this.props.isChatFormOpen && (
          <div className="header-NeighborhoodSignUp-callout">
            <ChatCallout key={this.state.toolboxMessageId} />
          </div>
        )}
        {this.state.showInviteForm && (
          <div className="pwa-invite-callout shar-form search-form">
            <Invite
              onClose={() => {
                this.props.openOrClosePlusIcon(false);
                this.setState({ showInviteForm: false });
              }}
              userData={this.props.userData}
              fromHomePage={true}
              changeLoader={(val) => this.props.showLoader(val)}
            />
          </div>
        )}

        {Utility.pwaModeEnabled() && this.state.showMyCalling && (
          <>
            <SideCallout
              fromPlusIcon={true}
              closeCallOut={() => {
                this.setState({ showMyCalling: false });
                this.props.openOrClosePlusIcon(false);
              }}
              position="position-absolute gil-pwa-callout gil-conversation-callout"
            >
              <div className="px-3">
                <h1 className="d-flex align-items-center mb-4">
                  <span className="font-weight-bold display-7 flex-auto ml-2">
                    My Calling
                  </span>
                </h1>
                <div className="d-flex flex-wrap mb-42">
                  {!Utility.isEmpty(this.state.passportData) &&
                    this.state.passportData.map((item, i) => {
                      return (
                        <div
                          className="passport-info-outer"
                          key={i}
                          onClick={() =>
                            this.openEditPassportForm(item && item.id)
                          }
                        >
                          <div className="display-8 font-bold mb-4">
                            {item && item.name}
                          </div>
                          <div className="d-flex flex-wrap">
                            {item &&
                              !Utility.isEmpty(item.Tags) &&
                              item.Tags.map((tag, i) => {
                                return (
                                  <div
                                    className="passport-pill bg-primary text-white font-14 font-bold px-4 mb-2 mr-2"
                                    key={i}
                                  >
                                    {tag.tag_name}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </SideCallout>
          </>
        )}

        {Utility.pwaModeEnabled() &&
          this.state.isEditPassportFormOpen &&
          !Utility.isEmpty(this.state.tagByCategories) && (
            <SideCallout
              closeCallOut={() =>
                this.setState({ isEditPassportFormOpen: false })
              }
              calloutHeading=""
              position="position-fixed gil-pwa-callout gil-pwa-callout-passport"
              calloutHeadingImg="assets/images/profile/passport-icon.svg"
              calloutCloseIcon={<ChevronUpIcon />}
            >
              <EditPassport
                tagByCategories={this.state.tagByCategories}
                passportData={this.state.passportData}
                closeCallOut={() =>
                  this.setState({ isEditPassportFormOpen: false })
                }
                userData={this.props.userData}
                getAllCategories={() =>
                  this.getAllCategories(this.props.userData)
                }
              />
            </SideCallout>
          )}

        {Utility.pwaModeEnabled() && this.state.showAllPeople && (
          <>
            <SideCallout
              fromPlusIcon={true}
              closeCallOut={() => {
                this.setState({ showAllPeople: false });
                this.props.openOrClosePlusIcon(false);
              }}
              position="position-absolute gil-pwa-callout gil-conversation-callout"
            >
              <div className="pwa-callout-body">
                <div className="d-flex align-items-center flex-nowrap mb-4">
                  <div
                    style={{ minWidth: 60 }}
                    className="pencil-icon-outer flex-center"
                  >
                    <PencilIcon color="#272262" />
                  </div>
                  <h5 className="font-weight-bold display-7 text-primary">
                    Select a person to start a conversation.
                  </h5>
                </div>
                <h6 className="d-none">Community</h6>
                <div className="position-relative shar-form search-form search-form-pwa sidebar-form px-2 px-lg-0 pwa-search-outer">
                  <InputField
                    required={true}
                    type="text"
                    placeholder="Search"
                    handleChange={this.handleMemberSearch}
                  />
                  <img
                    style={{ right: 30 }}
                    className="pwa-chat-icon position-absolute"
                    src={require("assets/images/icons/search-icon.png")}
                    alt=""
                  />
                </div>
                {!Utility.isEmpty(this.state.allMembers)
                  ? this.state.allMembers.map((items, index) => {
                      return (
                        <>
                          <div key={index} className="pwa-chat-view">
                            <div className="pwa-chat-unit align-items-center flex-nowrap position-relative">
                              {!Utility.isEmpty(items.profile_image_url) ? (
                                <img
                                  className="pcu-img rounded-circle"
                                  src={items.profile_image_url}
                                  alt=""
                                />
                              ) : (
                                <Avatar
                                  name={items.user_name}
                                  round={true}
                                  size={"60"}
                                  textSizeRatio={1.75}
                                  color="#007bb3"
                                  maxInitials={1}
                                />
                              )}
                              <div
                                onClick={() => {
                                  this.setState({ showAllPeople: false });
                                  this.handleChatClick(items);
                                  this.props.openOrClosePlusIcon(false);
                                }}
                                className="pcu-text-outer ml-2"
                              >
                                <div className="font-weight-bold text-truncate display-8">
                                  {items.user_name}
                                </div>
                                <div className="text-truncate font-14 b-500">
                                  {!Utility.isEmpty(items.phone_cellular)
                                    ? items.phone_cellular
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : Utility.pwaModeEnabled() &&
                    Utility.isEmpty(this.state.allMembers) && (
                      <div className="font-weight-bold display-7 my-4 text-center text-muted">
                        No Result Found
                      </div>
                    )}
              </div>
            </SideCallout>
          </>
        )}

        {this.props.isPlusIconClicked &&
          this.state.pwaEnable &&
          !this.state.showAllArtcles &&
          !this.state.showMyCalling &&
          !this.state.showGroups &&
          !this.state.showMyEvents &&
          !this.state.showMyCourses &&
          !this.state.showMyDonations &&
          !this.state.showInviteForm && (
            <SideCallout
              fromPlusIcon={true}
              closeCallOut={() => this.props.openOrClosePlusIcon(false)}
              position="position-absolute gil-pwa-callout gil-pwa-callout-slideMore pwa-todo-callout"
            >
              <div className="mt-n4 mx-n3">
                <div className="font-weight-bold display-7 flex-center pb-4 border-bottom">
                  <AddIcon mainClass="mr-3" />
                  What do you want to do?
                </div>
                <div
                  onClick={() => {
                    this.setState({ showMyCalling: true });
                  }}
                  className="border-bottom py-3 align-items-center px-5"
                >
                  <img
                    className="ml-2"
                    src={
                      this.state.isBageEarnedForPassport
                        ? require("assets/images/profile/badges/gm-badge.svg")
                        : require("assets/images/profile/badges/empty-badge.svg")
                    }
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">Manage Passport</div>
                </div>
                <div
                  onClick={() => this.handleTabSelectionClick("group")}
                  className="border-bottom py-3 align-items-center px-5"
                >
                  <img
                    className="ml-2"
                    src={
                      this.state.groupsData.length > 0
                        ? require("assets/images/profile/badges/gm-badge.svg")
                        : require("assets/images/profile/badges/empty-badge.svg")
                    }
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">Create Group</div>
                </div>
                <div
                  onClick={() =>
                    this.handleTabSelectionClick(CAMPAIGN_TYPES_IDS.event)
                  }
                  className="border-bottom py-3 align-items-center px-5"
                >
                  <img
                    className="ml-2"
                    src={
                      this.state.myEvents.length > 0
                        ? require("assets/images/profile/badges/gm-badge.svg")
                        : require("assets/images/profile/badges/empty-badge.svg")
                    }
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">Add Event</div>
                </div>
                <div className="border-bottom py-3 align-items-center px-5">
                  <img
                    className="ml-2"
                    src={
                      this.state.myCourses.length > 0
                        ? require("assets/images/profile/badges/gm-badge.svg")
                        : require("assets/images/profile/badges/empty-badge.svg")
                    }
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">Setup Course</div>
                </div>
                <div
                  onClick={() =>
                    this.handleTabSelectionClick(CAMPAIGN_TYPES_IDS.story)
                  }
                  className="border-bottom py-3 align-items-center px-5"
                >
                  <img
                    className="ml-2"
                    src={
                      this.state.myArticles.length > 0
                        ? require("assets/images/profile/badges/gm-badge.svg")
                        : require("assets/images/profile/badges/empty-badge.svg")
                    }
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">Share Article</div>
                </div>
                <div className="border-bottom py-3 align-items-center px-5">
                  <img
                    className="ml-2"
                    src={require("assets/images/profile/badges/empty-badge.svg")}
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">Give Testimony</div>
                </div>
                <div
                  onClick={() => this.setState({ showInviteForm: true })}
                  className="border-bottom py-3 align-items-center px-5"
                >
                  <img
                    className="ml-2"
                    src={require("assets/images/profile/badges/empty-badge.svg")}
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">Invite Friends</div>
                </div>
                <div
                  onClick={() => this.setState({ showAllPeople: true })}
                  className="border-bottom py-3 align-items-center px-5"
                >
                  <img
                    className="ml-2"
                    src={require("assets/images/profile/badges/empty-badge.svg")}
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">
                    Start new Conversation
                  </div>
                </div>
                <div className="border-bottom py-3 align-items-center px-5">
                  <img
                    className="ml-2"
                    src={
                      this.state.myDonations.length > 0
                        ? require("assets/images/profile/badges/gm-badge.svg")
                        : require("assets/images/profile/badges/empty-badge.svg")
                    }
                    style={{ width: 25 }}
                    alt=""
                  />
                  <div className="display-7 ml-3 b-500">Donate</div>
                </div>
              </div>
            </SideCallout>
          )}
      </>
    );
  }
}

const AddWhiteIcon = (props) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="PWA-2021"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="5.0-Home---Get-Started"
          transform="translate(-266.000000, -74.000000)"
          fill="#FFFFFF"
          stroke="#FFFFFF"
        >
          <g id="Group-2">
            <g
              id="arrow/chevron/up"
              transform="translate(276.000000, 84.000000) rotate(45.000000) translate(-276.000000, -84.000000) translate(268.000000, 76.000000)"
            >
              <g id="Close-Icon" transform="translate(0.500000, 0.500000)">
                <polygon
                  id="Close"
                  points="14.7558333 1.42208333 13.5775 0.24375 7.5 6.32125 1.4225 0.24375 0.244166667 1.42208333 6.32166667 7.49958333 0.244166667 13.5770833 1.4225 14.7554167 7.5 8.67875 13.5775 14.7554167 14.7558333 13.5770833 8.67833333 7.49958333"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const PencilIcon = (props) => {
  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26">
      <g
        id="PWA-2021"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="5.1A"
          transform="translate(-34.000000, -190.000000)"
          fill={props.color}
        >
          <g id="Group-5" transform="translate(-1.000000, 143.000000)">
            <g
              id="Icons/Start-Message"
              transform="translate(35.142857, 47.142857)"
            >
              <path d="M22.381907,3.33487342 C23.3641352,4.31836539 23.3641352,6.03336968 22.381907,7.01686165 L21.4575325,7.94250115 L17.7728909,4.25922731 L18.6998367,3.33487342 C19.6833505,2.35266706 21.3983931,2.35138145 22.381907,3.33487342 L22.381907,3.33487342 Z M3.05489483,22.6601692 L3.81985007,19.6081301 L6.10700198,21.8978022 L3.05489483,22.6601692 Z M15.9562829,6.07836605 L19.6370675,9.75906866 L8.621712,20.7741787 L4.94092734,17.0934761 L15.9562829,6.07836605 Z M24.1972293,1.51573468 C23.2201436,0.537385154 21.9203625,2.62900812e-13 20.5370149,2.62900812e-13 C19.1562385,2.62900812e-13 17.8551718,0.537385154 16.8780861,1.51573468 L2.21022996,16.1819783 C2.21022996,16.1819783 2.20894432,16.1858351 2.20765868,16.1871207 C2.10352192,16.2925408 1.92481808,16.5702326 1.87210688,16.7797871 L0.0387855705,24.1141946 C-0.0717793894,24.5513021 0.0580701566,25.0154075 0.376908646,25.3342389 L0.378194285,25.3355245 L0.380765563,25.3368101 C0.699604052,25.6556415 1.16243412,25.7854881 1.60083704,25.6762112 L8.93412229,23.8416452 C9.14368146,23.7889351 9.42266514,23.6102353 9.5268019,23.5061008 C9.52808754,23.5048152 9.53194446,23.5035296 9.53194446,23.5035296 L24.1998006,8.83600039 C25.1768863,7.85893647 25.7155691,6.55918434 25.7142834,5.17715314 C25.7142834,3.79383634 25.1768863,2.49536982 24.1998006,1.51702029 L24.1972293,1.51573468 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const mapActionToProps = {
  showLoader,
  updateUserData,
  updateIsNeighborhoodFormOpen,
  updateAccountList,
  updateCampaignFormOpen,
  openNeighborhoodForm,
  updateSeeAll,
  openOrClosePlusIcon,
  updateCampaignSelectedTab,
  updateIsCampSelFormOpen,
  updateChatFriend,
  updateIsChatFormOpen,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  isNeighborhoodFormOpen: state.isNeighborhoodFormOpen,
  isLoading: state.isLoading,
  neighborhoodState: state.neighborhoodState,
  isChatFormOpen: state.isChatFormOpen,
  accountList: state.accountList,
  isCampaignFormOpen: state.isCampaignFormOpen,
  isPlusIconClicked: state.isPlusIconClicked,
});

export default connect(mapStateToProps, mapActionToProps)(Header);