import React, { Component } from 'react';
import PasswordValidationFrame from 'shared/components/password_validation_frame';
import VerifyUser from './verifyUser';
import { Form, Image, Container, Spinner, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { InputField } from 'shared/components/form_elements';
import { Utility } from 'utils';
import { SIGN_UP, VERIFY_ACTIVATION_CODE, RESEND_CONFIRMATION_LINK } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import CloseToast from 'shared/components/close_toast';

const validateFields = ['email', 'password', 'first_name', 'last_name'];

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      showLoader: false,
      isUserRegistered: false
    };
  }

  handleChange = (e) => {
    let formData = this.state.formData;
    let password = {}, showPasswordValidation = false;

    formData = Utility.validateAndReturnFormData(this, formData, e, validateFields);
    if (!Utility.isEmpty(formData.password)) {
      password = Utility.validatePassword(formData.password);
      showPasswordValidation = true;
    }

    this.setState({ formData, showPasswordValidation, password });
  }

  submit = (e) => {
    e.preventDefault();
    const formData = Utility.deepClone(this.state.formData);

    if (!Utility.showFormErrors(this, validateFields)) return;

    if (!Utility.isEmpty(this.state.password)) {
      const validatePassword = Object.values(this.state.password).map((value) => {
        return value;
      });
      if (validatePassword.includes(false)) return;
    }

    this.setState({ showLoader: true });

    this.signUp(formData);
  }

  signUp = (formData) => {
    formData.client_id = CLIENT_ID;
    formData.source = 'web';

    Utility.sendRequest(SIGN_UP, 2, formData, (error, response, body) => {
      body = JSON.parse(body);

      this.setState({ showLoader: false });

      if (!body.error) {
        const verifyCodeParams = {
          email: formData.email,
          client_id: CLIENT_ID,
          is_token_require: true
        };
        const resendVerificationParams = {
          email: formData.email,
          client_id: CLIENT_ID,
        };
        this.setState({ isUserRegistered: true, verifyCodeParams, resendVerificationParams });
      } else {
        this.setState({ showLoader: false });
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  onLogoClick = () => {
    this.props.history.push('/');
  }

  render() {
    return(
      <Container fluid="true" className="h-md-100 px-0 scroll-y" >
      {
        !this.state.isUserRegistered &&
        <div className="container">
          <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3">
            <Image src={require("assets/images/logos/main-logo.png")} className="register-logo mb-4 cursor-pointer" alt="sharemeister" onClick={this.onLogoClick}/>
            <h3 className="font-bold" >It's all about you!</h3>
            <p className="my-4 display-7" >Basic Info needed to get started.</p>
            <Form>
              <div className="new-landing-theme register-form" >
                <Form.Group>
                  <InputField
                    required={true}
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    ref="first_name"
                    handleChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <InputField
                    required={true}
                    placeholder="Last Name"
                    type="text"
                    name="last_name"
                    ref="last_name"
                    handleChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <InputField
                    required={true}
                    type="email"
                    placeholder="Email"
                    name="email"
                    ref="email"
                    handleChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group>
                <InputField
                  required={true}
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="password"
                  handleChange={this.handleChange}
                />
                {
                  this.state.showPasswordValidation &&
                  <PasswordValidationFrame password={this.state.password}/>
                }
                </Form.Group>
              </div>
              <p className="form-element-outer mx-auto display-8 mt-4 pt-3" >By selecting "Get Started" you agree to our <Link to="/terms_of_services" target="_blank" className="text-primary">Terms of Service </Link> and <Link to="/privacy_policy" target="_blank" className="text-primary">Privacy Policy </Link></p>
              <Link to="/users/sign_in" className="">Already have an account?</Link>
              <div className="text-right mt-5" >
                <Button
                  type="submit"
                  className="btn btn-primary btn-login"
                  onClick={(e) => this.submit(e)}
                >
                {
                  this.state.showLoader
                  ? <Spinner animation="border" role="status"/>
                  : "Get Started"
                }
                </Button>
              </div>
            </Form>
          </div>
        </div>
      }
        {
          this.state.isUserRegistered &&
          <VerifyUser
            email={this.state.formData.email}
            platform={this.state.formData.email}
            history={this.props.history}
            showLogo={true}
            verifyCodeAPIName={VERIFY_ACTIVATION_CODE}
            resendActivationCodeAPI={RESEND_CONFIRMATION_LINK}
            showScreen={false}
            verifyCodeParams={this.state.verifyCodeParams}
            resendVerificationParams={this.state.resendVerificationParams}
          />
        }
      </Container>
    );
  }
}

export default SignUp;
