import React, { Component } from "react";
import Menu from "shared/components/menu";
import ItemDetail from "shared/components/templates/item_detail/item_detail";
import TableList from "shared/components/templates/table_list";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import {
  DROPDOWN_ACTIONS,
  SEARCH_MINIMUM_LENGTH,
  SM_CLIENT_ID,
  ETHOS_CLIENT_ID,
  AHURA_CLIENT_ID,
  CAMPAIGN_TYPES_IDS,
  ROLE_IDS
} from "config/constants";
import { Utility } from "utils";
import {
  showLoader,
  updateSelectedMenuItem,
  updateSelectedModuleMenu,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";
import { Invite } from "./components/invite";
import {
  GET_USERS_BY_NETWORKS,
  GET_NETWORK_MENU,
  UPDATE_ADMIN_ROLE,
  CREATE_CATEGORY,
  SCHOOLS,
  TOPIC_CATEGORIES,
  UPDATE_USER_CATEGORY,
  USER_ACTIVATE_DEACTIVATE,
  GET_USERS_OF_NEIGHBORHOOD,
  REMOVE_USERS_FROM_NEIGHBORHOOD,
  ALTER_ADMIN_STATUS_IN_NEIGHBORHOOD,
  GET_FOLLOWERS_FOLLOWING,
  UPDATE_USER,
  USERS,
} from "config/constants/api_endpoints";
import { NETWORK_TYPES, CLIENT_NAME } from "config/constants/config";
import CategoryFormContainer from "shared/components/catagory_form_container";
import CreateCustomGroupContainer from "../../shared/components/customGroup_form_container";
import ChatCallout from "shared/components/chat_callout.js";
import { WelcomeOverlays } from "shared/components/welcome_overlays";
import { TickSuccess } from "../../shared/icons/icons";
import { DEFAULT_SCHOOL_ID } from "config/constants/config";
import axios from 'axios';

class NetworkContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    props.showLoader(Utility.isEmpty(props.userData));

    this.state = {
      showInviteForm: false,
      menus: null,
      subMenus: null,
      userDetails: null,
      selectedMenuItemName: null,
      selectedNetworkUser: null,
      changeAdminValue: null,
      tableTitles: this.getTableTitles(),
      showFilterComponent: true,
      selectedMenuItem: "",
      isUpdateCategory: false,
      isUpdateCustomGroup: false,
      formData: null,
      isSearchEmpty: false,
      categoriesData: [],
      newUserDetails: null,
      licensesCount: 0,
      max_licenses: 0,
      subCategoriesData: [],
      filteredUserDetails: [],
      SearchText:"",
      selectedViewData: {},
    };

    this.props.updateSelectedMenuItem({});
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.getMenuDetails();
    this.getAllCategories();
    this.getAllSubCategories();
  };

  componentDidUpdate = (prevProps) => {
    Utility.showComponent(true, "menu-list");

    if (prevProps.selectedModuleMenu !== this.props.selectedModuleMenu) {
      this.setState({ menus: this.props.selectedModuleMenu });
    }
  };

  componentWillUnmount = () => (this._isMounted = false);

  getNetworkType = (menuName) => {
    menuName = menuName.trim();

    if (menuName === "All Registrants") {
      menuName = "all_registrants";
    }

    if (menuName === "All Applications") {
      menuName = "all_applications";
    }

    if (!(menuName === "All Registrants")) {
      Object.entries(NETWORK_TYPES).forEach(([key, value]) => {
        if (value === menuName) menuName = key;
      });
    }

    this.setState({ selectedNetworkUser: menuName });
  };

  handleMenuItemClick = (selectedMenuItem) => {
    this.props.updateSelectedMenuItem(selectedMenuItem);
    this.getNetworkType(selectedMenuItem.menu_name);
    if (
      this.props.userData.role === "CLIENT_OWNER" ||
      this.props.userData.role === "CLIENT_ADMIN"
    ) {
      this.setState(
        {
          showInviteForm: false,
          showCategoryform: false,
          selectedMenuItemName: selectedMenuItem.menu_name,
          selectedMenuItem,
          showFilterComponent: false,
        },
        selectedMenuItem.menu_name === "All Registrants"
          ? () => {
              this.getUserDetails();
            }
          : selectedMenuItem.menu_name === "Following"
          ? () => this.getUserFollowing()
          : () => {
              this.getUserFollowers();
            }
      );
    } else {
      this.setState(
        {
          SearchText : "",
          showInviteForm: false,
          showCategoryform: false,
          selectedMenuItemName: selectedMenuItem.menu_name,
          selectedMenuItem,
          showFilterComponent: false,
          selectedViewData:{}
        },
        () => {
          this.getUserDetails();
        }
      );
    }
  };

  closeInviteForm = () => {
    this.setState({ showInviteForm: false }, () => {
      this.getUserDetails();
    });
  };

  handleDropdownAction = () => {
    this.props.updateSelectedMenuItem({});

    this.setState(
      { showInviteForm: true, userDetails: false, showCategoryform: false },
      () => {
        Utility.showComponent(true, "network-invitation");
      }
    );
  };

  closeSummaryForm = () => {
    this.setState({ showCategoryform: false }, () => {
      this.getUserDetails();
    });
  };

  closeCustomGroupForm = () => {
    this.setState({ showCustomGroupform: false }, () => {
      this.getUserDetails();
    });
  };

  handleCategoryFormClicked = () => {
    this.setState({
      showCategoryform: true,
      userDetails: false,
      showInviteForm: false,
      isUpdateCategory: false,
      formData: false,
    });
  };

  handleCustomGroupFormClicked = () => {
    this.setState({
      showCustomGroupform: true,
      userDetails: false,
      showInviteForm: false,
      isUpdateCustomGroup: false,
      formData: false,
    });
  };

  handleListItemAction = (id, list) => {
    if (list === 2) {
      Utility.sendRequest(
        CREATE_CATEGORY + "/" + id,
        1,
        {},
        (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            this.setState({
              userDetails: false,
              showCategoryform: true,
              isUpdateCategory: true,
              showInviteForm: false,
              formData: body,
            });
          }
        }
      );
    }

    if (list === 1) {
      Utility.sendRequest(
        CREATE_CATEGORY + "/" + id,
        4,
        {},
        (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            const menus = { ...this.state.menus };
            menus.categories = menus.categories.filter((item) => {
              if (!(item.id === id)) return item;
            });

            const name = menus.registrants[0].menu_name;
            toast.success(body.message, { containerId: "private" });
            this.props.updateSelectedMenuItem(menus.registrants[0]);
            this.setState(
              {
                menus,
                selectedMenuItem: menus.registrants[0],
                selectedMenuItemName: name,
                selectedNetworkUser: name.split(" ")[0].toLowerCase(),
              },
              () => {
                this.getUserDetails();
              }
            );
          }
        }
      );
    }
  };

  handleCustomGroupItemAction = (id, list) => {
    if (list === 2) {
      Utility.sendRequest(SCHOOLS + "/" + id, 1, {}, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({
            userDetails: false,
            showCustomGroupform: true,
            isUpdateCustomGroup: true,
            showInviteForm: false,
            formData: body,
          });
        }
      });
    }

    if (list === 1) {
      Utility.sendRequest(SCHOOLS + "/" + id, 4, {}, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          const menus = { ...this.state.menus };
          menus.custom_groups = menus.custom_groups.filter((item) => {
            if (!(item.id === id)) return item;
          });

          const name = menus.registrants[0].menu_name;
          toast.success(body.message, { containerId: "private" });
          this.props.updateSelectedMenuItem(menus.registrants[0]);
          this.setState(
            {
              menus,
              selectedMenuItem: menus.registrants[0],
              selectedMenuItemName: name,
              selectedNetworkUser: name.split(" ")[0].toLowerCase(),
            },
            () => {
              this.getUserDetails();
            }
          );
        }
      });
    }
  };

  getUsersOfNeighborhood = () => {
    const params = {
      platform_id: CLIENT_ID, //ahura
      client_id: this.props.userData.client_id, //neighborhood
    };
    this.setState({ showLoader: true });
    Utility.sendRequest(
      GET_USERS_OF_NEIGHBORHOOD,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        this.setState({ showLoader: false });
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          const menus = { ...this.state.menus };
          menus.registrants[0].count = body.data.length;
          this.setState({
            newUserDetails: body.data,
            showFilterComponent: true,
            menus: menus,
            licensesCount: body.data.length,
            max_licenses: body.max_licenses,
          },()=>this.getUserExtensionData(body));
        }
      }
    );
  };

  getUserExtensionData = async () =>{
    if(CLIENT_ID === AHURA_CLIENT_ID && ROLE_IDS.CLIENT_OWNER === this.props.userData.role_id){
      const data = JSON.parse(JSON.stringify(this.state.newUserDetails));
      if(Array.isArray(data) && data.length > 0){
        for(let i=0; i<data.length; i++){
          if(data[i].external_id === null || data[i].external_id === undefined){
            data[i].sessions = "-";
            data[i].study_hours = "-";
            data[i].topics = "-";
          }
          else{
            const response = await axios.get(`https://api.ahura.ai/users/${data[i].external_id}/user_sessions_info`);
            try{
              data[i].sessions = response.data.data.total_sessions;
              data[i].study_hours = response.data.data.total_study_time;
              data[i].topics = response.data.data.last_two_topics;
            }
            catch(err){
              data[i].sessions = "-";
              data[i].study_hours = "-";
              data[i].topics = "-";
            }
          }
        }
      }
        this.setState({
          newUserDetails: data,
        })
    }
  }

  //function to remove user from neighbourhood which is called in table_rows
  removeUserFromNeighborhood = (member_user_id, user_external_id, client_id) => {
    const params = {
      platform_id: CLIENT_ID,
      client_id: client_id ? client_id : this.props.userData.client_id, //id of neighborhood
      user_id: member_user_id, //id of member in userhood
      external_id: user_external_id
    };
    this.setState({ showLoader: true });
    Utility.sendRequest(
      REMOVE_USERS_FROM_NEIGHBORHOOD,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        this.setState({ showLoader: false });
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if(this.state.selectedViewData.active === "NeighborhoodUsers")
            this.getMembersOfNeighborhood();
          else
            this.getUserDetails();
        }
      }
    );
  };
  //adminStatusApi
  alterAdminStatusInNeighborhood = (member_user_id, admin_status, client_id) => {
    const params = {
      platform_id: CLIENT_ID,
      client_id: client_id ? client_id : this.props.userData.client_id, //id of neighborhood
      user_id: member_user_id, //id of member in userhood
      is_admin: !admin_status,
    };

    this.setState({ showLoader: true });
    Utility.sendRequest(
      ALTER_ADMIN_STATUS_IN_NEIGHBORHOOD,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        this.setState({ showLoader: false });
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if(this.state.selectedViewData.active === "NeighborhoodUsers")
            this.getMembersOfNeighborhood();
          else
            this.getUserDetails();
        }
      }
    );
  };
  //get Followers of neighborhood
  getUserFollowers = () => {
    const params = {
      userClientId: this.props.userData.id,
      type: "follower",
      clientId: CLIENT_ID,
    };
    this.setState({ showLoader: true });

    Utility.sendRequest(
      GET_FOLLOWERS_FOLLOWING,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        this.setState({ showLoader: false });
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({
            newUserDetails: body.follower,
            showFilterComponent: true,
          });
        }
      }
    );
  };
  //get Following of neighborhood
  getUserFollowing = () => {
    const params = {
      userClientId: this.props.userData.id,
      type: "following",
      clientId: CLIENT_ID,
    };
    this.setState({ showLoader: true });
    Utility.sendRequest(
      GET_FOLLOWERS_FOLLOWING,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        this.setState({ showLoader: false });
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({
            newUserDetails: body.following,
            showFilterComponent: true,
          });
        }
      }
    );
  };
  getUserDetails = (searchText = "") => {
    if (this.state.selectedNetworkUser) {
      const networksType = this.state.selectedNetworkUser.toLowerCase();

      let params;
      if (this.state.selectedMenuItem.parent_menu === "categories")
        params = { category_id: this.state.selectedMenuItem.id };
      else if (this.state.selectedMenuItem.parent_menu === "custom_groups")
        params = { school_id: this.state.selectedMenuItem.id };
      else params = { type: networksType };

      if (!Utility.isEmpty(searchText)) {
        params.search = searchText;
      }
      this.setState({ showLoader: true });

      params.client_id = CLIENT_ID;
      if (
        this.props.userData.role !== "CLIENT_OWNER" &&
        this.props.userData.role !== "CLIENT_ADMIN"
      ) {
        Utility.sendRequest(
          GET_USERS_BY_NETWORKS,
          1,
          params,
          (err, res, body) => {
            body = JSON.parse(body);

            this.setState({ showLoader: false });
            if (body.error) {
              toast.error(body.error.message, { containerId: "private" });
            } else {
              this.setState({
                userDetails: body.registrants,
                filteredUserDetails: body.registrants.filter((item)=>new RegExp(`${this.state.SearchText.toLowerCase()}`).test(item.user_name.toLowerCase())),
                showFilterComponent: true,
              });
            }
          }
        );
      }
      if (
        this.props.userData.role === "CLIENT_OWNER" ||
        this.props.userData.role === "CLIENT_ADMIN"
      ) {
        this.getUsersOfNeighborhood();
      }
    }
  };

  getTableTitles = () => {
    if(CLIENT_ID === AHURA_CLIENT_ID && ROLE_IDS.CLIENT_OWNER === this.props.userData.role_id){
      return [
        "Name",
        "Network",
        "Study Hours",
        "Sessions",
        "Topics",
        "Licenses",
        "Action",
      ];
    }
    else if (
      CLIENT_ID === AHURA_CLIENT_ID &&
      this.props.userData.role === "PARENT_OWNER" &&
      this.props.selectedMenuItem.menu_name === "Neighborhoods"
    ) {
      return [
        "Name",
        "Network",
        // "Category",
        // "Sub Category",
        "Licenses",
        "Action",
      ];
    }
    else if(CLIENT_ID === AHURA_CLIENT_ID) {
      return ["Name", "Network", "Admin", "Action"];
    } 
    else {
      return ["Name", "Network", "Category", "Sub Category", "Admin", "Action"];
    }
  };

  getTableTitlesForApplicants = () => {
    return ["Name", "Network", "Category", "Sub Category"];
  };

  getTableTilesNeighborhoodMembers = () => {
    return ["Name", "Email", "Date of Joining", "Admin", "Action"];
  }

  getMenuItemName = (items) => {
    let name;
    if (items && items.registrants.length) {
      name = items.registrants[0].menu_name;
      this.props.updateSelectedMenuItem(items.registrants[0]);
      this.setState(
        {
          selectedMenuItemName: name,
          selectedNetworkUser: name.split(" ")[0].toLowerCase(),
        },
        () => {
          this.getUserDetails();
        }
      );
    }
  };

  getMenuDetails = () => {
    this.props.showLoader(true);

    Utility.sendRequest(
      GET_NETWORK_MENU,
      1,
      { client_id: CLIENT_ID },
      (err, res, body) => {
        body = JSON.parse(body);
        body.summary.networks.map((item) => {
          item.menu_name = NETWORK_TYPES[item.menu_name];
        });

        this.props.showLoader(false);

        // !Utility.isEmpty(body.summary) &&
        this.getMenuItemName(body.summary);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          const filters = [];
          Object.keys(body.summary).map((key) =>
            filters.push(key.charAt(0).toUpperCase() + key.slice(1))
          );
          const newArray = body.summary.categories.filter((item) => {
            if (!(item.id === null)) return item;
          });
          body.summary.categories = newArray;
          //body.summary.categories = this.state.categoriesData;
          if (
            this.props.userData.role === "CLIENT_OWNER" ||
            this.props.userData.role === "CLIENT_ADMIN"
          ) {
            body.summary["registrants"] = [
              {
                count: null,
                id: 0,
                menu_name: "All Registrants",
                parent_menu: "registrants",
              },
            ];
            this.handleMenuItemClick(body.summary["registrants"][0]);
          }
          this.props.updateSelectedModuleMenu(body.summary);

          if(CLIENT_ID === AHURA_CLIENT_ID){
              let body_summary = {...body.summary};
              delete body_summary.custom_groups;
              delete body_summary.categories;
              let sub_menus = filters.filter((item)=> (item !== "Categories" && item !== "Custom_groups" ));
              this.setState({ menus: body_summary, subMenus: sub_menus });
          }
          else{
            this.setState({ menus: body.summary, subMenus: filters }, () => {
              //this.state.menus.categories.push(this.state.categoriesData);
            });
          }


          if (CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5") {
            const categories = [];
            body.summary.categories.map((item) => {
              categories.push({
                id: item.id,
                name: item.menu_name,
                category_id: item.id,
              });
            });
            this.setState({ categoriesData: categories });
          }
        }
      }
    );
  };

  showActionMenu = () => {
    this.setState({ isActionMenuDisplayed: true });
  };

  filterList = (event) => {
    this.setState({SearchText:event.target.value})
    if (event.target.value.length === 1)
      this.setState({ isSearchEmpty: false });

    if (
      event.target.value.length >= SEARCH_MINIMUM_LENGTH ||
      (event.target.value.length === 0 && !this.state.isSearchEmpty)
      // event.key === 'Enter'
    ) {
      this.setState({ showLoader: true });
      const data = [...this.state.userDetails];
      const searchData = this.state.selectedViewData.active === "NeighborhoodUsers" ? data.filter((item)=>new RegExp(`${event.target.value.trim().toLowerCase()}`).test(item.full_name.toLowerCase())) : data.filter((item)=>new RegExp(`${event.target.value.trim().toLowerCase()}`).test(item.user_name.toLowerCase()));
      this.setState({
        filteredUserDetails: [...searchData]
      })
      // this.getUserDetails(event.target.value.trim());

      if (event.target.value.length === 0)
        this.setState({ isSearchEmpty: true, filteredUserDetails: [...this.state.userDetails] });
        this.setState({ showLoader: false });

    }
  };

  onToggleClick = (value, userData) => {
    const params = {
      id: userData.id,
      is_admin: value,
      user_id: userData.user_id,
      client_id: userData.client_id,
      loggedInUserClientID: this.props.userData.id,
      clientName: this.props.userData.client_name,
    };

    const data = {
      id: userData.id,
      value,
    };

    Utility.sendRequest(UPDATE_ADMIN_ROLE, 3, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.success === true) {
          this.updateMenuCount(value);

          this.setState({ changeAdminValue: data });
        }
      }
    });
  };
  getAllCategories = () => {
    if (CLIENT_ID !== "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5") {
      const params = {
        module_id: CRM_MODULES.CAMPAIGNS,
        client_id: CLIENT_ID,
        user_client_id: this.props.userData.id,
        campaign_type_id: CAMPAIGN_TYPES_IDS.story,
        fromHomePage: true,
      };

      Utility.sendRequest(TOPIC_CATEGORIES, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({ categoriesData: body.data });
        }
      });
    }
  };

  handleSelectCategory = (user_client, category_name) => {
    if (CLIENT_ID !== "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5") {
      const params = {
        category_name,
        user_client_id: user_client.id,
      };

      Utility.sendRequest(UPDATE_USER_CATEGORY, 3, params, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.getUserDetails();
        }
      });
    } else {
      let categoriesData = this.state.categoriesData.find(
        (o) => o.name === category_name
      );
      const params = {
        id: user_client.id, //user->id
        category_id: categoriesData.category_id, //category id
      };
      Utility.sendRequest(USERS, 3, params, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.getUserDetails();
        }
      });
    }
  };

  getAllSubCategories = () => {
    if (CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5") {
      const params = {
        platform_id: CLIENT_ID, // ETHOS CLIENT_ID,
      };
      Utility.sendRequest(SCHOOLS, 1, params, (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          this.setState({ subCategoriesData: body.data });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      });
    }
  };

  handleSelectSubCategory = (id, school_id) => {
    if(!Array.isArray(school_id))
      school_id = [school_id]
    if(!school_id[0]){
      school_id = []
    };
    const school = school_id.map((item)=> item.value);
    const params = {
      id: id.id,
      school_id: school,
    };

    Utility.sendRequest(UPDATE_USER, 3, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.getUserDetails();
        // window.location.reload();
      }
    });
  };

  updateMenuCount = (isAmin) => {
    const menu = this.props.selectedModuleMenu;
    const index = menu.networks
      .map((x) => x.menu_name)
      .indexOf(NETWORK_TYPES.Admins);

    let count = parseInt(menu.networks[index].count);

    count = isAmin ? count + 1 : count - 1;

    menu.networks[index].count = count.toString();

    this.props.updateSelectedModuleMenu(menu);
  };

  openChat = (user) => {
    if (user) {
      this.props.updateChatFriend(user);
      this.props.updateIsChatFormOpen(true);
    }
  };

  activateDeactivateUser = (user_id, status) => {
    const params = {
      id: user_id,
      status: status,
    };

    this.props.showLoader(true);
    Utility.sendRequest(
      USER_ACTIVATE_DEACTIVATE,
      2,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);
        if (!body.error) {
          if (
            body.message === "You don't have access to perform this activity"
          ) {
            toast.error(body.message, { containerId: "public" });
            return;
          }
          this.getUserDetails();
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  getMembersOfNeighborhood = () => {
    this.setState({
      showLoader:true
    })
    const params = {
      platform_id: CLIENT_ID, //ahura
      client_id: this.state.selectedViewData.neighborhood_id, //neighborhood
    };
    Utility.sendRequest(
      GET_USERS_OF_NEIGHBORHOOD,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({
            filteredUserDetails: body.data,
            userDetails: body.data,
            showLoader:false
          });
        }
      }
    );
  };

  handleSetSelectedViewData = (data) => {
    this.setState({
      selectedViewData: data
    }, ()=> this.getMembersOfNeighborhood());
  }

  handleBack = () => {
    if(this.state.selectedViewData.active === "NeighborhoodUsers")
      this.setState({selectedViewData: {}})
    this.getUserDetails();
  }

  render() {
    const { is_super_owner, platform_id } = this.props.userData;
    const a = { id: 2, name: "Networks", image_url: "networks.png" };

    if (is_super_owner || platform_id !== SM_CLIENT_ID) {
      return (
        <>
          <Row className="no-gutters h-100">
            {!Utility.isEmpty(this.props.userData) &&
              this.state.menus &&
              this.state.subMenus && (
                <Menu
                  selectedModule={a}
                  clientName={CLIENT_NAME}
                  userName={`${this.props.userData.first_name} ${this.props.userData.last_name}`}
                  dropdownItems={DROPDOWN_ACTIONS.networks}
                  handleMenuItemClick={this.handleMenuItemClick}
                  menus={this.state.menus}
                  subMenus={this.state.subMenus}
                  isButton={true}
                  showCount={true}
                  showZeroInCount={true}
                  selectedMenuItemId={this.props.selectedMenuItem.id}
                  handleDropdownAction={this.handleDropdownAction}
                  showMap={this.displayMap}
                  categoryButton={true}
                  handleCategoryFormClicked={this.handleCategoryFormClicked}
                  handleCustomGroupFormClicked={
                    this.handleCustomGroupFormClicked
                  }
                  handleListItemAction={this.handleListItemAction.bind(this)}
                  handleCustomGroupItemAction={this.handleCustomGroupItemAction.bind(
                    this
                  )}
                  customGroupFeature={
                    this.props.userData.platform_id === ETHOS_CLIENT_ID
                  }
                  campaignCategoryList={this.state.categoriesData}
                  categorySection={true}
                />
              )}
            {this.state.showInviteForm && (
              <Invite
                onClose={this.closeInviteForm}
                userData={this.props.userData}
                changeLoader={(val) => this.props.showLoader(val)}
              />
            )}
            {this.state.showCategoryform && !this.state.showInviteForm && (
              <CategoryFormContainer
                name={this.state.isUpdateCategory ? "Edit" : "Create"}
                onClose={this.closeSummaryForm}
                isUpdateCategory={this.state.isUpdateCategory}
                data={this.state.formData}
                getMenuDetails={this.getMenuDetails}
              />
            )}
            {this.state.showCustomGroupform &&
              !this.state.showCategoryform &&
              !this.state.showInviteForm && (
                <CreateCustomGroupContainer
                  name={this.state.isUpdateCustomGroup ? "Edit" : "Create"}
                  onClose={this.closeCustomGroupForm}
                  isUpdateGroup={this.state.isUpdateCustomGroup}
                  data={this.state.formData}
                  getMenuDetails={this.getMenuDetails}
                />
              )}
            {this.state.menus &&
              !this.state.showInviteForm &&
              !this.state.showCategoryform &&
              !this.state.showCustomGroupform && (
                <>
                  <ItemDetail
                    showFilterComponent={this.state.showFilterComponent}
                    filterList={this.filterList}
                    headerTitle={this.state.selectedMenuItemName}
                  >
                    {(this.props.userData.role === "CLIENT_ADMIN" ||
                      this.props.userData.role === "CLIENT_OWNER") && (
                      <div className="display-6 mb-2">
                        Number of Licenses Used:{" "}
                        {this.state.licensesCount || "0"} of{" "}
                        {this.state.max_licenses} (CODE:{" "}
                        {this.props.userData.client_id})
                      </div>
                    )}
                    <TableList
                      data={
                        this.props.userData.role === "CLIENT_OWNER" ||
                        this.props.userData.role === "CLIENT_ADMIN"
                          ? this.state.newUserDetails
                          : this.state.filteredUserDetails
                      }
                      removeUserFromNeighborhood={
                        this.removeUserFromNeighborhood
                      }
                      alterAdminStatusInNeighborhood={
                        this.alterAdminStatusInNeighborhood
                      }
                      openChat={this.openChat}
                      onToggleClick={this.onToggleClick}
                      setAdminAfterToggle={this.state.changeAdminValue}
                      showLoader={this.state.showLoader}
                      tableTitles={
                        this.state.selectedMenuItemName === "All Applications"
                          ? this.getTableTitlesForApplicants()
                          : this.state.selectedMenuItemName === "Neighborhoods"
                          ? this.state.selectedViewData.active === "NeighborhoodUsers" ? this.getTableTilesNeighborhoodMembers() : this.getTableTitles()
                          : this.state.tableTitles
                      }
                      userData={this.props.userData}
                      selectedMenuItemName={this.state.selectedMenuItemName}
                      allCategories={this.state.categoriesData}
                      handleSelectCategory={this.handleSelectCategory}
                      activateDeactivateUser={this.activateDeactivateUser}
                      subCategoriesData={this.state.subCategoriesData}
                      handleSelectSubCategory={this.handleSelectSubCategory}
                      selectedViewData = {this.state.selectedViewData}
                      handleSetSelectedViewData = {this.handleSetSelectedViewData}
                      handleBack={this.handleBack}
                    />
                  </ItemDetail>
                </>
              )}
          </Row>

          {this.props.isChatFormOpen && <ChatCallout />}
        </>
      );
    } else {
      return (
        <WelcomeOverlays
          heading="Welcome to Networks!"
          subHeading="Manage networks, teams, groups and audiences in various formats."
          mainText="Ask for a customized DEMO to learn how all the available features of this module can be used to serve the requirements of your digital application."
          btnText="Check it out!"
        />
      );
    }
  }
}

const mapActionToProps = {
  showLoader,
  updateSelectedMenuItem,
  updateSelectedModuleMenu,
  updateChatFriend,
  updateIsChatFormOpen,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedModule: state.selectedModule,
  selectedMenuItem: state.selectedMenuItem,
  selectedModuleMenu: state.selectedModuleMenu,
  isChatFormOpen: state.isChatFormOpen,
});

export default connect(mapStateToProps, mapActionToProps)(NetworkContainer);
