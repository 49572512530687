import React, { Component } from 'react';
import { Col, Form, Button, } from 'react-bootstrap';
import { toast } from "react-toastify";
import { InputField, Checkbox, CustomTagCreation } from 'shared/components/form_elements';
import {  CREATE_CATEGORY } from 'config/constants/api_endpoints';
import { CLIENT_ID, CRM_MODULES } from 'config/constants/config';
import * as Utility from 'utils/utility';
import UploadFile from 'shared/components/file_upload';
import { showLoader } from 'shared/actions';
import { connect } from 'react-redux';


const validateFields = ['name', 'description', 'embedded_image_link'];

 class CategoryForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      formData: this.getFormData(),
      imagePreviewUrl: !Utility.isEmpty(props.data.image_url) ? props.data.image_url : '',
      imageFile:'',
      formName:'create_category',
    };
  }

  componentDidMount = () => {
    this.setState({ formData: this.getFormData() });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.data !== this.props.data) {
      this.setState({ formData: this.getFormData(), imagePreviewUrl: '' });
    }
  }

  handleTagChange = (arr) => {
    const formData = { ...this.state.formData };

    if (arr === null) {
      formData.sub_categories = [];
      this.setState({ formData });
      return;
    }

    const tags = [];
    arr.map((value) => {
      tags.push(value.label);
    });

    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    const uniqueTags = tags.filter(unique);
    formData.sub_categories = uniqueTags;
    this.setState({ formData });
  };

  handleChange = (event) => {
    let formData = this.state.formData;
    formData = Utility.validateAndReturnFormData(this, formData, event, validateFields);
    this.setState({ formData });
  }

  handleFileChange = (inputId, previewURL, file) => {
    const formData = { ...this.state.formData };
    if (inputId === 'image_url') {
      this.setState({ imagePreviewUrl: previewURL, imageFile: file, formData });
    }
  }

  removeUploadedFile = (inputId) => {
    if (inputId === 'image_url') {
      const formData = { ...this.state.formData };
      formData.image_url = '';
      this.setState({ imageFile: '', imagePreviewUrl: '', formData });
    }
  }

  uploadFile = async (id, imageSignedURL) => {
    const imageURL = await Utility.uploadFile(this.state.imageFile, imageSignedURL);
    this.updateMediaUrl(id, imageURL);
  }

  updateMediaUrl = (id, imageURL) => {
    const params  = {
      image_url: imageURL,
    };

    Utility.sendRequest(CREATE_CATEGORY + '/' + id, 3, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  handleFormErrors = () => {
    const isValid = Utility.showFormErrors(this, validateFields, this.state.formName);
    return (isValid);
  }


  handleSubmit = (event) => {
    const isFormValid = this.handleFormErrors();
    if (!isFormValid) return;
    const reqParams = this.state.formData;
    reqParams.client_id = CLIENT_ID;
    reqParams.crm_module_id = CRM_MODULES.NETWORK;
    event.preventDefault();
    if (!Utility.showFormErrors(this, validateFields, this.state.formName)) return;
    this.props.showLoader(true);
    const request = this.props.isUpdateCategory ? 3 : 2 ;
    const route = this.props.isUpdateCategory ? `${CREATE_CATEGORY}/${this.props.data.id}` : `${CREATE_CATEGORY}`;

    Utility.sendRequest(route, request, reqParams, async(err, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
        this.props.showLoader(false);
      } else {

        if (this.state.imageFile) {
          await this.uploadFile(body.data.id, body.data.imageSignedURL);
        }

        toast.success(body.message, { containerId: 'private' });
        this.props.getMenuDetails();
        this.props.showLoader(false);
        this.props.closeSummary();
      }
    });
  }

  resetFormData = () => {
      this.setState({ formData: this.getFormData() }, () => {
        if (!this.props.isUpdateCategory && !this.props.data.image_url) {
          this.removeUploadedFile('image_url');
          document.getElementById('agree_terms').checked = false;
        }
      });
  }

  getFormData = () => {
    return {
      name: this.props.data ? this.props.data.name : '',
      description: this.props.data ? this.props.data.description : '',
      embedded_image_link: this.props.data ? this.props.data.embedded_image_link : '',
      sub_categories: this.props.data ?  this.props.data.subCategories : [],
      agree_terms: this.props.data ? this.props.data.is_active : false,
      image_url:  this.props.data ? this.props.data.image_url : ''
    };
  }
  render() {
    return (
      <React.Fragment>
        <Col xs={12} xl={9} lg={8}
          className="h-100 p-4 ModalOpen top-on-sm d-none d-lg-block" id="network-invitation">

            <Form className="mt-4 px-0 shar-form">
              <Form.Group className="mt-3">
                <Form.Label className="font-18-bold mt-2">What is the name?</Form.Label>
                <InputField
                  required={true}
                  value={this.state.formData.name}
                  type="text"
                  placeholder="Name"
                  name="name"
                  handleChange={this.handleChange}
                  ref="name"
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label
                  className="font-18-bold mt-2">What is the description?
                </Form.Label>
                <InputField
                  required={true}
                  value={this.state.formData.description}
                  type="text"
                  placeholder="Description"
                  name="description"
                  handleChange={this.handleChange}
                  ref="description"
                />
              </Form.Group>
              <div className="d-inline w-md-100 mr-lg-4">
                <Form.Label className="font-18-bold mt-2">Select an image for this category icon
                  <span className="font-16-light-italic pl-1">(optional)</span>
                </Form.Label>
                <label className="add-file-height">
                  <UploadFile
                    name='image_url'
                    inputId="image_url"
                    selectTitle="Select an image"
                    removeText="Remove image"
                    categoryName="create_network_category"
                    acceptTypes="image/x-png,image/gif,image/jpeg"
                    handleFileChange={this.handleFileChange}
                    uploadedFilePreviewUrl={this.state.imagePreviewUrl}
                    removeUploadedFile={this.removeUploadedFile}
                  />
                </label>
                <div className="error errorIcon" id={'image_urlError'} />
                <div className="text-center font-16-medium pb-2 form-element-width">or</div>
                <InputField
                  required={false}
                  type="url"
                  placeholder="Paste embedded image link here…"
                  name="embedded_image_link"
                  ref="embedded_image_link"
                  value={this.state.formData.embedded_image_link}
                  handleChange={this.handleChange}
                />
              </div>
              <Form.Group controlId="sub_categories" className="pt-3">
                <Form.Label className="font-18-bold mt-2">Add Sub-Category Tags
                 <span className="font-16-light-italic pl-1">(optional)</span>
                </Form.Label>
                <CustomTagCreation subCategories ={this.state.formData.sub_categories} handleChange={this.handleTagChange}/>
              </Form.Group>
              <Form.Group controlId="agree_terms">
                <Checkbox
                  ref="agree_terms"
                  checked= {this.state.formData.agree_terms}
                  checkboxId="agree_terms"
                  name="agree_terms"
                  label="Yes, I would like to add this category to the registration process of my City Account (if applicable)"
                  handleChange={this.handleChange}
                />
                <div className="error errorIcon" id='agree_termsError'></div>
              </Form.Group>
              <Form.Group className="mt-4 mb-0" >
                <Button
                  className={`${!(this.state.formData.agree_terms) ? "btn-disabled" : "btn-primary"} mt-3 sm-btn"`}
                  onClick={this.handleSubmit}
                  variant="btn-secondary" >Save
                </Button>
              </Form.Group>
              <Form.Group className="mt-4 mb-0" >
              {  <Button
                  variant="link"
                  className="mt-2 sm-btn btn-cancel  font-light"
                  onClick={() => this.resetFormData()}> Cancel
                </Button>
              }
              </Form.Group>
            </Form>
        </Col>
      </React.Fragment>
    );
  }
}

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, mapActionToProps)(CategoryForm);
