import React, { memo, useState } from 'react';
import * as Utility from 'utils/utility';
import { Row, Col, Image } from 'react-bootstrap';

const ProductItem = (props) => {
  const [isActionMenuDisplayed, showActionMenu] = useState(false);
  const actionItem = [
    { id: 1, name: 'Edit', iconName: 'edit_icon.svg' },
    { id: 2, name: 'Remove', iconName: 'trash.svg' }
  ];

  const handleActions = (id, list) => {
    showActionMenu(false);
    props.handleListItemAction(id, list);
  };

  const item = props.item;
  let imageUrl;
  if (item.all_images !== '[]') {
    const images = JSON.parse(item.all_images);
    if (!Utility.isEmpty(images)) {
      const allimages = images.filter(items => items.isPrimary === true)
      if (!Utility.isEmpty(allimages))
        imageUrl = allimages[0].image_url;
    }
    else
      imageUrl = require('assets/images/img_not_available.png');
  }
  else
    imageUrl = require('assets/images/img_not_available.png');

  return (
    <tr>
      <td className="product-img cursor-pointer" onClick={() => { props.editProductItem(props.item.id, 1); }}>
        <img src={imageUrl} alt="Product Image" />
      </td>
      <td className="product-name cursor-pointer" onClick={() => { props.editProductItem(props.item.id, 1); }}>
        {item.name}
      </td>
      <td className="product-stock cursor-pointer" onClick={() => { props.editProductItem(props.item.id, 1); }}>
        <span className="d-inline-block d-md-none"></span>
        {!Utility.isEmpty(item.categories) ? item.categories[0].name : item.category_name}
      </td>

      <td className="product-action position-relative" >
        <button className="btn btn-primary btn-rounded font-18 font-weight-bold" onClick={() => { props.editProductItem(props.item.id, 1); }} >View</button>
        <img
          src={require('assets/images/icons/menu_vertical_icon.svg')}
          className="cursor-pointer ml-3"
          style={{ width: 6 }}
          onClick={() => { showActionMenu(!isActionMenuDisplayed) }}
        />
        {
          isActionMenuDisplayed &&
          <Row className="no-gutters bg-white mx-1 mt-1 p-3 mb-0 sub-option-menu sub-option-menu-shop">
            {
              actionItem.map((list, index) => {
                return (
                  <ActionItem
                    key={"list-item-action" + index}
                    actionItem={list}
                    handleActions={handleActions}
                    item={props.item}
                  />
                );
              })}
          </Row>
        }
      </td>
    </tr>
  );
};


const ActionItem = (props) => {
  const list = props.actionItem;
  const item = props.item;

  return (
    <Col xs={6} className="p-0 align-items-center cursor-pointer" onClick={() => {props.handleActions(item.id, list.id); }}>
      <span className="pr-2">
        <Image className="hx-20 approve-action-icon" src={require("assets/images/icons/" + list.iconName)} alt={item.name + " icon"} />
      </span>
      <span className="display-xl-8 display-9 primary-link line-height-1">{list.name}</span>
    </Col>
  );
};

export default memo(ProductItem);
