import React, { Component } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { InputField, Checkbox } from 'shared/components/form_elements';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Utility } from 'utils';
import { LOGIN, USER_AUTH } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import { updateUserData, showLoader } from 'shared/actions';
import { ShowIcon } from 'shared/icons/icons';
import { BackButton, Image } from 'shared/components/pwa';
import cookie from 'react-cookies';

const validateFields = ['email', 'password'];

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        email: localStorage.getItem("email") || '',
        password: Utility.loadState("password") || '',
        client_id: CLIENT_ID.toString()
      },
      showLoader: false,
      inputType: "password",
      remember_me: localStorage.getItem("remember_me") || false
    };
  }

  componentDidMount() {
    if (!Utility.isEmpty(this.props.userData))
      this.props.history.push('/');
  }

  handleChange = (e) => {
    let formData = this.state.formData;

    formData = Utility.validateAndReturnFormData(this, formData, e, validateFields, 'pwa_login');

    this.setState({ formData });
  }

  handleRememberMe = (e) => {
    if (e.target.checked === true) {
      if (this.state.formData.email && this.state.formData.password) {
        localStorage.setItem('remember_me', e.target.checked);
        localStorage.setItem('email', this.state.formData.email);
        Utility.saveState(this.state.formData.password, 'password');
      }
    } else {
      localStorage.removeItem('remember_me');
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  }

  resetPassword = () => {
    const formData = this.state.formData;
    formData.password = '';

    this.setState({ formData });
  }

  login = (e) => {
    e.preventDefault();

    if (!Utility.showFormErrors(this, validateFields, 'pwa_login')) return;
    this.setState({ showLoader: true });

    const formData = { ...this.state.formData };

    Utility.sendRequest(LOGIN, 2, formData, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        this.setState({ showLoader: false });
        this.resetPassword();
        toast.error(body.error.message, { containerId: 'public' });
      } else {
        cookie.save('sessionToken', body.token, { path: '/' });

        Utility.sendRequest(USER_AUTH, 1, body.token, (error, response, body) => {
          body = JSON.parse(body);
          if (!body.error) {
            this.props.updateUserData(body);
            this.props.history.push('/home');
          } else {
            toast.error(body.error.message, { containerId: 'public' });
          }
        });
      }
    });
  }
  toggleShowPassword = () => {
    this.state.inputType === "text"
      ? this.setState({ inputType: "password" })
      : this.setState({ inputType: "text" });
  }

  render() {
    return (
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column" >
        <div className="d-flex" >
          <BackButton
            action={() => this.props.history.push({
              pathname: '/',
              state: { isOpen: true }
            })} name='email' />
          <Image />
        </div>
        <h1 className="font-28-bold text-center my-5 text-margin" >Welcome back!</h1>
        <Form className="pwa-form" >
          <Form.Group controlId="formBasicEmail">
            <InputField
              required={true}
              name="email"
              className="form-control"
              ref="email"
              value={this.state.formData.email}
              handleChange={this.handleChange}
              type="email"
              placeholder="Email address"
            />
          </Form.Group>
          <Form.Group className='position-relative' controlId="formGroupPassword">
            <InputField
              required={true}
              handleChange={this.handleChange}
              type={this.state.inputType}
              value={this.state.formData.password}
              className="form-control"
              ref='password'
              name='password'
              placeholder="Password" />
            <b className="show-icon show-icon-pwa" onClick={() => this.toggleShowPassword()}>
              <ShowIcon mainClass={`sm-show-icon ${this.state.inputType === "text" ? "sm-show-icon-active" : ""}`} />
            </b>
          </Form.Group>
          <Form.Group>
            <Checkbox
              ref="remember_me"
              placeholder="Remember Me"
              checkboxId="remember_me"
              checked={this.state.remember_me}
              name="remember_me"
              label="Remember Me"
              handleChange={this.handleRememberMe}
            />
          </Form.Group>
        </Form>
        <div className="text-center mt-auto">
          <button className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100" onClick={(e) => this.login(e)}>{this.state.showLoader ? <Spinner animation="border" role="status" /> : 'LOGIN'}</button>
          <Link className="btn btn-link btn-cancel lg-btn w-100 align-items-center justify-content-center" to={{ pathname: "/forgot_password", state: { email: this.state.formData.email } }} >FORGOT MY PASSWORD</Link>
        </div>
      </div>
    );
  }
}

const mapActionToProps = { updateUserData, showLoader };
const mapStateToProps = (state) => ({
  userData: state.userData
});

export default connect(mapStateToProps, mapActionToProps)(Login);
