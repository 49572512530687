import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Utility } from 'utils';
import { GROUP_CAMPAIGNS } from 'config/constants/api_endpoints';
import { CLIENT_ID, GROUPS_CAMPAIGNS_LIMIT } from 'config/constants/config';
import { SuggestedReadsCard } from 'shared/components/suggested_reads';

export default function Campaigns(props){
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    getCampaignsDetails();
  }, []);

  const getCampaignsDetails = () => {
    const params = {
      groupId: props.groupId,
      clientId: CLIENT_ID,
      limit: GROUPS_CAMPAIGNS_LIMIT,
    };

    if(!Utility.isEmpty(props.userData)){
      Utility.sendRequest(GROUP_CAMPAIGNS, 1, params, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          if(body.length)
            setCampaigns(body);
        }
      });
    }
  };

  return(
    campaigns.length ?
    <div className={`article-section ${Utility.pwaModeEnabled ? '' : 'mb-42'}`}>
      {
        (props.showHeading && props.seeAll) &&
          <h1 className="font-24-bold mb-25 home-page-heading" >
            {props.showHeading && props.heading}
            {
              (props.seeAll) &&
                <span className="see-all-text display-7" onClick={props.setSeeAll}>
                  See All
                </span>
            }
          </h1>
        }
      <div className="d-flex article-section-outer flex-lg-wrap slider-mob-inner mb-42" >
        <SuggestedReadsCard
          loadedFrom={props.loadedFrom}
          handleCampaignIdChange={props.handleCampaignIdChange}
          stories={campaigns}
        />
      </div>
    </div> : <></>
  );
}
