import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Image } from "react-bootstrap";
import Avatar from "react-avatar";
import cookie from "react-cookies";
import { Utility } from "utils";
import SideCallout from "shared/components/templates/side_callout";
import {
  GET_CLIENT_LOGO_URL,
  SWITCH_ACCOUNT,
  USER_AUTH,
} from "config/constants/api_endpoints";
import SupportTeamCard from "./SupportTeamCard";

const AfterPurchase = (props) => {
  const [logoUrl, setLogoUrl] = useState(localStorage.getItem("logoUrl"));

  useEffect(() => {
    getClientLogo();
  }, [props.newUserClientDetails]);

  const getClientLogo = () => {
    if (props.newUserClientDetails) {
      Utility.sendRequest(
        GET_CLIENT_LOGO_URL,
        1,
        { clientId: props.newUserClientDetails.id },
        (error, response, body) => {
          body = JSON.parse(body);

          if (!body.error) {
            setLogoUrl(body.logo_url);
          }
        }
      );
    }
  };

  const switchAccount = (id) => {
    props.showLoader(true);

    Utility.sendRequest(SWITCH_ACCOUNT, 1, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "public" });
      } else {
        cookie.save("sessionToken", body.token, { path: "/" });

        Utility.sendRequest(USER_AUTH, 1, { id }, (error, response, body) => {
          body = JSON.parse(body);

          props.showLoader(false);

          if (!body.error) {
            props.updateUserData(body);
            props.history.push({
              pathname: "/profile",
              state: { body },
            });
            props.handleClose();
          } else {
            toast.error(body.error.message, { containerId: "public" });
          }
        });
      }
    });
  };

  return (
    <SideCallout
      closeCallOut={props.handleClose}
      position="position-fixed mt-5"
      calloutHeading="Confirmation"
      calloutHeadingImg={process.env.PUBLIC_URL + '/toast_icon/city_logo.png'}
    >
      <div className="font-18 mb-3 font-weight-bold">Key</div>
      <div className="box py-3 px-4 font-16 mb-4">
        <div className="d-flex">
          <div className="mr-3">
            <Image
              src={require("assets/images/profile/new-profile/security-key.svg")}
              alt=""
            />
          </div>
          <div>
            You have successfully created a{" "}
            <span className="text-primary b-600">City Account</span>. Here is
            your key to your city!
          </div>
        </div>
        <div className="mt-3">
          <span className="b-700 font-15">KEY: </span>
          <span className="font-15">
            {props.transactionData.client_api_key}
          </span>
        </div>
      </div>
      <div className="font-16 mb-3 font-weight-bold pt-2">City Account</div>
      <div className="city-account box py-3 px-4 mb-4">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className="city-account-img mr-2">
              {!Utility.isEmpty(logoUrl) && logoUrl !== "null" ? (
                <Image
                  className="rounded-circle"
                  src={logoUrl}
                  alt="Profile Picture"
                />
              ) : (
                <Avatar
                  name={props.transactionData.client_name}
                  round={true}
                  size="55"
                  textSizeRatio={1.75}
                  color="#007bb3"
                  maxInitials={1}
                />
              )}
            </div>
            <div className="d-block">
              <div className="font-18 b-600">
                {props.transactionData.client_name}
              </div>
              <div className="font-16">{props.transactionData.client_city}</div>
            </div>
          </div>
          <div className="ml-auto">
            <div
              onClick={() =>
                switchAccount(props.transactionData.sender_user_client_id)
              }
              className="b-700 text-primary cursor-pointer"
            >
              Toggle
            </div>
          </div>
        </div>
      </div>
      <div className="font-16 mb-3 font-weight-bold pt-2">Support Team</div>
      <SupportTeamCard {...props} data={props.supportUsers}></SupportTeamCard>
      <div className="font-16 mb-3 font-weight-bold mt-4 pt-2">
        Transaction Details
      </div>
      <div className="py-3 px-4 box">
        <p className="mb-2">Date: {props.transactionData.date}</p>
        <p className="mb-2">
          Transaction #: {props.transactionData.transaction}
        </p>
        <p className="mb-2">
          License: <span className="text-primary">City License</span>
        </p>
        <p className="mb-2">Plan: Monthly</p>
        <p className="mb-2">Amount: ${props.transactionData.amount}</p>
        <p className="mb-2">Card: {props.transactionData.card}</p>
        <p className="mb-2">Sender: {props.transactionData.sender}</p>
      </div>
    </SideCallout>
  );
};

export default AfterPurchase;
