import React, { Component } from 'react';
import { Button, Row, Col, Image, Form, Spinner } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { MdClose, MdPhotoCamera } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUserData, showLoader } from 'shared/actions';
import { COUNTRIES,  USERS, STATES, USER_AUTH } from 'config/constants/api_endpoints';
import { InputField, DropDown, Textarea } from 'shared/components/form_elements';
import { toast } from 'react-toastify';
import  * as Utility from 'utils/utility';
import cookie from 'react-cookies';
import ImageUpload from './profile_img_upload';
import ProfileDetails from 'shared/components/profile_details';

const validateFields = ['first_name', 'city', 'title', 'line1', 'line2'];

class ProfileEdit extends Component {
  constructor(props){
    super(props);

    this.props.showLoader((Utility.isEmpty(this.props.userData)));
    const formData = this.getFormData();

    this.state = {
      formData: formData,
      countries: [],
      states: [],
      showImageUploader: false
    };
  }

  componentDidMount = () => {
    this.getAllCountries();
    this.setUserDetails();
  }

  componentDidUpdate = () => {
    if(!Utility.isEmpty(this.props.userData)){
      this.setDefaultValues(this.props.userData.Client.ClientType.id, this.props.userData.Client.ClientSubType.name);
    }

    if (this.state.formData.user_id !== this.props.userData.id) {
      this.setUserDetails();
    }
  }

  handleFormChange = (event) => {
    let formData = this.state.formData;

    if (event.target.name === 'country_id') {
      this.getStateByCountry(event.target.value);
    }

    formData = Utility.validateAndReturnFormData(this, formData, event, validateFields);
    this.setState({ formData });
  }

  getAllCountries = () => {
    Utility.sendRequest(COUNTRIES, 1, { limit: 300 }, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.setState({ countries: body.data });
      } else {
        toast.error(JSON.parse(body).error.message, { containerId: 'private' });
      }
    });
  }

  getStateByCountry = (countryId) => {
    Utility.sendRequest(STATES, 1, { country_id: countryId, limit: 200 }, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.setState({ states: body.data });
      } else {
        toast.error(JSON.parse(body).error.message, { containerId: 'private' });
      }
    });
  }

  fetchUserDetails = () => {
    if (Utility.isEmpty(cookie.load("sessionToken"))) {
      Utility.sendRequest(USER_AUTH, 1, {}, (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          this.setUserDetails(body);
          this.props.onUpdateData(body);
        } else {
          toast.error(body.error.message, { containerId: 'private' });
        }
      });
    } else {
      this.props.history.push("/users/sign_in");
    }
  }

  setUserDetails = () => {
    this.setState({ formData: this.getFormData() });
    if (!Utility.isEmpty(this.props.userData)) {
      this.getStateByCountry(this.props.userData.Client.country_id || 1);
    }
  }

  getFormData = () => {
    const user = this.props.userData;
    const client = user.Client || {};
    const clientType = client.ClientType || {};
    const clientSubType = client.ClientSubType || {};

    return {
      user_id: user.id,
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      title: user.title  || '',
      name: client.name || '',
      client_id: client.id,
      description: client.description || '',
      city: client.city || '',
      state_id: client.state_id || null,
      zip_code: client.zip_code || '',
      website_url: client.website_url || '',
      client_type_id: clientType.id || 0,
      client_sub_type:  clientSubType.name || '',
      country_id: client.country_id || null,
      address1: client.address1 || '',
      address2: client.address2 || '',
    };
  }

  setDefaultValues = (clientTypeId, clientSubTypeId) => {
    const element = document.getElementById('career_info');
    const element2 = document.getElementById('careerSubInfo');

    if (element) element.value = clientTypeId;
    if (element2) element2.value = clientSubTypeId;
  }

  closeImageUploader = () => {
    this.setState({ showImageUploader: false });
  }

  submitForm = (event) => {
    event.preventDefault();
    const self = this;

    if (!Utility.showFormErrors(self, validateFields)) {
      return;
    }

    self.setState({ showLoader: true });

    Utility.sendRequest(USERS, 3, self.state.formData, (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        self.fetchUserDetails();
        toast.success(body.message, { containerId: 'private' });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  render() {
    let profileDetails = '';

    if (!this.props.isLoading && !(Utility.isEmpty(this.props.userData))) {
      const clientType = this.props.userData.Client.ClientType;
      const clientSubType = this.props.userData.Client.ClientSubType;
      const clientTypeName = clientType ? clientType.name : '';
      const clientSubTypeName = clientSubType ? clientSubType.name : '';
      const clientGoals = this.props.userData.Client.ClientGoals;

      profileDetails = <ProfileDetails
                        profileImgUrl={this.props.userData.Client.image_url}
                        showHeader={true}
                        userDetails={this.props.userData}
                        name={this.props.userData.Client.name}
                        city={this.props.userData.Client.city}
                        state={this.props.userData.Client.State ? this.props.userData.Client.State.name : ''}
                        description={this.props.userData.Client.description}
                        clientTypeName={clientTypeName}
                        clientSubTypeName={clientSubTypeName}
                        title={this.props.userData.title}
                        address1={this.props.userData.Client.address1 || ''}
                        address2={this.props.userData.Client.address2 || ''}
                        websiteUrl={this.props.userData.Client.website_url}
                        profileImgClassName="my-profile-img ml-5"
                        clientGoals={clientGoals}
                        disableEdit={true}
                      />;
    }
    return (
      <React.Fragment>
        <div className="p-sm-3 p-2 h-100">
          <div className="profile h-100">
            { (!this.props.isLoading && !(Utility.isEmpty(this.props.userData))) &&
              <Row className="no-gutters h-100">
                {profileDetails}
                <Col className="edit-profile-margin dashboard-data-container h-100 scroll-y ml-3">
                  <div className="edit-profile">
                    <div className="edit-profile-border">
                      <div className="px-md-5 py-md-4 p-3">
                        <div className="close-button ">
                          <Link to="/profile"><Button variant="link" className="close-btn-position mt-sm-4 mt-3"><MdClose/></Button></Link>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="round-circle d-flex-align-center"><span className="text-white"><FaPencilAlt /></span></div>
                          <span className="display-xl-6 display-7 font-bold pl-2">Edit Profile</span>
                        </div>
                        <div className="pt-3">
                          <Col xs={12} lg={9} className="px-0 px-sm-3" >
                            <div className="edit-profile-img mt-3 cursor-pointer d-inline-block position-relative" onClick={() => this.setState({ showImageUploader: true })}>
                              <Image src={!Utility.isEmpty(this.props.userData.Client.image_url) ? this.props.userData.Client.image_url : require("assets/images/users/user_no_image.png")} alt="img_profile" roundedCircle />
                              <div className="round-circle-sm change-photo-position bg-camera"><span className="text-white photo-icon-position"><MdPhotoCamera /></span></div>
                            </div>
                            <Form className="pt-4 shar-form edit-form" onSubmit={this.submitForm}>
                              <Form.Label className="display-xl-6 display-7 font-bold pb-2">Profile Details</Form.Label>
                              <Form.Group controlId="Organization">
                                <InputField type="text" placeholder="Organization Name" name="name"
                                  value={this.state.formData.name} ref="name" handleChange={this.handleFormChange}
                                />
                              </Form.Group>

                              <Form.Group controlId="first_name">
                                <InputField required={true} type="text" placeholder="First Name" name="first_name"
                                  value={this.state.formData.first_name} ref="first_name" handleChange={this.handleFormChange}
                                />
                              </Form.Group>

                              <Form.Group controlId="last_name">
                                <InputField type="text" placeholder="Last Name" name="last_name"
                                    value={this.state.formData.last_name} ref="last_name" handleChange={this.handleFormChange}
                                  />
                              </Form.Group>
                              {/* <Form.Group controlId="location">
                                <Form.Control placeholder="Location" name="location" onChange={this.handleFormChange} />
                              </Form.Group> */}
                              <Form.Group controlId="description">
                                <Form.Label className="display-xl-6 display-7 font-bold pb-2">About Description <span className="font-16-light-italic">(150 characters max)</span></Form.Label>
                                <Textarea maxLength={150} required={false} placeholder="Enter text here..." name="description" value={this.state.formData.description} handleChange={this.handleFormChange} />
                              </Form.Group>
                              <Form.Group controlId="country">
                                <Form.Label className="display-xl-6 display-7 font-bold pb-2">Countries</Form.Label>
                                <DropDown options={this.state.countries} optionId="id" optionValue="name" id="country" name="country_id" ref="country_id" value={this.state.formData.country_id} handleChange={this.handleFormChange}/>
                              </Form.Group>
                              <Form.Group controlId="career_info">
                                <Form.Label className="display-xl-6 display-7 font-bold pb-2">Career Info</Form.Label>
                                  <DropDown options={this.props.clientTypes} disabled={true} optionId="id" optionValue="name" name="clientTypeId" ref="clientTypeId"/>
                              </Form.Group>
                              <Form.Group controlId="careerSubInfo">
                                <InputField disabled={true} required={true} type="text" placeholder="Sub Type" name="title" ref="title" value={this.state.formData.client_sub_type} handleChange={this.handleFormChange}/>
                              </Form.Group>
                              <Form.Group controlId="line1">
                                <Form.Label className="display-xl-6 display-7 font-bold pb-2">Address</Form.Label>
                                <InputField required={true} type="text" placeholder="Line 1" name="address1" ref="line1"
                                    value={this.state.formData.address1} handleChange={this.handleFormChange}
                                  />
                              </Form.Group>
                              <Form.Group controlId="line2">
                                <InputField required={true} type="text" placeholder="Line 2" name="address2" ref="line2"
                                    value={this.state.formData.address2} handleChange={this.handleFormChange}
                                  />
                              </Form.Group>
                              <Form.Group controlId="city">
                                <InputField required={true} type="text" placeholder="City" name="city" ref="city"
                                      value={this.state.formData.city} handleChange={this.handleFormChange}
                                    />
                                {
                                  !Utility.isEmpty(this.state.states) &&
                                  <Form.Row className="pt-3">
                                    <Col>
                                      <DropDown options={this.state.states} optionId="id" optionValue="name" id="state_id" name="state_id" ref="state_id" value={this.state.formData.state_id} handleChange={this.handleFormChange}/>
                                    </Col>
                                  </Form.Row>
                                }
                                <Form.Row className="pt-3">
                                  <Col>
                                  <InputField required={false} type="text" placeholder="ZIP Code" name="zip_code" ref="zip_code"
                                      value={this.state.formData.zip_code} handleChange={this.handleFormChange}
                                    />
                                  </Col>
                                </Form.Row>
                              </Form.Group>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label className="display-xl-6 display-7 font-bold pb-2">Website URL</Form.Label>
                                <InputField required={false} type="text" placeholder="Enter URL..." name="website_url" ref="website_url"
                                      value={this.state.formData.website_url} handleChange={this.handleFormChange}
                                    />
                              </Form.Group>
                              <Form.Row className="pt-3 pb-5">
                                <div className="order-sm-0 pb-sm-1">
                                <Link to="/profile"><Button variant="none" type="button" className="mr-3 font-light"> Cancel </Button></Link>
                                </div>
                                <div className="order-sm-1">
                                  <Button variant="primary" type="submit" >{this.state.showLoader ? <Spinner animation="border" role="status"/> : 'Submit'} </Button>
                                </div>
                              </Form.Row>
                            </Form>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            }
          </div>
        </div>
        {
          this.state.showImageUploader &&
          <ImageUpload closeImageUploader={this.closeImageUploader}/>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData,
  isLoading: state.isLoading,
  clientTypes: state.clientTypes
});

const mapActionToProps =  {
  onUpdateData: updateUserData,
  showLoader: showLoader
};

export default connect(mapStateToProps, mapActionToProps)(ProfileEdit);
