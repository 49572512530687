import React, { Component } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Utility } from 'utils';
import { MdClose } from 'react-icons/md';
import { FaSistrix } from 'react-icons/fa';

export default class ToolsModal extends Component {
  constructor() {
    super();
    this.state = {
      item: true
    };
  }

  closeTools = () => {
    Utility.showComponent(false, 'tools');
  }

  render() {
    return (
      <div className="side-modal-outer col-lg-7 offset-lg-5 col-sm-10 offset-sm-2 position-fixed px-0 d-none h-100" id="tools" >
        <div className="side-modal-inner bg-white p-sm-4 p-3 h-100 ml-sm-1">
        {/* Modal Header */}
          <div className="side-modal-header align-items-center">
            <h4 className="font-bold mb-0 align-items-center"> <img src={require('assets/images/icons/tip_icon.svg')} alt="sharemeister tool icon" className="mr-2" />  Tools!</h4>
            <div className="ml-auto align-items-center tool-search-container">
              <div className="search-item position-relative ml-2">
                <Form.Control type="text" className="shar-search"  placeholder="Search"/>
                <span className="icon-position"><FaSistrix/></span>
              </div>
              <Button variant="link" className="close-btn-position side-modal-close" onClick={this.closeTools} ><MdClose/></Button>
            </div>
          </div>
        {/* Search Result */}
          <div className="search-result border-bottom pb-5 mb-4">
            <div className="display-8 font-bold">Tools with the keyword, &quot;Contracts&quot;... </div>
            <div className="tool-image my-3">
              <img src={require('assets/images/tools/contract_tool.png')} alt="sharemeister tool thubmnail" />
            </div>
            <div className="tool-name display-7 font-bold">Contracts!</div>
            <div className="tool-info display-9 text-muted">
              Share assets with friends and business partners with this Asset Manager Tool!
            </div>
          </div>
        {/* Most Recent Tools */}
          <div className="tools-recent">
            <div className="display-8 font-bold">Most Recent Tools</div>
            <Row className="mx-0" >
              <Col lg={3} sm={4} xs={6}  >
                <div className="tool-image my-3">
                  <img src={require('assets/images/tools/assets_manager_tool.png')} alt="sharemeister tool thubmnail" />
                </div>
                <div className="tool-name display-7 font-bold">Asset Manager</div>
                <div className="tool-info display-9 text-muted">
                  Share assets with friends and business partners with this Asset Manager Tool!
                </div>
              </Col>
              <Col lg={3} sm={4} xs={6}  >
                <div className="tool-image my-3">
                  <img src={require('assets/images/tools/folder_tool.png')} alt="sharemeister tool thubmnail" />
                </div>
                <div className="tool-name display-7 font-bold">Folders!</div>
                <div className="tool-info display-9 text-muted">
                  Share assets with friends and business partners with this Asset Manager Tool!
                </div>
              </Col>
              <Col lg={3} sm={4} xs={6}  >
                <div className="tool-image my-3">
                  <img src={require('assets/images/tools/products_tool.png')} alt="sharemeister tool thubmnail" />
                </div>
                <div className="tool-name display-7 font-bold">Products</div>
                <div className="tool-info display-9 text-muted">
                  Share assets with friends and business partners with this Asset Manager Tool!
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
