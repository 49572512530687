import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PersonsGroupIcon } from 'shared/icons/icons';
import PwaCampaign from 'scenes/campaigns/pwa';
import { updateFooterLink, openOrClosePlusIcon } from 'shared/actions';
import PwaFooterChatMessage from 'shared/components/pwa_footer_chat_message';
import { useDispatch, useSelector } from 'react-redux';

export function PwaFooter() {
  const dispatch = useDispatch();
  const isCampSelFormOpen = useSelector(state => state.isCampSelFormOpen);
  const activeLink = useSelector(state => state.currentFooterLink);
  const plusIconClicked = useSelector(state => state.isPlusIconClicked);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    let lastKnownScrollY = 0;
    let currentScrollY = 0;
    let ticking = false;
    const idOfHeader = 'pwaFooterMenu';
    let eleHeader = null;

    const classes = {
      pinned: 'footer-pin',
      unpinned: 'footer-unpin',
    };

    const onScroll = () => {
      currentScrollY = window.pageYOffset;
      requestTick();
    };

    const requestTick = () => {
      if (!ticking) {
        requestAnimationFrame(update);
      }
      ticking = true;
    };

    const update = () => {
      if (currentScrollY < lastKnownScrollY) {
        pin();
      } else if (currentScrollY > lastKnownScrollY && lastKnownScrollY > 100) {
        unpin();
      }
      lastKnownScrollY = currentScrollY;
      ticking = false;
    };

    const pin = () => {
      if (eleHeader && eleHeader.classList.contains(classes.unpinned)) {
        eleHeader.classList.remove(classes.unpinned);
        eleHeader.classList.add(classes.pinned);
      }
    };

    const unpin = () => {
      if (eleHeader.classList.contains(classes.pinned) || !eleHeader.classList.contains(classes.unpinned)) {
        eleHeader.classList.remove(classes.pinned);
        eleHeader.classList.add(classes.unpinned);
      }
    };

    window.addEventListener('scroll', () => {
      eleHeader = document.getElementById(idOfHeader);
      onScroll();
   });
 }, []);

 const changeLinks = (link) => {
  dispatch(updateFooterLink(link));
};


  return (
    <>
      <div className="h-md-100 scroll-y dashboard-page-container">
        <div id="pwaFooterMenu" className="pwa-menu p-0 pwa-footer-outer">
          <div className="d-flex justify-content-around">
            <Link onClick = {() => {changeLinks('home');} } className={`pwa-footer-link flex-center w-25 flex-column ${activeLink === 'home' && !plusIconClicked ? 'active-link' : ''}`} to={'/home'}>
              <HomeIcon fillClass="fill-current" />
              <div className="pwa-footer-menu" style={{top: 7}} >Home</div>
            </Link >

            <Link onClick = {() => {changeLinks('media');} } className={`pwa-footer-link flex-center w-25 flex-column ${activeLink === 'media' && !plusIconClicked ? 'active-link' : ''}`} to={'/media'} >
              <MediaIcon fillClass="fill-current" />
              <div style={{top: -3}} className="pwa-footer-menu" >Courses</div>
            </Link>

            <Link onClick={() => dispatch(openOrClosePlusIcon(!plusIconClicked))} className={`pwa-footer-link flex-center w-25 flex-column todo-link ${plusIconClicked ? 'show-call-out active-link' : ''}`} >
              <TodoIcon />
              <div style={{top: -8}} className="pwa-footer-menu" >To Do</div>
            </Link>

            <Link onClick = {() => {changeLinks('events');} } className={`pwa-footer-link flex-center w-25 flex-column ${activeLink === 'events' && !plusIconClicked ? 'active-link' : ''}`} to={'/events'}>
              <EventIcon fillClass="fill-current" />
              <div style={{top: -8}} className="pwa-footer-menu" >Events</div>
            </Link >

            <Link onClick = {() => {changeLinks('groups');} } className={`pwa-footer-link flex-center w-25 flex-column ${activeLink === 'groups' && !plusIconClicked ? 'active-link' : ''}`} to={'/groups'}>
              <PersonsGroupIcon fillClass="fill-current" />
              <div style={{top: 7}} className="pwa-footer-menu" >Groups</div>
            </Link>
          </div>
        </div>
      </div>

      {
        isCampSelFormOpen &&
        <PwaCampaign />
      }

      { isChatOpen &&
        <PwaFooterChatMessage
          setIsChatOpen={setIsChatOpen}
        />
      }

    </>
  );
}

export const EventIcon = (props) => {
    return(
      <svg width="59px" height="59px" viewBox="0 0 59 59" style={{marginTop: -5}}>
          <g id="Jun-6-2021" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="5.0-Home---Get-Started" transform="translate(-223.000000, -1055.000000)">
                  <g id="Group-2" transform="translate(0.000000, 1047.000000)">
                      <g id="Group" transform="translate(144.000000, 13.000000)">
                          <g id="clarity:event-solid-badged" transform="translate(96.000000, 12.000000)">
                              <rect id="ViewBox" x="0" y="0" width="25" height="25"></rect>
                              <path className={props.fillClass} d="M6.94444444,8.08080808 C7.32797552,8.08080808 7.63888889,7.76989471 7.63888889,7.38636364 L7.63888889,3.21969697 C7.63888889,2.83616589 7.32797552,2.52525253 6.94444444,2.52525253 C6.56091337,2.52525253 6.25,2.83616589 6.25,3.21969697 L6.25,7.38636364 C6.25,7.76989471 6.56091337,8.08080808 6.94444444,8.08080808 Z" id="Path" fill="#272262" fill-rule="nonzero"></path>
                              <path className={props.fillClass} d="M20.8333333,10.5113636 C17.9568503,10.5113636 15.625,8.17951338 15.625,5.30295016 L8.47222222,5.30295016 L8.47222222,7.38636364 C8.47222222,8.230132 7.78821281,8.91414141 6.94444444,8.91414141 C6.10067608,8.91414141 5.41666667,8.230132 5.41666667,7.38636364 L5.41666667,5.30295016 L2.63888889,5.30295016 C2.30500841,5.2992308 1.98379217,5.43066585 1.74834266,5.6674234 C1.51289316,5.90418096 1.38323971,6.22612044 1.38871017,6.55997475 L1.38871017,22.1016414 C1.38331674,22.429492 1.50823505,22.7461234 1.73615377,22.9818565 C1.96407249,23.2175895 2.27631456,23.3531068 2.60416667,23.3585859 L22.3958333,23.3585859 C22.7236854,23.3531068 23.0359275,23.2175895 23.2638462,22.9818565 C23.4917649,22.7461234 23.6166833,22.429492 23.6112898,22.1016414 L23.6112898,9.70580808 C22.7805956,10.2329414 21.8170124,10.5123805 20.8333333,10.5113636 Z M18.0138889,12.6502525 L11.2986111,19.3655303 L7.63888889,15.6780303 C7.30228302,15.3078158 7.31282887,14.7393242 7.66293383,14.3818487 C8.01303878,14.0243731 8.58118733,14.0019872 8.95833333,14.3308081 L11.3055556,16.6780303 L16.6666667,11.3169192 C16.9060842,11.0775016 17.2550429,10.9839984 17.5820934,11.0716313 C17.9091439,11.1592642 18.1645994,11.4147197 18.2522323,11.7417702 C18.3398652,12.0688207 18.246362,12.4177794 18.0069444,12.657197 L18.0138889,12.6502525 Z" id="Shape" fill="#272262" fill-rule="nonzero"></path>
                              <circle className={props.fillClass} id="Oval" fill="#272262" fill-rule="nonzero" cx="20.8333333" cy="5.3030303" r="3.47222222"></circle>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
};

export const TodoIcon = (props) => {
    return(
      <svg width="84px" height="84px" viewBox="0 0 84 84">
          <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
                  <stop stop-color="#77549F" offset="0%"></stop>
                  <stop stop-color="#422A66" offset="100%"></stop>
              </linearGradient>
          </defs>
          <g id="Jun-6-2021" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="5.0-Home---Get-Started" transform="translate(-138.000000, -1030.000000)">
                  <g id="Group-2" transform="translate(0.000000, 1047.000000)">
                      <g id="Group-5" transform="translate(155.000000, 0.000000)">
                          <circle id="Oval" fill="url(#linearGradient-2)" cx="25" cy="25" r="25"></circle>
                          <path d="M33.5833333,29.4166667 L29.4166667,29.4166667 L29.4166667,33.5833333 C29.4166667,34.7552083 29.0043403,35.7426215 28.1796875,36.5455729 C27.3550347,37.3485243 26.3676215,37.75 25.2174479,37.75 C24.0672743,37.75 23.0907118,37.3485243 22.2877604,36.5455729 C21.484809,35.7426215 21.0833333,34.7552083 21.0833333,33.5833333 L21.0833333,29.4166667 L16.9166667,29.4166667 C15.7447917,29.4166667 14.7573785,29.0043403 13.9544271,28.1796875 C13.1514757,27.3550347 12.75,26.3676215 12.75,25.2174479 C12.75,24.0672743 13.1514757,23.0907118 13.9544271,22.2877604 C14.7573785,21.484809 15.7447917,21.0833333 16.9166667,21.0833333 L21.0833333,21.0833333 L21.0833333,16.9166667 C21.0833333,15.7447917 21.484809,14.7573785 22.2877604,13.9544271 C23.0907118,13.1514757 24.0672743,12.75 25.2174479,12.75 C26.3676215,12.75 27.3550347,13.1514757 28.1796875,13.9544271 C29.0043403,14.7573785 29.4166667,15.7447917 29.4166667,16.9166667 L29.4166667,21.0833333 L33.5833333,21.0833333 C34.7118056,21.0833333 35.6883681,21.484809 36.5130208,22.2877604 C37.3376736,23.0907118 37.75,24.0672743 37.75,25.2174479 C37.75,26.3676215 37.3376736,27.3550347 36.5130208,28.1796875 C35.6883681,29.0043403 34.7118056,29.4166667 33.5833333,29.4166667 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
};

export const MediaIcon = (props) => {
    return(
      <svg width="45px" height="45px" viewBox="0 0 45 45">
          <g id="PWA-2021" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="5.0-Home---Get-Started" transform="translate(-112.000000, -1066.000000)" fill="#272262" className={props.fillClass} fill-rule="nonzero">
                  <g id="TBH-Bottom-Navigation" transform="translate(0.000000, 1063.000000)">
                      <g id="Group-2">
                          <g id="Group-3" transform="translate(90.000000, 0.000000)">
                              <path d="M44.5,11 C37.5947581,11 32,16.5947581 32,23.5 C32,30.4052419 37.5947581,36 44.5,36 C51.4052419,36 57,30.4052419 57,23.5 C57,16.5947581 51.4052419,11 44.5,11 Z M50.3316532,24.7096774 L41.4606855,29.8004032 C40.6643145,30.2439516 39.6612903,29.6743952 39.6612903,28.7419355 L39.6612903,18.2580645 C39.6612903,17.3306452 40.6592742,16.7560484 41.4606855,17.1995968 L50.3316532,22.5927419 C51.1582661,23.0564516 51.1582661,24.2510081 50.3316532,24.7096774 Z" id="Icon-Shape"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
};

export const HomeIcon = (props) => {
    return(
      <svg width={16} height={25} viewBox="0 0 16 25" className="position-relative" style={{top: -2}}>
        <g fill="none" fillRule="evenodd">
          <path fill="#272262" className="white-on-active" d="M16 0v16L1 8.148z" />
          <path fill="#F3953D" d="M0 25V10l15 7.438z" />
        </g>
    </svg>
    );
};
