import React from "react";
import { Utility } from "utils";
import { RedoIcon, TickFilledIcon } from "shared/icons/icons";
import RichTextEditor from "shared/components/rich_text_editor";
import { CLIENT_ID } from "config/constants/config";
import { GIL_CLIENT_ID } from "config/constants";

const AssignmentQuestion = (props) => {
  
  return (
    <>

      <div className="course-sidecallout mb-4 d-flex overflow-hidden p-4 align-items-center">
        <div className="position-relative ml-2">
          <div className="font-24 font-weight-bold">
            {props.campaignDetails[0].heading}
          </div>
          <div className="text-primary-light b-600 font-14 mt-1">
            {props.courseDetails[0].heading}
          </div>
        </div>
        <div className="cs-lesson-indicators ml-auto text-right mr-2">
          <span className="font-14">
            Lesson {props.count} of{" "}
            {(props.questionLists && props.questionLists.length) || 0}
          </span>
          <ul className="list-unstled d-flex mt-2">
            {Array((props.questionLists && props.questionLists.length) || 0)
              .fill({})
              .map((i, index) => {
                return (
                  <li
                    key={index}
                    className={index + 1 <= props.count && "active"}
                  />
                );
              })}
          </ul>
        </div>
      </div>

      <div className="course-sidecallout mb-5 overflow-hidden p-4">
        <div className="d-flex align-items-baseline justify-content-between mb-4">
          <div className="position-relative ml-2">
            <div className="font-24 font-weight-bold">
              {props.question_title}
            </div>
            <div className="text-primary-light b-600 font-14 mt-1">
              {Utility.getQuestionType(props.question_type)}
            </div>
          </div>
          {props.count && props.count > 1 && props.editQuestion !== true && (
            <div
              className="text-center cursor-pointer"
              onClick={() => props.previousQuestion()}
            >
              <RedoIcon />
              <div className="font-14 mt-1">Go Back</div>
            </div>
          )}
        </div>
        <ul className="list-unstyled pt-3 ml-4">
          {props.question_type !== 3 &&
            props.question_options_attributes &&
            props.question_options_attributes.map((item, i) => (
              <li className="mb-4" key={`key_${i}`}>
                <div className="d-flex">
                  <label className="cursor-pointer mb-0">
                    <input
                      type="checkbox"
                      className="toolbox-card-checkbox"
                      checked={
                        props.question_options_attributes[i].selected_answer
                      }
                      onClick={(ev) => props.onToggleClick(ev, i)}
                    />
                    <div className="toolbox-card-checkbox-outer assignment-checkbox">
                      <TickFilledIcon fillClass="fill-primary" />
                    </div>
                  </label>
                  {props.question_type !== 3 ? (
                    <div className="font-18 ml-3 font-weight-bold">
                      {i + 1}.{" "}
                      {props.question_options_attributes[i].option_value}
                    </div>
                  ) : (
                    <div className="font-18 ml-3 font-weight-bold">Answer</div>
                  )}
                </div>
              </li>
            ))}
          {props.question_type === 3 && (
            <RichTextEditor
              id="essayContent"
              name="essayContent"
              data={props.question_options_attributes[0].essayContent || ""}
              changeDescription={props.changeEssay}
              placeholder="Write your answer here..."
            />
          )}
        </ul>
      </div>

      <div className="d-flex flex-column align-items-center">
        <button
          className="btn btn-primary btn-half-rounded px-5 mb-4 font-weight-bold"
          onClick={() => props.nextQuestion()}
        >
          Continue
        </button>
        {CLIENT_ID === GIL_CLIENT_ID ? (
          <button className="btn btn-link" onClick={props.closeCallOut}>
            CANCEL
          </button>
        ) : (
          <button className="btn btn-link">CANCEL</button>
        )}
      </div>
    </>
  );
};

export default AssignmentQuestion;
