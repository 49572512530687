import React, { Component } from 'react';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { MENU } from  'config/constants';
import { Utility } from 'utils';
import { MenuTitle } from 'shared/components/templates/menu_list';
import { OVERVIEW_STATS_LIST } from 'config/constants/api_endpoints';
import { NoRecordFound } from 'shared/components/no_record_found';
import { toast } from 'react-toastify';
import TableView from 'shared/components/table_view';
import Tooltip from '@material-ui/core/Tooltip';

class OverviewStatsList extends Component {
  constructor(props){
    super(props);

    this.state = { tag: null, showLoader: false };
  }

  generateCsvForList = (selectedCard, param) => {
    this.setState({ showLoader: true });
    let endPoint = '';
    selectedCard = selectedCard.toLowerCase().split(' ').join('_');

    switch (selectedCard) {
      case 'registrations_list':
        endPoint = 'generate_registrations_csv';
        break;
      case 'pledges_list':
        endPoint = 'generate_pledges_csv';
        break;
      case 'volunteers_list':
        endPoint = 'generate_volunteers_csv';
        break;
      default:
    }

    const params = { client_id: param.client_id };

    Utility.sendRequest(`${OVERVIEW_STATS_LIST}/${endPoint}`, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      toast.success('CSV generated successfully, please download it by clicking on download icon.', { containerId: 'private' });

      this.setState({ tag: body.tag, showLoader: false });
    });
  }

  render(){
    const type = !Utility.isEmpty(this.props.selectedCard) ? this.props.selectedCard[0].toLowerCase() : '';
    const downloadUrl = process.env.REACT_APP_BASE_API_URL + '/v1/web/statistics/download?fileName=' + this.state.tag + '&m=s&t=' + type;

    return (
      <>
        <Col xl={9} lg={8} xs={12} className="ModalOpen h-md-100 scroll-y top-on-sm d-lg-block" id="overview_list" >
          <div className="d-flex justify-content-between position-relative bg-white p-3 border-bottom back-button-sm">
            <Button variant="link" className="color-primary text-left align-items-center" onClick={() => this.props.updateSelectedMenuItem(MENU.statistics[0])}>
              <img className="width-10 align-baseline mr-3" src={require("assets/images/chevron_left.svg")} alt="left-icon" />
              Back to Overview
            </Button>
            <div className="d-flex justify-content-between position-relative bg-white px-3">
              <div>
                <Button className="color-primary text-left align-items-center justify-content-center" disabled={Utility.isEmpty(this.props.body) ? true : false} onClick={() => this.generateCsvForList(this.props.selectedCard, this.props.userData)}>
                  {this.state.showLoader ? <Spinner animation="border" role="status"/> : 'Generate CSV'}
                </Button>
              </div>
              <div className="d-flex align-items-center ml-1">
                { !Utility.isEmpty(this.state.tag) &&
                  <Tooltip title="Download CSV">
                    <a href={downloadUrl}><img className="width-10 align-baseline mr-3 icon-body" src={require("assets/images/icons/download_icon.svg")} alt="down-icon" /></a>
                  </Tooltip>
                }
              </div>
            </div>
          </div>
          <div className="p-4">
            <Row className="mx-0 p-sm-3">
              <div className="network-box-shadow h-100 mt-3 mt-sm-0">
                <div className="network-item-header d-flex align-items-center mt-md-1">
                  <div className="round-circle mr-2">
                  </div>
                  <MenuTitle menuName={this.props.selectedCard}/>
                </div>
              </div>
            </Row>
            <div className="px-sm-4">
              <div className="mt-2">
                <div>
                  { (!Utility.isEmpty(this.props.body) && !Utility.isEmpty(this.props.headers)) ?
                    <TableView data={this.props.body} headers={this.props.headers}/>
                    : <NoRecordFound />
                  }
                </div>
              </div>
            </div>
          </div>
        </Col>
      </>
    );
  }
}

export default OverviewStatsList;
