import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Dropdown } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import WelcomeSection from './welcome_section';
import * as Utility from 'utils/utility';
import { connect, useSelector } from "react-redux";
import { openNeighborhoodForm, updateIsNeighborhoodFormOpen, showLoader,updateOpenCityPurchase, updateAccountList, updateUserData, updateRefreshCampaignAPI, updateCampaignFormOpen, updateIsChatFormOpen, updateSeeAll } from 'shared/actions';
import { NeighborhoodSignUp } from 'scenes/users/components/neighborhood_sign_up';
import ReactPlayer from 'react-player';
import Dialog from '@material-ui/core/Dialog';
import { CloseIcon } from 'shared/icons/icons';
import cookie from 'react-cookies';
import Slide from '@material-ui/core/Slide';
import { SuggestedReadsCard } from 'shared/components/suggested_reads_articles';
import { CLIENT_ID } from 'config/constants/config';
import { CAMPAIGNS_TOP_STORIES, LICENSE, SUPPORT_USER } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { subscribeUser } from 'subscription.js';
import PurchaseCityAccount from '../../../profile/components/PurchaseCityAccount';
import GetKeysToYourCity from '../../../profile/components/GetKeysToYourCity';
import AfterPurchase from '../../../profile/components/AfterPurchase';

const HomePage = (props) => {
  const refreshCampaignApi = useSelector(state => state.refreshCampaignApi);
  const [smPlaying, setSmPlaying] = useState(false);
  const [campaignsTopStories, setCampaignsTopStories] = useState([]);
  const [licenses, setlicenses] = useState([]);
  const [purchaseCityAccount, setpurchaseCityAccount] = useState(false);
  const [newUserClientDetails, setnewUserClientDetails] = useState({});
  const [afterPurchase, setafterPurchase] = useState(false);
  const [transactionData, settransactionData] = useState({});
  const [cityFormData, setcityFormData] = useState({});
  const [supportUsers, setsupportUsers] = useState([]);

  useEffect(() => {
    if (cookie.load('sessionToken') !== undefined && props.userData)
      subscribeUser(props.userData.id);

    if (props.location.calledFrom === 'suggestedReadsSeeAll') {
      props.updateSeeAll({ isOpen: 'article', calledFrom: 'article' });
    }
    if (props.location.calledFrom === 'communitySeeAll') {
      props.updateSeeAll({ isOpen: 'community', calledFrom: 'community' });
    }
    return () => props.updateCampaignFormOpen(false);
  }, []);

  const getCampaignsTopStoriesDetails = (seeAll = false) => {
    const params = {
      publisher_network_id: CLIENT_ID,
      limit: 12,
    };

    if(seeAll) {
      delete params.range;
    }

    Utility.sendRequest(CAMPAIGNS_TOP_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.length)
          setCampaignsTopStories(body);
      }
    });
  };

  useEffect(() => {
    getLicenses();
    getSupportTeamMembers();
    getCampaignsTopStoriesDetails();
    props.updateOpenCityPurchase(false);
    props.updateIsNeighborhoodFormOpen(false);
    props.openNeighborhoodForm({});
  }, []);

  const paintSmVideo = () => {
    return (
      <>
        <div className='player-wrapper'>
          <ReactPlayer
            playing={smPlaying}
            url={require("assets/videos/Sharemeister Promo.mov")}
            className='react-player video-fit-screen-sm vh-100 vw-100'
            width='100%'
            controls={true}
            playsInline={true}
            loop={false}
            onEnded={() => setSmPlaying(false)}
            config={{
              youtube: {
                playerVars: {
                  rel: 0,
                  showinfo: 0,
                  fs: 1,
                  disablekb: 0,
                  controls: 1,
                  modestbranding: 1,
                }
              }
            }}
          />
          <div className="landing-page-video-close-sm" onClick={() => setSmPlaying(false)}>
            <CloseIcon
              mainClass="cursor-pointer"
              fillClass="fill-white"
            />
          </div>
        </div>
      </>
    );
  };

  const setCityFormData = (data) => {
    setcityFormData(data);
  };

  const getLicenses = () => {
    props.showLoader(true);
    const params = {
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(LICENSE, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      props.showLoader(false);

      if (!body.error) {
        setlicenses(body);
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  const openNeighborhoodForm = (userData) => {
    const data = {
      showForm: true,
      edit: userData ? true : false,
      id: userData && userData.id,
      roleId: userData && userData.role_id,
    };

    props.openNeighborhoodForm(data);
  };

  const editCloseForm = () => {
    props.openNeighborhoodForm({});
  };

  const getSupportTeamMembers = () => {
    const params = {
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(SUPPORT_USER, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setsupportUsers(body);
      }
    });
  };


  return (
    <>
      {/* <section className="dashboard-welcome">
        <div className="dashboard-welcome-inner">
        <div className="close"><Image src={require("assets/images/close.png")} alt="Close" /></div>
          <Container>
            <Row className="slide">
              <Col md={3}>
                <div className="top-helping-image">
                  <div className="first-image"><Image src={require("assets/images/welcome-triangle.png")} alt="Helping Image" />
                  </div>
                  <div className="second-image"><Image src={require("assets/images/welcome-circle.png")} alt="Helping Image" />
                  </div>

                </div>
              </Col>
              <Col md={7}>
                <div className="welcome-detail">
                  <div className="title text-black font-50 b-700">Welcome to Dashboards!</div>
                  <div className="text-black font-28 b-600 mb-3 line-height-normal">Organize your digital community <br />experience through command centers.</div>
                  <p className="font-20 text-black ">Ask for a customized DEMO to learn how all the <br />available features of this module can be used to serve<br /> the requirements of your digital application.</p>
                  <button className="btn btn-alternate my-4 font-18">Check it Out</button>
                  <div class="check-msg">
                    <input type="checkbox" id='check' />
                    <label for="check">Thanks! I got the message</label>
                  </div>
                  <div className="bottom-helping-image">
                    <Image src={require("assets/images/welcome-plus.png")} alt="Sharemeister Logo" />
                  </div>
                </div>

              </Col>
            </Row>
          </Container>

        </div>
      </section> */}
      <section className="home-page">
        <div className="home-page-sharemeister">
          <Container className="welcome-card-sharemeier mb-4 mb-xl-0" >
            <Row>
              <Col md={12}>
              <WelcomeSection
                isFormOpen={props.isCampaignFormOpen}
                setIsFormOpen={(arg) => props.updateCampaignFormOpen(arg)}
                showLoader={props.showLoader}
              />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="home-page-city">
          <Container>
            <Row>
              <Col md={12}>
                <div className="font-24 text-center text-lg-left b-700 mt-xl-5 mb-4 tilte pt-3">Digital Utility Services</div>
                <div className="d-flex mb-4 pb-3 flex-wrap home-page-options">
                  <Dropdown className="events-none">
                    <Dropdown.Toggle variant="support-team" id="dropdown-basic">
                      All
                    {/* <div className="ml-auto toggle-icon" >
                        <img
                          src={require('assets/images/icons/chevron_down.svg')}
                          alt="drodown-icon"
                        />
                      </div> */}
                    </Dropdown.Toggle>
                  </Dropdown>

                  <div className="ml-auto d-flex">
                    <InputField
                      type="text"
                      className="input-form-size search-follower"
                      placeholder="Search…"
                    />
                    <img
                      src={require('assets/images/profile/new-profile/setting-icon.svg')}
                      alt="drodown-icon"
                      className="ml-3"
                    />
                  </div>
                </div>
                <div className="landing-page-stories d-flex flex-wrap justify-content-between home-page-articles mr-n3">
                  <SuggestedReadsCard
                    stories={campaignsTopStories}
                  />
              </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {
        smPlaying && <FullScreenDialog
          childern={paintSmVideo()}
          isOpen={smPlaying}
          handleClose={() => setSmPlaying(false)}
        />
      }
       {props.openCityPurchase && (
          <GetKeysToYourCity
            openNeighborhoodForm={openNeighborhoodForm}
            userData={props.userData}
            licenses={licenses}
            handleClose={() => props.updateOpenCityPurchase(false)}
          />
        )}
        {purchaseCityAccount && (
          <PurchaseCityAccount
            newUserClientDetails={newUserClientDetails}
            handleClose={() => setpurchaseCityAccount(false)}
            showAfterPurchase={(data) => {
              setafterPurchase(true);
              settransactionData(data);
            }
            }
            cityFormData={cityFormData}
            userData={props.userData}
          />
        )}

        {afterPurchase && (
          <AfterPurchase
            transactionData={transactionData}
            handleClose={() => setafterPurchase(false)}
            showLoader={props.showLoader}
            updateUserData={props.updateUserData}
            history={props.history}
            supportUsers={supportUsers}
            {...props}
          />
        )}

        {!Utility.isEmpty(props.neighborhoodState) &&
          props.neighborhoodState.showForm && (
            <NeighborhoodSignUp
              forCityAccount={true}
              showPurchaseCityAccount={(data) => {
                setnewUserClientDetails(data);
                setpurchaseCityAccount(true);
              }
              }
              userData={props.userData}
              history={props.history}
              neighborhoodState={props.neighborhoodState}
              edit={false}
              id={props.neighborhoodState.id}
              openNeighborhoodForm={props.openNeighborhoodForm}
              closeForm={editCloseForm}
              showLoader={props.showLoader}
              updateAccountList={props.updateAccountList}
              updateUserData={props.updateUserData}
              setCityFormData={setCityFormData}
              supportUsers={supportUsers}
              {...props}
            />
          )}
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog({ childern, isOpen, handleClose }) {
  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
        {childern}
      </Dialog>
    </div>
  );
}

const mapActionToProps = { showLoader, updateOpenCityPurchase, updateIsNeighborhoodFormOpen, updateRefreshCampaignAPI, openNeighborhoodForm, updateAccountList, updateUserData, updateCampaignFormOpen, updateIsChatFormOpen, updateSeeAll };

const mapStateToProps = (state) => ({
  userData: state.userData,
  isLoading: state.isLoading,
  accountList: state.accountList,
  neighborhoodState: state.neighborhoodState,
  isCampaignFormOpen: state.isCampaignFormOpen,
  isChatFormOpen: state.isChatFormOpen,
  chatFriend: state.chatFriend,
  seeAll: state.seeAll,
  refreshCampaignApi: state.refreshCampaignApi,
  openCityPurchase: state.openCityPurchase
});

export default connect(mapStateToProps, mapActionToProps)(HomePage);
