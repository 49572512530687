import React, { useState, useEffect } from 'react';
import HomeContainer from 'shared/components/home_container';
import EventCard from 'shared/components/cards/event_card';
import CreatebleCard from 'shared/components/cards/creatable_card';
import CalendarCallout from './calendar_callout';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Utility } from 'utils';
import { InputField } from 'shared/components/form_elements';
import { Form, Spinner } from 'react-bootstrap';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';
import { EVENT_CAMPAIGNS, ALL_PUBLIC_EVENT_CAMPAIGNS, EVENT_INVITATIONS_LIST, ALL_PARENT_CAMPAIGNS } from 'config/constants/api_endpoints';
import { CLIENT_ID, CRM_MODULES, GROUPS_EVENTS_LIMIT } from 'config/constants/config';
import { STATUS } from 'config/constants';
import CreateCampaign from '../../users/components/create_campaign';
import moment from 'moment';
import { showLoader, updateSeeAll, updateCampaignFormOpen, updateCampaignSelectedTab, updateIsCampSelFormOpen, updateRefreshCampaignAPI } from 'shared/actions';
import { useDispatch } from 'react-redux';
import { ClockIcon } from 'shared/icons/icons';
import { DoubleArrowLeftIcon, DoubleArrowRightIcon, CalenderIcon, CloseIcon, ChevronUpIcon } from 'shared/icons/icons';
import SeeAll from 'shared/components/v2/see_all';

const EventHome = (props) => {
  const dispatch = useDispatch();
  const [eventList, setEventList] = useState([]);
  const [publicEventList, setPublicEventList] = useState([]);
  const [publicEventDate, setPublicEventDate] = useState(moment().format('DD MMMM YYYY'));
  const [count, setCount] = useState(0);
  const [eventInvitationList, setEventInvitationList] = useState([]);
  const [activeRightSection, setActiveRightSection] = useState('none');
  const [pageLoad, setPageLoad] = useState(1);
  const [startOfDay, setStartOfDay] = useState(moment.utc(moment().startOf('day').format()).format())
  const [endOfDay, setEndOfDay] = useState(moment.utc(moment().endOf('day').format()).format())
  const [search, setSearch] = useState('');
  const [loader, setLoader] = useState(false);
  const [seeMoreOrLess, setSeeMoreOrLess] = useState("See More");
  const [eventsLength, setEventsLength] = useState(0);
  let debouncedFn = null;

  useEffect(() => {
    props.showLoader(true);
    getPublicEvents();
    getEventsInvitations();
    getEvents(0);
    getEvents();
    dispatch(updateCampaignFormOpen(false));
  }, []);

  useEffect(() => {
    setPublicEventDate(moment().add(`${count}`, 'months').format('DD MMMM YYYY'));
  }, [count]);

  useEffect(() => {
    getEvents();
    getPublicEvents();
    getEventsInvitations();
    dispatch(updateCampaignFormOpen(false));
  }, [pageLoad]);

  useEffect(() => {
    getPublicEvents();
  }, [search, publicEventDate]);

  const paintUserEvents = () => {
    const arr = [];
    eventList.length &&
      eventList.map(e => {
        arr.push(
          <EventCard
            {...e}
            key={e.id}
            eventDetailLink={true}
            goBackLink="/events"
          />
        );
      });
    return arr;
  };

  const paintMyInvitations = () => {
    const arr = [];
    eventInvitationList.length &&
      eventInvitationList.map(e => {
        arr.push(
          <EventCard
            {...e}
            key={e.id}
            displayAddBtn={true}
            pageLoad={setPageLoad}
            goBackLink="/events"
          />
        );
      });
    return arr;
  };

  const getDateInFormat = (date) => {
    return moment.utc(date).local().format("MMMM DD, YYYY");
  };

  const paintPublicEvents = () => {
    const arr = [];
    publicEventList.length &&
      publicEventList.map(e => {
        arr.push(
          <div className="mt-3 event-page-card" >
              <h1 className="font-18-bold my-4" >{getDateInFormat(e.date)}</h1>
            <div className="event-container d-flex flex-wrap" >
              {e.data.map((event) => {
                const displayAddBtn = !(event.calendar_details_status);
                return <EventCard
                  {...event}
                  key={event.id}
                  displayAddBtn={displayAddBtn}
                  pageLoad={setPageLoad}
                  eventDetailLink={true}
                  goBackLink="/events"
                />;
              })}
            </div>
          </div>
        );
      });
    return arr;
  };

  const getEvents = (limit = 3) => {
    props.showLoader(true);

    const params = {
      client_id: CLIENT_ID,
      user_client_id: props.userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.event,
    };
    if(limit === 3) {
      params.limit = limit;
    }
    else
    params.all = true;

    Utility.sendRequest(ALL_PARENT_CAMPAIGNS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setEventList(body.data);
        if(params.all) {
          setEventsLength(body.data.length);
        } 
      }
      props.showLoader(false);
    });
  };

  const getEventsInvitations = () => {
    props.showLoader(true);

    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: props.userData.id,
      campaign_type_id: CAMPAIGN_TYPES_IDS.event
    };

    Utility.sendRequest(EVENT_INVITATIONS_LIST, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setEventInvitationList(body.data);
      }
      props.showLoader(false);
    });
  };

  const getPublicEvents = () => {
    setLoader(true);

    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: props.userData.id,
      campaign_type_id: CAMPAIGN_TYPES_IDS.event,
      current_time: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      start_of_day: startOfDay,
      end_of_day: endOfDay,
      search,
      date_for_month: publicEventDate,
    };

    if (moment(publicEventDate).format('MMMM YYYY') !== moment().format('MMMM YYYY')) {
      params.start_of_day = moment.utc(moment(publicEventDate).startOf('day').format()).format();
      params.end_of_day = moment.utc(moment(publicEventDate).endOf('day').format()).format();
    }

    Utility.sendRequest(ALL_PUBLIC_EVENT_CAMPAIGNS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setPublicEventList(body.data);
      }
      setLoader(false);
    });
  };

  const handleDateSelect = (date) => {
    setPublicEventDate(date.format("YYYY-MM-DD HH:mm:ss"));
    setStartOfDay(moment.utc(date.startOf('day').format()).format());
    setEndOfDay(moment.utc(date.endOf('day').format()).format());
  };

  const handleClick = (arg) => {
    dispatch(updateCampaignFormOpen(arg));
    dispatch(updateCampaignSelectedTab(CAMPAIGN_TYPES_IDS.event));
  };

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const paintActiveRightSection = () => {
    return (
      <div className="article-page-side-outer scroll-y overflow-auto">
        <div className="article-page-side h-100" >
          <div className="article-page-side-head py-3 px-4 align-items-center border-bottom" >
            <CalenderIcon fillClass="fill-less-black" />
            <span className="display-8 font-bold ml-2" >Calendar</span>
            <ChevronUpIcon
              handleClick={() => setActiveRightSection('none')}
              mainClass="chevron-up-icon"
            />
            <CloseIcon
              mainClass="ml-auto cursor-pointer"
              handleClick={() => setActiveRightSection('none')}
            />
          </div>
          <div className="article-page-side-body py-3 px-md-4 px-3">
            <CalendarCallout
              userClientId={props.userData.id}
              handleChange={handleDateSelect}
            />
          </div>
        </div>
      </div>
    );
  };
  const getTime = (startDate) => {
    const start_time = new Date(startDate);
    return start_time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  };

  const handleEventClick = (id) => {
    props.history.push({
      pathname: `/campaign/${id}`,
      goBackLink: '/media',
    });
  };

  const seeMoreOrLessCourses = () => {
    if(seeMoreOrLess === 'See More') {
      setSeeMoreOrLess("See Less");
      getEvents(0);
  }
  else {
      setSeeMoreOrLess("See More");
      getEvents();
  }
  };

  return (
    <>
      <HomeContainer
        name="Events"
        className="event-home"
        goBackLink="/home"
        history={props.history}
      >
        {Utility.pwaModeEnabled() &&
          <div className="mb-4 font-15" >Create and join physical and virtual events in the community</div>
        }
        {!Utility.pwaModeEnabled() ?
          <div className="event-page-outer" >
            <h1 className="font-24-bold mb-4 home-page-heading" >
              My Events
          {eventList.length > 2 &&
                <span
                  className="see-all-text display-7"
                  onClick={() =>
                    props.updateSeeAll({ isOpen: 'myEvents', calledFrom: 'myEvents' })
                  }>
                  See All
            </span>
              }
            </h1>
            <div className="event-container d-flex flex-wrap" >
              {
                eventList.length === 0 && !Utility.pwaModeEnabled() &&
                <CreatebleCard
                  className="create-group-upload additional-card"
                  useUpload={false}
                  handleClick={() => handleClick(true)}
                  title='Create your own event'
                />
              }
              {paintUserEvents()}
            </div>
          </div>
          :
          <div className="event-page-outer">
            <h1 className="font-24-bold mb-4 home-page-heading" >
              My Events
        </h1>
            {!Utility.isEmpty(eventList) ?
               eventList.map((items, index) => {
                return (
                  <>
                    <div key={index} className="pwa-chat-view pl-2 mr-n1" >
                      <div onClick={() => handleEventClick(items.id)} className="position-relative pwa-chat-unit px-0 align-items-center flex-nowrap position-relative" >
                        <img className="pcu-img rounded" src={items.image_url} alt="" />
                        <div className="event-time-box" >
                          <div className="event-time-box-day" >{moment(items.start_at).format('ddd')}</div>
                          <div className="event-time-box-date" >{new Date(items.start_at).getDate()}</div>
                        </div>
                        <div className="pcu-text-outer ml-4" >
                          <div className="font-weight-bold text-truncate display-8" >{items.heading}</div>
                          <div className="text-truncate font-14 b-500 mt-1 align-items-center" >
                            <ClockIcon mainClass="mr-2" />
                            <span className="pl-1 font-medium" style={{color: '#5c6978'}} >
                              {getTime(items.start_at)},{" "}{"EST"}
                            </span>
                          </div>
                        </div>
                        <div onClick={(e) => e.stopPropagation()} className="ml-auto flex-center" >
                              <img src={require('assets/images/icons/menu_vertical_icon.svg')} alt="more icon" style={{width: 5}} />
                          </div>
                      </div>
                    </div>
                  </>
                );
              })
              :
              <div className="d-flex align-items-center justify-content-center mt-5" >
              <div className="px-5" >
                <span className="font-bold display-6">No events found</span>
              </div>
            </div>
            }
            {Utility.pwaModeEnabled() && eventsLength > 3 &&
            <div className="text-center mb-4 mt-2" >
                <span onClick={() => seeMoreOrLessCourses()} className ='cursor-pointer font-weight-bold display-8 text-primary'>{seeMoreOrLess}</span>
              </div>
            }
          </div>
        }
        {
        (eventInvitationList.length > 0) &&
        <div className="mt-3" >
          <h1 className="font-24-bold mb-4" >My Invitations</h1>
          <div className="event-container d-flex flex-wrap" >
            {paintMyInvitations()}
          </div>
        </div>
        }
        <div className="event-page-data" >
          <div className="mt-3 pb-4" >
            <h1 className="font-24-bold mb-4" >Public Events</h1>
            <Form className="shar-form search-form search-form-pwa sidebar-form" >
              <Form.Group className="mb-0 align-items-center flex-nowrap d-flex" >
                <InputField
                  required={true}
                  type="text"
                  placeholder='Search Public Events... '
                  handleChange={(ev) => handleSearch(ev)}
                  className="mr-3 mb-0"
                />
                {Utility.pwaModeEnabled() &&
                  <img src={require('assets/images/search.svg')} className="event-search-icon position-absolute" alt="search icon" />
                }
                {loader &&
                  <Spinner animation="border" size="sm" role="status" />
                }
              </Form.Group>
            </Form>
          </div>
          <div className="d-flex event-date-outer align-items-center justify-content-center" >
            <DoubleArrowRightIcon
              mainClass="cursor-pointer"
              handleClick={() => setPublicEventDate(
                moment(publicEventDate)
                  .subtract('1', 'months')
                  .startOf('month')
                  .format('DD MMMM YYYY'))}
            />
            <div className="px-5" onClick={() => setActiveRightSection('calendar')}>
              <CalenderIcon mainClass="mr-3 mt-n2 cursor-pointer" />
              <span className="font-bold display-6 cursor-pointer">
                {moment(publicEventDate).format('MMM YYYY')}
              </span>
            </div>
            <DoubleArrowLeftIcon
              mainClass="cursor-pointer"
              handleClick={() => setPublicEventDate(
                moment(publicEventDate)
                  .add('1', 'months')
                  .startOf('month')
                  .format('DD MMMM YYYY'))}
            />
          </div>
        </div>
        {Utility.isEmpty(publicEventList) &&
          <div className="d-flex align-items-center justify-content-center mt-5 mb-5 mb-lg-0" >
            <div className="px-5" >
              <span className="font-bold display-6">No events found</span>
            </div>
          </div>
        }
        {paintPublicEvents()}
      </HomeContainer>
      {
        props.seeAll.isOpen &&
        <SeeAll
          closeCallout={() =>
            props.updateSeeAll({ isOpen: null, calledFrom: null })
          }
          calledFrom={props.seeAll.calledFrom}
          width={'100%'}
        />
      }
      {
        activeRightSection === "calendar" &&
        paintActiveRightSection()
      }
      { props.isCampaignFormOpen &&
        Utility.pwaModeEnabled() &&
        <CreateCampaign
          doRefresh={() => props.updateRefreshCampaignAPI(Math.random())}
          position='position-absolute gil-pwa-callout gil-pwa-campaign-create'
          loadedFrom='webCampaign'
          isFormOpen={props.isCampaignFormOpen}
          setIsFormOpen={(arg) => props.updateCampaignFormOpen(arg)}
        />
      }
    </>
  );

};

const mapActionToProps = { showLoader, updateSeeAll, updateCampaignFormOpen, updateCampaignSelectedTab, updateIsCampSelFormOpen, updateRefreshCampaignAPI };

const mapStateToProps = (state) => ({
  userData: state.userData,
  isCampaignFormOpen: state.isCampaignFormOpen,
  campaignSelectedTab: state.campaignSelectedTab,
  seeAll: state.seeAll,
});

export default connect(mapStateToProps, mapActionToProps)(EventHome);
