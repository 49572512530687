import React, { Component } from 'react';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
// import { GoPlus } from "react-icons/go";
import Overlay from 'shared/components/overlay';
import { connect } from 'react-redux';
import { Utility } from 'utils';
import { SEARCH_MINIMUM_LENGTH } from  'config/constants';
import { CHATS_CLIENTS } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { NoRecordFound } from 'shared/components/no_record_found';

class AttachFriend extends Component {
  _isMounted  = false;

  constructor(props) {
    super(props);

    this.state = {
      selectedFriend: {},
      friends: [],
      showLoader: false,
      searchText: '',
    };
  }

  componentDidMount = () => this._isMounted = true;

  componentWillUnmount = () => this._isMounted = false;

  handleKeyUp = (e) => {
    if (e.target.value.length < SEARCH_MINIMUM_LENGTH) {
      this.setState({ friends: [], showLoader: false, searchText: '' });
      return;
    }
    const params = {
      client_id: this.props.userData.client_id,
      publisher_network_id: this.props.userData.client_id,
      user_clients_id: this.props.userData.id,
      search_text: e.target.value.trim(),
      source: ''
    };

    this.setState({ showLoader: true, searchText: params.search_text });

    Utility.sendRequest(CHATS_CLIENTS, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      let data = [];

      if (!body.error) {
        data = body.data;
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }

      if (this._isMounted) this.setState({ friends: data, showLoader: false });
    });
  }

  reset = () => {
    this.setState({ selectedFriend: {} });
    document.getElementById('friend_search').value = '';
  }

  render() {
    return (
      <React.Fragment>
        <Col xs={12} lg={8} xl={9} id="attach-friend" className="h-100 ModalOpen direct-message-container d-none d-lg-block">
          <Overlay title="Direct Message" iconName="message_icon.svg" goBack={this.props.goBack}>
            <div className="pl-2">
              <div className="px-0">
                <div className="padding-top-direct">
                  <div className="font-18-medium pr-4 pb-4">Find or start a direct conversation with</div>
                  <div className="border-bottom-dotted w-50"></div>
                  <Form className="mt-4" onSubmit={e => { e.preventDefault(); }}>
                    <Row className="no-gutters align-items-center">
                      <div className="pr-3 mb-3 shar-form w-xs-100 position-relative">
                        <Form.Control placeholder="Search for name..." type="text" data-role="tagsinput" id="friend_search" className="h-auto" onKeyUp={this.handleKeyUp} disabled={!Utility.isEmpty(this.state.selectedFriend)}/>
                        { Object.keys(this.state.selectedFriend).length > 0 &&
                          <div className="position-absolute close-chip">
                            <div className="grey-close-chip">
                              <div className="d-flex align-items-center justify-content-between padding-y-sm">
                                <div className="sm-round-circle">
                                  <img src={this.state.selectedFriend.image_url} alt="" className="sm-round-circle"/>
                                </div>
                                <div className="font-16-bold pl-2">{this.state.selectedFriend.name}</div>
                                <div className="pl-2">
                                  <Button variant="link" onClick={this.reset}>
                                    <img className="close-icon-width" src={require("assets/images/close_icon.svg")} alt="close-icon" />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="d-inline mb-3">
                        <Button onClick={this.props.startConversation.bind(this, this.state.selectedFriend)} variant="primary" disabled={Utility.isEmpty(this.state.selectedFriend)} >Start</Button>
                      </div>
                    </Row>
                  </Form>
                  <div className="py-4">
                    {/* <a href="#" className="font-18-bold"><span className="align-text-bottom pr-1"><GoPlus /></span>Invite</a> */}
                  </div>
                  { (!Utility.isEmpty(this.state.friends) || this.state.searchText.length >= SEARCH_MINIMUM_LENGTH) && <div className="border-bottom-dotted w-50"></div> }
                  <ul className="pt-4 search-list">
                    { !Utility.isEmpty(this.state.friends)
                      ? this.state.friends.map((friend, index) => {
                          return(
                            <li key={'ms-cl-' + index}>
                              <Button variant="link"
                                onClick={() => this.setState({ selectedFriend: friend, friends: [], searchText: '' }) }>
                                <div className="d-flex align-items-center py-2">
                                  {/* <div className="icon-active mr-1"></div> */}
                                  <img className="sm-round-circle" src={friend.image_url} alt=""/>
                                  <div className="font-16-bold pl-2">{friend.name}</div>
                                </div>
                              </Button>
                            </li>
                          );
                        })
                      : this.state.showLoader
                        ? <center><Spinner animation="border" role="status"/></center>
                        : this.state.searchText.length >= SEARCH_MINIMUM_LENGTH && <NoRecordFound/>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </Overlay>
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData
});

export default connect(mapStateToProps, {})(AttachFriend);
