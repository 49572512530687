import React, { Component } from 'react';
import cookie from 'react-cookies';
import { Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { InputField } from 'shared/components/form_elements';
import { Utility } from 'utils';
import { BACKSPACE } from 'config/constants';
import { CLIENT_NAME } from 'config/constants/config';
import jwt_decode from "jwt-decode";

export default class VerifyUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: null
    };
  }

  handleChange = () => {
    this.displayError('otp', '');
  }

  handleKeyUp = (e, type) => {
    if (type !== "otp4" && e.keyCode !== BACKSPACE) {
     this.refs[type].refs[type].nextSibling.nextSibling.focus();
   } else if (e.keyCode === BACKSPACE && type !== "otp1") {
     this.refs[type].refs[type].previousSibling.previousSibling.focus();
   }
  }

  submit = async (e) => {
    e.preventDefault();
    const validateField = await this.validateOtpFields();

    if (!(validateField.isFormValid)) {
      const errorMessage = 'All fields are required';
      this.displayError('otp', errorMessage);
      return;
    }

    this.verifyCode(validateField.code);
  }

  validateOtpFields = () => {
    var inputs = [];

    [1, 2, 3, 4].map(e => {
      inputs.push(document.getElementById(`otp${e}`).value);
    });

    let isFormValid = true;
    let code = '';

    inputs.forEach((input) => {
      if (Utility.isEmpty(input)) isFormValid = false;
      code = code.concat(input);
    });

    return { isFormValid, code };
  }

  displayError = (id, errorMessage) => {
    const error = Utility.getElementById(`${id}Error`);
    const data = this.state.isFormValid ? '' : errorMessage;
    error.textContent = data;
  }

  verifyCode  = (code) => {
    this.props.verifyCodeParams.code = code;
    this.setState({ showLoader: true });
    Utility.sendRequest(this.props.verifyCodeAPIName, 2, this.props.verifyCodeParams, (error, response, body) => {
      body = JSON.parse(body);

      this.setState({ showLoader: false });

      if (!body.error) {
        if (this.props.showNextScreen){
          this.props.showConfirmationScreen();
        } else if (this.props.editDetails) {
          this.props.backToForm();
        } else if (this.props.purchaseAccount){
          this.props.purchaseAccount();
        }
         else {
          cookie.save('sessionToken', body.token, { path: '/' });
          cookie.save("primaryId", (jwt_decode(body.token)).id, { path: "/" });
        if (this.props.handleNext) {
          this.props.handleNext();
        } else {
            this.props.history.push({
            pathname : '/profile',
            state :{
              fromSignUp: true
          } }
        );
        }
      }
      } else {
        this.setState({ showLoader: false });
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  resendVerificationCode = () => {
    Utility.sendRequest(this.props.resendActivationCodeAPI, 3, this.props.resendVerificationParams, (error, response, body) => {
      body = JSON.parse(body);

      this.setState({ showLoader: false });

      if (!body.error) {
        toast.success(body.message, { containerId: 'public' });
      } else {
        this.setState({ showLoader: false });
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  render() {
    return(
      <>
        <div className="container">
          <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3" >
            {
              this.props.showLogo &&
              <img src={require("assets/images/logos/main-logo.png")} className="register-logo mb-4" alt="sharemeister" />
            }
            <h3 className="mb-5 font-bold" >Enter verification code</h3>
            <Form className="pwa-form">
              <div className="new-landing-theme register-form">
                <div className="error errorIcon text-center" id="otpError" />
                  <Form.Group className="otp-input d-flex justify-content-center web-otp-inputs mx-auto">
                    <InputField
                      id="otp1"
                      type="tel"
                      name="otp1"
                      ref="otp1"
                      maxLength={1}
                      placeholder=" "
                      onKeyUp={(e) => this.handleKeyUp(e, "otp1")}
                      handleChange={this.handleChange}
                    />
                    <InputField
                      id="otp2"
                      type="tel"
                      name="otp2"
                      ref="otp2"
                      maxLength={1}
                      placeholder=" "
                      onKeyUp={(e) => this.handleKeyUp(e, "otp2")}
                      handleChange={this.handleChange}
                    />
                    <InputField
                      id="otp3"
                      type="tel"
                      name="otp3"
                      ref="otp3"
                      maxLength={1}
                      placeholder=" "
                      onKeyUp={(e) => this.handleKeyUp(e, "otp3")}
                      handleChange={this.handleChange}
                    />
                    <InputField
                      id="otp4"
                      type="tel"
                      name="otp4"
                      ref="otp4"
                      maxLength={1}
                      placeholder=" "
                      onKeyUp={(e) => this.handleKeyUp(e, "otp4")}
                      handleChange={this.handleChange}
                    />
                  </Form.Group>
                  <p className="display-9 text-center mb-4" >Your 4 digit code was sent to <br /> <span className="text-primary" >{this.props.platform}</span>  </p>
                  <p className="display-9 text-center" >By verifying your code you consent to receive notifications from {CLIENT_NAME}.</p>
              </div>
              <div className="text-center mt-auto bottom-text-position">
                <p className="display-8 mb-5" >I did not receive a code <br /><span className="text-primary font-bold cursor-pointer" onClick={this.resendVerificationCode}>RESEND</span> </p>
                <button
                  type="submit"
                  className="btn btn-theme btn-primary btn-login"
                  disabled={this.props.showLoader}
                  onClick={(e) => this.submit(e)}
                >
                  {
                    this.props.showLoader
                      ? <Spinner animation="border" role="status" />
                      : 'Continue'
                  }
                </button>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
