import React, { memo } from 'react';

let DetailListTitle = props => {
  return (
    <>
    <div className="align-items-center" >
      <div className="round-circle mr-2"></div>
      <span className="font-30-bold">{props.headerTitle}</span>
    </div>
    </>
  );
};

export default DetailListTitle = memo(DetailListTitle);
