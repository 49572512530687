import React, { useState, useEffect, useRef } from "react";
import { Form, Container, Button, Dropdown, Spinner } from "react-bootstrap";
import StripeCardElement from "shared/components/v2/campaign/components/stripe_card_elements";
import { CLIENT_PAYMENT_DETAILS, LICENSE, GET_TAX_RATE, API_PATH } from "config/constants/api_endpoints";
import { Utility } from "utils";
import { toast } from "react-toastify";
import { CLIENT_ID, PLAN } from "config/constants/config";
import { DEFAULT_TAX_PERCENTAGE, ETHOS_CLIENT_ID } from "config/constants";
import moment from "moment-timezone";
import { CloseIconSharp } from 'shared/icons/icons';
import { updatePlanUpgraded } from 'shared/actions';
import { connect } from "react-redux";
import axios from "axios";
const Purchase = (props) => {
  const [fullName, setFullName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [licenses, setlicenses] = useState([]);
  const [selectedLicense, setselectedLicense] = useState([]);
  const license = useRef({});
 const [amount, setAmount] = useState(0);
  const getLicenses = () => {
    const params = { clientId: CLIENT_ID };

    Utility.sendRequest(LICENSE, 1, params, async (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        const taxAmt = await getTaxAmount(props.userData.zip_code);      
        body.map((item)=>{
          return(
            item.base_fee = (Number(item.base_fee) + ((Number(item.base_fee) * Number(taxAmt)) / 100))
          )
        })
        setlicenses(body);
        setselectedLicense((body && body[0]) || {});
        license.current = (body && body[0]) || {};
        // !props.paywall && getTaxAmount(body[0].base_fee, props.userData.zip_code);
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };


  const getTaxAmount = async (zipCode) => {
    // // if(CLIENT_ID !== "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5"){
    //   Utility.sendRequest(GET_TAX_RATE, 1, { zip_code: zipCode }, (error, response, body) => {
    //     body = JSON.parse(body);
    //     if (!body.error) {
    //       let amount = body && body.rate && body.rate.state_rate != "0.0" ? body.rate.state_rate : DEFAULT_TAX_PERCENTAGE;
    //       return amount;
    //       // amount = Number(baseFee) + ((Number(baseFee) * Number(amount)) / 100);
    //       // localStorage.setItem('taxAmount', amount);
    //       // setAmount(amount);
    //     } else {
    //       return DEFAULT_TAX_PERCENTAGE;
    //       // const defaultAmount = baseFee + (baseFee * DEFAULT_TAX_PERCENTAGE) / 100;
    //       // localStorage.setItem('taxAmount', defaultAmount);
    //       // setAmount(defaultAmount);
    //     }
    //   });
    // // }
      let response;
      try{
        response = await axios.get(`${API_PATH}${GET_TAX_RATE}?zip_code=${zipCode}`);
        return response.data.rate.state_rate;
      }
      catch(err){
        return 0;
      }
    };

  useEffect(() => {
    getLicenses();
  }, []);

  const handleSubmit = (stripe, cardNumber) => {
    setShowLoader(true);
    stripe
      .createToken(cardNumber)
      .then(function (result) {
        if (result.error) {
          const errorElement = document.getElementById("card-errors");
          if (errorElement) errorElement.textContent = result.error.message;
          setShowLoader(false);
        } else {
          paymentDetailAPI(result, license.current);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const paymentDetailAPI = (result, license) => {
    const userData = props.userData;
    const taxamount = localStorage.getItem("taxAmount") || license.base_fee;
    const amount = `$${Utility.paintUptoTwoDecimalPlaces(taxamount)}`;
    const isCardSaved = document.getElementById("isCardSaved").checked;
    const stripe_plan_id = license.stripe_plan_id;

    const paymentDetails = {
      isCardSaved: true, // card need to be saved for subscription
      amount: parseFloat(amount.split("$")[1]),
      is_active: true,
      token: result.token,
      user_id: props.paywall ? userData.user_id : userData.UserClients[0].user_id,
      user_email: props.paywall ? userData.email : userData.UserClients[0].email,
      client_id: props.paywall ? userData.client_id : userData.UserClients[0].client_id,
      sender_user_client_id: props.paywall ? userData.id : userData.UserClients[0].id,
      receiver_user_client_id: "a917e0e1-740e-11ea-adc8-b73a2b77d69d",
      stripe_plan_id: stripe_plan_id,
      is_product: CLIENT_ID===ETHOS_CLIENT_ID?true:false
    };

    Utility.sendRequest(
      CLIENT_PAYMENT_DETAILS,
      2,
      paymentDetails,
      (error, response, body) => {
        body = JSON.parse(body);

        if (body.error) {
          setShowLoader(false);
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setShowLoader(false);
          props.updatePlanUpgraded(true);
          props.showAfterPurchase(buildSuccessMessage({ ...paymentDetails }, body.id, license));
          !props.paywall && props.onSkip();
        }
      }
    );
  };

  const buildSuccessMessage = (paymentDetails, transactionId, license) => {
    const dateTime = generateDateAndTime();
    return {
      date:
        dateTime.date.toString() +
        " | " +
        dateTime.time.toString() +
        " " +
        dateTime.zone.toString(),
      transaction: transactionId,
      license: license.name,
      plan: PLAN[license.period],
      amount: paymentDetails.amount,
      card:
        paymentDetails.token.card.brand.toUpperCase() +
        " ending in xxxx-" +
        paymentDetails.token.card.last4,
      sender:
        props.userData &&
        props.userData.first_name + " " + props.userData.last_name,
    };
  };

  const generateDateAndTime = () => {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const today = moment().tz(clientTimezone).format();
    return {
      date: moment(today).format("ll"),
      time: moment(today).format("hh:mm:ss A"),
      zone: moment.tz(today, clientTimezone).format("z"),
    };
  };

  const handleSelect = (e) => {
    const setLicense =
      licenses && licenses.filter((item) => item.days_count === parseInt(e))[0];
    setselectedLicense(setLicense);
    license.current = setLicense;
  };

  return (
    <Container fluid="true" className="h-md-100 px-0 scroll-y container">
      {props.paywall &&
         <div onClick={props.handleClose} className="modal-close-btn p-5 px-5 text-right cursor-pointer position-absolute w-100" >
         <CloseIconSharp fillClass="fill-white" />
       </div>
      }
      <div className="p-sm-5 p-4 mx-auto my-sm-5 my-3">
        {!props.paywall && <h3 className="font-bold mb-5">Purchase</h3>}
        {props.paywall && <h3 className="font-bold mb-5">{props.paywallFor && props.paywallFor === 'DOCUMENT' ? "Want to download the assignment?" : "Want to continue watching?"}</h3>}
        {props.paywall &&
          <div className="d-flex align-items-center pb-4 mb-lg-5 purchase-paywall-decorator position-relative flex-nowrap" >
          {props.paywallFor && props.paywallFor === 'DOCUMENT' ?
            <>
              <img src={require('assets/images/logos/main-logo.png')} className="purchase-paywall-img"  />
              <div className="ml-3 font-16" >
                <span className="font-weight-bold" >Download</span> the PDF assignment.
              </div>
            </> :
             <>
             <img src={props.campaignDetails && props.campaignDetails.image_url} className="purchase-paywall-img"  />
             <div className="ml-3 font-16" >
               <span className="font-weight-bold" >{props.campaignDetails && props.campaignDetails.heading}</span> has helped improve the lives of so many young leaders like yourself.
             </div>
           </>
          }
          </div>
        }
        {/* <p className="font-weight-bold display-6 mb-5" >Gain access to a library of Ethos Village legacy content and worldclass mentors for an entire for an entire year at $2.30 per week.</p> */}
        <Form>
          <div className="new-landing-theme register-form">
            <p className="font-weight-bold font-18 mb-3">Student Name</p>
            <Form.Group className="account-type-list">
              <Form.Control
                className="list-group-item active student-name font-bold"
                plaintext
                readOnly
                defaultValue={
                  props.userData &&
                  `${props.userData.first_name || ""} ${
                    props.userData.last_name || ""
                  }`
                }
              />
            </Form.Group>
            <p className="font-weight-bold font-18 mb-3">Payment Information</p>
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle
                variant="none"
                className="w-100 text-left font-weight-bold px-4"
              >
                {selectedLicense &&
                  `${
                    PLAN[selectedLicense.days_count]
                  } License - $${Utility.paintUptoTwoDecimalPlaces(
                    selectedLicense.base_fee
                  )} (includes tax)`}
                <div className="ml-auto mr-2 toggle-icon">
                  <img
                    src={require("assets/images/icons/chevron_down.svg")}
                    alt="drodown-icon"
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {licenses &&
                  licenses.map((license, key) => (
                    <Dropdown.Item key={key} eventKey={license.days_count}>{`${
                      PLAN[license.days_count]
                    } License - $${Utility.paintUptoTwoDecimalPlaces(
                      license.base_fee
                    )} (includes tax)`}</Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
            <Form className="card-detail" id="payment-form">
              <StripeCardElement
                paymentForm="payment-form"
                setZipCode={setZipCode}
                setFullName={setFullName}
                handleSubmit={handleSubmit}
              />
              <div className="check-msg">
                <input type="checkbox" id="isCardSaved" name="isCardSaved" />
                <label className="font-16" htmlFor="isCardSaved">
                  Save card to file
                </label>
              </div>
              <Form.Text className="font-16 my-4">
                <span>
                  By selecting, “Purchase”, you agree to have your card charged
                  ${Utility.paintUptoTwoDecimalPlaces(selectedLicense.base_fee)}{" "}
                  (includes taxes) on the 1st day of each {PLAN[license.days_count] === "Monthly" ? "calendar month" : "year"}.
                </span>
              </Form.Text>
              <Button
                disabled={showLoader}
                type="submit"
                variant="primary"
                className={`btn btn-theme btn-primary px-4 btn-get-started position-relative mt-3`}
              >
                {showLoader ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                ) : (
                  "Purchase"
                )}
              </Button>
            </Form>
          </div>
          {!props.paywall &&
            <p onClick={props.onSkip} className="font-18 mt-4 cursor-pointer">
            Skip
          </p>}
        </Form>
      </div>
    </Container>
  );
};

const mapActionToProps = { updatePlanUpgraded };

const mapStateToProps = (state) => ({
    isPlanUpgraded: state.isPlanUpgraded
  });

export default connect(mapStateToProps, mapActionToProps)(Purchase);
