import { UPDATE_ATTRIBUTES } from 'config/constants/action_types';

export const updateProductAttribute = (state = [], { type, payload }) => {
  switch(type){
    case UPDATE_ATTRIBUTES:
      return payload || [];
    default:
      return state;
  }
};
