import React, { Component } from 'react';
import UploadFile from 'shared/components/file_upload';
import TileSelection from 'shared/components/tile_selection';
import { Form, Dropdown, Tab, Tabs, Spinner, Button } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { CloseIcon } from "shared/icons/icons";
import { NAMES_MAPPING } from 'config/constants/config';
import { CircleFilledAddLargeIcon, ChevronDownIcon } from 'shared/icons/icons';
import { DropdownInside } from './dropdown_inside';
import { Utility } from 'utils';
import { SingleCampaign } from './single_campaign';
import { CLIENT_NAME, CLIENT_ID } from 'config/constants/config';
import { DateTimePicker, TimePicker } from '@material-ui/pickers';
import { MAX_DOCUMENT_SIZE } from 'config/constants';
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Radio } from 'shared/components/form_elements';
import moment from 'moment';
export class CreateForm extends Component {
  state = {
    shareEventKey: Utility.isEmpty(this.props.groups) ? 'public' : 'group',
    documentName: "",
    // event_schedule_id:'',
    // eventSchedule:[
    //                   {
    //                       "id": "1",
    //                       "name": "Doesn't repeat"
    //                   },
    //                   {
    //                       "id": "2",
    //                       "name": "Daily"
    //                   },
    //                   {
    //                       "id": "3",
    //                       "name": "Weekly on Monday"
    //                   },
    //                   {
    //                       "id": "4",
    //                       "name": "Monthly on the third Monday"
    //                   },
    //                   {
    //                       "id": "5",
    //                       "name": "Anually on November 15"
    //                   },
    //                   {
    //                       "id": "6",
    //                       "name": "Every Weekday (Mon to Fri)"
    //                   } 
    //               ]
  }

  componentDidUpdate(nextProps){
    if(this.props.groups !== nextProps.groups){
      const selectedTab = Utility.isEmpty(nextProps.groups) ? 'public' : 'group';
      this.setState({ shareEventKey: selectedTab });
    }
  }

  handleDocumentUpload = (event) => {
  const DOCUMENT_URL = "document_url";
  if (event.target.files[0]) {
    const reader = new FileReader();
    const originalFile = event.target.files[0];

     if (originalFile.size > MAX_DOCUMENT_SIZE){
       const errText = 'Document must be less than ' + (MAX_DOCUMENT_SIZE / 1048576) + 'MB.';
       toast.error(errText, { containerId: "public" });
       event.target.value = '';
       return;
     }else if (originalFile.type !== "application/pdf") {
       toast.error("Document must be PDF file.", { containerId: "public" });
       return;
     }
     reader.readAsDataURL(originalFile);
     reader.onloadend = () => { this.props.handleFileChange(DOCUMENT_URL, reader.result, originalFile);};
     this.setState({ documentName: originalFile.name });
    }
  }

  removeDocument = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.removeUploadedFile('document_url');
    document.getElementById('pwa-upload').value = null;
    this.props.newCampaign.document_url = null;
    this.setState({ documentName: null });
  }

  paintArticleDropdown = () => (
    <Form.Group className="mb-4 pb-1" >
      <Form.Label className="font-bold display-8 mb-3" >
      {`Create or add to an existing ${Utility.capitalize(this.props.name)}`}</Form.Label>
      <Dropdown className="article-dropdown form-control h-auto py-0" show={this.props.formStates.showDropdown}>
        <Dropdown.Toggle
          variant="none"
          className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100"
          id="dropdown-article"
          onClick={this.props.toggleDropdown}>
          { this.props.selectedCampaignLogic() }
          <ChevronDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none" >
        <div className="search-inside-dropdown shar-form">
          <InputField
            type="text"
            required={true}
            value={this.props.formStates.isNewChap ? this.props.formStates.heading : this.props.newCampaign.heading}
            placeholder={`Search for an ${Utility.capitalize(this.props.name)}...`}
            handleChange={this.props.handleSearch}
            onBlur={this.props.toggleDropdown}
          />
        </div>
        <DropdownInside
          isSearch={this.props.formStates.search}
          parent_heading={this.props.newCampaign.parent_heading}
          parent_image_url={this.props.newCampaign.parent_image_url}
          campaignsList={this.props.campaignsList}
          newCampaign={this.props.newCampaign}
          handleEditClick={this.props.handleEditClick}
          handleCreateClick={this.props.handleCreateClick}
        />
    </Dropdown.Menu>
    </Dropdown>
  </Form.Group>
  )

  paintGroupBox = () => (
    <>
      <Form.Label className="font-bold display-8 mb-3" >
      Group Name
      </Form.Label>
      <div className="article-dropdown form-control h-auto py-0 dropdown">
        <div className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100 dropdown-toggle btn btn-none">
          <SingleCampaign
          imageUrl={this.props.groupData.image_url}
          heading={this.props.groupData.name}
          handleClick={() => {}}
          />
        </div>
      </div>
    </>
  )

  paintTestimonyDropdown = () => (
    <Form.Group className="mb-4 pb-1" >
      <Form.Label className="font-bold display-8 mb-3" >
        Create or add to your testimony
      </Form.Label>
      <Dropdown
        className="article-dropdown form-control h-auto py-0"
        show={this.props.formStates.showDropdown}>
        <Dropdown.Toggle
          variant="none"
          className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100 flex-nowrap"
          id="dropdown-article"
          onClick={this.props.toggleDropdown}>
          { this.props.selectedCampaignLogic() }
          <ChevronDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none" >
          <DropdownInside
            isSearch={this.props.formStates.search}
            parent_heading={this.props.newCampaign.parent_heading}
            parent_image_url={this.props.newCampaign.parent_image_url}
            campaignsList={
              this.props.campaignsList.filter((item) => item.id !== this.props.newCampaign.id)
            }
            newCampaign={this.props.newCampaign}
            handleEditClick={this.props.handleEditClick}
            handleCreateClick={this.props.handleCreateClick}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  )

  paintChapterDropdown = () => (
    this.props.formStates.isEdit &&
    <Form.Group className="mb-4 pb-1" >
      <Form.Label className="font-bold display-8 mb-3" >
      {`Select ${NAMES_MAPPING[this.props.subCampaign]}`}</Form.Label>
      <Dropdown className="article-dropdown form-control h-auto py-0" >
        <Dropdown.Toggle variant="none" className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100 font-bold" id="dropdown-article">
        {
          this.props.formStates.isNewChap ? this.props.newChapter :
          !Utility.isEmpty(this.props.all_chapters_ids) &&
          this.props.all_chapters_ids.map((e, i) => {
          return e === this.props.formStates.selectedCampaignId ? `${NAMES_MAPPING[this.props.subCampaign]} ${i}` :  '';
          })
        }
        <ChevronDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none px-3" >
        {
          !Utility.isEmpty(this.props.all_chapters_ids) &&
          this.props.all_chapters_ids.map((e, index) => {
            return(
                <Dropdown.Item
                  key={e}
                  eventKey={e}
                  onSelect={this.props.handleChapterDropdown}
                  >
                  <div className="display-7 font-medium text-capitalize" >
                    {this.props.idForNewChap === e ?
                      <i>Create new {NAMES_MAPPING[this.props.subCampaign]}</i> :
                      `${NAMES_MAPPING[this.props.subCampaign]} ${index}`
                    }
                  </div>
                </Dropdown.Item>
            );
          })
        }
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  )

  paintTitleInput = () => (
    (this.props.formStates.isCreate &&  !this.props.formStates.isNewChap) &&
    <Form.Group className="mb-4 pb-1">
      <Form.Label className="font-bold display-8 mb-3">
        {this.props.name} Title <span className="text-red">*</span>
      </Form.Label>
      <InputField
        required={true}
        type="text"
        name='heading'
        ref='heading'
        value={this.props.newCampaign.heading}
        handleChange={this.props.handleInputChange}
        placeholder={`Enter ${Utility.capitalize(this.props.chapOrArticle)} Title`}
      />
    </Form.Group>
  )

  paintImageInput = () => (
    <Form.Group className="mb-4 pb-1" >
      <Form.Label className="font-bold display-8 mb-3">Add an Image <span className="text-red">*</span> </Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <label className="post-uplodad-label mb-0 cursor-pointer">
          <UploadFile
            name='image_url'
            inputId="image_url"
            removeText="Remove image"
            categoryName="publisher_campaign_image"
            acceptTypes="image/x-png,image/gif,image/jpeg"
            uploadedFilePreviewUrl={
              this.props.formStates.isEdit &&
              this.props.newCampaign.image_url ||
              this.props.formStates.imagePreviewUrl}
            handleFileChange={this.props.handleFileChange}
            removeUploadedFile={this.props.removeUploadedFile}
          />
          <div className="error errorIcon mb-3" id={'image_urlError'} />
          { !this.props.formStates.imagePreviewUrl &&
            <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
          }
        </label>
      </div>
      <div className="my-2 ml-1 display-8 font-medium" >Or</div>
      <InputField
        required={true}
        type="text"
        name="embedded_image_link"
        ref="embedded_image_link"
        value={this.props.newCampaign.embedded_image_link}
        handleChange={this.props.handleInputChange}
        placeholder="Embed Image URL..."
      />
    </Form.Group>
  )

  paintVideoInput = () => (
    <Form.Group className="mb-4 pb-1" >
      <Form.Label className="font-bold display-8 mb-3" >
        Add a Video
      </Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <label className="post-uplodad-label mb-0 cursor-pointer">
          <UploadFile
            name='video_url'
            inputId="video_url"
            removeText="Remove video"
            acceptTypes="video/mp4"
            uploadedFilePreviewUrl={this.props.formStates.videoPreviewUrl}
            handleFileChange={this.props.handleFileChange}
            removeUploadedFile={this.props.removeUploadedFile}
          />
          <div className="error errorIcon" id={'video_urlError'} />
          {
            !this.props.formStates.videoPreviewUrl &&
            <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
          }
        </label>
      </div>
      <div className="my-2 ml-1 display-8 font-medium" >Or</div>
      <InputField
        type="text"
        name='embedded_video_link'
        value={this.props.newCampaign.embedded_video_link}
        handleChange={this.props.handleInputChange}
        placeholder="Embed YouTube URL..."
      />
    </Form.Group>
  )

  paintArticleDetails = () => (
    <Form.Group className="mb-4 form-element-mb-20" >
      {
        this.props.categoriesList.length > 0 &&
          <>
          <Form.Label className="font-bold display-8 mb-3" >
            Select Category <span className="text-red">*</span>
          </Form.Label>
            <Dropdown className="article-dropdown form-control h-auto py-0" >
                <Dropdown.Toggle variant="none" className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100" id="category_id">
                {
                  !Utility.isEmpty(this.props.categoriesList) && !Utility.isEmpty(this.props.newCampaign.category_id) ?
                  this.props.categoriesList.map(e => {
                    return e.id === this.props.newCampaign.category_id ? e.name : '';
                  }) :
                  'Select category'
                }
                 <ChevronDownIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none px-3" >
                {
                  this.props.categoriesList.map(e => {
                    return(
                      <Dropdown.Item
                        key={e.id}
                        id="category_id"
                        name="category_id"
                        ref="category_id"
                        eventKey={['category_id', e.id]}
                        onSelect={this.props.handleDropdown}
                        >
                        {e.name}
                      </Dropdown.Item>
                    );
                  })
                }
                </Dropdown.Menu>
            </Dropdown>
            <div className="error errorIcon mb-3" id={'category_idError'} />
          </>
        }
        
        {
            (CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5' && this.props.formName === "event") &&
            <>
            <Form.Label className="font-bold display-8 mb-3" >
              Ranking <span className="text-red">*</span>
            </Form.Label>
              <Dropdown className="article-dropdown form-control h-auto py-0" >
                  <Dropdown.Toggle variant="none" className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100" id="ranking">
                  {
                    !Utility.isEmpty(this.props.newCampaign.ranking) ? this.props.newCampaign.ranking : 'Select ranking'
                  }
                   <ChevronDownIcon />
                  </Dropdown.Toggle>
  
                  <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none px-3" >
                  {
                    [1,2,3,4,5,6,7,8,9,10].map(e => {
                      return(
                        <Dropdown.Item
                          key={e}
                          id="ranking"
                          name="ranking"
                          ref="ranking"
                          eventKey={['ranking', e]}
                          onSelect={this.props.handleDropdownRanking}
                          >
                          {e}
                        </Dropdown.Item>
                      );
                    })
                  }
                  </Dropdown.Menu>
              </Dropdown>
              <div className="error errorIcon mb-3" id={'rankingError'} />
            </>
        }

        <Form.Label className="font-bold display-8 mb-3" >
          {Utility.capitalize(NAMES_MAPPING[this.props.subCampaign])} Title <span className="text-red">*</span>
        </Form.Label>
        <InputField
          required={true}
          type="text"
          name='sub_heading'
          ref="sub_heading"
          value={this.props.newCampaign.sub_heading}
          handleChange={this.props.handleInputChange}
          placeholder={`Enter ${Utility.capitalize(NAMES_MAPPING[this.props.subCampaign])} Title...`}
        />
        <Form.Label className="font-bold display-8 mb-3" >
          Add {NAMES_MAPPING[this.props.subCampaign] === NAMES_MAPPING.SUB_CAMPAIGN_EVENT ? 'an' : 'a'}&nbsp;
           {Utility.capitalize(NAMES_MAPPING[this.props.subCampaign])} Description <span className="text-red">*</span>
        </Form.Label>

          {/* <RichTextEditor
            id="description"
            name="description"
            data={this.props.newCampaign.description}
            changeDescription={this.props.changeDescription}
            placeholder="Add text..."
          /> */}
           <ReactQuill
           id="description"
           name="description"
            theme='snow'
            value={this.props.newCampaign.description}
            onChange={this.props.changeDescription}
            style={{ minHeight: '300px' }}
      />
       <div className="error errorIcon mb-3" id={'descriptionError'} />
        <Form.Label className="font-bold display-8 mb-3" >
          Add {Utility.capitalize(NAMES_MAPPING[this.props.subCampaign])} Tags
        </Form.Label>
        <InputField
          handleChange={() => <></>}
          type="text"
          placeholder="Add tags..."
        />
    </Form.Group>
  )

  paintAdditionalResources = () => (
    <Form.Group className="mb-4 form-element-mb-20" >
      <Form.Label className="font-bold display-8 mb-3" >Additional Resources <span className="font-light-italic">(Optional)</span></Form.Label>
        <div className="px-lg-3 mt-3" >
          <Form.Label className="font-semi-bold display-8 mb-3 ml-n2" >Schedule a Meeting</Form.Label>
          <InputField
            type="text"
            name="location"
            placeholder="Add location..."
            value={this.props.newCampaign.location}
            handleChange={this.props.handleInputChange}
          />
         
          {
            this.props.showEventFields &&
            <>
              <Form.Group id="start_at" className="event-date-picker" ref="start_at">
                <DateTimePicker
                  variant="inline"
                  disablePast={true}
                  value={(this.props.formName === 'event' && CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5') ? this.props.newCampaign.start_at_show : this.props.newCampaign.start_at}
                  onChange={(event) => this.props.handleScheduleDate(event, 'start_at')}
                />
              </Form.Group>
              <Form.Group id="end_at" className="event-date-picker" ref="end_at">
                <DateTimePicker
                  variant="inline"
                  value={(this.props.formName === 'event' && CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5') ? this.props.newCampaign.end_at_show : this.props.newCampaign.start_at}
                  disablePast={true}
                  onChange={(event) => this.props.handleScheduleDate(event, 'end_at')}
                />
              </Form.Group>
            </>
          }

        { //campaign_type_id - Event and category_id - office hours id
        (CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5' && this.props.newCampaign.campaign_type_id === '93ab8d70-3d6b-4f3f-a702-8ead10a90bcf') &&
          <React.Fragment>
            <Dropdown className="article-dropdown form-control h-auto py-0" >
              <Dropdown.Toggle variant="none" className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100" id="event_schedule_id">            
                  {/* { this.props.newCampaign.is_repeated === false ? `Doesn't repeat` : (this.props.eventScheduleList.find(item => item.id === this.props.newCampaign.repeat_details.frequency_type)).name } */}
                  {this.props.newCampaign.repeat_details.frequency_type ? (this.props.eventScheduleList.find(item => item.id === this.props.newCampaign.repeat_details.frequency_type)).name : "Select category"}
                <ChevronDownIcon />
              </Dropdown.Toggle>

              <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none px-0" >
              {
                this.props.eventScheduleList.map(e => {
                  return(
                    <Dropdown.Item
                      key={e.id}
                      id="frequency_type"
                      name="frequency_type"
                      ref="frequency_type"
                      eventKey={['frequency_type', e.id]}
                      onSelect={this.props.handleDropdownEventSchedule}
                      >
                      {e.name}
                    </Dropdown.Item>
                  );
                })
              }
              </Dropdown.Menu>
            </Dropdown>
            
            {(this.props.newCampaign.is_repeated === true) && 
            <div className="ml-5 mb-3">  
              <Form.Label className="font-semi-bold display-8 ml-n2 ml-5" >Custom recurrence</Form.Label>

              <div className="row">
                {/* <div className="col-md-12">Start Time</div> */}
                {/* <Form.Group id="start_time" className="ml-3 event-date-picker mb-0" ref="start_time">
                  <TimePicker
                    variant="inline"
                    value={this.props.newCampaign.repeat_details.start_time}
                    disablePast={true}
                    onChange={(event) => this.props.handleFrequencyScheduleDate(event, 'start_time')}
                  />
                </Form.Group> */}

                {/* <div className="col-md-12">End Time</div>
                <Form.Group id="end_time" className="ml-3 event-date-picker mb-0" ref="end_time">
                  <TimePicker
                    variant="inline"
                    value={this.props.newCampaign.repeat_details.end_time}
                    disablePast={true}
                    onChange={(event) => this.props.handleFrequencyScheduleDate(event, 'end_time')}
                  />
                </Form.Group> */}
                
                {
                  this.props.newCampaign.repeat_details.frequency_type === "weekly" &&
                  <React.Fragment>
                    <div className="col-md-12">Repeat On</div>
                    <div className="col-md-12 ml-2">
                      {
                        [{id:"0",day:"S"},{id:"1",day:"M"},{id:"2",day:"T"},
                        {id:"3",day:"W"},{id:"4",day:"T"},{id:"5",day:"F"},
                        {id:"6",day:"S"}].map((item)=>{
                          return (<span key={item.id} onClick={()=>this.props.handleFrequency(item.id)}><div className={this.props.newCampaign.repeat_details.frequency.includes(item.id) ? "schedule schedule-active" : "schedule"}>{item.day}</div></span>)
                        })
                      }
                    </div>
                    <div className="error errorIcon mb-3 mt-1 ml-3" id={'repeatMeetingError'} />
                  </React.Fragment>
                }

                {
                  this.props.newCampaign.repeat_details.frequency_type === "monthly" &&
                  <React.Fragment>
                    <div className="col-md-12">Repeat On</div>
                    <div className="col-md-4 ml-2">
                      {
                      ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"].map((item)=>{
                        return (<span key={item} onClick={()=>this.props.handleFrequency(item)}><div className={this.props.newCampaign.repeat_details.frequency.includes(item) ? "schedule schedule-active mb-2" : "schedule mb-2"}>{item}</div></span>)
                      })}
                    </div>
                    <div className="error errorIcon mb-3 mt-1 ml-3" id={'repeatMeetingError'} />
                  </React.Fragment>
                }
              </div>
            </div>
            }
            </React.Fragment>
          }
          
          <InputField
            // required={true}
            type="text"
            name = "meeting_url"
            value = {this.props.newCampaign.meeting_url}
            handleChange={this.props.handleInputChange} 
            placeholder="Add Zoom URL..."
          />
          <InputField
            // required={true}
            handleChange={() => <></>}
            type="text"
            placeholder="Add Zoom phone number..."
            onKeyPress={Utility.handleNumber}
          />
          <Form.Label className="font-semi-bold display-8 mb-3 ml-n2 community-input-label" >Support a Community Member</Form.Label>
          <InputField
            handleClick={this.props.openMemberCallout}
            handleChange={() => {}}
            type="text"
            className="cursor-pointer"
            placeholder="Search a Community member…"
          />
          <TileSelection
            inCallout={false}
            selected={this.props.newCampaign.non_profits}
            getSelectedIds={this.props.getSelectedMembersIds}
            items={this.props.users}
            showClose={true}
          />
          {
            this.props.showEventFields &&
            <>
              <Form.Label className="font-semi-bold display-8 mb-3 ml-n2 community-input-label" >Add Speaker, Presenter and Expert</Form.Label>
              <InputField
                handleClick={this.props.openOrganizerCallout}
                handleChange={() => {}}
                type="text"
                className="cursor-pointer"
                placeholder="Search a Community member…"
              />
              <TileSelection
                inCallout={false}
                selected={this.props.newCampaign.organizers}
                getSelectedIds={this.props.getSelectedOrganizersIds}
                items={this.props.users}
                showClose={true}
              />
            </>
          }

          <Form.Label className="font-semi-bold display-8 mb-3 ml-n2 document-input-label" >Documents</Form.Label>
          <label htmlFor="pwa-upload" className="pwa-upload-field form-control align-items-center justify-content-between  cursor-pointer">
            <span>{this.props.newCampaign.document_url ? "Banner Document" : this.state.documentName ? this.state.documentName : "Upload document"}</span>
            {(this.state.documentName || this.props.newCampaign.document_url)
              ? <span onClick={this.removeDocument} ><CloseIcon className="cursor-pointer" fillClass="fill-primary" /></span>
              : <img src={require('assets/images/icons/attachment_icon.svg')} className="attacment-icon" alt="" />}
          </label>
          <InputField
            handleChange={this.handleDocumentUpload}
            type="file"
            id="pwa-upload"
            accept="application/pdf"
          />
        </div>
      </Form.Group>
  )

  paintSharing = () => (
    <div className="sidebar-tabs-outer">
      <Form.Label className="font-bold display-8 mb-3" >Share post to…</Form.Label>
      <Tabs
        id="sidebar-tabs-inner"
        activeKey={this.state.shareEventKey}
        onSelect={(k) => this.setState({ shareEventKey: k })}
      >
          <Tab eventKey="public" title="PUBLIC">
             <div className="display-8 font-medium" >
                 By sharing to public, you agree to allow visibility to everyone within the {CLIENT_NAME} community.
              </div>
          </Tab>
          <Tab eventKey="group" title="GROUPS">
            <InputField
              handleClick={this.props.openGroupCallout}
              handleChange={() => {}}
              type="text"
              className="cursor-pointer"
              placeholder="Search Groups…"
            />
            <TileSelection
              inCallout={false}
              selected={this.props.newCampaign.groups}
              getSelectedIds={this.props.getSelectedGroupsIds}
              items={this.props.groups}
              showClose={true}
            />
          </Tab>
      </Tabs>
    </div>
  )

  paintSubmitButton = () => (
    <div className="d-flex mt-4 pt-3">
      <div className="ml-auto d-inline-block text-center campaigns-actions">
        <div className="display-8 font-bold cursor-pointer" >Save as a Draft</div>
        <Button
          disabled={this.props.showLoader}
          type="submit"
          onClick={this.props.submit}
          variant="primary"
          className={`btn btn-primary btn-primary-pwa btn-post-size font-14 my-4 `} >
          {this.props.showLoader
            ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
            : this.props.formStates.isEdit && !this.props.formStates.isNewChap ? 'UPDATE' : 'POST'
          }
        </Button>
        <div
          className="display-8 font-medium-italic cursor-pointer"
          onClick={this.props.handleIsFormOpen}>
          Cancel
        </div>
      </div>
    </div>
  )

  render() {
    return (
      <Form className="shar-form search-form search-form-pwa sidebar-form" >

        {this.props.forTestimony && this.paintGroupBox()}

        {this.props.forTestimony ? this.paintTestimonyDropdown() : this.paintArticleDropdown()}

        {!this.props.forTestimony && this.paintChapterDropdown()}

        {this.paintTitleInput()}

        {this.paintImageInput()}

        {this.paintVideoInput()}

        {this.paintArticleDetails()}

        {this.paintAdditionalResources()}

        {!this.props.forTestimony && this.paintSharing()}

        {this.paintSubmitButton()}

      </Form>
    );
  }
}
