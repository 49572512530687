import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Row, Col, Spinner } from 'react-bootstrap';
import { MENU } from 'config/constants';
import { CREATE_CATEGORY, GET_CATEGORIES_BY_CLIENTS, DISPLAY_PRODUCT_TYPES,  ORDERS, ORDER_ITEMS, PRODUCTS , SEND_ORDER} from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { showLoader, updateSelectedMenuItem, editComponent, updateProductID, updateSelectedFilters } from 'shared/actions';
import { Utility } from 'utils';
import { DROPDOWN_ACTIONS, NAME_SILHOUETTE_CLIENT_ID, SM_CLIENT_ID, ROLE_IDS, NS_PRODUCTS } from 'config/constants';
import { CRM_MODULES, CLIENT_ID } from 'config/constants/config';
import MenuShops from 'shared/components/menuForShops';
import MyProductList from 'scenes/shops/my_products';
import ShopHandler from 'scenes/shops/components/shop_handler';
import { WelcomeOverlays } from 'shared/components/welcome_overlays';
import CategoryForm from './components/CategoryForm';
import ProductTypeForm from './components/productTypeForm';
import moment from 'moment';
import SideCallout from 'shared/components/templates/side_callout';
import { InputField } from 'shared/components/form_elements';
import { ChevronDownIcon, CircleOutlinedAddIcon, ChevronLeftIcon } from 'shared/icons/icons';

class ShopContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formTypeId: null,
      showCreateForm: false,
      selectedProductId: null,
      selectedProductCategoryId: null,
      menus: MENU.shops,
      buttonClick: false,
      displayCategories: [],
      selectedMenuItemId: null,
      showCategoryform: false,
      showProductTypeForm: false,
      showAddProductForm: true,
      editFromMain: false,
      categoryFormData: [],
      typeFormData: {},
      productTypeId: "",
      menuShop: [],
      adminMenuShop: MENU.adminMenus,
      isAdminMenuSold: false,
      orderList: [],
      customOrderItems: []
    };

    props.showLoader(Utility.isEmpty(props.userData));
    props.updateSelectedMenuItem(MENU.shops[0]);
  }
  componentDidMount = () => {
    this.getCategories();
    this.getProductsTypes();
    if (NAME_SILHOUETTE_CLIENT_ID === CLIENT_ID) {
      this.getOrderList();
      this.getCustomOrderItemList();
    }
  }

  componentDidUpdate = () => {

    const list = document.getElementById('menu-list');

    if (list) list.classList.add('d-block');
    if (Utility.isEmpty(this.props.selectedMenuItem) && !Utility.isEmpty(this.props.userData)) {
      this.props.updateSelectedMenuItem(MENU.shops[0]);
    }
  }


  handleMenuItemClick = (selectedMenuItem) => {
    this.props.updateSelectedMenuItem(selectedMenuItem);

    if (selectedMenuItem.menu_name === 'Sold') {
      this.setState({ isAdminMenuSold: true });
    } else {
      this.setState({ isAdminMenuSold: false });
    }

    if (selectedMenuItem.menu_name === 'Custom') {
      this.setState({ isAdminMenuCustom: true });
    } else {
      this.setState({ isAdminMenuCustom: false });
    }

    this.setState({ formTypeId: null, showCreateForm: false }, () => {
      Utility.showComponent(true, 'my-products');
    });
  }

  handleChange = (e) => {
    if (!Utility.isEmpty(e) && e.name === "Add product") {
      this.props.editComponent(null);
      this.buttonClick();
    }

    this.setState({ formTypeId: 1, showCreateForm: true, buttonClick: true, showAddProductForm: true, editFromMain: false }, () => {
      this.props.editComponent(null);
    });
  }

  editProductItem = (productId, productTypeId, categoryId) => {
    if (productTypeId === 1) {
      this.buttonClick('edit');
      this.setState({ editFromMain: false })
    }
    else
      this.setState({ editFromMain: true })

    this.setState({
      selectedProductId: productId,
      formTypeId: 1,
      selectedProductCategoryId: Utility.isEmpty(categoryId) ? this.state.categoryId : categoryId,
      showAddProductForm: true
    });
  }

  getCategories = () => {

    const params = {
      client_id: this.props.userData.client_id,
      crm_module_id: CRM_MODULES.SHOPS,
      owner_id: this.props.userData.id
    };

    Utility.sendRequest(GET_CATEGORIES_BY_CLIENTS, 1, params, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.setState({ displayCategories: body.data });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  getProductsTypes = () => {
    const params = {
      user_client_id: this.props.userData.id,
      platform_id: this.props.userData.platform_id
    }
    this.state.menuShop = [];
    Utility.sendRequest(DISPLAY_PRODUCT_TYPES, 1, params, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        body.data.map(items => {
          const obj = {
            id: items.id,
            menu_name: items.name,
            color_code: items.color_code,
            image_url: items.image_url
          }
          this.state.menuShop = [...this.state.menuShop, obj]
        })

        this.setState({ menuShop: this.state.menuShop });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  buttonClick = (e) => {
    if (e === 'edit') {
      this.props.editComponent('edit');
      this.setState({ buttonClick: false });
    } else {
      this.setState({ buttonClick: true, editFromMain:false });
    }
  }

  closeForm = () => {
    this.setState({ formTypeId: null, showCreateForm: false, buttonClick: false });
  }

  closeCategory = () => {
    this.setState({ showCategoryform: false, categoryFormData: {} }, () => {
      this.getCategories();
    });
  }

  closeAddProductForm = () => {
    this.setState({ showAddProductForm: false }, () => {
      this.getCategories();
    })
  }

  closeProductTypeForm = () => {
    this.setState({ showProductTypeForm: false, typeFormData: {} });
  }

  saveProductType = () => {
    this.setState({ showProductTypeForm: false, typeFormData: {} });
    this.getProductsTypes();
  }

  handleCategoryFormClicked = (item) => {
    this.setState({ productTypeId: item.id });
    this.setState({ showCategoryform: true });
  }

  handleProductTypeFormClick = () => {
    this.setState({ showProductTypeForm: true });
  }

  handleProductTypeActions = (item, list) => {
    if (list === 2) {
      this.setState({ showProductTypeForm: true, typeFormData: item });
    }
  }

  handleListItemAction = (id, list) => {

    if (list === 2) {
      Utility.sendRequest(CREATE_CATEGORY + '/' + id, 1, {}, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          this.setState({ showCategoryform: true, isUpdateCategory: true, showInviteForm: false, categoryFormData: body });
        }
      });
    }

    // if (list === 1) {
    //   Utility.sendRequest(CREATE_CATEGORY + '/' + id, 4, {}, (err, res, body) => {
    //     body = JSON.parse(body);

    //     if (body.error) {
    //       toast.error(body.error.message, { containerId: 'private' });
    //     }else {
    //       const menus = { ...this.state.menus };
    //       menus.categories = menus.categories.filter((item) => {
    //           if (!(item.id === id)) return item;
    //       });

    //       toast.success(body.message, { containerId: 'private' });
    //       this.props.updateSelectedMenuItem(menus.shops[0]);
    //       this.setState({ menus, selectedMenuItem:menus.registrants[0], selectedMenuItemName: name});
    //     }
    //   });
    // }
  }
  // Get all order lists
  getOrderList = () => {
    this.props.showLoader(true);

    // NOTE: we can also pass @limit and @offset params
    // Get order list in DESC order
    const params = {
      client_id: CLIENT_ID,
    };

    const adminMenu = this.state.adminMenuShop;

    Utility.sendRequest(ORDERS, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);
      if (!body.error) {
        adminMenu[0].count = body.data.length;
        this.setState({ orderList: body.data, adminMenuShop: adminMenu });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  };

  // Get all custom order Item lists
  getCustomOrderItemList = () => {
    // NOTE: we can also pass @limit and @offset params
    // Get order list in DESC order
    const params = {
      client_id: CLIENT_ID,
      type: 'CUSTOM'
    };

    const adminMenu = this.state.adminMenuShop;

    Utility.sendRequest(ORDER_ITEMS, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        adminMenu[1].count = body.data.length;
        this.setState({ customOrderItems: body.data, adminMenuShop: adminMenu });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  };

  updateCustomCount = (count) => {
    const adminMenu = this.state.adminMenuShop;
    adminMenu[1].count = count;
    this.setState({ adminMenuShop: adminMenu });
  }

  render() {
    const { is_super_owner, platform_id, role_id } = this.props.userData;
    if ((is_super_owner || role_id === ROLE_IDS.PARENT_OWNER) || platform_id !== SM_CLIENT_ID) {
      return (
        <>
          <Row className="no-gutters h-100">
            {
              !Utility.isEmpty(this.props.userData) &&
              <MenuShops
                selectedModule={{ name: 'Shops' }}
                clientName={this.props.userData.client_name}
                userName={`${this.props.userData.first_name} ${this.props.userData.last_name}`}
                dropdownItems={DROPDOWN_ACTIONS.shops}
                handleMenuItemClick={this.handleMenuItemClick}
                selectedMenuItemId={this.props.selectedMenuItem.id}
                menus={this.state.menuShop}
                adminMenus={this.state.adminMenuShop}
                isAdminId={NAME_SILHOUETTE_CLIENT_ID === CLIENT_ID}
                isShowButton={true}
                isButton={true}
                handleDropdownAction={this.handleChange}
                displayCategory={this.state.displayCategories}
                handleCategoryFormClick={this.handleCategoryFormClicked}
                handleProductTypeFormClick={this.handleProductTypeFormClick}
                handleListItemAction={this.handleListItemAction.bind(this)}
                handleProductTypeActions={this.handleProductTypeActions}
                shops={true}
              />
            }
            {
              (!this.state.isAdminMenuSold && !this.state.isAdminMenuCustom && (Utility.isEmpty(this.state.formTypeId) || !this.state.showAddProductForm)) &&
              <MyProductList
                goToAddProduct={this.handleChange}
                showLoader={this.props.showLoader}
                editProductItem={this.editProductItem}
                userData={this.props.userData}
                updateSelectedFilters={this.props.updateSelectedFilters}
                selectedFilters={this.props.selectedFilters}
                selectedMenuItem={this.props.selectedMenuItem}
                allProductTypes={this.state.menuShop}
                handleMenuItemClick={this.handleMenuItemClick}
                refreshCategories={this.getCategories}
              />
            }
            {
              (CLIENT_ID === NAME_SILHOUETTE_CLIENT_ID && this.state.isAdminMenuSold && !this.state.isAdminMenuCustom) &&
              <AdminShopPage
               orderList={this.state.orderList}
              />
            }
            {
              (CLIENT_ID === NAME_SILHOUETTE_CLIENT_ID && !this.state.isAdminMenuSold && this.state.isAdminMenuCustom) &&
              <AdminCustomPage userData={this.props.userData} customOrderItems={this.state.customOrderItems} showLoader={this.props.showLoader} updateCustomCount={this.updateCustomCount}/>
            }
            {
              !Utility.isEmpty(this.state.formTypeId) && !this.state.CategoryForm && this.state.showAddProductForm &&
              <ShopHandler
                formTypeId={this.state.formTypeId}
                selectedProductId={this.state.selectedProductId}
                selectedProductCategoryId={this.state.selectedProductCategoryId}
                edit={this.props.edit}
                showLoader={this.props.showLoader}
                editProductItem={this.editProductItem}
                showCreateForm={this.state.showCreateForm}
                closeForm={this.closeForm}
                editComponent={this.props.editComponent}
                userData={this.props.userData}
                buttonClickState={this.state.buttonClick}
                buttonClickFunction={this.buttonClick}
                primaryProductId={this.props.productId}
                updateProductID={this.props.updateProductID}
                allCategories={this.state.displayCategories ? this.state.displayCategories : ""}
                closeAddProductForm={this.closeAddProductForm}
                editFromMain={this.state.editFromMain}
                allProductTypes={this.state.menuShop}
              />
            }
            {this.state.showCategoryform &&
              <CategoryForm
                onClose={this.closeCategory}
                userData={this.props.userData}
                categoryFormData={this.state.categoryFormData}
                productTypeId={this.state.productTypeId}
              />
            }
            {
              this.state.showProductTypeForm &&
              <ProductTypeForm
                onClose={this.closeProductTypeForm}
                saveProductType={this.saveProductType}
                userData={this.props.userData}
                typeFormData={this.state.typeFormData}
                showLoader= {this.props.showLoader}
              />
            }
          </Row>

        </>
      );
    } else {
      return (
        <WelcomeOverlays
          heading="Welcome to Shops!"
          subHeading="Create, manage and distribute various types of products and services."
          mainText="Ask for a customized DEMO to learn how all the available features of this module can be used to serve the requirements of your digital application."
          btnText="See example!"
        />
      );
    }
  }
}

const AdminShopPage = (props) => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(showLoader);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(false);

  const getOrderDetails = (orderId, user_client_id) => {
    setShowLoader(true)
    const params = {
      client_id: CLIENT_ID,
      user_client_id
    };

    Utility.sendRequest(
      `${ORDERS}/${orderId}`,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        setShowLoader(false);
        if (!body.error) {
          setOrderDetails(body.data);
          setOpenOrderDetailsModal(true);
        } else {
          toast.error(body.error.message, { containerId: 'public' });
        }
      }
    );
  };

  return (
    <>
      <Col xl={9} lg={8} className="ModalOpen custom-page-outer scroll-y h-100 top-on-sm d-none d-lg-block px-4 bg-white py-4 py-md-0">
        <div className="align-items-center py-4 my-1 product-head">
          <div className="round-circle mr-2"></div>
          <span className="font-20-bold">Sold</span>
        </div>
        <div className='mb-lg-CircleOutlinedAddIcon5 mb-4'>
          {!Utility.isEmpty(props.orderList) ? (
            <div className='registants-table'>
              <div className='table-responsive'>
                <table className='table table-hover table-product-sold'>
                  <thead className='display-7'>
                    <tr className="display-7">
                      <th className='border-top-0 border-bottom-0 font-bold pb-3'>
                        Product Name
                      </th>
                      <th
                        className='border-top-0 border-bottom-0 font-bold pb-3'
                        style={{width: 120}}
                      >
                        Price
                      </th>
                      <th
                        className='border-top-0 border-bottom-0 font-bold pb-3'
                        style={{width: 150}}
                      >
                        Date
                      </th>
                      <th
                        className='border-top-0 border-bottom-0 font-bold pb-3'
                        style={{width: 240}}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="display-8" >
                    {props.orderList.map(order => (
                      <tr key={order.id}>
                        
                        <td className="font-bold" >
                          <div className="order-id-length align-items-center">
                            Order {order.id}
                          </div>
                        </td>
                        <td>
                          $
                          {Utility.paintUptoTwoDecimalPlaces(
                            order.base_grand_total
                          )}
                        </td>
                        <td>
                          {moment(order.createdAt).format('LL')}
                        </td>
                        <td className='text-center cursor-pointer'>
                          <div className='d-flex flex-nowrap align-items-center'>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='table-dropdown'
                                id='dropdown-basic'
                              >
                                <span> View Order </span>
                                <ChevronDownIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  ....
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <div onClick={() => getOrderDetails(order.id, order.customer_id)} className='bg-primary rounded-circle lesson-img-arrow flex-center mr-3 cursor-pointer'>
                              <ChevronLeftIcon fillClass='fill-white' />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Col>
      {openOrderDetailsModal && (
        <OrderDetails
          // showLoader = {this.props.showLoader}
          orderDetails={orderDetails}
          show={openOrderDetailsModal}
          close={() => setOpenOrderDetailsModal(false )}
        />
      )}
    </>
  );
};

const OrderDetails = ({ orderDetails, show, close,showLoader }) => {
  const [email, setemail] = useState('')
  const handleChange =(e) => {
    setemail(e.target.value)
    
  } 
  const ValidEmail = () => {
      if(email==='' || email.trim==='')
      {
        toast.error('Please Enter an Email ID',{ containerId: 'public' })
        return false
      }
      else if (!email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )){
        toast.error("Please Enter Valid Email Address",{ containerId: 'public' })
        return  false
      }
      return true
  }
  const handleEmailSubmit = (e,user_order_id) =>{
    e.preventDefault()
    // showLoader(true);
    if(ValidEmail())
    { const params = {
      client_id: CLIENT_ID,
      order_id: user_order_id,
      email: email
    };

    Utility.sendRequest(`${SEND_ORDER}`, 2, params, (error, response, body) => {
      body = JSON.parse(body);
      // this.props.showLoader(false);
      if (!body.error) {
        toast.success(body.message,{ containerId: 'public' })
        // showLoader(false)
      } else {
        toast.error(body.error.message, { containerId: 'public' });
        // showLoader(false)
      }
    });}
  }


  return (
      
      <SideCallout
          // calloutHeadingSvg={<CircleOutlinedAddIcon mainClass="mr-2" />}
          closeCallOut={close}
          calloutHeading={`Order for Product`}
          position={'order-detail-callout position-absolute'}
          innerPosition="" >
      {/*
        {`${orderDetails.cardDetails.cc_type}(•••• •••• •••• ${orderDetails.cardDetails.cc_last_4_digits})`}
      */}
      <div className="font-bold-italic display-7 mb-4" >Customer Information</div>
      
      <ul className="list-unstyled display-8 mb-4 pb-2 ml-2 pl-1" style={{lineHeight: 1.6}} >
        <li className="display-7 font-bold mb-2 pb-1" >
            {`${orderDetails.customer_first_name} ${orderDetails.customer_last_name}`}
        </li>
        {orderDetails.address && <li>{orderDetails.address}</li>}
        {orderDetails.creator_city && <li>{orderDetails.creator_city}</li>}
        {orderDetails.zip_code && <li>{orderDetails.zip_code}</li>}
        {orderDetails.customer_email && <li>{orderDetails.customer_email}</li>}
        {orderDetails.customer_phone &&  <li>{orderDetails.customer_phone}</li>}
      </ul>

      <div className="font-bold-italic display-7 mb-4" >Receipt Information</div>
      <ul className="list-unstyled display-8 mb-4 pb-2 ml-2 pl-1" style={{lineHeight: 1.6}} >
        <li className="display-7 font-bold mb-2 pb-1" >
           Order Id: {orderDetails.id}
        </li>
        <li>{moment(orderDetails.createdAt).format('L')}</li>
        <li>{Number(orderDetails.base_grand_total).toFixed(2)}</li>
        <li>Paid with Visa ending in - {orderDetails.cardDetails.cc_last_4_digits}</li>
        <li>Location: Website</li>
      </ul>

      <div className="font-bold-italic display-7 mb-4" >Product Information</div>
      {orderDetails.orderItems.length > 0 && orderDetails.orderItems.map( (order) => {
        const name = JSON.parse(order.additional_details);
        return (
          <div key={order.id}>
            
     
            <ul className="list-unstyled display-8 mb-3 pb-2 ml-2 pl-1" style={{lineHeight: 1.6}} >
            <li className="display-7 font-bold mb-2 pb-1" >
              {`${name.firstName} ${name.middleName} ${name.lastName}`}
            </li>
            <li>Category: Digital</li>
            <li>{`Type: ${order.type}`}</li>
            <li>{`Product Name: ${order.product_name}`}</li>
            </ul>
            <div className="mt-4 shar-form">
              <div>
                <img className="img-placeholder-lg" src={order.downloadable_file_url}/>
              </div>
            </div>
          </div>
        )
      })}
      <form className="mt-4 shar-form" >
        <label className="font-bold display-8 mb-3" >Send Digital Download to Customer</label>
        
         <InputField
            handleChange={handleChange}
            required
            type='email'
            placeholder='Enter Email'
            name='first_name'
          />
          <button onClick={(e)=>handleEmailSubmit(e,orderDetails.id)} className="btn btn-grey font-bebas display-7 text-primary" >Send Digital Download</button>
      </form>
    </SideCallout>
  );  
};

const Items = ({ item }) => {
  const showName = () => {
    const additionalDetails = item.additional_details
      ? JSON.parse(item.additional_details)
      : null;

    return additionalDetails
      ? `${additionalDetails.firstName} ${additionalDetails.middleName} ${additionalDetails.lastName}`
      : '';
  };
  return (
    <div className='py-3 d-flex align-items-center cart-product-outer flex-wrap flex-lg-nowrap'>
      <div className='cart-product-img bg-white'>
        <img
          src={
            item.downloadable_file_url
              ? item.downloadable_file_url
              : require('assets/images/no-img.jpg')
          }
        />
      </div>
      <div className='pl-lg-5 flex-grow-1 product-name-outer'>
        <div className='font-bebas font-30 text-muted'>{item.product_name}</div>
        <div className='font-18'>{showName()}</div>
      </div>
      <div className='d-flex align-items-center ml-auto'>
        <div className='text-primary font-18 font-weight-bold mr-4 pr-3'>
          ${Number(item.base_price).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

class AdminCustomPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isCreateModal: false,
      isViewModal: false,
      productTabText: 'Select Type',
      productTabComponent: '0',
      products: [],
      formData: {},
      viewFormData: {},
      formName: 'customSilhouetteForm',
      showLoader: false,
      product: [],
      email:'',
      customOrderItems: this.props.customOrderItems,
      customOrderItemDetails : null,
      selectTypeError: false,
      download: false,
    };

  }

  validateUpdateFields = ['first_name', 'middle_name', 'last_name', 'email'];

  componentDidMount = () => {
    this.fetchProducts();
  }

  fetchProducts = () => {
    const params = {
      client_id: CLIENT_ID,
    };
    Utility.sendRequest(PRODUCTS, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.setState({ products: body });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  };

  // Get all custom order Item lists
  getCustomOrderItemList = () => {
    // NOTE: we can also pass @limit and @offset params
    // Get order list in DESC order
    const params = {
      client_id: CLIENT_ID,
      type: 'CUSTOM'
    };

    Utility.sendRequest(ORDER_ITEMS, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);
      if (!body.error) {
        this.setState({ customOrderItems: body.data });
        this.props.updateCustomCount(body.data.length);
      } else {
        this.props.showLoader(false);
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  };

  handleAccountDropdown = (evKey, evObj) => {
    const category = evObj.currentTarget.innerText;
    const product = this.state.products.data.filter((product) => {
      return (product.name === category);
    });
    this.setState({
      productTabText: evObj.currentTarget.innerText,
      product: product[0],
      productTabComponent: evKey,
      selectTypeError: false
    });
  };

  handleChange = (e) => {
    let formData = this.state.formData;

    formData = Utility.validateAndReturnFormData(this, formData, e, this.validateUpdateFields, this.state.formName);

    this.setState({ formData });
  }

  // validate first name, middle name, and last name
  validateName = (name, nameType) => {
    if (!name) {
      toast.error(`Please Specify ${nameType}`, { containerId: 'private' });
      return;
    } else if (name && (name.length < 3 || name.length > 10)) {
      toast.error(`${nameType} should be between 3 to 10 characters` , { containerId: 'private' });
      return;
    }
    return name;
  }

  // validate form data
  validateFormData = (formData) => {
    const first_name = this.validateName(formData.first_name, 'First Name');
    if (!first_name) return;
    const middle_name = this.validateName(formData.middle_name, 'Middle Name');
    if (!middle_name) return;
    const last_name = this.validateName(formData.last_name, 'Last Name');
    if (!last_name) return;
    if (!formData.email) {
      toast.error('Please Specify email', { containerId: 'private' });
      return;
    }
    return formData;
  }

  submit = async (e) => {
    e.preventDefault();
    this.setState({ showLoader: true });

    let formData = Utility.deepClone(this.state.formData);

    formData = this.validateFormData(formData);

    if (!formData) {
      this.setState({ showLoader: false });
      return;
    }

    if (this.state.productTabText === 'Select Type') {
      this.setState({ selectTypeError: true, showLoader: false });
      toast.error('Please Select Type', { containerId: 'private' });
      return;
    }

    const type = this.state.productTabText.toLowerCase();

    const additional_details = {
      firstName: formData.first_name,
      middleName: formData.middle_name,
      lastName: formData.last_name,
      email: formData.email
    };

    const payload = {
      product : {
        id: this.state.product.id,
        silhouette_type: NS_PRODUCTS[type],
        name: this.state.product.name,
        base_price: this.state.product.price,
        type: this.state.product.type,
        file_type: this.state.product.file_type,
        file_api_url: this.state.product.file_api_url,
        additional_details: JSON.stringify(additional_details),
      },
      platform_id: CLIENT_ID,
      customer_id: this.props.userData.id,
      type: 'CUSTOM'
    };

    Utility.sendRequest(ORDER_ITEMS, 2, payload, (error, response, body) => {
      body = JSON.parse(body);
      if (body.error) {
        this.setState({ showLoader:false });
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({ showLoader:false, isCreateModal: false });
        this.getCustomOrderItemList();
      }
    });
  }

  // display order item's additional details
  showName = (item) => {
    const additionalDetails = item.additional_details
      ? JSON.parse(item.additional_details)
      : null;

    return additionalDetails
      ? `${additionalDetails.firstName} ${additionalDetails.middleName} ${additionalDetails.lastName}`
      : '';
  }

  // get custom order item details
  getCustomOrderItemDetails = (item) => {
    this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(`${ORDER_ITEMS}/${item.id}`, 1, params, (error, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);
      if (!body.error) {
        this.setState({ customOrderItemDetails: body.data, isViewModal: true });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  //delete custom order item details
  deleteOrderItemDetails = (item) => {
    this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(`${ORDER_ITEMS}/${item.id}`, 4, params, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.getCustomOrderItemList();
        this.props.showLoader(false);
      } else {
        this.props.showLoader(false);
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  //handle sideallout for sending custom Image
  handleChangeEmail = (e) => {
    let viewFormData = this.state.formData;

    viewFormData  = Utility.validateAndReturnFormData(this, viewFormData, e, this.validateUpdateFields, this.state.formName);

    this.setState({ viewFormData });

  }

  //sent the image to given email
  sendImage = () => {
    this.setState({ isViewModal: false });
  }

  render() {

    return (
      <Col xl={9} lg={8} className="ModalOpen scroll-y h-100 top-on-sm d-none d-lg-block px-4 bg-white py-4 py-md-0 custom-page-outer">
        <div className="align-items-center justify-content-between network-view mb-3 mb-sm-0 product-head" >
          <div className="d-flex align-items-center py-4 my-1">
            <div className="round-circle mr-2"></div>
            <span className="font-20-bold">Custom</span>
          </div>
        </div>
        <div className="d-inline-flex align-items-center cursor-pointer font-bold display-7" onClick={() => {this.setState({ isCreateModal: true, formData: {}, productTabText: 'Select Type', productTabComponent: '0', selectTypeError: false });}}>
          <CircleOutlinedAddIcon mainClass="mr-3" />
          Create
        </div>
        <table className='table table-hover table-product-sold mt-4'>
          <thead className='display-7'>
            <tr className="display-7">
              <th className='border-top-0 border-bottom-0 font-bold pb-3'
                style={{width: 420}}
              >
               Product Name
              </th>
              <th
                className='border-top-0 border-bottom-0 font-bold pb-3'
              >
                Category
              </th>
              <th
                className='border-top-0 border-bottom-0 font-bold pb-3'
                style={{width: 240}}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="display-8" >
           {this.state.customOrderItems ? this.state.customOrderItems.map(item => (
             <ToggleCustomPage
              key={item.id}
              item={item}
              showName={this.showName}
              getCustomOrderItemDetails={this.getCustomOrderItemDetails}
              deleteOrderItemDetails={this.deleteOrderItemDetails}
              customOrderItemDetails={this.state.customOrderItemDetails}
              showLoader={this.props.showLoader}
             />
           )) : (
             <div>No custom items found</div>
           )}
          </tbody>
          </table>
        {this.state.isCreateModal &&
        <SideCallout
          calloutHeadingSvg={<div className="circle-decorator" />}
          closeCallOut={() => this.setState({ isCreateModal: false })}
          calloutHeading='Create Name Silhouette'
          position={'position-absolute create-ns-callout'}
          innerPosition="create-card-pwa-outer" >
            <form className='shar-form search-form search-form-pwa sidebar-form sidebar-form-width pb-4 pb-md-0'>
              <div className='row mx-lg-0'>
                <div className='form-unit col-lg-12 pl-lg-0'>
                  <label className='font-bold display-8 mb-3 form-label'>
                    Write Full Name
                  </label>
                  <InputField
                    required
                    type='text'
                    placeholder='First Name'
                    name='first_name'
                    ref='first_name'
                    handleChange={this.handleChange}
                  />
                  <InputField
                    required
                    type='text'
                    placeholder='Middle Name'
                    name='middle_name'
                    ref='middle_name'
                    handleChange={this.handleChange}
                  />
                  <InputField
                    required
                    type='text'
                    placeholder='Last Name'
                    name='last_name'
                    ref='last_name'
                    handleChange={this.handleChange}
                  />
                </div>
              </div>
              <div className='row mx-lg-0'>
                <div className='form-unit col-lg-12 pl-lg-0'>
                  <label className='font-bold display-8 mb-3 form-label'>
                    Select Character
                  </label>
                        <div className='d-flex mt-2 pt-2 flex-wrap flex-md-nowrap support-team-tab'>
                          <Dropdown className="article-dropdown form-control h-auto py-0" onSelect={this.handleAccountDropdown}>
                            <Dropdown.Toggle
                              variant='none'
                              id='dropdown-basic'
                              className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100 font-bold"
                            >
                              {this.state.productTabText}
                              <ChevronDownIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none">
                            {this.state.products.count > 0 && this.state.products.data.map((product) => {
                              return (
                                <Dropdown.Item
                                  key={product.id}
                                  eventKey={product.id}
                                  active={this.state.productTabComponent === product.id}
                                >
                                {product.name}
                                </Dropdown.Item>
                              )})
                            }
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      {this.state.selectTypeError && <div className="error errorIcon ">Please Select Type</div>}
                </div>
              </div>
              <div className='form-unit'>
                <label className='font-bold display-8 mb-3 form-label'>
                  Add Email or User
                </label>
                <InputField
                  required
                  placeholder='Email'
                  type="email"
                  name="email"
                  ref="email"
                  handleChange={this.handleChange}
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  disabled={this.state.showLoader}
                  onClick={(e) => {
                    this.submit(e);
                  }}
                  variant='none'
                  id='updateForm'
                  className={`btn btn-outline-primary border-size-2 font-18 font-bebas mt-md-4 mb-5 px-5 py-3 `}
                >
                {this.state.showLoader ? (
                  <Spinner animation='border' role='status' />
                ) : (
                  'CREATE'
                )}
                </button>
              </div>
            </form>
        </SideCallout>
        }
       {this.state.isViewModal &&
        <SideCallout
        calloutHeadingSvg={<div className="circle-decorator" />}
        closeCallOut={() => this.setState({ isViewModal: false })}
        calloutHeading='View Name Silhouette'
        position={'position-absolute'}
        innerPosition="create-card-pwa-outer" >
          <form className='shar-form search-form search-form-pwa sidebar-form sidebar-form-width pb-4 pb-md-0'>
            <div className='row mx-lg-0'>
              <div className='form-unit col-lg-12 pl-lg-0'>
                <div className="d-flex justify-content-between mb-4 align-items-center" >
                  <label className='font-bold display-8 mb-3 form-label'>
                  {this.showName(this.state.customOrderItemDetails)}
                  </label>
                  <a
                  className='cursor-pointer'
                  href={this.state.customOrderItemDetails.downloadable_file_url}
                  download={this.showName(this.state.customOrderItemDetails)}
                  >
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 30 30"
                      >
                        <path
                          d="M15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zm0 26.74C8.516 26.74 3.26 21.482 3.26 15 3.26 8.517 8.517 3.26 15 3.26S26.74 8.518 26.74 15c0 6.483-5.256 11.74-11.74 11.74zm2.935-18.017h-5.87v6.668H8.397L15 21.603l6.603-6.212h-3.668V8.723z"
                          fill="#08C"
                          fillRule="nonzero"
                        />
                      </svg>
                </a>
                </div>
                <img src={this.state.customOrderItemDetails.downloadable_file_url} className="view-ns-image" alt="ns image" />
              </div>
            </div>
            <div className='row mx-lg-0'>
              <div className='form-unit col-lg-12 pl-lg-0'>
                <label className='font-bold display-8 mb-3 form-label'>
                  Send to
                </label>
                <InputField
                  required
                  type='text'
                  placeholder='email@user.com'
                  name='email'
                  ref='email'
                  handleChange={this.handleChangeEmail}
                />
              </div>
            </div>
           <div className='text-center'>
              <button
                type='submit'
                disabled={this.state.showLoader}
                onClick={() => {
                  this.sendImage()
                }}
                variant='none'
                id='sendForm'
                className={`btn btn-primary font-18 py-3 font-bebas mt-md-4 mb-5 px-5`}
              >
              {this.state.showLoader ? (
                  <Spinner animation='border' role='status' />
                ) : (
                  'SEND'
                )}
              </button>
            </div>
          </form>
      </SideCallout>
       }
      </Col>
    );
  }
}

const ToggleCustomPage = (props) => {
  const [selectedOption, setOption] = useState('View');
  const [selectedOptionKey, setOptionKey] = useState('View');
  const [isDownload, setDownload] = useState(false);
  //handle menu dropdown
  const handleActionDropdown = (eventKey, eventObject) => {
    props.showLoader(true);
    const selectedOption = eventObject.currentTarget.innerText;
    const selectedOptionKey = eventKey;
    if (selectedOption === 'Download') {
      setOption(selectedOption);
      setOptionKey(selectedOptionKey);
      setDownload(true);
    } else {
      setOption(selectedOption);
      setOptionKey(selectedOptionKey);
      setDownload(false);
    }
    props.showLoader(false);
  };

    // handle toggle options
  const handleCustomItem = (item) => {
    if (selectedOption === 'View') {
      props.getCustomOrderItemDetails(item);
    } else if (selectedOption === 'Delete') {
      props.deleteOrderItemDetails(item);
    }
  };

  return (
    <tr key={props.id}>
    <td>
      <div className="order-id-length align-items-center font-bold">
        <div className="img-placeholder" />
        {props.showName(props.item)}
      </div>
    </td>
    <td>{props.item.product_name}</td>
    <td>
      <div className='d-flex flex-nowrap align-items-center'>
        <Dropdown onSelect={handleActionDropdown}>
          <Dropdown.Toggle
            variant='table-dropdown'
            id='dropdown-basic'
          >
            {(selectedOptionKey === props.item.id + 'Download' || selectedOptionKey === props.item.id + 'View' ||
              selectedOptionKey === props.item.id + 'Delete'
             ) ? <span>{selectedOption}</span> : <span>View</span>}
            <ChevronDownIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={props.item.id + 'Download'} active={selectedOptionKey === props.item.id + 'Download'} >
              Download
            </Dropdown.Item>
            <Dropdown.Item eventKey={props.item.id + 'View'} active={selectedOptionKey === props.item.id + 'View'}>
              View
            </Dropdown.Item>
            <Dropdown.Item eventKey={props.item.id + 'Delete'} active={selectedOptionKey === props.item.id + 'Delete'}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div onClick={() => handleCustomItem(props.item)} className='bg-primary rounded-circle lesson-img-arrow flex-center mr-3 cursor-pointer'>
          {isDownload ?  <a
                className='cursor-pointer'
                href={props.item.downloadable_file_url}
                download={props.showName(props.item)}
        ><ChevronLeftIcon fillClass='fill-white' /></a> : <ChevronLeftIcon fillClass='fill-white' />}
        </div>
      </div>
    </td>
   </tr>
  );
};


const mapActionToProps = { showLoader, updateSelectedMenuItem, editComponent, updateProductID, updateSelectedFilters };

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedModule: state.selectedModule,
  selectedMenuItem: state.selectedMenuItem,
  edit: state.edit,
  productId: state.productId,
  selectedFilters: state.selectedFilters
});

export default connect(mapStateToProps, mapActionToProps)(ShopContainer);
