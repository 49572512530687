import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { USER_AUTH } from "config/constants/api_endpoints";
import { Utility } from "utils";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  updateUserData,
  showLoader,
  updateIsPageCourse,
  updateIsCitizenSidebarOpen,
} from "shared/actions";
import Header from "./header";
import SideBar from "./sidebar";
import { PwaFooter } from "./pwa_footer";
import cookie from "react-cookies";
import "react-toastify/dist/ReactToastify.css";
import { ROLE_IDS } from "config/constants";
import CloseToast from "shared/components/close_toast";
import { SM_CLIENT_ID, ETHOS_CLIENT_ID, GIL_CLIENT_ID } from "config/constants";
import { CLIENT_ID } from "config/constants/config";
import CitizenSidebar from "./CitizenSidebar";
// import { updateIsPageCourse } from "../actions";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pwaModeEnabled: Utility.pwaModeEnabled(),
      isExpanded: false,
    };

    const token = cookie.load("sessionToken");

    if (token) {
      Utility.sendRequest(USER_AUTH, 1, {}, (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          this.props.updateUserData(body);
          this.checkLocal(body);
          props.showLoader(false);
        } else {
          Utility.clearSessionAndRedirectToHomePage();
        }
      });
    }
  }

  setIsExpanded = (isExpanded) => {
    this.setState({ isExpanded: isExpanded });
  };

  checkLocal = (data) => {
    if (typeof localStorage !== "undefined") {
      let localData = Utility.getLocalStorage("users");

      if (localData.length > 0) {
        const id = localData.find((user) => user.id === data.id);
        if (!id) {
          localData.push(data);
          Utility.setLocalStorage("users", localData);
        }
      } else {
        Utility.setLocalStorage("users", []);
        localData = Utility.getLocalStorage("users");
        localData.push(data);
        Utility.setLocalStorage("users", localData);
      }
    }
  };

  render() {
    const { platform_id, role_id } = this.props.userData;
    console.log("Hey", this.props.isCitizenSidebarOpen);
    console.log(this.props.history.location);
    const isSuperClient = platform_id === SM_CLIENT_ID;
    // if platform id is matched to sharemeister client
    //  {this.props.isPageCourse ? ()=>{this.setIsExpanded(false);}:()=>{}}
    if (this.props.isPageCourse) this.props.updateIsCitizenSidebarOpen(false);
    if (
        this.props.history.location.pathname === "/home" ||
        this.props.history.location.pathname === "/groups" ||
        this.props.history.location.pathname === "/events" ||
        this.props.history.location.pathname.match("/profile")
    )
      this.props.updateIsPageCourse(false);

    return (
      <main
        className={`main-container ${
          this.props.location.pathname.indexOf("profile") !== -1
            ? "pwa-menu-position"
            : ""
        }`}
      >
        <Header
          history={this.props.children.props.history}
          location={this.props.location}
          path={this.props.path}
          isProfilePicShow={true}
        />
        {/* {console.log('IsPageCourse',this.props.isPageCourse)} */}

        <Container
          fluid
          className={
            CLIENT_ID === GIL_CLIENT_ID && role_id === ROLE_IDS.USER
              ? this.props.isCitizenSidebarOpen
                ? "px-0 h-100 marginToContainer"
                : "px-0 h-100 pl-5"
              : "px-0 h-100"
          }
        >
          <SideBar
            history={this.props.children.props.history}
            location={this.props.location}
            path={this.props.path}
          />
          {CLIENT_ID === GIL_CLIENT_ID && role_id === ROLE_IDS.USER ? (
            <CitizenSidebar
              history={this.props.children.props.history}
              location={this.props.location}
              setIsExpanded={this.props.updateIsCitizenSidebarOpen}
              isExpanded={this.props.isCitizenSidebarOpen}
              path={this.props.path}
            />
          ) : (
            ""
          )}
          <div
            className={`page-container ${
              role_id === ROLE_IDS.USER && !isSuperClient ? "pl-0" : ""
            } ${CLIENT_ID === ETHOS_CLIENT_ID ? "ethos-container" : ""} ${
              CLIENT_ID === GIL_CLIENT_ID && role_id === ROLE_IDS.USER
                ? this.props.isCitizenSidebarOpen
                  ? "pl-3"
                  : ""
                : ""
            }`}
          >
            <ToastContainer
              hideProgressBar={true}
              autoClose={6000}
              containerId={"private"}
              position={toast.POSITION.TOP_RIGHT}
              closeButton={<CloseToast />}
            />
            {this.props.children}
          </div>
          {this.state.pwaModeEnabled && <PwaFooter />}
        </Container>
      </main>
    );
  }
}

const mapActionToProps = {
  updateUserData,
  showLoader,
  updateIsPageCourse,
  updateIsCitizenSidebarOpen,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  isPageCourse: state.isPageCourse,
  isCitizenSidebarOpen: state.isCitizenSidebarOpen,
});

export default withRouter(connect(mapStateToProps, mapActionToProps)(Layout));
