import React from 'react';

export default function faq() {
  return (
    <section className="main-container">
			<div className="container">
				<div className="py-2 mb-5 text-light-black terms-services">
					<h3 className="display-3 text-black-500 font-weight-bold mt-4 mb-0 text-center py-3">SILHOUETTE</h3>
					<div className="pb-4 text-center">
						<h5>fAQ INC SILHOUETTE.</h5>
					</div>
				</div>
			</div>
		</section>
  );
}
