import React, { Component } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { BackButton, Skip, Image, Cancel } from 'shared/components/pwa';
import { CLIENT_NAME } from 'config/constants/config';

export class Otp extends Component {
  render() {
  const phone_no = this.props.formData.phone_cellular;
    return(
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column" >
        {
          this.props.show &&
          <div className="d-flex" >
            <BackButton action={this.props.goBack}/>
            <Image />
          </div>
        }
        <h1 className="font-20-bold text-center my-5" >Enter verification code</h1>
        <div className="error errorIcon text-center" id="otpError"/>
        <Form className="pwa-form" >
          <Form.Group className="otp-input d-flex justify-content-center">
            <InputField
              id="otp1"
              type="tel"
              name="otp1"
              ref="otp1"
              maxLength={1}
              placeholder=" "
              onKeyUp={(e) => this.props.onKeyUp(e, "otp1")}
              handleChange={(e) => this.props.handleChange(e, 'otp1')}
            />
            <InputField
              id="otp2"
              type="tel"
              name="otp2"
              ref="otp2"
              maxLength={1}
              placeholder=" "
              onKeyUp={(e) => this.props.onKeyUp(e, "otp2")}
              handleChange={(e) => this.props.handleChange(e, 'otp2')}
            />
            <InputField
              id="otp3"
              type="tel"
              name="otp3"
              ref="otp3"
              maxLength={1}
              placeholder=" "
              onKeyUp={(e) => this.props.onKeyUp(e, "otp3")}
              handleChange={(e) => this.props.handleChange(e, 'otp3')}
            />
            <InputField
              id="otp4"
              type="tel"
              name="otp4"
              ref="otp4"
              maxLength={1}
              placeholder=" "
              onKeyUp={(e) => this.props.onKeyUp(e, "otp4")}
              handleChange={(e) => this.props.handleChange(e, 'otp4')}
            />
          </Form.Group>
          <p className="display-9 text-center mb-4" >Your 4 digit code was sent to <br /> <span className="text-primary" >{phone_no}</span>  </p>
          <p className="display-9 text-center" >By verifying your code you consent to receive notifications from {CLIENT_NAME}.</p>
          <div className="text-center mt-auto bottom-text-position">
            <p className="display-8 mb-5" >I did not receive a code <br /><span className="text-primary font-bold cursor-pointer" onClick={this.props.resendVerificationCode}>RESEND</span> </p>
            <button
              className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
              disabled={this.props.showLoader}
              onClick={(e) => this.props.nextPage(e, 'otp')}
            >
              {
                this.props.showLoader
                ? <Spinner animation="border" role="status"/>
                : 'CONTINUE'
              }
            </button>
            {
              this.props.showSkipButton &&
              <Skip skip={this.props.skip} name={'SKIP FOR NOW'} />
            }
            {
              this.props.showCancelButton &&
              <Cancel cancel={this.props.cancel}/>
            }
          </div>
        </Form>
      </div>
    );
  }
}
