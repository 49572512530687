import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as Utility from 'utils/utility';
import { GROUP_CAMPAIGNS, EVENT_CAMPAIGNS } from 'config/constants/api_endpoints';
import { CLIENT_ID, CRM_MODULES } from 'config/constants/config';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';
import EventCard from 'shared/components/cards/event_card';
import {  ChevronLeftIcon } from 'shared/icons/icons';
import { showLoader } from 'shared/actions';
import { connect } from 'react-redux';

function Events(props) {
  const userData = useSelector(state => state.userData);
  const refreshCampaignApi = useSelector(state => state.refreshCampaignApi);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState('');
  const [isApiResponse, setIsApiResponse] = useState(false);
  let debouncedFn = null;

  useEffect(() => {
    getEvents();
  }, [search, refreshCampaignApi]);

  const getEvents = () => {
    if(props.type === 'myEvents') {
      getMyEvents();
    }
    if(props.type === 'groupEvent') {
      getGroupEvents();
    }
  };

  const getMyEvents = () => {

    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      campaign_type_id: CAMPAIGN_TYPES_IDS.event
    };

    if (search)
      params.search = search;

    props.showLoader(true);
    Utility.sendRequest(EVENT_CAMPAIGNS, 1, params, (err, res, body) => {

      setIsApiResponse(true);
      props.showLoader(false);

      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
          setEvents(body.data);
      }
    });
  };

  const getGroupEvents = () => {
    const params = {
      userClientId: userData.id,
      groupId: props.groupId,
      clientId: CLIENT_ID,
      campaignTypeId: CAMPAIGN_TYPES_IDS.event,
      eventList: true,
      search,
    };

    if(!Utility.isEmpty(userData)){
      props.showLoader(true);
      Utility.sendRequest(GROUP_CAMPAIGNS, 1, params, (err, res, body) => {
        body = JSON.parse(body);

        props.showLoader(false);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
            setEvents(body);
        }
      });
    }
  };

  const paintEvents = () => {
    const arr = [];
    const goBackLink = `${props.type === 'groupEvent' ? `/groups/${props.groupId}` : '/events'}`;
    events.length &&
    events.map(e => {
      arr.push(
        <EventCard
          {...e}
          key={e.id}
          pageLoad={() => {}}
          eventDetailLink={true}
          goBackLink={goBackLink}/>
      );
    });
    return arr;
  };

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  return(
    <>
      <div className="mt-3 pb-4" >
        <h1 className="font-24-bold mb-4" >
          <ChevronLeftIcon
            handleClick={() => props.closeCallout(null)}
            mainClass="cursor-pointer see-all-back"
          />
          {props.heading}
        </h1>
        <Form className="shar-form search-form search-form-pwa sidebar-form" >
            <Form.Group className="mb-0" >
              <InputField
                required={true}
                type="text"
                placeholder="Search Events..."
                handleChange={(ev) => handleSearch(ev)}
              />
            </Form.Group>
        </Form>
        {Utility.isEmpty(events) && isApiResponse &&
          <div className="d-flex align-items-center justify-content-center mt-5" >
            <div className="px-5" >
              <span className="font-bold display-6">No record found</span>
            </div>
          </div>
        }
      </div>
      <div className="article-section">
        <div className="event-container d-flex flex-wrap" >
          {paintEvents()}
        </div>
      </div>
    </>
  );
}

const mapActionToProps = { showLoader };

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, mapActionToProps)(Events);
