import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Col, Spinner } from 'react-bootstrap';
import {  MdImage } from "react-icons/md";
import { MEDIA_SIGNED_URL, USERS } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import AvatarEditor from 'react-avatar-editor';
import Avatar from 'react-avatar';


export const PwaImageUpload = (props) => {
  const [cropStep, setCropStep] = useState(false);
  const [originalImage, setOriginalImage] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [imageUploaded, setImageUpload] = useState(false);
  const [show, setShow] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const handleClose = () => setShow(false);
  const userData = useSelector(state => state.userData);

  useEffect(() => {
    if (props.imageURL) {
       setImageURL(props.imageURL);
    }
  }, []);

  const uploadImage = (croppedImage) => {
    showSpinner(true);
    const blob = Utility.splitImageDataAndConvertToBlob(croppedImage);
    generateSignedUrlAndUpload(blob);
  };

  const generateSignedUrlAndUpload = (file) => {
    Utility.sendRequest(MEDIA_SIGNED_URL, 1, {}, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      const url = body.imageSignedUrl;
       const imageURL = await Utility.uploadFile(file, url);
       setImageURL(imageURL);

      Utility.sendRequest(USERS, 3, { profile_image_url: imageURL }, async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          toast.success('Profile picture updated successfully', { containerId: 'private' });
          setCropStep(false);
          setImageUpload(true);
          showSpinner(false);

          if (props.updateUserData) {
            await props.updateUserData({ ...userData, profile_image_url: imageURL });
            await props.refreshImage();
          }
          if (props.setIsProfileUploaded) {
            props.setIsProfileUploaded(true);
          }
        }
      });
    });
  };

  const handleFileChange = (imagePreview) => {
    setOriginalImage(imagePreview);
    setCropStep(true);
    setShow(true);
  };

  const handleImageChange = (event) => {
    setCropStep(true);
    setShow(true);
    const reader = new FileReader();
    const originalFile = event.target.files[0];

    if (!originalFile) return;

    if (['image/png', 'image/gif', 'image/jpeg'].includes(originalFile.type)) {
      const file = new File([originalFile], 'profile_image');
      reader.onloadend = () => { handleFileChange(reader.result);};
      reader.readAsDataURL(file);
    } else {
      toast.error('Please select a valid image', { containerId: 'private' });
    }
  };

    return(
        <>

            {!imageUploaded &&
            <label>
              <input type="file" id="file" accept="image/png,image/gif,image/jpeg" className="d-none" onClick={(event) => { event.target.value = null; } }  onChange={(e) => handleImageChange(e)}/>
              <div className="profile-img-upload d-flex align-items-center justify-content-center rounded-circle mx-auto" >
              {
                cropStep &&
                <Modal show={show} onHide={handleClose} size="lg" className="shar-modal" centered>
                  {
                     <EditModal setCropStep={setCropStep} imagePreview={originalImage} uploadImage={uploadImage} spinner={spinner}/>
                  }
                </Modal>
              }
              {
                (!imageUploaded && Utility.isEmpty(imageURL)) &&
                <div className="add-icon-lg" >
                  {
                    props.showAddIcon ?
                      <div className="flex-center h-100" >
                        <img src={require("assets/images/plus_icon.svg")} alt="add icon" />
                      </div>
                    :
                      <Avatar
                        name={props.user_name}
                        round={true}
                        size={"96"}
                        textSizeRatio={1.75}
                        color='#007bb3'
                        maxInitials={1}
                    />
                  }
             </div>
              }
              {
                (!imageUploaded && !Utility.isEmpty(imageURL)) &&
                <>
                  <div className="add-icon-lg" >
                     <img className="h-100 user-image-uploaded" src={imageURL} alt="add icon"/>
                  </div>
                </>
              }
              </div>
              { !props.removeEditInfoText &&
                <span className="font-medium display-8 mt-2 d-block">Click on image to edit</span>
              }
            </label>
          }
          {imageUploaded &&
            <>
            <label className="position-relative" >
              <label className="upload-edit-icon" >
                <input type="file" id="file" accept="image/png,image/gif,image/jpeg" className="d-none" onClick={(event) => { event.target.value = null; } }  onChange={(e) => handleImageChange(e)}/>
                {/*<img className="hx-20 approve-action-icon" src={require("assets/images/icons/pencil-white.svg")} alt={ "pencil icon"}/>*/}
              </label>
              <div className="profile-img-upload d-flex align-items-center justify-content-center rounded-circle mx-auto" >
              {
                cropStep &&
                <Modal show={show} onHide={handleClose} size="lg" className="shar-modal" centered>
                  {
                     <EditModal setCropStep={setCropStep} imagePreview={originalImage} uploadImage={uploadImage} spinner={spinner}/>
                  }
                </Modal>
              }
              {!Utility.isEmpty(imageURL) &&
                <div className="add-icon-lg" >
                  <img src={imageURL} alt="add icon" className="user-image-uploaded"/>
                </div>
              }
              </div>
            </label>
            </>
          }
    </>
    );
};

export const EditModal = (props) => {
  const [scale, setScale] = useState(1);
  const cropper = useRef(null);

  const getCroppedImage = () => {
    const image = cropper.current.getImage().toDataURL();
    props.uploadImage(image);
  };

  return (
    <>
      <Modal.Header className="justify-content-center border-bottom-0" closeButton={!props.spinner}>
        <Modal.Title className="display-8 font-bold">Edit Thumbnail</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-5 mt-3">
        <div className="edit-img text-center">
          <AvatarEditor
            image={props.imagePreview}
            width={190}
            height={190}
            border={0}
            borderRadius={100}
            scale={scale}
            ref={cropper}
          />
        </div>
        <div className="text-center display-10 my-4">Resize & Crop</div>
        <Col md={6} className="edit-img-resizer mx-auto">
          <div className="resizer">
            <input type="range" min="1" max="5" defaultValue="1" className="w-100" onChange={(e) => setScale(parseInt(e.target.value))} />
          </div>
          <div className="d-flex justify-content-between mt-2">
            {/*<span className="display-10"> <MdImage onClick={() => props.setCropStep(false)}/></span>
            <span className="display-8 line-height-1"> <MdImage onClick={() => props.setCropStep(false)}/></span>*/}
          </div>
        </Col>
        <div className="text-right pt-5 mt-5">
          <Button variant="link" disabled={props.spinner} className={`mr-3  border-0 ${props.spinner ? 'btn-disabled' : ''}`} onClick={() => props.setCropStep(false)}>CANCEL</Button>
          <Button variant="primary" onClick={() => getCroppedImage()}>
            {
              props.spinner
              ? <Spinner animation="border" role="status"/>
              : 'SUBMIT'
            }
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default PwaImageUpload;
