import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { InputField } from "shared/components/form_elements";
import { BackButton, Image } from 'shared/components/pwa';
import { CLIENT_NAME } from 'config/constants/config';

export default class Otp extends React.Component{
  render(){
    return(
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column" >
        <div className="d-flex" >
          <BackButton action={this.props.goBack} />
          <Image />
        </div>
        <h1 className="font-20-bold text-center my-5">Enter verification code</h1>
        <Form className="pwa-form" >
          <Form.Group className="otp-input d-flex justify-content-center" controlId="formBasicNumber">
            <InputField
            type="tel"
            name="otp1"
            ref="otp1"
            placeholder=""
            maxLength={1}
            onKeyUp={(e) => this.props.onKeyUp(e, "otp1")}
            handleChange={(e) => this.props.handleOtpChange(e)}
          />
          <InputField
            type="tel"
            name="otp2"
            ref="otp2"
            maxLength={1}
            onKeyUp={(e) => this.props.onKeyUp(e, "otp2")}
            placeholder=""
            handleChange={(e) => this.props.handleOtpChange(e)}
          />
          <InputField
            type="tel"
            name="otp3"
            ref="otp3"
            placeholder=""
            maxLength={1}
            onKeyUp={(e) => this.props.onKeyUp(e, "otp3")}
            handleChange={(e) => this.props.handleOtpChange(e)}
          />
          <InputField
            type="tel"
            name="otp4"
            ref="otp4"
            placeholder=""
            maxLength={1}
            onKeyUp={(e) => this.props.onKeyUp(e, "otp4")}
            handleChange={(e) => this.props.handleOtpChange(e)}
          />
          </Form.Group>
          <p className="display-9 text-center mb-4" >Your 4 digit code was sent to <br /> <span className="text-primary" > {this.props.email}</span>  </p>
          <p className="display-9 text-center" >By verifying your code you consent to receive notifications from {CLIENT_NAME}.</p>
        </Form>
        <div className="text-center mt-auto bottom-text-position">
            <p className="display-8 mb-5" >I did not receive a code <br /><span className="text-primary font-bold cursor-pointer"onClick ={(e) => this.props.resendVerificationCode(e) }>RESEND</span> </p>
              <button
                className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
                onClick={(e) => this.props.handleOtpVerify(e)}
              >
                {
                  this.props.showLoader
                  ? <Spinner animation="border" role="status"/>
                  : 'VERIFY'
                }
              </button>
          </div>
      </div>
    );
  }
}
