import React from 'react';
import { AttachIcon, ChevronLeftIcon, CircleFilledAddLargeIcon, SmileIcon, SendIcon  } from 'shared/icons/icons';

const GroupChat = () => {
    return (
        <div className="group-chat-outer" >
            <div className="group-chat-header bg-primary text-white p-2 header">
                <div onClick= {() => window.history.back()} className="mr-2" >
                    <ChevronLeftIcon fillClass="fill-white" />
                </div>

                <img alt="group image" className="group-chat-dp rounded-circle" src={require('assets/images/articles/article.jpg')}  />

                <div className="font-bold ml-3 display-7" >Group Name</div>

                <div className="ml-auto" >
                    <CircleFilledAddLargeIcon mainClass="group-chat-add" />
                </div>

                <div className="mx-3" >
                    <img alt="call icon" className="group-chat-call" src={require('assets/images/icons/call-white.svg')} />
                </div>

                <div>
                    <img alt="more icon" className="group-chat-more" src={require('assets/images/icons/menu_vertical_icon_white.svg')} />
                </div>
            </div>
            <div className="group-chat-body" >
                <div className="text-center" >
                    <img alt="group image" className="group-chat-main-img rounded-circle mx-auto" src={require('assets/images/articles/article.jpg')}  />
                    <div className="font-bold display-7 my-3" >Group Name</div>
                    <div className="display-7" >Lorem ipsum dolor sit amet,<br/> consectetur adipsoscing elit, sed do.</div>
                    <button className="btn btn-primary btn-sm mt-4 group-chat-join" >Join</button>
                </div>

                <div className="messages" >
                    <div className="text-center font-12 font-med mt-4">Apr 9, 2021</div>
                    <ul>
                         <li className="replies float-none w-100 mx-0">
                            <img
                                src="https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/4d883f93-a5b0-4d66-be12-4142e01aa776/1619607274055image" 
                                className="message-profile-picture" 
                            />
                            <p className="group-chat-text" >HI</p>
                            <div className="group-chat-time d-flex w-100">
                                <span className="font-12 font-light">06:58 PM</span>
                                <img src={require('assets/images/icons/heart-light.svg')} className="group-chat-like ml-auto" />
                            </div>
                        </li>
                        <li className="sent float-none w-100 mx-0">
                            <div>
                                <img 
                                    src="https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/4d883f93-a5b0-4d66-be12-4142e01aa776/1619607274055image" 
                                    class="message-profile-sent" 
                                />
                                <p className="group-chat-text">HI</p>
                            </div>
                            <div className="group-chat-time d-flex w-100">
                                <span class="font-12 font-light">06:58 PM</span>
                                <img src={require('assets/images/icons/heart-light.svg')} className="group-chat-like ml-auto" />
                            </div>
                        </li>
                    </ul>

                    <div class="text-center font-12 font-med mt-4">Apr 8, 2021</div>
                    <ul>
                        <li className="sent float-none w-100 mx-0">
                            <div>
                                <img 
                                    src="https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/4d883f93-a5b0-4d66-be12-4142e01aa776/1619607274055image" 
                                    class="message-profile-sent" 
                                />
                                <p className="group-chat-text" >Lorem ipsum dolor sit amet, consectetur adipsoscing elit, sed do eiusomed tempor incididunt ut labore et dolore magna alique.</p>
                            </div>
                            <div className="group-chat-time d-flex w-100">
                                <span class="font-12 font-light">06:58 PM</span>
                                <img src={require('assets/images/icons/heart-light.svg')} className="group-chat-like ml-auto" />
                            </div>
                        </li>
                        <li className="replies float-none w-100 mx-0">
                            <img 
                                src="https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/4d883f93-a5b0-4d66-be12-4142e01aa776/1619607274055image" 
                                class="message-profile-picture" 
                            />
                            <p className="group-chat-text">Sed do eiusomed tempor incididunt ut labore et dolore magna alique.</p>
                            <div className="group-chat-time d-flex w-100">
                                <span class="font-12 font-light">06:58 PM</span>
                                <img src={require('assets/images/icons/heart-light.svg')} className="group-chat-like ml-auto" />
                            </div>
                        </li>
                        <li className="replies float-none w-100 mx-0">
                            <img 
                                src="https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/4d883f93-a5b0-4d66-be12-4142e01aa776/1619607274055image" 
                                class="message-profile-picture" 
                            />
                            <div className="group-chat-article">
                                <div className="article-section-outer pr-3" >
                                    <div className="article-outer overflow-hidden mb-3 cursor-pointer">
                                        <div className="article-main-img-wrap">
                                            <img src={require('assets/images/articles/article.jpg')} alt="article image" className="group-chat-article-pic" />
                                        </div>
                                        <div className="px-3 py-4 ">
                                            <div className="font-14 mb-20 font-bold" style={{color: 'rgb(29, 77, 25)'}}>Unity</div>
                                            <div className="font-bold display-6 article-title pb-1 text-dark">First Article</div>
                                            <div className="d-flex mt-3">
                                                <img src="https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/64686e71-80ab-11ea-a5d6-d146249bbf68/1587129251509image" alt="" className="article-pic rounded-circle" />
                                                <div>
                                                    <div className="font-bold font-12 text-dark text-capitalize">GIL</div>
                                                    <div className="font-12 text-secondary">Updated at 11:27 PM, May 19</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-chat-time d-flex w-100">
                                <img src={require('assets/images/icons/heart-light.svg')} className="group-chat-like ml-auto" />
                            </div>
                        </li>
                        <li className="replies float-none w-100 mx-0">
                            <img 
                                src="https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/4d883f93-a5b0-4d66-be12-4142e01aa776/1619607274055image" 
                                class="message-profile-picture" 
                            />
                            <p className="group-chat-text">Sed do eiusomed tempor incididunt ut labore et dolore magna alique.</p>
                            <div className="group-chat-time d-flex w-100">
                                <span class="font-12 font-light">06:58 PM</span>
                                <img src={require('assets/images/icons/heart-light.svg')} className="group-chat-like ml-auto" />
                            </div>
                        </li>
                        <li className="sent float-none w-100 mx-0">
                            <div>
                                <img 
                                    src="https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/4d883f93-a5b0-4d66-be12-4142e01aa776/1619607274055image" 
                                    class="message-profile-sent" 
                                />
                                <div className="group-chat-attachment d-flex" >
                                    <div className="bg-primary flex-center group-chat-attachment-icon-outer" >
                                        <AttachIcon fillClass="fill-white" />
                                    </div>
                                    <div className="group-chat-attachment-data" >
                                        <div className="font-bold text-primary display-8" >Document Name</div>
                                        <div className="font-12" >PDF</div>
                                    </div>
                                    <div className="ml-auto align-self-end group-chat-attachment-download-outer" >
                                        <img src={require('assets/images/icons/download_icon.svg')} alt="attachment icon" className="group-chat-attachment-download" />
                                    </div>
                                </div>
                            </div>
                            <div className="group-chat-time d-flex w-100">
                                <span class="font-12 font-light">06:58 PM</span>
                                <img src={require('assets/images/icons/heart-light.svg')} className="group-chat-like ml-auto" />
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
            <div className="group-chat-footer position-fixed bg-white w-100 align-items-center border-top" >
                <div className="ml-2" >
                    <ImgUpload />
                </div>
                <div className="mx-4" >
                    <AttachIcon fillClass="fill-primary" />
                </div>
                <div className="ml-auto" >
                    <ChevronLeftIcon fillClass="fill-black" />
                </div>
                <div className="position-relative" >
                    <input type="text" className="group-chat-input mr-1 ml-2" />
                    <div className="group-chat-emoji position-absolute" >
                        <SmileIcon fillClass="fill-primary" strokeClass="stroke-primary" />
                    </div>
                </div>
                <div className="bg-primary rounded-circle flex-center group-chat-send" >
                    <SendIcon fillClass="fill-white" />
                </div>
            </div>
        </div>
    );
}

const ImgUpload = (props) => {
    return(
        <svg width="25px" height="24px" viewBox="0 0 25 24">
            <g id="PWA-2021" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="7.1-Group-Page-1" transform="translate(-67.000000, -1576.000000)" fill="#272262" fill-rule="nonzero">
                    <g id="Group-5" transform="translate(0.001162, 1563.000000)">
                        <g id="bx:bx-image-add" transform="translate(66.998838, 13.000000)">
                            <path d="M2.5,2.5 L18.75,2.5 L18.75,11.25 L21.25,11.25 L21.25,2.5 C21.25,1.12125 20.12875,0 18.75,0 L2.5,0 C1.12125,0 0,1.12125 0,2.5 L0,17.5 C0,18.87875 1.12125,20 2.5,20 L12.5,20 L12.5,17.5 L2.5,17.5 L2.5,2.5 Z" id="Path"></path>
                            <polygon id="Path" points="7.5 10 3.75 15 17.5 15 12.5 7.5 8.75 12.5"></polygon>
                            <polygon id="Path" points="21.25 13.75 18.75 13.75 18.75 17.5 15 17.5 15 20 18.75 20 18.75 23.75 21.25 23.75 21.25 20 25 20 25 17.5 21.25 17.5"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default GroupChat;