import { createStore } from 'redux';
import { allReducers } from './root_reducer';
import { loadState, saveState } from './persist-redux';
import { CAMPAIGN_TYPES_IDS, LOCALSTORAGE } from 'config/constants';
import * as Utility from 'utils/utility';

const persistedStore = loadState();

const initialState = {
  userData: persistedStore || {},
  publicUserData: {},
  clientTypes: [],
  sdGoals: [],
  isLoading: false,
  crmModules: [],
  selectedModule: {},
  selectedMenuItem: {},
  selectedGroup: {},
  edit: null,
  productId: null,
  productAttributes: [],
  selectedFilters: {},
  selectedModuleMenu: {},
  accountList: {},
  neighborhoodState: {},
  isCampaignFormOpen: false,
  isCampSelFormOpen: false,
  campaignSelectedTab: CAMPAIGN_TYPES_IDS.story,
  campaignFormSelectedId: null,
  isChatFormOpen: false,
  chatFriend: {},
  isSessionModalOpen: false,
  openCityPurchase: false,
  isNeighborhoodFormOpen: false,
  seeAll: {
    isOpen: null,
    calledFrom: '',
  },
  refreshCampaignApi: 0,
  isPlanUpgraded: false,
  isAdminAccessRequestFormOpen: { open: false, data: null },
  isAcceptedAdminRequestFormOpen: { open: false, data: null },
  isPlusIconClicked: false,
  currentFooterLink: 'home',
  cart: Utility.loadState(LOCALSTORAGE.CART_INFO) || {
    cartItems: [],
  },
  guestData: {},
  isUserDistracted: false,
  isCampaignVideoPlaying: false,
  extUserId: '',
  extTopic: '',
  isSessionStart: false,
  isVideoPlayed: false,
  category_listing_info: {
    isListingPageOpen: false,
    isCampaignDisplayFormOpen: false,
    selectedCategory: ''
  },
  newCorporateJoined: false,
  isExtensionReportOpen: false,
};

export const store = createStore(
  allReducers,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
  const state = store.getState();
  saveState(state.userData);

  // persist cart data for #Name Silhouette
  Utility.saveState(state.cart, LOCALSTORAGE.CART_INFO);

  // persist extension data for #Ahura
  // TODO: store data in session storage instead of local storage
  // Utility.saveState(state.extTopic, LOCALSTORAGE.EXT_TOPIC);
});
