import React, { Component } from "react";
import ReactPlayer from "react-player";
import {
  ETHOS_CLIENT_ID,
  GIL_CLIENT_ID,
  CAMPAIGN_TYPES_IDS,
  SM_CLIENT_ID,
} from "config/constants";
import { CLIENT_ID } from "config/constants/config";
import { connect } from "react-redux";
import { SUGGESTED_READS_LIMIT } from "config/constants/config";
import WelcomeSection from "scenes/users/components/home/welcome_section.js";
import SuggestedReads from "shared/components/v2/suggested_reads";
import SuggestedCourses from "shared/components/v2/suggested_courses";
import { SuggestedReadsCard } from "shared/components/suggested_reads_articles";
import * as Utility from "utils/utility";
import { Form } from "react-bootstrap";
import { InputField } from "shared/components/form_elements";
import {
  CAMPAIGNS_TOP_STORIES,
  ALL_PARENT_CAMPAIGNS,
} from "config/constants/api_endpoints";
import HomeContainer from "shared/components/home_container";
import { toast } from "react-toastify";
import SeeAll from "shared/components/v2/see_all";
import {
  openNeighborhoodForm,
  showLoader,
  updateAccountList,
  updateUserData,
  updateRefreshCampaignAPI,
  updateCampaignFormOpen,
  updateIsChatFormOpen,
  updateSeeAll,
  updateCampaignVideoPlaying,
} from "shared/actions";

class MediaView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: false,
      allArticles: [],
      myCourses: [],
      publicCourses: [],
      seeMoreOrLess: "See More",
      coursesLength: 0,
      is_mounted: false,
    };
  }
  componentDidMount = () => {
    this.setState({ is_mounted: true });
    if (
      GIL_CLIENT_ID === this.props.userData.client_id &&
      Utility.pwaModeEnabled()
    ) {
      this.getCampaignsTopStoriesDetails();
      this.getMyCourses(this.props.userData, 0);
      this.getMyCourses(this.props.userData);
      this.getCampaignCourses();
    }
  };

  onVideoEnd = () => {
    this.setState({ autoPlay: false });
    if (this.props.isStudent) {
      if (
        CLIENT_ID === ETHOS_CLIENT_ID &&
        this.props.videoWatched === false &&
        this.props.download === true
      ) {
        this.props.setVideoWatched(true);
      }
      if (
        CLIENT_ID === ETHOS_CLIENT_ID &&
        this.props.videoWatched === true &&
        this.props.download === true
      ) {
        this.props.setWatched(true);
      }
    }
  };

  handleVideoProgress = (data) => {
    if (
      Math.floor(data.playedSeconds) === 30 &&
      this.props.download === false &&
      CLIENT_ID === ETHOS_CLIENT_ID
    ) {
      const videoPaywall = "VIDEO";
      this.props.openPaywall(videoPaywall);
    }
  };

  getCampaignsTopStoriesDetails = (seeAll = false) => {
    const params = {
      publisher_network_id: CLIENT_ID,
      limit: SUGGESTED_READS_LIMIT,
    };

    if (seeAll) {
      delete params.range;
    }

    if (!Utility.isEmpty(this.props.userData)) {
      Utility.sendRequest(
        CAMPAIGNS_TOP_STORIES,
        1,
        params,
        (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            if (body.length) this.setState({ allArticles: body });
          }
        }
      );
    }
  };

  getMyCourses = (userData, limit = 3) => {
    this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.courses,
    };

    if (limit !== 3) {
      params.all = true;
    } else params.limit = limit;

    Utility.sendRequest(
      ALL_PARENT_CAMPAIGNS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);

        if (!body.error) {
          this.setState({ myCourses: body.data });
          if (params.all) this.setState({ coursesLength: body.data.length });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  getCampaignCourses = (search = "") => {
    const params = {
      publisher_network_id: CLIENT_ID,
      is_course: true,
      search,
    };
    Utility.sendRequest(CAMPAIGNS_TOP_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.length) this.setState({ publicCourses: body });
      }
    });
  };

  handleSearch = (e) => {
    this.getCampaignCourses(e.target.value);
  };

  seeMoreOrLessCourses = () => {
    if (this.state.seeMoreOrLess === "See More") {
      this.setState({ seeMoreOrLess: "See Less" }, () => {
        this.getMyCourses(this.props.userData, 1);
      });
    } else {
      this.setState({ seeMoreOrLess: "See More" }, () => {
        this.getMyCourses(this.props.userData);
      });
    }
  };

  handleCoursesClick = (id) => {
    this.props.history.push({
      pathname: `/campaign/${id}`,
      goBackLink: "/media",
    });
  };

  render() {
    console.log("mii", this.props.isLesson);
    return (
      <>
        {this.props.videoUrl ? (
          <div className={this.props.outerClassName}>
            {/* {(CLIENT_ID === GIL_CLIENT_ID && Utility.pwaModeEnabled()) && */}
            {/* {(CLIENT_ID === GIL_CLIENT_ID) &&
                ?
                (<video id='campaign_video' preload="metadata" type="video/mp4" controls>
                  <source src={this.props.videoUrl + '#t=0.1'} type="video/mp4"/>
                </video>)
                : */}

            <ReactPlayer
              playing={this.state.autoPlay}
              className={this.props.innerClassName}
              width={this.props.width}
              url={this.props.videoUrl}
              style={
                CLIENT_ID === GIL_CLIENT_ID
                  ? { paddingBottom: "40px" }
                  : ""
              }
              ref="player"
              loop={false}
              light={this.props.imageUrl}
              controls={true}
              onProgress={this.handleVideoProgress}
              onEnded={() => this.onVideoEnd()}
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
                youtube: {
                  playerVars: {
                    rel: 0,
                    showinfo: 0,
                    fs: 1,
                    disablekb: 0,
                    controls: 1,
                    modestbranding: 1,
                  },
                  embedOptions: {
                    rel: 0,
                  },
                },
              }}
            />
            {/* } */}
          </div>
        ) : (
          <img
            className={`${this.props.imageUrl ? "" : "d-none"} ${
              this.props.imageClassName
            }`}
            src={this.props.imageUrl}
            alt="image caption"
          />
        )}

        {!this.props.isCarousel &&
          !this.props.imageUrl &&
          !this.props.videoUrl &
            (GIL_CLIENT_ID === this.props.userData.client_id) &&
          Utility.pwaModeEnabled() && (
            <>
              <HomeContainer
                name="Courses"
                className="event-home"
                goBackLink="/home"
                history={this.props.history}
              >
                <p>
                  Join courses and improve your learning capacity in the
                  community.
                </p>
                <h1 className="font-24-bold mb-2 pt-2 home-page-heading">
                  My Courses
                </h1>

                {Utility.pwaModeEnabled() &&
                !Utility.isEmpty(this.state.myCourses)
                  ? this.state.myCourses.map((items, index) => {
                      return (
                        <>
                          <div
                            onClick={() => this.handleCoursesClick(items.id)}
                            key={index}
                            className="align-items-center py-3 px-0 cursor-pointer flex-nowrap"
                          >
                            <div className="align-items-center flex-nowrap">
                              <img
                                src={items.image_url}
                                alt=""
                                className="rounded courses-small-img"
                              />
                              <div className="ml-2 pl-1">
                                <div className="font-weight-bold display-7">
                                  {items.heading}
                                </div>
                                <div className="font-14 b-500">
                                  {items.campaign_category}
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="ml-auto flex-center"
                            >
                              <img
                                src={require("assets/images/icons/menu_vertical_icon.svg")}
                                alt="more icon"
                                style={{ width: 5 }}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })
                  : Utility.pwaModeEnabled() &&
                    Utility.isEmpty(this.state.myCourses) && (
                      <div className="font-weight-bold display-7 my-4 text-center text-muted">
                        No Courses Found
                      </div>
                    )}
                {Utility.pwaModeEnabled() && this.state.coursesLength > 3 && (
                  <div className="text-center mb-4 mt-2">
                    <span
                      className="cursor-pointer font-weight-bold display-8 text-primary"
                      onClick={() => this.seeMoreOrLessCourses()}
                    >
                      {this.state.seeMoreOrLess}
                    </span>
                  </div>
                )}

                <div className="mt-3 pb-4">
                  <h1 className="font-24-bold mb-4">Public Courses</h1>
                  <Form className="shar-form search-form search-form-pwa sidebar-form">
                    <Form.Group className="mb-30 align-items-center flex-nowrap d-flex">
                      <InputField
                        type="text"
                        handleChange={(e) => this.handleSearch(e)}
                        placeholder="Search Public Courses..."
                        className="mb-0"
                      />
                    </Form.Group>
                  </Form>
                  {Utility.isEmpty(this.state.publicCourses) && (
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <div className="px-5">
                        <span className="font-bold display-6">
                          No courses found
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="home-page-padding courses-tab-pwa pt-lg-0 media-pwa-outer article-section justify-content-center">
                    <SuggestedReadsCard stories={this.state.publicCourses} />
                  </div>
                </div>
              </HomeContainer>
            </>
          )}
        {!this.props.isCarousel &&
          !this.props.imageUrl &&
          !this.props.videoUrl &
            (GIL_CLIENT_ID === this.props.userData.client_id) &&
          Utility.pwaModeEnabled() &&
          this.props.seeAll.isOpen && (
            <SeeAll
              closeCallout={(value) => {
                this.props.updateSeeAll({ isOpen: value, calledFrom: value });
              }}
              calledFrom={this.props.seeAll.isOpen}
              width={"100%"}
            />
          )}
      </>
    );
  }
}

const mapActionToProps = {
  showLoader,
  updateRefreshCampaignAPI,
  openNeighborhoodForm,
  updateAccountList,
  updateUserData,
  updateCampaignFormOpen,
  updateIsChatFormOpen,
  updateSeeAll,
  updateCampaignVideoPlaying,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  isLoading: state.isLoading,
  accountList: state.accountList,
  neighborhoodState: state.neighborhoodState,
  isCampaignFormOpen: state.isCampaignFormOpen,
  isChatFormOpen: state.isChatFormOpen,
  chatFriend: state.chatFriend,
  seeAll: state.seeAll,
  refreshCampaignApi: state.refreshCampaignApi,
  isVideoPlayed: state.isVideoPlayed,
});

export default connect(mapStateToProps, mapActionToProps)(MediaView);
