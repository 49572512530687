import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import * as Utility from 'utils/utility';
import CreateSelect from 'react-select/creatable';


export class InputField extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Control
          id={this.props.id}
          ref={this.props.name}
          value={this.props.value}
          onBlur={this.props.onFocus}
          onKeyDown={this.props.onKeyDown}
          onKeyUp={this.props.onKeyUp}
          onKeyPress={this.props.onKeyPress}
          onClick={this.props.handleClick}
          type={this.props.type}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={(e) => this.props.handleChange(e)}
          disabled={this.props.disabled}
          style={this.props.inlineStyle}
          className={this.props.className}
          required={this.props.required}
          readOnly= {this.props.readonly}
          autoComplete="off"
        />
        <div className="error errorIcon" id={this.props.name + "Error"}/>
      </React.Fragment>
    );
  }
}

export function Radio(props) {
  return (
    <Form.Check
      custom
      id={props.id}
      type="radio"
      name={props.name}
      label={props.label}
      value={props.value}
      checked={props.checked}
      defaultValue={props.defaultValue}
      onChange={(e) => props.handleChange(e)}
    />
  );
}

export class DropDown extends Component {
  render() {
    const key = this.props.optionId;
    const value = this.props.optionValue;
    const dropDownOptions = this.props.options.map((item, index) => {
      return(
        <option
          key={`${index}-${item.id}`}
          value={item[key]}
          selected={this.props.defaultValue === item.name ? "selected" : ""}
          >{item[value]}
        </option>
      );
    });

    return (
      <React.Fragment>
        <Form.Control as="select"
          id={this.props.dropDownId}
          ref={this.props.name}
          disabled={this.props.disabled}
          required={this.props.required}
          name={this.props.name}
          className="round"
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={(e) => this.props.handleChange(e)}>
          {dropDownOptions}
        </Form.Control>
        <div className="error errorIcon" id={this.props.name + "Error"}/>
      </React.Fragment>
    );
  }
}

export class Checkbox extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Check
          custom
          type="checkbox"
          ref={this.props.name}
          name={this.props.name}
          label={this.props.label}
          id={this.props.checkboxId}
          disabled={this.props.disabled}
          className={this.props.className}
          defaultChecked={this.props.checked}
          placeholder={this.props.placeholder}
          onChange={(e) => this.props.handleChange(e)}
        />
      </React.Fragment>
    );
  }
}

export class Textarea extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Control
          id={this.props.id}
          ref={this.props.name}
          required={this.props.required}
          name={this.props.name}
          maxLength={this.props.maxLength}
          as="textarea"
          rows={this.props.rows}
          placeholder={this.props.placeholder || this.props.placeholder}
          value={this.props.value}
          onChange={(e) => this.props.handleChange(e)}
          onKeyDown={this.props.onKeyDown}
          autoresize={this.props.autoresize}
          className={this.props.className}
        />
      <div className="error errorIcon" id={this.props.name + "Error"}/>
      </React.Fragment>
    );
  }
}

export const MultiDropDown = (props) => {
  const dropDownOptions = props.options.map((item, index) => {
    return(
      { id: props.id, value: item.id, label: item.name }
    );
  });

  const defaultValues = () => {
    const arr = [];
    dropDownOptions.map(e => {
      if(props.defaultValues && props.defaultValues.includes(e.value))
        arr.push(e);
    });
    return arr;
  };

  return(
    <Select
      value={props.value}
      onChange={props.handleChange}
      options={dropDownOptions}
      isMulti={props.isMulti}
      defaultValue={defaultValues()}
      placeholder={props.placeholder}
    />
  );
};

export class CheckboxNew extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Check
          custom
          type="checkbox"
          placeholder={this.props.placeholder}
          id={this.props.id}
          value={this.props.value}
          ref={this.props.name}
          name={this.props.name}
          label={this.props.label}
          checked={this.props.checked}
          disabled={this.props.disabled}
          onChange={(e) => this.props.handleChange(e)}
        />
      </React.Fragment>
    );
  }
}

export const CustomTagCreation = (props) => {
  const arr = [];
  const defaultValues = () => {
    if (Utility.isEmpty(props.subCategories)) {
      return arr;
    }

    props.subCategories.map((item, index) => {
        const obj = {};
        obj.label = item;
        obj.value = index;
        arr.push(obj);
    });
    return arr;
  };
    return (
      <>
      <CreateSelect
        isMulti={true}
        onChange={props.handleChange}
        noOptionsMessage={() => null}
        value={defaultValues()}
        placeholder={'Enter Sub-Categories'}
        className="shar-form-element"
      />
      </>
    );
};
