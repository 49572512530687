import React from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import { USERS, USER_AUTH } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import { CloseIcon, ChevronDownIcon } from 'shared/icons/icons';
import Accordion from 'react-bootstrap/Accordion';
import { connect } from 'react-redux';
import { updateUserData } from 'shared/actions';

class ScheduleTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      WEEK: [
        { id: 1, value: 'Monday', isChecked: false },
        { id: 2, value: 'Tuesday', isChecked: false },
        { id: 3, value: 'Wednesday', isChecked: false },
        { id: 4, value: 'Thursday', isChecked: false },
        { id: 5, value: 'Friday', isChecked: false },
        { id: 6, value: 'Saturday', isChecked: false },
        { id: 7, value: 'Sunday', isChecked: false },
      ],
      checkedItems: new Map(),
      isLoading: false,
      openIndex: -1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleWeekChange = this.handleWeekChange.bind(this);
  }

  // Do not change time format
  // TODO: generate time dynamically instead of static typed
  TIME = [
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:30 PM',
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
  ];

  handleChange = async (e, week) => {
    const key = `${week.value}-${e.target.value}`;
    const value = {
      day: week.value,
      time: e.target.value,
      isChecked: e.target.checked,
    };
    if (e.target.checked) {
      await this.setState(prevState => ({
        checkedItems: prevState.checkedItems.set(key, value),
      }));
    } else {
      this.state.checkedItems.delete(key);
    }

    if (
      Array.from(this.state.checkedItems).filter(
        ([key, value]) => value.day === week.value && value.isChecked === true
      ).length > 0
    ) {
      await this.setState(prevState => ({
        WEEK: prevState.WEEK.map(obj =>
          obj.id === week.id ? Object.assign(obj, { isChecked: true }) : obj
        ),
      }));
    } else {
      await this.setState(prevState => ({
        WEEK: prevState.WEEK.map(obj =>
          obj.id === week.id ? Object.assign(obj, { isChecked: false }) : obj
        ),
      }));
    }
  };

  componentDidMount = () => {
    if (this.props.isUpdate && !Utility.isEmpty(this.props.userData)) {
      this.fetchUserData();
      this.handleWeekChecked();
      this.getScheduledStudyTime();
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.isUpdate && prevProps.userData !== this.props.userData) {
      this.handleWeekChecked();
      this.getScheduledStudyTime();
    }
  };

  fetchUserData = () => {
    Utility.sendRequest(
      USER_AUTH,
      1,
      { id: this.props.userData.id },
      (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          this.props.updateUserData(body);
        } else {
          toast.error(body.error.message, { containerId: 'public' });
        }
      }
    );
  };

  handleWeekChecked = () => {
    if (
      !Utility.isEmpty(this.props.userData) &&
      !Utility.isEmpty(this.props.userData.additional_details)
    ) {
      const additional_details = JSON.parse(
        this.props.userData.additional_details
      );
      if (additional_details && additional_details.selectedTime) {
        this.state.WEEK.forEach(item => {
          const selectedDay = Object.values(
            additional_details.selectedTime
          ).some(key => key.day === item.value);
          if (selectedDay) {
            this.setState(prevState => ({
              WEEK: prevState.WEEK.map(obj =>
                obj.id === item.id
                  ? Object.assign(obj, { isChecked: true })
                  : obj
              ),
            }));
          }
        });
      }
    }
  };

  getScheduledStudyTime = () => {
    if (
      this.props.userData &&
      !Utility.isEmpty(this.props.userData) &&
      !Utility.isEmpty(this.props.userData.additional_details)
    ) {
      const additional_details = JSON.parse(
        this.props.userData.additional_details
      );
      return additional_details && additional_details.selectedTime
        ? this.setState({
            checkedItems: new Map(
              Object.entries(additional_details.selectedTime)
            ),
          })
        : this.setState({ checkedItems: new Map() });
    } else {
      this.setState({ checkedItems: new Map() });
    }
  };

  handleWeekChange = (e, week) => {
    if (e.target.checked) {
      this.setState(prevState => ({
        WEEK: prevState.WEEK.map(obj =>
          obj.id === week.id ? Object.assign(obj, { isChecked: true }) : obj
        ),
      }));
      this.TIME.map(time => {
        const key = `${week.value}-${time}`;
        const value = {
          day: week.value,
          time: time,
          isChecked: e.target.checked,
        };

        this.setState(prevState => ({
          checkedItems: prevState.checkedItems.set(key, value),
        }));
      });
    } else {
      this.setState(prevState => ({
        WEEK: prevState.WEEK.map(obj =>
          obj.id === week.id ? Object.assign(obj, { isChecked: false }) : obj
        ),
      }));
      this.TIME.map(time => {
        const key = `${week.value}-${time}`;
        this.state.checkedItems.delete(key);
      });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const selectedTime = Object.fromEntries(this.state.checkedItems);

    const params = {
      client_id: CLIENT_ID,
      additional_details: JSON.stringify({ selectedTime }),
    };

    Utility.sendRequest(USERS, 3, params, (error, response, body) => {
      if (!body.error) {
        if (this.props.isUpdate && this.props.userData) {
          Utility.sendRequest(
            USER_AUTH,
            1,
            { id: this.props.userData.id },
            (error, response, body) => {
              this.setState({ isLoading: true });
              body = JSON.parse(body);
              if (!body.error) {
                this.props.updateUserData(body);
                this.setState({ isLoading: false }, () => this.props.skip());
              } else {
                toast.error(body.error.message, { containerId: 'public' });
                this.setState({ isLoading: false });
              }
            }
          );
        } else {
          this.setState({ isLoading: false });
          this.props.skip();
        }
      } else {
        toast.error(body.error.message, { containerId: 'public' });
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    return (
      <div className='ahura-onboarding-modal py-4 position-relative'>
        <div onClick={this.props.close} className='modal-close-outer'>
          <CloseIcon fillClass='fill-white' />
        </div>
        <h1 className='font-36 b-500 mb-3'>Schedule a time to study.</h1>
        <p className='display-6 mb-30'>
          Our schedules are packed, schedule a time with yourself when it is the
          best time to focus.
        </p>
        <div className='align-items-center bg-primary justify-content-center position-relative ahura-profile-upload'>
          <img src={require('assets/images/icons/schedule-time.svg')} />
        </div>
        <div className='font-medium display-7 text-center mb-5'>
          Receive notifications <br />
          when it’s time to study.
        </div>
        <div className='font-28 font-bold text-center'>
          Schedule a Day &amp; Time
        </div>
        <Accordion
          className='ahura-onboarding-accordion my-5 px-lg-4'
          defaultActiveKey='0'
        >
          {this.state.WEEK.map((week, i) => (
            <ScheduleTimeAccordion
              key={i}
              handleWeekChange={this.handleWeekChange}
              TIME={this.TIME}
              week={week}
              currentIndex={i}
              openIndex={this.state.openIndex}
              handleChange={this.handleChange}
              checkedItems={this.state.checkedItems}
              setOpenIndex={index => this.setState({ openIndex: index })}
            />
          ))}
        </Accordion>

        <div className='font-28 font-bold text-center'>Selected Times</div>
        <div className='row my-5 pb-3'>
          {Array.from(this.state.checkedItems).map(
            ([key, value]) =>
              value.isChecked === true && (
                <div key={key} className='col-lg-4 mb-4'>
                  <div className='selected-times-outer text-center py-2'>
                    <div className='font-18 mb-2'>{value.day}</div>
                    <span className='font-bold display-6'>{value.time}</span>
                  </div>
                </div>
              )
          )}
        </div>
        <div className='text-center mb-4 pb-3'>
          <button
            onClick={this.handleSubmit}
            disabled={this.state.isLoading}
            className='btn btn-lg btn-ahura-onboarding-main btn-primary radius-15'
          >
            {this.state.isLoading ? (
              <Spinner animation='border' role='status' />
            ) : (
              'Confirm'
            )}
          </button>
        </div>
        {!this.props.isUpdate && (
          <div
            onClick={this.props.skip}
            className='text-center display-7 my-5 cursor-pointer'
          >
            Skip for now
          </div>
        )}
      </div>
    );
  }
}

const ScheduleTimeAccordion = ({
  handleWeekChange,
  week,
  openIndex,
  currentIndex,
  TIME,
  handleChange,
  checkedItems,
  setOpenIndex,
}) => {
  const open = openIndex === currentIndex;
  const handleOpen = () => {
    open ? setOpenIndex(-1) : setOpenIndex(currentIndex);
  };

  return (
    <>
      <div className='flex-nowrap align-items-center'>
        <label className='ahura-checbox mb-0 cursor-pointer'>
          <input
            checked={week.isChecked}
            onChange={e => handleWeekChange(e, week)}
            type='checkbox'
          />
          <div className='ahura-checbox-inner'>
            <img src={require('assets/images/icons/input-check.svg')} />
          </div>
        </label>
        <Accordion.Toggle
          className='accordion-toggle-btn'
          variant='link'
          eventKey={week.id}
          onClick={handleOpen}
        >
          <span className='font-bold font-24 ml-5'>{week.value}</span>
          <div className='ml-auto'>
            <ChevronDownIcon mainClass={`schedule-time-arrow ${open ? 'arrow-rotate' : '' }`} fillClass='fill-white' />
          </div>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={week.id}>
        <div className='ahura-accordian-body px-4 py-3 text-black mt-3 mb-4'>
          <div className='display-6 font-medium mb-4'>Select Time</div>
          {TIME.map((time, index) => (
            <div key={index} className='d-flex align-items-center mb-3'>
              <label className='ahura-checbox mb-0 cursor-pointer'>
                <input
                  value={time}
                  checked={checkedItems.get(`${week.value}-${time}`)}
                  onChange={e => handleChange(e, week)}
                  type='checkbox'
                />
                <div className='ahura-checbox-inner'>
                  <img src={require('assets/images/icons/input-check.svg')} />
                </div>
              </label>
              <span className='font-medium font-18 ml-4'>{time}</span>
            </div>
          ))}
        </div>
      </Accordion.Collapse>
    </>
  );
};

const mapActionToProps = { updateUserData };

const mapStateToProps = state => ({ userData: state.userData });

export default connect(mapStateToProps, mapActionToProps)(ScheduleTime);
