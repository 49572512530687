import React, { useState, useEffect } from 'react';
import SideCallout from "shared/components/templates/side_callout";
import { TickFilledIcon } from 'shared/icons/icons';
import { Utility } from 'utils';
import { Button } from 'react-bootstrap';
import { CLIENT_ID } from 'config/constants/config';
import { USER_BADGES, BADGES, CRM_MODULES, ALL_PARENT_CAMPAIGNS } from 'config/constants/api_endpoints';
import { useSelector } from 'react-redux';

export default function CourseCompleteSuccess(props){
  const [badges, setBadges] = useState([]);
  const [userBadges, setUserBadges] = useState([]);
  const [campaignLength, setCampaignLength] = useState([]);
  const userData = useSelector(state => state.userData);

  useEffect(() => {
    getBadges();
    getUserBadges();
    getAllParentCampaigns();
  }, []);

  const getUserBadges = () => {
    const params = {
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(USER_BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) setUserBadges(body.filter((item) => item.name !== 'Calling'));
    });
  };

  const getAllParentCampaigns = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      campaign_type_id: props.campaignSelectedTab
    };

    Utility.sendRequest(ALL_PARENT_CAMPAIGNS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setCampaignLength(body.data.length);
      }
    });
  };

  const getBadges = () => {
    const params = {
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) setBadges(body);
    });
  };

  const returnBadges = () => {
    const gmBadge = [];
    const emptyBadge = [];

    if(!Utility.isEmpty(userBadges)) {
      emptyBadge.forEach(e => {

        e.have_badge ?
        gmBadge.push(
          <div className="earned-badge mr-3" >
            <img
              src={require('assets/images/profile/badges/gm-badge.svg')}
              alt=""
            />
          </div>
        ) :
        emptyBadge.push(
          <div className="earned-badge mr-3" >
            <img
              src={require('assets/images/profile/badges/empty-badge.svg')}
              alt=""
            />
          </div>
        );
      });
    }

    return (
      <>
        {gmBadge}
        {emptyBadge}
      </>
    );
  };

  return(
    <SideCallout
      closeCallOut={props.closeCallOut}
      calloutHeadingSvg={<TickFilledIcon fillClass="fill-primary" />}
      calloutHeading='Confirmation'>
      <div className="align-items-center justify-content-between group-section-outer my-4 pb-3">
          <div className="text-center mx-auto" >
            <div className="font-21-bold mb-4 pb-2" >Well Done!</div>
            <div className="rounded-circle align-items-center justify-content-center  badge-display mb-2 mx-auto" >
              <img src={require('assets/images/profile/badges/gm-badge.svg')} alt="" />
            </div>
            <div className="display-6 font-medium mb-4 pb-3" >{Utility.capitalize('course')}</div>
            <div className="badges-earned-outer d-flex justify-content-center mb-4 pb-3" >
            { returnBadges() }
            </div>
            <div className="display-7 font-bold mb-4 pb-2" >
              You earned a badge for <br/> completing a {Utility.capitalize('course')}.</div>
          </div>
      </div>
      <div className="text-center" >
            <Button onClick={props.goToCourse}>
              GO TO COURSE
            </Button>
            <div className="mt-4 pt-3 ml-2 display-7 font-medium">
            <span
              className="cursor-pointer font-medium"
              onClick={props.goToHome}>
                GO TO HOME
            </span>
          </div>
      </div>
    </SideCallout>
  );
}
