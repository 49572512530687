import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Dropdown, Button } from "react-bootstrap";
import {
  CircleOutlinedAddIcon,
  DragIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
} from "shared/icons/icons";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import { GIL_CLIENT_ID } from '../../../config/constants';
import { EditIcon } from "shared/icons/edit_icon";
import { InputField } from "shared/components/form_elements";
import { Utility } from "utils";
import {
  CAMPAIGNS,
  ALL_PARENT_CAMPAIGNS,
  CAMPAIGNS_BY_ID,
  SWAP_IDS,
} from "config/constants/api_endpoints";
import { toast } from "react-toastify";
import { LessonForm } from "./lesson_form";
import { showLoader, updateLessons } from "shared/actions";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const validateFields = {
  lesson: ["embedded_image_link", "image_url", "description", "heading"],
};

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campId: this.props.campId,
      createLesson: false,
      openSection: false,
      sectionList: [],
      sectionName: "",
      sectionData: this.getSectionData(),
      formName: "lesson",
      formStates: this.initialState(),
      newCampaign: this.getFormData(),
    };
  }

  initialState = () => {
    return {
      selectedCampaignId: "",
      showDropdown: false,
      isEdit: false,
      isCreate: true,
      isChapToggle: false,
      isCreateOrEdit: false,
      search: null,
      imageFile: "",
      imagePreviewUrl: "",
      videoFile: "",
      videoPreviewUrl: "",
    };
  };

  getFormData = () => {
    const userData = { ...this.props.userData };

    return {
      heading: "",
      sub_heading: "",
      description: "",
      location: "",
      video_url: "",
      embedded_video_link: "",
      image_url: "",
      embedded_image_link: "",
      imageSignedURL: "",
      videoSignedURL: "",
      parent_image_url: "",
      parent_heading: "",
      category_id: null,
      parent_id: null,
      non_profits_id: null,
      campaign_type_id: CAMPAIGN_TYPES_IDS.lesson,
      author_name: userData.user_name,
      author_email: userData.email,
      author_id: userData.user_id,
      created_by: userData.user_id,
      publisher_network_id: CLIENT_ID,
      updated_by: userData.user_id,
      user_client_id: userData.id,
      schedule_start: new Date(),
      client_id: CLIENT_ID,
      non_profits: [],
      organizers: [],
      groups: [],
      is_public: true,
    };
  };

  componentDidMount() {
    this.getAllParentCampaigns();
  }

  getSectionData = () => {
    const userData = { ...this.props.userData };

    return {
      heading: "",
      sub_heading: "",
      description: "",
      location: "",
      video_url: "",
      embedded_video_link: "",
      image_url: "",
      embedded_image_link: "",
      imageSignedURL: "",
      videoSignedURL: "",
      parent_image_url: "",
      parent_heading: "",
      category_id: null,
      parent_id: this.props.campId,
      non_profits_id: null,
      campaign_type_id: CAMPAIGN_TYPES_IDS.section,
      author_name: userData.user_name,
      author_email: userData.email,
      author_id: userData.user_id,
      created_by: userData.user_id,
      publisher_network_id: CLIENT_ID,
      updated_by: userData.user_id,
      user_client_id: userData.id,
      schedule_start: new Date(),
      client_id: CLIENT_ID,
      non_profits: [],
      organizers: [],
      groups: [],
      is_public: true,
    };
  };

  openLesson = (item) => {
    this.setState(
      {
        newCampaign: this.getFormData(),
        formStates: this.initialState(),
      },
      () => {
        const newCampaign = { ...this.state.newCampaign };
        newCampaign.parent_id = item.id;

        this.setState({
          createLesson: !this.state.createLesson,
          newCampaign,
        });
      }
    );
  };

  openSection = () => {
    this.setState({
      openSection: !this.state.openSection,
    });
  };

  handleSectionChange = (ev) => {
    const sectionData = { ...this.state.sectionData };
    sectionData["heading"] = ev.target.value;
    this.setState({ sectionName: ev.target.value, sectionData: sectionData });
  };

  saveSection = () => {
    this.createSection();
  };

  createSection = () => {
    const { sectionName } = this.state;
    if (!sectionName) {
      toast.error("Section Name can't be empty.", { containerId: "private" });
    } else {
      this.props.showLoader(true);
      Utility.sendRequest(
        CAMPAIGNS,
        2,
        this.state.sectionData,
        async (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
            this.props.showLoader(false);
          } else {
            this.setState(
              {
                openSection: !this.state.openSection,
                sectionName: "",
              },
              () => {
                this.getAllParentCampaigns();
                this.props.showLoader(false);
              }
            );
          }
        }
      );
    }
  };

  updateSection = (item, params) => {
    if (params.heading) {
      this.props.showLoader(true);
      Utility.sendRequest(
        CAMPAIGNS + "/" + item.id,
        3,
        params,
        async (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
            this.props.showLoader(false);
          } else {
            this.editSection(item);
            this.props.showLoader(false);
          }
        }
      );
    } else {
      toast.error("Section Name can't be empty.", { containerId: "private" });
      this.props.showLoader(false);
    }
  };

  getAllParentCampaigns = () => {
    let params = {};
    if (
      CLIENT_ID === "cb19c961-fc50-4613-87d9-40488220a1d9" ||
      CLIENT_ID === GIL_CLIENT_ID
    ) {
      params = {
        module_id: CRM_MODULES.CAMPAIGNS,
        client_id: CLIENT_ID,
        // user_client_id: this.props.userData.id,
        parent_id: this.props.campId,
        campaign_type_id: CAMPAIGN_TYPES_IDS.section,
        sub_campgain: "lessons",
      };
    } else {
      params = {
        module_id: CRM_MODULES.CAMPAIGNS,
        client_id: CLIENT_ID,
        user_client_id: this.props.userData.id,
        parent_id: this.props.campId,
        campaign_type_id: CAMPAIGN_TYPES_IDS.section,
        sub_campgain: "lessons",
      };
    }

    Utility.sendRequest(ALL_PARENT_CAMPAIGNS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const lessons = [];
        body.data.map((item) => {
          item.lessons &&
            item.lessons.length > 0 &&
            item.lessons.map((list) => {
              lessons.push(list);
            });
        });
        this.props.updateLessons(lessons);
        const data = body.data.filter((section) =>
          section.lessons !== null
            ? section.lessons.sort((a, b) => a.seq_id - b.seq_id)
            : section
        );

        this.setState({ sectionList: data });
      }
    });
  };

  editSection = (section) => {
    const updatedList = this.state.sectionList.map((item) => {
      if (item.id == section.id) {
        return { ...item, isEdit: !item.isEdit };
      }
      return item;
    });

    this.setState({ sectionList: updatedList });
  };

  editSectionName = (section, ev) => {
    const updatedList = this.state.sectionList.map((item) => {
      if (item.id === section.id) {
        return { ...item, heading: ev.target.value };
      }
      return item;
    });

    this.setState({ sectionList: updatedList });
  };

  updateSectionCamp = (section) => {
    if (section.heading) {
      const params = { heading: section.heading };
      this.updateSection(section, params);
    }
  };

  handleInputChange = (ev) => {
    let formData = { ...this.state.newCampaign };
    formData = Utility.validateAndReturnFormData(
      this.refs[this.state.formName],
      formData,
      ev,
      validateFields[this.state.formName],
      this.state.formName
    );

    if (formData.heading != "") {
      const error = Utility.getElementById("lesson_headingError");
      if (error) error.innerText = "";
    }

    if (formData.embedded_image_link != "") {
      const error = Utility.getElementById("lesson_image_urlError");
      if (error) error.innerText = "";
    }

    this.setState({ newCampaign: formData });
  };

  changeDescription = (data) => {
    const dataHash = this.state.newCampaign;
    dataHash["description"] = data;

    this.setState({ newCampaign: dataHash });
  };

  handleFileChange = (inputId, previewURL, file) => {
    const formStates = { ...this.state.formStates };

    if (inputId === "image_url") {
      formStates.imagePreviewUrl = previewURL;
      formStates.imageFile = file;
    } else if (inputId === "video_url") {
      formStates.videoPreviewUrl = previewURL;
      formStates.videoFile = file;
    }

    this.setState({ formStates }, () => {
      if (Utility.isEmpty(this.state.newCampaign.embedded_image_link))
        Utility.isFilePathEmpty(
          "image_url",
          this.state.formStates.imagePreviewUrl,
          this.state.formName
        );
    });
  };

  removeUploadedFile = (inputId) => {
    const formStates = { ...this.state.formStates };
    const newCampaign = { ...this.state.newCampaign };

    if (inputId === "image_url") {
      formStates.imagePreviewUrl = "";
      formStates.imageFile = "";
      newCampaign.image_url = "";
    } else {
      formStates.videoPreviewUrl = "";
      formStates.videoFile = "";
      newCampaign.video_url = "";
    }
    this.setState({ formStates }, () => {
      this.setState({ newCampaign });
    });
  };

  handleFormErrors = () => {
    let isFilePathEmpty;
    const newCampaign = { ...this.state.newCampaign };
    const formStates = { ...this.state.formStates };
    const lesson_heading = Utility.getElementById("lesson_heading");

    if (!lesson_heading.value) {
      const error = Utility.getElementById("lesson_headingError");
      if (error) error.innerText = "Heading is a required field";
    }

    // if (!formStates.imagePreviewUrl || !newCampaign.embedded_image_link) {
    //   const error = Utility.getElementById('lesson_image_urlError');
    //   if (error)
    //     error.innerText =
    //       'Either select an image or fill up the embedded image link below';
    // }

    // return lesson_heading.value &&
    //   (formStates.imagePreviewUrl || newCampaign.embedded_image_link)
    //   ? true
    //   : false;

    return lesson_heading.value ? true : false;
  };

  createCampaign = () => {
    this.setState({ showLoader: true });
    this.props.showLoader(true);

    Utility.sendRequest(
      CAMPAIGNS,
      2,
      this.state.newCampaign,
      async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          const campData = body;
          const afterSuccessCampaign = body;
          afterSuccessCampaign.createdOrUpdated = "created";
          afterSuccessCampaign.image_url =
            this.state.formStates.imagePreviewUrl;
          afterSuccessCampaign.author_image_url =
            this.props.userData.profile_image_url;
          if (
            !Utility.isEmpty(this.state.formStates.imagePreviewUrl) ||
            !Utility.isEmpty(this.state.formStates.videoPreviewUrl)
          ) {
            await this.uploadFiles(
              campData.id,
              campData.imageSignedURL,
              campData.videoSignedURL
            );
          } else {
            this.getAllParentCampaigns();
            this.openLesson(body);
          }
          this.setState(
            {
              newCampaign: this.getFormData(),
              formStates: this.initialState(),
            },
            () => {
              this.setState({ afterSuccessCampaign }, () => {
                this.setState({ showSuccess: true });
              });
            }
          );
        }
        this.setState({ showLoader: false });
        this.props.showLoader(false);
      }
    );
  };

  updateCampaign = () => {
    this.setState({ showLoader: true });
    this.props.showLoader(true);

    Utility.sendRequest(
      CAMPAIGNS + "/" + this.state.formStates.selectedCampaignId,
      3,
      this.state.newCampaign,
      async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (
            !Utility.isEmpty(this.state.newCampaign.imageSignedURL) &&
            !Utility.isEmpty(this.state.newCampaign.videoSignedURL)
          )
            await this.uploadFiles(
              this.state.formStates.selectedCampaignId,
              this.state.newCampaign.imageSignedURL,
              this.state.newCampaign.videoSignedURL
            );

          const afterSuccessCampaign = body;
          afterSuccessCampaign.createdOrUpdated = " updated ";
          afterSuccessCampaign.image_url =
            this.state.formStates.imagePreviewUrl;
          afterSuccessCampaign.author_image_url =
            this.props.userData.profile_image_url;
          this.setState(
            {
              newCampaign: this.getFormData(),
              formStates: this.initialState(),
            },
            () => {
              this.setState({ afterSuccessCampaign }, () => {
                this.setState({ showSuccess: true });
              });
            }
          );
        }
        this.setState({ showLoader: false });
        this.props.showLoader(false);
      }
    );
  };

  uploadFiles = async (id, imageSignedURL, videoSignedURL) => {
    let imageURL = this.state.formStates.imagePreviewUrl;
    let videoURL = this.state.formStates.videoPreviewUrl;

    if (!Utility.isEmpty(this.state.formStates.imageFile.size)) {
      imageURL = await Utility.uploadFile(
        this.state.formStates.imageFile,
        imageSignedURL
      );
    }

    if (!Utility.isEmpty(this.state.formStates.videoFile.size)) {
      videoURL = await Utility.uploadFile(
        this.state.formStates.videoFile,
        videoSignedURL
      );
    }

    await this.updateCampaignMediaUrl(id, imageURL, videoURL);
  };

  updateCampaignMediaUrl = (campId, imageURL, videoURL) => {
    const params = {
      image_url: imageURL,
      video_url: videoURL,
    };

    Utility.sendRequest(
      CAMPAIGNS + "/" + campId,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.getAllParentCampaigns();
          this.openLesson(body);
        }
      }
    );
  };

  getCampaignsById = (campDetails) => {
    const formStates = { ...this.state.formStates };

    if (campDetails) {
      const params = {
        campaign_id: campDetails.id,
        client_id: CLIENT_ID,
        user_client_id: this.props.userData.id,
      };

      Utility.sendRequest(CAMPAIGNS_BY_ID, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (body.data) {
            this.setState(
              {
                newCampaign: {
                  ...body.data,
                  start_at: moment.utc(body.start_at).local(),
                  end_at: moment.utc(body.end_at).local(),
                },
              },
              () => {
                formStates.imagePreviewUrl = this.state.newCampaign.image_url;
                formStates.videoPreviewUrl = this.state.newCampaign.video_url;
                formStates.isEdit = true;
                formStates.isCreate = false;
                formStates.isCreateOrEdit = true;
                formStates.showDropdown = false;
                formStates.selectedCampaignId = body.data.id;
                this.setState({
                  formStates,
                });
              }
            );
          }
        }
      });
    }
  };

  submit = (ev) => {
    ev.preventDefault();
    const isFormValid = this.handleFormErrors();
    if (!isFormValid) {
      var elmnt = document.getElementById("form");
      if (elmnt) elmnt.scrollIntoView();
      return;
    }

    if (this.state.formStates.isCreate) this.createCampaign();
    else this.updateCampaign();
  };

  editLesson = (campDetails) => {
    this.setState(
      {
        createLesson: true,
      },
      () => {
        this.getCampaignsById(campDetails);
      }
    );
  };

  removeLesson = (lesson) => {
    if (window.confirm("Connected assignments will also delete.")) {
      Utility.sendRequest(
        CAMPAIGNS + "/" + lesson.id,
        4,
        {},
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            this.getAllParentCampaigns();
          }
        }
      );
    } else {
      return;
    }
  };

  removeSection = (section, e) => {
    e.preventDefault();
    if (window.confirm("Connected lessons and assignments will also delete.")) {
      Utility.sendRequest(
        CAMPAIGNS + "/" + section.id,
        4,
        {},
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            this.getAllParentCampaigns();
          }
        }
      );
    } else {
      return;
    }
  };

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: 2,
    width: 100,
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "whitesmoke" : "white",
    ...draggableStyle,
  });

  onDragEndLessons = (result, sectionIndex) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const { sectionList } = this.state;
    const actualSectionList = sectionList;

    const params = {
      sourceId:
        actualSectionList[sectionIndex].lessons[result.source.index].seq_id,
      destinationId:
        actualSectionList[sectionIndex].lessons[result.destination.index]
          .seq_id,
    };

    const lessons = Array.from(sectionList[sectionIndex].lessons);
    lessons[source.index].seq_id = params.destinationId;
    lessons[destination.index].seq_id = params.sourceId;
    lessons.sort((a, b) => {
      return a.seq_id - b.seq_id;
    });
    sectionList[sectionIndex].lessons = lessons;
    this.setState({ sectionList: sectionList });

    Utility.sendRequest(SWAP_IDS, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      }
    });
  };

  onDragEndSections = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const { sectionList } = this.state;
    const actualSectionList = sectionList;

    const params = {
      sourceId: actualSectionList[source.index].seq_id,
      destinationId: actualSectionList[destination.index].seq_id,
    };

    const sections = Array.from(sectionList);
    sections[source.index].seq_id = params.destinationId;
    sections[destination.index].seq_id = params.sourceId;
    sections.sort((a, b) => {
      return a.seq_id - b.seq_id;
    });
    this.setState({ sectionList: sections });

    Utility.sendRequest(SWAP_IDS, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      }
    });
  };

  render() {
    const { createLesson, sectionList, openSection } = this.state;
    return (
      <>
        {createLesson && (
          <div>
            <LessonForm
              {...this.state}
              ref={"lesson"}
              key={"lesson"}
              setIsFormOpen={this.props.setIsFormOpen}
              handleFileChange={this.handleFileChange}
              removeUploadedFile={this.removeUploadedFile}
              handleChange={this.handleInputChange}
              submit={this.submit}
              changeDescription={this.changeDescription}
              closeForm={this.openLesson}
            />
          </div>
        )}
        {!createLesson && (
          <div className="py-5 w-100">
            <div className="d-flex flex-nowrap align-items-center">
              <span
                className="font-14 font-weight-bold space-nowrap mr-4 pr-3 cursor-pointer"
                onClick={this.openSection}
              >
                Click Here to Add a Section
              </span>
              <div className="border w-100"></div>
            </div>
            {openSection && (
              <div className="d-flex flex-nowrap align-items-center add-new-section position-relative bg-white mb-5">
                <InputField
                  required={true}
                  type="text"
                  name="section_name"
                  value={this.state.sectionName}
                  handleChange={this.handleSectionChange}
                  placeholder="Section Name"
                />
                <Button
                  type="submit"
                  variant="primary"
                  onClick={this.saveSection}
                  className={`btn-sm ml-1 font-weight-bold btn-section-save mx-3`}
                >
                  Save
                </Button>
                <Button
                  type="submit"
                  variant="link"
                  onClick={this.openSection}
                  className={`font-14 ml-1 font-weight-bold btn-section-save`}
                >
                  Close
                </Button>
              </div>
            )}
            <DragDropContext onDragEnd={this.onDragEndSections}>
              <Droppable droppableId="section">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {sectionList.map((item, i) => (
                      <Draggable key={item.id} draggableId={item.id} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="registants-table mt-4"
                          >
                            <div className="table-responsive">
                              <table className="table table-sm table-hover section-table-draggable">
                                <thead>
                                  <tr className="font-18">
                                    <th className="border-top-0 border-bottom-0">
                                      <div className="d-flex flex-nowrap align-items-center course-edit-sec">
                                        <DragIcon mainClass="mr-2" />
                                        {item.isEdit ? (
                                          <>
                                            <InputField
                                              required={true}
                                              type="text"
                                              name="section_name"
                                              value={item.heading}
                                              placeholder="Section Name"
                                              handleChange={(e) =>
                                                this.editSectionName(item, e)
                                              }
                                            />
                                            <Button
                                              type="submit"
                                              variant="primary"
                                              onClick={(e) =>
                                                this.updateSectionCamp(item, e)
                                              }
                                              className={`btn btn-primary btn-sm ml-1 font-weight-bold`}
                                            >
                                              Update
                                            </Button>
                                            <Button
                                              type="submit"
                                              variant="primary"
                                              onClick={(e) =>
                                                this.removeSection(item, e)
                                              }
                                              className={`btn btn-danger btn-sm ml-1 font-weight-bold`}
                                            >
                                              Delete
                                            </Button>
                                          </>
                                        ) : (
                                          item.heading
                                        )}
                                      </div>
                                    </th>
                                    <th className="border-top-0 border-bottom-0">
                                      <div className="d-flex justify-content-between">
                                        Action
                                        <div
                                          className="cursor-pointer"
                                          onClick={() => this.editSection(item)}
                                        >
                                          <EditIcon />
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <DragDropContext
                                  onDragEnd={(result) =>
                                    this.onDragEndLessons(result, i)
                                  }
                                >
                                  <Droppable droppableId={`lesson-${i}`}>
                                    {(provided, snapshot) => (
                                      <tbody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={this.getListStyle(
                                          snapshot.isDraggingOver
                                        )}
                                      >
                                        {item.lessons &&
                                          item.lessons.map((details, index) => (
                                            <Draggable
                                              key={details.id}
                                              draggableId={details.id}
                                              index={index}
                                            >
                                              {(provided, snapshot) => (
                                                <tr
                                                  {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  style={this.getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                      .style
                                                  )}
                                                  key={item.id}
                                                >
                                                  <td className="py-3 pl-4">
                                                    <div className="align-items-center">
                                                      <DragIcon mainClass="mr-2 ml-1" />
                                                      <div className="lesson-img mr-3">
                                                        {details.image_url !==
                                                          "" && (
                                                          <img
                                                            src={
                                                              details.image_url
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                      <span className="font-weight-bold font-18 text-dark">
                                                        {details.heading}
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td className="action-td-chat pt-4">
                                                    <div className="d-flex flex-nowrap align-items-center">
                                                      <Dropdown>
                                                        <Dropdown.Toggle
                                                          variant="table-dropdown"
                                                          id="dropdown-basic"
                                                        >
                                                          <span> ... </span>
                                                          <ChevronDownIcon />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              this.editLesson(
                                                                details
                                                              )
                                                            }
                                                          >
                                                            Edit
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              this.removeLesson(
                                                                details
                                                              )
                                                            }
                                                          >
                                                            Delete
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                      <div className="bg-primary rounded-circle lesson-img-arrow flex-center mr-3 cursor-pointer">
                                                        <ChevronLeftIcon fillClass="fill-white" />
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                            </Draggable>
                                          ))}
                                        {provided.placeholder}
                                      </tbody>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </table>
                              <div
                                className="align-items-center font-weight-bold font-18 mb-4 pl-5 cursor-pointer d-inline-flex"
                                onClick={() => this.openLesson(item)}
                              >
                                <CircleOutlinedAddIcon mainClass="mr-3" /> Add
                                Lesson
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </>
    );
  }
}

const mapActionToProps = { showLoader, updateLessons };

const mapStateToProps = (state) => ({
  userData: state.userData,
  publicUserData: state.publicUserData,
  neighborhoodState: state.neighborhoodState,
  isLoading: state.isLoading,
  isChatFormOpen: state.isChatFormOpen,
  seeAll: state.seeAll,
});

export default connect(mapStateToProps, mapActionToProps)(Section);
