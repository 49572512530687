import React from 'react';

export const SplashScreen = () => {
	return (
		<>
		<div className="splash-screen bg-primary d-flex justify-content-center align-item-center" >
			<img src={require("assets/images/logos/main-logo-white.png")} alt="splash screen logo" />
		</div>
		</>
	);
};
