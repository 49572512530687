import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import {  CircleOutlinedAddIcon, CloseIcon } from 'shared/icons/icons';
import { CircleFilledAddLargeIcon } from 'shared/icons/icons';
import { InputField } from 'shared/components/form_elements';
import UploadFile from 'shared/components/file_upload';
import RichTextEditor from 'shared/components/rich_text_editor';

export class LessonForm extends Component {
  constructor(props) {
    super(props);
  }

  paintImageInput = () => (
    <Form.Group className="mb-4 pb-1" >
      {/* <Form.Label className="font-bold display-8 mb-3">Add an Image <span className="text-red">*</span> </Form.Label> */}
      <Form.Label className="font-bold display-8 mb-3">Add an Image </Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <label className="post-uplodad-label mb-0 cursor-pointer">
          <UploadFile
            name='image_url'
            inputId="image_url"
            removeText="Remove image"
            categoryName="publisher_campaign_image"
            acceptTypes="image/x-png,image/gif,image/jpeg"
            uploadedFilePreviewUrl={
              this.props.formStates.isEdit &&
              this.props.newCampaign.image_url ||
              this.props.formStates.imagePreviewUrl}
            handleFileChange={this.props.handleFileChange}
            removeUploadedFile={this.props.removeUploadedFile}
          />
          <div className="error errorIcon mb-3" id={'lesson_image_urlError'} />
          { !this.props.formStates.imagePreviewUrl &&
            <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
          }
        </label>
      </div>
      <div className="my-2 ml-1 display-8 font-medium" >Or</div>
      <InputField
        required={true}
        type="text"
        name="embedded_image_link"
        value={this.props.newCampaign.embedded_image_link}
        handleChange={this.props.handleChange}
        placeholder="Embed Image URL..."
      />
    </Form.Group>
  );

  paintVideoInput = () => (
    <Form.Group className="mb-4 pb-1" >
      <Form.Label className="font-bold display-8 mb-3" >
        Add a Video
      </Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <label className="post-uplodad-label mb-0 cursor-pointer">
          <UploadFile
            name='video_url'
            inputId="video_url"
            removeText="Remove video"
            acceptTypes="video/mp4"
            uploadedFilePreviewUrl={this.props.formStates.videoPreviewUrl}
            handleFileChange={this.props.handleFileChange}
            removeUploadedFile={this.props.removeUploadedFile}
          />
          <div className="error errorIcon" id={'video_urlError'} />
          {
            !this.props.formStates.videoPreviewUrl &&
            <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
          }
        </label>
      </div>
      <div className="my-2 ml-1 display-8 font-medium" >Or</div>
      <InputField
        type="text"
        name='embedded_video_link'
        value={this.props.newCampaign.embedded_video_link}
        handleChange={this.props.handleChange}
        placeholder="Embed YouTube URL..."
      />
    </Form.Group>
  );

  render() {
    const props = this.props;
    const headingTxt = props.formStates.isEdit ? 'Edit' : 'Create';
    return(
      <div className="">
        <div className="d-flex justify-content-between border-bottom align-items-center">
           <div className="align-items-center font-weight-bold font-18 my-4">
              <CircleOutlinedAddIcon mainClass="mr-3" /> {headingTxt} Lesson
            </div>
            <div className="cursor-pointer" onClick={props.closeForm}><CloseIcon /></div>
        </div>
         <Form className="shar-form search-form search-form-pwa sidebar-form py-4" id="lesson_form" >
           <Form.Group className="mb-4 pb-1">
              <Form.Label className="font-bold display-8 mb-3">
                Add Lesson Title
                <span className="text-red">*</span>
              </Form.Label>
              <InputField
                required={true}
                name='heading'
                ref='heading'
                type="text"
                id="lesson_heading"
                placeholder="Create a new Lesson"
                value={ props.newCampaign.heading }
                handleChange={props.handleChange}
              />
              <div className="error errorIcon mb-3" id={'lesson_headingError'} />
            </Form.Group>

            {this.paintImageInput()}

            {this.paintVideoInput()}

            <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">Enter Description
                  <span className="font-light-italic" >(Details)</span>
                </Form.Label>
                <RichTextEditor
                  id="description"
                  name="description"
                  placeholder="Add Text..."
                  data={props.newCampaign.description}
                  changeDescription={props.changeDescription}
                />
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              onClick = {props.submit}
              className={`btn btn-primary btn-primary-pwa btn-post-size font-18 my-4 ml-1 font-weight-bold`} >
              Save
            </Button>
            <div className="">
              <div className="display-8 font-medium-italic cursor-pointer btn btn-post-size">
                Cancel
              </div>
            </div>
         </Form>
      </div>
    );
  }
}
