import React from 'react';
import { connect } from 'react-redux';
import { Utility } from 'utils';

 const AllowPermission = (props) => {
    const permissions =  props.userData.permissions;

   if (props.userData.is_super_owner) {
     return props.children;
   } else {

       if (!Utility.isEmpty(permissions)) {
         const showChildren = permissions.includes(props.permissions);
         return showChildren ? props.children : null;
        }
    }
    return <></>;
};

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, null)(AllowPermission);
