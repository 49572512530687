import React from "react";
import { Button } from 'react-bootstrap';
import { MdClose } from "react-icons/md";

let Overlay = (props) =>  {
  return (
    <div className="edit-profile direct-message scroll-y h-100 p-4">
      <div className="p-sm-3">
        <div className="d-flex align-items-center">
          <div className="round-circle d-flex-align-center"><span>
            <img src={require("assets/images/" + props.iconName)} alt="msg-icon" /></span></div>
          <span className="font-30-bold pl-2">{props.title}</span>
          <Button variant="link" onClick={props.goBack} className="close-btn"><MdClose/></Button>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Overlay = React.memo(Overlay);
