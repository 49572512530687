import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { DROPDOWN_ACTIONS, MENU } from 'config/constants';
import { Utility } from 'utils';
import { showLoader } from 'shared/actions';
import MarketPlace from './components/market_place';
import Menu from 'shared/components/menu';

class ToolContainer extends Component {
  constructor() {
    super();
    this.state = {
      menus: MENU.tools,
      selectedMenuItem: {}
    };
  }

  componentDidUpdate = () => {
    Utility.showComponent(true, 'menu-list');
  }

  handleMenuItemClick = (selectedMenuItem) => {
    this.setState({ selectedMenuItem });
    Utility.showComponent(true, 'market-place');
  }

  render() {
    return (
      <Row className="no-gutters h-100">
        {
          !Utility.isEmpty(this.props.userData) &&
          !Utility.isEmpty(this.props.selectedModule) &&
          <Menu
            selectedModule={this.props.selectedModule}
            clientName={this.props.userData.Client.name}
            userName={`${this.props.userData.first_name} ${this.props.userData.last_name}`}
            dropdownItems={DROPDOWN_ACTIONS.networks}
            handleMenuItemClick={this.handleMenuItemClick}
            menus={this.state.menus}
            isButton={true}
            showCount={true}
            showZeroInCount={true}
            selectedMenuItemId={this.state.selectedMenuItem.id}
          />
        }
        {
          this.state.selectedMenuItem.id === 1 &&
          <MarketPlace />
        }
      </Row>
    );
  }
}

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedModule: state.selectedModule
});

export default connect(mapStateToProps, mapActionToProps)(ToolContainer);
