import React from 'react';
import ReactDOM from 'react-dom';
import SMRoutes from './config/routes';
import { store } from './store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './scss/main.scss';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CLIENTS_DOMAIN } from 'config/constants/api_endpoints';
import { Utility } from 'utils';
import cookie from 'react-cookies';
import { CLIENT_NAME, CLIENT_ID } from 'config/constants/config';
import { AHURA_CLIENT_ID, GIL_CLIENT_ID } from './config/constants';

const createHistory = createBrowserHistory();
const url = window.location.href;
const params = {};
const domain_name = url.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
const arr = domain_name.split('.');
let title = CLIENT_NAME;
let favIcon = '/favicon/favicon.png';

if (arr.length === 3) params.crm_sub_domain = arr.splice(0, 1);

// if (!Utility.isEmpty(params)) {
//   Utility.sendRequest(CLIENTS_DOMAIN, 1, params, (err, res, body) => {
//     body = JSON.parse(body);
//
//     if (body.error) {
//       toast.error(body.error.message, { containerId: 'public' });
//     } else {
//       if (!Utility.isEmpty(body)) {
//         title = body.crm_sub_domain;
//         favIcon = body.crm_logo_url;
//         Utility.updateFavIconAndTitle(title, favIcon);
//         cookie.save('subDomain', body, { path: '/' });
//
//         ReactDOM.render(
//           <Provider store={store} >
//             <Router>
//               <Route component={SMRoutes} history={createHistory}/>
//             </Router>
//           </Provider>,
//           document.getElementById('root'));
//       }
//     }
//   });
// }
// else {
  cookie.save('subDomain', {}, { path: '/' });

  Utility.updateFavIconAndTitle(title, favIcon);

  ReactDOM.render(
    <Provider store={store} >
      <Router>
        <Route component={SMRoutes} history={createHistory}/>
      </Router>
    </Provider>,
    document.getElementById('root'));
// }

// Register service worker for #Ahura because of push notification received from chrome extension
(CLIENT_ID === GIL_CLIENT_ID || CLIENT_ID === AHURA_CLIENT_ID) && serviceWorker.register();
