import React, { useState } from 'react';
import { TickFilledIcon, CircleOutlinedAddIcon, CloseIconSharp, ChevronDownIcon, CircleFilledAddLargeIcon, DragIcon, ChevronLeftIcon, } from 'shared/icons/icons';
import { InputField, Textarea, CheckboxNew } from 'shared/components/form_elements';
import UploadFile from 'shared/components/file_upload';
import { Form, Dropdown } from 'react-bootstrap';
import Add_Sub_Product from './add_sub_products';
import Edit_Sub_Product from './edit_sub_product';
import EditAttributeForm from './edit_attribute_form';
import { PRODUCTS, SUBPRODUCTS, SWAP_SEQUENCES, API_PATH } from 'config/constants/api_endpoints';
import { showLoader, updateSelectedMenuItem } from 'shared/actions';
import * as Utility from 'utils/utility';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { SketchPicker } from 'react-color';
import { MAX_DOCUMENT_SIZE } from 'config/constants';
import { CloseIcon } from "shared/icons/icons";
import RichTextEditor from 'shared/components/rich_text_editor';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import { CLIENT_ID } from 'config/constants/config';

const validateFields = ['category_id', 'name'];
const formName = 'add_primary_product';

class AddOrEditProduct extends React.Component {
  constructor(props) {

    super(props);
    const state = this.getState(props);

    state.productCategories = [];
    state.imageURL = [];
    state.imageFile = '';
    state.documentFile = '';
    state.generateUrl = '';
    this.state = state;
    state.attributeValues = [];
    state.allTags = [];
    state.allColors = [];
    state.allImages = [];
    state.newTags = [];
    state.renderView = false;
    state.editSubProductView = false;
    state.showEditAttributeform = false;
    state.category_name = "Product Category";
    state.product_type_name = "Product type";
    state.selectedProductTypeId = "";
    state.subProductActionName = "Select Action"
    state.selectedSubProductItem = {}
    state.selectedAttributeItem = {}
    state.addSubProductList = []
    state.displayActionName = "------"
    state.showColorSelector = false
    state.documentName = "";
  }

  componentDidMount = async () => {
    this.setAllAttributesValues();
    if (!Utility.isEmpty(this.props.selectedProductId)) {
      this.getProductDetails();
      this.getSubProductDetails();
    }
  }

  getState = (props) => {
    let tags = '';

    if (!Utility.isEmpty(props.product_tags)) {
      const tags = props.product_tags.map((item) => { return item.tag_name; });
      this.setState({ allTags: tags })
    }
    if (!Utility.isEmpty(props.additional_details)) {
      let allAttributes = JSON.parse(props.additional_details)
      if (allAttributes !== null) {
        allAttributes.sort(function (a, b) {
          return a.sequenceNo - b.sequenceNo;
        })
        const attributes = allAttributes.map(item => { return item })
        this.setState({ attributeValues: attributes })
      }
    }

    if (!Utility.isEmpty(props.all_images)) {
      let allImages = JSON.parse(props.all_images)
      if (allImages !== null) {
        allImages.sort(function (a, b) {
          return a.sequenceNo - b.sequenceNo
        })
        const images = allImages.map(item => { return item })
        this.setState({ allImages: images });
      }
    }
    if (!Utility.isEmpty(props.document_details)) {
      let doc = JSON.parse(props.document_details);
      this.setState({ documentName: doc.document_name })
    }

    if (!Utility.isEmpty(props.all_colors)) {
      let allcolor = JSON.parse(props.all_colors);
      const colors = allcolor.map((item) => { return item });
      this.setState({ allColors: colors })
    }
    return {
      formData: {
        name: props.name || (props.selectedSubProduct ? props.selectedSubProduct.name : "") || "",
        image_urls: props.image_urls || '',
        description: props.description || (props.selectedSubProduct ? props.selectedSubProduct.description : "") || '',
        plain_description: '',
        summary: props.summary || '',
        price: props.price || (props.selectedSubProduct ? props.selectedSubProduct.price : "") || 0,
        rank: props.rank || null,
        tags: props.tags || tags,
        shop_id: props.shop_id || 0,
        category_id: "",
        product_type_id: "",
        category_client_id: props.creator_client_id || '',
        updated_by: props.updated_by || null,
        created_by: props.created_by || null,
        is_approved: props.is_approved || true,
        is_active: this.props.is_active || true,
        parent_id: this.props.parentProductId || null,
        parent_product_category_id: props.parentProductCategoryId || null,
        sequence_no: null,
        attributeName: '',
        attributeDescription: '',
        is_checked: false,
      },
      imagePreviewUrl: props.image_urls || '',
      imageSignedURL: props.imageSignedURL || '',
      subProductListing: [],

      document_url: "",
      error: {
        name: "",
        description: "",
        category_id: "",
        product_type_id: "",
      }
    };
  }

  setAllAttributesValues = () => {
    if (this.props.selectedSubProduct && !Utility.isEmpty(this.props.selectedSubProduct.document_details)) {
      let doc = JSON.parse(this.props.selectedSubProduct.document_details);
      this.setState({ documentName: doc.document_name })
    }

    if (this.props.selectedSubProduct && !Utility.isEmpty(this.props.selectedSubProduct.tags)) {
      const tag = this.props.selectedSubProduct.tags.map((item) => { return item });
      this.setState({ allTags: tag })
    }
    if (this.props.selectedSubProduct && !Utility.isEmpty(this.props.selectedSubProduct.product_tags)) {
      const tag = this.props.selectedSubProduct.product_tags.map((item) => { return item.tag_name });
      this.setState({ allTags: tag })
    }
    if (this.props.selectedSubProduct && !Utility.isEmpty(this.props.selectedSubProduct.additional_details)) {
      let allAttributes = JSON.parse(this.props.selectedSubProduct.additional_details)
      allAttributes.sort(function (a, b) {
        return a.sequenceNo - b.sequenceNo;
      })
      const attributes = allAttributes.map(item => { return item })
      this.setState({ attributeValues: attributes });
    }
    if (this.props.selectedSubProduct && !Utility.isEmpty(this.props.selectedSubProduct.all_colors)) {
      let allColors = JSON.parse(this.props.selectedSubProduct.all_colors)
      const colors = allColors.map(items => { return items });
      this.setState({ allColors: colors });
    }
    if (this.props.selectedSubProduct && !Utility.isEmpty(this.props.selectedSubProduct.all_images)) {
      let allImages = JSON.parse(this.props.selectedSubProduct.all_images)
      allImages.sort(function (a, b) {
        return a.sequenceNo - b.sequenceNo
      })
      const images = allImages.map(item => { return item })
      this.setState({ allImages: images });
    }

    if (this.props.selectedSubProduct && !Utility.isEmpty(this.props.selectedSubProduct.categories)) {
      const category = this.props.allCategories.filter(x => x.id === this.props.selectedSubProduct.categories[0].id)
      this.setState({ category_name: category[0].name_for_shops })
    }

  }

  getProductDetails = () => {

    this.props.showLoader(true);
    Utility.sendRequest(PRODUCTS + '/' + this.props.selectedProductId, 1, {}, (err, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);
      if (!body.error) {
        this.setState(this.getState(body));
        this.getSubProductDetails();
        if (!Utility.isEmpty(body.categories)) {
          const category = this.props.allCategories.filter(x => x.id === body.categories[0].id)
          this.state.formData.category_id = category[0].id;
          this.setState({ category_name: category[0].name_for_shops })
        }
        if (!Utility.isEmpty(body.categories)) {
          let prodtypeId = body.categories[0].CategoryProducts.product_type_id
          const currentProductId = this.props.allProductTypes.filter(x => x.id === prodtypeId)

          if (!Utility.isEmpty(currentProductId)) {
            this.state.formData.product_type_id = currentProductId[0].id;
            this.setState({ product_type_name: currentProductId[0].menu_name });
          }
        }
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  getSubProductDetails = () => {
    const params = {
      parent_id: this.props.selectedProductId ? this.props.selectedProductId : this.props.productId
    }

    Utility.sendRequest(SUBPRODUCTS, 1, params, (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        body.sort((a, b) => {
          return a.seq_id - b.seq_id
        })
        this.setState({ subProductListing: body });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  handleClick = () => {
    this.setState({ renderView: true });
  }
  closeForm = () => {
    this.setState({ renderView: false });
  }

  handleChange = (event) => {

    let formData = this.state.formData;

    formData = Utility.validateAndReturnFormData(this, formData, event, validateFields, formName);
    this.setState({ formData });

  }

  handleFormErrors = () => {
    const isValid = Utility.showFormErrors(this, validateFields, formName);
    return (isValid);
  }

  handleErrors = () => {

    const formData = this.state.formData;
    const error = this.state.error;
    error.name = '';
    error.description = '';
    error.category_id = ""
    error.product_type_id = ""

    if (formData.name === "") {
      error.name = "Name is required field";
    }
    if (formData.description === '') {
      error.description = "Description is required field"
    }
    this.setState({ error });

    if (!error.name && !error.description)
      return true;
    else
      return false;
  }

  handleDescriptionError = () => {

    const formData = this.state.formData;
    const error = this.state.error;
    error.description = '';

    if (formData.description === '') {
      error.description = "Description is required field"
    }
    this.setState({ error });

    if (!error.description)
      return true;
    else
      return false;
  }

  handleCategoryError = () => {
    const error = this.state.error;
    error.category_id = ""
    const formData = this.state.formData;

    if (formData.category_id === '') {
      error.category_id = "Please select category !!";
      this.setState({ error });
      return false;
    }
    return true;
  }

  handleProductTypeError = () => {
    const error = this.state.error;
    error.product_type_id = ""
    const formData = this.state.formData;

    if (formData.product_type_id === '') {
      error.product_type_id = "Please select product type !!";
      this.setState({ error });
      return false;
    }
    return true;
  }

  submit = (e) => {
    e.preventDefault();

    const isFormValid = this.handleFormErrors();
    const isCategoryValid = this.handleCategoryError();
    const isProductTypeValid = this.handleProductTypeError();
    const isDescriptionvalid = this.handleDescriptionError();
    if (!isFormValid) return;
    if (!isCategoryValid) return;
    if (!isProductTypeValid) return;
    if (!isDescriptionvalid) return;

    const params = this.state.formData;

    params.tags = this.state.allTags;
    params.product_type_id = this.state.formData.product_type_id;

    if (!Utility.isEmpty(this.state.attributeValues))
      params.additional_details = JSON.stringify(this.state.attributeValues);

    if (!Utility.isEmpty(this.state.allColors))
      params.all_colors = JSON.stringify(this.state.allColors);

    if (!Utility.isEmpty(this.state.addSubProductList)) {
      this.state.addSubProductList.map(item => {
        item.user_client_id = this.props.userData.id;
      })
      params.subProduct = this.state.addSubProductList;
    }

    this.props.showLoader(true);

    if (!Utility.isEmpty(this.state.addSubProductList) && Utility.isEmpty(this.props.selectedProductId)) {
      this.createAllProductsDetails(params);
    }
    else if (Utility.isEmpty(this.props.selectedProductId)) {
      this.createProductDetails(params);
    } else {
      this.updateProductDetails(params);
    }
  }

  saveSubProduct = async (e) => {

    const isValid = this.handleErrors();
    if (!isValid) return;
    e.preventDefault();
    let allImageURL = [];
    const params = this.state.formData;
    params.tags = this.state.allTags;
    if (!Utility.isEmpty(this.state.attributeValues))
      params.additional_details = JSON.stringify(this.state.attributeValues);

    if (!Utility.isEmpty(this.state.allColors))
      params.all_colors = JSON.stringify(this.state.allColors);

    if (this.state.documentFile) {
      const res = await this.getImageUrl();
      let documentUrl = await Utility.uploadFile(this.state.documentFile, res)
      var documentObj = {
        document_name: this.state.documentName,
        document_url: documentUrl
      }
      params.document_details = JSON.stringify(documentObj);
    }

    if (!Utility.isEmpty(this.state.allImages)) {
      await Promise.all(this.state.allImages.map(async (items) => {
        const res = await this.getImageUrl();
        if (items.imageFile) {
          const imageURL = await Utility.uploadFile(items.imageFile, res);
          var obj = {
            id: items.id,
            image_url: imageURL,
            isPrimary: items.isPrimary,
            name: items.imageFile.name,
            sequenceNo: items.sequenceNo
          }
          allImageURL = [...allImageURL, obj];
        }
        else {
          allImageURL = [...allImageURL, items]
        }
      })
      )
      params.all_images = JSON.stringify(allImageURL);
    }
    if (this.props.mainProduct === true) {

      if (this.props.Add === true) {
        this.props.setSubProductList(params);
        this.props.closeBtn();
      }
      else {
        this.props.editSubProductDetails(params);
        this.props.closeSubProductForm();
      }

    }

    else {

      if (!this.props.productId) {
        this.props.setSubProductList(params);
        this.props.closeBtn();
      }

      else if (this.props.isSubproduct) {
        this.props.showLoader(true);
        this.addSubProduct(params);
        this.props.closeBtn();
      }
      else {
        this.props.showLoader(true);
        this.editSubProduct(params);
        this.props.closeSubProductForm();

      }

    }

  }

  addSubProduct = (params) => {

    params.user_client_id = this.props.userData.id;
    params.category_id = this.state.formData.category_id;
    params.parent_id = this.props.productId ? this.props.productId : ""
    params.created_by = this.props.userData.id;
    params.updated_by = this.props.userData.id;

    Utility.sendRequest(PRODUCTS, 2, params, async (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        const productData = body;
        this.props.getSubProductDetails();

        if (!Utility.isEmpty(this.state.allImages)) {
          this.createImagesParams(productData.id)
        }

        this.props.showLoader(false);
        toast.success("Sub Product Added Successfully", { containerId: 'private' });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  editSubProduct = (params) => {

    if (this.props.selectedSubProduct) {
      const id = this.props.selectedSubProduct.id;
      params.category_id = this.state.formData.category_id
      params.parent_id = this.state.selectedProductId;

      Utility.sendRequest(PRODUCTS + '/' + id, 3, params, async (err, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {

          if (!Utility.isEmpty(this.state.allImages)) {
            this.createImagesParams(id)
          }

          this.props.showLoader(false);
          this.props.getSubProductDetails();
          toast.success("Sub Product Updated Successfully", { containerId: 'private' });
        } else {
          toast.error(body.error.message, { containerId: 'private' });
        }
      });
    }
  }

  closeSubProductForm = () => {
    this.setState({ editSubProductView: false });
  }

  updateProductDetails = async (params) => {
    let documentUrl;
    params.category_id = this.state.formData.category_id
    Utility.sendRequest(PRODUCTS + '/' + this.props.selectedProductId, 3, params, async (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {

        if (this.state.documentFile) {
          documentUrl = await Utility.uploadFile(this.state.documentFile, body.documentSignedURL)
          this.updateDocumentUrl(this.props.selectedProductId, documentUrl);
        }

        if (!Utility.isEmpty(this.state.allImages)) {
          this.createImagesParams(this.props.selectedProductId, "edit");
        }
        else {
          this.props.showProductList("tab1", "", this.state.formData.category_id);
          toast.success("Product Updated successfully", { containerId: 'private' });
          this.props.showLoader(false);
        }
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  createAllProductsDetails = (params) => {

    let documentUrl;
    params.user_client_id = this.props.userData.id;
    params.client_id = CLIENT_ID;
    params.category_id = this.state.formData.category_id;
    params.created_by = this.props.userData.id;
    params.updated_by = this.props.userData.id;

    Utility.sendRequest(PRODUCTS + '/bulkCreate', 2, params, async (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        const productData = body;

        if (this.state.documentFile) {
          documentUrl = await Utility.uploadFile(this.state.documentFile, body.documentSignedURL)
          this.updateDocumentUrl(body.id, documentUrl);
        }

        if (!Utility.isEmpty(this.state.allImages)) {
          this.createImagesParams(productData.id, "create")
        }
        else {
          this.props.showLoader(false);
          toast.success("Product Added successfully", { containerId: 'private' });
          this.props.closeAddProductForm();
        }
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });

  }
  updateProductMediaUrl = (productId, allImagesArray, action) => {
    const imgArray = allImagesArray.filter(x => x.isPrimary !== true)
    if (allImagesArray.length === imgArray.length) {
      allImagesArray[0].isPrimary = true;
    }

    const all_images = JSON.stringify(allImagesArray);
    const params = { all_images: all_images };

    Utility.sendRequest(PRODUCTS + '/' + productId, 3, params, (err, res, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      else {
        if (action === "edit") {
          this.props.showProductList("tab1", "", this.state.formData.category_id);
          toast.success("Product Updated successfully", { containerId: 'private' });
        }
        else if (action === "create") {
          this.props.showLoader(false);
          toast.success("Product Added successfully", { containerId: 'private' });
          this.props.closeAddProductForm();
        }
      }
    });
  }

  cancelForm = () => {
    if (this.props.mainProduct === true) {

      if (this.props.Add === true) {
        this.props.closeBtn();
      }
      else {
        if (this.props.isSubproductEdit) {
          this.props.closeSubProductForm();
        }
        else
          this.props.closeAddProductForm()
      }

    }

    else {

      if (!this.props.productId) {
        this.props.closeAddProductForm()
      }
      else if (this.props.isSubproductEdit) {
        this.props.closeSubProductForm();
      }
      else {
        this.props.closeBtn();
      }
    }
  }

  createProductDetails = (params) => {
    let documentUrl;

    params.client_id = CLIENT_ID;
    params.tags = this.state.allTags;
    params.user_client_id = this.props.userData.id;
    params.category_id = this.state.formData.category_id
    params.created_by = this.props.userData.id;
    params.updated_by = this.props.userData.id;
    Utility.sendRequest(PRODUCTS, 2, params, async (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        const productData = body;

        if (this.state.documentFile) {
          documentUrl = await Utility.uploadFile(this.state.documentFile, body.documentSignedURL)
          this.updateDocumentUrl(body.id, documentUrl);
        }

        if (!Utility.isEmpty(this.state.allImages)) {
          this.createImagesParams(productData.id, "create")
        }
        else {
          this.props.showLoader(false);
          toast.success("Product Added successfully", { containerId: 'private' });
          this.props.closeAddProductForm();
        }

      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }

    });
  }

  deleteProductItem = (subProductId) => {
    Utility.sendRequest(PRODUCTS + '/' + subProductId, 4, {}, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        const list = this.state.myProductList;
        this.getSubProductDetails();
        toast.success('Record deleted successfully', { containerId: 'private' });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  deleteSubProductItem = (data) => {

    this.state.addSubProductList = this.state.addSubProductList.filter(item => item.name !== data.name)

    this.setState({
      addSubProductList: [...this.state.addSubProductList]
    })
  }

  updateDocumentUrl = async (productId, documentURL) => {
    const documentObj = {
      document_name: this.state.documentName,
      document_url: documentURL
    }
    const document = JSON.stringify(documentObj);
    const params = { document_details: document };

    Utility.sendRequest(PRODUCTS + '/' + productId, 3, params, (err, res, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      else {
      }
    });
  }

  createImagesParams = async (productId, action = null) => {
    let allImagesArray = [];

    if (!Utility.isEmpty(this.state.allImages)) {
      await Promise.all(this.state.allImages.map(async (items) => {
        const res = await this.getImageUrl();
        if (items.imageFile) {
          const imageURL = await Utility.uploadFile(items.imageFile, res);
          const obj = {
            id: items.id,
            image_url: imageURL,
            isPrimary: items.isPrimary,
            name: items.imageFile.name,
            sequenceNo: items.sequenceNo
          }
          allImagesArray.push(obj);
        }
        else {
          allImagesArray.push(items);
        }
      })
      )
    }
    this.updateProductMediaUrl(productId, allImagesArray, action);
  }

  getImageUrl = async () => {
    let res = "";
    try {
      const resp = await axios.get(`${API_PATH}/getImageUrl`);
      res = resp.data.data;
    } catch (err) {
       console.error(err);
    }
    return res;
  }

  handleSelectCategory = (e) => {
    this.state.error.category_id = '';
    const categoryName = this.props.allCategories.filter(data => data.id === e);
    this.setState({ category_name: categoryName[0].name_for_shops })
    this.state.formData.category_id = e;
  }

  handleSelectProductType = (e) => {
    this.state.error.product_type_id = '';
    const typeName = this.props.allProductTypes.filter(data => data.id === e);
    this.setState({ product_type_name: typeName[0].menu_name, selectedProductTypeId: e })
    this.state.formData.product_type_id = e;
  }

  handleFileChange = (name, previewURL, file) => {

    if (!file) {
      return;
    }

    if (name === "document_url") {
      this.setState({ document_url: previewURL, documentName: file.name, documentFile: file })
    }
    else {
      this.setState({ imagePreviewUrl: previewURL, imageFile: file }, () => {
        Utility.isFilePathEmpty('image_url', this.state.imagePreviewUrl, formName);
        let recentSeqNo = 1;
        if (this.state.allImages.length > 0) {
          const image = this.state.allImages;
          const count = this.state.allImages.length;
          recentSeqNo = image[count - 1].sequenceNo + 1;
        }

        let obj = {
          id: Math.floor(Math.random() * 1000),
          image_url: this.state.imagePreviewUrl,
          isPrimary: false,
          imageFile: this.state.imageFile,
          name: this.state.imageFile.name,
          sequenceNo: recentSeqNo
        }
        this.setState({ allImages: [...this.state.allImages, obj] })
      });
    }
  }


  removeUploadedFile = () => {
    this.setState({ documentName: '', document_url: '', documentFile: '' }, () => {
      if (!this.props.SubProductItem)
        document.getElementById('pwa-upload').value = null;
      else
        document.getElementById('pwa').value = null;
    });
  }

  handleImagePrimaryClick = (item) => {
    this.state.allImages && this.state.allImages.map(items => {
      if (items.id === item.id) {
        items.isPrimary = true
      }
      else {
        items.isPrimary = false
      }
    })
    const index = this.state.allImages.findIndex(x => x.id === item.id)
    let seq1 = item.sequenceNo;
    let seq2 = this.state.allImages[0].sequenceNo;

    this.state.allImages[0].sequenceNo = seq1;
    this.state.allImages[index].sequenceNo = seq2;

    this.state.allImages.sort((a, b) => {
      return a.sequenceNo - b.sequenceNo;
    })

    this.setState({ allImages: [...this.state.allImages] })
  }

  handleActionClick = (item, edit) => {

    if (edit === "1") {
      if (this.state.displayActionName === 'Set As Primary') {
        this.handleImagePrimaryClick(item);
      }
      else if (this.state.displayActionName === 'Delete') {
        const ans = window.confirm("Delete this image?")
        if (ans === true) {
          let remmainingImages = this.state.allImages.filter(x => x.id !== item.id)
          this.setState({ allImages: remmainingImages })
        }
      }
      this.state.displayActionName = '';
    }

    if (edit === "2") {
      if (this.state.displayActionName === 'Edit') {
        this.setState({ showEditAttributeform: true, selectedAttributeItem: item })
      }
      else if (this.state.displayActionName === 'Delete') {
        const ans = window.confirm("Delete this field?")
        if (ans === true) {
          let remmainingAttributes = this.state.attributeValues.filter(x => x.id !== item.id)
          this.setState({ attributeValues: remmainingAttributes })
        }
      }
      this.state.displayActionName = '';
    }

    else if (edit === "3") {
      if (this.state.displayActionName === 'Edit')
        this.setState({ editSubProductView: true, selectedSubProductItem: item })
      else if (this.state.displayActionName === 'Delete') {
        const ans = window.confirm("Delete this product?")
        if (ans === true) {
          if (this.props.mainProduct) {
            this.deleteSubProductItem(item);
          }
          else
            this.deleteProductItem(item.id);
        }
      }
      this.state.displayActionName = '';
    }
  }

  deleteTag = (selectedTag) => {
    const tags = this.state.allTags.filter(x => x !== selectedTag)
    this.setState({ allTags: tags })
  }
  deleteColors = (selectedColor) => {
    const color = this.state.allColors.filter(x => x !== selectedColor);
    this.setState({ allColors: color })
  }

  handleAttribute = (e) => {
    e.preventDefault();
    if (this.state.formData.attributeName === '' && this.state.formData.attributeDescription === '') {
      return;
    }
    let recentSeqNo = 1;
    if (this.state.attributeValues.length > 0) {
      const attribute = this.state.attributeValues;
      const count = this.state.attributeValues.length;
      recentSeqNo = attribute[count - 1].sequenceNo + 1;
    }

    const obj = {
      id: Math.random() * 1000,
      attributeName: this.state.formData.attributeName,
      attributeDescription: this.state.formData.attributeDescription,
      isPrivate: this.state.formData.is_checked,
      sequenceNo: recentSeqNo
    }
    this.setState({ attributeValues: [...this.state.attributeValues, obj] }, () => {
      if (!this.props.getSubProductDetails) {
        document.getElementById('attributeName').value = ''
        document.getElementById('attributeDescription').value = ''
        document.getElementById('fields_checkbox').checked = false;
      }
      else {
        document.getElementById('attributeNames').value = ''
        document.getElementById('attributeDescriptions').value = ''
        document.getElementById('field_checkbox').checked = false;
      }
      this.state.formData.attributeName = '';
      this.state.formData.attributeDescription = '';
      this.setState({ is_checked: false });
    });
  }

  closeAttributeForm = () => {
    this.setState({ showEditAttributeform: false });
  }

  handleChangeComplete = (color) => {
    const formData = this.state.formData;
    formData.color_code = color.hex;
    this.openColorSelector();

    this.setState({ formData, allColors: [...this.state.allColors, formData.color_code] });
  };

  openColorSelector = () => {
    this.setState((state) => {
      return { showColorSelector: !state.showColorSelector };
    });
  }

  changeDescription = (data, id) => {
    const dataHash = this.state.formData;
    dataHash[id] = data;
    Utility.isRichTextEmpty(id, data, formName);
    this.state.formData.plain_description = data.replace(/<\/?[^>]+(>|$)/g, "");
    this.setState({ formData: dataHash });
  }

  handleDocumentUpload = (event) => {
    const DOCUMENT_URL = "document_url";
    if (event.target.files[0]) {
      const reader = new FileReader();
      const originalFile = event.target.files[0];

      if (originalFile.size > MAX_DOCUMENT_SIZE) {
        const errText = 'Document must be less than ' + (MAX_DOCUMENT_SIZE / 1048576) + 'MB.';
        toast.error(errText, { containerId: "public" });
        event.target.value = '';
        return;
      } else if (originalFile.type !== "application/pdf") {
        toast.error("Document must be PDF file.", { containerId: "public" });
        return;
      }
      reader.readAsDataURL(originalFile);
      reader.onloadend = () => { this.handleFileChange(DOCUMENT_URL, reader.result, originalFile); };
    }
  }

  removeDocument = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.target.value = '';
    this.removeUploadedFile('document_url');
    this.setState({ documentName: "", document_url: "" });
  }

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const { allImages } = this.state;
    const all_images = allImages;

    let seq1 = all_images[source.index].sequenceNo
    let seq2 = all_images[destination.index].sequenceNo

    allImages[source.index].sequenceNo = seq2;
    allImages[destination.index].sequenceNo = seq1;

    this.handleIsPrimaryImage(allImages)
  }

  handleIsPrimaryImage = (images) => {

    for (let i = 0; i < images.length; i++) {
      images[i].isPrimary = false;
    }
    images.sort((a, b) => {
      return a.sequenceNo - b.sequenceNo;
    })
    images[0].isPrimary = true
    this.setState({ allImages: images });
  }

  onDragEndAttributes = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const { attributeValues } = this.state;
    const all_attributes = attributeValues;


    let seq1 = all_attributes[source.index].sequenceNo
    let seq2 = all_attributes[destination.index].sequenceNo

    attributeValues[source.index].sequenceNo = seq2;
    attributeValues[destination.index].sequenceNo = seq1;

    attributeValues.sort((a, b) => {
      return a.sequenceNo - b.sequenceNo;
    })

    this.setState({ attributeValues: attributeValues })
  }

  onDragEndSubProducts = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return
    }

    const { subProductListing } = this.state;
    const sub_product = subProductListing;
    const { addSubProductList } = this.state;

    if (!Utility.isEmpty(sub_product)) {
      const subProductId1 = sub_product[source.index].id;
      const seq1 = sub_product[source.index].seq_id;
      const subProductId2 = sub_product[destination.index].id;
      const seq2 = sub_product[destination.index].seq_id;

      sub_product[source.index].seq_id = seq2;
      sub_product[destination.index].seq_id = seq1;
      sub_product.sort((a, b) => {
        return a.seq_id - b.seq_id;
      })
      this.updateSeqNoforSubProducts(subProductId1, seq2);
      this.updateSeqNoforSubProducts(subProductId2, seq1);
      this.setState({ subProductListing: sub_product });
    }

    if (!Utility.isEmpty(addSubProductList)) {
      const subProduct = Array.from(addSubProductList);
      const [reOrderItem] = subProduct.splice(source.index, 1);
      subProduct.splice(destination.index, 0, reOrderItem);
      this.setState({ addSubProductList: subProduct });
    }

  }

  updateSeqNoforSubProducts = (id, seq) => {
    let params = {
      seq_id: seq
    }
    Utility.sendRequest(SWAP_SEQUENCES + '/' + id, 3, params, async (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }


  render() {
    return (
      <>
        <div className="py-4" >
          <div className="font-italic font-bold mt-3 font-18" >Primary Details</div>
          <Form className="shar-form search-form search-form-pwa sidebar-form mt-4" >
            {this.props.allProductTypes &&
              <Form.Group className="mb-4 pb-1" >
                <Form.Label className="font-bold display-8 mb-3" >Select Shop Type</Form.Label>
                <Dropdown onSelect={this.handleSelectProductType} name="product_type_id" ref="product_type_id" className="article-dropdown form-control h-auto py-0">
                  <Dropdown.Toggle
                    variant="none"
                    className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100 font-bold font-weight-bold"
                    id="dropdown-article">
                    {this.state.product_type_name}
                    <ChevronDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none">
                    {this.props.allProductTypes && this.props.allProductTypes.map((item, index) => {
                      return (
                        <Dropdown.Item eventKey={item.id}>{item.menu_name}</Dropdown.Item>
                      )
                    })
                    }
                  </Dropdown.Menu>
                </Dropdown>
                <div className="error errorIcon">{this.state.error.product_type_id}</div>
              </Form.Group>
            }
            {this.props.allCategories &&
              <Form.Group className="mb-4 pb-1" >
                <Form.Label className="font-bold display-8 mb-3" >Select Category</Form.Label>
                <Dropdown onSelect={this.handleSelectCategory} name="category_id" ref="category_id"
                  className="article-dropdown form-control h-auto py-0">
                  <Dropdown.Toggle
                    variant="none"
                    className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100"
                    id="dropdown-article">
                    {this.state.category_name}
                    <ChevronDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none" >
                    {this.props.allCategories && this.props.allCategories.map((item, index) => {
                      return (
                        item.product_type_id === this.state.selectedProductTypeId &&
                        <Dropdown.Item eventKey={item.id}>{item.name_for_shops}</Dropdown.Item>
                      )
                    })
                    }

                  </Dropdown.Menu>
                </Dropdown>
                <div className="error errorIcon">{this.state.error.category_id}</div>
              </Form.Group>
            }

            <Form.Group className="mb-4 pb-1" controlId="name">
              <Form.Label className="font-bold display-8 mb-3">
                {!this.props.isSubproduct ?
                  "Add Product Name" : "Add Sub Product Name"
                }
              </Form.Label>
              <InputField
                required={true}
                type="text"
                name="name"
                ref="name"
                placeholder="Name"
                value={this.state.formData.name}
                handleChange={this.handleChange}
              />
              <div className="error errorIcon">{this.state.error.name}</div>
            </Form.Group>

            <Form.Group className="mb-4 pb-1" >
              <Form.Label className="font-bold display-8 mb-3" >
                {!this.props.isSubproduct ?
                  "Upload Product Images/Video" : "Upload Sub Product Images/Video"
                }
              </Form.Label>
              <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
                <label className="post-uplodad-label mb-0 cursor-pointer">
                  <UploadFile
                    name='image_url'
                    inputId="image_url"
                    removeText="Remove image"
                    acceptTypes="image/x-png,image/gif,image/jpeg"
                    handleFileChange={this.handleFileChange}
                    removeUploadedFile={this.removeUploadedFile}
                    fromProducts={true}
                  />
                  <div className="error errorIcon" id={'image_urlError'} />
                  {
                    true &&
                    <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
                  }
                </label>
              </div>

              <div className="mt-4 pt-3">
                <div className="table-responsive">
                  <table className="table table-sm table-hover table-subproduct">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId='droppable'>
                        {(provided, snapshot) => (
                          <tbody {...provided.droppableProps} ref={provided.innerRef}>
                            {this.state.allImages && this.state.allImages.map((items, index) => (
                              <Draggable
                                key={items.id}
                                draggableId={`${items.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={items.id}
                                  >
                                    <td className="py-3">
                                      <div className="align-items-center">
                                        <DragIcon mainClass="mr-2 ml-1" />
                                        <div className="lesson-img mr-3" >
                                          <img src={items.image_url} />
                                        </div>
                                        <span className="font-weight-bold font-18 text-dark">{items.name ? items.name : "Image"}</span>
                                      </div>
                                    </td>
                                    <td className="action-td-chat pt-4">

                                      <DropDownAction
                                        handleActionClick={this.handleActionClick}
                                        item={items}
                                        edit={"1"}
                                        setCurrentActionName={(data) => {
                                          this.setState({ displayActionName: data });
                                        }}
                                      />
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </table>
                </div>
              </div>

              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">
                  Color
                          </Form.Label>
                <div className="d-flex flex-wrap align-items-end position-relative" >
                  <div className="shop-color-picker-box mr-4 flex-center position-relative">
                    <CircleOutlinedAddIcon fillClass="fill-black" />
                    {
                      this.state.showColorSelector &&
                      <SketchPicker
                        color={this.state.formData.color_code}
                        onChangeComplete={this.handleChangeComplete}
                      />
                    }
                    <div className="shar-color-picker cursor-pointer" onClick={this.openColorSelector} />
                  </div>
                  {this.state.allColors && this.state.allColors.map(item => {
                    return (
                      <div onClick={() => { this.deleteColors(item) }} style={{ backgroundColor: item }} className="shop-tag-info text-white mb-0">
                        <CloseIconSharp mainClass="mr-2 cursor-pointer" fillClass="fill-white" />
                        {item}
                      </div>
                    )
                  })
                  }
                </div>
              </Form.Group>

              <Form.Group className="mb-4 pb-1 pt-3">
                <Form.Label className="font-bold display-8 mb-3">
                  {!this.props.isSubproduct ? "Enter Product Description" : "Enter Sub Product Description"} <span className="font-italic" >(Details)</span>
                </Form.Label>
                <RichTextEditor
                  id="description"
                  ref="description"
                  name="description"
                  placeholder="Description"
                  data={this.state.formData.description}
                  changeDescription={this.changeDescription}
                />
                <div className="error errorIcon">{this.state.error.description}</div>
              </Form.Group>

            </Form.Group>

            <Form.Group className="mb-4 pb-1 pt-3">
              <Form.Label className="font-bold display-8 mb-3">
                Inventory
                          </Form.Label>
              <div className="d-flex input-field-sm align-items-center">
                <InputField
                  type="text"
                  placeholder="#"
                  handleChange={this.handleChange}
                />

                <label className="cursor-pointer d-flex">
                  <input type="checkbox" className="toolbox-card-checkbox" />
                  <div className="toolbox-card-checkbox-outer">
                    <TickFilledIcon fillClass="fill-primary" />
                  </div>
                  <span className="ml-2 font-16 b-500" >Unlimited Supply</span>
                </label>
              </div>
            </Form.Group>

            <Form.Group className="mb-4 pb-1 pt-3">
              <Form.Label className="font-bold display-8 mb-3">
                Price
                          </Form.Label>
              <div className="d-flex input-field-sm align-items-center">
                <InputField
                  type="text"
                  ref="price"
                  placeholder="$"
                  name="price"
                  value={this.state.formData.price}
                  handleChange={this.handleChange}
                />

                <label className="cursor-pointer d-flex">
                  <input type="checkbox" className="toolbox-card-checkbox" checked="true" />
                  <div className="toolbox-card-checkbox-outer">
                    <TickFilledIcon fillClass="fill-primary" />
                  </div>
                  <span className="ml-2 font-16 b-500" >Include State Tax at checkout</span>
                </label>
              </div>
            </Form.Group>

            <div className="font-italic font-bold mt-3 font-18" >Additional Details</div>

            <div className="bg-light py-4 additional-field-details mt-4" >
              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">
                  {!this.props.isSubproduct ? "Add Product Fields" : "Add Sub Product Fields"}
                </Form.Label>
                <InputField
                  required={true}
                  type="text"
                  id={!this.props.getSubProductDetails ? "attributeName" : "attributeNames"}
                  name="attributeName"
                  ref="attributeName"
                  handleChange={this.handleChange}
                  placeholder="Add Field Name..."
                  className="w-75"
                />
                <Textarea
                  required={true}
                  type="text"
                  name="attributeDescription"
                  id={!this.props.getSubProductDetails ? "attributeDescription" : "attributeDescriptions"}
                  ref="attributeDescription"
                  placeholder="Add text…"
                  handleChange={this.handleChange}
                  className="scroll-y w-75 py-3 mt-3"
                />
                <CheckboxNew
                  ref="is_checked"
                  checkboxId="is_checked"
                  id={!this.props.getSubProductDetails ? "fields_checkbox" : "field_checkbox"}
                  name="is_checked"
                  label="is Private?"
                  handleChange={this.handleChange}
                />
              </Form.Group>
              <div className="w-75 text-right" >
                <button onClick={this.handleAttribute} className="btn text-primary bg-light-primary btn-rounded font-weight-bold px-5 py-2 font-18" >Save &amp; Add</button>
              </div>
            </div>

            <div className="mt-4 pt-3">
              <div className="table-responsive pb-5">
                <table className="table table-sm table-hover table-attributes">
                  <thead>
                    <tr className="font-18 b-600">
                      <th className="border-0" >Field Name</th>
                      <th className="border-0">Field Description</th>
                      <th className="border-0">Is Private</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>

                  <DragDropContext onDragEnd={this.onDragEndAttributes}>
                    <Droppable droppableId='droppable'>
                      {(provided, snapshot) => (
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                          {this.state.attributeValues && this.state.attributeValues.map((items, index) => (
                            <Draggable
                              key={items.id}
                              draggableId={`${items.id}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={items.id}
                                >
                                  <td className="py-3">
                                    <div className="align-items-center">
                                      <DragIcon mainClass="mr-2 ml-1" />
                                      <span className="font-18 text-dark">{items.attributeName}</span>
                                    </div>
                                  </td>
                                  <td className="py-3 font-18">{items.attributeDescription}</td>
                                  <td className="py-3 font-18">{items.isPrivate ? "true" : "false"}</td>
                                  <td className="action-td-chat pt-4">
                                    <DropDownAction
                                      handleActionClick={this.handleActionClick}
                                      item={items}
                                      edit={"2"}
                                      setCurrentActionName={(data) => {
                                        this.setState({ displayActionName: data });
                                      }}
                                    />
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>
              </div>
            </div>

            <Form.Group className="mb-4 pb-1">
              <Form.Label className="font-bold display-8 mb-3" >Add Documents</Form.Label>
              <label htmlFor={!this.props.SubProductItem ? "pwa-upload" : "pwa"} className="pwa-upload-field form-control align-items-center justify-content-between  cursor-pointer">
                <span>{!Utility.isEmpty(this.state.documentName) ? this.state.documentName : "Upload Document"}</span>
                {(this.state.documentName)
                  ? <span onClick={this.removeDocument} ><CloseIcon className="cursor-pointer" fillClass="fill-primary" /></span>
                  : <img src={require('assets/images/icons/attachment_icon.svg')} className="attacment-icon" alt="" />}
              </label>
              <InputField
                handleChange={this.handleDocumentUpload}
                type="file"
                id={!this.props.SubProductItem ? "pwa-upload" : "pwa"}
                accept="application/pdf"
              />
            </Form.Group>

            <div className="w-75">
              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">
                  {!this.props.isSubproduct ? "Add Product Tag" : "Add Sub Product Tag"}
                </Form.Label>
                <InputField
                  required={true}
                  type="text"
                  name="tags"
                  ref="tags"
                  placeholder="Add Tag, separated by commas..."
                  value={this.state.formData.tags}
                  handleChange={this.handleChange}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      const arr = e.target.value.split(',');
                      this.setState({ allTags: [...this.state.allTags, ...arr] })
                      this.state.formData.tags = ""
                    }
                  }}
                  className="w-75"
                />
              </Form.Group>
              <div className="d-flex flex-wrap" >
                {this.state.allTags && this.state.allTags.map(item => {
                  return (
                    <div onClick={() => { this.deleteTag(item) }} className="shop-tag-info bg-primary text-white">
                      <CloseIconSharp mainClass="mr-2 cursor-pointer" fillClass="fill-white" />
                      {item}
                    </div>
                  )
                })}

              </div>
            </div>
            {!this.props.isSubproduct && !this.props.isSubproductEdit &&
              <>
                <div className="font-italic font-bold mt-3 font-18" >Sub Products</div>
                <Form.Group className="my-4 pb-1">
                  <Form.Label className="font-bold display-8 mb-3">
                    Add a Sub Product
                          </Form.Label>
                  <div className="shop-color-picker-box flex-center">
                    <button className="btn" onClick={this.handleClick} ><CircleOutlinedAddIcon fillClass="fill-black" /></button>
                  </div>
                </Form.Group>

                <div className="mt-lg-4 pt-lg-3">
                  <div className="table-responsive">
                    <table className="table table-sm table-hover table-subproduct">
                      <thead>
                        <tr className="b-600 font-18">
                          <th className="border-0" >Name</th>
                          <th className="border-0" >Actions</th>
                        </tr>
                      </thead>
                      <DragDropContext onDragEnd={this.onDragEndSubProducts}>
                        <Droppable droppableId='droppable'>
                          {(provided, snapshot) => (
                            <tbody  {...provided.droppableProps} ref={provided.innerRef}>
                              {
                                this.state.subProductListing && this.state.subProductListing.map((item, index) => {
                                  let url = '';
                                  if (!Utility.isEmpty(item.all_images)) {
                                    const img = JSON.parse(item.all_images)
                                    url = img.map(itm => { return itm.image_url });
                                  }
                                  return (
                                    <>
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            key={item.id}
                                          >
                                            <td className="py-3">
                                              <div className="align-items-center">
                                                <DragIcon mainClass="mr-2 ml-1" />
                                                <div className="lesson-img mr-3" >
                                                  <img src={url[0]} />
                                                </div>
                                                <span className="font-weight-bold font-18 text-dark">{item.name}</span>
                                              </div>
                                            </td>
                                            <td className="action-td-chat pt-4">
                                              <DropDownAction
                                                handleActionClick={this.handleActionClick}
                                                item={item}
                                                edit={"3"}
                                                setCurrentActionName={(data) => {
                                                  this.setState({ displayActionName: data });
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    </>
                                  )
                                })}
                              {
                                this.state.addSubProductList && this.state.addSubProductList.map((item, index) => {
                                  let id = Math.random().toString(36).substring(3);
                                  let url = '';
                                  if (!Utility.isEmpty(item.all_images)) {
                                    const img = JSON.parse(item.all_images)
                                    url = img.map(itm => { return itm.image_url });
                                  }
                                  return (
                                    <>
                                      <Draggable
                                        key={id}
                                        draggableId={`${index + 1}`}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            key={id}
                                          >
                                            <td className="py-3">
                                              <div className="align-items-center">
                                                <DragIcon mainClass="mr-2 ml-1" />
                                                <div className="lesson-img mr-3" >
                                                  <img src={url[0]} />
                                                </div>
                                                <span className="font-weight-bold font-18 text-dark">{item.name}</span>
                                              </div>
                                            </td>
                                            <td className="action-td-chat pt-4">
                                              <DropDownAction
                                                handleActionClick={this.handleActionClick}
                                                item={item}
                                                edit={"3"}
                                                setCurrentActionName={(data) => {
                                                  this.setState({ displayActionName: data });
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    </>
                                  )
                                })}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </table>
                  </div>
                </div>
              </>
            }
            <Form.Group className="my-4 pb-1">
              <Form.Label className="font-bold display-8 mb-3">
                Add to Marketplace
                          </Form.Label>
              <label className="cursor-pointer d-flex">
                <input type="checkbox" className="toolbox-card-checkbox" checked="true" />
                <div className="toolbox-card-checkbox-outer">
                  <TickFilledIcon fillClass="fill-primary" />
                </div>
                <span className="ml-2 font-16 b-500" >Yes, I want my product shared to global community.</span>
              </label>
            </Form.Group>

            <div className="d-inline-flex flex-column mt-5" >
              {!this.props.isSubproduct && !this.props.isSubproductEdit ?
                <button className="btn btn-primary font-weight-bold py-3 px-4 mb-4" onClick={this.submit}>Save &amp; Close</button>
                :
                <button className="btn btn-primary font-weight-bold py-3 px-4 mb-4" onClick={this.saveSubProduct}>Save Sub product</button>
              }
              <button className="btn btn-link" onClick={this.cancelForm}>Cancel</button>
            </div>
          </Form>
        </div>

        {this.state.showEditAttributeform &&
          <EditAttributeForm
            onClose={this.closeAttributeForm}
            selectedAttributeItem={this.state.selectedAttributeItem}
            setAttributeValues={(data) => {
              this.state.attributeValues.map(item => {
                if (item.id === this.state.selectedAttributeItem.id) {
                  item.attributeName = data.attributeName
                  item.attributeDescription = data.attributeDescription
                }
              })
              this.setState({ attributeValues: [...this.state.attributeValues], showEditAttributeform: false })
            }}
          />
        }


        {this.state.renderView && !this.state.editSubProductView &&
          <Add_Sub_Product
            closeBtn={this.closeForm}
            cancelForm={this.cancelForm}
            selectedProductId={this.props.selectedProductId}
            mainProduct={this.props.mainProduct || false}
            getSubProductDetails={this.getSubProductDetails}
            setSubProductList={(data) => {
              this.setState({
                addSubProductList: [...this.state.addSubProductList, data]
              })
            }}
            deleteSubProductItem={(data) => {
              this.deleteSubProductItem(data)
            }
            }
          />
        }
        {
          this.state.editSubProductView &&
          <Edit_Sub_Product
            closeBtn={this.closeForm}
            cancelForm={this.cancelForm}
            selectedSubProductItem={this.state.selectedSubProductItem}
            selectedProductId={this.props.selectedProductId}
            mainProduct={this.props.mainProduct || false}
            closeSubProductForm={this.closeSubProductForm}
            getSubProductDetails={this.getSubProductDetails}
            editSubProductDetails={(data) => {
              let newList = [];
              this.state.addSubProductList.map(item => {
                if (item.name === this.state.selectedSubProductItem.name) {
                  newList.push(data);
                }
                else {
                  newList.push(item);
                }
              })
              this.setState({
                addSubProductList: newList
              })
            }}
          />
        }
      </>
    )
  }
}


const DropDownAction = (props) => {

  let actionName;

  if (props.edit === "1") {
    actionName = "Set As Primary";
  }
  else if (props.edit === "2" || props.edit === '3') {
    actionName = "Edit";
  }

  const [subProductActionName, setSubProductActionName] = useState("-----");

  const handleActionSelect = (e) => {
    setSubProductActionName(e);
    props.setCurrentActionName(e);
  }
  return (
    <div className="d-flex flex-nowrap align-items-center">
      <Dropdown onSelect={handleActionSelect}>
        <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
          <span>
            {subProductActionName}
          </span>
          <ChevronDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu >

          <Dropdown.Item eventKey={actionName}>{actionName}</Dropdown.Item>
          <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>
      <div onClick={() => props.handleActionClick(props.item, props.edit)} className="bg-primary rounded-circle lesson-img-arrow flex-center mr-3 cursor-pointer" >
        <ChevronLeftIcon fillClass="fill-white" />
      </div>
    </div>
  )
}


const mapActionToProps = { showLoader, updateSelectedMenuItem };

const mapStateToProps = (state) => ({
  userData: state.userData,
  productAttributes: state.productAttributes
});

export default connect(mapStateToProps, mapActionToProps)(AddOrEditProduct);