import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { MdForum } from "react-icons/md";
import UserComment from "./UserComment";
import * as Utility from "utils/utility";
import { COMMENT_TOOL_BAR_OPTION } from "../../../../../config/constants";
import CommentTool from "./CommentTool";
import RichTextEditor from "../../../rich_text_editor";
import ReactQuill from "react-quill";
import moment from "moment";

export class Discussion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentTime: null,
      isEdit: false,
      isReply: false,
      commentData: "",
      formName: "add_primary_product",
    };
  }

  handleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit,
      commentData: this.props.commentData.description,
      isReply: false,
    });
  };

  handleReply = () => {
    this.setState({
      isEdit: false,
      isReply: !this.state.isReply,
    });
  };

  changeDescription = (data, id) => {
    var dataHash = this.state.commentData;
    dataHash = data;
    Utility.isRichTextEmpty(id, data, this.state.formName);
    console.log(dataHash);
    this.setState({
      commentData: dataHash,
    });
  };

  componentDidMount() {
    const updatedAt = new Date(this.props.commentData.created_at);
    // console.log(this.props.commentData.created_at)
    this.setState({
      commentTime: updatedAt.toLocaleString("en-US", {
        day: "numeric", // numeric, 2-digit
        year: "numeric", // numeric, 2-digit
        month: "long", // numeric, 2-digit, long, short, narrow
      }),
    });
  }

  render() {
    return (
      <>
        <div className="comment_container w-75 mx-auto" >
          {this.props.commentData.level===1 && <div className="comment_date">
            <p>{this.props.commentData.created_at && this.state.commentTime}</p>
          </div>}
          <div className="lesson_comment_container">
            {!this.state.isEdit ? (
              <UserComment
                handleEdit={this.handleEdit}
                handleReply={this.handleReply}
                {...this.state}
                {...this.props}
              />
            ) : (
              <div className="comment-input comment-inline-input">
                <RichTextEditor
                  id="comment"
                  name="comment"
                  placeholder="Start a Conversation ....."
                  data={this.state.commentData}
                  changeDescription={this.changeDescription}
                  isComment={true}
                  toolbar={COMMENT_TOOL_BAR_OPTION}
                />
                <CommentTool
                  purpose="Edit"
                  handleEdit={this.handleEdit}
                  Data = {this.props.commentData}
                  commentData={this.state.commentData}
                  getCommentData = {this.props.getCommentData}
                  changeDescription={this.changeDescription}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.isReply && (
          <div className="comment-input comment-inline-input w-75 mx-auto">
            <div className="reply-container">
              <RichTextEditor
                id="comment"
                name="comment"
                placeholder="Start a Conversation ....."
                data={this.state.commentData}
                changeDescription={this.changeDescription}
                isComment={true}
                toolbar={COMMENT_TOOL_BAR_OPTION}
              />

              <CommentTool
                purpose="Reply"
                {...this.props}
                parent_id ={this.props.commentData.id}
                handleReply = {this.handleReply}
                getCommentData = {this.props.getCommentData}
                commentData={this.state.commentData}
                changeDescription={this.changeDescription}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Discussion);
