import React, { useState } from 'react';
import { Form, Col, Button, Image, Row } from 'react-bootstrap';
import { Date, Utility } from 'utils';
import { Link } from 'react-router-dom';
import { FaSistrix } from 'react-icons/fa';
import { connect } from 'react-redux';
import { updateSelectedMenuItem } from 'shared/actions';
import Tooltip from '@material-ui/core/Tooltip';

const MenuList = (props) => {
  return (
    <Col
    xl={3}
    lg={4}
    xs={12}
    className={props.className || 'd-none h-100 ModalOpen side-menu-shadow overflow-hidden'}
    id={props.id || 'menu-list'}>
      <div className="d-block d-lg-none position-relative bg-white px-3 py-4 border-bottom back-button-sm">
        <Button variant="link" className="color-primary text-left align-items-center" onClick={() => {props.updateSelectedMenuItem({});}}><img className="width-10 align-baseline mr-3" src={require("assets/images/chevron_left.svg")} alt="left-icon" />
          <span className="display-7 font-bold" >Back to {props.selectedModule.name}</span>
        </Button>
      </div>
      <div className="h-100 position-relative">
        <div className="network-view h-100">
          {props.children}
        </div>
      </div>
    </Col>
  );
};

const mapActionToProps = { updateSelectedMenuItem };
const mapStateToProps = (state) => ({
  selectedModule: state.selectedModule
});

export default connect(mapStateToProps, mapActionToProps)(MenuList);

export const MenuTitle = props => (
  <span className="font-24-bold">
    {props.menuName}
  </span>
);

export const SearchBar = props => (
  <div className="item-search position-relative">
    <Form.Control type="text" className="shar-search" onKeyUp={props.handleKeyUp} placeholder={props.placeholder}/>
    <span className="icon-position"><FaSistrix/></span>
  </div>
);

export const ListItem = props => {
  const [menu, showMenu] = useState(false);

  const handleActions = (actionId, recordId) => {
    props.handleListItemActions(actionId, recordId);
    showMenu(false);
  };

  return (
    <li>
      <Link to={{}} className="text-networks">
        <div className="align-items-center member flex-nowrap">
          <div className="w-100 align-items-center flex-nowrap" onClick={() => props.selectListItem(props.item.id, props.userId)}>
            <div className="fix-width position-relative">
              <img src={!Utility.isEmpty(props.item.image_url) ? props.item.image_url : require("assets/images/users/user_no_image.png")} alt="client_image" className="round-circle-medium"/>
              { props.item.count &&
                <div className="round-circle-sm position-absolute">{props.item.count}</div>
              }
            </div>
            <div className="networks-name w-100">
              <div className="pl-2 align-items-center flex-nowrap">
                <Tooltip title={props.item.name}>
                  <span className="font-bold display-xl-6 display-7 text-truncate text-width">{props.item.name}</span>
                </Tooltip>
                { props.item.updated_at &&
                  <span className="pl-2 font-12 text-light-grey ml-auto">{Date.localTime(props.item.updated_at)}</span>
                }
              </div>
              <div className="pl-2">
              {/*
                <span className="font-14-medium">{props.item.description_heading}</span>
                <span className="font-14">{props.item.description}</span>
              */}
              </div>
            </div>
          </div>
          {
            !Utility.isEmpty(props.actionList) &&
            <span className="ml-auto d-block vertical-menu-option" onClick={() => { showMenu(!menu); }} >
              <Image className="menu-vertical" src={require("assets/images/icons/menu_vertical_icon.svg")} alt="menu_vertical_icon"/>
            </span>
          }
        </div>
      </Link>
      <div>
        {
          menu &&
          <Row className="no-gutters bg-white mx-1 mt-1 p-3 mb-0">
            {
              props.actionList.map((item, index) => {
                return (
                  <ActionItem
                    key={"list-item-action" + index}
                    item={item}
                    handleActions={handleActions}
                    recordId={props.item.recordId}
                  />
                );
              })
            }
          </Row>
        }
      </div>
    </li>
  );
};

const ActionItem = props => (
  <Col xs={6} className="p-0 align-items-center" onClick={() => props.handleActions(props.item.id, props.recordId)} >
    <span className="pr-2 line-height-1">
      <Image className="hx-20 approve-action-icon" src={require("assets/images/icons/" + props.item.iconName)} alt={props.item.name + " icon"}/>
    </span>
    <span className="display-xl-8 display-9 primary-link line-height-1">{props.item.name}</span>
  </Col>
);
