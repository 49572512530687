import { API_PATH } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import { AHURA_CLIENT_ID, ETHOS_CLIENT_ID } from '../config/constants';
import {
	EMAIL_REGEX_PATTERN,
	REQUEST_TYPES,
	UPPERCASE_PATTERN,
	LOWERCASE_PATTERN,
	NUMBER_PATTER,
	SPECIAL_CHAR_PATTERN,
	PASSWORD_LENGTH,
	VALID_STATUS_CODES,
	YOUTUBE_PATTERN,
	SALT_AND_IV_BYTE_SIZE,
	AES_IV,
	STORY_URL_PATTERN,
	URL_PATTERN,
	PUBLISHER_NETWORK_ID,
	SEARCH_MINIMUM_LENGTH,
	GIL_CLIENT_ID
} from 'config/constants';
import { FORM_ERRORS } from 'config/constants/form_errors';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';
import moment from 'moment';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
const CryptoJS = require('crypto-js');
const request = require('request');
const axios = require('axios');

export const sendRequest = (endPoint, methodType, params, callback) => {

	const options = {
		method: REQUEST_TYPES[methodType],
		url: API_PATH + endPoint,
		headers: {
			'content-type': 'application/json',
			'Authorization': `Bearer ${cookie.load('sessionToken')}`
		}
	};

	if (endPoint === '/product_categories') {
		options.headers.accesskey = process.env.REACT_APP_BASE_ACCESS_KEY;
		options.headers.secretkey = process.env.REACT_APP_BASE_SECRET_KEY;
	}

	if (methodType === 1 || (methodType === 4 && endPoint.includes('/order_items/'))) {
		options.qs = params;
	} else if ([2, 3].includes(methodType)) {
		if (params.qs && methodType === 3) {
			options.qs = params.qs;
			delete params.qs;
		}
		options.body = JSON.stringify(params);
	}

	request(options, (error, response, body) => {
		if (!response || (response && response.statusCode === 401)) {
			if (CLIENT_ID===AHURA_CLIENT_ID || CLIENT_ID===GIL_CLIENT_ID) {
				toast.error('Something went wrong', { containerId: 'public' });
			} 
			else if(CLIENT_ID ===ETHOS_CLIENT_ID){
				console.log("ERROR IN API");
			}
			else {
				clearSessionAndRedirectToHomePage();
			}
		} else if (response && VALID_STATUS_CODES.includes(response.statusCode)) {
			callback(error, response, body);
		} else {
			toast.error('Something went wrong', { containerId: 'public' });
		}
	});
	// window.location = '/forgot_otp';
};

export const isEmpty = (object) => {
	if (!object && object !== 0) {
		return true;
	}

	const className = object.constructor.name;
	let status = true;

	switch (className) {
		case 'String':
			status = object.trim().length === 0;
			break;

		case 'Number':
			status = false;
			break;

		case 'Array':
			status = object.length === 0;
			break;

		case 'Object':
			status = Object.keys(object).length === 0;
			break;

		default:
			status = true;
	}

	return status;
};

export const validateAndReturnFormData = (obj, formData, e, validateFields = [], formName = '') => {

	formData[e.target.name] = checkInputTypeAndReturnValue(e);

	if (validateFields.includes(e.target.name)) {
		validateFieldValue(obj, e.target.name, e.target.value, e.target.type, formName);
	}

	return formData;
};

export const validateFieldValue = (obj, refName, value = '', type, formName = '') => {
	let refs = null;
	let label = null;

	if (obj && obj.refs && obj.refs[refName] && obj.refs[refName].refs &&
		obj.refs[refName].refs[refName] && obj.refs[refName].refs[refName].refs &&
		obj.refs[refName].refs[refName].refs[refName] &&
		obj.refs[refName].refs[refName].refs[refName].refs &&
		obj.refs[refName].refs[refName].refs[refName].refs[refName]) {
		refs = obj.refs[refName].refs[refName].refs[refName].refs[refName];
	} else if (obj && obj.refs && obj.refs[refName] && obj.refs[refName].refs &&
		obj.refs[refName].refs[refName] && obj.refs[refName].refs[refName].refs &&
		obj.refs[refName].refs[refName].refs[refName]) {
		refs = obj.refs[refName].refs[refName].refs[refName];
	} else if (obj && obj.refs && obj.refs[refName] && obj.refs[refName].refs &&
		obj.refs[refName].refs[refName]) {
		refs = obj.refs[refName].refs[refName];
	} else if (obj && obj.refs && obj.refs[refName]) {
		refs = obj.refs[refName];
	} else if (obj && obj[refName] && obj[refName].refs && obj[refName].refs[refName]) {
		refs = obj[refName].refs[refName];
	} else if (obj) {
		refs = obj[refName];
	}

	if (refs && refs.hasOwnProperty('placeholder')) label = refs.placeholder;

	if (type === 'select-one') label = refs.options[0].text;

	if (!isEmpty(formName)) label = FORM_ERRORS[formName][refName];

	if (type === 'checkbox' && ((!refs && !refs.refs[refName].checked))) {
		showError(label, refName);
		return false;
	}

	if (type === 'tel' && isEmpty(value)) {
		showError(`${label} is a required field`, refName);
		return false;
	}

	if (type === 'number' && isEmpty(value)) {
		showError(`${label} should only be a number and is required field`, refName);
		return false;
	}

	if (isEmpty(value) && refName !== 'embedded_video_link' && refName !== 'storymap_url' && refName !== 'embedded_image_link') {
		prepareError(obj, refName, label);
		return false;
	}

	if (type === 'email' && !EMAIL_REGEX_PATTERN.test(value.toLowerCase())) {
		showError(`${label} should be a valid email address`, refName);
		return false;
	}

	if (refName === 'embedded_image_link' && !isEmpty(value) && !URL_PATTERN.test(value.toLowerCase())) {
		showError('Please enter the valid embedded image link', refName);
		return false;
	}

	if (refName === 'storymap_url' && !STORY_URL_PATTERN.test(value.toLowerCase()) && !isEmpty(value)) {
		showError('Please enter the valid story url', refName);
		return false;
	}

	if (refName === 'embedded_video_link' && !isYouTubeLink(value) && !isEmpty(value)) {
		showError('Embedded link should be a youtube URL', refName);
		return false;
	}

	showError('', refName);
	return true;
};

export const clearSessionAndRedirectToHomePage = () => {
	clearUserSession();
	window.location = '/';
};

export const showFormErrors = (obj, validateFields, formName = '') => {
	const inputs = document.querySelectorAll('input');
	let isFormValid = true;
	inputs.forEach((input) => {
		if (input.id !== "bio" && validateFields.includes(input.name) && !validateFieldValue(obj, input.name, input.value, input.type, formName)) {
			isFormValid = false;
		}
	});

	const selects = document.querySelectorAll('select');

	selects.forEach((select) => {
		if (validateFields.includes(select.name) && !validateFieldValue(obj, select.name, select.value, select.type, formName)) {
			isFormValid = false;
		}
	});
	const textArea = document.querySelectorAll('textarea');

	textArea.forEach((textArea) => {
		if (validateFields.includes(textArea.name) && !validateFieldValue(obj, textArea.name, textArea.value, textArea.type, formName)) {
			isFormValid = false;
		}
	});

	return isFormValid;
};

export const clearUserSession = () => {
	cookie.remove('sessionToken', { path: '/' });
};

export const createYearsData = () => {
	const start = 1900;
	const end = (new Date().getFullYear() - 16);
	const yearsData = [];

	for (let count = start; count <= end; count++) {
		const yearObj = {};
		yearObj['id'] = count.toString();
		yearObj['name'] = count.toString();
		yearsData.push(yearObj);
	}

	return yearsData;
};

export const validatePassword = (password) => {
	return {
		hasUpper: UPPERCASE_PATTERN.test(password),
		hasLower: LOWERCASE_PATTERN.test(password),
		hasNumber: NUMBER_PATTER.test(password),
		hasSpecial: SPECIAL_CHAR_PATTERN.test(password),
		lengthValid: (password.length >= PASSWORD_LENGTH)
	};
};

export const showPasswordValidation = (password) => {
	return (password.hasUpper && password.hasLower && password.hasNumber && password.hasSpecial && password.lengthValid);
};

export const encode64 = (string) => {
	return btoa(string);
};

export const decode64 = (string) => {
	return atob(string);
};

export const getAPIEndpointWithParams = (url, params) => {
	var re = new RegExp(Object.keys(params).join('|'), 'gi');

	url = url.replace(re, (matched) => {
		return params[matched];
	});

	return url;
};

export const isAllSocialLinkParamsExist = (searchHash) => {
	return searchHash.has('client_id') && searchHash.has('cs_id') && searchHash.has('smc_id');
};

const prepareError = (obj, refName, label) => {
	let validity = null;

	if (obj && obj.refs[refName]) {
		validity = obj.refs[refName].validity || obj.refs[refName].refs[refName].validity || obj.refs[refName].refs[refName].refs[refName].validity;
	}
	else if (obj && obj[refName])
		validity = obj[refName].refs[refName].validity;

	if (!validity.valid) {
		if (validity.valueMissing) {
			showError(`${label} is a required field`, refName);
		}
	}
};

const showError = (errorMessage, refName) => {
	const error = document.getElementById(`${refName}Error`);
	error.textContent = errorMessage;
};

const checkInputTypeAndReturnValue = (e) => {
	let value;

	if (e.target.type === 'checkbox') {
		value = e.target.checked;
	} else if (e.target.type === 'radio') {
		value = e.target.id;
	} else {
		value = e.target.value;
	}

	return value;
};

export const decryptMessages = (encryptedMessages, chatPrivateKey, selectedClientPrivateKey, userPrivateKey = '') => {
	if (chatPrivateKey !== null && isNotBlank(selectedClientPrivateKey) && isNotBlank(userPrivateKey)) {
		const senderPrivateKey = chatPrivateKey === '' ? userPrivateKey : chatPrivateKey;
		const receiverPrivateKey = (chatPrivateKey === selectedClientPrivateKey) ? userPrivateKey : selectedClientPrivateKey;

		return decryptAllMessages(encryptedMessages, senderPrivateKey, receiverPrivateKey);
	}
};

export const decryptAllMessages = (encryptedMessages, senderPrivateKey, receiverPrivateKey, groupPrivateKey = '') => {

	let sharedKey;
	if (groupPrivateKey === '')
		sharedKey = senderPrivateKey.slice(0, 8) + receiverPrivateKey.slice(0, 8);
	else
		sharedKey = groupPrivateKey;

	if (typeof encryptedMessages === 'string') return decryptSingleMessage(encryptedMessages, sharedKey);

	encryptedMessages.forEach((item) => {
		item.message = decryptSingleMessage(item.message, sharedKey);
	});

	return encryptedMessages;
};

export const decryptSingleMessage = (message, sharedKey) => {
	let decryptMessage;
	try {
		sharedKey = CryptoJS.enc.Utf8.parse(sharedKey);
		const encrypted = message.substring(48);

		const decrypted = CryptoJS.AES.decrypt(encrypted, sharedKey, {
			iv: CryptoJS.enc.Utf8.parse(message.substr(32, 16)),
			padding: CryptoJS.pad.Pkcs7,
			mode: CryptoJS.mode.CBC
		});

		decryptMessage = decrypted.toString(CryptoJS.enc.Utf8);
	} catch (e) {
		console.log(e);
	}

	return decryptMessage;
};

/* Encrytion of message */
export const encryptedMessage = (message, senderPrivateKey, receiverPrivateKey, groupPrivateKey = '') => {
	const salt = CryptoJS.lib.WordArray.random(SALT_AND_IV_BYTE_SIZE);
	let sharedKey = '';
	if (groupPrivateKey === '') {
		sharedKey = CryptoJS.enc.Utf8.parse(senderPrivateKey.slice(0, 8) + receiverPrivateKey.slice(0, 8));
	}
	else {
		sharedKey = CryptoJS.enc.Utf8.parse(groupPrivateKey);
	}

	const encrypted = CryptoJS.AES.encrypt(message.trim(), sharedKey, {
		iv: CryptoJS.enc.Utf8.parse(AES_IV),
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC
	});

	return (salt.toString() + AES_IV + encrypted.ciphertext.toString(CryptoJS.enc.Base64));
};

export const isNotBlank = (val) => {
	return (val !== null && val !== undefined && val.trim() !== '');
};

export const getPlainTextFromRichText = (richText) => {
	const plainText = richText && richText.replace(/(<([^>]+)>)/g, '');
	return plainText;
};

export const isYouTubeLink = (link) => {
	const match = link.match(YOUTUBE_PATTERN);
	return (match && match[2].length === 11) ? true : false;
};

export const changeFirstCharUC = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export function ProgressUpdate(file, progressEvent){
	const percentage = (progressEvent.loaded/file.size)*100;
	const upload_progress = window.document.getElementById("upload-file-progress");
	if(upload_progress !== null)
		upload_progress.innerText = `Uploading ${parseInt(percentage)}%`;
}

export const uploadFile = async (file, signedURL) => {
	if (!isEmpty(signedURL)) {
		const isPDF = CLIENT_ID === GIL_CLIENT_ID ? file.type === 'application/pdf' || "application/msword" || '.doc' || '.docx' || "text/plain" || "application/vnd.openxmlformats-officedocument.wordprocessingml.document"||"application/wps-office.docx" : file.type === 'application/pdf';
		const options = isPDF ? { headers: { 'Content-Type': file.type, 'x-amz-acl': 'public-read', 'Content-Disposition': 'attachment' }, onUploadProgress: progressEvent => ProgressUpdate(file, progressEvent)}
			: { headers: { 'Content-Type': file.type, 'x-amz-acl': 'public-read' }, onUploadProgress: progressEvent => ProgressUpdate(file, progressEvent) };
		const res = await axios.put(signedURL, file, options)
			.then(() => { return true; }).catch(() => { return false; });

		if (!res) {
			toast.error('Something went wrong', { containerId: 'private' });
			return;
		}

		return signedURL.substr(0, signedURL.indexOf("?X-Amz-Algorithm="));
	}
};

export const isRichTextEmpty = (fieldName = '', richText = '', formName = '') => {
	const text = getPlainTextFromRichText(richText).trim();
	const element = document.getElementById(fieldName + 'Error');
	console.log(element)
	if (text.length === 0) {
		element.textContent = FORM_ERRORS[formName][fieldName];
		return true;
	}
	element.textContent = '';
	return false;
};

export const isFilePathEmpty = (fieldName, filePath, formName = '') => {
	const errorMessage = FORM_ERRORS[formName][fieldName];
	const element = document.getElementById(fieldName + 'Error');

	if (isEmpty(filePath)) {
		element.textContent = errorMessage;
		return true;
	}

	element.textContent = '';
	return false;
};

export const filterCrmModules = (crmModules, userData) => {
	if (parseInt(userData.client_id) !== PUBLISHER_NETWORK_ID) {
		crmModules = crmModules.filter((item) => { return item.id !== 8; });
	}

	return crmModules;
};

export const updateFavIconAndTitle = (title, favIcon) => {
	const appTitle = document.getElementById('sm-title');
	const appFav = document.getElementById('sm-fav-icon');

	if (appTitle) appTitle.textContent = title;
	if (appFav) appFav.href = favIcon;
};

export const toLocaleString = (value) => {
	return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const showComponent = (isShow, componentId) => {
	const source = document.getElementById(componentId);
	if (source) {
		if (isShow) {
			source.classList.remove('d-none');
			source.classList.add('d-block');
		} else {
			source.classList.remove('d-block');
			source.classList.add('d-none');
		}
	}
};

export const handlePayoutOverlay = (sourceId, isOpen) => {
	const payoutSchedule = document.getElementById('payout-schedule');
	const source = document.getElementById(sourceId);

	if (payoutSchedule && source) {
		source.classList.remove('d-lg-block');
		payoutSchedule.classList.remove(isOpen ? 'd-none' : 'd-block');
		payoutSchedule.classList.add(isOpen ? 'd-block' : 'd-none');
		source.classList.remove(isOpen ? 'd-block' : 'd-none');
		source.classList.add(isOpen ? 'd-none' : 'd-block');
		payoutSchedule.scrollTo(0, 0);
	}
};

export const handleAgreement = (isConfirmed, sourceId) => {
	const checkbox = document.getElementById('agree-terms');
	checkbox.checked = isConfirmed;

	handlePayoutOverlay(sourceId, false);
	handleTermsError(isConfirmed);
};

export const handleTermsError = (val) => {
	const errorElement = document.getElementById('terms-error');
	errorElement.textContent = val ? '' : 'Please agree with Payout Schedule';
};

export const isTagsValid = (tags, formName) => {
	const ele = document.getElementById('tagsError');

	if (isEmpty(ele)) {
		if (tags.match(SPECIAL_CHAR_PATTERN)) {
			ele.textContent = FORM_ERRORS[formName]['tags'];
		} else {
			ele.textContent = '';
		}
	}
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: contentType });
};

export const splitImageDataAndConvertToBlob = (croppedImage) => {
	const block = croppedImage.split(';');
	const contentType = block[0].split(':')[1];
	const realData = block[1].split(',')[1];

	return b64toBlob(realData, contentType);
};

export const setDfaultDropdown = (dropDownId, value) => {
	if (document.getElementById(dropDownId))
		document.getElementById(dropDownId).value = value;
};

export const pushToArray = (arr, obj) => {
	const index = arr.findIndex((e) => e.id === obj.id);

	if (index === -1) {
		arr.push(obj);
	} else {
		arr[index] = obj;
	}
	return arr;
};

export const getSelectedOptions = (ev) => {
	const arr = [];
	const inputId = ev.target.id;
	const inputType = ev.target.type;
	const inputName = ev.target.name;
	switch (inputType) {
		case 'number':
			arr.push({ id: ev.target.id, value: ev.target.value });
			return arr;
			break;
		case 'range':
			arr.push({ id: ev.target.id, value: ev.target.value });
			return arr;
			break;
		case 'radio':
			const selectedRadio = document.getElementById(inputId);
			selectedRadio && arr.push({ id: selectedRadio.id.split("_")[0], value: selectedRadio.value })
			return arr;
			break;
		case 'checkbox':
			const selectedCheckedBoxes = getCheckedBoxes(inputName);
			selectedCheckedBoxes && selectedCheckedBoxes.map(e => {
				arr.push({ id: e.id.split("_")[0], value: e.value });
			});
			return arr;
			break;
		default:
	}
};

export const getCheckedBoxes = (chkboxName) => {
	var checkboxes = document.getElementsByName(chkboxName);
	var checkboxesChecked = [];
	for (var i = 0; i < checkboxes.length; i++) {
		if (checkboxes[i].checked) {
			checkboxesChecked.push(checkboxes[i]);
		}
	}
	return checkboxesChecked.length > 0 ? checkboxesChecked : null;
};

export const checkSearchCondition = (value) => {
	if (value.length >= SEARCH_MINIMUM_LENGTH || value.length === 0) return value.trim();

	return '';
};

export const getElementById = (id) => {
	return document.getElementById(id);
};

export const deepClone = (object) => {
	return JSON.parse(JSON.stringify(object));
};

export const setLocalStorage = (key, data) => {
	return localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorage = (key) => {
	const ls = JSON.parse(localStorage.getItem(key));
	const localData = (ls == null) ? [] : ls;
	return localData;
};

export const pwaModeEnabled = () => {
	if (GIL_CLIENT_ID !== CLIENT_ID) // pwa is only for GIL
		return false;
	else{
		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
			if((window.screen.width === 1024 && window.screen.height === 1366) || (window.screen.width === 1366 && window.screen.height === 1024))
				return false;
			else
				return true;	
		}
		else{
			return false;
		}
		// return (
		// 	window.navigator.standalone ||
		// 	window.matchMedia('screen and (max-width: 991px)').matches
		// );
	}
};

export const pwaModeEnabledNew = () => {
	return (window.matchMedia('(display-mode: standalone)').matches);
};

export const isResponsive = () => {
	return (window.matchMedia('screen and (max-width: 991px').matches);
};

export const capitalize = (string) => {
	if (!string) return null;
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const debounce = (func, wait) => {
	let timeout;
	return function (...args) {
		const context = this;
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			timeout = null;
			func.apply(context, args);
		}, wait);
	};
};

export const getValueFromEventByType = (ev) => {
	switch (ev.target.type) {
		case 'checkbox':
			return ev.target.checked;
		default:
			return ev.target.value;
	}
};

export const splitString = (string, len, dots = "...") => {
	return (string.length > len) ? string.substr(0, len - dots.length) + dots : string;
};

export const pinUnpinNav = (elementId) => {
	let lastKnownScrollY = 0;
	let currentScrollY = 0;
	let ticking = false;
	const idOfHeader = 'header';
	const idOfFooter = 'pwaFooterMenu';
	const callout = document.getElementById(elementId);

	const classes = {
		header: {
			pinned: 'header-pin',
			unpinned: 'header-unpin',
		},
		footer: {
			pinned: 'footer-pin',
			unpinned: 'footer-unpin',
		}
	};

	const onScroll = () => {
		currentScrollY = callout.scrollTop;
		requestTick();
	};

	const requestTick = () => {
		if (!ticking) {
			requestAnimationFrame(update);
		}
		ticking = true;
	};

	const update = () => {
		const eleHeader = document.getElementById(idOfHeader);
		const eleFooter = document.getElementById(idOfFooter);

		if (currentScrollY <= lastKnownScrollY) {
			pin(eleHeader, 'header');
			pin(eleFooter, 'footer');
		} else if (currentScrollY > lastKnownScrollY) {
			unpin(eleHeader, 'header');
			unpin(eleFooter, 'footer');
		}
		lastKnownScrollY = currentScrollY;
		ticking = false;
	};

	const pin = (element, classKey) => {
		if (element && element.classList.contains(classes[classKey].unpinned)) {
			element.classList.remove(classes[classKey].unpinned);
			element.classList.add(classes[classKey].pinned);
		}
	};

	const unpin = (element, classKey) => {
		if (element && element.classList.contains(classes[classKey].pinned) || !element.classList.contains(classes[classKey].unpinned)) {
			element.classList.remove(classes[classKey].pinned);
			element.classList.add(classes[classKey].unpinned);
		}
	};

	callout.addEventListener('scroll', () => {
		onScroll();
	});
};

export const trimHtmlTags = (string) => {
	return string.replace(/<[^>]*>?/gm, '');
};

export const returnDuration = (date) => {
	var now = moment(new Date()); //todays date
	var end = moment(date); // another date

	const getDiffByTimeframe = (timeframe) => {
		return parseInt(now.diff(end, timeframe));
	};

	if (getDiffByTimeframe('minutes') <= 60)
		return getDiffByTimeframe('minutes') + 'm ago';
	if (getDiffByTimeframe('hours') <= 24)
		return getDiffByTimeframe('hours') + 'h ago';
	if (getDiffByTimeframe('days') <= 7)
		return getDiffByTimeframe('days') + 'd ago';
	if (getDiffByTimeframe('weeks') <= 5)
		return getDiffByTimeframe('weeks') + 'w ago';
	if (getDiffByTimeframe('months') <= 12)
		return getDiffByTimeframe('months') + 'mo ago';
	if (getDiffByTimeframe('years') > 12)
		return getDiffByTimeframe('years') + 'y ago';
	return '';
};

export const kFormatter = (num) => {
	return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
};


export const nFormatter = (number) => {
	var SI_POSTFIXES = ["", "k", "M", "G", "T", "P", "E"];

	// what tier? (determines SI prefix)
	var tier = Math.log10(Math.abs(number)) / 3 | 0;

	// if zero, we don't need a prefix
	if (tier == 0) return number;

	// get postfix and determine scale
	var postfix = SI_POSTFIXES[tier];
	var scale = Math.pow(10, tier * 3);

	// scale the number
	var scaled = number / scale;

	// format number and add postfix as suffix
	var formatted = scaled.toFixed(1) + '';

	// remove '.0' case
	if (/\.0$/.test(formatted))
		formatted = formatted.substr(0, formatted.length - 2);

	return formatted + postfix;
};

export const compareValues = (key, order = 'desc') => {
	return function innerSort(a, b) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			return 0;
		}

		const varA = (typeof a[key] === 'string')
			? a[key].toUpperCase() : a[key];
		const varB = (typeof b[key] === 'string')
			? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return (
			(order === 'desc') ? (comparison * -1) : comparison
		);
	};
};

export const handleNumber = (ev) => {
	var theEvent = ev || window.event;
	var key = theEvent.keyCode || theEvent.which;
	key = String.fromCharCode(key);
	var regex = /[0-9]|\./;

	if (!regex.test(key)) {
		theEvent.returnValue = false;
		if (theEvent.preventDefault) theEvent.preventDefault();
	}
};

export const handleAlphaNumeric = (ev) => {
	var theEvent = ev || window.event;
	var key = theEvent.keyCode || theEvent.which;
	key = String.fromCharCode(key);
	var regex = /^[a-z0-9]+$/i;

	if (!regex.test(key)) {
		theEvent.returnValue = false;
		if (theEvent.preventDefault) theEvent.preventDefault();
	}
};

export const getPlanString = (days) => {
	switch (days) {
		case 28:
			return "monthly";
		case 29:
			return "monthly";
		case 30:
			return "monthly";
		case 90:
			return "quarterly";
		case 180:
			return "half yearly";
		case 365:
			return "yearly";
		default:
			return days + "days";
	}
};

export const paintUptoTwoDecimalPlaces = (num) => {
	const number = Number(num);
	if ((number % 1 != 0))
		return (Math.round(parseFloat(num) * 100) / 100).toFixed(2);
	else
		return number;
};

export const getQuestionType = (type) => {
	switch (type) {
		case 1:
			return 'Multiple Choice';
		case 2:
			return 'True Or False';
		case 3:
			return 'Essay';
		default:
			break;
	}
};

export const getAlphabet = (index) => {
	let alphabet;
	switch (index) {
		case 0:
			alphabet = "A";
			break;
		case 1:
			alphabet = "B";
			break;
		case 2:
			alphabet = "C";
			break;
		case 3:
			alphabet = "D";
			break;
	}
	return alphabet;
}

export function loadState(key) {
	try {
		const stringState = localStorage.getItem(key);
		let decryptedState = CryptoAES.decrypt(stringState.toString(), AES_IV);
		decryptedState = decryptedState.toString(CryptoENC);

		return JSON.parse(decryptedState);
	} catch (err) {
		return undefined;
	}
}

export function saveState(data, key) {
	try {
		const stringState = JSON.stringify(data);
		const encryptedState = CryptoAES.encrypt(stringState, AES_IV);
		localStorage.setItem(key, encryptedState);
	} catch (err) {
		console.log(err);
	}
}

export const testSaveState = (password) => {
	try {
		const encrypt = CryptoAES.encrypt(JSON.stringify(password), AES_IV).toString();
		return encrypt;
	} catch (err) {
		console.log(err);
	}
}

export function testloadState(encryPassword) {
	try {
		let decryptedState = CryptoAES.decrypt(encryPassword, AES_IV);
		decryptedState = JSON.parse(decryptedState.toString(CryptoENC));
		return decryptedState;
	} catch (err) {
		return undefined;
	}
}


export const TimeZoneConversion = (time) => {
	time = (new Date(time))
	time = (new Date(time).setHours(new Date(time).getHours()-6))
	return time
  }