import React, { useState } from 'react';
import { Image, Dropdown } from 'react-bootstrap';
import { Utility } from 'utils';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronDownIcon } from 'shared/icons/icons';
import Avatar from 'react-avatar';

export default function TableRowsComm(props){
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedItem, setSelectedItem] = useState({});

  const returnDecryptedMessage = (data) => {

    const senderPrivateKey = data.chatPrivateKey === '' ?
                             props.userData.private_key :
                             data.chat_private_key;
    const receiverPrivateKey = (data.chat_private_key === data.private_key) ?
                              props.userData.private_key :
                              data.private_key;

    if(!Utility.isEmpty(senderPrivateKey) && !Utility.isEmpty(receiverPrivateKey)){
      const sharedKey = senderPrivateKey.slice(0, 8) + receiverPrivateKey.slice(0, 8);
      const actualMessage = Utility.decryptSingleMessage(data.latest_message, sharedKey);
      return (actualMessage.length > 60 ?
              actualMessage.substr(0, 60) + '...' : actualMessage);
    } else return '';
  };

  const handleDropdown = (evKey, item) => {
    setSelectedOption(evKey);
    setSelectedItem(item);
    if(evKey !== 'respond')
      props.onActionClick(evKey, item, props.selectedTab);
  };

  return (
    <>
     <tbody>
     {
       props.data &&
       props.data.map((item, index) => {
        return(
          <tr key={index} className={(item.is_read || (props.selectedTab === 'message' && item.from_user_client_id === props.userData.id)) ? 'read' : `unread`}>
            { item.heading &&
              <td>
                <Link to={{
                  pathname: `/campaign/${item.campaign_id}`,
                }}>
                  <span className="text-dark">{item.heading}</span>
                </Link>
              </td> }
            <td className="td-username" >
              <div className="align-items-center" >
                {
                  props.selectedTab === 'comments' ?
                  <Link to={{
                    pathname: `/profile/${item.user_client_id}`,
                    state: { otherUserProfile: true },
                  }}>
                     {(!Utility.isEmpty(item.profile_image_url) || !Utility.isEmpty(item.notification_image)) ? (
                      <Image
                      className="registrant-img"
                      src={item.profile_image_url || item.notification_image}
                      alt="image"
                    />
                    ) : (
                      <Avatar
                        name={item.user_name}
                        round={true}
                        size={'28'}
                        textSizeRatio={1.75}
                        color='#007bb3'
                        maxInitials={1}
                      />
                    )}
                   
                  </Link> :
                  <>
                    {(!Utility.isEmpty(item.profile_image_url) || !Utility.isEmpty(item.notification_image)) ? (
                      <Image
                      className="registrant-img"
                      src={item.profile_image_url || item.notification_image}
                      alt="image"
                    />
                    ) : (
                      <Avatar
                        name={item.user_name}
                        round={true}
                        size={'28'}
                        textSizeRatio={1.75}
                        color='#007bb3'
                        maxInitials={1}
                      />
                    )}
                  </>
                }
                <span className="font-weight-bold ml-2" >{item.user_name}</span>
              </div>
            </td>
            <td>{returnDecryptedMessage(item) ||
              !Utility.isEmpty(item.notification_message) && Utility.trimHtmlTags(item.notification_message) || item.text}
            </td>
            <td className="td-timestamp" >
              {item.duration && Utility.returnDuration(item.duration) || item.created_at && Utility.returnDuration(item.created_at)}</td>
            <td className="action-td-chat">
              <div className="d-flex">
                {
                  <Dropdown>
                    <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                      <span>
                        {item === selectedItem ? selectedOption : '------'}
                      </span>
                      <ChevronDownIcon/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                      { props.actions.map(e => {
                        if(e.toLowerCase() === 'mark as read' && item.is_read)
                          return '';
                        else
                          return <Dropdown.Item
                          key={e}
                          eventKey={e}
                          onSelect={(evKey) => handleDropdown(evKey, item)}
                          >{e}</Dropdown.Item>;
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                }

                { (selectedOption.toLowerCase() === 'respond') &&
                  (item === selectedItem) &&
                  <div
                    className="chat-activator"
                    onClick={() => props.handleClick(item)}>
                    <ChevronLeftIcon fillClass="fill-white" />
                  </div>
                }
              </div>
            </td>
          </tr>
        );
       })
     }
     </tbody>
    </>
  );
}
