import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaBookReader } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import {
  updateIsCourseDropdown,
  updateIsCourseSidebar,
  updateSeeAll
} from "../../../actions";
import { CircleFilled } from "../../../icons/icons";

class CourseHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  handleStartCourse = (e) => {
    e.preventDefault();
    this.props.updateIsCourseDropdown(true);
    this.props.updateIsCourseSidebar(true);
  };
  render() {
    // console.log('Hi', this.props.isCourseSidebar);
    return (
      <div className="d-flex coursePageHeader">
        <div className="d-flex bd-highlight pt-1 pb-1 w-25 align-items-baseline">
          <div className="p-1 py-2 bd-highlight">
            <Link
              to={"/home"}
              onClick={() => {
                this.props.updateSeeAll({
                  isOpen: 'courses',
                  calledFrom: 'courses',
                });
              }}
            >
              <AiOutlineArrowLeft
                style={{ width: "20px", height: "20px" }}
                className="CourseHeaderIcon"
              />
            </Link>
          </div>
          <div className="p-1 py-2 bd-highlight">
            <FaBookReader
              style={{ width: "20px", height: "20px" }}
              className="CourseHeaderIcon"
            />
          </div>
          <div className="p-1 py-1  bd-highlight">
            <h6 className="pt-1 courseHeaderHeading">
              {this.props.courseDetails[0] !== undefined &&
                this.props.courseDetails[0].heading}
            </h6>
          </div>
        </div>
        <div
          className="d-flex bd-highlight pt-1 pb-1 px-1 w-75 align-items-center"
          style={this.props.isLesson ? { borderLeftStyle: "ridge" } : {}}
        >
          <div className="ps-1 bd-highlight">
            {this.props.isLesson && (
              <div className="d-flex align-items-center">
                {" "}
                <CircleFilled color={"lightgrey"} />
                <h5 className="d-inline p-1 pt-2">{this.props.pageHeading}</h5>
              </div>
            )}
          </div>

          {this.props.activePanal === "lesson" ? (
            <div className="ml-auto bd-highlight mr-2">
              <HiDotsHorizontal />
            </div>
          ) : (
            <div className="ml-auto bd-highlight mr-2">
              <button
                onClick={this.handleStartCourse}
                className="btn btn-primary CourseHeaderBtn"
              >
                Start Course
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapActionToProps = {
  updateIsCourseDropdown,
  updateIsCourseSidebar,
  updateSeeAll
};

export default connect(null, mapActionToProps)(CourseHeader);
