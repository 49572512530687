import React, { Component } from 'react';
import { Utility } from 'utils';
import { toast } from 'react-toastify';
import { DELETE_TAGS, SAVE_SELECTED_TAG } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';

export default class EditPassport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      selectedTagsIds: []
    };
  }

  componentDidMount = () => {
    const data = {};
    const selectedTagsIds = [];
    data[this.props.tagByCategories.id] = [];

    this.props.passportData.forEach((item) => {
      if (item.id === this.props.tagByCategories.id) {
        if (!Utility.isEmpty(item.Tags)) {
          item.Tags.forEach((tag) => {
            selectedTagsIds.push(tag.id);
            data[this.props.tagByCategories.id].push(tag.id);
          });
        }
      }
    });

    this.setState({ selectedTagsByCategoryId: data, selectedTagsIds });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.tagByCategories !== this.props.tagByCategories) {
      const data = {};
      const selectedTagsIds = [];
      data[this.props.tagByCategories.id] = [];

      this.props.passportData.forEach((item) => {
        if (item.id === this.props.tagByCategories.id) {
          if (!Utility.isEmpty(item.Tags)) {
            item.Tags.forEach((tag) => {
              selectedTagsIds.push(tag.id);
              data[this.props.tagByCategories.id].push(tag.id);
            });
          }
        }
      });

      this.setState({ selectedTagsByCategoryId: data, selectedTagsIds });
    }
  }

  selectTag = (id, categoryId) => {
    const tags = [...this.state.selectedTagsIds];
    const data = { ...this.state.selectedTagsByCategoryId };

    if (!tags.includes(id)) {
      tags.push(id);
      data[categoryId].push(id);
    }
    else {
      const i = tags.indexOf(id);
      const check = data[categoryId].indexOf(id);
      if (i > -1) tags.splice(i, 1);
      if (check > -1) data[categoryId].splice(check, 1);
    }

    if (data[categoryId].length > 3) {
      const i = tags.indexOf(id);
      const check = data[categoryId].indexOf(id);
      if (i > -1) tags.splice(i, 1);
      if (check > -1) data[categoryId].splice(check, 1);
      toast.error('You can only select upto 3 answers', { containerId: 'public' });
    }
    else this.setState({ selectedTagsIds: tags, selectedTagsByCategoryId: data });
  }

  saveTags = () => {
    const previouslyTagsIds = [];
    const unSelectedTagsIds = [];
    const newSelectedTagsIds = [];

    this.props.passportData.forEach((item) => {
      if (item.id === this.props.tagByCategories.id) {
        if (!Utility.isEmpty(item.Tags)) {
          item.Tags.forEach((tag) => {
            previouslyTagsIds.push(tag.id);
          });
        }
      }
    });

    previouslyTagsIds.forEach((item) => {
      if (!this.state.selectedTagsIds.includes(item)) unSelectedTagsIds.push(item);
    });

    this.state.selectedTagsIds.forEach((item) => {
      if (!previouslyTagsIds.includes(item)) newSelectedTagsIds.push(item);
    });

    if (!Utility.isEmpty(newSelectedTagsIds)) {
      const params = this.createParams(newSelectedTagsIds);
      this.saveSelectedTags(params);
    }
    if (!Utility.isEmpty(unSelectedTagsIds)) {
      this.deleteUnSelectedTags(unSelectedTagsIds);
    }
  }

  saveSelectedTags = (params) => {
    Utility.sendRequest(SAVE_SELECTED_TAG, 2, { data: params, clientId: CLIENT_ID }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'public' });
      } else {
        this.props.getAllCategories(this.props.userData);
        this.props.closeCallOut();
        this.props.getUserBadges(this.props.userData);
      }
    });
  }

  createParams = (selectedTags) => {
    const params = [];

    selectedTags.forEach((item) => {
      const data = {
        user_client_id: this.props.userData.id,
        is_active: true,
        created_by: this.props.userData.user_id,
        updated_by: this.props.userData.user_id
      };

      data['tag_id'] = item;
      params.push(data);
    });

    return params;
  }

  deleteUnSelectedTags = (ids) => {
    const params = {
      tags_id: ids,
      user_client_id: this.props.userData.id,
    };

    Utility.sendRequest(DELETE_TAGS, 3, params, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'public' });
      } else {
        this.props.getAllCategories();
        this.props.closeCallOut();
      }
    });
  }

  render() {
    return (
      <>
      {
        !Utility.isEmpty(this.props.tagByCategories) &&
        <>
          <div className="display-8 font-semi-bold">
            {this.props.tagByCategories.name}
          </div>
          <div className="passport-items-selector">
          <div className="passport-select-items d-flex flex-wrap mx-auto mw-100">
          {
            !Utility.isEmpty(this.props.tagByCategories.Tags) &&
            this.props.tagByCategories.Tags.map((item, i) => {
              return (
                <div className="passport-select-set passport-select-set-inner" key={i}>
                  <input
                    type="checkbox"
                    id={item.id}
                    onClick={() => this.selectTag(item.id, item.category_id)}
                    checked={this.state.selectedTagsIds.includes(item.id) ? 'checked' : ''}
                  />
                  <label
                    htmlFor={item.id}
                    className="passport-item passport-item-inner py-2 px-3 align-items-center justify-content-center font-bold display-8"
                  >
                    {item.tag_name}
                  </label>
                </div>
              );
            })
          }
          </div>
          </div>
        </>
      }
      <div className="text-right">
        <button
          className="btn btn-primary btn-primary-pwa mt-5 font-14 px-4"
          onClick={this.saveTags}
        >
          SAVE
        </button>
      </div>
      </>
    );
  }
}
