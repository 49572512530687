import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { PRODUCTS, SUBPRODUCTS } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import * as Utility from 'utils/utility';
import { ScreenViewShop } from 'shared/components/templates/screen_view';
import { CloseIconSharp } from 'shared/icons/icons';
import Sub_productlist from './sub_ProductList';
import AddOrEditProduct from './add_or_edit_products';
export default class ShopHandler extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'tab2',
      parentProductId: null,
      parentProductCategoryId: null,
      productList: [],
      hiddenProductList: [],
      isListShown: true,
      selectedProductDetails: {},
      productId: null,
      productTypeId: null,
      primaryProductId: null,
      isClicked: false,
      product_type_name: "Type",
      subProductListing: [],
      attributeValues: [],
      allColors: [],
      allImages: [],
    };
  }
  SubProductDisplayCount = 0;

  componentDidMount = () => {
    if (this.props.selectedProductId) {
      this.getMyProduct();
      this.getSubProductDetails();
    }

    if (this.props.editFromMain === true) {
      this.showProductList('tab2', this.props.selectedProductId, this.props.selectedProductCategoryId);
    }
    else
      this.showProductList('tab1', this.props.selectedProductId, this.props.selectedProductCategoryId);
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  getSubProductList = (parentProductId, isHidden = false) => {
    const params = {};
    this.props.showLoader(true);

    if (isHidden) params.is_hidden = isHidden;

    Utility.sendRequest(PRODUCTS + '/' + parentProductId, 1, params, (err, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);

      if (!body.error) {
        if (!isHidden) this.setState({ productList: body.sub_products });
        else {
          this.setState({ hiddenProductList: body.sub_products });
        }
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  deleteProductItem = (productItem, index) => {
    this.props.showLoader(true);

    Utility.sendRequest(PRODUCTS + '/' + productItem, 4, {}, (err, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);

      if (!body.error) {
        const list = this.state.productList;
        list.splice(index, 1);

        this.setState({ productList: list });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  hideProductItem = (productItem, index) => {
    const params = { is_hidden: productItem.is_hidden ? false : true };

    Utility.sendRequest(PRODUCTS + '/' + productItem.id, 3, params, (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        const hiddenProductList = this.state.hiddenProductList;
        const productList = this.state.productList;
        productItem.is_hidden = params.is_hidden;

        this.props.showLoader(false);

        if (params.is_hidden) {
          hiddenProductList.push(productItem);
          productList.splice(index, 1);
        } else {
          hiddenProductList.splice(index, 1);
          productList.push(productItem);
        }


        this.setState({ hiddenProductList, productList });
        toast.success(body.message, { containerId: 'private' });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  editProductDetails = (productId, productTypeId, editPrimaryProduct) => {
    editPrimaryProduct ? this.props.editComponent(null) : this.props.editComponent('edit');
    this.setState({ isListShown: false, editPrimaryProduct: editPrimaryProduct, productTypeId });
    this.props.editProductItem(productId, productTypeId);

    if (productTypeId === 1) {
      this.setState({ parentProductId: null });
    } else if (productTypeId === 2) {
      if (this.state.parentProductId === null) {
        this.setState({ parentProductId: this.props.primaryProductId });
      }
    }
  }

  showProductList = (selectedTab, parentProductId = null, parentProductCategoryId = null) => {
    if (this.props.primaryProductId === null) this.props.updateProductID(parentProductId);
    this.setState({
      parentProductId: (this.state.productTypeId === 1 && parentProductId === null) ? null : parentProductId,
      selectedTab,
      parentProductCategoryId: Utility.isEmpty(parentProductCategoryId) ? this.state.parentProductCategoryId : parentProductCategoryId,
      isListShown: true,
    }, () => {
      if (this.props.edit === 'edit') {
        this.getMyProduct();
        this.getSubProductDetails();
      }
    });
  }

  closeForm = () => {
    if (this.props.buttonClickState) {
      this.props.closeForm();
      this.props.updateProductID(null);
    } else {
      this.props.editComponent('edit');
      this.setState({ isListShown: true });

    }
    if (this.state.isListShown && this.props.edit === 'edit') {
      this.props.updateProductID(null);
      this.props.closeForm();
    }
  }

  getMyProduct = () => {
    const id = this.props.selectedProductId;
    const params = {
      module_type_id: 8,
      user_client_id: this.props.userData.id,
      is_hidden: false,
    };

    Utility.sendRequest(PRODUCTS + '/' + id, 1, params, (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.getSubProductDetails();

        if (!Utility.isEmpty(body.additional_details)) {
          let allAttributes = JSON.parse(body.additional_details)
          this.setState({ attributeValues: allAttributes })
        }
        if (!Utility.isEmpty(body.all_colors)) {
          let allcolor = JSON.parse(body.all_colors)
          this.setState({ allColors: allcolor });
        }

        if (!Utility.isEmpty(body.all_images)) {
          let allImages = JSON.parse(body.all_images)
          this.setState({ allImages: allImages })
        }
        if (!Utility.isEmpty(body.categories)) {
          let prodtypeId = body.categories[0].CategoryProducts.product_type_id
          const currentProductId = this.props.allProductTypes.filter(x => x.id === prodtypeId)
          if (!Utility.isEmpty(currentProductId))
            this.setState({ product_type_name: currentProductId[0].menu_name });
        }

        this.setState({ selectedProductDetails: body });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }

  getSubProductDetails = () => {
    const params = {
      parent_id: this.props.selectedProductId ? this.props.selectedProductId : this.props.productId
    }
    Utility.sendRequest(SUBPRODUCTS, 1, params, (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.setState({ subProductListing: body });
      } else {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });
  }


  handleKey = (key) => {
    this.setState({ selectedTab: key });
  }

  getProductID = (id) => {
    this.setState({ productId: id }, () => {
      this.props.updateProductID(id);
    });
  }

  render() {
    const selectedTab = this.state.selectedTab;
    const label = (Utility.isEmpty(this.props.selectedProductId) || this.state.isListShown) ? 'Create a Product' : 'Edit Product Details';
    return (
      <>
        {
          <ScreenViewShop
            closeSummary={this.closeForm}
            id="createProduct"
            name={this.state.selectedProductDetails && (this.props.editFromMain || this.props.edit !== null) ? this.state.selectedProductDetails.name : ""}
            label={label} >
            <Tabs activeKey={this.state.selectedTab} onSelect={(key) => this.handleKey(key)} id="product-add" className="mt-5">
              {(this.props.edit !== null || this.props.editFromMain) ?
                <Tab eventKey="tab1" title="Product Summary">
                  <div className="py-4" >
                    <div className="font-italic font-bold mt-3 font-18" >Primary Details</div>
                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Type</div>
                      <div className="shop-tag-input align-items-center" >{this.state.product_type_name}</div>
                    </div>

                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Category</div>
                      <div className="shop-tag-input align-items-center" >{!Utility.isEmpty(this.state.selectedProductDetails.categories) ? this.state.selectedProductDetails.categories[0].name_for_shops : "Product Category"}</div>
                    </div>

                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Name</div>
                      <div className="shop-tag-input align-items-center" >{this.state.selectedProductDetails.name}</div>
                    </div>

                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Video &amp; Images</div>
                      <div className="shop-tag-elements" >
                        {!Utility.isEmpty(this.state.allImages) ?
                          this.state.allImages.map(items => {
                            return (
                              <>
                                <img className="shop-tag-thumb" src={items.image_url} />
                              </>
                            )
                          })
                          :

                          <div className="shop-tag-thumb" />
                        }
                      </div>
                    </div>

                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Color</div>
                      <div className="shop-tag-elements" >
                        {!Utility.isEmpty(this.state.allColors) ?
                          this.state.allColors.map(items => {
                            return (
                              <div style={{ backgroundColor: items }} className="shop-tag-color">{items}</div>
                            )
                          })
                          :
                          <div className="shop-tag-color">No Colors..</div>
                        }
                      </div>
                    </div>

                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Product Description</div>
                      <div className="shop-tag-input shop-tag-textarea" dangerouslySetInnerHTML={{ __html: this.state.selectedProductDetails.description }}></div>
                    </div>

                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Inventory</div>
                      <div className="shop-tag-input align-items-center" >Unlimited Supply</div>
                    </div>

                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Price</div>
                      <div className="shop-tag-input align-items-center" >{this.state.selectedProductDetails.price}</div>
                    </div>

                    <div className="font-italic font-bold mt-3 font-18" >Additional Details</div>
                    {!Utility.isEmpty(this.state.attributeValues) ?
                      this.state.attributeValues.map(item => {
                        return (
                          <div className="shop-tag-outer">
                            <div className="shop-tag-label" >{item.attributeName}</div>
                            <div className="shop-tag-input align-items-center">{item.attributeDescription}</div>
                          </div>
                        )
                      })
                      :
                      <div className="shop-tag-outer">
                        <div className="shop-tag-label" >Field Name</div>
                        <div className="shop-tag-input align-items-center" >Field Description...</div>
                      </div>
                    }


                    <div className="shop-tag-outer">
                      <div className="shop-tag-label" >Product Tags</div>
                      <div className="d-flex flex-wrap px-4 pt-4" >
                        {!Utility.isEmpty(this.state.selectedProductDetails.product_tags) ?
                          this.state.selectedProductDetails.product_tags.map(items => {
                            return (

                              <div className="shop-tag-info bg-primary text-white">
                                <CloseIconSharp mainClass="mr-2 cursor-pointer" fillClass="fill-white" />
                                {items.tag_name}
                              </div>
                            )
                          })
                          :
                          <div className="shop-tag-info bg-primary text-white">No Tags...</div>
                        }
                      </div>
                    </div>


                    <div className="font-italic font-bold mt-3 font-18" >Sub Products</div>
                    {this.state.subProductListing && this.state.subProductListing.map((item) => {
                      return (
                        <Sub_productlist
                          subProducts={item}
                          productTags={item.product_tags ? item.product_tags : ""}
                          attributes={item.additional_details}
                          colors={item.all_colors}
                          images={item.all_images}
                        />
                      )
                    })
                    }

                    <div className="d-inline-flex flex-column mt-5" >
                      <button onClick={this.props.closeForm} className="btn btn-primary font-weight-bold py-3 px-4 mb-4">Save &amp; Close</button>
                      <button onClick={this.props.closeAddProductForm} className="btn btn-link">Cancel</button>
                    </div>
                  </div>

                </Tab>
                :
                <Tab eventKey="tab1" title="Add Products">
                  <AddOrEditProduct
                    allCategories={this.props.allCategories}
                    mainProduct={true}
                    closeAddProductForm={this.props.closeAddProductForm}
                    allProductTypes={this.props.allProductTypes}
                  />

                </Tab>
              }
              {(this.props.edit !== null || this.props.editFromMain) &&
                <Tab eventKey="tab2" title="Edit Products">
                  <AddOrEditProduct
                    allCategories={this.props.allCategories}
                    closeAddProductForm={this.props.closeAddProductForm}
                    selectedProductId={this.props.selectedProductId}
                    showProductList={this.showProductList}
                    selectedTab={this.state.selectedTab}
                    closeAddProductForm={this.props.closeAddProductForm}
                    allProductTypes={this.props.allProductTypes}
                  />
                </Tab>
              }

            </Tabs>

          </ScreenViewShop>
        }
      </>
    );
  }
}
