import React from "react";
import SideCallout from "shared/components/templates/side_callout";
import { Utility } from "utils";
import moment from "moment-timezone";
import { Row, Col, Image } from "react-bootstrap";
import {  ToolboxIcon } from 'shared/icons/icons';

const GetKeysToYourCity = (props) => {
  return (
    <SideCallout
      closeCallOut={props.handleClose}
      position="position-fixed create-campaign-margin passport-sidebar-width"
      calloutHeading="Create Account"
      calloutHeadingImg={process.env.PUBLIC_URL + '/toast_icon/city_logo.png'}
    >
      <div className="user-detail d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap pb-3">
        <div className="d-flex flex-wrap align-items-center">
          <div className="user-detail-img">
            <Image src={props.userData.profile_image_url} alt="" />
          </div>
          <div className="name-city">
            <div className="user-detail-name text-capitalize">
              {props.userData.user_name}
            </div>
            <div className="user-detail-citizen">
              You became a Citizen on{" "}
              {moment(props.userData.user_client_created_at).format("MMMM")}{" "}
              {moment(props.userData.user_client_created_at).format("D")},{" "}
              {moment(props.userData.user_client_created_at).format("Y")}.
            </div>
          </div>
        </div>
        <div className="user-detail-citizen-name">
          You are a <span className="text-primary">Citizen</span>
        </div>
      </div>
      <div className="key-info d-flex align-items-center py-5">
        <div className="key-info-img">
          <Image
            src={require("assets/images/profile/new-profile/character.png")}
            alt=""
          />
        </div>
        <div className="key-info-text px-5">
          <div className="font-24 b-600">Get the Key to your City!</div>
          <div className="font-22 mt-2">
            Unlock decentralized software utilities to take <br />
            ownership of your digital community experience.
          </div>
        </div>
      </div>
      <div className="font-22 b-500 my-3"></div>
      <div className="key-plan d-flex p-4 mr-md-5 align-items-center">
        <div className="key-plan-icon mr-4">
          <Image
            src={require("assets/images/logos/slogo-big.png")}
            alt=""
          />
        </div>
        <div className="key-plan-detail">
          <div className="price mr-3">One Price. Unlimited Software.</div>
          <div className="users">Only pay for what you use.</div>
        </div>
      </div>

      <div className="font-20 b-700 my-4">Select a License Type</div>
      {props.licenses.map((e) => {
        return (
          <div key={e.id} className="software-list p-4 mr-md-5 box">
            <Row className="city-details profile-text-dark border-bottom">
              <Col md={3} className="border-right">
                <div className="d-flex">
                  <Image
                    src={require("assets/images/profile/new-profile/city-icon.png")}
                    alt=""
                  />
                  <div className="d-flex flex-column ml-3">
                    <div className="text-uppercase b-600">
                      {e.name.split(" ")[0]}
                    </div>
                    <div className="text-uppercase">{e.name.split(" ")[1]}</div>
                  </div>
                </div>
                <div className="mt-3">Base License</div>
                <div className="mt-3 font-22 b-700">
                  ${Utility.paintUptoTwoDecimalPlaces(e.base_fee)}
                </div>
                <p className="mt-3">
                  A City license includes up to {e.free_user} users and{" "}
                  {e.free_admin} Admin for end-to-end digital community
                  experience.
                </p>
              </Col>
              <Col md={9} className="overflow-hidden">
                <Row>
                  <Col md={12}>
                    <div className="text-uppercase b-600">
                      Select a plan that fits your needs.
                    </div>
                    <div className="text-uppercase">
                      only pay for what you use.
                    </div>
                  </Col>
                </Row>
                <Row className="h-100">
                  <Col md={3} className="">
                    <div className="border-right h-100">
                      <div className="mt-3">Users</div>
                      <div className="mt-3 font-22 b-700">
                        ${Utility.paintUptoTwoDecimalPlaces(e.per_user_fee)}
                      </div>
                      <div className="text-uppercase b-700 font-13">
                        per user
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="border-right h-100">
                      <div className="mt-3">Admin</div>
                      <div className="mt-3 font-22 b-700">
                        ${Utility.paintUptoTwoDecimalPlaces(e.per_admin_fee)}
                      </div>
                      <div className="text-uppercase b-700 font-13">
                        per admin
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="border-right h-100">
                      <div className="mt-3">Data Usage</div>
                      <div className="mt-3 font-22 b-700">
                        $
                        {Utility.paintUptoTwoDecimalPlaces(
                          e.per_data_usage_fee
                        )}
                      </div>
                      <div className="text-uppercase b-700 font-13">
                        per {e.data_usage_index.toLowerCase()}
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mt-3">Transaction</div>
                    <div className="mt-3 font-22 b-700">
                      $
                      {Utility.paintUptoTwoDecimalPlaces(
                        e.per_transaction_fixed_fee
                      )}{" "}
                      + {e.per_transaction_percent_fee}%
                    </div>
                    <div className="text-uppercase b-700 font-13">
                      per transactions
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <p className="text-uppercase b-700 profile-text-dark font-13 my-4">
              Software features
            </p>

            <ul className="list-unstyled">
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/dashboard.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Dashboards!</span> Organize your
                digital experience through command centers.
              </li>
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/communications.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Communications!</span> Engage in
                multiple forms of conversation.
              </li>
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/networks.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Networks!</span> Manage networks,
                teams, groups and audiences in various formats.
              </li>
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/campaigns.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Campaigns!</span> Create, manage
                and distribute various types of campaigns.
              </li>
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/software.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Software!</span> Access premium
                collaboration tools to enhance your digital experience.
              </li>
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/statistics.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Statistics!</span> Manage real-time
                data.
              </li>
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/finances.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Finances!</span> Manage the
                financial flow of transactions.
              </li>
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/shops.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Shops!</span> Create, manage and
                distribute various types of products and services.
              </li>
              <li className="d-flex font-16 align-items-center flex-wrap mb-3">
                <div className="icon mr-3">
                  <Image
                    src={require("assets/images/profile/new-profile/marketplace.svg")}
                    alt=""
                  />
                </div>
                <span className="b-700 mr-1">Marketplace!</span> Syndicate your
                content across multiple network channels.
              </li>
            </ul>
            <div className="d-flex align-items-center mt-5 mb-2">
              <button
                onClick={() => {
                  props.handleClose();
                  props.openNeighborhoodForm(props.userData);
                }}
                className="btn btn-theme mr-4 btn-primary btn-get-started d-flex align-items-center position-relative justify-content-center w-auto"
              >
                <ToolboxIcon mainClass="mr-2" fillClass="fill-current" />
                {/* <Image
                  src={require("assets/images/profile/new-profile/toolbox-white.svg")}
                  alt="drodown-icon"
                  className="mr-2 btn-icon-inside feed-profile"
                /> */}
                Purchase Key
              </button>
              <div className="d-flex flex-column mt-4 mt-sm-0">
                <div className="font-24 b-700 text-primary">
                  ${Utility.paintUptoTwoDecimalPlaces("500")}
                </div>
                <div className="font-13">Base Monthly Rate</div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="software-list p-4 mr-5 box mt-5 d-none">
        <Row className="city-details profile-text-dark border-bottom">
          <Col md={3} className="border-right">
            <div className="d-flex">
              <Image
                src={require("assets/images/profile/new-profile/city-icon.png")}
                alt=""
              />
              <div className="d-flex flex-column ml-3">
                <div className="text-uppercase b-600">neighborhood</div>
                <div className="text-uppercase">account</div>
              </div>
            </div>
            <div className="mt-3">Base License</div>
            <div className="mt-3 font-22 b-700">
              ${Utility.paintUptoTwoDecimalPlaces(100)}
            </div>
            <p className="mt-3">
              A Neigborhood license includes up to 1,000 users and 1 Admin for
              Landing Page websites, only.
            </p>
          </Col>
          <Col md={9} className="overflow-hidden">
            <Row>
              <Col md={12}>
                <div className="text-uppercase b-600">
                  Select a plan that fits your needs.
                </div>
                <div className="text-uppercase">only pay for what you use.</div>
              </Col>
            </Row>
            <Row className="h-100">
              <Col md={3} className="">
                <div className="border-right h-100">
                  <div className="mt-3">Users</div>
                  <div className="mt-3 font-22 b-700">
                    ${Utility.paintUptoTwoDecimalPlaces(0.1)}
                  </div>
                  <div className="text-uppercase b-700 font-13">per user</div>
                </div>
              </Col>
              <Col md={3}>
                <div className="border-right h-100">
                  <div className="mt-3">Admin</div>
                  <div className="mt-3 font-22 b-700">
                    ${Utility.paintUptoTwoDecimalPlaces(100)}
                  </div>
                  <div className="text-uppercase b-700 font-13">per admin</div>
                </div>
              </Col>
              <Col md={3}>
                <div className="border-right h-100">
                  <div className="mt-3">Data Usage</div>
                  <div className="mt-3 font-22 b-700">
                    ${Utility.paintUptoTwoDecimalPlaces(0.025)}
                  </div>
                  <div className="text-uppercase b-700 font-13">per gb</div>
                </div>
              </Col>
              <Col md={3}>
                <div className="mt-3">Transaction</div>
                <div className="mt-3 font-22 b-700">
                  ${Utility.paintUptoTwoDecimalPlaces(0.3)} + 5%
                </div>
                <div className="text-uppercase b-700 font-13">
                  per transactions
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="d-flex align-items-center my-4">
          <button className="btn btn-theme mr-4 btn-primary btn-get-started d-flex align-items-center position-relative justify-content-center">
            <Image
              src={require("assets/images/profile/new-profile/toolbox-white.svg")}
              alt="drodown-icon"
              className="mr-2 btn-icon-inside feed-profile"
            />
            Purchase Key
          </button>
          <div className="d-flex flex-column">
            <div className="font-24 b-700 text-primary">
              ${Utility.paintUptoTwoDecimalPlaces(100)}
            </div>
            <div className="font-13">Base Monthly Rate</div>
          </div>
        </div>
      </div>
    </SideCallout>
  );
};

export default GetKeysToYourCity;
