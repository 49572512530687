import React from 'react';
import CampaignDetail from 'shared/components/v2/campaign';
import { showLoader } from 'shared/actions';
import { connect } from "react-redux";

const Campaign = (props) => {
  const goToHome = (path) => {
    if (props.location.calledFrom === 'suggestedReads') {
      props.history.push({
        pathname: path,
        calledFrom: 'suggestedReadsSeeAll',
      });
    }
    else if (props.location.calledFrom === 'myArticles') {
      props.history.push({
        pathname: path,
        calledFrom: 'profileMyArticleSeeAll',
      });
    }
    else
      props.history.push(path);
  };

  return(
    <CampaignDetail
      key={props.match.params.id}
      campaignId={props.match.params.id}
      calledFrom={props.location.calledFrom || null}
      goBackLink={props.location.goBackLink || null}
      handleBack={goToHome}
      history={props.history}
    />
  );
};

const mapActionToProps =  { showLoader };

export default connect(null, mapActionToProps)(Campaign);
