import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { BackButton, Skip, Image } from 'shared/components/pwa';

export class First extends Component {
  render() {
    return(
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column" >
        <div className="d-flex" >
          <BackButton action={this.props.goBack}/>
          <Image />
        </div>
        <h1 className="font-20-bold text-center my-5 " >What is your name?</h1>
        <Form className="pwa-form" >
          <Form.Group controlId="first_name">
            <InputField
              required={true}
              className="form-control"
              type="text"
              placeholder="First Name"
              name="first_name"
              ref="first_name"
              value={this.props.first_name}
              handleChange={(e) => this.props.handleChange(e, 'first_name')}
            />
          </Form.Group>
          <Form.Group controlId="last_name">
            <InputField
              required={true}
              className="form-control"
              type="text"
              placeholder="Last Name"
              name="last_name"
              ref="last_name"
              value={this.props.last_name}
              handleChange={(e) => this.props.handleChange(e, 'last_name')}
            />
          </Form.Group>
          <div className="text-center mt-auto">
            <button
              className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
              onClick={(e) => this.props.nextPage(e, 'first_page')}
            >
              CONTINUE
            </button>
            <Skip skip={this.props.skip} name={'SKIP FOR NOW'} />
          </div>
        </Form>
      </div>
    );
  }
}
