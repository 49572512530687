import React from 'react';
import { Badge } from 'react-bootstrap';
import { Utility } from 'utils';

export const MenuCount = props => (
  
  <>
    <Badge variant="warning" className={!props.isShops?"shar-badge display-9 display-xl-8":"display-9 display-xl-8"}>
      {
        !Utility.isEmpty(props.count)
        ? props.count
        : 0
      }
    </Badge>
  </>
);
