import React, { Component } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { BackButton, Image } from 'shared/components/pwa';

export class Email extends Component {
  render() {
    return(
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column" >
        <div className="d-flex" >
          <BackButton action={this.props.goBack} name='email'/>
          <Image />
        </div>
        <h1 className="font-20-bold text-center my-5">What is your email?</h1>
        <Form className="pwa-form" >
          <Form.Group controlId="formBasicEmail">
            <InputField
              required={true}
              className="form-control"
              type="email"
              placeholder="Email address"
              name="email"
              ref="email"
              value={this.props.email || ''}
              handleChange={(e) => this.props.handleChange(e, 'email')}
            />
          </Form.Group>
          <div className="text-center mt-auto">
            <button
              className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
              onClick={(e) => this.props.nextPage(e, 'email')}
              disabled={this.props.showLoader}
            >
            {
              this.props.showLoader
              ? <Spinner animation="border" role="status"/>
              : 'CONTINUE'
            }
            </button>
          </div>
        </Form>
      </div>
    );
  }
}
