import React, { Component } from 'react';
import { Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import { MdClose } from 'react-icons/md';
import { REVENUE_DISTRIBUTIONS } from 'config/constants/api_endpoints';

export default class PayoutSchedule extends Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      payoutTerms: [],
      isLoading: false
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.getPayoutTerms();
  }

  componentDidUpdate = () => {
    if (Utility.isEmpty(this.state.payoutTerms) && !this.state.isLoading) {
      this.getPayoutTerms();
    }
  }

  componentWillUnmount = () => this._isMounted = false;

  getPayoutTerms = () => {
    this.setState({ isLoading: true });
    const params = { module_id: this.props.moduleId, is_global: true };

    Utility.sendRequest(REVENUE_DISTRIBUTIONS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      let payoutTerms = [];

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        payoutTerms = body.data;
      }

      if (this._isMounted) this.setState({ isLoading: false, payoutTerms });
    });
  }

  render() {
    return (
      <Col xl={9} lg={8} className="px-0 h-md-100 scroll-y top-on-sm d-none" id="payout-schedule">
        <div className="p-xl-5 p-lg-4 p-3">
          <div className="align-items-center justify-content-between">
            <h4>Payout Schedule</h4>
            <Button variant="link" className="btn-close close-btn-setup" onClick={this.props.onClose}><MdClose onClick={() => {Utility.handlePayoutOverlay(this.props.sourceId, false);}}/></Button>
            <div className="close-button">
            </div>
          </div>
          {
            !Utility.isEmpty(this.state.payoutTerms) &&
            <Col xl={8} lg={12} md={9} className="px-0 mt-lg-5 mt-sm-4 mt-3" >
              {
                this.state.payoutTerms.map((term, index) => {
                  return (
                    <TermField key={index} term={term} />
                  );
                })
              }
              <div className="my-5">
                <p>{this.state.payoutTerms[0].CrmModule.payout_description}</p>
                <p>A transaction fee of 3% is deducted before the payout percentages are applied to the captured funds and distributed to network.</p>
                <p>By selecting “confirm”, I agree to the payout schedule.</p>
              </div>
              <div className="d-inline-flex flex-column">
                <Button variant="primary" className="mb-3" onClick={() => {Utility.handleAgreement(true, this.props.sourceId);}}>Confirm</Button>
                <Button variant="link" onClick={() => {Utility.handleAgreement(false, this.props.sourceId);}}>Decline</Button>
              </div>
            </Col>
          }
        </div>
      </Col>
    );
  }
}

export const TermField = (props) => {
  return (
    <div className="shar-card d-flex mt-3">
      <div className="payout-left-panel w-100 py-3 px-sm-4 px-3">
        <h3 className="display-7 text-black-muted">{props.term.RateType.name}</h3>
        <p className="display-9 mb-0">{props.term.RateType.description}</p>
      </div>
      <div className="payout-right-panel bg-primary text-white flex-center ml-auto">
        <h2 className="mb-0">{props.term.revenue_distribution_percentage}%</h2>
      </div>
    </div>
  );
};
