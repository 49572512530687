import React, { memo } from 'react';
import DetailListTitle from './detail_list_title';
// import { DetailListSorting } from './detail_list_sorting';
import { SearchBar } from 'shared/components/templates/menu_list';

let DetailListHeader = props => {
  return (
      <div className="d-flex flex-wrap justify-content-between" >
        <DetailListTitle headerTitle={props.headerTitle}/>
       <div className="align-items-center mt-2 mt-sm-0" >
         <div className="d-inline-block mr-4" >
         {
          props.showFilterComponent &&
          <SearchBar placeholder="Search" handleKeyUp={props.filterList}/>
         }
         </div>
         {/*<DetailListSorting />*/}
       </div>
      </div>
  );
};

export default DetailListHeader = memo(DetailListHeader);
