import React, { Component } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import PasswordValidationFrame from 'shared/components/password_validation_frame';
import { BackButton, Image } from 'shared/components/pwa';

export class CreatePasswordPWA extends Component {
  render() {
    return(
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column" >
        <div className="d-flex" >
          <BackButton action={this.props.goBack}/>
          <Image />
        </div>
        <h1 className="font-20-bold text-center my-5">Please setup your password</h1>
        <Form className="pwa-form" >
          <Form.Group>
            <InputField
              required={true}
              id="password"
              className="form-control"
              type="password"
              placeholder="Enter password"
              name="password"
              ref="password"
              value={this.props.password}
              handleChange={(e) => this.props.handleChange(e, 'password')}
            />
          {
            this.props.showPasswordValidation &&
            <PasswordValidationFrame password={this.props.passwordValidation}/>
          }
          </Form.Group>
          <Form.Group>
            <InputField
              className="form-control"
              required={true}
              id="confirm_password"
              type="password"
              placeholder="Confirm password"
              name="confirm_password"
              ref="confirm_password"
              handleChange={(e) => this.props.handleChange(e, 'confirm_password')}
            />
          </Form.Group>
          <div className="text-center mt-auto">
            <button
              className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
              disabled={this.props.showLoader}
              onClick={(e) => this.props.submit(e)}
            >
            {
              this.props.showLoader
              ? <Spinner animation="border" role="status"/>
              : 'CONTINUE'
            }
            </button>
          </div>
        </Form>
      </div>
    );
  }
}
