import React from "react";
import { Utility } from "utils";
import Avatar from "react-avatar";

const SupportTeamCard = (props) => {
  const handleChatClick = (user) => {
    props.updateChatFriend(user);
    props.updateIsChatFormOpen(true);
  };

  return (
    <>
      {props.data.map((e) => {
        return (
          <div key={e.id} className="mt-4 pt-lg-2">
            <div className="p-3 text-center mr-3 new-follower-card text-white">
            {!Utility.isEmpty(e.creator_profile_image_url) ? (
                <img
                  src={e.creator_profile_image_url}
                  alt=""
                  className="new-follower-image mb-2"
                />
              ) : (
                <Avatar
                  name={e.creator_name}
                  round={true}
                  color="#007bb3"
                  maxInitials={1}
                  className="new-follower-image mb-2"
                />
              )}
              <div className="follower-account-name mb-2">{e.creator_name}</div>
              {e.category_name && <div className="font-12 follower-account-name mb-2">{e.category_name}</div>}
              <div>
                {e.state}
                {e.city ? "," : ""} {e.city}
              </div>
              <img
                src={require("assets/images/icons/chat-white.svg")}
                alt=""
                className="mt-4 cursor-pointer team-chat-trigger"
                onClick={() => handleChatClick(e)}
              />
              <div className="mt-4 show-on-ahura d-none" >
                <div>
                  <img
                    src={require("assets/images/icons/chat_bubble.svg")}
                    alt=""
                    className="cursor-pointer"
                    style={{filter: 'invert(1) brightness(2)'}}
                    onClick={() => handleChatClick(e)}
                  />
                  <div className="font-bold mt-2 font-14" >Chat</div>
                </div>
              </div>
              {e.managedAccount && e.managedAccount === true &&
                <img
                src={require("assets/images/icons/menu_horizontal_icon.svg")}
                alt=""
                className="mt-4 cursor-pointer img-invert ml-3"
                onClick={() => props.openAccountManager(e)}
              />}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SupportTeamCard;
