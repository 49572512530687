import React, { Component } from 'react';
import { ChatOvalIcon, MemberGroupIcon, ShareIcon, ExpandIcon, LessonIcon } from 'shared/icons/icons';
import { CLIENT_ID, CRM_MODULES } from 'config/constants/config';
import { GIL_CLIENT_ID } from 'config/constants/index';
export default class ExpandableOptions extends Component {
    constructor(props) {
      super(props);
      this.state = {
          modalOpen: false,
          videoPlay: false,
          currentTime: 0,
      };
    }

    componentDidMount = () => {
      this.getVideoPercentage();
    }

    playVideo = () => {
      document.getElementById("campaign_video").play();
      this.setState({ videoPlay: true });
    }

    pauseVideo = () => {
      document.getElementById("campaign_video").pause();
      this.setState({ videoPlay: false });
    }

    getVideoPercentage = () => {
      var self = this;
      setInterval(function(){
          //code goes here that will be run every 5 seconds.
          const video = document.getElementById("campaign_video");
          if(video) {
            const percent = (video.currentTime / video.duration) * 100;
            self.setState({ currentTime: percent });
          }
      }, 1000);
    }

    render() {
    //   console.log(this.props.videoPlaying);
        return(
            <>
            <div className={`article-expandable-options ${this.props.openModal ? 'options-opened' : 'article-expand'}`} >
                <ul className="list-unstyled mb-0 icons-list" >
                    <li className="pill-expand" onClick={() => this.props.closeModal(!this.props.openModal)} >
                        <ExpandIcon fillClass="fill-primary" />
                    </li>
                    {this.props.isCourseType &&
                    <li className={`pill-option pill-lesson ${this.props.activeTab === 'course' ? 'active' : ''}`} onClick={() => this.props.switchRightSection("course")}  >
                        <LessonIcon fillClass="fill-current" />
                        <span className="option-name font-14 font-bold">Lesson</span>
                    </li>}
                    <li className={`pill-option pill-chat ${this.props.activeTab === 'chat' ? 'active' : ''}`} onClick={() => this.props.switchRightSection("chat")} >
                        <ChatOvalIcon mainClass="hide-on-pwa" fillClass="fill-current" />
                        <ChatIcon mainClass="show-on-pwa" fillClass="fill-current" />
                        <span className="option-name font-14 font-bold">Chat</span>
                    </li>
                    {!this.state.videoPlay ?
                        <li onClick={this.playVideo} className={`pill-option pill-play pill-playing ${this.props.videoPlay ? 'active' : ''}`} >
                            <PlayIcon courseRemainingPercent={this.state.currentTime} />
                            <span className="option-name font-14 font-bold">Play</span>
                        </li>
                        :
                        <li onClick={this.pauseVideo} className={`pill-option pill-play pill-playing ${this.props.videoPlay ? 'active' : ''}`} >
                            <PauseIcon courseRemainingPercent={this.state.currentTime} />
                            <span className="option-name font-14 font-bold">Pause</span>
                        </li>
                    }
                    <li className={`pill-option pill-peer ${this.props.activeTab === 'viewingMembers' ? 'active' : ''}`}  onClick={() => this.props.switchRightSection("viewingMembers")}>
                        <MemberGroupIcon mainClass="hide-on-pwa" fillClass="fill-current" />
                        <PeersIcon mainClass="show-on-pwa" fillClass="fill-current" />
                        <span className="option-name font-14 font-bold">Peers</span>
                    </li>
                    <li className={`pill-option pill-share ${this.props.activeTab === 'share' ? 'active' : ''}`} onClick={() => this.props.switchRightSection("share")}>
                        <ShareIcon mainClass="hide-on-pwa" fillClass="fill-current" />
                        <ShareIconPWA mainClass="show-on-pwa" fillClass="fill-current" />
                        <span className="option-name font-14 font-bold">Share</span>
                    </li>
                </ul>
                <div className="article-expandable-body" >
                    {this.props.children}
                </div>
            </div>
            </>
        );
    }
}

export const PlayIcon = (props) => {
    return(
        <svg
            width={53}
            height={54}
            viewBox="0 0 53 54"
            >
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__b">
                <stop stopColor="#77549F" offset="0%" />
                <stop stopColor="#422A66" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__a">
                <stop stopColor="#D8A0FF" offset="0%" />
                <stop stopColor="#913DFB" offset="100%" />
                </linearGradient>
                <filter
                x="-12.8%"
                y="-12.7%"
                width="125.7%"
                height="125.4%"
                filterUnits="objectBoundingBox"
                id="prefix__d"
                >
                <feGaussianBlur
                    stdDeviation={1.5}
                    in="SourceAlpha"
                    result="shadowBlurInner1"
                />
                <feOffset dy={1} in="shadowBlurInner1" result="shadowOffsetInner1" />
                <feComposite
                    in="shadowOffsetInner1"
                    in2="SourceAlpha"
                    operator="arithmetic"
                    k2={-1}
                    k3={1}
                    result="shadowInnerInner1"
                />
                <feColorMatrix
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    in="shadowInnerInner1"
                />
                </filter>
                <path
                d="M32.242 25.029l-13.04-6.597a1.263 1.263 0 00-1.242.052c-.376.229-.606.643-.607 1.09v13.198c0 .443.228.856.607 1.089.378.233.847.253 1.243.052l13.04-6.6a1.28 1.28 0 000-2.284z"
                id="prefix__c"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                d="M24.5 50C38.031 50 49 38.807 49 25S38.031 0 24.5 0 0 11.193 0 25s10.969 25 24.5 25z"
                stroke="url(#prefix__a)"
                strokeWidth={4}
                fill={CLIENT_ID === GIL_CLIENT_ID ? 'url(#prefix__b)' : ''}
                className={CLIENT_ID === GIL_CLIENT_ID ? 'course-btn-transition' : 'course-btn-transition fill-primary-dark'}
                strokeDasharray="160"
                strokeDashoffset={(100 * 1.6) - (props.courseRemainingPercent * 1.6)}
                transform="matrix(1 0 0 -1 2 52)"
                />
                <g fillRule="nonzero" transform="matrix(1 0 0 -1 3 54)">
                <use fill="#FFF" xlinkHref="#prefix__c" />
                <use fill="#000" filter="url(#prefix__d)" xlinkHref="#prefix__c" />
                </g>
            </g>
        </svg>
    );
};

export const PauseIcon = (props) => {
    return(
        <svg
            width={53}
            height={54}
            viewBox="0 0 53 54"
        >
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__b">
                <stop stopColor="#77549F" offset="0%" />
                <stop stopColor="#422A66" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__a">
                <stop stopColor="#D8A0FF" offset="0%" />
                <stop stopColor="#913DFB" offset="100%" />
                </linearGradient>
            </defs>
            <g fillRule="nonzero" fill="none">
                <path
                d="M24.5 50C38.031 50 49 38.807 49 25S38.031 0 24.5 0 0 11.193 0 25s10.969 25 24.5 25z"
                stroke="url(#prefix__a)"
                strokeWidth={4}
                fill={CLIENT_ID === GIL_CLIENT_ID ? 'url(#prefix__b)' : ''}
                className={CLIENT_ID === GIL_CLIENT_ID ? 'course-btn-transition' : 'course-btn-transition fill-primary-dark'}
                strokeDasharray="160"
                strokeDashoffset={(100 * 1.6) - (props.courseRemainingPercent * 1.6)}
                transform="matrix(1 0 0 -1 2 52)"
                />
                <path
                d="M32.22 34.087h-4.25V17.949h4.25zM23.22 34.087h-4.25V17.949h4.25z"
                fill="#FFF"
                />
            </g>
        </svg>
    );
};

export const PeersIcon = (props) => {
    return(
    <svg width={18} height={18} viewBox="0 0 18 18" className={props.mainClass}>
      <g className={props.fillClass} fill="#422A66" fillRule="nonzero">
        <path d="M6.879 6.5c.504-.61 1.267-1 2.121-1a2.75 2.75 0 11-2.122 1h.001zM12.75 8.25c0-.632-.156-1.228-.432-1.75H16.5A1.5 1.5 0 0118 8v.5c0 1.587-1.206 3.212-3.315 3.784A2.5 2.5 0 0012.5 11h-.95a3.74 3.74 0 001.2-2.75zM12.5 12a1.496 1.496 0 011.5 1.5v.5c0 1.971-1.86 4-5 4-3.14 0-5-2.029-5-4v-.5A1.496 1.496 0 015.5 12h7zM0 8.5c0 1.587 1.206 3.212 3.315 3.784A2.5 2.5 0 015.5 11h.95a3.74 3.74 0 01-1.2-2.75c0-.632.156-1.228.433-1.75H1.5A1.5 1.5 0 000 8v.5zM7.75 2.75a2.75 2.75 0 10-5.5 0 2.75 2.75 0 005.5 0zM15.75 2.75a2.75 2.75 0 10-5.5 0 2.75 2.75 0 005.5 0z" />
      </g>
    </svg>
    );
};

export const ChatIcon = (props) => {
    return(
        <svg width="1em" height="1em" viewBox="0 0 22 20" className={props.mainClass}>
            <g className={props.fillClass} fill="#422A66" fillRule="nonzero">
                <path d="M3.957 10.109V4.833H1.978A1.978 1.978 0 000 6.812v11.87a.66.66 0 00.37.586.66.66 0 00.659-.066l3.396-2.499h8.863a1.827 1.827 0 001.879-1.892v-.746H7.913a3.957 3.957 0 01-3.956-3.956z" />
                <path d="M19.673 0H8.476A1.976 1.976 0 006.5 1.976V9.88c0 1.091.885 1.976 1.976 1.976h8.925l3.148 2.444c.19.14.442.168.659.072a.659.659 0 00.375-.593V1.976c0-1.066-.844-1.94-1.91-1.976z" />
            </g>
        </svg>
    );
};

export const ShareIconPWA = (props) => {
    return(
        <svg width="1em" height="1em" viewBox="0 0 20 20" className={props.fillClass} className={props.mainClass}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path
                d="M19.675 7.415L12.8 1.48c-.602-.52-1.55-.098-1.55.71v3.126C4.975 5.387 0 6.645 0 12.591c0 2.4 1.546 4.778 3.255 6.02.534.389 1.294-.098 1.097-.727-1.771-5.665.84-7.169 6.898-7.256v3.434c0 .81.95 1.23 1.55.71l6.875-5.937a.938.938 0 000-1.42z"
                fill="#422A66"
                fillRule="nonzero"
                />
            </g>
        </svg>
    );
};
