import React, { Component } from 'react';
// import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { showLoader, updateCampaignFormOpen, updateCampaignSelectedTab, updateIsCampSelFormOpen, updateCampaignFormSelectedId, updateRefreshCampaignAPI } from 'shared/actions';
import { CircleOutlinedAddIcon, CloseIcon, ChevronDownIcon } from 'shared/icons/icons';
import SideCallout from 'shared/components/templates/side_callout';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Utility } from 'utils';
import { CAMPAIGNS, CATEGORIES_BY_MODULE_ID, ALL_PARENT_CAMPAIGNS, CAMPAIGNS_BY_ID, ALL_CAMPAIGN_CHAPTERS, GET_USERS_BY_NETWORKS, GROUPS, MESSAGES } from 'config/constants/api_endpoints';

import { CLIENT_ID, CRM_MODULES, NAMES_MAPPING, NON_PROFITS_LIMIT, GROUPS_LIMIT } from 'config/constants/config';
import { CAMPAIGN_TYPES_IDS, MESSAGE_TYPE_IDS } from 'config/constants';
import { SingleCampaign } from './single_campaign';
import TileSelection from 'shared/components/tile_selection';
// import { SuggestedReadsCard } from 'shared/components/suggested_reads';
import { SuggestedReadsCard } from 'shared/components/suggested_reads_articles';
import Success from 'shared/components/successCallouts/success';
import { CreateForm } from './form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GroupForm from 'scenes/groups/form';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {Date as utilsDate} from "utils"; 



const validateFields = {
  story: ['embedded_image_link', 'image_url', 'category_id', 'sub_heading', 'description', 'heading'],
  event: ['embedded_image_link', 'image_url', 'category_id', 'sub_heading', 'description', 'heading'],
};

const pwaModeEnabled = Utility.pwaModeEnabled();

class CreateCampaign extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedTab: CAMPAIGN_TYPES_IDS.story,
      isMemberCalloutOpen: false,
      isGroupCalloutOpen: false,
      isSpeakerPresenterCalloutOpen: false,
      showSuccess: false,
      memberLimit: true,
      groupLimit: true,
      memberSearch: '',
      organizerSearch: '',
      groupSearch: '',
      formName: 'campaign',
      users: [],
      categoriesList: [],
      campaignsList: [],
      all_chapters_ids: [],
      formStates: this.initialState(),
      newCampaign: this.getFormData(),
      afterSuccessCampaign: {},
      isGroupCreate: true,
      showLoader: false,
      editCampaign: false,

      eventScheduleList:[
                      {
                          "id": "no_repeat",
                          "name": "Doesn't repeat"
                      },
                      {
                          "id": "weekly",
                          "name": "Weekly"
                      },
                      {
                          "id": "monthly",
                          "name": "Monthly"
                      }
                  ]
    };
    this.idForNewChap = "00000";
    this.debouncedFn = null;

    this.storyFormData.bind(this);
    this.eventFormData.bind(this);
  }

  componentDidMount = () => {
    this.setFormName();
    this.getCategoriesByModuleId();
    this.getAllParentCampaigns();
    this.getUserDetails();
    this.getGroups();

    if(this.props.campaignId || this.props.campId || this.props.editCampaignId)
      this.handleEditClick(this.props.campId || this.props.campaignId || this.props.editCampaignId);

    if(pwaModeEnabled){
      const body = document.getElementsByTagName('body')[0];
      body.classList.toggle('overflow-hidden');

      Utility.pinUnpinNav('acticle-sidebar-inner');
    }
  }

  setFormName = () => {
    let formName = '';
    switch (this.props.campaignSelectedTab) {
      case CAMPAIGN_TYPES_IDS.story:
        formName = 'story';
        break;
      case CAMPAIGN_TYPES_IDS.event:
        formName = 'event';
        break;
      default :
        formName = 'group';
    }

    this.setState({ formName });
  };

  componentDidUpdate = (nextProps) => {
    if(this.props.campaignId)
      if(this.props.campaignId !== nextProps.campaignId)
        this.handleEditClick(this.props.campaignId);

    if (this.props.campaignSelectedTab !== nextProps.campaignSelectedTab) {
      this.setFormName();
      this.setState({ newCampaign: this.getFormData() }, () => {
        this.getCategoriesByModuleId();
        this.getAllParentCampaigns();
        this.getUserDetails();
        this.getGroups();
        this.setState({ isGroupCreate: true, selectedTab: nextProps.campaignSelectedTab });
      });
    }
  }

  componentWillUnmount = () => {
    if(this.props.loadedFrom === 'pwaCampaign')
      this.props.updateIsCampSelFormOpen(false);
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('overflow-hidden');
    this.props.updateCampaignFormSelectedId(null);
  }

  getFormOpenOrClose = () => {
    return this.props.isFormOpen || this.props.isCampaignFormOpen;
  }

  getFormData = () => {
    switch (this.props.campaignSelectedTab) {
      case CAMPAIGN_TYPES_IDS.story:
        return this.storyFormData();
      case CAMPAIGN_TYPES_IDS.event:
        return this.eventFormData();
      default :
        return 'group';
    }
  }

  storyFormData = () => {
    const userData = { ...this.props.userData };

    return {
      heading: '',
      sub_heading: '',
      description: '',
      location: '',
      video_url: '',
      embedded_video_link: '',
      image_url: '',
      document_url: '',
      embedded_image_link: '',
      imageSignedURL: '',
      videoSignedURL: '',
      documentSignedURL: '',
      parent_image_url: '',
      parent_heading: '',
      category_id: null,
      ranking:null,
      parent_id: null,
      non_profits_id: null,
      meeting_url : '',
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
      author_name: userData.user_name,
      author_email: userData.email,
      author_id: userData.user_id,
      created_by: userData.user_id,
      publisher_network_id: CLIENT_ID,
      updated_by: userData.user_id,
      user_client_id: userData.id,
      schedule_start: new Date(),
      client_id: CLIENT_ID,
      non_profits: [],
      organizers: [],
      groups: [],
      is_public: true,
      plain_description: ''
    };
  }

  eventFormData = () => {
    const userData = { ...this.props.userData };
    return {
      heading: '',
      sub_heading: '',
      description: '',
      location: '',
      video_url: '',
      document_url: '',
      embedded_video_link: '',
      image_url: '',
      embedded_image_link: '',
      imageSignedURL: '',
      videoSignedURL: '',
      documentSignedURL: '',
      parent_image_url: '',
      parent_heading: '',
      category_id: null,
      ranking:null,

      meeting_url :'',
      parent_id: null,
      non_profits_id: null,
      campaign_type_id: CAMPAIGN_TYPES_IDS.event,
      author_name: userData.user_name,
      author_email: userData.email,
      author_id: userData.user_id,
      created_by: userData.user_id,
      publisher_network_id: CLIENT_ID,
      updated_by: userData.user_id,
      user_client_id: userData.id,
      schedule_start: new Date(),
      client_id: CLIENT_ID,
      non_profits: [],
      organizers: [],
      groups: [],
      start_at: moment().utcOffset(-6),
      start_at_show: moment().utcOffset(-6),
      end_at:  moment().utcOffset(-6),
      end_at_show:  moment().utcOffset(-6),

      // start_at: moment().format(),
      // end_at:  moment().format(),
      is_public: true,
      plain_description: '',

      is_repeated: false,
      repeat_details:{
        start_time: moment().format(),
        end_time: moment().format(),
        frequency_type:"no_repeat",
        frequency:[],
      },
    };
  }

  handleIsFormOpen = () => {
    this.props.updateCampaignFormOpen(false);
    this.props.setIsFormOpen(false);
  }

  closeMemberCallout = () => {
    this.setState({
      isMemberCalloutOpen: false
    });
  }

  closeOrganizerCallout = () => {
    this.setState({
      isOrganizerCalloutOpen: false
    });
  }

  closeGroupCallout = () => {
    this.setState({
      isGroupCalloutOpen: false
    });
  }

  closeConfirmationCallout = () => {
    this.setState({
      showSuccess: false
    });
    this.props.updateCampaignFormOpen(false);
  }

  openMemberCallout = () => {
    this.setState({
      isMemberCalloutOpen: true
    });
  }

  openOrganizerCallout = () => {
    this.setState({
      isOrganizerCalloutOpen: true
    });
  }

  openGroupCallout = () => {
    this.setState({
      isGroupCalloutOpen: true
    });
  }

  removeMemberLimit = () => {
    this.setState({
      memberLimit: false
    }, () => this.getUserDetails());
  }

  removeOrganizerLimit = () => {
    this.setState({
      organizerLimit: false
    }, () => this.getUserDetails());
  }

  removeGroupLimit = () => {
    this.setState({
      groupLimit: false
    }, () => this.getGroups());
  }

  getSelectedMembersIds = (arr) => {
    const newCampaign = { ...this.state.newCampaign };
    newCampaign.non_profits = arr;
    this.setState({
      newCampaign
    });
  }

  getSelectedOrganizersIds = (arr) => {
    const newCampaign = { ...this.state.newCampaign };
    newCampaign.organizers = arr;
    this.setState({
      newCampaign
    });
  }

  getSelectedGroupsIds = (arr) => {
    const newCampaign = { ...this.state.newCampaign };
    newCampaign.groups = arr;
    this.setState({
      newCampaign
    });
  }

  handleMemberSearch = (ev) => {
    ev.persist();

    if (!this.debouncedFn) {
      this.debouncedFn =  Utility.debounce(() => {
         const searchString = ev.target.value;
         this.setState({ memberSearch: searchString }, () => {
           this.getUserDetails();
         });
      }, 500);
    }
    this.debouncedFn();
  };

  handleOrganizerSearch = (ev) => {
    ev.persist();

    if (!this.debouncedFn) {
      this.debouncedFn =  Utility.debounce(() => {
         const searchString = ev.target.value;
         this.setState({ organizerSearch: searchString }, () => {
           this.getUserDetails();
         });
      }, 500);
    }
    this.debouncedFn();
  };

  handleGroupSearch = (ev) => {
    ev.persist();

    if (!this.debouncedFn) {
      this.debouncedFn =  Utility.debounce(() => {
         const searchString = ev.target.value;
         this.setState({ groupSearch: searchString }, () => {
           this.getGroups();
         });
      }, 500);
    }
    this.debouncedFn();
  };

  initialState = () => {
    return {
      selectedCampaignId: '',
      showDropdown: false,
      isEdit: false,
      isCreate: true,
      isNewChap: false,
      isChapToggle: false,
      isCreateOrEdit: false,
      search: null,
      imageFile: '',
      imagePreviewUrl: '',
      videoFile: '',
      videoPreviewUrl: '',
      documentFile: '',
      documentPreviewUrl: ''
    };
  }

  handleSearch = (ev) => {
    let search = '';
    const formStates = { ...this.state.formStates };
    const newCampaign = this.getFormData();

    if (!Utility.isEmpty(ev.target.value)){
      search = ev.target.value;
      newCampaign.heading = search;
      formStates.imagePreviewUrl = '';
    } else if (Utility.isEmpty(this.state.campaignsList)) {
      search = null;
    } else {
      search = null;
    }

    formStates.search = search;

    this.setState({ formStates, newCampaign },
      () => this.getAllParentCampaigns()
    );
  }

  handleFileChange = (inputId, previewURL, file) => {
    const formStates = { ...this.state.formStates };

    if (inputId === 'image_url') {
      formStates.imagePreviewUrl = previewURL;
      formStates.imageFile = file;
    } else if (inputId === 'video_url') {
      formStates.videoPreviewUrl = previewURL;
      formStates.videoFile = file;
    } else if (inputId === 'document_url'){
      formStates.documentPreviewUrl = previewURL;
      formStates.documentFile = file;
    }

    this.setState({ formStates }, () => {
      if(Utility.isEmpty(this.state.newCampaign.embedded_image_link))
      Utility.isFilePathEmpty('image_url', this.state.formStates.imagePreviewUrl, this.state.formName);
    });
  }

  removeErrors = (className) => {
    var node = document.getElementsByClassName(className);
    for(let i = 0; i < node.length; i++)
    node[i].innerText = '';
  }

  removeUploadedFile = (inputId) => {
    const formStates = { ...this.state.formStates };
    const newCampaign = { ...this.state.newCampaign };

    if (inputId === 'image_url') {
      formStates.imagePreviewUrl = '';
      formStates.imageFile = '';
      newCampaign.image_url = '';
    } else if (inputId === 'document_url') {
      formStates.documentPreviewUrl = '';
      formStates.documentFile = '';
      newCampaign.document_url = '';
    } else {
      formStates.videoPreviewUrl = '';
      formStates.videoFile = '';
      newCampaign.video_url = '';
    }
    this.setState({ formStates }, () => {
      this.setState({ newCampaign });
    });
  }

  handleInputChange = (ev) => {
    let formData = { ...this.state.newCampaign };
    formData = Utility.validateAndReturnFormData(this.refs[this.state.formName], formData, ev, validateFields[this.state.formName], this.state.formName);

    this.setState({ newCampaign: formData });
  }

  changeDescription = (data) => {
    const formData = { ...this.state.newCampaign };
    let plainDescription = data;
    plainDescription = plainDescription.replace(/<[^>]+>/g, '');
    formData['description'] = data;
    formData['plain_description'] = plainDescription;
    this.setState({ newCampaign: formData });
  }

  handleDropdown = (evKey) => {
    const formData = { ...this.state.newCampaign };
    formData[evKey.split(',')[0]] = evKey.split(',')[1];

    Utility.getElementById('category_idError').innerText = '';

    this.setState({
      newCampaign: formData
    });
  }

  handleDropdownRanking = (evKey) => {
    const formData = { ...this.state.newCampaign };
    formData[evKey.split(',')[0]] = evKey.split(',')[1];

    Utility.getElementById('rankingError').innerText = '';

    this.setState({
      newCampaign: formData
    });
  }


  handleDropdownEventSchedule = (evKey) => {
    const formData = { ...this.state.newCampaign };
    formData["repeat_details"][evKey.split(',')[0]] = evKey.split(',')[1];
    formData["is_repeated"] = evKey.split(',')[1] === "no_repeat" ? false : true;
    formData["repeat_details"]["frequency"] = [];
    formData["repeat_details"]["start_time"] = moment().format();
    formData["repeat_details"]["end_time"] = moment().format();
    // Utility.getElementById('category_idError').innerText = '';

    this.setState({
      newCampaign: formData
    });
  }

  handleFrequencyScheduleDate = (dateEvent, sourceName) => {
    let scheduleDate = moment.utc(dateEvent._d);
    scheduleDate = moment(moment(scheduleDate).format());

    const newCampaign = { ...this.state.newCampaign };
    newCampaign["repeat_details"][sourceName] = scheduleDate._i;

    this.setState({ newCampaign });
  }

  handleFrequency = (id) =>{
    const formData = { ...this.state.newCampaign };
    let repeat_details = formData["repeat_details"];
    let frequency = repeat_details.frequency;

    const index = frequency.indexOf(id);
    if(index === -1)
      frequency.push(id);
    else
      frequency.splice(index,1);

    // formData["frequency"]  = frequency;
    formData["repeat_details"]["frequency"]  = frequency;
    this.setState({
      newCampaign: formData
    });

  }

  handleChapterDropdown = (evKey) => {
    const formStates = { ...this.state.formStates };

    const parent_heading = this.state.newCampaign.parent_heading ||    this.state.newCampaign.heading;
    const parent_image_url = this.state.newCampaign.parent_image_url ||
                            this.state.newCampaign.image_url;
    const parent_id = this.state.newCampaign.parent_id ?
                    this.state.newCampaign.parent_id :
                    this.state.formStates.selectedCampaignId;

    if(evKey === this.idForNewChap){
      formStates.imagePreviewUrl = '';
      formStates.videoPreviewUrl = '';
      formStates.documentPreviewUrl = '';
      formStates.imageFile = '';
      formStates.videoFile = '';
      formStates.isNewChap = true;
      formStates.isChapToggle = false;

      this.setState({
        newCampaign: this.getFormData(),
        formStates
      }, () => {
        const newCampaign = this.state.newCampaign;
        newCampaign.heading =  parent_heading;
        newCampaign.parent_heading = parent_heading;
        newCampaign.parent_image_url = parent_image_url;
        newCampaign.parent_id = parent_id;
        this.setState({
          newCampaign
        });
      });
    }else{
      formStates.isNewChap = false;
      formStates.isChapToggle = true;
      formStates.selectedCampaignId = evKey;
      this.setState({
        formStates,
      }, () => this.getCampaignsById());
    }
  }

  handleEditClick = (id) => {
    const formStates = { ...this.state.formStates };
    formStates.selectedCampaignId = id;
    formStates.isEdit = true;
    formStates.isCreate = false;
    formStates.isNewChap = false;
    formStates.isCreateOrEdit = true;
    formStates.showDropdown = false;
    this.setState({
      formStates
    }, () => {
      this.removeErrors('error');
      this.getCampaignsById();
      this.getAllCampaignsChapters();
    });
    this.setState({ editCampaign: true });
  }

  handleCreateClick = () => {
    const formStates = { ...this.state.formStates };
    formStates.isEdit = false;
    formStates.isCreate = true;
    formStates.isCreateOrEdit = true;
    formStates.showDropdown = false;
    this.setState({
      formStates
    });
  }

  toggleDropdown = () => {
    const formStates = { ...this.state.formStates };
    formStates.showDropdown = !this.state.formStates.showDropdown;
    this.setState({
      formStates
    });
  }

  getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: 'all',
      forCommunity: true
    };

    if(this.state.memberLimit)
      params.limit = NON_PROFITS_LIMIT;

    if(!Utility.isEmpty(this.state.memberSearch))
      params.search = this.state.memberSearch;

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.registrants)
          this.setState({
            users: body.registrants
          });
      }
    });
  }

  getOrganizerDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: 'all',
      forCommunity: true
    };

    if(this.state.memberLimit)
      params.limit = NON_PROFITS_LIMIT;

    if(!Utility.isEmpty(this.state.memberSearch))
      params.search = this.state.memberSearch;

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.registrants)
          this.setState({
            users: body.registrants
          });
      }
    });
  }

  getGroups = () => {
    const params = {
      clientId: CLIENT_ID,
      userClientId: this.props.userData.id,
      forMyGroups: true
    };

    if(this.state.groupLimit)
      params.limit = GROUPS_LIMIT;

    if(!Utility.isEmpty(this.state.groupSearch))
      params.search = this.state.groupSearch;

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({ groups: body });
      }
    });
  };

  getCategoriesByModuleId = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      campaign_type_id: this.props.campaignSelectedTab
    };

    if(this.props.campaignSelectedTab !== "group"){
      Utility.sendRequest(CATEGORIES_BY_MODULE_ID, 1, params, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          this.setState({ categoriesList: body.data });
        }
      });
    }
  }

  getAllCampaignsChapters = () => {
    const params = {
      campaign_id: this.state.formStates.selectedCampaignId,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(ALL_CAMPAIGN_CHAPTERS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.data.length){
          body.data.unshift(this.idForNewChap);
          this.setState({ all_chapters_ids: body.data });
        }
      }
    });
  }

  getAllParentCampaigns = () => {
    if(this.props.forTestimony && this.props.testimonyData)
      this.setState({ campaignsList: this.props.testimonyData });
    else {
      const params = {
        module_id: CRM_MODULES.CAMPAIGNS,
        client_id: CLIENT_ID,
        user_client_id: this.props.userData.id,
        search: this.state.formStates.search,
        campaign_type_id: this.props.campaignSelectedTab
      };

      Utility.sendRequest(ALL_PARENT_CAMPAIGNS, 1, params, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          this.setState({ campaignsList: body.data });
        }
      });
    }
  }

  getCampaignsById = () => {
    const formStates = { ...this.state.formStates };

    const params = {
      campaign_id: this.state.formStates.selectedCampaignId,
      client_id: CLIENT_ID,
      user_client_id: this.props.userData.id,
    };

    Utility.sendRequest(CAMPAIGNS_BY_ID, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.data){
          this.setState({ newCampaign: { ...body.data, start_at: moment.utc(body.data.start_at).local(), end_at: moment.utc(body.data.end_at).local(),
            start_at_show: moment.utc(body.data.start_at),
            end_at_show: moment.utc(body.data.end_at)
          } }, () => {
            this.getUserDetails();
            this.getGroups();
            formStates.imagePreviewUrl = this.state.newCampaign.image_url;
            formStates.videoPreviewUrl = this.state.newCampaign.video_url;
            formStates.documentPreviewUrl = this.state.newCampaign.document_url;
            this.setState({
              formStates,
            });
          });
        }
      }
    });
  }

  uploadFiles = async (id, imageSignedURL, videoSignedURL, documentSignedURL) => {
    let imageURL = this.state.formStates.imagePreviewUrl;
    let videoURL = this.state.formStates.videoPreviewUrl;
    let documentURL = this.state.formStates.documentPreviewUrl;

    if (!Utility.isEmpty(this.state.formStates.imageFile.size)) {
      imageURL = await Utility.uploadFile(this.state.formStates.imageFile, imageSignedURL);
    }

    if (!Utility.isEmpty(this.state.formStates.videoFile.size)) {
      videoURL = await Utility.uploadFile(this.state.formStates.videoFile, videoSignedURL);
    }

    if (!Utility.isEmpty(this.state.formStates.documentFile.size)) {
      documentURL = await Utility.uploadFile(this.state.formStates.documentFile, documentSignedURL);
    }

    await this.updateCampaignMediaUrl(id, imageURL, videoURL, documentURL);
  }

  updateCampaignMediaUrl = (campId, imageURL, videoURL, documentURL) => {
    const params  = {
      image_url: imageURL,
      video_url: videoURL,
      document_url: documentURL
    };

    Utility.sendRequest(CAMPAIGNS + '/' + campId, 3, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(this.props.isChatFormOpen && !this.state.editCampaign) {
          if(this.props.chatFriend.group_id)
            this.shareCampaignsToChats(campId, this.props.chatFriend.group_id);
          else
            this.shareCampaignsToChats(campId);
        }
        if(!Utility.isEmpty(this.props.chatFriend)) {
          this.props.updateCampaignFormOpen(false);
        }
        this.props.doRefresh && this.props.doRefresh(Math.random());
        this.props.forTestimony && this.props.getTestimony();
        this.props.updateRefreshCampaignAPI(Math.random());
      }
    });
  }

  createCampaign = () => {
    this.setState({ showLoader: true });
    this.props.showLoader(true);

    Utility.sendRequest(CAMPAIGNS, 2, this.state.newCampaign, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      else {
        const campData = body;
        const afterSuccessCampaign = body;
        afterSuccessCampaign.createdOrUpdated = ' created ';
        afterSuccessCampaign.image_url = this.state.formStates.imagePreviewUrl;
        afterSuccessCampaign.author_image_url = this.props.userData.profile_image_url;
        afterSuccessCampaign.campaign_category = this.state.categoriesList.map(e => {
          return e.id == afterSuccessCampaign.category_id ? e.name : '';
        });
        this.state.categoriesList.forEach(e => {
          if (e.id === afterSuccessCampaign.category_id) afterSuccessCampaign.category_color = e.color_code;
        });

        if (!Utility.isEmpty(this.state.formStates.imagePreviewUrl) ||
        !Utility.isEmpty(this.state.formStates.videoPreviewUrl) ||
        !Utility.isEmpty(this.state.formStates.documentPreviewUrl)) {
          await this.uploadFiles(campData.id, campData.imageSignedURL, campData.videoSignedURL, campData.documentSignedURL);
        }
        if(!Utility.isEmpty(this.state.newCampaign.groups) && !this.state.editCampaign) {
         this.shareCampaignsToGroupChat(campData.id, this.state.newCampaign.groups);
        }

        this.setState({
          newCampaign: this.getFormData(),
          formStates: this.initialState()
        }, () => {
          
          this.setState({ afterSuccessCampaign }, () => {
            this.setState({ showSuccess: true });
            if(this.props.doRefresh)
              this.props.doRefresh(Math.random());
            this.props.onSuccessApi &&  this.props.onSuccessApi(this.props.campaignSelectedTab)
            this.props.onSuccessApi1 &&  this.props.onSuccessApi1(this.props.campaignSelectedTab)
          });
          this.getAllParentCampaigns();
        });
      }
      this.setState({ showLoader: false });
      this.props.showLoader(false);
    });
  }

  shareCampaignsToGroupChat = (campaign_id, groups) => {
    groups.map(items => {
      const params = {
        from_user_client_id: this.props.userData.id,
        group_id: items,
        campaign_id,
        message_type_id: MESSAGE_TYPE_IDS.CAMPAIGNS,
        user_id: this.props.userData.user_id,
      };

      Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        }
        else
        this.props.updateCampaignFormOpen(false);
      }); 
    });
  }

  shareCampaignsToChats = (campaign_id, group_id = '') => {

    const params = {
      from_user_client_id: this.props.userData.id,
      campaign_id,
      message_type_id: MESSAGE_TYPE_IDS.CAMPAIGNS,
      user_id: this.props.userData.user_id,
    };

    if(!Utility.isEmpty(group_id)) {
      params.group_id = group_id;
    }
    else {
      params.to_user_client_id = this.props.chatFriend.id;
    }

    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);
      this.props.updateIsCampSelFormOpen(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      else
      this.props.updateCampaignFormOpen(false);
    }); 
  }

  updateCampaign = () => {
    this.setState({ showLoader: true });
    this.props.showLoader(true);

    Utility.sendRequest(CAMPAIGNS + '/' + this.state.formStates.selectedCampaignId, 3, this.state.newCampaign, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
      else {
        if (this.props.refreshEventHome) {
          this.props.refreshEventHome(Math.random());
        }

        if(!Utility.isEmpty(this.state.newCampaign.imageSignedURL) && !Utility.isEmpty(this.state.newCampaign.videoSignedURL) || !Utility.isEmpty(this.state.newCampaign.documentSignedURL))
          await this.uploadFiles(this.state.formStates.selectedCampaignId, this.state.newCampaign.imageSignedURL, this.state.newCampaign.videoSignedURL, this.state.newCampaign.documentSignedURL);

        const afterSuccessCampaign = body;
        afterSuccessCampaign.createdOrUpdated = ' updated ';
        afterSuccessCampaign.image_url = this.state.formStates.imagePreviewUrl;
        afterSuccessCampaign.author_image_url = this.props.userData.profile_image_url;
        afterSuccessCampaign.campaign_category = this.state.categoriesList.map(e => {
          return e.id == afterSuccessCampaign.category_id ? e.name : '';
        });
        this.state.categoriesList.forEach(e => {
          if (e.id === afterSuccessCampaign.category_id) afterSuccessCampaign.category_color = e.color_code;
        });
        this.setState({
            newCampaign: this.getFormData(),
            formStates: this.initialState(),
          }, () => {
            this.setState({ afterSuccessCampaign }, () => {
              this.setState({ showSuccess: true });
              if(this.props.doRefresh)
                this.props.doRefresh(Math.random());
              this.props.onSuccessApi &&  this.props.onSuccessApi(this.props.campaignSelectedTab)
              
            });
        });
      }
      this.setState({ showLoader: false });
      this.props.showLoader(false);
    });
  }

  handleFormErrors = () => {
    const eventDatesValid = this.isEventDatesValid();

    //const isDescriptionEmpty = Utility.isRichTextEmpty('description', this.state.newCampaign.description, this.state.formName);
    let isDescriptionEmpty = false;
    const category = Utility.getElementById('category_id');
    const isCategoryDirty = category && category.innerText.toLowerCase() !== 'select category';

    let isFilePathEmpty;
    // if (isDescriptionEmpty) {
    //   const error = Utility.getElementById('descriptionError');
    //   if (error) error.innerText = 'Description is a required field';
    // }
    if(Utility.isEmpty(this.state.newCampaign.plain_description)) {
       isDescriptionEmpty = true;
      const error = Utility.getElementById('descriptionError');
       if (error) error.innerText = 'Description is a required field';
    }

    if (!isCategoryDirty) {
      const error = Utility.getElementById('category_idError');
      if (error) error.innerText = 'Category is a required field';
    }

    const ranking =  Utility.getElementById('ranking');
    const isRankingDirty = ranking && ranking.innerText.toLowerCase() !== 'select ranking';
    if (CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5' && !isRankingDirty) {
      const error = Utility.getElementById('rankingError');
      if (error) error.innerText = 'Ranking is a required field';
    }

    let isRepeatDirty = true;
    //CLIENT_ID AND EVENT
    if(CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5' && this.state.newCampaign.campaign_type_id === "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf"){
      const repeat = this.state.newCampaign.repeat_details ? this.state.newCampaign.repeat_details.frequency : [];
      isRepeatDirty = this.state.newCampaign.is_repeated === true ? (repeat.length > 0 ? true : false) : true;
      if (CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5' && !isRepeatDirty) {
        const error = Utility.getElementById('repeatMeetingError');
        if (error) error.innerText = 'Select at least one.';
      }
    }

    const isValid = Utility.showFormErrors(this.refs[this.state.formName], validateFields[this.state.formName], this.state.formName);
    if(Utility.isEmpty(this.state.newCampaign.embedded_image_link))
      isFilePathEmpty = Utility.isFilePathEmpty('image_url', this.state.formStates.imagePreviewUrl, this.state.formName);

    return ((isValid &&
            !isFilePathEmpty &&
             !isDescriptionEmpty &&
            eventDatesValid &&
            (!category || isCategoryDirty)) &&
            (CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5' && this.state.newCampaign.campaign_type_id === "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf" ? isRankingDirty : true) &&
            ((CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5' && this.state.newCampaign.campaign_type_id === "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf") ? isRepeatDirty : true)
            ? true : false);
  }

  isEventDatesValid = () => {
    let isFormValid = true;

    if (moment(this.state.newCampaign.start_at).diff(this.state.newCampaign.end_at) > 0) {
      toast.warning('End date should be greater than start date', { containerId: 'private' });
      isFormValid = false;
    }

    return isFormValid;
  }

  submit = (ev) => {
    ev.preventDefault();

    const isFormValid = this.handleFormErrors();

    if (!isFormValid){
      var elmnt = document.getElementById("form");
      elmnt.scrollIntoView();
      return;
    }

    if(this.state.formStates.isCreate || this.state.formStates.isNewChap)
      this.createCampaign();
    else
      this.updateCampaign();
  }

  selectedCampaignLogic = () => {
    if(!this.state.formStates.showDropdown && !this.state.formStates.isCreateOrEdit){
      const name = this.props.campaignSelectedTab === CAMPAIGN_TYPES_IDS.story
      ? NAMES_MAPPING.CAMPAIGN.story.parent : NAMES_MAPPING.CAMPAIGN.event.parent;

      return `Create a new ${Utility.capitalize(name)}`;
    }

    if(this.state.formStates.showDropdown && !this.state.formStates.isCreateOrEdit) {
      const name = this.props.campaignSelectedTab === CAMPAIGN_TYPES_IDS.story
      ? NAMES_MAPPING.CAMPAIGN.story.parent : NAMES_MAPPING.CAMPAIGN.event.parent;

      return `Add to an existing ${Utility.capitalize(name)}`;
    }

    else if(this.state.formStates.isCreate){
      return(
        <SingleCampaign
          heading={this.state.newCampaign.heading}
          imageUrl={(this.state.formStates.imagePreviewUrl ||
                    this.state.newCampaign.parent_image_url ||
                    this.state.newCampaign.embedded_image_link ||
                    this.state.newCampaign.image_url)}
          videoUrl={this.state.formStates.videoPreviewUrl ||
                    (this.state.newCampaign.embedded_video_link ||
                    this.state.newCampaign.video_url)}
        />
      );
    }

    else if(this.state.formStates.isEdit &&
      (this.state.formStates.isNewChap || this.state.formStates.isChapToggle)){
      return(
        <SingleCampaign
          heading={this.state.newCampaign.parent_heading ||
                   this.state.newCampaign.heading}
          imageUrl={this.state.newCampaign.parent_image_url ||
                    this.state.newCampaign.image_url ||
                    this.state.newCampaign.embedded_image_link}
        />
      );
    }

    else if(this.state.formStates.isEdit &&
           !this.state.formStates.isChapToggle &&
           !this.state.formStates.isNewChap){
      return(
        <SingleCampaign
          heading={this.state.newCampaign.heading || this.state.newCampaign.parent_heading}
          imageUrl={(this.state.formStates.imagePreviewUrl ||
                    this.state.newCampaign.image_url ||
                    this.state.newCampaign.embedded_image_link)}
        />
      );
    }

    else if(this.state.formStates.showDropdown
      && !Utility.isEmpty(this.state.formStates.search)
      && !this.state.formStates.isCreate){
      return(
        <SingleCampaign
          heading={this.state.newCampaign.parent_heading}
          imageUrl={this.state.newCampaign.parent_image_url ||
                    (this.state.newCampaign.embedded_image_link ||
                    this.state.newCampaign.image_url)}
        />
      );
    } else {
      return 'Create a new article';
    }
  }

  handleScheduleDate = (dateEvent, sourceName) => {
    // let scheduleDate = moment.utc(dateEvent._d);
    // scheduleDate = moment(moment(scheduleDate).format());

    // const newCampaign = { ...this.state.newCampaign };
    // newCampaign[sourceName] = scheduleDate._i;

    // this.setState({ newCampaign });

    let scheduleDate = moment.utc(dateEvent._d);
    scheduleDate = moment(moment(scheduleDate).format());

    const newCampaign = { ...this.state.newCampaign };
    newCampaign[sourceName] = scheduleDate._i;
    if(CLIENT_ID === '0bc8dc7c-b5a3-4344-b313-819ppzhau6b5')
      newCampaign[sourceName+"_show"] = dateEvent;

    this.setState({ newCampaign });  
  }

  markGroupCreate = (arg) => {
    this.setState({ isGroupCreate: arg });
  }

  onTabClick = async (e) => {
    await this.props.updateCampaignSelectedTab(e);
    await this.setState({ selectedTab: e });
    if(this.props.campaignSelectedTab !== e){
      await this.setState({ formStates: this.initialState() });
    }
  }

  paintEditOrCreateOnCallout = () => {
    if(this.props.campaignSelectedTab === 'group')
      return pwaModeEnabled
      ? this.state.isGroupCreate
        ? this.formTitleForPwa('Create')
        : this.formTitleForPwa('Edit')
      : this.state.isGroupCreate
        ? 'Create'
        : 'Edit';
    else
      return pwaModeEnabled
      ? this.state.formStates.isCreate
        ? this.formTitleForPwa('Create')
        : this.formTitleForPwa('Edit')
      : this.state.formStates.isCreate
        ? 'Create'
        : 'Edit';
  }

  formTitleForPwa = (arg) => {
    switch (this.props.campaignSelectedTab) {
      case CAMPAIGN_TYPES_IDS.story:
        return arg + ' ' + Utility.capitalize(NAMES_MAPPING.CAMPAIGN.story.parent);
      case CAMPAIGN_TYPES_IDS.event:
        return arg + ' Event';
      case 'group':
        return arg + ' Group';
      default:
    }
  }

  getName = () => {
    switch (this.props.campaignSelectedTab) {
      case CAMPAIGN_TYPES_IDS.story:
      return this.state.formStates.isEdit ?
            Utility.capitalize(NAMES_MAPPING.CAMPAIGN.story.child) :
            Utility.capitalize(NAMES_MAPPING.CAMPAIGN.story.parent);
      case CAMPAIGN_TYPES_IDS.event:
        return this.state.formStates.isEdit ?
              Utility.capitalize(NAMES_MAPPING.CAMPAIGN.event.child) :
              Utility.capitalize(NAMES_MAPPING.CAMPAIGN.event.parent);
    }
  }

  getChapter = () => {
    return this.props.campaignSelectedTab === CAMPAIGN_TYPES_IDS.story
      ? NAMES_MAPPING.CAMPAIGN.story.child
      : NAMES_MAPPING.CAMPAIGN.event.child;
  }

  handleJumpAfterSuccessClick = () => {
    return this.state.afterSuccessCampaign.parent_id
           || this.state.afterSuccessCampaign.id;
  }

  render() {
    const chapOrArticle = this.getName();
    const newChapter = `Create a new ${this.getChapter()}`;

    return(
      <>
        { this.getFormOpenOrClose() && !this.state.showSuccess &&
        <div style={this.props.style} className={`col-6 px-0 bg-white page-sidebar-outer page-sidebar-outer-article ${this.props.position}`}>
          <div className="d-lg-none d-block py-3 pwa-callout-decorator-sec" >
            <div className="pwa-callout-decorator" onClick={this.handleIsFormOpen} />
          </div>
          <div id="acticle-sidebar-inner" className="page-sidebar-inner h-100 overflow-auto scroll-y" >
            <div onClick={this.handleIsFormOpen} className="page-sidebar-head border-bottom align-items-center page-sidebar-head-article">
              <CircleOutlinedAddIcon />
                <span className="font-bold display-8 ml-2" >
                  {this.props.forTestimony ? 'Testimony' : this.paintEditOrCreateOnCallout()}
                </span>
              <CloseIcon
                mainClass="ml-auto cursor-pointer"
                handleClick={this.handleIsFormOpen}/>
            </div>
            <div id="form" className="page-sidebar-body page-sidebar-body-article" >
                <div
                className={`${this.props.forTestimony && 'hide-tab'} client-profile-inner p-0 article-page-tabs article-create-tabs`} style={{ position: 'inherit' }} >
                  <Tabs id="sidebar-tabs" activeKey={this.props.campaignSelectedTab} onSelect={(k) => this.onTabClick(k)}>
                    <Tab eventKey={CAMPAIGN_TYPES_IDS.story}>
                      {
                        this.props.campaignSelectedTab === CAMPAIGN_TYPES_IDS.story &&
                        <CreateForm
                          {...this.state}
                          key={this.props.campaignSelectedTab}
                          ref={this.state.formName}
                          name={'Article'}
                          idForNewChap={this.idForNewChap}
                          handleChapterDropdown={this.handleChapterDropdown}
                          formStates={this.state.formStates}
                          toggleDropdown={this.toggleDropdown}
                          selectedCampaignLogic={this.selectedCampaignLogic}
                          handleSearch={this.handleSearch}
                          changeDescription={this.changeDescription.bind(this)}
                          handleEditClick={this.handleEditClick}
                          handleCreateClick={this.handleCreateClick}
                          handleInputChange={this.handleInputChange}
                          handleFileChange={this.handleFileChange}
                          removeUploadedFile={this.removeUploadedFile}
                          handleDropdown={this.handleDropdown}
                          handleDropdownRanking={this.handleDropdownRanking}
                          getSelectedMembersIds={this.getSelectedMembersIds}
                          getSelectedOrganizersIds={this.getSelectedOrganizersIds}
                          getSelectedGroupsIds={this.getSelectedGroupsIds}
                          newChapter={newChapter}
                          showLoader={this.state.showLoader}
                          chapOrArticle={chapOrArticle}
                          openMemberCallout={this.openMemberCallout}
                          openOrganizerCallout={this.openOrganizerCallout}
                          openGroupCallout={this.openGroupCallout}
                          handleIsFormOpen={this.handleIsFormOpen}
                          submit={this.submit}
                          forTestimony={this.props.forTestimony}
                          groupData={this.props.groupData}
                          subCampaign={'SUB_CAMPAIGN'}
                        />
                      }
                    </Tab>
                    <Tab eventKey="group">
                      <GroupForm
                        key={this.props.campaignSelectedTab}
                        markGroupCreate={this.markGroupCreate}
                        handleIsFormOpen={this.handleIsFormOpen}
                        doRefresh={this.props.doRefresh}
                        categoriesList = {this.state.categoriesList}
                        handleDropdown={this.handleDropdown}
                      />
                    </Tab>
                    <Tab eventKey={CAMPAIGN_TYPES_IDS.event}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        {
                          this.props.campaignSelectedTab === CAMPAIGN_TYPES_IDS.event &&
                          <CreateForm
                            {...this.state}
                            key={this.props.campaignSelectedTab}
                            ref={this.state.formName}
                            name={'Event'}
                            idForNewChap={this.idForNewChap}
                            toggleDropdown={this.toggleDropdown}
                            changeDescription={this.changeDescription.bind(this)}
                            selectedCampaignLogic={this.selectedCampaignLogic}
                            handleSearch={this.handleSearch}
                            handleEditClick={this.handleEditClick}
                            handleCreateClick={this.handleCreateClick}
                            handleInputChange={this.handleInputChange}
                            handleFileChange={this.handleFileChange}
                            removeUploadedFile={this.removeUploadedFile}
                            handleDropdown={this.handleDropdown}
                            handleDropdownEventSchedule={this.handleDropdownEventSchedule}
                            handleDropdownRanking={this.handleDropdownRanking}
                            handleFrequency={this.handleFrequency}
                            handleFrequencyScheduleDate={this.handleFrequencyScheduleDate}
                            getSelectedGroupsIds={this.getSelectedGroupsIds}
                            getSelectedMembersIds={this.getSelectedMembersIds}
                            getSelectedOrganizersIds={this.getSelectedOrganizersIds}
                            handleChapterDropdown={this.handleChapterDropdown}
                            newChapter={newChapter}
                            chapOrArticle={chapOrArticle}
                            subCampaign={this.getChapter()}
                            submit={this.submit}
                            handleScheduleDate={this.handleScheduleDate}
                            showEventFields={true}
                            openMemberCallout={this.openMemberCallout}
                            handleIsFormOpen={this.handleIsFormOpen}
                            openOrganizerCallout={this.openOrganizerCallout}
                            openGroupCallout={this.openGroupCallout}
                            subCampaign={'SUB_CAMPAIGN_EVENT'}
                          />
                        }
                      </MuiPickersUtilsProvider>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        }

        { this.state.isMemberCalloutOpen &&
          <SideCallout
            position='position-sidebar-fixed create-campaign-margin'
            closeCallOut={this.closeMemberCallout}
            calloutHeading='Select Community Members'
            calloutHeadingImg={require('assets/images/icons/person_plus.svg')}>
            <InputField
              handleChange={this.handleMemberSearch}
              type="text"
              className="search-input-callout mb-5"
              placeholder="Search Community Members…"
            />
            {Utility.isEmpty(this.state.users) &&
              <div className="d-flex align-items-center justify-content-center mt-5" >
                <div className="px-5" >
                  <span className="font-bold display-6">No record found</span>
                </div>
              </div>
            }
            <TileSelection
              inCallout={true}
              selected={this.state.newCampaign.non_profits}
              getSelectedIds={this.getSelectedMembersIds}
              close={this.closeMemberCallout}
              showAll={this.removeMemberLimit}
              items={this.state.users}
              isSearch={Utility.isEmpty(this.state.memberSearch)}
              showClose={true}
            />
          </SideCallout>
        }

        { this.state.isOrganizerCalloutOpen &&
          <SideCallout
            position='position-sidebar-fixed create-campaign-margin'
            closeCallOut={this.closeOrganizerCallout}
            calloutHeading='Select Community Members'
            calloutHeadingImg={require('assets/images/icons/person_plus.svg')}>
            <InputField
              handleChange={this.handleOrganizerSearch}
              type="text"
              className="search-input-callout mb-5"
              placeholder="Search Community Members…"
            />
            <TileSelection
              inCallout={true}
              selected={this.state.newCampaign.organizers}
              getSelectedIds={this.getSelectedOrganizersIds}
              close={this.closeOrganizerCallout}
              showAll={this.removeOrganizerLimit}
              items={this.state.users}
              isSearch={Utility.isEmpty(this.state.organizerSearch)}
              showClose={true}
            />
          </SideCallout>
        }

        { this.state.isGroupCalloutOpen &&
          <SideCallout
            position='position-sidebar-fixed create-campaign-margin'
            closeCallOut={this.closeGroupCallout}
            calloutHeading='Select Groups'
            calloutHeadingImg={require('assets/images/icons/person_plus.svg')}>
            <InputField
              handleChange={this.handleGroupSearch}
              type="text"
              className="search-input-callout mb-5"
              placeholder="Search Groups…"
            />
            <TileSelection
              inCallout={true}
              selected={this.state.newCampaign.groups}
              getSelectedIds={this.getSelectedGroupsIds}
              close={this.closeGroupCallout}
              showAll={this.removeGroupLimit}
              items={this.state.groups}
              isSearch={Utility.isEmpty(this.state.groupSearch)}
              showClose={true}
            />
          </SideCallout>
        }
        
        { 
        
          this.state.showSuccess && !this.props.isChatFormOpen &&
          <Success
            position={this.props.position}
            closeCallOut={this.closeConfirmationCallout}
            calloutHeading='Confirmation'
            imageUrl={!Utility.isEmpty(this.props.userData.city_logo_url)
              ? this.props.userData.city_logo_url
              : require("assets/images/users/user_no_image.png")}
            createdOrUpdated={this.state.afterSuccessCampaign.createdOrUpdated}
            campaignSelectedTab={this.props.campaignSelectedTab}
            successOf={this.props.forTestimony ? 'Testimony' : chapOrArticle}
            forTestimony={this.props.forTestimony}
            forCampaign={true}
            calloutCloseIcon={this.props.forTestimony && <ChevronDownIcon />}
            link={chapOrArticle==="Event" ? `/event_details/${this.handleJumpAfterSuccessClick()}` : `/campaign/${this.handleJumpAfterSuccessClick()}` }
            handleAddClick={() => {
              this.setState({
                showSuccess: false
              });
            }}
            handleEditClick={
              () => {
                this.handleEditClick(this.state.afterSuccessCampaign.id);
                this.setState({
                  showSuccess: false
                });
              }
            }
            card={
              <div className="home-page-city-ethos">
                <div className="landing-page-stories">
                  <SuggestedReadsCard
                    fromSuccess={true}
                    stories={[this.state.afterSuccessCampaign]}
                  />
                </div>
            </div>
            }
          />
        }
      </>
    );
  }
}

const mapActionToProps = { showLoader, updateCampaignFormOpen, updateCampaignSelectedTab, updateIsCampSelFormOpen, updateCampaignFormSelectedId, updateRefreshCampaignAPI };

const mapStateToProps = (state) => ({
  userData: state.userData,
  isCampaignFormOpen: state.isCampaignFormOpen,
  isChatFormOpen: state.isChatFormOpen,
  chatFriend: state.chatFriend,
  campaignSelectedTab: state.campaignSelectedTab,
  campaignId: state.campaignFormSelectedId,
});

export default connect(mapStateToProps, mapActionToProps)(CreateCampaign);
