import React from 'react';

const ContactPage = () => {
  return (
    <>
      <div className='container'>
        <h1 className='font-bebas text-primary font-60 my-4 font-sm-42 text-center mb-0'>
          Get in Touch
        </h1>
        <p
          style={{ color: '#606060' }}
          className='font-16 col-lg-7 mx-auto text-center '
        >
          We’d love to hear from you!{' '}
          <span className='font-weight-bold'>
            Please fill out the form below
          </span>{' '}
          and we’ll be in touch as soon as possible.
        </p>
      </div>
    </>
  );
};

export default ContactPage;