import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateSelectedModule } from 'shared/actions';
import { PUBLIC_URL, SM_CLIENT_ID, NAME_SILHOUETTE_CLIENT_ID, ETHOS_CLIENT_ID } from 'config/constants';
import { Utility } from 'utils';
import { LandingPage } from 'scenes/home';
import { SubDomainHome } from 'scenes/sub_domain_home';
import ProfileContainer from 'scenes/profile';
import PwaProfileContainer from 'scenes/profile/pwa';
import SelectAccount from '../scenes/users/components/select_account';
import AppLayout from 'shared/components/layout';
import HomePageLayout from 'shared/components/home_page_layout';
import JoinLayout from 'shared/components/layouts/join_layout';
import faq from 'shared/components/faq';
import contact from 'shared/components/contact';
import TermsOfServices from 'shared/components/terms_and_privacy/terms_of_services';
import PrivacyPolicy from 'shared/components/terms_and_privacy/privacy_policy';
import SignIn from 'scenes/users/components/sign_in';
import SignUp from 'scenes/users/components/registration';
import ForgotPassword from 'scenes/users/components/forgot_password/index.js';
import CreatePassword from 'scenes/users/components/create_password';
import { PwaLandingPage } from 'scenes/users/components/pwa';
import  HomePage from 'scenes/users/components/home/index.js';
import  Login  from 'scenes/users/components/pwa/sign_in/login';
import SignUpContainer from 'scenes/users/components/pwa/sign_up';
import { SplashScreen } from 'scenes/users/components/pwa/sign_up/splash_screen';
import ForgotPasswordPWA from 'scenes/users/components/pwa/forgot_password/index';
import ActivateUser from 'scenes/users/components/activate_user';
import ProfileEdit from 'scenes/profile/components/profile_edit';
import NetworkContainer from 'scenes/networks/new_index';
import CommunicationsContainer from 'scenes/communications';
import EmptyLayout from 'shared/components/empty_layout';
import GiftCard from 'scenes/tools/toolbox/gift_cards/public';
import CampaignContainer from 'scenes/campaigns';
import FinanceContainer from 'scenes/finances';
import GroupChat from 'shared/components/group_chats.js';
import StatisticsContainer from 'scenes/statistics';
import ToolsContainer from 'scenes/tools';
import ShopContainer from 'scenes/shops';
import TopicDetail from 'scenes/topics/index.js';
import Campaign from 'scenes/campaigns/web';
import { CLIENT_ID } from 'config/constants/config';
import { ViewAgenda } from 'shared/components/v2/campaign/components/view_agenda';
import EventHome from 'scenes/campaigns/components/event_home';
import MediaView from 'scenes/campaigns/components/media_view.js';
import GroupHome from 'scenes/groups';
import GroupDetail from '../scenes/groups/detail/index';
import cookie from 'react-cookies';
import { IconsScreen } from 'shared/icons/AllIcons';

import CustomAppRoute from './custom_route';
import AppRoute from './app_route';

// const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
//   <Route {...rest} render={ props => (
//     <Layout {...rest}>
//       <Component {...rest} {...props}/>
//     </Layout>
//   )} />
// );

 class SMRoutes extends Component {
  componentDidUpdate = () => {
    let selectedModule = this.props.crmModules.find((item) => {
      return item.name.toLowerCase() === this.props.location.pathname.split('/')[1];
    });

    selectedModule = !Utility.isEmpty(selectedModule) ? { id: selectedModule.id, name: selectedModule.name, image_url: selectedModule.image_url } : {};

    this.props.updateSelectedModule(selectedModule);
  }

  render() {
    let config; let path;
    const skipLogin = ['gift_cards'];
    const actualPath = this.props.location.pathname;
    const createHistory = this.props.history;
    const pathArray = actualPath.split('/');
    const subDomain = cookie.load('subDomain');
    const userAuth = {
      isAuthenticated: (cookie.load('sessionToken') !== undefined ? true : false)
    };
    const pwaModeEnabled = Utility.pwaModeEnabled();
    const profileContainer = pwaModeEnabled ? PwaProfileContainer : ProfileContainer ;

    const handlePwaLanding = () => {
      return ETHOS_CLIENT_ID === CLIENT_ID ? LandingPage :
        Utility.pwaModeEnabledNew() ? PwaLandingPage : LandingPage;
    };

    if (pathArray.length === 3 && (skipLogin.includes(pathArray[1]))) path = '/' + pathArray[1];
    else path = actualPath;

    if (((CLIENT_ID === SM_CLIENT_ID) && ((path === '/users/sign_up') || (path === '/users/sign_in')))) {
      if (!userAuth.isAuthenticated) {
        return <Redirect to='/'/>;
      }else{
        return <Redirect to='/home'/>;
      }
    }

    if (((path === '/users/sign_up') || (path === '/users/sign_in')) && userAuth.isAuthenticated) {
      return <Redirect to='/home' />;
    }

    if (!userAuth.isAuthenticated && !PUBLIC_URL.includes(path)) {
      return pwaModeEnabled ? <Redirect to='/sign_in'/>
        : (CLIENT_ID === SM_CLIENT_ID) ? <Redirect to='/'/> : <Redirect to='/users/sign_in'/>;
    } else {
      config =
        <Switch>
          {
            Utility.isEmpty(subDomain)
            ? pwaModeEnabled ? <AppRoute exact path='/' layout={HomePageLayout}   component={handlePwaLanding()} history={createHistory} />
            : <AppRoute exact path='/' layout={HomePageLayout} component={LandingPage} history={createHistory} />
            : <AppRoute exact path='/' layout={JoinLayout} component={SubDomainHome} history={createHistory} />
          }
          <AppRoute path='/select_account' layout={EmptyLayout} component={SelectAccount} history={createHistory}/>
          <AppRoute exact path='/sign_in' layout={EmptyLayout} component={Login} history={createHistory} />
          <AppRoute exact path='/forgot_password' layout={EmptyLayout} component={ForgotPasswordPWA} history={createHistory} />
          <AppRoute exact path='/forgot_password' layout={EmptyLayout} component={ForgotPassword} history={createHistory} />
          <AppRoute exact path='/create_password' layout={EmptyLayout} component={CreatePassword} history={createHistory} />
          <AppRoute exact path='/sign_up' layout={EmptyLayout} component={SignUpContainer} history={createHistory} />
          <AppRoute exact path='/home' layout={AppLayout} component={HomePage} history={createHistory} />
          <AppRoute exact path='/splash_screen' layout={EmptyLayout} component={SplashScreen} history={createHistory} />
          <AppRoute exact path='/event' layout={EmptyLayout} component={ViewAgenda} history={createHistory} />
          <AppRoute exact path='/terms_of_services' layout={EmptyLayout} component={TermsOfServices} history={createHistory} />
          <AppRoute exact path='/faq' layout={EmptyLayout} component={faq} history={createHistory} />
          <AppRoute exact path='/contact' layout={EmptyLayout} component={contact} history={createHistory} />
          <AppRoute exact path='/home' layout={EmptyLayout} component={HomePage} history={createHistory} />
          <AppRoute exact path='/privacy_policy' layout={EmptyLayout} component={PrivacyPolicy} history={createHistory} />
          <AppRoute exact path='/users/sign_in' layout={EmptyLayout} component={SignIn} history={createHistory} />
          <AppRoute exact path='/users/sign_up' layout={EmptyLayout} component={SignUp} history={createHistory} />
          <AppRoute exact path='/users/forgot_password' layout={EmptyLayout} component={ForgotPassword} history={createHistory} />
          <AppRoute exact path='/users/create_password' layout={EmptyLayout} component={CreatePassword} history={createHistory} />
          <AppRoute exact path='/users/activate_user' layout={EmptyLayout} component={ActivateUser} history={createHistory} />
          <AppRoute exact path='/profile' layout={AppLayout} component={profileContainer} history={createHistory} />
          <AppRoute exact path='/profile/:id' layout={AppLayout} component={profileContainer} history={createHistory} />
          <AppRoute exact path='/profile/edit' layout={AppLayout} component={ProfileEdit} history={createHistory} />
          <AppRoute exact path='/communications' layout={AppLayout} component={CommunicationsContainer} history={createHistory} />
          <AppRoute exact path='/networks' layout={AppLayout} component={NetworkContainer} history={createHistory} />
          <AppRoute exact path='/gift_cards/:id' layout={EmptyLayout} component={GiftCard} history={createHistory} />
          <AppRoute exact path='/campaigns' layout={AppLayout} component={CampaignContainer} history={createHistory} />
          <AppRoute exact path='/finances' layout={AppLayout} component={FinanceContainer} history={createHistory} />
          <AppRoute exact path="/statistics" layout={AppLayout} component={StatisticsContainer} history={createHistory} />
          <AppRoute exact path="/tools" layout={AppLayout} component={ToolsContainer} history={createHistory} />
          <AppRoute exact path="/shops" layout={AppLayout} component={ShopContainer} history={createHistory} />
          <AppRoute exact path="/campaign/:id" layout={AppLayout} component={Campaign} history={createHistory} />
          <AppRoute exact path="/agenda/:id" layout={AppLayout} component={Campaign} history={createHistory} />
          <AppRoute exact path="/groups" layout={AppLayout} component={GroupHome} history={createHistory} />
          <AppRoute exact path="/groups/:id" layout={AppLayout} component={GroupDetail} history={createHistory} />
          <AppRoute exact path="/events" layout={AppLayout} component={EventHome} history={createHistory} />
          <AppRoute exact path="/media" layout={AppLayout} component={MediaView} history={createHistory} />
          <AppRoute exact path="/topics/:id" layout={AppLayout} component={TopicDetail} history={createHistory} />
          <AppRoute exact path="/group_chat" layout={EmptyLayout} component={GroupChat} history={createHistory} />
          <AppRoute exact path="/icons" layout={EmptyLayout} component={IconsScreen} history={createHistory} />
          <CustomAppRoute/>
        </Switch>;
    }

    return (
      <div>
        {config}
      </div>
    );
  }
}

const mapActionToProps = { updateSelectedModule };

const mapStateToProps = (state) => ({ crmModules: state.crmModules });

export default connect(mapStateToProps, mapActionToProps)(SMRoutes);
