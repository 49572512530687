import { combineReducers } from "redux";
import * as sharedReducer from 'shared/reducers';
import { updateProductAttribute } from 'scenes/shops/reducers';
import { IsCourseDropdown, IsCourseSidebar,IsCitizenSidebarOpen, HeaderSearchBar,IsPageCourse,UserProfileId } from "./shared/reducers";

export const allReducers = combineReducers({
  userData: sharedReducer.updateUserData,
  guestData: sharedReducer.updateGuestData,
  publicUserData: sharedReducer.updatePublicUserData,
  clientTypes: sharedReducer.updateClientTypes,
  sdGoals: sharedReducer.updateSDGoals,
  isLoading: sharedReducer.showLoader,
  crmModules: sharedReducer.updateCrmModules,
  selectedModule: sharedReducer.updateSelectedModule,
  selectedMenuItem: sharedReducer.updateSelectedMenuItem,
  selectedGroup: sharedReducer.updateSelectedGroup,
  edit: sharedReducer.editComponent,
  productId: sharedReducer.updateProductID,
  productAttributes: updateProductAttribute,
  selectedFilters: sharedReducer.updateSelectedFilters,
  selectedModuleMenu: sharedReducer.updateSelectedModuleMenu,
  accountList: sharedReducer.updateAccountList,
  neighborhoodState: sharedReducer.openNeighborhoodForm,
  isCampaignFormOpen: sharedReducer.updateCampaignFormOpen,
  isNeighborhoodFormOpen: sharedReducer.updateIsNeighborhoodFormOpen,
  isCampSelFormOpen: sharedReducer.updateIsCampSelFormOpen,
  openCityPurchase: sharedReducer.updateOpenCityPurchase,
  campaignSelectedTab: sharedReducer.updateCampaignSelectedTab,
  campaignFormSelectedId: sharedReducer.updateCampaignFormSelectedId,
  isChatFormOpen: sharedReducer.updateIsChatFormOpen,
  chatFriend: sharedReducer.updateChatFriend,
  isSessionModalOpen:sharedReducer.updateIsSessionModalOpen,
  seeAll: sharedReducer.updateSeeAll,
  refreshCampaignApi: sharedReducer.updateRefreshCampaignAPI,
  updateLessons: sharedReducer.updateLessons,
  isPlanUpgraded: sharedReducer.updatePlanUpgraded,
  isAdminAccessRequestFormOpen: sharedReducer.updateIsAdminAccessRequestFormOpen,
  isAcceptedAdminRequestFormOpen: sharedReducer.updateIsAcceptedAdminRequestFormOpen,
  isPlusIconClicked: sharedReducer.openOrClosePlusIcon,
  currentFooterLink: sharedReducer.updateFooterLink,
  cart: sharedReducer.cartReducer,
  isUserDistracted: sharedReducer.updateUserDistracted,
  extTopic: sharedReducer.updateExtTopic,
  isSessionStart: sharedReducer.updateSessionStart,
  extUserId: sharedReducer.updateExtUserId,
  isCampaignVideoPlaying: sharedReducer.updateCampaignVideoPlaying,
  category_listing_info: sharedReducer.updateCategoryListingInfo,
  newCorporateJoined: sharedReducer.updateNewCorporateJoined,
  isExtensionReportOpen: sharedReducer.updateExtensionModalReport,
  isCourseSideBar : IsCourseSidebar,
  isCourseDropDown : IsCourseDropdown,
  headerSearchbar:HeaderSearchBar,
  isCitizenSidebarOpen:IsCitizenSidebarOpen,
  isPageCourse:IsPageCourse,
  userProfileId:UserProfileId
});
