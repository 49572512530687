import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';
// import { FaMapMarked } from "react-icons/fa";
import { DropDownList } from './drop_down_list';
import { MenuCount } from './menu_count';
import { ReactComponent as GoPrimitiveDot } from 'assets/images/icons/profile_active.svg';
import AllowPermission from './allow_permission.js';
import { PERMISSIONS,NAME_SILHOUETTE_CLIENT_ID } from 'config/constants';
import { Utility } from 'utils';
import DisplayShopsAction from '../../scenes/shops/components/displayShopsAction.js'
import { CLIENT_ID } from '../../config/constants/config';

const LISTITEM_ACTIONS = [
  { id: 1, name: 'Add category', iconName: 'add_icon.svg' }
];
export default class MenuShops extends Component {


  handleMenuDisplay = (menuArr, sub, key) => {
    const menuName = sub.includes("_") ?
      Utility.capitalize(sub.split("_")[0]) + " " +
      Utility.capitalize(sub.split("_")[1]) :
      Utility.capitalize(sub);

    if (sub.toLowerCase() === 'categories') {
      return <AllowPermission permissions={PERMISSIONS.CREATE_CATEGORY}>
        <div className="font-18-medium ml-4 pt-4 pb-2" key={`${key}-${sub}`}>
          {menuName}
        </div>
      </AllowPermission>;
    }
    else {
      if (!Utility.isEmpty(menuArr[sub.toLowerCase()] ||
        sub.toLowerCase() === 'custom_groups')) {
        return <div className="font-18-medium ml-4 pt-4 pb-2" key={`${key}-${sub}`}>
          {menuName}
        </div>;
      }
    }
  }


  render() {
    const selectedModule = this.props.selectedModule;
    return (
      <Col xl={3} lg={4} xs={12} className="h-100">
        <div className="overflow-auto h-md-100 side-menu-shadow">
          <div className="align-items-center d-lg-none py-4 px-3 shar-shadow-inset position-relative border-bottom">
            <span className="text-captalize d-inline d-lg-none text-dark display-7 font-bold ml-2" >{selectedModule.name}</span>
          </div>
          <div className="communication-nav h-100 scroll-y">
            <div className="communication-header">
              <Row className="no-gutters align-items-baseline">
                <Col xs={11}>
                  <div className="display-6 display-xl-5">{selectedModule.name}</div>
                </Col>
                <Col xs={1} className="text-right"><IoIosArrowDown /></Col>
              </Row>
            </div>
            <div className="border-padding">
              <div className="border-bottom-dotted"></div>
            </div>
            <div className="nav-padding">
              <Row className="align-items-center no-gutters justify-content-between">
                <div className="p-0 dropdown_primary">
                  {
                    this.props.isButton &&
                    <Button variant="primary" type="submit" className="secondary-menu-dropdown w-auto" onClick={this.props.handleDropdownAction}>{this.props.dropdownItems[0].name}</Button>
                  }
                  {
                    !this.props.isButton && this.props.isShowButton &&
                    <DropDownList
                      dropDownClassName="secondary-menu-dropdown cursor-pointer"
                      toggleButtonClassName="border-transparent font-20-bold secondary-menu-dropdown-img"
                      menuClassName="lists-position font-bold"
                      dropdownItems={this.props.dropdownItems}
                      isMenuAction={this.props.isMenuAction}
                      handleDropdownAction={(item) => this.props.handleDropdownAction(item)}
                    />
                  }
                </div>
                {/*
              selectedModule.id === 2 &&
              <Button variant="link" className="cursor-pointer p-0 ml-5 map-button"
                onClick={this.props.showMap}><FaMapMarked/></Button>
            */}
              </Row>
            </div>
            {
              Array.isArray(this.props.menus) &&
              <>
                <div className="font-18-medium ml-4 pt-4 pb-2"></div>
                <ul className="networks line-height-sm mb-5 mb-lg-2">
                  {
                    this.props.menus.map((item, index) => {
                      return (
                        <>
                          <MenuItem
                            key={index + "menuItems"}
                            menuItem={item}
                            isMenuActive={this.props.selectedMenuItemId === item.id}
                            handleMenuItemClick={this.props.handleMenuItemClick}
                            showCount={this.props.showCount}
                            showZeroInCount={this.props.showZeroInCount}
                            showCategoryList={this.props.showCategoryList}
                            openCategory={this.props.openCategory}
                            campaignCategoryList={this.props.campaignCategoryList}
                            categorySection={this.props.categorySection}
                            selectedMenuItemId={this.props.selectedMenuItemId}
                            displayShopCategory={this.props.displayCategory}
                            handleListItemAction={this.props.handleListItemAction}
                            handleProductTypeActions={this.props.handleProductTypeActions}
                            shops={this.props.shops}
                          />
                          { this.props.shops &&
                          <div className="w-100 px-5">
                            <div
                              className="align-items-center px-2 my-3 cursor-pointer" onClick={()=>{this.props.handleCategoryFormClick(item)}} >
                              <img
                                src={require("assets/images/icons/add_icon.svg")}
                                className="mr-3 menu-add-icon"
                                alt="Add icon"
                              />
                              <span className="display-10" >
                                Add Category
                              </span>
                            </div>
                          </div>
                    }
                        </>
                      );
                    })
                  }
                  { this.props.shops &&
                   <div className="w-100 px-3">
                            <div
                              className="align-items-center px-2 my-3 cursor-pointer" onClick={()=>{this.props.handleProductTypeFormClick()}} >
                              <img
                                src={require("assets/images/icons/add_icon.svg")}
                                className="mr-3 menu-add-icon"
                                alt="Add icon"
                              />
                              <span className="display-10" >
                                Add Type
                              </span>
                            </div>
                          </div>
                  } 
                    </ul>
              </>
            }
            <div className="border-padding">
              <div className="border-bottom-dotted"></div>
            </div>
            <div className="communication-header pt-2 pb-4">
              <Row className="no-gutters align-items-baseline">
                <Col xs={11}>
                  <div className="display-6 display-xl-5">Administrative</div>
                </Col>
                {/* <Col xs={1} className="text-right"><IoIosArrowDown /></Col> */}
              </Row>
            </div>
            {
              (this.props.isAdminId && Array.isArray(this.props.adminMenus)) &&
              <>
                {/* <div className="font-18-medium ml-4 pt-4 pb-2"></div> */}
                <ul className="networks line-height-sm mb-5 mb-lg-2">
                  {
                    this.props.adminMenus.map((item, index) => {
                      return (
                        <>
                          <AdminMenuItem
                            key={index + "menuItems"}
                            menuItem={item}
                            isMenuActive={this.props.selectedMenuItemId === item.id}
                            handleMenuItemClick={this.props.handleMenuItemClick}
                            showCount={this.props.showCount}
                            showZeroInCount={true}
                            selectedMenuItemId={this.props.selectedMenuItemId}
                            shops={this.props.shops}
                          />
                        </>
                      );
                    })
                  }
                    </ul>
              </>
            }
            {
              !Array.isArray(this.props.menus) && selectedModule.id === 2 &&
              <>
                <ul className="networks line-height-sm mb-5 mb-lg-2">
                  {
                    this.props.subMenus.map((sub, key) => {
                      return (
                        <>
                          {this.handleMenuDisplay(this.props.menus, sub, key)}
                          {
                            this.props.menus[sub.toLowerCase()].map((item, index) => {
                              item['parent_menu'] = sub.toLowerCase();
                              return (
                                <>
                                  <MenuItem
                                    index={`${key}${index}`}
                                    key={`${key}${index}item`}
                                    menuItem={item}
                                    isMenuActive={this.props.selectedMenuItemId === item.id}
                                    handleMenuItemClick={this.props.handleMenuItemClick}
                                    showCount={this.props.showCount}
                                    showZeroInCount={this.props.showZeroInCount}
                                    handleListItemAction={this.props.handleListItemAction}
                                  />
                                </>
                              );
                            })
                          }
                          {sub.toLowerCase() === 'categories' &&
                            <AllowPermission permissions={PERMISSIONS.CREATE_CATEGORY}>
                              {
                                this.props.categoryButton &&
                                <div className="w-100 px-4">
                                  <div
                                    className="align-items-center px-2 my-3 cursor-pointer" onClick={this.props.handleCategoryFormClicked} >
                                    <img
                                      src={require("assets/images/icons/add_icon.svg")}
                                      className="mr-3 menu-add-icon"
                                      alt="Add icon"
                                    />
                                    <span className="display-10" >
                                      Create Category
                              </span>
                                  </div>
                                </div>
                              }
                            </AllowPermission>
                          }

                          {/* sub.toLowerCase() === 'custom_groups' &&
                      <div className="w-100 px-4">
                        <div className="align-items-center px-2 my-3 cursor-pointer">
                          <img
                          src={require("assets/images/icons/add_icon.svg")}
                          className="mr-3 menu-add-icon"
                          alt="Add icon"
                          />
                          <span className="display-10" >
                            Create Group
                          </span>
                        </div>
                      </div>
                    */}
                        </>
                      );
                    })
                  }
                </ul>
              </>
            }

            {/* <div className="mt-5 px-2">
              <div className="border-bottom-dotted pt-4"></div>
            </div>
            <div className="recent-activity">
              <DropDownList
                dropDownClassName="custom-dropdown font-18-medium pl-0 w-100"
                toggleButtonClassName="border-transparent font-18-medium align-inherits w-100"
                menuClassName="lists-position"
                buttonTitle="Recent Activity"
                dropdownActions={this.props.dropdownActions}
              />
            </div>
            <div className="recent-activity-padding">
              <ul>
                <li className="display-xl-7 display-8 font-bold py-2">All Communications</li>
                <li className="display-9 display-xl-8 py-2"># UN-Presentation</li>
                <li className="display-9 display-xl-8 py-2"># General</li>
                <li className="display-9 display-xl-8 py-2"># Business-Strategy</li>
              </ul>
            </div> */}
          </div>
        </div>
      </Col>
    );
  }
}

const MenuItem = (props) => {
  const item = props.menuItem;
  const [isActionMenuDisplayed, showActionMenu] = useState(false);
  const actionItem = [
    { id: 2, name: 'Edit', iconName: 'edit_icon.svg' },
    { id: 1, name: 'Remove', iconName: 'trash.svg' }
  ];

  const handleActions = (id, list) => {
    showActionMenu(false);
    props.handleListItemAction(id, list);
  };

  const { categorySection, campaignCategoryList, displayShopCategory } = props;

  return (
    <li className={props.isMenuActive ? 'active' : ''} key={props.index}>
      <Link to={{}} className="text-networks">
        <div className="network-nav">
          <Row className="align-items-center no-gutters">
            <Col xs={item.parent_menu === 'categories' ? 9 : 10} className="pr-0" onClick={() => {props.handleMenuItemClick(item); }}>
              <div className="d-flex align-items-center flex-nowrap" >
                <div className="fix-width">
                  <div className="round-circle" ></div>
                </div>
                <span className="display-9 display-xl-8 pl-3 text-truncate">{item.menu_name ? item.menu_name : item.name}</span>
              </div>
            </Col>

             { props.shops && 
                <Col xs={2}>
                  <span className="ml-auto d-block text-right" onClick={() => { showActionMenu(!isActionMenuDisplayed); }} >
                    <Image src={require("assets/images/icons/menu_vertical_icon.svg")} alt="menu_vertical_icon" className="vertical-menu" style={{marginRight: 6}}/>
                  </span>
                </Col>
             }
                {
                  isActionMenuDisplayed && props.shops && 
                  <Row className="no-gutters bg-white mx-1 mt-1 p-3 mb-0">
                  {
                    actionItem.map((list, index) => {
                      return (                 
                          <ActionItem
                            key={"list-item-action" + index}
                            actionItem={list}
                            handleProductTypeActions={props.handleProductTypeActions}
                            item={item}
                            editType={true}
                            showActionMenu= {showActionMenu}
                          />
                      );
                    })}
                </Row>
                }
            {
              item.count === 'none'
                ? <></>
                : props.showCount && (props.showZeroInCount || item.count !== 0) &&
                <Col xs={2}>
                  <div className="text-right">
                    <MenuCount count={item.count} />
                  </div>
                </Col>
            }
            {
              item.parent_menu === 'categories' &&
              <AllowPermission permissions={PERMISSIONS.EDIT_CATEGORY || PERMISSIONS.DELETE_CATEGORY}>
                <Col xs={1}>
                  <span className="ml-auto d-block text-right" onClick={() => { showActionMenu(!isActionMenuDisplayed); }} >
                    <Image src={require("assets/images/icons/menu_vertical_icon.svg")} alt="menu_vertical_icon" className="vertical-menu" />
                  </span>
                </Col>
              </AllowPermission>
            }

            {
              isActionMenuDisplayed && item.parent_menu === 'categories' &&
              <Row className="no-gutters bg-white mx-1 mt-1 p-3 mb-0">
                {
                  actionItem.map((list, index) => {
                    return (
                      <AllowPermission permissions={(list.name === 'Edit') ? PERMISSIONS.EDIT_CATEGORY : PERMISSIONS.DELETE_CATEGORY} key={index}>
                        <ActionItem
                          key={"list-item-action" + index}
                          actionItem={list}
                          handleActions={handleActions}
                          item={item}
                        />
                      </AllowPermission>
                    );
                  })}
              </Row>
            }
          </Row>
        </div>
        <div className='network-nav-suboptions pl-5'>
          <ul className={`bg-white pl-4 ${props.selectedMenuItemId !== item.id && !displayShopCategory ? 'd-none' : ''}`}>

            {categorySection && campaignCategoryList.length > 0 && campaignCategoryList.map((item, i) => {
              return (
                <li className="py-2 pl-2 pr-3 d-flex justify-content-between font-14" key={`${i}_${item.name}`}>{item.name}
                  <div className="text-right">
                    <MenuCount count="5" />
                  </div>
                </li>
              );
            })
            }

            {displayShopCategory && displayShopCategory.map((list, i) => {
              return (
                <>
                {props.menuItem.id === list.product_type_id &&
                <DisplayShopsAction
                  item={list}
                  actionItem={actionItem}
                  handleMenuItemClick={props.handleMenuItemClick}
                  handleActions={handleActions}
                  isActive={props.selectedMenuItemId === list.id}
                />
              }
                </>
              );
            })

            }

          </ul>

          {categorySection && (
            <div className={campaignCategoryList.length > 0 ? "more-actions-options-outer mt-0 pl-4" : " "}>
              {
                props.selectedMenuItemId === item.id &&
                LISTITEM_ACTIONS.map((item, i) => {
                  return (
                    <Col
                      xs={12}
                      className="pl-2 py-2 align-items-center more-actions-options-list"
                      key={i}
                    >
                      <span className="pr-2">
                        <Image
                          className="hx-20 approve-action-icon more-actions-options-icon"
                          src={require("assets/images/icons/" + item.iconName)} alt={item.name + " icon"}
                        />
                      </span>
                      <span className="display-9 primary-link line-height-1 text-capitalize">
                        {item.name}
                      </span>
                    </Col>
                  );
                })
              }
            </div>
          )}
        </div>
      </Link>
    </li>
  );
};

const AdminMenuItem = (props) => {
  const item = props.menuItem;
  if(CLIENT_ID===NAME_SILHOUETTE_CLIENT_ID && item.menu_name === 'Custom'){
    return null;
  }
  return (
    <li className={props.isMenuActive ? 'active' : ''} key={props.index}>
      <Link to={{}} className="text-networks">
        <div className="network-nav">
          <Row className="align-items-center no-gutters">
            <Col xs={item.parent_menu === 'categories' ? 9 : 10} className="pr-0" onClick={() => {props.handleMenuItemClick(item); }}>
              <div className="d-flex align-items-center flex-nowrap" >
                <div className="fix-width">
                  <div className="round-circle" ></div>
                </div>
                <span className="display-9 display-xl-8 pl-3 text-truncate">{item.menu_name ? item.menu_name : item.name}</span>
              </div>
            </Col>
            {
              item.count === 'none'
                ? <></>
                : (props.showZeroInCount || item.count !== 0) &&
                <Col xs={2}>
                  <div className="text-right">
                    <MenuCount count={item.count} />
                  </div>
                </Col>
            }
          </Row>
        </div>
      </Link>
    </li>
  );
};

const ActionItem = (props) => {
  const list = props.actionItem;
  const item = props.item;

  return (
    <Col xs={6} className="p-0 align-items-center cursor-pointer" onClick={() => {!props.editType? props.handleActions(item.id, list.id): props.handleProductTypeActions(item, list.id);props.showActionMenu(false)}}>
      <span className="pr-2">
        <Image className="hx-20 approve-action-icon" src={require("assets/images/icons/" + list.iconName)} alt={item.name + " icon"} />
      </span>
      <span className="display-xl-8 display-9 primary-link line-height-1">{list.name}</span>
    </Col>
  );
};

