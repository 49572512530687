import React, { Component } from 'react';
import { Card, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ClientGoals from './client_goals';
import { Utility, Date } from 'utils';
import { EditIcon } from 'shared/icons/edit_icon';
import Collapse from 'react-bootstrap/Collapse';
import { MdKeyboardArrowDown } from "react-icons/md";

export default class ProfileDetails extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
    };
  }

  getName = () => {
    if (this.props.audienceDetails) {
      const firstName = this.props.audienceUserDetails ? this.props.audienceUserDetails.first_name : '';
      const lastName = this.props.audienceUserDetails && this.props.audienceUserDetails.last_name ? this.props.audienceUserDetails.last_name : '';

      return firstName + ' ' + lastName;
    }

    return this.props.name;
  }

  render() {
    const { open } = this.state;

    return (
      <>
        {
          this.props.isLoading ?
            <center><Spinner animation="border" role="status"/></center>
          :
            <div className={!Utility.isEmpty(this.props.userDetails) ? "dashboard-profile-container h-md-100" : "dashboard-profile-container network-profile-card h-md-100 w-100"} >
            <Card className="profile-info scroll-y">
              <Card.Header className="profile-header">
                {
                  this.props.showHeader &&
                  <div className="profile-left">My Profile</div>
                }
                {
                  !this.props.disableEdit &&
                  <>
                    <div className="profile-right d-lg-block">
                      <Link to="/profile/edit" className="shar-icon">
                        <EditIcon/>
                      </Link>
                    </div>
                  </>
                }
                <Button
                  onClick={() => this.setState({ open: !open })}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="d-block d-lg-none icon-collapse"
                >
                  <MdKeyboardArrowDown/>
                </Button>
              </Card.Header>
              <Card.Img variant="top" className="my-profile-img rounded-circle ml-3 my-2"
                src={!Utility.isEmpty(this.props.profileImgUrl) ? this.props.profileImgUrl : require("assets/images/users/user_no_image.png")} alt="Profile Picture" />
              <Card.Body className="pt-1">
                <Card.Title className="m-0 display-7 font-bold pb-2">{this.getName()}</Card.Title>
                <div className="pb-3">
                  {
                    !Utility.isEmpty(this.props.city) && !Utility.isEmpty(this.props.state) ? `${this.props.city},` : !Utility.isEmpty(this.props.city) ? this.props.city : ''
                  }
                  {this.props.state}
                </div>
                <Collapse in={this.state.open}>
                  <div id="example-collapse-text" className="d-lg-block" >
                    {
                      this.props.description &&
                      <ProfileBlock label='About' data={this.props.description} />
                    }
                    {
                      this.props.clientTypeName &&
                      <ProfileBlock label={this.props.clientTypeName} data={this.props.clientSubTypeName} />
                    }
                    {
                      this.props.title &&
                      <ProfileBlock label='Title' data={this.props.title} />
                    }
                    {
                      this.props.address1 &&
                      <ProfileBlock label='Address' data={this.props.address1 + " " + this.props.address2} />
                    }
                    {
                      this.props.websiteUrl &&
                      <ProfileBlock label='Website' data={this.props.websiteUrl} />
                    }
                    {
                      !Utility.isEmpty(this.props.clientGoals) &&
                      <ClientGoals clientGoals={this.props.clientGoals} />
                    }
                    {
                      !Utility.isEmpty(this.props.audienceUserDetails) &&
                        <UserDetailsBlock
                          userData={this.props.audienceUserDetails}
                          country={this.props.country}
                          organizationName={this.props.name}
                        />
                    }
                    {
                      !Utility.isEmpty(this.props.savedTags) &&
                      <TagsBlock savedTags={this.props.savedTags}/>
                    }
                  </div>
                </Collapse>
              </Card.Body>
            </Card>
          </div>
        }
      </>
    );
  }
}

const ProfileBlock = props => (
  <div className="card-text-margin">
    <h6 className="pt-1 display-9">{props.label}:</h6>
    <div className="display-9">{props.data}</div>
  </div>
);

const UserDetailsBlock = props => {
  const date = !Utility.isEmpty(props.userData.dob) ? Date.formatDate(props.userData.dob, 'DD/MM/YYYY') : '';

  return (
    <>
    <div className="card-text-margin">
      <h6 className="pt-1 display-9">Organization Name:</h6>
      <div className="display-9">{props.organizationName}</div>
    </div>
    <div className="card-text-margin">
      <h6 className="pt-1 display-9">Email:</h6>
      <div className="display-9">{props.userData.email}</div>
    </div>
    <div className="card-text-margin">
      <h6 className="pt-1 display-9">Gender:</h6>
      <div className="display-9">{props.userData.gender === 1 ? "Male" : "Female"}</div>
    </div>
    <div className="card-text-margin">
      <h6 className="pt-1 display-9">Date Of Birth:</h6>
      <div className="display-9">{date}</div>
    </div>
    <div className="card-text-margin">
      <h6 className="pt-1 display-9">Country:</h6>
      <div className="display-9">{!Utility.isEmpty(props.country) ? props.country.name : ''}</div>
    </div>
    <div className="card-text-margin">
      <h6 className="pt-1 display-9">Phone numbers:</h6>
      <div className="display-9">{props.userData.phone_cellular}</div>
    </div>
      {
        !Utility.isEmpty(props.userData.additional_details) &&
        <>
          <div className="card-text-margin">
            <h6 className="pt-1 display-9">Additional contact Information:</h6>
            <div className="display-9">{props.userData.additional_details.additional_contact_info}</div>
          </div>
          <div className="card-text-margin">
            <h6 className="pt-1 display-9">Additional Details:</h6>
            <div className="card-text-margin">
              <h6 className="pt-1 display-9">Write a paragraph of your community goals:</h6>
              <div className="display-9"> {props.userData.additional_details.write_a_paragraph_of_your_community_goals}</div>
            </div>
            <div className="card-text-margin">
              <h6 className="pt-1 display-9">Write a paragraph of your leadership goals:</h6>
              <div className="display-9"> {props.userData.additional_details.write_a_paragraph_of_your_leadership_goals}</div>
            </div>
            <div className="card-text-margin">
              <h6 className="pt-1 display-9">Write a paragraph of your personal strengths:</h6>
              <div className="display-9"> {props.userData.additional_details.write_a_paragraph_of_your_personal_strengths}</div>
            </div>
            <div className="card-text-margin">
              <h6 className="pt-1 display-9">Write a paragraph of your organization strengths:</h6>
              <div className="display-9"> {props.userData.additional_details.write_a_paragraph_of_your_organization_strengths}</div>
            </div>
            <div className="card-text-margin">
              <h6 className="pt-1 display-9">What is your work story:</h6>
              <div className="display-9">{props.userData.additional_details.what_is_your_work_story}</div>
            </div>
            <div className="card-text-margin">
              <h6 className="pt-1 display-9">What is your family story:</h6>
              <div className="display-9"> {props.userData.additional_details.what_is_your_family_story}</div>
            </div>
            <div className="card-text-margin">
              <h6 className="pt-1 display-9">What is your gospel testimony:</h6>
              <div className="display-9"> {props.userData.additional_details.what_is_your_gospel_testimony}</div>
            </div>
            <div className="card-text-margin">
              <h6 className="pt-1 display-9">Any additional notes?</h6>
              <div className="display-9">{props.userData.additional_details.any_additional_notes}</div>
            </div>
          </div>
        </>
      }
  </>
);
};

const TagsBlock = props => {
  let length;
  const data = props.savedTags.map(category => {
    length = 0;
    return (
    <div className="card-text-margin"key={category.id}>
      <h6 className="pt-1 display-9">{category.name}:</h6>
      <div className="d-flex flex-wrap">
        {
          !Utility.isEmpty(category.Tags) &&
          category.Tags.map(tag => {
            if (length === category.Tags.length - 1){
              return (tag.tag_name);
            } else {
              length += 1;
              return (tag.tag_name + ',' + ' ');
            }})
        }
      </div>
    </div>);
  });
  return (
  <>
  {data}
  </>
  );
};
