import React, { useState, useEffect } from 'react';
import HomeContainer from 'shared/components/home_container';
import InviteMembers from './invite_members';
import Members from './members';
import Campaigns from './campaigns';
import Events from './events';
import Testimonies from './testimonies';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import { STATUS, CAMPAIGN_TYPES_IDS } from 'config/constants';
import { Link } from 'react-router-dom';
import { CLIENT_ID } from 'config/constants/config';
import { ChevronDownIcon } from 'shared/icons/icons';
import CreatebleCard from 'shared/components/cards/creatable_card';
import CreateCampaign from 'scenes/users/components/create_campaign';
import SideCallout from 'shared/components/templates/side_callout';
import ChatCallout from 'shared/components/chat_callout.js';
import { GROUPS, GROUP_MEMBERS, CAMPAIGNS_TOP_STORIES } from 'config/constants/api_endpoints';
import { updateCampaignFormOpen, updateCampaignFormSelectedId, updateCampaignSelectedTab, updateSeeAll } from 'shared/actions';
import { useSelector, useDispatch } from 'react-redux';
import SeeAll from 'shared/components/v2/see_all';
import Avatar from 'react-avatar';

const isPwaEnabled = Utility.pwaModeEnabled();

export default function GroupDetail(props){
  const groupId = props.match.params.id;
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData);
  const seeAll = useSelector(state => state.seeAll.isOpen);
  const isCampaignFormOpen = useSelector(state => state.isCampaignFormOpen);
  const isChatFormOpen = useSelector(state => state.isChatFormOpen);
  const refreshCampaignApi = useSelector(state => state.refreshCampaignApi);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isShowAllTestimony, setIsShowAllTestimony] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [testimonyData, setTestimonyData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [alreadyAMember, setAlreadyAMember] = useState(false);
  const calledFormPublicGroup = props.location ? props.location.isPublicGroup : false;
  const [testimonyCallout, setTestimonyCallout] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(updateCampaignFormSelectedId(null));
    };
  });

  useEffect(() => {
    getGroupData();
  }, [refreshCampaignApi]);

  useEffect(() => {
    getGroupData();
    dispatch(updateCampaignFormOpen(false));
    dispatch(updateCampaignSelectedTab(CAMPAIGN_TYPES_IDS.story));
    dispatch(updateSeeAll({ isOpen: null, calledFrom: seeAll }));
  }, []);

  useEffect(() => {
    groupData.length && getTestimony();
  }, [groupData.length]);

  useEffect(() => {
    if(isShowAllTestimony || isFormOpen) {
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('body').scroll = "no";
    }
    else {
    document.querySelector('body').style.overflow = 'auto';
    document.querySelector('body').scroll = "yes";
    }

    return () => {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').scroll = "yes";
    };

  }, [isShowAllTestimony, isFormOpen]);

  const getGroupData = () => {
    Utility.sendRequest(GROUPS + '/' + groupId, 1, null, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setGroupData(body);
        getTestimony();
      }
    });
  };

  const getTestimony = () => {
    const params = {
      publisher_network_id: CLIENT_ID,
      forTestimony: true,
      groupId,
      memberId: calledFormPublicGroup ?
                groupData.owner_id : userData.id,
    };

    Utility.sendRequest(CAMPAIGNS_TOP_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.length)
          setTestimonyData(body);
      }
    });
  };

  const sendInvite = () => {
    const params = {
      groupId: groupData.id,
      userClientIds: [userData.id],
      status: STATUS.approved
    };

    Utility.sendRequest(GROUP_MEMBERS, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.data){
          toast.success(
          'Congratulation, you have successfully joined this group.',
          { containerId: 'private' });
          setRefresh(Math.random());
        }
      }
    });
  };

  const handleTestimonyClick = (campaign) => {
    dispatch(updateCampaignFormSelectedId(campaign.id));
    setTestimonyCallout(true);
  };

  const returnCreatebleCard = (e) => (
    <CreatebleCard
      key={e.id}
      data={e}
      className="article-outer mr-3"
      useUpload={false}
      handleClick={() => handleTestimonyClick(e)}
      title={e.heading}
      description={e.description}
      forTestimony={true}
    />
  );

  const paintTestimony = () => {
    return testimonyData.map(e => {
      if(calledFormPublicGroup){
        return (
          e.created_at !== e.updated_at &&
          <>
          <Link
            className="mr-3"
            to={{
            pathname: `/campaign/${e.id}`,
            goBackLink: `/groups/${groupId}`,
            calledFrom: 'Testimony' }}>
            {returnCreatebleCard(e)}
            </Link>
          </>
        );
      }
      else {
        return (
          e.created_at === e.updated_at ?
          returnCreatebleCard(e) :
          <>
            <Link
              className="mr-3"
              to={{
              pathname: `/campaign/${e.id}`,
              goBackLink: `/groups/${groupId}`,
              calledFrom: 'Testimony' }}>
              {returnCreatebleCard(e)}
            </Link>
          </>
        );
      }
    });
  };

  const isMyGroup = () => {
    return groupData.owner_id === userData.id;
  };

  const paintGroupWelcome = () => (
    <>
      { !isMyGroup() && !alreadyAMember && !Utility.pwaModeEnabled &&
        <button
          onClick={() => sendInvite()}
          className="btn btn-primary btn-primary-pwa client-profile-btn-create web-create-button" >
          Join
        </button>
      }
      <div
        className={`welcome-card overflow-hidden d-flex w-70 ${Utility.pwaModeEnabled ? '' : 'mb-42'}`} key={1}>
        <img src={groupData.image_url} className="welcome-card-img"  alt="" />
        <div className="p-lg-4 p-2 d-flex w-100" >
          {!Utility.isEmpty(groupData.author_profile_image_url) ?
          <img src={groupData.author_profile_image_url} className="img-border rounded-circle welcome-card-heading-img mr-2"  alt="" />
          :
          <Avatar
          name={groupData.author_name}
          round={true}
          size={'40'}
          textSizeRatio={1.75}
          color='#007bb3'
          maxInitials={1}
          />
       }
          <div className="welcome-card-body ml-1" >
            <div className="welcome-card-heading" >
              <span className="display-7 font-bold" >{groupData.name}</span>
            </div>
            <div className="text-muted font-14 mt-2" >{moment(groupData.updated_at).format('ll')}</div>
            <div className="welcome-card-text">
            {groupData.description &&
             groupData.description.replace(/^(.{250}[^\s]*).*/, "$1") + ' ...'}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return(
    <HomeContainer
      name={groupData.name}
      goBackLink={(props.location.state && props.location.state.fromPwaNotification) ? '/profile' : props.location.goBackLink} //? props.location.goBackLink :
    //  // window.history.back()}
    //   () => {
    //      window.history.back();
    //     dispatch(updateSeeAll({ isOpen: seeAll, calledFrom: seeAll }));
    //   }}
      calledFrom={props.location.calledFrom}
      history={props.history}
      className="group-detail">
      <>
        {paintGroupWelcome()}

        <Members
          groupId={groupId}
          userClientId={userData.id}
          setIsFormOpen={setIsFormOpen}
          refresh={refresh}
          isMyGroup={isMyGroup()}
          sendInvite={sendInvite}
          alreadyAMember={alreadyAMember}
          setAlreadyAMember={setAlreadyAMember}
        />

        {
          !Utility.isEmpty(testimonyData) && paintTestimony().length &&
          <div className={`${Utility.pwaModeEnabled ? 'slider-mob mt-42' : 'mb-42'}`}>
            <h1 className="font-24-bold mb-25 home-page-heading" >Group Testimonies
              {isPwaEnabled &&
                <span
                  className="see-all-text display-7"
                  onClick={() => setIsShowAllTestimony(true)}>
                  See All
                </span>
              }
            </h1>
            <div className="article-section-outer d-flex flex-lg-wrap overflow-auto overflow-lg-visible" >
              {paintTestimony()}
            </div>
          </div>
        }

        <Campaigns
          groupId={groupId}
          heading='Group Articles'
          showHeading={true}
          seeAll={true}
          isUserLoggedIn={!Utility.isEmpty(userData)}
          userData={userData}
          setSeeAll={() => dispatch(updateSeeAll({ isOpen: 'groupArticle', calledFrom: 'groupArticle' }))}
        />

        <Events
          groupId={props.match.params.id}
          heading='Group Events'
          showHeading={true}
          seeAll={true}
          isUserLoggedIn={!Utility.isEmpty(userData)}
          userData={userData}
          goBackLink={`/groups/${groupId}`}
          setSeeAll={() => dispatch(updateSeeAll({ isOpen: 'groupEvent', calledFrom: 'groupEvent' }))}
        />

        {isFormOpen &&
          <InviteMembers
            position="position-fixed sidebar-position-top"
            innerPosition="position-inherit"
            groupId={groupId}
            closeForm={() => setIsFormOpen(false)}
            setRefresh={setRefresh}
          />
        }

        {
          testimonyCallout &&
          <CreateCampaign
            position='position-absolute'
            loadedFrom='groupDetail'
            isFormOpen={testimonyCallout}
            forTestimony={true}
            getTestimony={getTestimony}
            groupData={groupData}
            testimonyData={testimonyData}
            setIsFormOpen={(arg) => setTestimonyCallout(arg)}
          />
        }

        { isShowAllTestimony &&
          <SideCallout
            closeCallOut={() => setIsShowAllTestimony(false)}
            calloutHeading='Group Members'
            calloutCloseIcon={
              <ChevronDownIcon />
            }
            innerPosition="create-card-pwa-outer" >
            <Testimonies
              groupId={groupId}
            />
          </SideCallout>
        }

        { isChatFormOpen &&
          <ChatCallout />
        }
        {
          seeAll &&
          <SeeAll
            closeCallout={() => dispatch(updateSeeAll({ isOpen: null, calledFrom: null }))}
            calledFrom={seeAll}
            groupId={groupId}
            width={'100%'}
          />
        }

      </>
    </HomeContainer>
  );
}
