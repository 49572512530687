import React, { useEffect, useState } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ALL_PUBLIC_EVENT_CAMPAIGNS } from 'config/constants/api_endpoints';
import { CLIENT_ID, CRM_MODULES } from 'config/constants/config';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';
import { Utility } from 'utils';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function CalendarCallout(props) {
  const [publicEventDate, setPublicEventDate] = useState(moment().startOf('month').format('DD MMMM YYYY'));
  let rightEle = null;
  let leftEle = null;

  useEffect(() => {
    const leftAndRightEle = document.getElementsByClassName("MuiButtonBase-root MuiIconButton-root MuiPickersCalendarHeader-iconButton");
    if (leftAndRightEle.length > 0) {
      leftEle = leftAndRightEle[0];
      rightEle = leftAndRightEle[1];
      leftEle.classList.add("leftButton");
      rightEle.classList.add("rightButton");

      const classes = "MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter";

      rightEle.onclick = function() {
        const selectedDate = document
        .getElementsByClassName(classes)[0]
        .innerText;
        setPublicEventDate(
          moment(`01 ${selectedDate}`)
          .add("1", "months")
          .format("DD MMMM YYYY")
        );
      };

      leftEle.onclick = function() {
        const selectedDate = document
        .getElementsByClassName(classes)[0]
        .innerText;
        setPublicEventDate(
          moment(`01 ${selectedDate}`)
          .subtract("1", "months")
          .format("DD MMMM YYYY")
        );
      };
    }
  }, []);

  useEffect(() => {
    getPublicEvents();
  }, [publicEventDate]);

  const getStartDate = () => {
    return moment.utc(
             moment(publicEventDate)
             .startOf('months')
             .startOf('day')
             .format()
           ).format();
  };

  const getEndDate = () => {
    return moment.utc(
             moment(publicEventDate)
             .startOf('months')
             .endOf('day')
             .format()
           ).format();
  };

  const getPublicEvents = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: props.userClientId,
      campaign_type_id: CAMPAIGN_TYPES_IDS.event,
      current_time: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      start_of_day: getStartDate(),
      end_of_day: getEndDate(),
    };

    Utility.sendRequest(ALL_PUBLIC_EVENT_CAMPAIGNS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        body.data.map(e => {
          const date = moment.utc(e.date).local().format("D");
          const arr = document.getElementsByClassName("MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit");
          for (let item of arr) {
            if (item.innerText == date) {
              item.parentElement.parentElement.classList.add("active");
            }
          }
        });
      }
    });
  };

  return (
    <div className="shar-calendar" >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="static"
          disablePast={false}
          open={true}
          onChange={(date) => {
            props.handleChange(date);
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
