import React, { useState, useEffect } from "react";
import {
  CAMPAIGN_TYPES_IDS,
  ETHOS_CLIENT_ID,
  PASSPORT_TOOL_ID,
} from "config/constants";
import {
  ChevronUpIcon,
  CircleOutlinedAddIcon,
  GmBadgeIcon,
  EmptyBadgeIcon,
  ChevronLeftIcon,
} from "shared/icons/icons";
import SideCallout from "shared/components/templates/side_callout";
import {
  updateCampaignFormOpen,
  updateCampaignSelectedTab,
  updateIsCampSelFormOpen,
  openOrClosePlusIcon,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";

import {
  USER_BADGES,
  ALL_PARENT_CAMPAIGNS,
  GET_USER_TAGS,
  GET_TAGS_BY_CATEGORIES,
  GET_FOLLOWERS_FOLLOWING,
  GROUPS,
  GET_MY_DONATIONS,
  GET_USERS_BY_NETWORKS,
  CATEGORIES,
  CAMPAIGN_STORIES,
} from "config/constants/api_endpoints";
import CreateCampaign from "./index";
import { Utility } from "utils";
import { CLIENT_ID, BADGES_IDS } from "config/constants/config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EditPassport from "scenes/users/components/pwa/passport/edit_possport";
import { Invite } from "scenes/networks/components/invite.js";
import { Link } from "react-router-dom";
import { InputField } from "shared/components/form_elements";
import Avatar from "react-avatar";
import CourseContainer from "scenes/campaigns/courses";

export default function CampaignSelectionForm(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [activeCard, setActiveCard] = useState(null);
  const [userBadges, setUserBadges] = useState([]);
  const isCampaignFormOpen = useSelector((state) => state.isCampaignFormOpen);
  const [showMyCalling, setshowMyCalling] = useState(false);
  const [myArticles, setmyArticles] = useState([]);
  const [myCourses, setmyCourses] = useState([]);
  const [myEvents, setmyEvents] = useState([]);
  const [myDonations, setmyDonations] = useState([]);
  const [allMembers, setallMembers] = useState([]);
  const [passportData, setpassportData] = useState([]);
  const [isBageEarnedForPassport, setisBageEarnedForPassport] = useState(false);
  const [groupsData, setgroupsData] = useState([]);
  const [tagByCategories, settagByCategories] = useState([]);
  const [isEditPassportFormOpen, setisEditPassportFormOpen] = useState(false);
  const [showInviteForm, setshowInviteForm] = useState(false);
  const [showAllPeople, setshowAllPeople] = useState(false);
  const [showSetupCourse, setshowSetupCourse] = useState(false);
  useEffect(() => {
    getUserBadges();
    if (!Utility.isEmpty(userData)) {
      getMyArticles(userData);
      getMyCourses(userData);
      getAllCategories(userData);
      getMyEvents(userData);
      getMyDonations(userData);
      getGroups();
      getAllUsers();
    }
  }, []);

  useEffect(() => {
    if (passportData.length) {
      passportData.map((item) => {
        if (item.Tags.length > 0) {
          setisBageEarnedForPassport(true);
        }
      });
    }
  }, [passportData]);

  const getMyArticles = (userData) => {
    // this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
    };

    Utility.sendRequest(
      ALL_PARENT_CAMPAIGNS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        // this.props.showLoader(false);

        if (!body.error) {
          setmyArticles({ ...body.data });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  const getAllUsers = (search = "") => {
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      search,
      forCommunity: true,
    };

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        console.log(body.error);
      } else {
        if (body.registrants) setallMembers(body.registrants);
      }
    });
  };

  const getGroups = () => {
    const params = {
      clientId: CLIENT_ID,
      userClientId: userData.id,
      forMyGroups: true,
    };

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setgroupsData({ ...body });
      }
    });
  };

  const getMyDonations = (userData) => {
    // this.props.showLoader(true);
    const params = {
      platform_id: userData.platform_id,
      user_client_id: userData.id,
    };

    Utility.sendRequest(
      GET_MY_DONATIONS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        // this.props.showLoader(false);

        if (!body.error) {
          setmyDonations({ ...body });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  const getCategoryList = (campTypeId) => {
    const params = {
      client_id: CLIENT_ID,
      campaign_type_id: campTypeId,
      is_active: true,
    };

    Utility.sendRequest(CATEGORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        console.log(body)
      }
    });
  };

  const getMyEvents = (userData) => {
    // this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.event,
    };

    Utility.sendRequest(
      ALL_PARENT_CAMPAIGNS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        // this.props.showLoader(false);

        if (!body.error) {
          setmyEvents({ ...body.data });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  const getMyCourses = (userData) => {
    // this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.courses,
    };

    Utility.sendRequest(
      ALL_PARENT_CAMPAIGNS,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        // this.props.showLoader(false);

        if (!body.error) {
          setmyCourses({ ...body.data });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  const handleTabSelectionClick = (arg) => {
    setActiveCard(arg);
    dispatch(updateCampaignSelectedTab(arg));
    dispatch(updateCampaignFormOpen(true));
  };

  // const handleTabSelectionClick = (arg) => {
  //   dispatch(updateIsCampSelFormOpen(false));
  //   dispatch(updateCampaignSelectedTab(arg));
  //   dispatch(updateCampaignFormOpen(true));
  //   dispatch(openOrClosePlusIcon(false));
  // };

  const openEditPassportForm = (id) => {
    getTagsByCategories(id);
    setisEditPassportFormOpen(true);
  };

  const getTagsByCategories = (id) => {
    const params = {
      client_id: CLIENT_ID,
      tool_id: PASSPORT_TOOL_ID,
      category_id: id,
    };

    Utility.sendRequest(
      GET_TAGS_BY_CATEGORIES,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          settagByCategories(body.data);
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  const haveBadge = (badgeId) => {
    let flag = false;
    userBadges.forEach((e) => {
      if (e.badge_id === badgeId && e.have_badge) flag = true;
    });
    return flag;
  };

  const closeIsSelectionFormOpen = () => {
    dispatch(updateCampaignFormOpen(false));
  };

  const getAllCategories = (userData) => {
    const params = {
      client_id: CLIENT_ID,
      tool_id: PASSPORT_TOOL_ID,
      user_client_id: userData.id,
    };

    Utility.sendRequest(GET_USER_TAGS, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setpassportData([...body.data]);
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  const getUserBadges = () => {
    const params = {
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(USER_BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) setUserBadges(body);
    });
  };

  const loadStories = (campTypeId, categoryId = "", search = "") => {
    const params = {
      publisher_network_id: CLIENT_ID,
      type: campTypeId,
      category_id: categoryId,
      search: search,
    };
    getCategoryList(campTypeId);
    Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
        // this.props.showLoader(false);
      } else {
        console.log(Utility.deepClone(body.data))
          
        // this.props.showLoader(false);
      }
    });
  };

  const handleMemberSearch = (e) => {
    getAllUsers(e.target.value);
  };

  const handleChatClick = (item) => {
    dispatch(updateIsChatFormOpen(true));
    dispatch(updateChatFriend(item));
  };
  const PencilIcon = (props) => {
    return (
      <svg width="26px" height="26px" viewBox="0 0 26 26">
        <g
          id="PWA-2021"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="5.1A"
            transform="translate(-34.000000, -190.000000)"
            fill={props.color}
          >
            <g id="Group-5" transform="translate(-1.000000, 143.000000)">
              <g
                id="Icons/Start-Message"
                transform="translate(35.142857, 47.142857)"
              >
                <path d="M22.381907,3.33487342 C23.3641352,4.31836539 23.3641352,6.03336968 22.381907,7.01686165 L21.4575325,7.94250115 L17.7728909,4.25922731 L18.6998367,3.33487342 C19.6833505,2.35266706 21.3983931,2.35138145 22.381907,3.33487342 L22.381907,3.33487342 Z M3.05489483,22.6601692 L3.81985007,19.6081301 L6.10700198,21.8978022 L3.05489483,22.6601692 Z M15.9562829,6.07836605 L19.6370675,9.75906866 L8.621712,20.7741787 L4.94092734,17.0934761 L15.9562829,6.07836605 Z M24.1972293,1.51573468 C23.2201436,0.537385154 21.9203625,2.62900812e-13 20.5370149,2.62900812e-13 C19.1562385,2.62900812e-13 17.8551718,0.537385154 16.8780861,1.51573468 L2.21022996,16.1819783 C2.21022996,16.1819783 2.20894432,16.1858351 2.20765868,16.1871207 C2.10352192,16.2925408 1.92481808,16.5702326 1.87210688,16.7797871 L0.0387855705,24.1141946 C-0.0717793894,24.5513021 0.0580701566,25.0154075 0.376908646,25.3342389 L0.378194285,25.3355245 L0.380765563,25.3368101 C0.699604052,25.6556415 1.16243412,25.7854881 1.60083704,25.6762112 L8.93412229,23.8416452 C9.14368146,23.7889351 9.42266514,23.6102353 9.5268019,23.5061008 C9.52808754,23.5048152 9.53194446,23.5035296 9.53194446,23.5035296 L24.1998006,8.83600039 C25.1768863,7.85893647 25.7155691,6.55918434 25.7142834,5.17715314 C25.7142834,3.79383634 25.1768863,2.49536982 24.1998006,1.51702029 L24.1972293,1.51573468 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };

  return (
    <>
      {!showMyCalling && !showInviteForm && !showAllPeople && !showSetupCourse && (
        <SideCallout
          calloutHeadingSvg={<CircleOutlinedAddIcon mainClass="mr-2" />}
          closeCallOut={closeIsSelectionFormOpen}
          calloutHeading="Create"
          position={`${props.position} create-call-out`}
          calloutCloseIcon={<ChevronUpIcon />}
          innerPosition="create-card-pwa-outer"
        >
          <div className={`create-card-pwa`}>
            <p
              className="card-body m-0 text-center font-bold display-6"
              onClick={() => setshowMyCalling(true)}
            >
              {isBageEarnedForPassport ? (
                <GmBadgeIcon mainClass="mr-4 gold-badge" />
              ) : (
                <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
              )}
              Manage Passport
              <ChevronLeftIcon mainClass="ml-auto chevron-right" />
            </p>
          </div>
          <div className={`create-card-pwa`}>
            <p
              className="card-body m-0 text-center font-bold display-6"
              onClick={() => handleTabSelectionClick("group")}
            >
              {groupsData.length > 0 ? (
                <GmBadgeIcon mainClass="mr-4 gold-badge" />
              ) : (
                <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
              )}
              Create Group
              <ChevronLeftIcon mainClass="ml-auto chevron-right" />
            </p>
          </div>
          <div className={`create-card-pwa`}>
            <p
              className="card-body m-0 text-center font-bold display-6"
              onClick={() => handleTabSelectionClick(CAMPAIGN_TYPES_IDS.event)}
            >
              {myEvents.length > 0 ? (
                <GmBadgeIcon mainClass="mr-4 gold-badge" />
              ) : (
                <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
              )}
              Add Event
              <ChevronLeftIcon mainClass="ml-auto chevron-right" />
            </p>
          </div>
          <div className={`create-card-pwa`}>
            <p
              className="card-body m-0 text-center font-bold display-6"
              onClick={() => {
                setshowSetupCourse(true);
              }}
            >
              {myCourses.length > 0 ? (
                <GmBadgeIcon mainClass="mr-4 gold-badge" />
              ) : (
                <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
              )}
              Setup Course
              <ChevronLeftIcon mainClass="ml-auto chevron-right" />
            </p>
          </div>
          <div className={`create-card-pwa`}>
            <p
              className="card-body m-0 text-center font-bold display-6"
              onClick={() => handleTabSelectionClick(CAMPAIGN_TYPES_IDS.story)}
            >
              {myArticles.length > 0 ? (
                <GmBadgeIcon mainClass="mr-4 gold-badge" />
              ) : (
                <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
              )}
              Share Article
              <ChevronLeftIcon mainClass="ml-auto chevron-right" />
            </p>
          </div>
          <div className={`create-card-pwa`}>
            <Link
              className="card-body m-0 text-center font-bold display-6"
              onClick={closeIsSelectionFormOpen}
              to={"/networks"}
            >
              {haveBadge(BADGES_IDS.CAMPAIGN) ? (
                <GmBadgeIcon mainClass="mr-4 gold-badge" />
              ) : (
                <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
              )}
              Invite Friends
              <ChevronLeftIcon mainClass="ml-auto chevron-right" />
            </Link>
          </div>
          <div className={`create-card-pwa`}>
            <p
              className="card-body m-0 text-center font-bold display-6"
              onClick={() => setshowAllPeople(true)}
            >
              {myArticles.length > 0 ? (
                <GmBadgeIcon mainClass="mr-4 gold-badge" />
              ) : (
                <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
              )}
              Start Conversation
              <ChevronLeftIcon mainClass="ml-auto chevron-right" />
            </p>
          </div>
          <div className={`create-card-pwa`}>
            <p className="card-body m-0 text-center font-bold display-6">
              {myDonations.length > 0 ? (
                <GmBadgeIcon mainClass="mr-4 gold-badge" />
              ) : (
                <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
              )}
              Donate
              <ChevronLeftIcon mainClass="ml-auto chevron-right" />
            </p>
          </div>
          {/* {props.userData && props.userData.platform_id !== ETHOS_CLIENT_ID && (
            <div className={`create-card-pwa`}>
              <p
                className="card-body m-0 text-center font-bold display-6"
                onClick={() => handleTabSelectionClick("group")}
              >
                {haveBadge(BADGES_IDS.GROUP) ? (
                  <GmBadgeIcon mainClass="mr-4 gold-badge" />
                ) : (
                  <EmptyBadgeIcon mainClass="mr-4 empty-badge" />
                )}
                Create Group
                <ChevronLeftIcon mainClass="ml-auto chevron-right" />
              </p>
            </div>
          )} */}
        </SideCallout>
      )}
      {activeCard && isCampaignFormOpen && (
        <CreateCampaign
          position={props.position}
          loadedFrom="webCampaign"
          isFormOpen={isCampaignFormOpen}
          setIsFormOpen={() => setActiveCard(null)}
        />
      )}
      {showMyCalling && (
        <div>
          <SideCallout
            // calloutHeadingSvg={<CircleOutlinedAddIcon mainClass="mr-2" />}
            closeCallOut={() => {
              setshowMyCalling(false);
              // openOrClosePlusIcon(false);
            }}
            position={`${props.position} create-call-out`}
            calloutCloseIcon={<ChevronUpIcon />}
            innerPosition="create-card-pwa-outer"
          >
            <div className="px-3">
              <h1 className="d-flex align-items-center mb-4">
                <span className="font-weight-bold display-7 flex-auto ml-2">
                  My Calling
                </span>
              </h1>
              <div className="d-flex flex-wrap mb-42">
                {!Utility.isEmpty(passportData) &&
                  passportData.length &&
                  passportData.map((item, i) => {
                    return (
                      <div
                        className="passport-info-outer"
                        key={i}
                        onClick={() => openEditPassportForm(item && item.id)}
                      >
                        <div className="display-8 font-bold mb-4">
                          {item && item.name}
                        </div>
                        <div className="d-flex flex-wrap">
                          {item &&
                            !Utility.isEmpty(item.Tags) &&
                            item.Tags.map((tag, i) => {
                              return (
                                <div
                                  className="passport-pill bg-primary text-white font-14 font-bold px-4 mb-2 mr-2"
                                  key={i}
                                >
                                  {tag.tag_name}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </SideCallout>
        </div>
      )}

      {showSetupCourse && (
        <SideCallout
          // fromPlusIcon={true}
          closeCallOut={() => {
            setshowAllPeople(false);
            // this.props.openOrClosePlusIcon(false);
          }}
          position={`${props.position} create-call-out`}
          calloutCloseIcon={<ChevronUpIcon />}
          innerPosition="create-card-pwa-outer"
        >
          <CourseContainer
            editCampaignId={props.campaignId}
            setIsFormOpen={() => setshowSetupCourse(false)}
            loadStories={loadStories}
          />
        </SideCallout>
      )}

      {showAllPeople && (
        <>
          <SideCallout
            // fromPlusIcon={true}
            closeCallOut={() => {
              setshowAllPeople(false);
              // this.props.openOrClosePlusIcon(false);
            }}
            position={`${props.position} create-call-out`}
            calloutCloseIcon={<ChevronUpIcon />}
            innerPosition="create-card-pwa-outer"
          >
            <div className="pwa-callout-body">
              <div className="d-flex align-items-center flex-nowrap mb-4">
                <div
                  style={{ minWidth: 60 }}
                  className="pencil-icon-outer flex-center"
                >
                  <PencilIcon color="#272262" />
                </div>
                <h5 className="font-weight-bold display-7 text-primary">
                  Select a person to start a conversation.
                </h5>
              </div>
              <h6 className="d-none">Community</h6>
              <div className="position-relative shar-form search-form search-form-pwa sidebar-form px-2 px-lg-0 pwa-search-outer">
                <InputField
                  required={true}
                  type="text"
                  placeholder="Search"
                  handleChange={handleMemberSearch}
                />
                <img
                  style={{ right: 30 }}
                  className="pwa-chat-icon position-absolute"
                  src={require("assets/images/icons/search-icon.png")}
                  alt=""
                />
              </div>
              {!Utility.isEmpty(allMembers)
                ? allMembers.map((items, index) => {
                    return (
                      <>
                        <div key={index} className="pwa-chat-view">
                          <div className="pwa-chat-unit align-items-center flex-nowrap position-relative">
                            {!Utility.isEmpty(items.profile_image_url) ? (
                              <img
                                className="pcu-img rounded-circle"
                                src={items.profile_image_url}
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={items.user_name}
                                round={true}
                                size={"60"}
                                textSizeRatio={1.75}
                                color="#007bb3"
                                maxInitials={1}
                              />
                            )}
                            <div
                              onClick={() => {
                                setshowAllPeople(false);
                                handleChatClick(items);
                                openOrClosePlusIcon(false);
                              }}
                              className="pcu-text-outer ml-2"
                            >
                              <div className="font-weight-bold text-truncate display-8">
                                {items.user_name}
                              </div>
                              <div className="text-truncate font-14 b-500">
                                {!Utility.isEmpty(items.phone_cellular)
                                  ? items.phone_cellular
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                : Utility.pwaModeEnabled() &&
                  Utility.isEmpty(allMembers) && (
                    <div className="font-weight-bold display-7 my-4 text-center text-muted">
                      No Result Found
                    </div>
                  )}
            </div>
          </SideCallout>
        </>
      )}
      {isEditPassportFormOpen && !Utility.isEmpty(tagByCategories) && (
        <SideCallout
          closeCallOut={() => setisEditPassportFormOpen(false)}
          calloutHeading=""
          position="position-fixed gil-pwa-callout gil-pwa-callout-passport"
          calloutHeadingImg="assets/images/profile/passport-icon.svg"
          calloutCloseIcon={<ChevronUpIcon />}
        >
          <EditPassport
            tagByCategories={tagByCategories}
            passportData={passportData}
            closeCallOut={() => setisEditPassportFormOpen(false)}
            userData={userData}
            getAllCategories={() => getAllCategories(userData)}
          />
        </SideCallout>
      )}
      {showInviteForm && (
        <div className="pwa-invite-callout shar-form search-form">
          <Invite
            onClose={() => {
              openOrClosePlusIcon(false);
              setshowInviteForm(false);
            }}
            userData={userData}
            // fromHomePage={true}
            // changeLoader={(val) => this.props.showLoader(val)}
          />
        </div>
      )}
    </>
  );
}
