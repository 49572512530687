import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {
  CircleFilledAddIcon,
  ChatOvalIcon,
  GiftIcon,
  ShareIcon,
  GraduationCapIcon,
} from 'shared/icons/icons';
import MediaView from 'scenes/campaigns/components/media_view';
import { Utility } from 'utils';
import Avatar from 'react-avatar';
import { CAMPAIGNS_COURSE_PROGRESS } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import { CLIENT_ID } from 'config/constants/config';
import { AHURA_CLIENT_ID, CAMPAIGN_TYPES_IDS ,GIL_CLIENT_ID} from 'config/constants';

export default class CarouselContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAnimation: false,
    };
  } 

  handleParentShowAnimation = value => {
    this.setState({ showAnimation: value });
  };

  handleSelect = selectedIndex => {
    this.props.handleSelect(selectedIndex);
  };

  render() {
    return (
      <>
        <div className='article-page-carousel-top position-absolute pl-3 pt-3'>
          {this.props.campaignDetails[0].creator_profile_image_url ? (
            <img
              src={this.props.campaignDetails[0].creator_profile_image_url}
              className='rounded-circle img-product'
              alt=''
            />
          ) : (
            <Avatar
              name={this.props.campaignDetails[0].creator_name}
              round={true}
              size={'60'}
              textSizeRatio={1.75}
              color='#007bb3'
              maxInitials={1}
            />
          )}
          {!this.props.isFollowed &&
            this.props.campaignDetails[0].user_client_id !==
              this.props.userClientId && (
              <div
                className='cursor-pointer'
                onClick={() => this.props.setFollowerfollowing(null)}
              >
                <CircleFilledAddIcon
                  mainClass='article-page-add-icon position-absolute'
                  fillClass='fill-primary'
                />
              </div>
            )}
          <svg
            className={
              this.props.isLiked && this.state.showAnimation
                ? 'progress-circle'
                : 'progress-circle d-none'
            }
          >
            <path
              d='m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z'
              stroke='#bae7f3'
              strokeWidth='4'
              fill='transparent'
            />
          </svg>
        </div>
        <Carousel
          controls={this.props.isIndicators}
          interval={null}
          activeIndex={this.props.index}
          indicators={this.props.isIndicators}
          slide={true}
          onSelect={this.handleSelect}
          className='article-page-carousel'
        >
          {[...Array(this.props.campaignDetails.length).keys()].map(e => (
            <Carousel.Item key={e}>
              <CarouselInner
                userClientId={this.props.userClientId}
                data={this.props.campaignDetails[e]}
                isUserDistracted={this.props.isUserDistracted}
                updateUserDistracted={this.props.updateUserDistracted}
                getSentimentDetails={this.props.getSentimentDetails}
                campaignSentimentDetails={this.props.campaignSentimentDetails}
                commentsCount={this.props.commentsCount}
                likesCount={this.props.likesCount}
                isLiked={this.props.isLiked}
                calledFrom={this.props.calledFrom}
                setFollowerfollowing={this.props.setFollowerfollowing}
                switchRightSection={this.props.switchRightSection}
                handleParentShowAnimation={this.handleParentShowAnimation}
                download={this.props.download}
                openPaywall={this.props.openPaywall}
                isStudent={this.props.isStudent}
                isLesson={this.props.isLesson}
                isArticle={this.props.isArticle ? this.props.isArticle : false}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </>
    );
  }
}

class CarouselInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAnimation: false,
      isTouched: false,
      watched: false,
      completed: false,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        isTouched: false,
      },
      () => {
        this.props.isLiked &&
          this.setState(
            { showAnimation: false },
            this.props.handleParentShowAnimation(false)
          );
      }
    );

    this.fetchCampaignProgress();
  };

  updateCampaignProgress = () => {
    const params = {
      client_id: CLIENT_ID,
      is_active: true,
      user_client_id: this.props.userClientId,
      campaign_id: this.props.data.campaign_id,
      is_video_watched: true,
      campaign_type_id: this.props.data.campaign_type_id,
    };

    Utility.sendRequest(
      CAMPAIGNS_COURSE_PROGRESS,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          this.setState({ completed: true, watched: true });
        }
      }
    );
  };

  fetchCampaignProgress = () => {
    const params = {
      user_client_id: this.props.userClientId,
      campaign_id: this.props.data.campaign_id,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(
      CAMPAIGNS_COURSE_PROGRESS,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          this.setState({
            watched: body.data.is_video_watched,
            completed: body.data.is_video_watched,
          });
        }
      }
    );
  };

  componentDidUpdate = nextProps => {
    if (
      nextProps.calledFrom !== 'RecommendedReads' &&
      this.props.isLiked !== nextProps.isLiked
    ) {
      if (!this.props.isLiked && nextProps.isLiked)
        this.setState(
          { showAnimation: true },
          this.props.handleParentShowAnimation(true)
        );
    }
  };

  handleAnimation = () => {
    this.setState({ isTouched: true }, () => {
      this.props.isLiked
        ? this.setState(
            { showAnimation: false },
            () => this.props.getSentimentDetails(),
            this.props.handleParentShowAnimation(false)
          )
        : this.setState(
            { showAnimation: true },
            () => this.props.getSentimentDetails(),
            this.props.handleParentShowAnimation(true)
          );
    });
  };

  resumeSession = () => {
    const mediaView = document.getElementById('ahura-media-view');
    const distractionView = document.getElementById('ahura-distraction-screen');
    const parentDiv = document.querySelector('.course-page-outer');
    if (mediaView && distractionView && parentDiv) {
      distractionView.classList.add('d-none');
      mediaView.classList.remove('d-none');
      parentDiv.classList.remove('border-alternate');
    }
  }

  switchCourses = () => {
  }

  render() {
    const isLessonCourseOrSection =
      this.props.data.campaign_type_id === CAMPAIGN_TYPES_IDS.courses ||
      this.props.data.campaign_type_id === CAMPAIGN_TYPES_IDS.section ||
      this.props.data.campaign_type_id === CAMPAIGN_TYPES_IDS.lesson;
console.log("Isart",this.props.isArticle);

    return (
      <>
        {CLIENT_ID !== AHURA_CLIENT_ID &&
          (!this.state.watched ? (
            <>
              <MediaView
                width='100%'
                innerClassName='react-player'
                outerClassName='player-wrapper'
                imageClassName='d-block w-100 article-page-carousel-main-img'
                imageUrl={
                  this.props.data.image_url ||
                  this.props.data.embedded_image_link
                }
                videoUrl={
                  this.props.data.video_url ||
                  this.props.data.embedded_video_link
                }
                download={this.props.download}
                openPaywall={this.props.openPaywall}
                videoWatched={this.state.completed}
                isStudent={this.props.isStudent}
                setVideoWatched={data => this.updateCampaignProgress(data)}
                setWatched={data => this.setState({ watched: data })}
                isCarousel={true}
                isLesson={this.props.isLesson}
              />

              <div className='justify-content-lg-start justify-content-between align-items-center flex-nowrap article-page-carousel-bottom p-3 position-absolute w-100 text-white'>
                {CLIENT_ID !== "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5" && <div className='align-items-center cursor-pointer flex-nowrap mr-4'>
                  <img
                    src={
                      this.props.isLiked
                        ? require('assets/images/icons/heart-red.svg')
                        : require('assets/images/icons/heart-white.svg')
                    }
                    alt=''
                    onClick={this.handleAnimation}
                  />
                  <span className='ml-2 font-14'>{this.props.likesCount}</span>
                  <div
                    className={
                      this.props.isLiked &&
                      this.state.showAnimation &&
                      (this.props.calledFrom !== 'RecommendedReads' ||
                        this.state.isTouched)
                        ? 'liked-article-container'
                        : 'liked-article-container d-none'
                    }
                  >
                    {[...Array(5).keys()].map(e => (
                      <img
                        key={e}
                        alt=''
                        src={require('assets/images/icons/heart-red.svg')}
                        className='liked-article'
                      />
                    ))}
                  </div>
                </div>}
                <div
                  className={`${
                    isLessonCourseOrSection ? 'align-items-center' : ''
                  } cursor-pointer flex-nowrap mr-4`}
                  onClick={() => this.props.switchRightSection('chat')}
                >
                  <ChatOvalIcon />
                  <span className='ml-2 font-14'>
                    {this.props.commentsCount}
                  </span>
                </div>
                {CLIENT_ID !== "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5" && (CLIENT_ID===GIL_CLIENT_ID && !this.props.isArticle && <div
                  className={`${
                    isLessonCourseOrSection ? 'align-items-center' : ''
                  } cursor-pointer flex-nowrap mr-4`}
                  onClick={() => this.props.switchRightSection('sendMoney')}
                >
                  <GiftIcon />
                  <span className='ml-2 font-14'>
                    $
                    {Utility.nFormatter(
                      parseFloat(
                        this.props.data.total_donation_amount || 0
                      ).toFixed(2)
                    )}
                  </span>
                </div>)}
                {CLIENT_ID !== "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5" && this.props.calledFrom !== 'Testimony' && (
                  <div
                    className={`${
                      isLessonCourseOrSection ? 'align-items-center' : ''
                    } cursor-pointer flex-nowrap mr-4`}
                    onClick={() => this.props.switchRightSection('share')}
                  >
                    <ShareIcon />
                    <span className='ml-2 font-14'>
                      {Utility.kFormatter(
                        parseInt(this.props.data.share_count)
                      )}
                    </span>
                  </div>
                )}
                {(this.props.data.campaign_type_id ===
                  CAMPAIGN_TYPES_IDS.courses ||
                  this.props.data.campaign_type_id ===
                    CAMPAIGN_TYPES_IDS.lesson) && (
                  <div
                    className='align-items-center cursor-pointer flex-nowrap'
                    onClick={() => this.props.switchRightSection('course')}
                  >
                    <GraduationCapIcon color='#fff' />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className='course-completion-apple'>
              <h3>
                Well Done! you have earned an apple for completing the course!
              </h3>
              <img
                src={require('assets/images/client/ethos_logo_inside_white.png')}
                alt=''
              />
              <br />
              <button
                className='btn btn-lg btn-primary btn-half-rounded px-5'
                onClick={() => this.setState({ watched: false })}
              >
                Watch Again
              </button>
            </div>
          ))}

        {/* Media view for ahura
          * Integrated with ahura chrome extension
          * NOTE: Do not change div id
          */}
        {CLIENT_ID === AHURA_CLIENT_ID &&
            <>
            <div id="ahura-media-view" className="">
              <MediaView
                width='100%'
                innerClassName='react-player'
                outerClassName='player-wrapper'
                imageClassName='d-block w-100 article-page-carousel-main-img'
                imageUrl={
                  this.props.data.image_url ||
                  this.props.data.embedded_image_link
                }
                videoUrl={
                  this.props.data.video_url ||
                  this.props.data.embedded_video_link
                }
                download={this.props.download}
                openPaywall={this.props.openPaywall}
                videoWatched={this.state.completed}
                isStudent={this.props.isStudent}
                setVideoWatched={data => this.updateCampaignProgress(data)}
                setWatched={data => this.setState({ watched: data })}
                isCarousel={true}
              />

              <div className='justify-content-lg-start justify-content-between align-items-center flex-nowrap article-page-carousel-bottom p-3 position-absolute w-100 text-white'>
                <div className='align-items-center cursor-pointer flex-nowrap mr-4'>
                  <img
                    src={
                      this.props.isLiked
                        ? require('assets/images/icons/heart-red.svg')
                        : require('assets/images/icons/heart-white.svg')
                    }
                    alt=''
                    onClick={this.handleAnimation}
                  />
                  <span className='ml-2 font-14'>{this.props.likesCount}</span>
                  <div
                    className={
                      this.props.isLiked &&
                      this.state.showAnimation &&
                      (this.props.calledFrom !== 'RecommendedReads' ||
                        this.state.isTouched)
                        ? 'liked-article-container'
                        : 'liked-article-container d-none'
                    }
                  >
                    {[...Array(5).keys()].map(e => (
                      <img
                        key={e}
                        alt=''
                        src={require('assets/images/icons/heart-red.svg')}
                        className='liked-article'
                      />
                    ))}
                  </div>
                </div>
                <div
                  className={`${
                    isLessonCourseOrSection ? 'align-items-center' : ''
                  } cursor-pointer flex-nowrap mr-4`}
                  onClick={() => this.props.switchRightSection('chat')}
                >
                  <ChatOvalIcon />
                  <span className='ml-2 font-14'>
                    {this.props.commentsCount}
                  </span>
                </div>
                <div
                  className={`${
                    isLessonCourseOrSection ? 'align-items-center' : ''
                  } cursor-pointer flex-nowrap mr-4`}
                  onClick={() => this.props.switchRightSection('sendMoney')}
                >
                  <GiftIcon />
                  <span className='ml-2 font-14'>
                    $
                    {Utility.nFormatter(
                      parseFloat(
                        this.props.data.total_donation_amount || 0
                      ).toFixed(2)
                    )}
                  </span>
                </div>
                {this.props.calledFrom !== 'Testimony' && (
                  <div
                    className={`${
                      isLessonCourseOrSection ? 'align-items-center' : ''
                    } cursor-pointer flex-nowrap mr-4`}
                    onClick={() => this.props.switchRightSection('share')}
                  >
                    <ShareIcon />
                    <span className='ml-2 font-14'>
                      {Utility.kFormatter(
                        parseInt(this.props.data.share_count)
                      )}
                    </span>
                  </div>
                )}
                {(this.props.data.campaign_type_id ===
                  CAMPAIGN_TYPES_IDS.courses ||
                  this.props.data.campaign_type_id ===
                    CAMPAIGN_TYPES_IDS.lesson) && (
                  <div
                    className='align-items-center cursor-pointer flex-nowrap'
                    onClick={() => this.props.switchRightSection('course')}
                  >
                    <GraduationCapIcon color='#fff' />
                  </div>
                )}
              </div>
              </div>
                {/* distraction screen for #ahura */}
                <div  id="ahura-distraction-screen" className='d-none'>
                <div className='flex-center distracted-sec p-5'>
                <div className="text-center" >
                <h3 className="font-48 mb-2">Distracted?</h3>
                <p className="font-36 mb-lg-5 mb-4">  
                  Let's stay focused on the course.
                </p>
                <button
                  className='btn btn-lg btn-light continue-study-btn px-5'
                  onClick={this.resumeSession}
                >
                  Continue Studying
                </button>
                {/* <p className="mt-lg-5 mt-4 display-7" onClick={this.switchCourses}>Still struggling?</p> */}
              </div>
              </div>
                </div>
             
            </>
         }
      </>
    );
  }
}
