import React, { useState, useEffect } from 'react';
import { CircleOutlinedAddIcon, CloseIcon } from 'shared/icons/icons';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { InputField } from 'shared/components/form_elements';
import TileSelection from 'shared/components/tile_selection';
import { Utility } from 'utils';
import { GET_USERS_BY_NETWORKS, GET_FOLLOWERS_FOLLOWING, GROUP_MEMBERS } from 'config/constants/api_endpoints';
import { CLIENT_ID, GROUPS_INVITE_MEM_LIMIT, GROUPS_INVITE_FOLLOWER_LIMIT, GROUPS_INVITE_FOLLOWING_LIMIT } from 'config/constants/config';
import { STATUS } from 'config/constants';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export default function InviteMembers(props){
  const userData = useSelector(state => state.userData);
  const [selectedTab, setSelectedTab] = useState('public');

  const [groupsMembersStates, setGroupsMemberState] = useState({
    members: [],
    memberLimit: true,
    memberSearch: '',
  });

  const [followersMembersStates, setFollowersMemberState] = useState({
    followers: [],
    followersLimit: true,
    followersSearch: '',
  });

  const [followingMembersStates, setFollowingMemberState] = useState({
    following: [],
    followingLimit: true,
    followingSearch: '',
  });

  const [newSelectedMembers, setNewSelectedMembers] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);

  let debouncedFn = null;

  useEffect(() => {
    getUserDetails();
    getGroupMember();
  }, [groupsMembersStates.memberSearch, groupsMembersStates.memberLimit]);

  useEffect(() => {
    getGroupMember();
    getFollowersDetails();
  }, [followersMembersStates.followersSearch, followersMembersStates.followersLimit]);

  useEffect(() => {
    getGroupMember();
    getFollowingDetails();
  }, [followingMembersStates.followingSearch, followingMembersStates.followingLimit]);


  const handleMemberSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
        const searchString = ev.target.value;
        setGroupsMemberState(prevState => {
          return {
            ...prevState,
            memberSearch: searchString
          };
        });
      }, 500);
    }
    debouncedFn();
  };

  const removeMemberLimit = () => {
    setGroupsMemberState(prevState => {
      return {
        ...prevState,
        memberLimit: false
      };
    });
  };

  const getSelectedMembersIds = (arr) => {
    setNewSelectedMembers(arr);
  };

  const getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: 'all',
      forCommunity: true
    };

    if(groupsMembersStates.memberLimit)
      params.limit = GROUPS_INVITE_MEM_LIMIT;

    if(!Utility.isEmpty(groupsMembersStates.memberSearch))
      params.search = groupsMembersStates.memberSearch;

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.registrants)
          setGroupsMemberState(prevState => {
            return {
              ...prevState,
              members: body.registrants,
            };
          });
      }
    });
  };

  const handleFollowerSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
        const searchString = ev.target.value;
        setFollowersMemberState(prevState => {
          return {
            ...prevState,
            followersSearch: searchString
          };
        });
      }, 500);
    }
    debouncedFn();
  };

  const removeFollowerLimit = () => {
    setFollowersMemberState(prevState => {
      return {
        ...prevState,
        followersLimit: false
      };
    });
  };

  const getFollowersDetails = () => {
    const params = {
      type: 'follower',
      clientId: CLIENT_ID,
      userClientId : userData.id,
    };

    if(followersMembersStates.followersLimit)
      params.limit = GROUPS_INVITE_FOLLOWER_LIMIT;

    if(!Utility.isEmpty(followersMembersStates.followersLimit))
      params.search = followersMembersStates.followersLimit;

    Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setFollowersMemberState(prevState => {
          return {
            ...prevState,
            followers: body.follower
          };
        });
      }
    });
  };

  const handleFollowingSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
        const searchString = ev.target.value;
        setFollowingMemberState(prevState => {
          return {
            ...prevState,
            followingSearch: searchString
          };
        });
      }, 500);
    }
    debouncedFn();
  };

  const removeFollowingLimit = () => {
    setFollowingMemberState(prevState => {
      return {
        ...prevState,
        followingLimit: false
      };
    });
  };

  const getFollowingDetails = () => {
    const params = {
      type: 'following',
      clientId: CLIENT_ID,
      userClientId : userData.id,
    };

    if(followingMembersStates.followingLimit)
      params.limit = GROUPS_INVITE_FOLLOWING_LIMIT;

    if(!Utility.isEmpty(followingMembersStates.followingSearch))
      params.search = followingMembersStates.followingSearch;

    Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setFollowingMemberState(prevState => {
          return {
            ...prevState,
            following: body.following
          };
        });
      }
    });
  };

  const getGroupMember = () => {
    const params = {
      groupId: props.groupId,
    };

    Utility.sendRequest(GROUP_MEMBERS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setGroupMembers(body.map(e => e.member_id));
      }
    });
  };

  const sendInvite = () => {
    const params = {
      groupId: props.groupId,
      userClientIds: newSelectedMembers,
      status: STATUS.pending
    };

    Utility.sendRequest(GROUP_MEMBERS, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.data){
          props.setRefresh(Math.random());
          toast.success(body.message, { containerId: 'private' });
        }
      }
    });
    props.closeForm();
  };

  return(
    <div className={`col-5 px-0 ${props.position
      ? props.position
      : 'position-absolute '}
      bg-white page-sidebar-outer passport-sidebar-width`}>
      <div className={`page-sidebar-inner h-100 overflow-auto scroll-y shar-form search-form-pwa sidebar-form search-form search-form-full ${props.innerPosition}`}>
        <div className="page-sidebar-head border-bottom align-items-center page-sidebar-head-article">
          <CircleOutlinedAddIcon />
            <span className="font-bold display-8 ml-2" >
              Invite members
            </span>
          <CloseIcon
            mainClass="ml-auto cursor-pointer"
            handleClick={sendInvite}/>
        </div>
        <div id="form" className="page-sidebar-body page-sidebar-body-article" >
          <div className="client-profile-inner p-0 article-page-tabs article-create-tabs" style={{ position: 'inherit' }} >
            <Tabs id="sidebar-tabs" activeKey={selectedTab} onSelect={(k) => setSelectedTab(k)}>
              <Tab eventKey='public' title="PUBLIC">
                <InputField
                  handleChange={handleMemberSearch}
                  type="text"
                  className="input-form-size mb-5"
                  placeholder="Search Public…"
                />
                <TileSelection
                  inCallout={true}
                  selected={newSelectedMembers}
                  getSelectedIds={getSelectedMembersIds}
                  close={sendInvite}
                  showAll={removeMemberLimit}
                  items={groupsMembersStates.members.filter(d => !groupMembers.includes(d.id))}
                  isSearch={Utility.isEmpty(groupsMembersStates.memberSearch)}
                  showClose={true}
                />
              </Tab>
              <Tab eventKey='followers' title="FOLLOWERS">
                <InputField
                  handleChange={handleFollowerSearch}
                  type="text"
                  className="input-form-size mb-5"
                  placeholder="Search Followers…"
                />
                <TileSelection
                  inCallout={true}
                  selected={newSelectedMembers}
                  getSelectedIds={getSelectedMembersIds}
                  close={sendInvite}
                  showAll={removeFollowerLimit}
                  items={followersMembersStates.followers.filter(d => !groupMembers.includes(d.id))}
                  isSearch={Utility.isEmpty(followersMembersStates.followersSearch)}
                  showClose={true}
                />
              </Tab>
              <Tab eventKey='following' title="FOLLOWING">
                <InputField
                  handleChange={handleFollowingSearch}
                  type="text"
                  className="input-form-size mb-5"
                  placeholder="Search Followings…"
                />
                <TileSelection
                  inCallout={true}
                  selected={newSelectedMembers}
                  getSelectedIds={getSelectedMembersIds}
                  close={sendInvite}
                  showAll={removeFollowingLimit}
                  items={followingMembersStates.following.filter(d => !groupMembers.includes(d.id))}
                  isSearch={Utility.isEmpty(followingMembersStates.followingSearch)}
                  showClose={true}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
