import React from "react";
import { EditorState, convertToRaw, ContentState, RichUtils } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { TOOL_BAR_OPTION } from "config/constants";
import { Utility } from "utils";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// import CommentTool from "./CommentTool";

export default class RichTextEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      commentEditorState: EditorState.createEmpty(),
      isDefaultValueSet: false,
    };
  }

  
  componentDidMount = () => {
    if (this.props.data) {
      console.log(this.props.data)
      const len = Utility.getPlainTextFromRichText(this.props.data).trim()
        .length;
      if (len !== 0 && !this.state.isDefaultValueSet) {
        const editorState = this.setDefaultValues(this.props.data);
        this.setState({
          editorState: editorState,
          isDefaultValueSet: true,
        });
      }
    }
  }

  componentDidUpdate = () => {
    if (this.props.data) {
      const len = Utility.getPlainTextFromRichText(this.props.data).trim()
        .length;
      if (len !== 0 && !this.state.isDefaultValueSet) {
        const editorState = this.setDefaultValues(this.props.data);
        this.setState({
          editorState: editorState,
          isDefaultValueSet: true,
        });
      }
    }
  };

  setDefaultValues = (htmlData) => {
    const contentBlock = htmlToDraft(htmlData);
    let editorState;

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editorState = EditorState.createWithContent(contentState);
    }

    return editorState;
  };

  onEditorStateChange = (editorState) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    this.setState({ editorState: editorState, isDefaultValueSet: true });
    this.props.changeDescription(data, this.props.id);
  };

  render() {
    const { editorState, commentEditorState } = Utility.isEmpty(this.props.data)
      ? {}
      : this.state;
    let placeholder = "Enter the content";

    if (this.props.placeholder) placeholder = this.props.placeholder;

    return (
      <>
        <Editor
          id={this.props.id}
          name={this.props.name}
          ref="description"
          editorState={editorState}
          placeholder={this.state.editorState && placeholder}
          wrapperClassName={`demo-wrapper ${
            this.props.courseClass && this.props.courseClass
          } rte-form-control`}
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={this.props.toolbar ? this.props.toolbar : TOOL_BAR_OPTION}
        />
        <div className="error errorIcon" id={this.props.id + "Error"} />
      </>
    );
  }
}
