import React from 'react';
import { Checkbox } from './form_elements';
import { Utility } from 'utils';

const PayoutScheduleCheckbox = (props) => {
  const handleChange = (e) => Utility.handleAgreement(e.target.checked);

  return (
    <>
      <div className="d-flex align-items-baseline checkbox-payable space-nowrap mb-3">
        <Checkbox
          checkboxId="agree-terms"
          name="agree-terms"
          label="Agree to"
          handleChange={handleChange}
        />
        <label className="text-payout ml-2"><span className="d-block text-payout space-nowrap space-sm-wrap cursor-pointer" onClick={() => { Utility.handlePayoutOverlay(props.sourceId, true); }}>{props.linkName}</span></label>
      </div>
      <div className="error errorIcon" id='terms-error'></div>
    </>
  );
};

export default PayoutScheduleCheckbox;
