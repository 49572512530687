import React, { Component } from 'react';

export class EditIcon extends Component {
    render(){
      return(
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" >
        <title></title>
          <desc>Created with Sketch.</desc>
           <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Dashboard-Profile" transform="translate(-277.000000, -92.000000)"
                fill={this.props.fillColor || `#373838`}>
                  <g id="Icons/Edit-Icon" transform="translate(275.000000, 90.000000)">
                      <g id="Edit-Icon" transform="translate(2.500000, 2.500000)">
                          <path d="M13.249875,2.956425 L12.647625,3.559425 L11.441625,2.353425 L12.044625,1.750425 C12.367125,1.427925 12.928125,1.428675 13.249875,1.750425 C13.582125,2.082675 13.582125,2.624175 13.249875,2.956425 L13.249875,2.956425 Z M4.837875,11.367675 L2.426625,12.573675 L3.632625,10.161675 L10.381125,3.413925 L11.587125,4.619925 L4.837875,11.367675 Z M14.311125,0.689175 C13.422375,-0.198825 11.873625,-0.199575 10.984125,0.689175 L2.485875,9.187425 C2.428875,9.245175 2.380875,9.310425 2.345625,9.383175 L0.079125,13.915425 C-0.064875,14.204175 -0.008625,14.552925 0.219375,14.780175 C0.447375,15.008175 0.796875,15.065925 1.084875,14.921175 L5.617125,12.655425 C5.689125,12.618675 5.755875,12.572175 5.812125,12.514425 L14.311125,4.016175 C15.199125,3.126675 15.199125,1.577925 14.311125,0.689175 L14.311125,0.689175 Z" id="Fill-2153"></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
  }
}
