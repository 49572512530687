import React from 'react';

export const BackButton = (props) => {
  return(
    <div className="go-back-icon line-height-1 display-6 position-absolute">
      <img src={require("assets/images/icons/back.svg")} alt="back button" onClick={() => props.action(props.name)} />
    </div>
  );
};

export const Skip = (props) => {
  return(
    <button
      className="btn btn-link btn-cancel lg-btn w-100"
      onClick={() => props.skip()}
    >
      {props.name}
    </button>
  );
};

export const Image = () => {
  return(
    <img src={require("assets/images/logos/main-logo.png")} className="splash-logo mx-auto" alt="sharemeister partners" />
  );
};

export const Cancel = (props) => {
  return(
    <button
      className="btn btn-link btn-cancel lg-btn w-100"
      onClick={() => props.cancel()}
    >
      CANCEL
    </button>
  );
};
