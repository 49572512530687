import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showLoader, updateSelectedMenuItem, updateIsChatFormOpen, updateChatFriend } from 'shared/actions';
import { Utility } from 'utils';
import { DROPDOWN_ACTIONS, MENU, SM_CLIENT_ID } from 'config/constants';
import { CLIENT_ID } from 'config/constants/config';
import { MESSAGES_COUNT, CLIENT_NOTIFICATIONS_COUNT, GET_USERS_BY_NETWORKS, CLIENT_NOTIFICATIONS, ALL_CAMPAIGN_COMMENTS, ALL_CC_COUNT, NOTIFICATION_MARK_AS_READ, COMMENTS_MARK_AS_READ  } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import Menu from 'shared/components/menu';
import ChatCallout from 'shared/components/chat_callout.js';
import ItemDetailComm from 'scenes/communications/components/item_detail_comm';
import TableListComm from 'scenes/communications/components/table_list_comm';
import { NAMES_MAPPING } from 'config/constants/config';
import DirectMessage from 'scenes/communications/direct_message';
import { WelcomeOverlays } from 'shared/components/welcome_overlays';

class CommunicationsContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      dropdownAction: 0,
      menus: MENU.communications,
      users: [],
      notifications: [],
      allCCData: [],
      selectedUser: {},
      chat: props.location.params ?
            props.location.params.friend : {},
      showChat: false,
    };

    this.props.updateSelectedMenuItem({});
  }

  componentDidMount = () => {
    this._isMounted = true;
    if (!Utility.isEmpty(this.props.userData)) {
      this.getUserDetails();
      this.getNotificationsData();
      this.getAllCampaignsCommentsDetails();
      this.menuCount();
    }

    if (!Utility.isEmpty(this.props.history.location.state)) {
      this.setState({ chat: this.props.history.location.state.friend });
    }
  }

  componentDidUpdate = (prevProps) => {
    const list = document.getElementById('menu-list');

    if (list) list.classList.add('d-block');

    if (Utility.isEmpty(prevProps.userData) &&
       !Utility.isEmpty(this.props.userData))
       this.menuCount();
  }

  componentWillUnmount = () => this._isMounted = false;

  menuCount = () => {
    this.getClientNotificationsCount();
    this.getAllCCCount();
  }

  updateMenus = (menuId, count) => {
    const menus = this.state.menus;

    menus.forEach((menu) => { if (menu.id === menuId) menu.count = count; });

    if (this._isMounted) this.setState({ menus });
  }

  getAllCCCount = () => {
    const params = {
      clientId: CLIENT_ID,
      userClientId: this.props.userData.id,
    };

    Utility.sendRequest(ALL_CC_COUNT, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.updateMenus(4, body.data.count);
      }
    });
  }

  getClientNotificationsCount = () => {
    const params = { ':id': this.props.userData.id };
    const baseURL = Utility.getAPIEndpointWithParams(CLIENT_NOTIFICATIONS_COUNT, params);

    Utility.sendRequest(baseURL, 1, null, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.updateMenus(2, body.count);
      }
    });
  }

  updateUnreadMessageCount = (value) => {
    const menus = this.state.menus;
    menus[0].count += (value);

    this.setState({ menus });
  }

  handleMenuItemClick = (selectedMenuItem) => {
    this.props.updateSelectedMenuItem(selectedMenuItem);

    this.setState({ dropdownAction: 0 });
  }

  handleDropdownAction = (selectedAction) => {
    this.props.updateSelectedMenuItem({});

    this.setState({ dropdownAction: selectedAction.id }, () => {
      Utility.showComponent(true, 'attach-friend');
    });
  }

  reset = () => {
    this.props.updateSelectedMenuItem({});

    this.setState({ dropdownAction: 0 });
  }

  getMessageReadCount = () => {
    let readCount = 0;
    if(this.state.users){
      this.state.users.map((e) => {
        if(!e.is_read) readCount++;
      });
    }
    return readCount;
  }

  getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: 'all',
      forCommunity: true,
      forCommunication: true,
    };

    this.props.showLoader(true);

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      this.props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.registrants)
          this.setState({ users: body.registrants.sort(Utility.compareValues('duration')) }, () => {
            this.updateMenus(3, this.getMessageReadCount());
          });
      }
    });
  };

  getAllCampaignsCommentsDetails = () => {
    const params = {
      clientId: CLIENT_ID,
      userClientId: this.props.userData.id,
    };

    Utility.sendRequest(ALL_CAMPAIGN_COMMENTS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.data)
          this.setState({ allCCData: body.data });
      }
    });
  };

  getNotificationsData = () => {
    const params = { ':id': this.props.userData.id };
    const baseURL = Utility.getAPIEndpointWithParams(CLIENT_NOTIFICATIONS, params);

    this.props.showLoader(true);

    Utility.sendRequest(baseURL, 1, {}, (error, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);

      if (!body.error) {
        this.setState({ notifications: body.data });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  markNotificationsRead = (id) => {
    Utility.sendRequest(NOTIFICATION_MARK_AS_READ, 3, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error)
        toast.error(body.error.message, { containerId: 'public' });
      else {
        this.getNotificationsData();
        this.getClientNotificationsCount();
      }
    });
  }

  markCommentsRead = (id) => {
    Utility.sendRequest(COMMENTS_MARK_AS_READ, 3, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error)
        toast.error(body.error.message, { containerId: 'public' });
      else {
        this.getAllCampaignsCommentsDetails();
        this.getAllCCCount();
      }
    });
  }

  handleAction = (dropdownAction, item, selectedTab) => {
    const self = this;
    if(dropdownAction === 'Mark As Read'){
      if(self.props.selectedMenuItem === 2 || selectedTab === 'notification') {
        self.markNotificationsRead(item.id);
      }

      if(self.props.selectedMenuItem === 4 || selectedTab === 'comments') {
        self.markCommentsRead(item.id);
      }
    }
  }

  handleChatClick = (user) => {
    if(user){
      this.props.updateChatFriend(user);
      this.props.updateIsChatFormOpen(true);
    }
  }

  render() {
    const { is_super_owner, platform_id } = this.props.userData;
    return (
      <>
        {
          (is_super_owner || platform_id !== SM_CLIENT_ID) ?
            <Row className="no-gutters h-md-100">
            {
              !Utility.isEmpty(this.props.userData) &&
              <Menu
              selectedModule={{ name: 'Communications' }}
              userName={`${this.props.userData.User && this.props.userData.User.first_name}
              ${this.props.userData.User && this.props.userData.User.last_name}`}
              dropdownItems={DROPDOWN_ACTIONS.communications}
              handleDropdownAction={this.handleDropdownAction}
              handleMenuItemClick={this.handleMenuItemClick}
              menus={this.state.menus}
              selectedMenuItemId={this.props.selectedMenuItem.id || (this.state.dropdownAction == 0 && 2)}
              isMenuAction={true}
              isShowButton= {true}
              showCount={true}
              showZeroInCount={true}
              />
            }

            {
              (this.props.selectedMenuItem.id === 2 ||
                Utility.isEmpty(this.props.selectedMenuItem.id) &&
                this.state.dropdownAction == 0) &&
                <ItemDetailComm
                showFilterComponent={this.state.showFilterComponent}
                filterList={this.filterList}
                headerTitle={this.props.selectedMenuItem.menu_name || 'Notifications'}>
                <TableListComm
                data={this.state.notifications}
                handleAction={this.handleAction}
                showLoader={this.state.showLoader}
                selectedTab='notification'
                tableTitles={["Name", "Notification", "Timestamp", "Action"]}
                actions={["Mark As Read"]}
                userData={this.props.userData}
                handleClick={() => {}}
                />
                </ItemDetailComm>
              }

              { this.props.selectedMenuItem.id === 3 &&
                <ItemDetailComm
                showFilterComponent={this.state.showFilterComponent}
                filterList={this.filterList}
                headerTitle={this.props.selectedMenuItem.menu_name}>
                <TableListComm
                data={this.state.users}
                handleAction={this.handleAction}
                actions={["Respond"]}
                showLoader={this.state.showLoader}
                selectedTab='message'
                tableTitles={["Name", "Message", "Timestamp", "Action"]}
                userData={this.props.userData}
                handleClick={this.handleChatClick}
                />
                </ItemDetailComm>
              }

              { this.props.selectedMenuItem.id === 4 &&
                <ItemDetailComm
                showFilterComponent={this.state.showFilterComponent}
                filterList={this.filterList}
                headerTitle={this.props.selectedMenuItem.menu_name}>
                <TableListComm
                data={this.state.allCCData}
                handleAction={this.handleAction}
                actions={["Mark As Read"]}
                showLoader={this.state.showLoader}
                selectedTab='comments'
                tableTitles={[Utility.capitalize(NAMES_MAPPING.CAMPAIGN.story.parent), "Name", "Comments", "Timestamp", "Action"]}
                userData={this.props.userData}
                handleClick={this.handleChatClick}
                />
                </ItemDetailComm>
              }

              { (this.state.dropdownAction === 2) &&
                <DirectMessage dropdownAction={this.state.dropdownAction}
                selectedMenuItem={this.props.selectedMenuItem}
                userData={this.props.userData}
                reset={this.reset}
                updateUnreadMessageCount={this.updateUnreadMessageCount}
                friend={this.state.chat}
                history={this.props.history}
                isConversation={this.props.location.params ?
                  this.props.location.params.isConversation : false}
                  isRecentChatView={this.props.location.params ?
                    this.props.location.params.isRecentChatView : true}
                    />
                  }

                  { this.props.isChatFormOpen &&
                    <ChatCallout />
                  }
            </Row>
          :
          <WelcomeOverlays
            heading="Welcome to Communications!"
            subHeading="Engage in multiple forms of conversation with your community."
            mainText="Ask for a customized DEMO to learn how all the available features of this module can be used to serve the requirements of your digital application."
            btnText="Go to module!"
          />
        }
      </>
    );
  }
}

const mapActionToProps = { showLoader, updateSelectedMenuItem, updateIsChatFormOpen, updateChatFriend };

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedModule: state.selectedModule,
  selectedMenuItem: state.selectedMenuItem,
  isChatFormOpen: state.isChatFormOpen,
  chatFriend: state.chatFriend,
});

export default connect(mapStateToProps, mapActionToProps)(CommunicationsContainer);
