import React, { Component } from "react";
import cookie from 'react-cookies';
import { toast, ToastContainer } from "react-toastify";
import CloseToast from 'shared/components/close_toast';
import { NAME_SILHOUETTE_CLIENT_ID } from "config/constants";
import { CLIENT_ID } from 'config/constants/config';

export default class HomePageLayout extends Component {
  constructor(props) {
    super(props);

    const token = cookie.load('sessionToken');
    if (token) {
      if (CLIENT_ID === NAME_SILHOUETTE_CLIENT_ID) {
        this.props.history.push('/');
      } else{
        this.props.history.push('/home');
      }
    }
  }

  render() {
    return (
      <main className="main-container">
        <ToastContainer hideProgressBar={true} autoClose={6000} containerId={'public'} position={toast.POSITION.TOP_RIGHT} closeButton={
          <CloseToast />
        }/>
        {this.props.children}
      </main>
    );
  }
}
