export const FORM_ERRORS = {
  story: {
    heading: 'Heading',
    image_url: 'Either select an image or fill up the embedded image link below',
    video: 'Embedded link should be a youtube URL',
    description: 'Description is required field',
    story_url: 'Please enter the valid story URL',
    publisher_network_id: 'Publisher Network',
    category_id: 'Category',
    sub_heading: 'Sub Heading'
  },
  courses: {
    heading: 'Course Name',
    image_url: 'Either select an image or fill up the embedded image link below',
    video: 'Embedded link should be a youtube URL',
    description: 'Description is required field',
    story_url: 'Please enter the valid story URL',
    category_id: 'Category'
  },
  lesson: {
    heading: 'Heading',
    image_url: 'Either select an image or fill up the embedded image link below',
    video: 'Embedded link should be a youtube URL',
    description: 'Description is required field',
  },
  initiative: {
    heading: 'Name',
    image_url: 'Either select an image or fill up the embedded image link below',
    video: 'Embedded link should be a youtube URL',
    description: 'Description is required field',
    publisher_network_id: 'Publisher Network',
  },
  post: {
    heading: 'Name',
  },
  event: {
    heading: 'Heading',
    sub_heading: 'Sub Heading',
    image_url: 'Either select an image or fill up the embedded image link below',
    video: 'Embedded link should be a youtube URL',
    description: 'Description is required field',
    publisher_network_id: 'Publisher Network',
    category_id: 'Category'
  },
  add_primary_product: {
    name: 'Name',
    image_url: 'Image is required field',
    category_id: 'Product Type',
    summary: 'Summary is required field',
   // description: 'Description is required field',
    price: 'Price',
    tags: 'Remove special characters',
  },
  create_category: {
    name: 'Name',
    description: 'Description',
    sub_categories: 'Remove special characters',
    agree_terms: 'please select'
  },
  create_customGroup: {
    name: 'Name'
  },
  registration : {
    email: 'Email',
    password: 'Password',
    first_name: 'First Name',
    last_name: 'Last Name',
    zip_code: 'Zip Code'
  },

  ahuraRegistration: {
    email: 'Email',
    password: 'Password',
    confirm_password: 'Confirm password',
    first_name: 'First Name',
    last_name: 'Last Name',
    agreed: 'Agree'
  },

  nameSilhouetteRegistration:{
    email: 'Email',
    password: 'Password',
    first_name: 'First Name',
    last_name: 'Last Name',
    phone_cellular: 'Phone number',
    zip_code: 'Zipcode',
  },

  nameSilhouetteprofileUpdate:{
    email: 'Email',
    password: 'Password',
    first_name: 'First_name',
    last_name: 'Last_name',
    phone_cellular: 'Phone Number',
    zip_code: 'Zipcode',
    confirm_password: 'Confirm Password'
  },

  customSilhouetteForm: {
    first_name: 'First Name',
    middle_name: 'Middle Name',
    last_name: 'Last Name',
    email: 'Email'
  },

  guestForm:{
    guest_email: 'Email',
    guest_first_name: 'First Name',
    guest_last_name: 'Last Name',
  },

  pwa_sign_up: {
    first_name: 'First Name',
    last_name: 'Last Name',
    password: 'Password',
    confirm_password: 'Confirm password',
    email: 'Email'
  },
  pwa_login: {
    email: 'Email',
    password: 'Password'
  },
  pwa_campaign: {
    heading: 'Heading',
    sub_heading: 'Title',
    campaign_type_id: 'Campaign type',
    description: 'Description is required field',
    image_url: 'Image is required field'
  },
  neighborhood_form: {
    name: 'Name',
    email: 'Email',
    country_id: 'Country',
    state_id: 'State',
    address1: 'Address',
    city: 'City',
    zip_code: 'Zip code',
    password: 'Password',
    existing_password: 'Existing password',
    confirm_password: 'Confirm password',
    image_url: 'Image is required field',
    phone: 'Phone',
    first_name: 'First Name',
    last_name: 'Last Name'
  },
  campaign: {
    heading: 'Title',
    sub_heading: 'Title',
    campaign_type_id: 'Campaign type',
    description: 'Description is required field',
    image_url: 'Either select an image or fill up the embedded image link below',
  },
  campaign_category: {
    name: 'Name',
    image_url: 'Image is required field'
  },
  email: {
    email: 'Email'
  },
  invite: {
    entity_first_name: 'First Name',
    entity_last_name: 'Last Name',
    entity_email: 'Email'
  }
};
