import React, { Component } from "react";
import { Date as date } from "utils";

export class CourseCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = { date: "" };
    this.handleCertificate.bind(this);
  }
  componentDidMount() {
    this.getDate();
  }
  handleCertificate = (completed) => {
    this.props.setIsCourseCompleted(completed);
  };
  getDate = () => {
    var date = new Date().toDateString();
    this.setState({ date: date });
  };
  GmBadgeIcon = () => {
    return (
      <svg
        width="85px"
        height="120px"
        viewBox="0 0 38 45"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="linearGradient-1"
          >
            <stop stop-color="#FFCA00" offset="0%"></stop>
            <stop stop-color="#FF9C52" offset="100%"></stop>
          </linearGradient>
          <path
            d="M8,-4.61852778e-14 L30,-4.61852778e-14 C34.418278,-4.69969023e-14 38,3.581722 38,8 L38,29.670477 C38,32.4805798 36.5256093,35.0846344 34.115966,36.5304204 L23.115966,43.1304204 C20.5825069,44.6504958 17.4174931,44.6504958 14.884034,43.1304204 L3.88403396,36.5304204 C1.47439067,35.0846344 2.12049518e-15,32.4805798 0,29.670477 L0,8 C-5.41083001e-16,3.581722 3.581722,-4.53736533e-14 8,-4.61852778e-14 Z"
            id="path-2"
          ></path>
          <filter
            x="-71.1%"
            y="-62.8%"
            width="242.1%"
            height="225.6%"
            filterUnits="objectBoundingBox"
            id="filter-4"
          >
            <feOffset
              dx="0"
              dy="9"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="2.5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 1   0 0 0 0 0.462842988   0 0 0 0 0.462842988  0 0 0 0.597574301 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
        </defs>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Gold-Medal-Badge">
            <g id="perfectionist-badge">
              <mask id="mask-3" fill="white">
                <use xlinkHref="#path-2"></use>
              </mask>
              <use
                id="Rectangle"
                fill="url(#linearGradient-1)"
                xlinkHref="#path-2"
              ></use>
              <g
                id="001-medal"
                filter="url(#filter-4)"
                mask="url(#mask-3)"
                fillRule="nonzero"
              >
                <g transform="translate(0.000000, -6.000000)" id="Path">
                  <path
                    d="M36.6174416,3.78651082 L31.5641818,0.479030303 C30.213619,-0.405004329 28.3974329,-0.0301038961 27.5064069,1.31313853 L18.4102597,14.4423853 C18.1909784,14.7588052 18.1750216,15.1735974 18.369381,15.505974 L23.9075108,24.981381 C24.0871472,25.2887532 24.4121212,25.4919957 24.7681039,25.4940519 C24.7704892,25.4940519 24.7728745,25.4940519 24.7752597,25.4940519 C25.1688312,25.4940519 25.5555758,25.3059437 25.7941861,24.9563766 L37.4536061,7.87422511 L37.4669307,7.85432035 C38.3541732,6.49865801 37.9731039,4.6738355 36.6174416,3.78651082 Z"
                    fill="#FF3636"
                  ></path>
                  <path
                    d="M25.7908961,23.5623853 L10.4944978,1.37383983 C10.0644069,0.724714286 9.40845455,0.279818182 8.64557576,0.120497835 C7.87718615,-0.0398917749 7.09242857,0.108406926 6.43573593,0.538251082 L1.38247619,3.84581385 C0.0268138528,4.73322078 -0.35417316,6.55804329 0.533151515,7.91370563 L0.542363636,7.92785281 L12.1638658,24.6670173 C12.293987,24.8544675 12.504303,24.9702771 12.732303,24.9799827 L24.7775628,25.4941342 C25.0187229,25.4941342 25.2623506,25.4234805 25.4758745,25.2762511 C26.0360866,24.8900823 26.1771472,24.1226797 25.7908961,23.5623853 Z"
                    fill="#FF712F"
                  ></path>
                  <path
                    d="M29.5115238,31.5938745 C29.5115238,37.3937273 24.7927792,42.1125541 18.9928442,42.1125541 C13.1929091,42.1125541 8.4741645,37.3938095 8.4741645,31.5938745 C8.4741645,25.7931991 13.1929091,21.0743723 18.9928442,21.0743723 C24.7927792,21.0743723 29.5115238,25.7931169 29.5115238,31.5938745 Z"
                    fill="#FFD561"
                  ></path>
                  <path
                    d="M24.4403333,31.3786234 L22.7595325,33.0167359 L23.1563117,35.3293074 C23.2499957,35.8731515 23.030632,36.4120606 22.5845022,36.7357186 C22.137632,37.0601991 21.5575974,37.1021472 21.0696017,36.8449481 L18.9928442,35.7531472 L16.9160866,36.8457706 C16.7041255,36.956645 16.4741515,37.0125758 16.2457403,37.0125758 C15.9483203,37.0125758 15.6542727,36.9188918 15.4020087,36.7357186 C14.9551385,36.4120606 14.7365974,35.8731515 14.8293766,35.3293074 L15.2261558,33.0167359 L13.5461775,31.3794459 C13.1510433,30.9941818 13.011381,30.4297749 13.1822165,29.9048485 C13.3523117,29.3807446 13.7967143,29.0052684 14.343026,28.9263896 L16.664645,28.5887489 L17.7030649,26.4848485 C17.9470216,25.9902727 18.4415974,25.6830649 18.9928442,25.6830649 C19.2680563,25.6830649 19.5301082,25.7602987 19.7527619,25.898316 C19.9762381,26.0371558 20.1602338,26.2376017 20.2826234,26.4848485 L21.3210433,28.5887489 L23.6426623,28.9263896 C24.188974,29.0052684 24.6333766,29.3806623 24.8034719,29.9048485 C24.9743074,30.4289524 24.834645,30.9941818 24.4403333,31.3786234 Z"
                    fill="#FFF2D4"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };

  EmptyBadgeIcon = () => {
    return (
      <svg
        width="85px"
        height="120px"
        viewBox="0 0 38 44"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            d="M8,0 L29.5,0 C33.918278,-3.47615976e-15 37.5,3.581722 37.5,8 L37.5,29.220477 C37.5,32.0305798 36.0256093,34.6346344 33.615966,36.0804204 L22.865966,42.5304204 C20.3325069,44.0504958 17.1674931,44.0504958 14.634034,42.5304204 L3.88403396,36.0804204 C1.47439067,34.6346344 -1.4322185e-15,32.0305798 0,29.220477 L0,8 C-5.41083001e-16,3.581722 3.581722,-1.85291076e-15 8,0 Z"
            id="path-1"
          ></path>
          <filter
            x="-5.3%"
            y="-4.4%"
            width="110.7%"
            height="105.9%"
            filterUnits="objectBoundingBox"
            id="filter-3"
          >
            <feGaussianBlur
              stdDeviation="1.5"
              in="SourceAlpha"
              result="shadowBlurInner1"
            ></feGaussianBlur>
            <feOffset
              dx="0"
              dy="1"
              in="shadowBlurInner1"
              result="shadowOffsetInner1"
            ></feOffset>
            <feComposite
              in="shadowOffsetInner1"
              in2="SourceAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"
              result="shadowInnerInner1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowInnerInner1"
            ></feColorMatrix>
          </filter>
        </defs>
        <g
          id="WEB-BROWSER"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="3.0-Profile-Home"
            transform="translate(-301.000000, -478.000000)"
          >
            <g id="Passport" transform="translate(82.000000, 429.000000)">
              <g
                id="course-1-copy-2"
                transform="translate(0.000000, 34.000000)"
              >
                <g
                  id="Empty-Badge-Icon"
                  transform="translate(219.000000, 15.000000)"
                >
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                  </mask>
                  <g id="Rectangle">
                    <use
                      className="empty-badge-fill"
                      fill="#CACDFF"
                      fillRule="evenodd"
                      xlinkHref="#path-1"
                    ></use>
                    <use
                      fill="black"
                      fillOpacity="1"
                      filter="url(#filter-3)"
                      xlinkHref="#path-1"
                    ></use>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };

  render() {
    // let totalAssign = 0;
    // let totalCompletedAssign = 0;
    // let totalLesson = 0;
    // let totalCompletedLesson = 0;
    // var d1 = new Date(0);

    // this.props.allAssignments
    //   .map((data) => data)
    //   .forEach((list) => {
    //     var temp = 0;
    //     if (list.assignmentList !== null) {
    //       totalAssign = totalAssign + list.assignmentList.length;
    //       list.assignmentList
    //         .map((list) => list)
    //         .forEach((data) => {
    //           if (data.score != null) {
    //             totalCompletedAssign += 1;
    //             temp = temp + 1;
    //             let d2 = new Date(data.score_last_updated);
    //             if (d1.getTime() < d2.getTime()) d1 = d2;
    //           }
    //         });
    //       if (temp === list.assignmentList.length) totalCompletedLesson += 1;
    //     }

    //     if (list.lessonHeading !== null) totalLesson += 1;
    //   });

    // const Score = Math.round(
    //   ((totalCompletedLesson + totalCompletedAssign) * 100) /
    //     (totalLesson + totalAssign)
    // );
    // const year = d1.getFullYear();
    // const day = d1.getDate();
    // const month = date.monthNameByNumber(d1.getMonth() + 1);

    return (
      <>
        <div
          className={
            this.props.isCourseSidebar
              ? "CoursePageExpantion w-100 h-100 mt-3"
              : "w-100 h-100 pl-5 mt-3"
          }
        >
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column bd-highlight mb-3 align-items-center">
              <div className="font-bold">
                You have completed{" "}
                <span className="h5">{isNaN(this.props.Score) ? '0' : this.props.Score}%</span> of course
              </div>
              <div
                className="progress mt-2 mb-3 text-center"
                style={{ height: "10px", width: "280px" }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${this.props.Score}%`,
                    backgroundColor: "SlateBlue",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column bd-highlight mb-3 align-items-center">
            <div className="courseCertificate p-2 pb-4 pt-3">
              <div className=" p-2 bd-highlight certificateTitle">
                Certificate Of Completion
              </div>
              <div className="p-2 bd-highlight">
                {!isNaN(this.props.Score) && this.props.Score === 100
                  ? this.GmBadgeIcon()
                  : this.EmptyBadgeIcon()}
              </div>
              <div className="p-2 bd-highlight">
                <h5>
                  {this.props.courseDetails[0] !== undefined &&
                    this.props.courseDetails[0].heading}
                </h5>
              </div>
              {!isNaN(this.props.Score) && this.props.Score === 100 ? (
                <>
                  <div className="p-2 bd-highlight">
                    {this.props.userData.profile_image_url && (
                      <img
                        src={this.props.userData.profile_image_url}
                        className="w-25 h-25 rounded-circle"
                      />
                    )}
                  </div>
                  <div className="p-2 bd-highlight">
                    <h5>{this.props.userData.user_name}</h5>
                  </div>
                  <div className="p-2 bd-highlight display-6">
                    has Completed Course on {this.props.day}th{" "}
                    {this.props.month} {this.props.year}
                  </div>
                </>
              ) : (
                <div className="p-2 bd-highlight">
                  Earn your certificate when you complete all the lessons and
                  assignments in the course
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
