import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner, Form, Image } from 'react-bootstrap';
import { InputField, Checkbox } from 'shared/components/form_elements.js';
import { LOGIN, USER_AUTH } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Utility } from 'utils';
import { Link } from 'react-router-dom';
import { FiUserPlus, FiUserX } from 'react-icons/fi';
import arrowLeft from 'assets/images/arrow.png';
import { CLIENT_ID } from 'config/constants/config';
import { ROLES, ROLE_IDS } from 'config/constants';
import { updateUserData, showLoader } from 'shared/actions';
import { ReactComponent as Close } from 'assets/images/icons/close_icon.svg';
import { BackButton } from 'shared/components/web';
import { ShowIcon } from 'shared/icons/icons';
import Avatar from "react-avatar";

const ConfirmCredientials = (props) => {

  useEffect(() => {
    if (!Utility.isEmpty(props.userData))
      props.history.push('/');
  });

  const accountTypeName = () => {
    switch (props.user.role_id) {
      case ROLE_IDS.SUPER_OWNER:
        return ROLES.PARENT_OWNER;
      case ROLE_IDS.PARENT_OWNER:
        return ROLES.PARENT_OWNER;
      case ROLE_IDS.USER:
        return ROLES.USER;
      case ROLE_IDS.CLIENT_OWNER:
        return ROLES.CLIENT_OWNER;
      default:
        return ROLES.USER;
    }
  };
  return (
    <>
      <React.Fragment>
        <div className="container">
          <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3" >
            <div className="d-flex cursor-pointer" >
              <BackButton action={() => props.goLogin()} />
            </div>
            <div onClick={() => props.goLogin()}><img src={require("assets/images/logos/main-logo.png")} className="register-logo mb-4" alt="sharemeister" /></div>
            <h3 className="mb-5 font-bold" >Please confirm Credentials</h3>
            <div className="new-landing-theme register-form" >
              <div className="account-info-outer align-items-center form-element-outer mx-auto mb-4" >
                <div className="account-info-pic mr-3" >
                  {props.user.first_name ? <Image className="profile-width rounded-circle" src={!Utility.isEmpty(props.user.profile_image_url) ? props.user.profile_image_url : require("assets/images/users/user_no_image.png")} alt="Profile Picture" /> : ''}
                </div>
                <div className="text-left" >
                  <h5 className="font-bold" >{props.user.user_name} </h5>
                  {accountTypeName()}
                </div>
              </div>
              <Form>
                <Form.Group className='position-relative form-element-outer mx-auto'>
                  <InputField
                    required={true}
                    name="password"
                    className="form-control"
                    type={props.inputType}
                    value={props.password}
                    placeholder="Password"
                    handleChange={(e) => props.handlePassword(e)}
                    ref={props.Ref}
                  />
                  <b className="show-icon" style={{ top: 14 }} onClick={() => props.toggleShowPassword()}>
                    <ShowIcon mainClass={`sm-show-icon ${props.inputType === "text" ? "sm-show-icon-active" : ""}`} />
                  </b>
                </Form.Group>
                <Link
                  className="form-element-outer mx-auto display-8 text-left mt-n2 d-block"
                  to={{
                    pathname: "/users/forgot_password",
                    state: {
                      email: props.user.email,
                      firstName: props.user.first_name,
                      lastName: props.user.last_name,
                      profileImgUrl: props.user.profile_image_url,
                      role: props.user.role,
                    }
                  }} >
                  Forgot Password?
                </Link>
                <Form.Group className='form-element-outer mx-auto text-left mt-3'>
                  <Checkbox
                    // ref="remember_me"
                    placeholder="Remember Me"
                    checkboxId="remember_me"
                    checked={props.remember_me}
                    name="remember_me"
                    label="Remember Password"
                    handleChange={(e) => props.handleRememberMe(e, props.user)}
                  />
                </Form.Group>
                <div className="text-left form-element-outer mx-auto mt-5" >
                  <button
                    type="submit"
                    className="btn btn-primary btn-login"
                    onClick={(e) => props.login(e, props.user.email)}>
                    {props.showLoader ? <Spinner animation="border" role="status" /> : 'Login'}
                  </button>
                </div>
              </Form>
              <Link
                className="d-inline-block mx-auto text-primary font-bold display-7 cursor-pointer pt-5"
                to={{ pathname: "/users/sign_up" }}>
                Create a New Account
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

const validateFields = ['password'];

class SelectAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      users: '',
      isRemove: false,
      isConfirmPasswordPage: false,
      user: '',
      currentStep: 1,
      showLoader: false,
      remember_me: false,
      inputType: 'password',
      formData: {
        password: '',
      }
    };
  }

  componentDidMount() {
    const user = Utility.getLocalStorage('users');
    if (user) {
      this.setState({ users: user });
    }
  }

  handleRemove = () => {
    this.setState({ isRemove: !this.state.isRemove });
  }

  handleLoggedIn = (e, user) => {
    if (user.password && !Utility.isEmpty(user.password)) {
      this.state.formData.password = Utility.testloadState(user.password);
      this.setState({ remember_me: true, formData: this.state.formData });
    }
    this.setState({ currentStep: 2, user: user });
  }

  handlePassword = (e) => {
    let formData = this.state.formData;
    formData = Utility.validateAndReturnFormData(this, formData, e, validateFields);

    this.setState({ formData });
  }

  checkLocal = (data, role) => {
    let id = null;
    if (typeof localStorage !== 'undefined') {
      let localData = Utility.getLocalStorage('users');
      if (localData.length > 0) {
        localData.map(e => {
          id = localData.find(user => user.id === data.id);
        })
        if (!id) {
          data['role'] = role;
          localData.push(data);
          Utility.setLocalStorage('key', localData);
        }
      } else {
        Utility.setLocalStorage('users', []);
        localData = Utility.getLocalStorage('users');
        data['role'] = role;
        localData.push(data);
        Utility.setLocalStorage('users', localData);
      }
    }
  }

  resetPassword = () => {
    const formData = this.state.formData;
    formData.password = '';

    this.setState({ formData });
  }

  login = (e, email) => {
    e.preventDefault();

    if (!Utility.showFormErrors(this, validateFields)) return;
    this.setState({ showLoader: true });

    const formData = { ...this.state.formData };
    formData.email = email;
    formData.client_id = CLIENT_ID;

    Utility.sendRequest(LOGIN, 2, formData, async (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        this.setState({ showLoader: false });
        this.resetPassword();
        toast.error(body.error.message, { containerId: 'public' });
      } else {
        cookie.save('sessionToken', body.token, { path: '/' });

        Utility.sendRequest(USER_AUTH, 1, body.token, (error, response, body) => {
          body = JSON.parse(body);
          if (!body.error) {
            this.props.updateUserData(body);
            this.props.history.push('/home');
            this.checkLocal(body, body.role);
          } else {
            toast.error(body.error.message, { containerId: 'public' });
          }
        });
      }
    });
  }

  onLogoClick = () => {
    this.props.history.push('/');
  }

  goLogin = () => {
    this.setState({ currentStep: 1 });
  }
  toggleShowPassword = () => {
    this.state.inputType === "text"
      ? this.setState({ inputType: "password" })
      : this.setState({ inputType: "text" });
  }


  removeAccount = (id) => {
    const localData = Utility.getLocalStorage('users');
    const removedId = localData.findIndex(x => x.id == id);
    localData.splice(removedId, 1);
    Utility.setLocalStorage('users', localData);
    this.setState({ users: localData }, () => {
      if (this.state.users < 1) {
        window.location.reload();
      }
    });
  }

  handleRememberMe = (e, user) => {
    let encryptedPassword = '';
    const users = Utility.getLocalStorage('users');
    if (this.state.formData.password) {
      encryptedPassword = Utility.testSaveState(this.state.formData.password);
    }

    if (e.target.checked === true) {
      this.setState({ remember_me: true });
      users.map(items => {
        if (items.id === user.id) {
          items.password = encryptedPassword;
        }
      });
      localStorage.setItem('users', JSON.stringify(users));

    }
    else {
      this.setState({ remember_me: false });
      users.map(items => {
        if (items.id === user.id) {
          items.password = '';
        }
      });
      localStorage.setItem('users', JSON.stringify(users));
    }
  }


  cards = () => {
    const { users } = this.state;

    return users.map(user =>
      <div key={user.id} className="card border-bottom border-top-0 border-right-0 border-left-0 ">
        <Row onClick={(e) => (!this.state.isRemove) && this.handleLoggedIn(e, user)}
          className={(!this.state.isRemove) && 'cursor-pointer'}>
          <Col md={2}>
            <div className="account-info-pic p-2" >
              {!Utility.isEmpty(user.profile_image_url) ?
                <Image className="profile-width rounded-circle" src={user.profile_image_url} alt="Profile Picture" />
                :
                <Avatar
                  name={user.user_name}
                  round={true}
                  size={"50"}
                  textSizeRatio={1.75}
                  color="#007bb3"
                  maxInitials={1}
                  />
             }
            </div>
          </Col>
          <Col md={8}>
            <div className="card-body float-left p-1">
              <div className="text-left" >
                <h5 className="font-bold" >{user.user_name}</h5>
                {ROLES[user.role]}
              </div>
            </div>
          </Col>
          <Col md={2}>
            {(!this.state.isRemove) ? <img src={arrowLeft} className="p-4 cursor-pointer" alt="profile image" /> :
              <div className="p-4 cursor-pointer"><Close onClick={() => this.removeAccount(user.id)} /></div>
            }
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { users } = this.state;
    return (
      <>
        <React.Fragment>
          {this.state.currentStep === 1 &&
            <div className="container">
              <div className="register-outer p-sm-5 text-center p-4 mx-auto my-sm-5 my-3">
                <img src={require("assets/images/logos/main-logo.png")} className="register-logo mb-4 cursor-pointer" alt="sharemeister" onClick={this.onLogoClick} />
                <h3 className="mb-5 font-bold" >Select an Account</h3>
                <div className="new-landing-theme register-form" >
                  {users && this.cards()}
                  <div className="card border-bottom border-top-0 border-right-0 border-left-0">
                    <Row>
                      <Col md={2}>
                        <div className="account-info-pic p-2" >
                          <FiUserPlus size="2em" />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="card-body float-left p-1 cursor-pointer" onClick={() => this.props.signIn()}>
                          <div className="text-left" >
                            <div className="font-bold py-3 add-remove-text" >Add an Existing Account</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <img src={arrowLeft} className="rounded-circle p-4 cursor-pointer" onClick={() => this.props.signIn()} alt="profile image"></img>
                      </Col>
                    </Row>
                  </div>
                  <div className="card border-bottom border-top-0 border-right-0 border-left-0">
                    <Row>
                      <Col md={2}>
                        <div className="account-info-pic p-2" >
                          <FiUserX size="2em" />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className={this.state.isRemove ? " card-body d-flex justify-content-center text-primary p-1" : "card-body float-left p-1"}>
                          <div className="text-left" >
                            <div className="font-bold cursor-pointer add-remove-text py-3" onClick={() => this.handleRemove()} > {(this.state.isRemove) ? "Done" : "Remove an Account"}</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        {/*<img src={arrowLeft} className="rounded-circle p-4" alt="profile image"></img>*/}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="d-inline-block mx-auto text-primary font-bold display-7 cursor-pointer pt-5" onClick={() => this.props.history.push('/users/sign_up')} >Create a New Account</div>
              </div>
            </div>
          }

          {this.state.currentStep === 2 &&
            <ConfirmCredientials
              user={this.state.user}
              handlePassword={this.handlePassword}
              password={this.state.formData.password}
              inputType={this.state.inputType}
              remember_me={this.state.remember_me}
              login={this.login}
              handleRememberMe={this.handleRememberMe}
              toggleShowPassword={this.toggleShowPassword}
              Ref={el => this.password = el}
              showLoader={this.state.showLoader}
              goLogin={this.goLogin}
            />
          }
        </React.Fragment>
      </>
    );
  }
}

const mapActionToProps = { updateUserData, showLoader };
const mapStateToProps = (state) => ({
  userData: state.userData
});
export default connect(mapStateToProps, mapActionToProps)(withRouter(SelectAccount));
