import React from 'react';

const DropdownInside = (props) => {
  return(
    <>
      <div
        className="border-top existing-article-outer d-flex py-3" onClick={props.handleClick} >
        <div className="existing-article-img-outer">
          <img src={props.imageUrl || require("assets/images/users/user_no_image.png")} className="existing-article-img"  />
        </div>
        <div className="existing-article-body ml-3" >
          <div className="existing-article-heading font-bold font-15 text-break" >
            {props.userName}
          </div>
          <div className="font-14" >
            {props.city ? props.city : ''}
            {props.city && props.state ? ', ' : ''}
            {props.state ? props.state : ''}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropdownInside;
