import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { Utility, String } from "utils";
import {
  CAMPAIGNS_COUNT,
  CAMPAIGNS,
  MODULE_TYPES,
  CAMPAIGN_STORIES,
  CATEGORIES,
  UPDATE_CAMPAIGNS_RANKING,
} from "config/constants/api_endpoints";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  showLoader,
  updateSelectedMenuItem,
  updateCampaignSelectedTab,
  updateSelectedGroup,
} from "shared/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Menu from "shared/components/menu";
import FormContainer from "./components/form_container";
import ItemDetail from "shared/components/templates/item_detail/item_detail";
import TableList from "shared/components/templates/table_list";
import MomentUtils from "@date-io/moment";
import { CLIENT_ID, CLIENT_NAME } from "config/constants/config";
import {
  CAMPAIGN_TYPES_IDS,
  CRM_MODULE_IDS,
  SM_CLIENT_ID,
  SEARCH_MINIMUM_LENGTH,
  ETHOS_CLIENT_ID,
  GIL_CLIENT_ID,
} from "config/constants";
import { WelcomeOverlays } from "shared/components/welcome_overlays";
import CreateCampaign from "../../scenes/users/components/create_campaign";
import { CampaignCategory } from "./components/campaign_category";
import { MenuSummary } from "shared/components/templates/menu_summary";
import CourseContainer from "./courses";
import { AHURA_CLIENT_ID } from "../../config/constants";

class CampaignContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    props.showLoader(Utility.isEmpty(this.props.userData));
    this.getCampaignsTypes();

    this.state = {
      selectedActionItem: {},
      menus: this.getMenu(),
      campId: null,
      campaignTypes: [],
      selectedMenuItemName: null,
      feeds: [],
      showFilterComponent: true,
      isEdit: false,
      createForm: false,
      campaignCategoryList: [],
      selectedMenuItemId: null,
      createEditCourse: false,
      openCatgoryForm: false,
      selectedViewData: {},
    };

    this.props.updateSelectedMenuItem({});
  }

  componentDidMount = () => {
    this._isMounted = true;

    if (!Utility.isEmpty(this.props.userData)) {
      this.getMenuCounts();
      // this.filterMenus();
    }
  };

  componentDidUpdate = (prevProps) => {
    Utility.showComponent(true, "menu-list");

    if (
      Utility.isEmpty(prevProps.userData) &&
      !Utility.isEmpty(this.props.userData)
    ) {
      this.getMenuCounts();
      this.setState({ menus: this.getMenu() });
      // this.filterMenus();
    }
  };

  getMenu = () => {
    this.getCampaignsTypes();
  };

  componentWillUnmount = () => (this._isMounted = false);

  getCampaignsTypes = () => {
    const { platform_id } = this.props.userData;
    const params = {
      module_id: CRM_MODULE_IDS.CAMPAIGN,
      order: "created_at",
      client_id: CLIENT_ID,
    };

    this.props.showLoader(true);

    Utility.sendRequest(MODULE_TYPES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      this.props.showLoader(false);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        let moduleData;
        if (platform_id === GIL_CLIENT_ID) {
          moduleData = body.data;
        } else if (platform_id === AHURA_CLIENT_ID) {
          moduleData = body.data.filter((item) => {
            return (
              item.name != "initiative" &&
              item.name != "Post" &&
              item.name != "Story" &&
              item.name != "event"
            );
          });
        } else if (platform_id === ETHOS_CLIENT_ID) {
          moduleData = body.data;
          moduleData = body.data.filter((item) => {
            return item.name != "initiative" && item.name != "Post";
            //   return (item.name != 'initiative' && item.name != 'Post' && item.name != 'courses');
          });
        } else {
          moduleData = body.data.filter((item) => {
            return item.name != "courses";
          });
        }
        const menus = moduleData.filter((item) => {
          return item.id !== "";
        });
        moduleData.unshift({ id: "", name: "Create" });
        const dropDownData = moduleData;
        const index = dropDownData.findIndex((x) => x.name === "courses");
        if (index > 0) {
          dropDownData[index].name = "course";
        }
        this.setState(
          {
            menus: menus,
            campaignTypes: dropDownData,
            selectedActionItem: menus[0],
          },
          () => {
            this.setState(
              {
                selectedMenuItemName: menus[0].name,
                selectedMenuItemId: menus[0].id,
              },
              () => {
                this.props.updateSelectedMenuItem(menus[0]);
                this.loadStories(menus[0].id);
              }
            );
          }
        );
      }
    });
  };

  filterMenus = () => {
    let menus = this.state.menus;

    if (!this.props.userData.Client.is_global_media_partner) {
      menus = menus.filter((item) => {
        return item.id !== 3;
      });
      this.setState({ menus });
    }
  };

  closePreviousForm = () => {
    const elem = document.getElementsByClassName("btn-close btn btn-link");
    if (elem && elem.length > 0) elem[0].click();
  };

  handleChange = async (actionItem) => {
    await this.props.updateCampaignSelectedTab(actionItem.id);
    if (actionItem.name === "course") {
      this.closePreviousForm();
      this.setState({
        createEditCourse: true,
        selectedActionItem: actionItem,
        openCatgoryForm: false,
        createForm: false,
      });
    } else {
      this.setState(
        { selectedActionItem: actionItem, campId: null, createForm: true },
        () => {
          Utility.showComponent(
            true,
            `create-${actionItem.name.toLowerCase()}`
          );
        }
      );
    }
  };

  updateMenus = (menuId, count) => {
    const menus = this.state.menus;
    if (menus && menus.length > 0) {
      menus.forEach((menu) => {
        if (menu.id === menuId) menu.count = count;
      });
    }
    if (this._isMounted) this.setState({ menus });
  };

  getMenuCounts = (campaignTypeId = CAMPAIGN_TYPES_IDS.story) => {
    // if (this.props.userData.Client.is_global_media_partner)
    this.unApprovedCampaignsCount(campaignTypeId);
    // this.myCampaignsCount();
  };

  myCampaignsCount = (campaignTypeId = CAMPAIGN_TYPES_IDS.story) => {
    const params = {
      campaign_type_id: campaignTypeId,
      client_id: CLIENT_ID,
      user_client_id: this.props.userData.id,
    };

    Utility.sendRequest(CAMPAIGNS_COUNT, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.updateMenus(4, body.count);
      }
    });
  };

  unApprovedCampaignsCount = (campaignTypeId) => {
    const params = {
      campaign_type_id: campaignTypeId,
      is_approved: false,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGNS_COUNT, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.updateMenus(3, body.count);
      }
    });
  };

  handleMenuItemClick = async (selectedMenuItem) => {
    const { selectedMenuItemId } = this.state;
    if (selectedMenuItemId !== selectedMenuItem.id) {
      await this.props.updateCampaignSelectedTab(selectedMenuItem.id);
      this.props.updateSelectedMenuItem(selectedMenuItem);
      this.props.updateSelectedGroup(selectedMenuItem);
      this.setState(
        {
          selectedActionItem: {},
          selectedMenuItemName: selectedMenuItem.name,
          createEditCourse: false,
          createForm: false,
          categorySection: false,
          selectedMenuItemId: selectedMenuItem.id,
        },
        () => {
          this.loadStories(selectedMenuItem.id);
        }
      );
    } else {
      if (selectedMenuItemId === CAMPAIGN_TYPES_IDS.courses) {
        this.setState({ createEditCourse: false });
      }
    }
  };

  categoryClick = async (selectedCategoryItem) => {
    const { selectedMenuItemId } = this.state;
    this.loadStories(selectedMenuItemId, selectedCategoryItem.id);
  };

  loadStories = (campTypeId, categoryId = "", search = "") => {
    this.props.showLoader(true);
    this.setState(
      {
        campaignCategoryList: [],
        //categorySection: false
      },
      () => {
        const params = {
          publisher_network_id: CLIENT_ID,
          type: campTypeId,
          category_id: categoryId,
          search: search,
        };
        this.getCategoryList();
        Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
            this.props.showLoader(false);
          } else {
            this.setState({
              feeds: Utility.deepClone(body.data),
              isLoading: false,
            });
            this.props.showLoader(false);
          }
        });
      }
    );
  };

  closeSummary = () => {
    this.props.updateSelectedMenuItem({});
    this.setState({
      selectedActionItem: {},
      isEdit: false,
      campId: "",
      createEditCourse: false,
    });
  };

  editStory = (campId, campTypeId) => {
    const selectedActionItem = this.state.campaignTypes.find(
      (item) => item.id === campTypeId
    );
    if (selectedActionItem.name === "course") {
      this.props.updateCampaignSelectedTab(selectedActionItem.id);
      this.setState({
        selectedActionItem: selectedActionItem,
        campId: campId,
        createEditCourse: true,
      });
    } else {
      this.setState(
        {
          selectedActionItem: selectedActionItem,
          campId: campId,
          isEdit: true,
          createForm: true,
        },
        () => {
          // Utility.showComponent(true, 'create-' + this.state.selectedActionItem.name.toLowerCase());
        }
      );
    }
  };

  editRanking = (id, ranking) => {
    const params = {
      campaign_id: id,
      ranking: parseInt(ranking),
    };

    this.props.showLoader(true);
    Utility.sendRequest(
      UPDATE_CAMPAIGNS_RANKING,
      3,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);
        if (!body.error) {
          // if (body.message === "You don't have access to perform this activity"){
          //     toast.error(body.message, { containerId: "public" });
          //   return;
          // }
          this.loadStories(this.props.campaignSelectedTab);
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  closeForm = () => {
    this.setState({
      isEdit: false,
      campId: "",
      createEditCourse: false,
    });
  };

  backToPreviousMenu = (previousMenuItem) => {
    this.props.updateSelectedMenuItem(previousMenuItem);
    this.setState({ selectedActionItem: {} });
  };

  getTableTitlesForCampaigns = () => {
    if (
      this.state.selectedMenuItemId ===
        "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf" &&
      this.state.selectedMenuItemName === "event" &&
      CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5"
    )
      return ["Name", "Ranking", "Category", "Action"];
    else if (
      this.state.selectedMenuItemId ===
        "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec" &&
      CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5" &&
      this.state.selectedMenuItemName === "Story"
    )
      return ["Name", "Category", "Ranking"];
    else return ["Name", "Category", "Action"];
  };

  filterList = (event) => {
    if (event.target.value.length === 1)
      this.setState({ isSearchEmpty: false });

    if (
      event.target.value.length >= SEARCH_MINIMUM_LENGTH ||
      (event.target.value.length === 0 && !this.state.isSearchEmpty)
    ) {
      this.loadStories(
        this.state.selectedMenuItemId,
        "",
        event.target.value.trim()
      );

      if (event.target.value.length === 0) {
        this.setState({ isSearchEmpty: true });
        this.loadStories(this.state.selectedMenuItemId, "", "");
      }
    }
  };

  openCategory = () => {
    this.setState(
      (state) => {
        return {
          categorySection: !state.categorySection,
          createEditCourse: false,
        };
      },
      () => {
        this.getCategoryList();
      }
    );
  };

  getCategoryList = () => {
    const params = {
      client_id: CLIENT_ID,
      campaign_type_id: this.state.selectedMenuItemId,
      is_active: true,
    };

    Utility.sendRequest(CATEGORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.setState({ campaignCategoryList: body.data });
      }
    });
  };

  catgoryForm = () => {
    this.setState(
      (state) => {
        return {
          openCatgoryForm: !state.openCatgoryForm,
          createEditCourse: false,
        };
      },
      () => {
        Utility.showComponent(true, "menu-summary");
      }
    );
  };

  removeCampaign = (id) => {
    Utility.sendRequest(CAMPAIGNS + "/" + id, 4, {}, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.loadStories(this.props.campaignSelectedTab);
      }
    });
  };

  render() {
    const a = { id: 3, name: "Campaigns", image_url: "campaigns.png" };
    const { is_super_owner, platform_id } = this.props.userData;
    const selectedActionItem = { ...this.state.selectedActionItem };
    const {
      campId,
      isEdit,
      createForm,
      createEditCourse,
      selectedMenuItemName,
    } = this.state;
    if (!Utility.isEmpty(selectedActionItem)) {
      selectedActionItem.name = selectedActionItem.name.toLowerCase();
    }
    if (is_super_owner || platform_id !== SM_CLIENT_ID) {
      return (
        <>
          <Row className="no-gutters h-100">
            {!Utility.isEmpty(this.props.userData) &&
              !Utility.isEmpty(this.state.menus) && (
                <Menu
                  selectedModule={a}
                  clientName={CLIENT_NAME}
                  userName={`${this.props.userData.first_name} ${this.props.userData.last_name}`}
                  dropdownItems={this.state.campaignTypes}
                  handleMenuItemClick={this.handleMenuItemClick}
                  handleDropdownAction={this.handleChange}
                  selectedMenuItemId={
                    this.props.selectedMenuItem.id ||
                    (Utility.isEmpty(selectedActionItem) ? 1 : null)
                  }
                  menus={this.state.menus}
                  isMenuAction={true}
                  showZeroInCount={false}
                  showCount={false}
                  isShowButton={true}
                  categoryButton={true}
                  showCategoryList={true}
                  openCategory={this.openCategory}
                  campaignCategoryList={this.state.campaignCategoryList}
                  categorySection={this.state.categorySection}
                  catgoryForm={this.catgoryForm}
                  categoryClick={this.categoryClick}
                />
              )}
            {!Utility.isEmpty(selectedActionItem) &&
              createForm &&
              !createEditCourse && (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <FormContainer
                    showLoader={this.props.showLoader}
                    closeSummary={this.closeSummary.bind(this)}
                    unApprovedCampaignsCount={this.unApprovedCampaignsCount.bind(
                      this
                    )}
                    backToPreviousMenu={this.backToPreviousMenu}
                    myCampaignsCount={this.myCampaignsCount}
                    formType={selectedActionItem}
                    campId={this.state.campId}
                    sdGoals={this.props.sdGoals}
                  />
                </MuiPickersUtilsProvider>
              )}
            {this.state.menus &&
              !createEditCourse &&
              !this.state.openCatgoryForm && (
                <ItemDetail
                  showFilterComponent={this.state.showFilterComponent}
                  filterList={this.filterList}
                  headerTitle={String.capitalizeFirstLetter(
                    selectedMenuItemName
                  )}
                >
                  <TableList
                    data={this.state.feeds}
                    openChat={this.openChat}
                    onToggleClick={this.onToggleClick}
                    editStory={this.editStory}
                    editRanking={this.editRanking}
                    setAdminAfterToggle={this.state.changeAdminValue}
                    showLoader={this.state.showLoader}
                    tableTitles={this.getTableTitlesForCampaigns()}
                    userData={this.props.userData}
                    selectedMenuItemName={selectedMenuItemName}
                    selectedActionItem={selectedActionItem}
                    selectedMenuItemId={this.state.selectedMenuItemId}
                    history={this.props.history}
                    removeCampaign={this.removeCampaign}
                    selectedViewData = {this.state.selectedViewData}
                  />
                </ItemDetail>
              )}
            {!this.state.openCatgoryForm && createEditCourse && (
              <CourseContainer
                editCampaignId={campId}
                setIsFormOpen={() => this.closeForm()}
                loadStories={this.loadStories}
              />
            )}
            {this.state.openCatgoryForm && !createEditCourse && (
              <MenuSummary>
                <CampaignCategory
                  selectedGroup={this.props.selectedGroup}
                  selectedActionItem={this.props.selectedActionItem}
                  selectedCampaignType={this.props.selectedCampaignType}
                  userData={this.props.userData}
                  closeSummary={this.catgoryForm}
                  getCategoryList={this.getCategoryList}
                  categoryId={this.state.categoryId}
                  loadStories={this.loadStories}
                  columnValue={9}
                  campaignTypeId={this.state.selectedMenuItemId}
                />
              </MenuSummary>
            )}
          </Row>
          {/* { !this.state.openCatgoryForm && isEdit &&
          <CreateCampaign
            position='position-fixed'
            isFormOpen={isEdit}
            loadedFrom='CampaignModule'
            editCampaignId={campId}
            setIsFormOpen={() => this.closeForm()}
          />
        } */}
        </>
      );
    } else {
      return (
        <WelcomeOverlays
          heading="Welcome to Campaigns!"
          subHeading="Create manage and distribute various types of campaigns"
          mainText="Ask for a customized DEMO to learn how all the available features of this module can be used to serve the requirements of your digital application."
          btnText="See example!"
        />
      );
    }
  }
}

const mapActionToProps = {
  showLoader,
  updateSelectedMenuItem,
  updateCampaignSelectedTab,
  updateSelectedGroup,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  sdGoals: state.sdGoals,
  selectedModule: state.selectedModule,
  selectedMenuItem: state.selectedMenuItem,
  campaignSelectedTab: state.campaignSelectedTab,
  selectedGroup: state.selectedGroup,
});

export default connect(mapStateToProps, mapActionToProps)(CampaignContainer);
