import * as moment from 'moment';
import { TIME_FORMAT } from 'config/constants';

export const localTime = (time) => {
	return moment.utc(time).local().format(TIME_FORMAT);
};

export const localDateWithTimeObj = (date, format) => {
  return moment(moment.utc(date).local());
};

export const localDateWithTime = (date, format) => {
  return localDateWithTimeObj(date, format).format(format);
};

export const formatDateToString = (date) => {
	const newDate = new Date(date);

	return `${dayNameByNumber(newDate.getDay())}, ${monthNameByNumber(newDate.getMonth() + 1)}
	 ${newDate.getDate()}, ${newDate.getFullYear()}`;
};

export const formatDate = (date, dateFormat) => {
	return moment(date).format(dateFormat);
};

export const monthNameByNumber = (monthNumber) => {
	const monthNames = {
		1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul',
		8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
	};

	return monthNames[monthNumber];
};

export const dayNameByNumber = (dayNumber) => {
	return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayNumber];
};

export const getDuration = (val) => {
  let start = moment.utc(val).local();
	start = start.local();
  const end = moment(moment().format('YYYY-MM-DDTHH:mm:ss.SSS'));
  let result = '';
  const duration = moment.duration(start.diff(end));
  const years = Math.abs(duration.years()) ;
  const months = Math.abs(duration.months()) ;
  const days = Math.abs(duration.days()) ;
  const hours = Math.abs(duration.hours()) ;
  const minutes = Math.abs(duration.minutes()) ;
  const seconds = Math.abs(duration.seconds()) ;

  if (years > 0) result = years === 1 ? (years + ' year ') : (years + ' years ');
  else if (months > 0) result = months === 1 ? (months + ' month ') : (months + ' months ');
  else if (days > 0) result = days === 1 ? (days + ' day ') : (days + ' days ');
  else if (hours > 0) result = hours === 1 ? (hours + ' hour ') : (hours + ' hours ');
  else if (minutes > 0) result = minutes === 1 ? (minutes + ' minute ') : (minutes + ' minutes ');
  else if (seconds > 0) result = seconds === 1 ? (seconds + ' seconds ') : (seconds + ' seconds ');

  return result;
};

export const getMonthName = (date) => {
	return date.toLocaleString('default', { month: 'long' }).toLocaleUpperCase()
}

export const getDateNumber = (date) => {
	return date.toLocaleString('default', { month: 'long' }).toLocaleUpperCase()
}

export const getLastUpdatedAtDuration = (updated_at) => {
	let diff = 0;
	let mom = moment
	if(updated_at){
		const now = moment.utc()
		const lastUpdated = moment.utc(updated_at)
		const diff = now.diff(moment.utc(lastUpdated.format('YYYY-MM-DD HH:mm:ss')), 'minutes')

		if(diff > 60)
			return `${now.diff(moment.utc(lastUpdated.format('YYYY-MM-DD HH:mm:ss')), 'hours')} hours ago`
		else
			return `${diff} minutes ago`
	}
	else
		return '';
}

export const getUtcToCST = (time, format) => {
	// let cst_date = new Date(new Date(time).setHours(new Date(time).getHours() - 6)).toUTCString();
	let cst_date = new Date(new Date(time).setHours(new Date(time).getHours())).toUTCString();
	if(format === "cstToSctring")
		return cst_date
	else if(format === "CST")
		return ((moment.utc(cst_date).format('ha')).replace('am','A').replace('pm','P'))
	else
		return ((moment.utc(cst_date).format(format)))
		
}

export const recurringMeeting = (start_date, end_date, repeat_details, is_repeated) => {
	if(start_date===undefined || end_date === undefined)
		return "";

	if(is_repeated === false || 
	   is_repeated === null || 
	   is_repeated === undefined || 
	   repeat_details === null ||
	   repeat_details===undefined ||
	   JSON.stringify(repeat_details) === JSON.stringify({})
	   ){
		start_date = new Date(start_date);
		return (start_date.toUTCString());
	}
	
	let frequency = repeat_details.frequency;
	let frequency_type = repeat_details.frequency_type;

    start_date = new Date(start_date);
    end_date = new Date(end_date);
    let current_date = new Date();
    let next_date = start_date > current_date ? new Date(start_date.getTime()) :new Date(current_date.getTime());

    if(frequency_type === "weekly"){
        let getNextDateDay = next_date.getDay();
        while(next_date <= end_date){
			getNextDateDay = next_date.getDay();
			if(frequency.includes(getNextDateDay.toString()))
				break;
			else
				next_date.setDate(next_date.getDate()+1);
        }  

        if(next_date <= end_date && frequency.includes(getNextDateDay.toString())){
            return (next_date.toUTCString());        
		}
        else{
            return (end_date.toUTCString());
		}
    }
    else if(frequency_type === "monthly"){
        while(next_date <= end_date){
            if(frequency.includes((next_date.getDate()).toString()))
                break;
            else
                next_date.setDate(next_date.getDate()+1); 
        }  
        
        if(next_date <= end_date && frequency.includes((next_date.getDate()).toString()))
            return (next_date.toUTCString());
        else
            return (end_date.toUTCString());
    }
    else{
        return (start_date.toUTCString());
	}
}