import React, { Component } from "react";
import { Dropdown, Button, Form } from "react-bootstrap";
import { ChevronDownIcon } from "shared/icons/icons";
import { Utility } from "utils";
import { CircleFilledAddLargeIcon } from "shared/icons/icons";
import { InputField } from "shared/components/form_elements";
import UploadFile from "shared/components/file_upload";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CLIENT_ID } from "config/constants/config";
import { AHURA_CLIENT_ID } from "config/constants";
import { GIL_CLIENT_ID } from "../../../config/constants";

export class CourseForm extends Component {
  constructor(props) {
    super(props);
  }

  paintImageInput = () => (
    <Form.Group className="mb-4 pb-1">
      <Form.Label className="font-bold display-8 mb-3">
        Add an Image <span className="text-red">*</span>{" "}
      </Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <label className="post-uplodad-label mb-0 cursor-pointer">
          <UploadFile
            name="image_url"
            inputId="image_url"
            removeText="Remove image"
            categoryName="publisher_campaign_image"
            acceptTypes="image/x-png,image/gif,image/jpeg"
            uploadedFilePreviewUrl={
              (this.props.formStates.isEdit &&
                this.props.newCampaign.image_url) ||
              this.props.formStates.imagePreviewUrl
            }
            handleFileChange={this.props.handleFileChange}
            removeUploadedFile={this.props.removeUploadedFile}
          />
          <div className="error errorIcon mb-3" id={"image_urlError"} />
          {!this.props.formStates.imagePreviewUrl && (
            <CircleFilledAddLargeIcon
              mainClass="add-icon-upper"
              fillClass="fill-primary"
            />
          )}
        </label>
      </div>
      <div className="my-2 ml-1 display-8 font-medium">Or</div>
      <InputField
        required={true}
        type="text"
        name="embedded_image_link"
        ref="embedded_image_link"
        value={this.props.newCampaign.embedded_image_link}
        handleChange={this.props.handleChange}
        placeholder="Embed Image URL..."
      />
    </Form.Group>
  );

  paintVideoInput = () => (
    <Form.Group className="mb-4 pb-1">
      <Form.Label className="font-bold display-8 mb-3">Add a Video</Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <label className="post-uplodad-label mb-0 cursor-pointer">
          <UploadFile
            name="video_url"
            inputId="video_url"
            removeText="Remove video"
            acceptTypes="video/mp4"
            uploadedFilePreviewUrl={this.props.formStates.videoPreviewUrl}
            handleFileChange={this.props.handleFileChange}
            removeUploadedFile={this.props.removeUploadedFile}
          />
          <div className="error errorIcon" id={"video_urlError"} />
          {!this.props.formStates.videoPreviewUrl && (
            <CircleFilledAddLargeIcon
              mainClass="add-icon-upper"
              fillClass="fill-primary"
            />
          )}
        </label>
      </div>
      <div className="my-2 ml-1 display-8 font-medium">Or</div>
      <InputField
        type="text"
        name="embedded_video_link"
        value={this.props.newCampaign.embedded_video_link}
        handleChange={this.props.handleChange}
        placeholder="Embed YouTube URL..."
      />
    </Form.Group>
  );

  render() {
    const props = this.props;
    return (
      <div className="py-5 page-sidebar-outer-article">
        <Form className="shar-form search-form search-form-pwa sidebar-form">
          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
              Select Category<span className="text-red">*</span>
            </Form.Label>
            <Dropdown className="article-dropdown course-categories-dropdown form-control py-0">
              <Dropdown.Toggle
                variant="none"
                className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100"
                id="category_id"
              >
                {!Utility.isEmpty(props.categoriesList) &&
                !Utility.isEmpty(props.newCampaign.category_id)
                  ? props.categoriesList.map((e) => {
                      return e.id === props.newCampaign.category_id
                        ? e.name
                        : "";
                    })
                  : "Select category"}
                <ChevronDownIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none">
                {props.categoriesList.map((e) => {
                  return (
                    <Dropdown.Item
                      key={e.id}
                      id="category_id"
                      name="category_id"
                      eventKey={["category_id", e.id]}
                      onSelect={props.handleDropdown}
                    >
                      {e.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <div className="error errorIcon mb-3" id={"category_idError"} />
          </Form.Group>

          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
              Add Course Name
              <span className="text-red">*</span>
            </Form.Label>
            <InputField
              required={true}
              name="heading"
              ref="heading"
              type="text"
              placeholder="Create a new Course"
              value={props.newCampaign.heading}
              handleChange={props.handleChange}
            />
          </Form.Group>

          {this.paintImageInput()}

          {this.paintVideoInput()}

          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
              Enter Course Description{" "}
              <span className="font-light-italic">(Details)</span>
            </Form.Label>
            {/* <RichTextEditor
                  id="description"
                  name="description"
                  placeholder="Add Text..."
                  data={props.newCampaign.description}
                  changeDescription={props.changeDescription}
                /> */}
            <ReactQuill
              id="description"
              name="description"
              theme="snow"
              value={this.props.newCampaign.description}
              onChange={props.changeDescription}
              style={{ minHeight: "300px" }}
            />
          </Form.Group>

          {/* <Form.Group className="mb-4 pb-1">
              <Form.Label className="font-bold display-8 mb-3">
                Add Course Tags
              </Form.Label>
              <InputField
                required={true}
                type="text"
                placeholder="Add Tag,separated by commas..."
              />
            </Form.Group> */}
          {CLIENT_ID === GIL_CLIENT_ID && (
            <Form.Group className="mb-4 d-flex mt-3 pb-1" >
              <Form.Check value="ranking" checked={props.newCampaign.ranking===5}  onChange={(e) => props.handleIsFeatured(e.target.checked)} type="checkbox" id="ranking" name="ranking" />
              <Form.Label className="font-bold display-8 mb-3">Do you want this course to be in Featured list?</Form.Label>
            </Form.Group>
          )}
          {CLIENT_ID === AHURA_CLIENT_ID && (
            <Form.Group className="mb-4 pb-1">
              <Form.Label className="font-bold display-8 mb-3">Type</Form.Label>
              <div key={`inline-radio`} className="mb-3 row m-0">
                <Form.Check
                  id="public"
                  value="public"
                  type="radio"
                  aria-label="Public"
                  label="Public"
                  onChange={() => props.handlePublicPrivate(true)}
                  checked={props.newCampaign.is_public === true}
                />
                <Form.Check
                  id="private"
                  className={"ml-3"}
                  value="private"
                  type="radio"
                  aria-label="Private"
                  label="Private"
                  onChange={() => props.handlePublicPrivate(false)}
                  checked={props.newCampaign.is_public === false}
                />
              </div>
            </Form.Group>
          )}

          <Button
            type="submit"
            variant="primary"
            onClick={props.submit}
            className={`btn btn-primary btn-primary-pwa btn-post-size font-18 my-4 ml-1 font-weight-bold`}
          >
            Save &amp; Close
          </Button>
          <div className="">
            <div
              className="display-8 font-medium-italic cursor-pointer btn btn-post-size"
              onClick={() => props.setIsFormOpen(false)}
            >
              Cancel
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
