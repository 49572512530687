import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import { Utility } from 'utils';
import { CAMPAIGNS_TOP_STORIES } from 'config/constants/api_endpoints';
import { CLIENT_ID, COMMUNITY_CARD_LIMIT } from 'config/constants/config';
import { SM_CLIENT_ID, ROLE_IDS, GIL_CLIENT_ID } from 'config/constants';
import { updateOpenCityPurchase } from "shared/actions";

function WelcomeSection(props){
  const userData = props.userData;
  const [welcomeTopStories, setWelcomeTopStories] = useState([]);
  const pwaModeEnabled = Utility.pwaModeEnabled();

  useEffect(() => {
    getCampaignsTopStoriesDetails();
  }, [userData]);

  const getCampaignsTopStoriesDetails = () => {
    const params = {
      publisher_network_id: CLIENT_ID,
      range: COMMUNITY_CARD_LIMIT,
      forWelcomeCarousel: true
    };
    props.showLoader(true);
    if(!Utility.isEmpty(props.userData)){
      Utility.sendRequest(CAMPAIGNS_TOP_STORIES, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        props.showLoader(false);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          if(body.length)
            setWelcomeTopStories(body);
        }
      });
    }
    else{
      props.showLoader(false);
    }
  };

  return(
    <>
    { userData.platform_id === SM_CLIENT_ID ?
    <>
      <div className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-between align-items-center mb-4">
          <div className="font-36 b-700 my-4 text-center text-md-left">Welcome to Sharemeister</div>
          <div>
           {!Utility.isEmpty(props.userData) &&
            ROLE_IDS.USER === props.userData.role_id &&
            <button
            className="btn btn-theme btn-alternate px-4 py-3 flex-nowrap space-nowrap d-flex align-items-center"
            onClick= {() => props.updateOpenCityPurchase(true)}
            >
            <img
              src={require('assets/images/logos/s-icon-sharp.svg')}
              alt="drodown-icon"
              className="mr-3 btn-icon-inside" />
            Get Started
          </button>
           }
          </div>
        </div>
      <div className="welcome-card-section" >
      <Carousel data={welcomeTopStories} userData={userData}/>
    </div>
    </> :

    <div className="welcome-card-section" >
    {/* (!pwaModeEnabled && !props.isFormOpen) &&
      <button
        onClick={() => props.setIsFormOpen(true)}
        className="btn btn-primary btn-primary-pwa client-profile-btn-create web-create-button" >
        CREATE
      </button>
    */}
    {!Utility.pwaModeEnabled() &&
    <h1 className="font-24-bold ml-3 mb-25 welcome-heading text-capitalize" >
      Welcome, {userData && (userData.user_name && userData.user_name.split(" ")[0])}!
    </h1>
    }
  {CLIENT_ID !== GIL_CLIENT_ID && <Carousel data={welcomeTopStories} userData={userData}/>}
    {CLIENT_ID === GIL_CLIENT_ID && <Carousel data={props.feeds.slice(props.feeds.length - 1, props.feeds.length)} userData={userData}/>}
    </div>
    }
    </>
  );
}


const Carousel = (props) => {
  // const handleOnDragStart = (e) => e.preventDefault()

  const paintWelcomeStories = () => {
    const arr = [];
    props.data.map(e => {
      arr.push(
          <div className="welcome-card overflow-hidden d-flex w-70" key={1}>
            <Link to={`/campaign/${e.id}`}>
              <img src={e.image_url} className="welcome-card-img"  alt="" />
            </Link>
              <div className="p-lg-4 p-2 d-flex w-100" >
                <Link to={{
                  pathname: `/profile/${e.user_client_id}`,
                  state: { otherUserProfile: true },
                }}>
                  <img src={e.author_image_url} className="img-border rounded-circle welcome-card-heading-img mr-2"  alt="" />
                </Link>
              <div className="welcome-card-body ml-1 w-100 d-flex flex-column" >
                <Link to={`/campaign/${e.id}`}>
                  <div className="welcome-card-heading text-black" >
                    <span className="display-7 font-bold" >{e.heading}</span>
                    { props.userData && props.userData.platform_id === SM_CLIENT_ID &&
                      <span className="font-14 mb-2 font-bold text-primary article-category d-none show-in-sm" >{e.campaign_category}</span>
                    }
                  </div>
                  <div className="text-muted font-14 mt-2 text-black" >{moment(e.updated_at).format('ll')}</div>
                  <div className="welcome-card-text text-black"
                  >
                    {e.description.replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, "").replace(/^(.{200}[^\s]*).*/, "$1") + ' ...'}
                  </div>
                  <div className="mt-auto chip-sm ml-auto cursor-pointer" >
                    <img src={require('assets/images/icons/right_black.svg')}  alt="" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
      );
    });
    return arr;
  };

  return (
    paintWelcomeStories()
    /* <AliceCarousel
    //   items={paintWelcomeStories()}
    //   onDragStart={handleOnDragStart}
    //   fadeOutAnimation
    //   mouseTrackingEnabled
    //   buttonsDisabled
    //   dotsDisabled
    //   playButtonEnabled={false}
    //   disableAutoPlayOnAction
    //   >
    // </AliceCarousel>*/
  );
};

const mapActionToProps = {
  updateOpenCityPurchase
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  openCityPurchase: state.openCityPurchase
});

export default connect(mapStateToProps, mapActionToProps)(WelcomeSection);
