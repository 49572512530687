import React from "react";
import { toast } from "react-toastify";
import { MESSAGES } from "config/constants/api_endpoints";
import SideCallout from "shared/components/templates/side_callout";
import { Utility } from "utils";

const GetStarted = (props) => {
  const sendMessage = (message, friend) => {
    if (!message || message.trim() === "") return;

    const senderPrivateKey = props.userData.private_key;
    const receiverPrivateKey = friend.private_key;

    const encryptedMessage = Utility.encryptedMessage(
      message,
      senderPrivateKey,
      receiverPrivateKey
    );

    const params = {
      from_user_client_id: props.userData.id,
      to_user_client_id: friend.id,
      new_message: props.chatPrivateKey === "",
      media: "",
      message: encryptedMessage,
      user_id: props.userData.user_id,
      source: friend.isAudience ? "audience" : "network",
      chat_private_key: senderPrivateKey,
    };

    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        body.message = message;
        props.updateIsChatFormOpen(true);
      }
    });
  };

  const handleOptionClick = (message) => {
    if (!Utility.isEmpty(props.supportUsers)) {
      props.updateChatFriend(props.supportUsers[0]);
      sendMessage(message, props.supportUsers[0]);
    }
  };

  return (
    <SideCallout
      closeCallOut={props.handleClose}
      position="position-fixed create-campaign-margin"
      calloutHeading="Get Started"
      calloutHeadingImg={process.env.PUBLIC_URL + '/toast_icon/city_logo.png'}
    >
      <div className="font-18">
        <div className="bg-primary px-4 py-5 side-callout-screen-inner text-center mb-4">
          <h3 className="text-white font-18">Welcome to</h3>
          <img
            src={require("assets/images/logos/main-logo-white.png")}
            className="shar-inner-logo mt-2"
          />
        </div>
        <div className="font-weight-bold">
          How would you like to get started?
        </div>
        <div
          className="d-flex align-items-center p-3 side-data-inner mt-4 flex-nowrap"
          onClick={() => {
            props.handleClose();
            props.showKeyCallout();
          }}
        >
          <div className="icon-side-callout-inner">
            <img
              src={require("assets/images/profile/new-profile/toolbox-active.svg")}
              className="icon-key"
              alt=""
            />
          </div>
          <div>
            I am ready to purchase a <b>Key</b> for my City Account to integrate
            the software service.
          </div>
        </div>
        <div className="d-flex align-items-center p-3 side-data-inner mt-4 flex-nowrap">
          <div className="icon-side-callout-inner">
            <img
              src={require("assets/images/profile/new-profile/target.svg")}
              alt=""
            />
          </div>
          <div
            onClick={() =>
              handleOptionClick(
                "I want to schedule a Discovery meeting to learn more about how to take ownership of my own digital community experience"
              )
            }
          >
            I want to schedule a <b>Discovery</b> meeting to learn more about
            how to take ownership of my own digital community experience.
          </div>
        </div>
        <div className="d-flex align-items-center p-3 side-data-inner mt-4 flex-nowrap">
          <div className="icon-side-callout-inner">
            <img
              src={require("assets/images/profile/new-profile/mobile.svg")}
              className="icon-mob"
              alt=""
            />
          </div>
          <div
            onClick={() =>
              handleOptionClick("I want to create a Demo of my idea to scale.")
            }
          >
            I want to create a <b>Demo</b> of my idea to scale.
          </div>
        </div>
        <div className="d-flex align-items-center p-3 side-data-inner mt-4 flex-nowrap">
          <div className="icon-side-callout-inner">
            <img
              src={require("assets/images/profile/new-profile/pen.svg")}
              alt=""
            />
          </div>
          <div
            onClick={() =>
              handleOptionClick(
                "I want to Design a landing page, home page or mobile application."
              )
            }
          >
            I want to <b>Design</b> a landing page, home page or mobile
            application.{" "}
          </div>
        </div>
        <div className="d-flex align-items-center p-3 side-data-inner mt-4 flex-nowrap">
          <div className="icon-side-callout-inner">
            <img
              src={require("assets/images/profile/new-profile/code.svg")}
              alt=""
            />
          </div>
          <div
            onClick={() =>
              handleOptionClick(
                "I am in need of Development services to manage my digital asset."
              )
            }
          >
            I am in need of <b>Development</b> services to manage my digital
            asset.
          </div>
        </div>
        <div className="d-flex align-items-center p-3 side-data-inner mt-4 flex-nowrap">
          <div className="icon-side-callout-inner">
            <img
              src={require("assets/images/profile/new-profile/code-1.svg")}
              className="icon-code"
              alt=""
            />
          </div>
          <div
            onClick={() =>
              handleOptionClick(
                "I am ready to Deploy my digital asset and need technical support."
              )
            }
          >
            I am ready to <b>Deploy</b> my digital asset and need technical
            support.
          </div>
        </div>
        <div className="d-flex align-items-center p-3 side-data-inner mt-4 flex-nowrap">
          <div className="icon-side-callout-inner">
            <img
              src={require("assets/images/profile/new-profile/expand.svg")}
              alt=""
            />
          </div>
          <div
            onClick={() =>
              handleOptionClick(
                "I am ready to Distribute my digital assets from my digital community applications with the marketplace"
              )
            }
          >
            I am ready to <b>Distribute</b> my digital assets from my digital
            community applications with the marketplace.
          </div>
        </div>
        <button
          className="btn btn-theme btn-primary btn-get-started d-flex align-items-center mt-5 position-relative mx-auto justify-content-center mb-4"
          onClick={() => {
            props.handleClose();
            props.showKeyCallout();
          }}
        >
          <img
            src={require("assets/images/profile/new-profile/toolbox-white.svg")}
            alt="drodown-icon"
            className="mr-2 btn-icon-inside feed-profile"
          />
          Purchase Key
        </button>
      </div>
    </SideCallout>
  );
};

export default GetStarted;
