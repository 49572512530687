import React, { Component } from 'react';
import { MdClose } from 'react-icons/md';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { MdStar, MdStarHalf, MdStarBorder } from 'react-icons/md';
import { Utility } from 'utils';

export default class MarketPlace extends Component {

  closeDescription = () => {
    Utility.showComponent(false, 'tool-description');
  }

  render () {
    return (
      <div className="side-modal-outer col-xl-8 offset-xl-4 col-lg-7 offset-lg-5 col-sm-10 offset-sm-2 position-fixed px-0 d-none h-100" id="tool-description" >
        <div className="side-modal-inner bg-white p-sm-4 p-3 h-100 ml-sm-1">
          <div className="side-modal-header align-items-center justify-content-between">
            <h5 className="font-bold mb-0">Asset Tool
            <div className="shar-product-ratings display-7">
              <MdStar />
              <MdStar />
              <MdStar />
              <MdStarHalf />
              <MdStarBorder />
            </div>
            </h5>
            <Button variant="link" className="close-btn-position side-modal-close" onClick={this.closeDescription} ><MdClose/></Button>
          </div>
          <Row className="no-gutters mt-2 tool-description-row">
            <Col lg={5}>
              <div className="tool-img-container">
                <Image src={require('assets/images/tools/market_place/marketplace_bg_1.png')} alt="tool image" />
              </div>
              <div className="tool-description mt-2">
                <div className="display-8 mt-3 font-bold">Tool Description:</div>
                <p className="display-9 mt-2">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum .</p>
              </div>
              <div className="tool-rating mt-5">
                <div className="display-9 font-bold">Rate this tool</div>
                <div className="display-7">
                  <MdStarBorder />
                  <MdStarBorder />
                  <MdStarBorder />
                  <MdStarBorder />
                  <MdStarBorder />
                </div>
              </div>
              <div className="tool-btn-container mt-sm-0 mt-3">
                <Button variant="primary-outlined" className="btn-lg">Add</Button>
              </div>
            </Col>
            {/*  <Col lg={7} className="px-3 px-xl-4">
              <h2>Comments</h2>
              <div className="border-bottom-dotted"></div>
              <div className="members-list">
                <ul>
                  <li className="mt-4">
                    <a href="#" className="text-networks">
                      <div className="d-flex member pl-1">
                        <div className="fix-width">
                          <div className="round-circle-medium bg-grey" />
                        </div>
                        <div className="networks-name w-100 text-truncate">
                          <div className="pl-3">
                            <span className="font-20-bold">General</span>
                            <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                          </div>
                          <div className="pl-3">
                            <div className="font-14 text-truncate"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  standard dummy text ever since the 1500s, when ... </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="mt-4">
                    <a href="#" className="text-networks">
                      <div className="d-flex member pl-1">
                        <div className="fix-width">
                          <div className="round-circle-medium bg-grey" />
                        </div>
                        <div className="networks-name w-100 text-truncate">
                          <div className="pl-3">
                            <span className="font-20-bold">General</span>
                            <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                          </div>
                          <div className="pl-3">
                            <div className="font-14 text-truncate"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  standard dummy text ever since the 1500s, when ... </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="mt-4">
                    <a href="#" className="text-networks">
                      <div className="d-flex member pl-1">
                        <div className="fix-width">
                          <div className="round-circle-medium bg-grey" />
                        </div>
                        <div className="networks-name w-100 text-truncate">
                          <div className="pl-3">
                            <span className="font-20-bold">General</span>
                            <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                          </div>
                          <div className="pl-3">
                            <div className="font-14 text-truncate"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  standard dummy text ever since the 1500s, when ... </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="chat-details tool-chat">
                <Form className="input-msg-box shar-form">
                  <InputGroup>
                    <Textarea required={true} type="text" name="text_message" ref="text_message"
                      placeholder="Type something here…"
                      autoresize={"true"}
                      className="scroll-y"
                    />
                    <div className="send-icons-position">
                      <Button variant="link">
                        <span><img className="width-20"  src={require("assets/images/send_icon.svg")} alt="send-icon"/></span>
                      </Button>
                    </div>
                  </InputGroup >
                </Form>
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
    );
  }
}
