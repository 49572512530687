import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Utility } from 'utils';
import { SidebarMenuItem } from './sidebar_menu_item';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import { crmData } from './crmJson';
import cookie from 'react-cookies';
import { PERMISSIONS, ROLE_IDS, SM_CLIENT_ID } from 'config/constants';
import { CLIENT_ID, GIL_PARENT_OWNER_ID } from 'config/constants/config';
import { LOGOUT, USERS }  from 'config/constants/api_endpoints';
import { updateIsChatFormOpen, updateChatFriend } from 'shared/actions';

class SideBar extends Component {
  constructor(props){
    super(props);

    this.state = {
      selectedMenuItemId: 0,
      crmModules: [],
      activeMenuName: '',
      pwaEnable: false,
      isUserLogedIn: cookie.load('sessionToken') !== undefined ? true : false,
    };
  }

  componentDidMount = () => {
    this.filterModules();
    this.pwaSideBar();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const activeMenuName = this.props.history.location.pathname.split('/')[1];
    if (prevProps.userData !== this.props.userData || prevState.activeMenuName !== this.state.activeMenuName) {
      this.filterModules();
    }
    if (this.state.activeMenuName !== activeMenuName) {
          this.setState({ activeMenuName });
    }

    if(prevProps.isChatFormOpen !== this.props.isChatFormOpen && prevProps.chatFriend && prevProps.chatFriend.fromMenu) {
      if(!this.props.isChatFormOpen) {
        this.goToHome();
      }
    }
  }

  pwaSideBar = () => {
    const pwaEnable = Utility.pwaModeEnabled();

    this.setState({ pwaEnable });
  }

  action = (check) => {
    if (check === "Login") {
      this.props.history.push(this.state.pwaEnable ? '/sign_in' : '/users/sign_in');
      return;
    }
    this.logOut();
    Utility.clearSessionAndRedirectToHomePage();
  }

  logOut = () => {
    const params = {
      user_client_id: this.props.userData.id
    };

    Utility.sendRequest(LOGOUT, 2, params, (error, response, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);

      if (!body.error) {
        this.setState({ myDonations: body });
      } else {
        console.log(body.error);
      }
    });
  }

  filterModules = () => {
    if (!Utility.isEmpty(crmData) && !Utility.isEmpty(this.props.userData)) {

      if (this.props.userData && this.props.userData.permissions && !(this.props.userData.is_super_owner) && !(this.props.userData.permissions.includes(PERMISSIONS.NETWORKS))) {
       const crmModules =  crmData.filter((item) => {  if(!(item.name === "Networks")) return item; });
       this.setState({ crmModules });
      } else if ((CLIENT_ID !== SM_CLIENT_ID) && (this.props.userData.role_id === ROLE_IDS.USER)) {
            const updateCrmData = crmData.filter(item => item.name === 'Home');
            this.setState({ crmModules: updateCrmData });
      } else {
            this.setState({ crmModules: crmData });
          }
    }
  }

  showMenu = () => {
    const body = document.getElementsByTagName('body')[0];
    const header = document.getElementsByTagName('header')[0];

    body.classList.contains('overflow-hidden') ? body.classList.remove('overflow-hidden') : body.classList.add('overflow-hidden') ;

    header.classList.contains('rounded-0') ? header.classList.remove('rounded-0') : header.classList.add('rounded-0') ;
  }

  goToHome = () => {
    this.showMenu();
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('overflow-hidden');
    this.props.history.push('/');
  }

    handleChatClick = () => {
      this.getOwnerDetailsInfo();
  };

  getOwnerDetailsInfo = () => {

    Utility.sendRequest(USERS + '/' + GIL_PARENT_OWNER_ID, 1, {}, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.props.updateIsChatFormOpen(true);
        body[0].fromMenu = true;
        this.props.updateChatFriend(body[0]);
      } else {
        console.log(body.error);
      }
    });
  }

  render() {
    return(
      <>
      <Navbar collapseOnSelect expand="xl" variant="dark" className="nav-share">
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          id="inner-nav"
          onClick={this.showMenu}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
          {!this.state.pwaEnable &&
            ((Utility.isResponsive() && (CLIENT_ID !== SM_CLIENT_ID) && (this.props.userData.role_id === ROLE_IDS.USER))
            || (this.props.userData.role_id !== ROLE_IDS.USER)
            || (this.props.userData.platform_id === SM_CLIENT_ID)) &&
            <nav className="sidebar">
              <ul className="side-navbar h-100 scroll-y">
                {
                  this.state.crmModules.map((item, index) => {
                    return(
                      <SidebarMenuItem
                        key={index}
                        item={item}
                        isMenuActive={this.state.activeMenuName === item.name.toLowerCase()}
                        selectedMenuItemId={this.state.selectedMenuItemId}
                        history={this.props.history}
                        location={this.props.location}
                        path={this.props.path}
                      />
                    );
                  })
                }
              </ul>
            </nav>
          }
          {
          this.state.pwaEnable &&
          <nav className="sidebar">
            <div className="side-navbar h-100 scroll-y d-flex flex-column" id= "pwa_side_navbar">
              <Row className="align-items-center no-gutters mt-3">
                <Col xs={9} className="pr-0 mt-4">
                  <div className=" align-items-center flex-nowrap mt-6" onClick ={() => this.goToHome()}>
                    <div className=" dashboard-card-img fix-width">
                    <img src={require('assets/images/icons/community-home-icon.svg')}  alt="dashboard icon" />
                    </div>
                    <h2 className="dashboard-card-right text-white mt-2">HOME</h2>
                  </div>
                </Col>
              </Row>
              <div className="mt-auto text-center">
              {GIL_PARENT_OWNER_ID !== this.props.userData.id &&
                <button className="btn btn-alternate px-4 lg-btn w-100" onClick={() => this.handleChatClick()}>CONTACT US </button>
              }
              </div>
              <div className="mt-3 mb-4 text-center">
                <button className="btn btn-link lg-btn text-white" onClick={() => this.action(this.state.isUserLogedIn ? "Logout" : "Login")}>
                  {
                    this.state.isUserLogedIn ? "LOGOUT" : "LOGIN"
                  }
                </button>
              </div>
            </div>
          </nav>
          }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
    );
  }
}
const mapActionToProps = {updateIsChatFormOpen, updateChatFriend };

const mapStateToProps = (state) => ({
  userData: state.userData,
  crmModules: state.crmModules,
  isChatFormOpen: state.isChatFormOpen,
  chatFriend: state.chatFriend
});

export default connect(mapStateToProps, mapActionToProps)(SideBar);
