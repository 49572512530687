import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Utility } from 'utils';

export class DropDownList extends Component {
  constructor(props) {
    super(props);
    const selectedFilterItem = !Utility.isEmpty(props.dropdownItems[0]) ? props.dropdownItems[0] : {};

    this.state = {
      selectedFilterItem: !Utility.isEmpty(props.selectedFilterItem) ?  props.selectedFilterItem : selectedFilterItem
    };
  }

  handleDropdown = (item) => {
    if (!this.props.isMenuAction) this.setState({ selectedFilterItem: item });

    this.props.handleDropdownAction(item);
  }

  render() {
    const dropdownItems = JSON.parse(JSON.stringify(this.props.dropdownItems));

    if (this.props.isMenuAction) dropdownItems.splice(0, 1);

    return (
      <Dropdown className={this.props.dropDownClassName}>
        <Dropdown.Toggle
          disabled={this.props.isDisabled?true:false}
          variant=""
          id="dropdown-basic"
          className={this.props.toggleButtonClassName}>
            { this.state.selectedFilterItem.name }
        </Dropdown.Toggle>
        <Dropdown.Menu className={this.props.menuClassName}>
          {
            dropdownItems.map((item, index) => {
              return (
                <Dropdown.Item
                  className={dropdownItems[index + 1] && dropdownItems[index + 1].name === 'Pending' ? 'dropdown-seprator' : ''}
                  onClick={() => this.handleDropdown(item)}
                  key={index}>
                    {Utility.capitalize(item.name)}
                </Dropdown.Item>
              );
            })
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
