import React, { useState, useEffect } from 'react';
import { Utility } from 'utils';
import { CHATS } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import SideCallout from 'shared/components/templates/side_callout';
import { useSelector, useDispatch } from 'react-redux';
import { ChevronLeftIcon } from 'shared/icons/icons';
import ChatCallout from 'shared/components/chat_callout.js';
import { InputField } from 'shared/components/form_elements';
import { NoRecordFound } from 'shared/components/no_record_found';
import { updateIsChatFormOpen, updateChatFriend } from 'shared/actions';
import { CLIENT_ID } from 'config/constants/config';
import { showLoader } from 'shared/actions';
import { Spinner } from 'react-bootstrap';


export default function PwaFooterChat (props){
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const userData = useSelector(state => state.userData);
  const isLoading = useSelector(state => state.isLoading);
  const isChatFormOpen = useSelector(state => state.isChatFormOpen);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    recentChats();
    return () => props.recentChatsApiCall();
  }, [search]);

  const recentChats = () => {
    const params = {
      from_user_client_id: userData.id,
      search_text: search.trim(),
      allUsers: true,
      clientId: CLIENT_ID,
    };

    dispatch(showLoader(true));
    Utility.sendRequest(CHATS, 1, params, (err, res, body) => {
      dispatch(showLoader(false));
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setUsers(JSON.parse(body).data);
      }
    });
  };

  const handleRadioClick = (ev, e) => {
    if (e === selectedUser) {
      setSelectedUser(null);
      document.getElementById(e.id).checked = false;
    } else setSelectedUser(e);
  };

  return(
    <>
      <SideCallout
        position={props.position}
        closeCallOut={() => {}}
        calloutHeading=' Choose People'
        calloutHeadingSvg={
          <ChevronLeftIcon
            handleClick={
              () => props.setIsChatOpen(false)
            }
            mainClass="article-page-back mr-3 mt-0"
          />
        }
        calloutCloseIcon={
          <GoIcon
            selectedUser={selectedUser}
          />
        }
        innerPosition="create-card-pwa-outer" >
          <InputField
            handleChange={(ev) => setSearch(ev.target.value)}
            type="text"
            className="search-input-callout mb-5"
            placeholder="Search People…"
          />
          <div className="tiles-container-pwa-footer" >
            {
              !Utility.isEmpty(users) ? users.map(e => {
                return(
                  <div className="align-items-center justify-content-between group-section-outer" key={e.id} >
                    <div className="w-100 align-items-center border-top border-bottom py-2" >
                      <img src={e.image_url || require('assets/images/users/user_no_image.png')} className="rounded-circle img-product"  alt="" />
                      <div className="lh-1 ml-10 mx-2 member-info-section-outer" >
                        <div className="font-bold display-7" >{e.name}</div>
                      </div>
                      <div className="mr-20 ml-auto">
                        <input
                          type="radio"
                          name="users"
                          id={e.id}
                          onClick={ (ev) => handleRadioClick(ev, e) } />
                      </div>
                    </div>
                  </div>
                );
              }) : isLoading ? <center><Spinner animation="border" role="status"/></center> : <NoRecordFound/>
            }
          </div>
      </SideCallout>

      { isChatFormOpen &&
        <ChatCallout />
      }
    </>
  );
}

function GoIcon(props){
  const dispatch = useDispatch();

  return(
    <>
    {
      props.selectedUser &&
      <button
        className="btn btn-primary"
        onClick={() => {
          dispatch(updateIsChatFormOpen(true));
          dispatch(updateChatFriend(props.selectedUser));
        }}
        >
        Go
      </button>
    }

    </>
  );
}
