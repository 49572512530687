import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserData } from 'shared/actions';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Navbar, Nav, Row, Spinner } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dialog from '@material-ui/core/Dialog';
import OwlCarousel from 'react-owl-carousel';
import ReactPlayer from 'react-player';
import { CloseIcon,CloseIconSharp, ShowIcon } from 'shared/icons/icons';
import Slide from '@material-ui/core/Slide';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LOGIN, USER_AUTH, SIGN_UP, VERIFY_ACTIVATION_CODE } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import { Utility } from 'utils';
import cookie from 'react-cookies';
import { toast, ToastContainer } from 'react-toastify';
import $ from 'jquery';
import { SmTermsOfService } from 'shared/components/sm_terms_of_service';
import PasswordValidationFrame from 'shared/components/password_validation_frame';
import { Checkbox } from "shared/components/form_elements";

export const LandingPage = (props) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showWaitASec, setShowWaitASec] = useState(false);
  const [showTos, setShowTos] = useState(false);
  const [showPp, setShowPp] = useState(false);
  const [showAup, setShowAup] = useState(false);
  const [showDa, setShowDa] = useState(false);
  const [showAfterRegis, setShowAfterRegis] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [acceptedTos, setAcceptedTos] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [smPlaying, setSmPlaying] = useState(false);
  const [gilPlaying, setGilPlaying] = useState(false);

  const [loginEmail, setLoginEmail] = useState(localStorage.getItem("email") || '');
  const [loginPassword, setLoginPassword] = useState(Utility.loadState("password") || '');

  const [registerFname, setRegisterFname] = useState("");
  const [registerLname, setRegisterLname] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerZipCode, setRegisterZipCode] = useState("");
  const [registerCheckbox, setRegisterCheckbox] = useState(false);
  const [showSmTosModal, setShowSmTosModal] = useState(false);
  const [showPasswordValidation, setshowPasswordValidation] = useState(false);
  const [password, setpassword] = useState({});
  const [remember_me, setRememberme] = useState(localStorage.getItem("remember_me") || false);

  const [otp, setOtp] = useState(null);

  const [verifyCodeParams, setVerifyCodeParams] = useState({});

  useEffect(() => {
    dispatch(updateUserData({}));
  }, []);


  const handleClose = () => {
    setShow(false);
    setShowRegister(false);
    setShowWaitASec(false);
    setShowLogin(false);
    setShowVerify(false);
    setShowTos(false);
    setShowDa(false);
    setShowPp(false);
    setShowAup(false);
    setInputType("password");
    setRegisterFname("");
    setRegisterLname("");
    setRegisterEmail("");
    setRegisterPassword("");
    setpassword({});
    setshowPasswordValidation(false);
    setAcceptedTos(false);
  };

  const handleOtpVerification = () => {
    if(otp && otp.length === 4){
      verifyCode();
    } else {
      toast.error("Please enter valid OTP", { containerId: 'public' });
    }
  };

  const showMenu = (event, isToggle = false) => {
    const body = document.getElementsByTagName('body')[0];

    isToggle ? body.classList.toggle('js-no-scroll') : body.classList.remove('js-no-scroll');
  };

  const goToNextCarouselPage = () => {
    var $dots = $('.owl-dot');
    var $next = $dots.filter('.active').next();

    if (!$next.length)
        $next = $dots.first();

    $next.trigger('click');
  };

  const handleNext = () => {
    goToNextCarouselPage();
  };

  const backToTop = () => {
    window.scrollTo(0, 0);
  };

  const paintSmVideo = () => {
    return (
      <>
        <div className='player-wrapper'>
          <ReactPlayer
            playing={smPlaying}
            url={require("assets/videos/Sharemeister Promo.mov")}
            className='react-player video-fit-screen-sm vh-100 vw-100'
            width='100%'
            controls={true}
            playsInline={true}
            loop={false}
            onEnded={() => setSmPlaying(false)}
            config={{
              youtube: {
                playerVars: {
                  rel: 0,
                  showinfo: 0,
                  fs: 1,
                  disablekb: 0,
                  controls: 1,
                  modestbranding: 1,
                }
              }
            }}
          />
          <div className="landing-page-video-close-sm" onClick={() => setSmPlaying(false)}>
            <CloseIcon
              mainClass="cursor-pointer"
              fillClass="fill-white"
            />
          </div>
        </div>
      </>
    );
  };

  const paintGilVideo = () => {
    return (
      <>
        <div className='player-wrapper'>
          <ReactPlayer
            playing={gilPlaying}
            url={require("assets/videos/GIL Journey Passport Promo.mp4")}
            className='react-player video-fit-screen-sm vh-100 vw-100'
            width='100%'
            controls={true}
            playsInline={true}
            loop={false}
            onEnded={() => setGilPlaying(false)}
            config={{
              youtube: {
                playerVars: {
                  rel: 0,
                  showinfo: 0,
                  fs: 1,
                  disablekb: 0,
                  controls: 1,
                  modestbranding: 1,
                }
              }
            }}
          />
          <div className="landing-page-video-close-sm" onClick={() => setGilPlaying(false)}>
            <CloseIcon
              mainClass="cursor-pointer"
              fillClass="fill-white"
            />
          </div>
        </div>
      </>
    );
  };

  const login = (e) => {
    e.preventDefault();

    const formData = {
      email: loginEmail,
      password: loginPassword,
      client_id: CLIENT_ID.toString()
    };

    setShowLoader(true);

    Utility.sendRequest(LOGIN, 2, formData, async (error, response, body) => {
      body = JSON.parse(body);

      setShowLoader(false);

      if (body.error) {
        //resetPassword();
        toast.error(body.error.message, { containerId: 'public' });
      } else {
        cookie.save('sessionToken', body.token, { path: '/' });

        Utility.sendRequest(USER_AUTH, 1, body.token, (error, response, body) => {
          body = JSON.parse(body);

          if (!body.error) {
            dispatch(updateUserData(body));
            props.history.push('/home');
            setShow(false);
            setShowLogin(false);
          } else {
            toast.error(body.error.message, { containerId: 'public' });
          }
        });
      }
    });
  };

  const checkValidations = () => {
    if(Utility.isEmpty(registerEmail) ||
      Utility.isEmpty(registerFname) ||
      Utility.isEmpty(registerLname) ||
      Utility.isEmpty(registerPassword))
      toast.error("All fields required", { containerId: 'public' });
    else if(!acceptedTos)
      toast.error("Please accept Terms of Services", { containerId: 'public' });
    else
      signUp();
  };


  const handleChange = (e) => {
    setRegisterPassword(e.target.value);
    let password = {};
    if (!Utility.isEmpty(e.target.value)) {
      password = Utility.validatePassword(e.target.value);
      setshowPasswordValidation(true);
      setpassword(password);
    } else {
      setshowPasswordValidation(false);
      setpassword({});
    }
  };

  const signUp = () => {
    setshowPasswordValidation(false);
    const formData = {
      email: registerEmail,
      first_name: registerFname,
      last_name: registerLname,
      password: registerPassword,
      client_id: CLIENT_ID,
      source: 'web',
    };

    Utility.sendRequest(SIGN_UP, 2, formData, (error, response, body) => {
      body = JSON.parse(body);

      // this.setState({ showLoader: false });

      if (!body.error) {
        setShowRegister(false);
        setShowVerify(true);
        setVerifyCodeParams(
          {
            email: formData.email,
            client_id: CLIENT_ID,
            is_token_require: true
          }
        )
        const resendVerificationParams = {
          email: formData.email,
          client_id: CLIENT_ID,
        };
        // this.setState({ isUserRegistered: true, verifyCodeParams, resendVerificationParams });
      } else {
        // this.setState({ showLoader: false });
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  };

  const verifyCode = () => {
    const params = verifyCodeParams;
    params.code = otp;

    Utility.sendRequest(VERIFY_ACTIVATION_CODE, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      // this.setState({ showLoader: false });

      if (!body.error) {
        cookie.save('sessionToken', body.token, { path: '/' });
        setShowVerify(false);
        setShow(false);
        setShowAfterRegis(true);
        window.scrollTo(0, 0);
      } else {
        // this.setState({ showLoader: false });
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  };

  const viewProfile = () => {
    props.history.push({
      pathname: '/profile',
      state: {
        fromSignUp: true
      }
    });
  }

  const handleRememberMe = (e) => {
    if (e.target.checked === true) {
      if (loginEmail && loginPassword) {
        localStorage.setItem('remember_me', e.target.checked);
        localStorage.setItem('email', loginEmail);
        Utility.saveState(loginPassword, 'password');
      }
    } else {
      localStorage.removeItem('remember_me');
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  }

  const checkBoxText = () => {
    return (
      <>
        By clicking "Register" you agree to the <span onClick={(ev) => {
          setShowSmTosModal(true); setShowTos(true);
        }} className="text-primary font-medium cursor-pointer">Terms of Service</span>, <span
        onClick={(ev) => {
          setShowSmTosModal(true); setShowPp(true);
        }} className="text-primary font-medium cursor-pointer">Privacy Policy</span>, <span onClick={(ev) => {
          setShowSmTosModal(true); setShowDa(true);
        }} className="text-primary font-medium cursor-pointer">Development Agreement</span>, <span onClick={(ev) => {
          setShowSmTosModal(true); setShowAup(true);
        }} className="text-primary font-medium cursor-pointer">Acceptable Use Policy</span> , and confirm you are at least 16 years old.
      </>
    );
  };

  return (
    <React.Fragment>
      {showSmTosModal &&
        <SmTermsOfService
          showTos={showTos}
          showPp={showPp}
          showDa={showDa}
          showAup={showAup}
          handleClose={() => {
            setShowSmTosModal(false);
            setShowTos(false);
            setShowDa(false);
            setShowPp(false);
            setShowAup(false);
          }}
        />
      }
      {/* <HomeNavbar/> */}
      <ToastContainer hideProgressBar={true} autoClose={6000} containerId={'public'} position={toast.POSITION.TOP_RIGHT} />
      {!showAfterRegis ?
        <>
        <header className="position-absolute w-100 new-shar-header">
          <Container>
            <Row>
              <Col>
                <Navbar collapseOnSelect expand="lg" className="shar-navbar d-flex align-items-center display-7 font-bold py-xl-5 py-3 position-relative" >
                  <NavLink to={'/'} className="w-md-100" >
                    <Image src={require("assets/images/logos/main-logo-white.png")} alt="Sharemeister Logo" className="home-nav-logo" />
                  </NavLink>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => showMenu(true)} />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <div className="navbar-outer d-flex align-items-lg-center">
                      <Nav className="ml-5">
                        <ul className="list-unstyled d-flex navbar-menu-list pt-3 pt-lg-0 display-md-6">
                          <li className="mb-3 mb-lg-0">
                            <HashLink exact to={'/#context'} className="mr-xl-4 mr-lg-3 text-white" activeClassName="nav-link-active" onClick={showMenu}>
                              Ownership
                            </HashLink>
                          </li>
                          <li className="mb-3 mb-lg-0">
                            <HashLink exact to={'/#service'} className="mr-xl-4 mr-lg-3 text-white" activeClassName="nav-link-active" onClick={showMenu}>
                              Service
                            </HashLink>
                          </li>
                          <li className="mb-3 mb-lg-0">
                            <HashLink exact to={'/#community'} className="mr-xl-4 mr-lg-3 text-white" onClick={showMenu}>
                              Community
                            </HashLink>
                          </li>
                          <li className="mb-3 mb-lg-0">
                            <HashLink exact to={'/#work'} className="mr-xl-4 mr-lg-3 text-white" onClick={showMenu}>
                              Work
                            </HashLink>
                          </li>
                          <li className="mb-3 mb-lg-0">
                            <HashLink exact to={'/#team'} className="mr-xl-4 mr-lg-3 text-white" onClick={showMenu}>
                              Team
                            </HashLink>
                          </li>
                        </ul>
                      </Nav>
                      <div className="shar-navbar-links align-items-lg-center ml-lg-auto ml-5 mt-lg-0 mt-5 mb-5 mb-lg-0">
                        <span className="mr-xl-5 mr-lg-3 mb-3 mb-lg-0 font-15 text-white text-uppercase cursor-pointer b-600"
                          onClick={() => {
                            setShow(true); setShowLogin(true);
                          }}
                        >Login</span>
                        <span className="btn btn-md text-uppercase btn-primary btn-getstarted display-md-6 cursor-pointer b-600"
                          onClick={() => {
                            setShow(true); setShowRegister(true);
                          }}
                        >Get Key</span>
                      </div>
                    </div>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </header>

        {/* Hero Section */}
        <section className="sec-hero row mx-0 text-white position-relative shar-text">
          <div className="slider">
            <OwlCarousel
              items={1}
              // loop
              className="owl-theme"
              // nav
              // dots
            >
              <div className="slides d-xl-flex">
                <Container>
                  <Row>
                    <Col xl={8}>
                      <div className="slid-content mt-lg-5">
                        <h1 className="font-60 b-700 line-height-60">Take ownership of your<br /> digital <span className="b-700 text-light-blue">community<br /> experience.</span></h1>
                        <p className="font-18 my-3">
                          Sharemeister is a software utility service with a marketplace <br />of resources to scale any digital application.
                        </p>
                        <div className="d-flex align-items-center video-play justify-content-center justify-content-md-start ml-3 my-4">
                          <div className="video-play-action">
                            <a href="javascript:;" onClick={() => setSmPlaying(true)}></a>
                          </div>
                          <div
                            className="video-play-text cursor-pointer font-15 b-500"
                            onClick={() => setSmPlaying(true)}>
                            Watch <span className="text-light-blue b-600">video</span>
                          </div>
                        </div>
                        <div className="text-center text-md-left">
                          <button
                            onClick={() => {
                              setShow(true); setShowRegister(true);
                            }}
                            className="btn btn-alternate font-weight-bold text-uppercase mt-4">
                            get the key to your city
                        </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <div className="slid-img">
                  <Image src={require("assets/images/landing-page/p-1.png")} alt="slide" />
                  <Image src={require("assets/images/landing-page/p-3.png")} className="grid-animation" alt="slide" />
                  <Image src={require("assets/images/landing-page/p-2.png")} className="persons-animation" alt="slide" />
                  <Image src={require("assets/images/landing-page/p-4.svg")} className="s-logo-animation" alt="slide" />
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>
        <section className="eliminate-complexity n-skewY">
          <div className="skewY mt-md-5 py-md-5">
            <div className="helping-image position-absolute d-none d-lg-block">
              <Image src={require("assets/images/helping-plus.png")} alt="plus" />
            </div>
            <div className="helping-image-polygon position-absolute d-none d-lg-block">
              <Image src={require("assets/images/helping-sm-polygon.png")} alt="plus" />
            </div>
            <Container className="py-5 my-md-5">
              <Row>
                <Col md={12}>
                  <div className="font-46 b-700 text-light text-center">
                    Eliminate complexity and ensure digital sovereignty with <span className="text-black">a suite of decentralized utility services.</span>
                  </div>
                  <p className="text-blue-mute font-25 text-center my-3 b-500">Streamline each layer of your digital application.</p>
                </Col>
              </Row>
              <Row className="mx-0 my-md-5 pb-md-5">
                <Col xl={11} className="mx-auto align-items-center justify-content-md-between flex-column flex-md-row">
                  <div className="eliminate-complexity-box my-3 my-md-0">
                    <div className="eliminate-complexity-icon">
                      <Image src={require("assets/images/context.png")} alt="Context" />
                    </div>
                    <div className="eliminate-complexity-text text-center b-600 text-light font-20 my-2">
                      Context
                  </div>
                  </div>
                  <div className="eliminate-complexity-box my-3 my-md-0">
                    <div className="eliminate-complexity-icon">
                      <Image src={require("assets/images/communities.png")} alt="Context" />
                    </div>
                    <div className="eliminate-complexity-text text-center b-600 text-light font-20 my-2">
                      Community
                  </div>
                  </div>
                  <div className="eliminate-complexity-box my-3 my-md-0">
                    <div className="eliminate-complexity-icon">
                      <Image src={require("assets/images/content.png")} alt="Context" />
                    </div>
                    <div className="eliminate-complexity-text text-center b-600 text-light font-20 my-2">
                      Content
                  </div>
                  </div>
                  <div className="eliminate-complexity-box my-3 my-md-0">
                    <div className="eliminate-complexity-icon">
                      <Image src={require("assets/images/data.png")} alt="Context" />
                    </div>
                    <div className="eliminate-complexity-text text-center b-600 text-light font-20 my-2">
                    Communication
                  </div>
                  </div>
                  <div className="eliminate-complexity-box my-3 my-md-0">
                    <div className="eliminate-complexity-icon">
                      <Image src={require("assets/images/distribution.png")} alt="Context" />
                    </div>
                    <div className="eliminate-complexity-text text-center b-600 text-light font-20 my-2">
                    Commerce
                  </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div id="context" className="helping-image-lg-polyon position-absolute d-none d-lg-block">
            <Image src={require("assets/images/helping-lg-polygon.png")} alt="polygon" />
          </div>
        </section>
        <section className="context n-skewY">
          <div className="skewY mt-md-5 py-md-5 add-bg">
            <Container className="py-md-5">
              <Row className="py-5">
                <Col md={12} className="my-5">
                  <OwlCarousel
                    items={1}
                    loop
                    className="owl-theme"
                    nav
                    dots
                  >
                    <Row className="slide">
                      <Col md={5}>
                        <div className="slide-text">
                          <p className="text-uppercase text-light-blue font-18">context</p>
                          <div className="font-48 text-light-blue b-700">
                            Own <span className="text-light">your Context</span>
                          </div>
                          <div className="text-light font-18">
                            <p>Give your community a place to live, work and play.</p>
                            <p>You maintain 360 degree control of your digital experience - including landing pages, home pages, mobile and content management systems.</p>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className="slide-image">
                          <Image src={require("assets/images/context-slide.png")} alt="Context" />
                        </div>
                      </Col>

                    </Row>
                    <Row className="slide">
                      <Col md={5}>
                        <div className="slide-text">
                          <p className="text-uppercase text-light-blue font-18">community</p>
                          <div className="font-48 text-light b-700">
                            Own your <span className="text-light-blue">Community</span>
                          </div>
                          <div className="text-light font-18">
                            <p>Your community comes in all shapes and sizes. Remove 3rd-party system distractions to better connect, communicate and collaborate with your community stakeholders.</p>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className="slide-image">
                          <Image src={require("assets/images/context-slide1.png")} alt="Context" />
                        </div>
                      </Col>
                    </Row>
                    <Row className="slide">
                      <Col md={5}>
                        <div className="slide-text">
                          <p className="text-uppercase text-light-blue font-18">content</p>
                          <div className="font-48 text-light b-700">
                            Own your <span className="text-light-blue">Content</span>
                          </div>
                          <div className="text-light font-18">
                            <p>Amplify your community interactions with a connected, higher-quality, more engaged audience that self-assemble around any type of media, product or service you can imagine.</p>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className="slide-image">
                          <Image src={require("assets/images/context-slide2.png")} alt="Context" />
                        </div>
                      </Col>
                    </Row>
                    <Row className="slide">
                      <Col md={5}>
                        <div className="slide-text">
                          <p className="text-uppercase text-light-blue font-18">Communication</p>
                          <div className="font-48 text-light b-700">
                            Own your <span className="text-light-blue">Communication</span>
                          </div>
                          <div className="text-light font-18">
                            <p>Build trust by keeping the conversation in your community. Algorithm controls ensure better governance, security and quality of engagement.</p>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className="slide-image">
                          <Image src={require("assets/images/context-slide3.png")} alt="Context" />
                        </div>
                      </Col>
                    </Row>
                    <Row className="slide">
                      <Col md={5}>
                        <div className="slide-text">
                          <p className="text-uppercase text-light-blue font-18">Commerce</p>
                          <div className="font-48 text-light b-700">
                            Own your <span className="text-light-blue">Commerce</span>
                          </div>
                          <div className="text-light font-18">
                            <p>Join a decentralized marketplace and distribute your content - including media, products and services around the world without losing your digital sovereignty.</p>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className="slide-image">
                          <Image src={require("assets/images/context-slide4.png")} alt="Context" />
                        </div>
                      </Col>
                    </Row>
                  </OwlCarousel>
                </Col>
              </Row>
            </Container>
            <div className="helping-image position-absolute d-none d-lg-block">
              <Image src={require("assets/images/helping-sm-plus.png")} alt="plus" />
            </div>
          </div>
        </section>
        <section className="what-idea n-skewY">
          <div className="skewY mt-md-5 py-md-5 add-bg">
            <div className="helping-image position-absolute d-none d-lg-block">
              <Image src={require("assets/images/helping-blue-polygon.png")} alt="polygon" />
            </div>
            <Container className="py-5">
              <Row className="py-5">
                <Col md={5}>
                  <div className="slide-text">
                    <div className="font-50 line-height-60 b-700 text-light-blue">
                      What idea <span className="text-black">do you want to scale?</span>
                    </div>
                    <div className="text-black font-18">
                      <p className="text-black-mute font-20 my-3">Software utilities as Your Service</p>
                      <p>Stop renting space and build to own. Take charge of digital experience that serves your community.</p>
                    </div>
                    <button
                      onClick={() => {
                        setShow(true); setShowRegister(true);
                      }}
                      className="btn btn-alternate text-uppercase mt-4 mb-3 mb-md-0 font-weight-bold no-transition-after">
                      get the key to your city
                    </button>
                  </div>
                </Col>
                <Col md={7}>
                  <div className="slide-image">
                    <Image src={require("assets/images/what-idea.png")} alt="Context" />
                  </div>
                </Col>

              </Row>

            </Container>
          </div>
        </section>
        <section className="software-services n-skewY">
          <div className="skewY mt-md-5 py-5">
            <Container>
              <Row>
                <Col lg={11} xl={9} className="mx-auto">
                  <div id="service" className="text-light text-center b-700 font-50">
                    Software utilities as <span className="text-light-blue">your Service</span> starting at $500 per month
                </div>
                </Col>
                <Col md={12} className="my-5 pb-md-5">
                  <OwlCarousel
                    items={3}
                    // loop
                    className="owl-theme pb-5 owl-height"
                    // nav
                    // dots
                    margin={30}
                    autoHeight='true'
                    responsive={
                      {
                        0: {
                          items: 1
                        },
                        600: {
                          items: 2
                        },
                        768: {
                          items: 2
                        },
                        1000: {
                          items: 3
                        }
                      }
                    }
                  >
                    <div className="items">
                      <div className="software-services-box">
                        <div className="software-services-icon">
                          <Image src={require("assets/images/modules.png")} alt="Context" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">Utilities</div>
                        <p>A fully customizable system of modules exemplify fluid and intuitive community management software utilities that integrate seamlessly with your digital application.</p>
                      </div>
                    </div>
                    <div className="items">
                      <div className="software-services-box">
                        <div className="software-services-icon">
                          <Image src={require("assets/images/apis.png")} alt="Context" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">APIs</div>
                        <p>Most platforms force customers into cookie-cutter molds. With more than 200 APIs, S! offers unparalleled customization to build a vibrant, beautiful, digital experience. Stop renting the same, boring, digital spaces and build to own!</p>
                      </div>
                    </div>
                    <div className="items">
                      <div className="software-services-box">
                        <div className="software-services-icon">
                          <Image src={require("assets/images/erps.png")} alt="Context" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">ERPs</div>
                        <p>Many organizations suffer from tech debt, feature fatigue and poor user adoption, overloaded with platforms that don't integrate well together behind-the-scenes in support of the application. Our software services provide seamless integration techniques that streamline your back-end systems.</p>
                      </div>
                    </div>
                   </OwlCarousel>
                </Col>
              </Row>
            </Container>

          </div>
        </section>
        <section className="scaling-process skewY">
          <div className="n-skewY mt-md-5 py-5">
            <div className="helping-image position-absolute d-none d-lg-block">
              <Image src={require("assets/images/helping-blue-polygon.png")} alt="polygon" />
            </div>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="text-black b-700 font-48 text-center my-4">
                    Our <span className="text-light-blue">Scaling</span> Process
                </div>
                </Col>
              </Row>
              <div className="process-section pb-md-5">
                <Row className="mb-3 justify-content-center">
                  <Col md={5}>
                    <div className="process d-flex">
                      <div className="process-number ml-md-auto font-20 b-700">
                        1
                    </div>
                      <div className="process-details">
                        <div className="process-icon">
                          <Image src={require("assets/images/discovery.png")} alt="Discovery" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">Discovery</div>
                        <p>We meet together and learn about the goals you have for your organization and identify how best to integrate the software utility services to scale your digital community experience.</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={1} className="d-none d-md-block">
                    <div className="arrow-right mt-5 mb-5 mb-md-0 text-md-right text-center">
                      <Image src={require("assets/images/arrow-icon.png")} alt="Discovery" />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="process d-flex">
                      <div className="process-number ml-md-auto font-20 b-700">
                        2
                  </div>
                      <div className="process-details">
                        <div className="process-icon">
                          <Image src={require("assets/images/demo.png")} alt="Discovery" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">Demo</div>
                        <p>Our designers are able to provide a customized low-fi demo of your digital community experience to help identify the resource requirements and timeline to bring your vision to life.</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3 justify-content-center">
                  <Col md={5} className="order-2 order-md-0">
                    <div className="process d-flex">
                      <div className="process-number ml-md-auto font-20 b-700">
                        4
                    </div>
                      <div className="process-details">
                        <div className="process-icon">
                          <Image src={require("assets/images/development.png")} alt="Development" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">Development</div>
                        <p>Our engineers are able to provide server-side support or client-side implementation of the decentralized community architecture with your digital application.</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={1} className="d-none d-md-block">
                    <div className="arrow-left mt-5 text-right">
                      <Image src={require("assets/images/arrow-icon.png")} alt="Arrow icon" />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="process d-flex">
                      <div className="process-number ml-md-auto font-20 b-700">
                        3
                  </div>
                      <div className="process-details">
                        <div className="process-icon">
                          <Image src={require("assets/images/design.png")} alt="Design" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">Design</div>
                        <p>Create a fully-customized hi-fi professional grade design of your digital application - including landing pages, homepages, mobile, web and utility services to manage your digital community at scale.</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3 justify-content-center">
                  <Col md={5}>
                    <div className="process d-flex">
                      <div className="process-number ml-md-auto font-20 b-700">
                        5
                    </div>
                      <div className="process-details">
                        <div className="process-icon">
                          <Image src={require("assets/images/deployment.png")} alt="Deployment" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">Deployment</div>
                        <p>Test and review your digital community experience in development and production environments.</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={1} className="d-none d-md-block">
                    <div className="arrow-right mt-5 mb-5 mb-md-0 text-md-right text-center">
                      <Image src={require("assets/images/arrow-icon.png")} alt="arrow" />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="process d-flex">
                      <div className="process-number ml-md-auto font-20 b-700">
                        6
                  </div>
                      <div className="process-details">
                        <div className="process-icon">
                          <Image src={require("assets/images/distribution-icon.png")} alt="Distribution" />
                        </div>
                        <div className="font-20 b-700 my-2 software-services-title">Distribution</div>
                        <p>It's go time! Market and scale your digital community experience to the world.</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

        </section>
        <section className="connecting skewY">
          <div id="work" className="n-skewY mt-5 py-5">
            <Container>
              <Col md={12} className="my-5">
                <OwlCarousel
                  items={1}
                  // loop
                  className="owl-theme"
                  // nav
                  // dots
                >
                  <Row>
                    <Col md={5}>
                      <Image src={require("assets/images/mobile.png")} alt="Mobile" />
                    </Col>
                    <Col md={6} className="mt-5 pt-5">
                      <div className="text-light b-700 font-48 line-height-50">
                        <span className="text-black">Connecting 600m</span> evangelicals across <br /> 193 countries
                      </div>
                      <p className="text-light my-4 font-20">Global Institute of Leadership <a href="" className="association">Associations</a></p>
                      <p className="text-light font-16">The Global Institute of Leadership (GIL) was created by the World Evangelical Allicance (WEA) with the purpose of strengthening leaders and their peers to bring about national, social, and spiritual transformation. Through the provision of the best training resources and leadership-centered experiences, GIL has helped guide thousands.</p>
                      <div className="d-flex align-items-center">
                        <button
                          onClick={() => {
                            setShow(true); setShowRegister(true);
                          }}
                          className="btn btn-theme btn-alternate text-uppercase py-3 font-weight-bold">
                          get  key
                          </button>
                        <div className="d-flex align-items-center video-play ml-4 my-4">
                          <div className="video-play-action">
                            <a href="javascript:;" onClick={() => setGilPlaying(true)}></a>
                          </div>
                          <div
                            className="text-light cursor-pointer font-18 b-700 ml-3"
                            onClick={() => setGilPlaying(true)}>
                            Watch Video
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={5}>
                      <Image src={require("assets/images/mobile.png")} alt="Mobile" />
                    </Col>
                    <Col md={6} className="mt-5 pt-5">
                      <div className="text-light b-700 font-48 line-height-50">
                        <span className="text-black">Connecting 600m</span> evangelicals across <br /> 193 countries
                      </div>
                      <p className="text-light my-4 font-20">Global Institute of Leadership <a href="" className="association">Associations</a></p>
                      <p className="text-light font-16">The Global Institute of Leadership (GIL) was created by the World Evangelical Allicance (WEA) in [year] with the purpose of sterngthening leaders and their peers to bring about national, social, and spiritual transformation. Through the provision of the best training resources and leadership-centered experiences, GIL has helped guide thousands.</p>
                      <div className="d-flex align-items-center">
                        <button className="btn btn-alternate text-uppercase py-3 font-weight-bold">get  key</button>
                        <div className="d-flex align-items-center video-play ml-4 my-4">
                          <div className="video-play-action">
                            <a href="javascript:;" onClick={() => setGilPlaying(true)}></a>
                          </div>
                          <div className="text-light cursor-pointer font-18 b-700 ml-3">Watch Video</div>
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                </OwlCarousel>
              </Col>
            </Container>
          </div>
        </section>
        <section className="define-community ">
          <div id="community" className="">
            <OwlCarousel
              items={1}
              loop
              className="owl-theme"
              nav
              dots
            >
              <div className="community-slide slide-1">
                <div className="community-slide-img">
                  <Image src={require("assets/images/community-image-1.png")} alt="Community" />
                </div>
                <Container className="community-slide-inner">
                  <Row>
                    <Col lg={8}>
                      <div className="community-slide-details">
                        <div className="text-light-blue font-48 b-700">
                          Define <span className="text-light">Community</span>
                        </div>
                        <p className="font-20 text-light my-3">What kind of communities are represented in your digital experience?</p>
                        <p className="font-16 text-light">We all have multiple roles in our physical communities. Also, we have multiple roles when we interact inside a digital environment. Our software services organize your community and provide role management inside your digital application.</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="community-slide slide-2">
                <div className="community-slide-img">
                  <Image src={require("assets/images/community-image-2.png")} alt="Community" />
                </div>
                <Container className="community-slide-inner">
                  <Row>
                    <Col lg={8}>
                      <div className="community-slide-details">
                        <p className="text-uppercase text-light-blue">citizens</p>
                        <div className="text-light-blue font-48 b-700 nowrap">
                          Citizens <span className="text-light">come in many forms</span>
                        </div>
                        <p className="font-16 text-light">To a Nonprofit, it's a donor. To a Celebrity, it's a fan. To a Shopkeeper, it's a customer, To a Politician, it's a voter. To a Company, it's an employee. To a Church, it's a Congregation. Whatever the audience - utilize the Citizen feature to give your people sovereignty of their own account inside your digital experience.</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="community-slide slide-3">
                <div className="community-slide-img">
                  <Image src={require("assets/images/community-image-3.png")} alt="Community" />
                </div>
                <Container className="community-slide-inner">
                  <Row>
                    <Col lg={8}>
                      <div className="community-slide-details">
                        <p className="text-uppercase text-light-blue">club houses</p>
                        <div className="text-light-blue font-48 b-700">
                          <span className="text-light">A Clubhouse is a Citizen with a </span> large fan base.
                        </div>
                        <p className="font-16 text-light">Upgrade to the Clubhouse and access the administrative features you need to manage your growing influence with your existing Citizen account.</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="community-slide slide-4">
                <div className="community-slide-img">
                  <Image src={require("assets/images/community-image-4.png")} alt="Community" />
                </div>
                <Container className="community-slide-inner">
                  <Row>
                    <Col lg={8}>
                      <div className="community-slide-details">
                        <p className="text-uppercase text-light-blue">Neighborhood</p>
                        <div className="text-light-blue font-48 b-700">
                          <span className="text-light">For CPG Brands, Firms and </span> Organizations.
                        </div>
                        <p className="font-16 text-light">A Neighborhood at Sharemeister are considered established organizations tha live within the context of a large community; providing content- including media, products and services to support the needs of larger communities.</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="community-slide slide-5">
                <div className="community-slide-img">
                  <Image src={require("assets/images/community-image-5.png")} alt="Community" />
                </div>
                <Container className="community-slide-inner">
                  <Row>
                    <Col lg={8}>
                      <div className="community-slide-details">
                        <p className="text-uppercase text-light-blue">cities</p>
                        <div className="text-light-blue font-48 b-700">
                          <span className="text-light">For anyone who has an idea that represents</span> a community.
                        </div>
                        <p className="font-16 text-light">With a City account, unlock all the modules and features of our community building platform designed to give you ownership of your social community experience in your own context.</p>
                        <p className="font-16 text-light">Upgrade to a City Account and get the Key to Your City today!</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="community-slide slide-6">
                <div className="community-slide-img">
                  <Image src={require("assets/images/community-image-5.png")} alt="Community" />
                </div>
                <Container className="community-slide-inner">
                  <Row>
                    <Col lg={8}>
                      <div className="community-slide-details">
                        <p className="text-uppercase text-light-blue">metroplexes</p>
                        <div className="text-light-blue font-48 b-700">
                          <span className="text-light">For Conglomerates and</span> Governments
                        </div>
                        <p className="font-16 text-light">Utilize the Metroplex account to seamlessly connect multiple City experiences inside one cohesive and customized command center. Our modules and features ensure your vertical integrations support the cross-distribution of resources from one community to the other without refracting your audience.</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </OwlCarousel>
          </div>
        </section>
        <section className="digital-house">
          <div className="mt-5 py-5">
            <Container className="py-5">
              <Row className="py-md-5">
                <Col md={12} className="py-5">
                  <div id="team" className="text-light text-center b-700 font-48 mt-5">
                    Digital House Builders bring <span className="text-light-blue">your idea to life</span>
                  </div>
                  <p className="font-20 b-500 text-light-50 my-4 text-center">Join a growing network of designers and developers working to bring a <br />decentralized digital community utilities and architecture to the marketplace.</p>
                  <div className="team pr-md-5">
                    <Image src={require("assets/images/team-image.png")} alt="Team" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="mx-auto">
                  <Row className="pb-5">
                    <Col lg={8}>
                      <div className="font-46 text-light b-700">
                        Partner with Sharemeister
                      </div>
                      <p className="text-light-50 mt-4 font-20 b-500">From Dallas to Delhi, we are helping the world take ownership of its digital landscape from the ground-up. Taking ownership of your digital community begins with respecting the constitution of your physical location where you live. Partner with us to own exclusive contract rights to projects originating in your home country, state, or city.</p>
                      <button
                        onClick={() => {
                          setShow(true); setShowRegister(true);
                        }}
                        className="btn btn-theme btn-alternate text-uppercase py-3 mt-4 mb-4 mb-md-0 font-weight-bold">
                        get  key
                        </button>
                    </Col>
                    <Col lg={4}>
                      <Image src={require("assets/images/logos/s-logo.png")} alt="Sharemeister Logo" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="helping-image-left position-absolute d-none d-lg-block">
            <Image src={require("assets/images/helping-dark-polygon.png")} alt="polygon" />
          </div>
          <div className="helping-image-right position-absolute d-none d-lg-block">
            <Image src={require("assets/images/helping-dark-polygon.png")} alt="polygon" />
          </div>
        </section>
        <section className="own-digital">
          <div className="own-digital-inner">
            <Container className="py-5">
              <Row className="align-items-center py-5">
                <Col lg={5}>
                  <Image src={require("assets/images/s-splash.png")} alt="Sharemeister Logo" />
                </Col>
                <Col lg={7}>
                  <div className="text-light font-48 b-700 line-height-60">
                    Create your own digital <span className="text-dark-blue">community experience</span>
                  </div>
                  <p className="mt-4 font-18">Sharemeister is a software utility service with a marketplace of resources to scale any digital application.</p>
                  <p className="mt-3 font-18">Get started with a free account today!</p>
                  <button className="btn btn-theme btn-alternate text-uppercase py-3 mt-4 button-shadow font-weight-bold"
                    onClick={() => {
                      setShow(true); setShowRegister(true);
                    }}
                  >get  key</button>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        </>
        : <section className="welcome-page">
          <div className="welcome-page-inner">
            <OwlCarousel
              items={1}
              loop
              className="owl-theme"
              nav
              dots
            >
              <Container>
                <Row className="slide">
                  <Col md={3}>
                    <div className="top-helping-image">
                      <div className="first-image"><Image src={require("assets/images/welcome-plus-white.png")} alt="Helping Image" />
                      </div>
                      <div className="second-image"><Image src={require("assets/images/welcome-triangle-blue.png")} alt="Helping Image" />
                      </div>
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className="welcome-detail">
                      <div className="welcome-title text-black font-50 b-700 pb-4 mb-5">Welcome to <span className="d-md-inline-flex"><Image src={require("assets/images/logos/main-logo.png")} alt="Sharemeister Logo" /></span></div>
                      <div className="text-black font-25 b-600">Why are we gathering?</div>
                      <div className="text-light-blue my-4 font-25">Take Ownership</div>
                      <p className="font-20 text-black">We provide digital architecture, software utilities and a marketplace of resources to support your own digital application.</p>
                      <button className="btn btn-theme btn-alternate my-4 font-18 px-5 font-weight-bold" onClick={handleNext}>Next</button>
                      <div className="bottom-helping-image">
                        <Image src={require("assets/images/welcome-circle-large.png")} alt="Sharemeister Logo" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="slide">
                  <Col md={3}>
                    <div className="top-helping-image">
                      <div className="first-image"><Image src={require("assets/images/welcome-circle-white.png")} alt="Helping Image" />
                      </div>
                      <div className="second-image"><Image src={require("assets/images/welcome-plus-small.png")} alt="Helping Image" />
                      </div>
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className="welcome-detail">
                      <div className="welcome-title text-black font-50 b-700 pb-4 mb-5">Welcome to <span className="d-md-inline-flex"><Image src={require("assets/images/logos/main-logo.png")} alt="Sharemeister Logo" /></span></div>
                      <div className="text-black font-25 b-600">What are we gathering to do?</div>
                      <div className="text-light-blue my-4 font-25">Scale your Idea.</div>
                      <p className="font-20 text-black">
                      Setup a <span className="b-700">DEMO</span> to learn how decentralized software utilities provide YOU with the digital infrastructure to customize, scale and own your digital application.</p>
                      <button className="btn btn-theme btn-alternate my-4 font-18 px-5" onClick={handleNext}>Next</button>
                      <div className="bottom-helping-image">
                        <Image src={require("assets/images/welcome-triangle-large.png")} alt="Sharemeister Logo" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="slide">
                  <Col md={3}>
                    <div className="top-helping-image">
                      <div className="first-image"><Image src={require("assets/images/welcome-triangle.png")} alt="Helping Image" />
                      </div>
                      <div className="second-image"><Image src={require("assets/images/welcome-circle.png")} alt="Helping Image" />
                      </div>
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className="welcome-detail">
                      <div className="welcome-title text-black font-50 b-700 pb-4 mb-5">Welcome to <span className="d-md-inline-flex"><Image src={require("assets/images/logos/main-logo.png")} alt="Sharemeister Logo" /></span></div>
                      <div className="text-black font-25 b-600">Who are we gathering with?</div>
                      <div className="text-light-blue my-4 font-25">A community of resources.</div>
                      <p className="font-20 text-black">
                      A member of the Sharemeister community will reach out shorlty to learn how best we can <span className="b-700">SERVE</span> you and the needs of your organization.</p>
                      <button className="btn btn-theme btn-alternate my-4 font-18" onClick={viewProfile}>Go to Profile</button>
                      <div className="bottom-helping-image">
                        <Image src={require("assets/images/welcome-plus.png")} alt="Sharemeister Logo" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </OwlCarousel>
          </div>
        </section>
      }
        <footer className="sh-footer py-5">
          <Container className="my-3">
            <Row>
              <Col md={4}>
                <Image src={require("assets/images/logos/main-logo-white.png")} className="action-td-chat" alt="Sharemeister Logo" />
                <ListGroup className="list-group-horizontal" horizontal>
                  <ListGroup.Item>
                    <Link onClick={() => {
                      setShow(true); setShowWaitASec(true);
                    }}>
                      <Image src={require("assets/images/social-instagram.png")} alt="Social Instagram" />
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link onClick={() => {
                      setShow(true); setShowWaitASec(true);
                    }}>
                      <Image src={require("assets/images/social-facebook.png")} alt="Social Facebook" />
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link onClick={() => {
                      setShow(true); setShowWaitASec(true);
                    }}>
                      <Image src={require("assets/images/social-youtube.png")} alt="Social Youtube" />
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link onClick={() => {
                      setShow(true); setShowWaitASec(true);
                    }}>
                      <Image src={require("assets/images/social-linkedin.png")} alt="Social Linkedin" />
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={4}>
                <ListGroup>
                  <ListGroup.Item>
                    <Link onClick={() => {
                      setShowSmTosModal(true); setShowTos(true);
                    }}>
                      Terms of Service
                  </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link onClick={() => {
                      setShowSmTosModal(true); setShowPp(true);
                    }}>
                      Privacy Policy
                  </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link onClick={() => {
                      setShowSmTosModal(true); setShowAup(true);
                    }}>
                      Acceptable Use Policy
                  </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link onClick={() => {
                      setShowSmTosModal(true); setShowDa(true);
                    }}>
                      Development Agreement
                  </Link>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={4} className="text-md-right">
                <button onClick={backToTop} className="btn back-top light-text text-uppercase">
                  <Image src={require("assets/images/top-arrow.png")} alt="Top Arrow" className="mr-2" />back to top
                  </button>
              </Col>
            </Row>
          </Container>
        </footer>
      {/* <HomeFooter history={props.history} /> */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="registration"
        className="sm-login-modal"
      >
        <Modal.Header closeButton className="border-0">
          <CloseIconSharp mainClass="modal-close-btn" />
        </Modal.Header>
        <Modal.Body className="pt-4">
          {/* Registration */}
          {showRegister &&
            <Row className="align-items-center">
              <Col lg={5} className="text-right">
                <Image src={require("assets/images/logos/modal-s-logo.png")} alt="Logo" />
              </Col>
              <Col lg={6} className="ml-md-5">
                <div className="helping-image position-absolute d-none d-md-block">
                  <Image src={require("assets/images/modal-helping-plus.png")} alt="Logo" />
                </div>
                <div className="modal-title font-60 b-700">Registration</div>
                <p className="my-4">Join a world of leaders who want to take ownership of <br />their digital community experience.</p>
                <Row>
                  <Col lg={10}>
                    <Form>
                      <Form.Group controlId="formGroupFirstName">
                        <div className="icon"><Image src={require("assets/images/name.png")} alt="First Name" /></div>
                        <Form.Control required type="text" onChange={(ev) => setRegisterFname(ev.target.value)} placeholder="First Name" />
                      </Form.Group>
                      <Form.Group controlId="formGroupLastName">
                        <div className="icon"><Image src={require("assets/images/name.png")} alt="Last Name" /></div>
                        <Form.Control type="text" onChange={(ev) => setRegisterLname(ev.target.value)} placeholder="Last Name" />
                      </Form.Group>
                      <Form.Group controlId="formGroupEmail">
                        <div className="icon"><Image src={require("assets/images/email.png")} alt="Email" /></div>
                        <Form.Control type="email" onChange={(ev) => setRegisterEmail(ev.target.value)} placeholder="Email address" />
                      </Form.Group>
                      <Form.Group controlId="formGroupPassword">
                        <div className="icon"><Image src={require("assets/images/password.png")} alt="Password" /></div>
                        <Form.Control type={inputType} onChange={(ev) => handleChange(ev)} placeholder="Password" />
                        <b className="btn ml-auto mt-2 icon-btn" onClick={() => setInputType(inputType === "text" ? "password" : "text")}>
                          <ShowIcon mainClass={`sm-show-icon ${inputType === "text" ? "sm-show-icon-active" : ""}`}  />
                        </b>
                      </Form.Group>
                      <Form.Group controlId="formGroupZipCode">
                        <div className="icon"><Image src={require("assets/images/zipcode.png")} alt="Zip Code" /></div>
                        <Form.Control type="text" onChange={(ev) => setRegisterZipCode(ev.target.value)} placeholder="Zip Code" />
                      </Form.Group>
                      {showPasswordValidation && <PasswordValidationFrame password={password}/>}
                      <div className="my-4 agreement-label">
                        {['checkbox'].map((type) => (
                          <div key={`custom-${type}`} className="mb-3">
                            <Form.Check
                              custom
                              type={type}
                              onClick={() => setAcceptedTos(!acceptedTos)}
                              id={`custom-${type}`}
                              label={checkBoxText()}
                            />
                          </div>
                        ))}
                      </div>
                    </Form>
                    <div className="actions d-flex flex-column mt-lg-5">
                      <Button
                        className="btn btn-theme btn-alternate text-uppercase button-shadow font-weight-bold"
                        onClick={checkValidations}>
                        register
                      </Button>
                      <Button
                        onClick={() => {
                          setShow(true); setShowRegister(false); setShowLogin(true);
                        }}
                        className="btn btn-theme login-btn b-500">
                        Login
                    </Button>
                    </div>
                  </Col>
                </Row>
                <div className="helping-image-bottom position-absolute d-none d-md-block">
                  <Image src={require("assets/images/modal-helping-plus-lg.png")} alt="Pluse" />
                </div>
              </Col>
            </Row>
          }

          {/* Wait a second */}
          {showWaitASec && <Row className="align-items-center">
            <Col lg={5} className="text-right">
              <Image src={require("assets/images/logos/modal-s-logo.png")} alt="Logo" />
            </Col>
            <Col lg={6} className="ml-md-5">
              <div className="helping-image position-absolute d-none d-md-block">
                <Image src={require("assets/images/modal-helping-plus.png")} alt="Logo" />
              </div>
              <div className="modal-title font-60 b-700">Wait a <span className="text-light-blue">second!</span></div>
              <p className="my-4">Why would we want to send you away to a place designed to disrupt OUR conversation? You are already here hanging out at our house - and we'd love to help you make your house a home for the people you serve.</p>
              <p className="my-4">We imagine the community you serve feels the same way! When friends come to visit your physical house, do you welcome them inside your home for dinner only to say, "Go eat across the street!" - then try to convince them to return after you have sent them away?</p>
              <p className="my-4">Of course not! We don't treat people like this physically, so why would we want to turn people away digitally?</p>
              <p className="my-4">We want all to feel welcome and develop meaning relationships with people in context of your community. We want to share memorable experiences to keep your people coming back.</p>
              <div className="text-dark font-20 b-500">Get started with Sharemeister to learn how we can help you build your own digital community experience!</div>
              <Button
                onClick={() => {
                  setShow(true); setShowRegister(true); setShowWaitASec(false);
                }}
                variant="alternate"
                className="btn btn-theme btn-alternate text-uppercase button-shadow  px-4 py-3 mt-5">
                Get key
              </Button>
              <div className="helping-image-bottom position-absolute d-none d-md-block">
                <Image src={require("assets/images/modal-helping-plus-lg.png")} alt="Pluse" />
              </div>
            </Col>
          </Row>}
          {/* Login */}
          {showLogin && <Row className="align-items-center">
            <Col lg={5} className="text-right">
              <Image src={require("assets/images/logos/modal-s-logo.png")} alt="Logo" />
            </Col>
            <Col lg={6} className="ml-md-5">
              <div className="helping-image position-absolute d-none d-md-block">
                <Image src={require("assets/images/modal-helping-plus.png")} alt="Logo" />
              </div>
              <div className="modal-title font-60 b-700">Login</div>
              <p className="my-4">Welcome back to Sharemeister</p>
              <Row>
                <Col lg={10}>
                  <Form>
                    <Form.Group controlId="formGroupEmail">
                      <div className="icon"><Image src={require("assets/images/email.png")} alt="Email" /></div>
                      <Form.Control onChange={(ev) => setLoginEmail(ev.target.value)} type="email" value={loginEmail} placeholder="Email address" />
                    </Form.Group>
                    <Form.Group controlId="formGroupPassword">
                      <div className="icon"><Image src={require("assets/images/password.png")} alt="Password" /></div>
                      <Form.Control onChange={(ev) => setLoginPassword(ev.target.value)} type={inputType} value={loginPassword} placeholder="Password" />
                      <b className="btn ml-auto icon-btn mt-2" onClick={() => setInputType(inputType === "text" ? "password" : "text")}>
                        <ShowIcon mainClass={`sm-show-icon ${inputType === "text" ? "sm-show-icon-active" : ""}`}  />
                      </b>
                    </Form.Group>
                    <Form.Group>
                      <Checkbox
                      placeholder="Remember Me"
                      checkboxId="remember_me"
                      checked={remember_me}
                      name="remember_me"
                      label="Remember Me"
                      handleChange={handleRememberMe}
                      />
                  </Form.Group>
                    <div className="actions d-flex flex-column mt-lg-5">
                    <Button type="submit" className="btn btn-alternate text-uppercase button-shadow font-weight-bold" onClick={login}>
                      {showLoader ? <Spinner animation="border" role="status" /> : 'login'}
                    </Button>
                    <Button onClick={() => {
                      setShowLogin(false);
                      setShowVerify(true);
                    }} className="btn login-btn text-capitalize text-nowrap px-0 b-500 mt-4">Forgot Password</Button>
                    </div>
                  </Form>
                </Col>
              </Row>
              <div className="helping-image-bottom position-absolute d-none d-md-block">
                <Image src={require("assets/images/modal-helping-plus-lg.png")} alt="Plus" />
              </div>
            </Col>
          </Row>}
          {/* Verify Account */}
          {showVerify && <Row className="align-items-center">
            <Col lg={5} className="text-right">
              <Image src={require("assets/images/logos/modal-s-logo.png")} alt="Logo" />
            </Col>
            <Col lg={6} className="ml-md-5">
              <div className="helping-image position-absolute d-none d-md-block">
                <Image src={require("assets/images/modal-helping-plus.png")} alt="Logo" />
              </div>
              <div className="modal-title font-60 b-700">Verify Account</div>
              <p className="my-4">Your 4 digit code was sent to {registerEmail || loginEmail}.</p>
              <p className="">By verifying your code you consent to receiving notifications from Sharemeister.</p>
              <Row>
                <Col lg={10}>
                  <Form>
                    <Form.Group controlId="formGroupPassword">
                      <div className="icon"><Image src={require("assets/images/password.png")} alt="Password" /></div>
                      <Form.Control type={inputType} onChange={(ev) => setOtp(ev.target.value)} placeholder="- - - -" maxLength="4" />
                      <b className="btn ml-auto icon-btn mt-2" onClick={() => setInputType(inputType === "text" ? "password" : "text")}>
                        <ShowIcon mainClass={`sm-show-icon ${inputType === "text" ? "sm-show-icon-active" : ""}`}  />
                      </b>
                    </Form.Group>
                  </Form>
                  <div className="actions d-flex flex-column">
                    <Button
                      className="btn btn-alternate text-uppercase button-shadow mt-4"
                      onClick={handleOtpVerification}>
                      Continue
                  </Button>
                    <Button className="btn login-btn text-capitalize text-nowrap px-0">Resend</Button>
                  </div>
                </Col>
              </Row>
              <div className="helping-image-bottom position-absolute d-none d-md-block">
                <Image src={require("assets/images/modal-helping-plus-lg.png")} alt="Pluse" />
              </div>
            </Col>
          </Row>}
        </Modal.Body>
      </Modal>
      {
        smPlaying && <FullScreenDialog
          childern={paintSmVideo()}
          isOpen={smPlaying}
          handleClose={() => setSmPlaying(false)}
        />
      }
      {
        gilPlaying && <FullScreenDialog
          childern={paintGilVideo()}
          isOpen={gilPlaying}
          handleClose={() => setGilPlaying(false)}
        />
      }
    </React.Fragment>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog({ childern, isOpen, handleClose }) {
  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
        {childern}
      </Dialog>
    </div>
  );
}
