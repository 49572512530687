import React, { useState } from 'react';
import { GiftIcon, ChevronUpIcon, CloseIcon, TickSuccess } from 'shared/icons/icons';
import { InputField, Checkbox } from 'shared/components/form_elements';
import { Form, Button, Spinner } from 'react-bootstrap';
import { Utility } from 'utils';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { showLoader } from 'shared/actions';
import { NoRecordFound } from 'shared/components/no_record_found';
import Dropdown from 'react-bootstrap/Dropdown';
import { ChevronDownIcon } from 'shared/icons/icons';
import { CLIENT_PAYMENT_DETAILS } from 'config/constants/api_endpoints';
import DropdownInside from './dropdown_inside';
import StripeCardElement from './stripe_card_elements';
import moment from 'moment-timezone';

const SendMoney = (props) => {
  const creator = {
    id: props.campaignCreator.creator_id,
    user_name: props.campaignCreator.creator_name,
    profile_image_url: props.campaignCreator.creator_profile_image_url,
    updated_at: props.campaignCreator.updated_at,
    city: props.campaignCreator.creator_city,
    state: props.campaignCreator.creator_state,
  };
  const nonProfits = [creator, ...props.nonProfits];
  const [selectedMember, setSelectedMember] = useState(creator);
  const [showLoader, setShowLoader] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [amount, setAmount] = useState('');
  const [fullName, setFullName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [isCardSaved, setIsCardSaved] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentSuccessDetails, setPaymentSuccessDetails] = useState({});

  const handleSelectedMember = (arg) => {
    const member = { ...selectedMember };
    member.id = arg.id;
    member.user_name = arg.user_name;
    member.profile_image_url = arg.profile_image_url;
    member.updated_at = arg.updated_at;
    member.city = arg.city;
    member.state = arg.state;

    setShowDropdown(false);
    setSelectedMember(member);
  };

  const generateDateAndTime = () => {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const today = moment().tz(clientTimezone).format();
    return {
      date: moment(today).format('ll'),
      time: moment(today).format("hh:mm:ss A"),
      zone: moment.tz(today, clientTimezone).format('z'),
    };
  };

  const buildSuccessMessage = (paymentDetails, transactionId) => {
    const dateTime = generateDateAndTime();
    return{
      date: dateTime.date.toString()
            + ' | '
            + dateTime.time.toString()
            + ' '
            + dateTime.zone.toString(),
      transaction: transactionId,
      recipient: selectedMember.user_name,
      amount: paymentDetails.amount,
      card: paymentDetails.token.card.brand.toUpperCase() + ' ending in xxxx-' + paymentDetails.token.card.last4,
      sender: props.userData.user_name,
    };
  };

  const handleDropdownClick = (isOpen) => {
    setShowDropdown(isOpen);
  };

  const paymentDetailAPI = (result) => {
    const userData = props.userData;
    const amount = document.getElementsByName("amount")[0].value;
    const isCardSaved = document.getElementById('isCardSaved').checked;

    const paymentDetails = {
      isCardSaved,
      amount: parseFloat(amount.split('$')[1]),
      is_active: true,
      token: result.token,
      user_id: userData.user_id,
      user_email: userData.email,
      client_id: userData.client_id,
      sender_user_client_id: userData.id,
      receiver_user_client_id: selectedMember.id,
      campaign_id: props.campaignCreator.campaign_id,
    };

    Utility.sendRequest(CLIENT_PAYMENT_DETAILS, 2, paymentDetails, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        setShowLoader(false);
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setPaymentSuccessDetails(buildSuccessMessage(paymentDetails, body.id));
        setShowLoader(false);
        setPaymentSuccess(true);
      }
    });
  };

  const handleSubmit = (stripe, cardNumber) => {
    const amount = document.getElementsByName("amount")[0].value;

    if (Utility.isEmpty(amount)) {
      const errorElement = document.getElementById('amountError');
      if (errorElement) errorElement.textContent = "Amount cannot be empty.";
    } else {
      document.getElementById('amountError').textContent = "";
      setShowLoader(true);
      stripe.createToken(cardNumber).then(function(result) {
        if (result.error) {
          const errorElement = document.getElementById('card-errors');
          if (errorElement) errorElement.textContent = result.error.message;
          setShowLoader(false);
        } else {
          paymentDetailAPI(result);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
    }
  };

  const handleAmount = (ev) => {
    var theEvent = ev || window.event;

    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
    // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }

    var regex = new RegExp(/^\d*\.?\d*$/);

    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const paintPaymentSuccess = () => (
    <div className="article-page-side h-100" >
      <div className="article-page-side-head py-3 px-4 align-items-center border-bottom" >
        <GiftIcon fillClass="fill-less-black" />
        <span className="display-8 font-bold ml-2" >Money sent!</span>
        <ChevronUpIcon
          handleClick={() => props.switchRightSection('none')}
          mainClass="chevron-up-icon"
        />
        {props.loadedFrom === 'campaignModule' &&
          <CloseIcon
            mainClass="ml-auto cursor-pointer"
            handleClick={() => setPaymentSuccess(false)}
          />
        }
      </div>
      <div className="article-page-side-body py-3 px-4" >
        <div className="w-100 align-items-center border-bottom border-top-sm pt-4 pt-lg-3 flex-nowrap pb-4 mb-4" >
          <TickSuccess />
          <div className="lh-1 ml-3 mx-2" >
            <div className="font-bold display-7 mb-2 text-truncate" >
              Thank you!
            </div>
            <div className="font-medium text-truncate">
              Money been sent to <b className="text-primary font-bold">{Utility.capitalize(paymentSuccessDetails.recipient)}</b>
            </div>
          </div>
        </div>
        <h3 className="display-8 font-bold mb-3" >Transactions details</h3>
        <ul className="list-unstyled font-14 ml-4 mb-0"  >
          <li className="font-medium mb-2 pb-1" >Date: {paymentSuccessDetails.date}</li>
          <li className="font-medium mb-2 pb-1" >Transaction #: {paymentSuccessDetails.transaction}</li>
          <li className="font-medium mb-2 pb-1" >Recipient: <b className="text-primary font-bold">{Utility.capitalize(paymentSuccessDetails.recipient)}</b></li>
          <li className="font-medium mb-2 pb-1" >Amount: ${parseFloat(paymentSuccessDetails.amount).toFixed(2)}</li>
          <li className="font-medium mb-2 pb-1" >Card: {paymentSuccessDetails.card}</li>
          <li className="font-medium mb-2 pb-1" >Sender: {Utility.capitalize(paymentSuccessDetails.sender)}</li>
        </ul>
        <hr className="mt-5 mt-4"  />
        <p className="font-medium font-14 pt-2 mb-5" >
          A receipt has been sent to your email on file and may be found in your Recent Activity of your Passport Profile.
        </p>
        <div className="text-center" >
          <button
            className="btn btn-primary btn-primary-pwa font-14 mt-5 px-4"
            onClick={() => {
              setPaymentSuccess(false);
              setAmount('');
              props.getCampaignDetails();
            }}>
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );

  const paintPaymentForm = () => (
    <div className="article-page-side h-100" >
      <div className="article-page-side-head py-3 px-4 align-items-center border-bottom" >
        <GiftIcon fillClass="fill-less-black" />
        <span className="display-8 font-bold ml-2" >Send money to</span>
        <ChevronUpIcon
          handleClick={() => props.switchRightSection('none')}
          mainClass="chevron-up-icon"
        />
        {props.loadedFrom === 'campaignModule' &&
          <CloseIcon
            mainClass="ml-auto cursor-pointer"
            handleClick={() => props.switchRightSection('none')}
          />
        }
      </div>
      <div className="article-page-side-body px-4 pt-0 pb-2" >
        <div className="align-items-center justify-content-between border-bottom py-3" >
          <Dropdown
            className="article-dropdown form-control h-auto py-0 border-0" onToggle={handleDropdownClick}
            show={showDropdown}>
            <Dropdown.Toggle
              variant="none"
              className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100"
              id="dropdown-article">
              <DropdownInside
                userName={selectedMember.user_name}
                imageUrl={selectedMember.profile_image_url}
                updatedAt={selectedMember.updated_at}
                city={selectedMember.city}
                state={selectedMember.state}
              />
              <ChevronDownIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="position-relative border-0 mt-0 pt-0 float-none pb-0" >
            { nonProfits && nonProfits
              .filter((item) => item.id !== selectedMember.id)
              .map(e => {
                return(
                  <DropdownInside
                    key={e.id}
                    userName={e.user_name}
                    imageUrl={e.profile_image_url}
                    updatedAt={e.updated_at}
                    city={e.city}
                    state={e.state}
                    handleClick={() => handleSelectedMember(e)}
                  />
                );
              })
            }
            {
              Utility.isEmpty(props.nonProfits) && <NoRecordFound />
            }
            </Dropdown.Menu>
          </Dropdown>
          </div>
          <Form
            className="shar-form search-form search-form-pwa sidebar-form py-4" id="payment-form" >
            <Form.Group className="mb-4 amount-group" >
              <Form.Label className="font-bold display-8 mb-3" >Enter amount</Form.Label>
              <InputField
                type="text"
                name="amount"
                value={amount ? '$' + amount : ''}
                handleChange={
                  (ev) => setAmount(amount
                    ? ev.target.value.split('$')[1]
                    : ev.target.value)
                  }
                onKeyPress={handleAmount}
                placeholder="$100.00"
              />
            </Form.Group>
            <Form.Group className="mb-0">
              <Form.Label className="font-bold display-8 mb-3" >Enter card details</Form.Label>

              <StripeCardElement
                paymentForm='payment-form'
                setFullName={setFullName}
                setZipCode={setZipCode}
                handleSubmit={handleSubmit}
              />

            </Form.Group>

            <div className="pwa-checkbox d-flex align-items-center ml-2 mt-4" >
              <Form.Group className="custom-control custom-checkbox mb-0" >
                <label className="mb-0">
                  <Checkbox
                    checkboxId="isCardSaved"
                    required={true}
                    checked={isCardSaved}
                    handleChange={(ev) => setIsCardSaved(ev.target.checked)}
                  />
                  <div className={`checkbox-checked ${isCardSaved && 'true'}`} />
                </label>
              </Form.Group>
              <span className="font-medium display-8 ml-3" >Save card to file</span>
              <Button
                disabled={showLoader}
                type="submit"
                variant="primary"
                className={`btn btn-theme ml-auto btn-primary btn-primary-pwa sidebar-btn font-14 text-uppercase ${showLoader && ' text-primary' }`} >
                {showLoader
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {` Sending...`}
                    </>
                  : 'Send'
                }
              </Button>
            </div>
          </Form>
        </div>
    </div>
  );

  return(
    <>
      {
        paymentSuccess ? paintPaymentSuccess() : paintPaymentForm()
      }
    </>
  );
};

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
    userData: state.userData,
  });

export default connect(mapStateToProps, mapActionToProps)(SendMoney);
