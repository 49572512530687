import React, { useState } from "react";
import { GET_CLIENT_LOGO_URL, USER_AUTH, SWITCH_ACCOUNT } from "config/constants/api_endpoints";
import { Utility } from "utils";
import { toast } from "react-toastify";
import cookie from "react-cookies";
import SideCallout from "shared/components/templates/side_callout";
import { Button } from "react-bootstrap";
import { TickSuccess } from "shared/icons/icons";

const UpgradePlanPurchased = (props) => {
  const [logoUrl, setLogoUrl] = useState(localStorage.getItem("logoUrl"));

  const getClientLogo = () => {
    if (props.newUserClientDetails) {
      Utility.sendRequest(
        GET_CLIENT_LOGO_URL,
        1,
        { clientId: props.newUserClientDetails.id },
        (error, response, body) => {
          body = JSON.parse(body);

          if (!body.error) {
            setLogoUrl(body.logo_url);
          }
        }
      );
    }
  };

  const switchAccount = (id) => {
    props.showLoader(true);

    Utility.sendRequest(SWITCH_ACCOUNT, 1, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "public" });
      } else {
        cookie.save("sessionToken", body.token, { path: "/" });

        Utility.sendRequest(USER_AUTH, 1, { id }, (error, response, body) => {
          body = JSON.parse(body);

          props.showLoader(false);

          if (!body.error) {
            props.updateUserData(body);
            props.history.push({
              pathname: "/profile",
              state: { body },
            });
            props.handleClose();
          } else {
            toast.error(body.error.message, { containerId: "public" });
          }
        });
      }
    });
  };

  return (
    <>
    {props.afterPurchase ?
        <>
          <div className="font-16 mb-3 font-weight-bold pt-2">
            You have successfully {props.afterPurchase ? 'upgraded your plan' : 'purchased your student account'}!
          </div>
          <div className="font-18 mb-3 mt-4 pt-2">Transaction Details</div>
          <div className="py-3 px-4 box">
            <p className="mb-2">Date: {props.transactionData.date}</p>
            <p className="mb-2">
              Transaction #: {props.transactionData.transaction}
            </p>
            <p className="mb-2">
              License:{" "}
              <span className="text-primary">
                {props.transactionData.license}
              </span>
            </p>
            <p className="mb-2">Plan: {props.transactionData.plan}</p>
            <p className="mb-2">Amount: ${props.transactionData.amount}</p>
            <p className="mb-2">Card: {props.transactionData.card}</p>
            <p className="mb-2">Sender: {props.transactionData.sender}</p>
          </div>
          <div className="text-center mt-5">
            <Button
              type="submit"
              variant="primary"
              onClick={props.afterPurchase}
              className={`btn btn-primary px-4 btn-ethos-primary position-relative mt-3`}
            >
            CONTINUE
            </Button>
          </div>
        </> :
        <SideCallout
          closeCallOut={props.handleClose}
          position="position-fixed create-campaign-margin"
          calloutHeading="Confirmation"
          calloutHeadingSvg={
            <TickSuccess mainClass="avtar-30 mr-2" fillClass="fill-primary" />
          }
        >
          <div className="font-16 mb-3 font-weight-bold pt-2">
          You have successfully {props.upgradeExistingPlan === true ? 'upgraded your plan' : 'purchased your student account'}!
          </div>
          <div className="font-18 mb-3 mt-4 pt-2">Transaction Details</div>
          <div className="py-3 px-4 box">
            <p className="mb-2">Date: {props.transactionData.date}</p>
            <p className="mb-2">
              Transaction #: {props.transactionData.transaction}
            </p>
            <p className="mb-2">
              License:{" "}
              <span className="text-primary">
                {props.transactionData.license}
              </span>
            </p>
            <p className="mb-2">Plan: {props.transactionData.plan}</p>
            <p className="mb-2">Amount: ${props.transactionData.amount}</p>
            <p className="mb-2">Card: {props.transactionData.card}</p>
            <p className="mb-2">Sender: {props.transactionData.sender}</p>
          </div>
          <div className="text-center mt-5">
            <Button
              type="submit"
              variant="primary"
              onClick={props.gotToProfile}
              className={`btn btn-primary px-4 btn-ethos-primary position-relative mt-3`}
            >
              GO TO PROFILE
            </Button>
          </div>
        </SideCallout>
    }
</>
  );
};

export default UpgradePlanPurchased;
