import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { InputField, Textarea } from 'shared/components/form_elements';
import UploadFile from 'shared/components/file_upload';
import { CircleFilledAddLargeIcon } from 'shared/icons/icons';
import { CLIENT_ID, CRM_MODULES } from 'config/constants/config';
import { CAMPAIGN_TYPES_IDS, GIL_CLIENT_ID } from 'config/constants';
import { Utility } from 'utils';
import { GROUPS, TOPIC_CATEGORIES } from 'config/constants/api_endpoints';
import { DropdownInside } from './dropdown_inside';
import { Dropdown } from 'react-bootstrap';
import { ChevronDownIcon } from 'shared/icons/icons';
import { SingleCampaign } from './single_campaign';
import Success from 'shared/components/successCallouts/success';
import GroupCard from 'shared/components/cards/group_card';
import useForm from "shared/custom_hooks/use_form";
import validate from "utils/validate";
import { toast } from 'react-toastify';
import { showLoader, updateRefreshCampaignAPI, updateCampaignFormOpen, updateChatFriend } from 'shared/actions';
import { useSelector, useDispatch } from 'react-redux';

const validateFields = ['groupName', 'groupDescription'];

export default function GroupForm(props) {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData);
  const chatFriend = useSelector(state => state.chatFriend);
  const isCampaignFormOpen = useSelector(state => state.isCampaignFormOpen);
  const initialGroupFormData = () => {
    return {
      groupName: '',
      imageUrl: '',
      groupDescription: '',
      isPrivate: false,
      // category_id: ''
    };
  };

  const { handleChange, handleSubmit, values, setValues, hideErrors } = useForm(
    initialGroupFormData,
    submit,
    validate,
    validateFields,
  );

  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState('');
  const [search, setSearch] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [groupRes, setGroupRes] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [category_name, setCategoryName] = useState('Select Category');
  const [category_id, setCategoryId] = useState('');
  const [categoryIdError, setCategoryIdError] = useState('');
  const [updatedImageUrl, setUpdatedImageUrl] = useState('');

  const [showDropdown, setShowDropdown] = useState(false);
  let debouncedFn = null;

  useEffect(() => {
    getGroups();
    getAllCategories();
  }, [search]);

  useEffect(() => {
    if (props.selectedGroupId) {
      handleEditClick(props.selectedGroupId);
    }
    if (chatFriend && chatFriend.group_id) {
      handleEditClick(chatFriend.group_id);
    }
  }, [groupList, categoriesList]);


  const setImage = (inputId, previewURL, file) => {
    setImageUrl(previewURL);
    setImageFile(file);
    document.getElementById('groupImageUrlError').innerText = '';
  };

  const removeImage = (inputId) => {
    if (inputId === 'image_url') {
      handleChange({
        target: {
          id: 'imageUrl',
          name: 'imageUrl',
          value: ''
        }
      });
      setImageUrl('');
      setImageFile('');
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCreateClick = () => {
    setIsCreate(true);
    setIsEdit(false);
    setShowDropdown(false);
    props.markGroupCreate(true);
    handleChange({
      target: {
        id: 'groupName',
        name: 'groupName',
        value: search
      }
    });
  };

  const handleEditClick = (id) => {
    setIsCreate(false);
    setCategoryName('Select Category');
    setIsEdit(true);
    setShowDropdown(false);
    if (!props.selectedGroupId) {
      props.markGroupCreate(false);
    }
    hideErrors();
    hideImageError();
    groupList.map(e => {
      if (e.id === id) {
        setValues({
          groupName: e.name,
          imageUrl: e.image_url,
          groupDescription: e.description,
          isPrivate: e.is_private,
        });
        if (e.category_id) {
          setCategoryId(e.category_id);
          const categoryName = categoriesList.filter(data => data.id === e.category_id);
          if (!Utility.isEmpty(categoryName))
            setCategoryName(categoryName[0].name);
        }
        else
          setCategoryName('Select Category');
        setImageUrl(e.image_url);
        setSelectedGroupId(e.id);
      }
    });
  };

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        if (!Utility.isEmpty(searchString)) {
          setSearch(searchString);
          setValues(initialGroupFormData());
          setCategoryId('');
          // setImageUrl('');
        } else if (Utility.isEmpty(groupList)) {
          setSearch('');
        }
      }, 200);
    }
    debouncedFn();
  };

  const uploadFiles = async (id, imageSignedURL) => {
    let imageURL = imageUrl;

    if (!Utility.isEmpty(imageFile.size)) {
      imageURL = await Utility.uploadFile(imageFile, imageSignedURL);
    }
    setUpdatedImageUrl(imageURL);
    return updateCampaignMediaUrl(id, imageURL);
  };

  const getGroups = () => {
    const params = {
      clientId: CLIENT_ID,
      search,
    };
    if(chatFriend && !chatFriend.group_id) {
     params.userClientId = userData.id;
    }

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setGroupList(body);
      }
    });
  };

  const updateCampaignMediaUrl = (groupId, imageURL) => {
    const params = {
      imageUrl: imageURL,
    };

    Utility.sendRequest(GROUPS + '/' + groupId, 3, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setGroupRes(body.data);
        if(chatFriend.group_id) {
        dispatch(updateChatFriend({
          ...chatFriend,
          name: values.groupName,
          image_url: imageURL || values.imageUrl,
          description: values.groupDescription,
          is_private: values.isPrivate
        }
        ));
        dispatch(updateCampaignFormOpen(false));
      }
        dispatch(updateRefreshCampaignAPI(Math.random()));
      }
    });
  };

  const createGroup = () => {
    const payload = {
      ...values,
      category_id: category_id,
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };
    setLoading(true);
    Utility.sendRequest(GROUPS, 2, payload, async (err, res, body) => {
      body = JSON.parse(body);

      dispatch(showLoader(true));
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
        setIsSuccess(false);
        dispatch(showLoader(false));
        setLoading(false);
      } else {
        await uploadFiles(body.data.id, body.data.imageSignedURL);
        setIsSuccess(true);
        setSearch('');
        setValues(initialGroupFormData());
        dispatch(showLoader(false));
        setLoading(false);
      }
    });
  };

  const updateGroup = () => {
    setLoading(true);
    const payload = {
      ...values,
      category_id: category_id,
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };
    Utility.sendRequest(GROUPS + '/' + selectedGroupId, 3, payload, async (err, res, body) => {
      body = JSON.parse(body);

      dispatch(showLoader(true));
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
        setIsSuccess(false);
        dispatch(showLoader(false));
        setLoading(false);
      } else {
        await uploadFiles(body.data.id, body.data.imageSignedURL);
        getGroups();
        // if(!props.selectedGroupId) {
        setIsSuccess(true);
        //}
        dispatch(showLoader(false));
        setLoading(false);
        props.doRefresh && props.doRefresh();
      }
    });
  };

  const selectedGroupLogic = () => {
    if (isEdit) {
      return (
        <SingleCampaign
          heading={values.groupName}
          imageUrl={(imageUrl || values.imageUrl)}
        />
      );
    }
    else if (isCreate) {
      return (
        <SingleCampaign
          heading={search}
          imageUrl={imageUrl}
        />
      );
    }
    else {
      return 'Create a new Group';
    }
  };

  const closeCallOut = () => {
    if (props.selectedGroupId) {
      props.handleIsFormOpen();
    }
    else {
      setImageUrl('');
      setIsEdit(false);
      setIsCreate(false);
      setIsSuccess(false);
      setValues(initialGroupFormData());
      props.doRefresh && props.doRefresh();
    }
    dispatch(updateCampaignFormOpen(false));
  };

  const handleAddClick = () => {
    setImageUrl('');
    setIsEdit(false);
    setIsCreate(false);
    setIsSuccess(false);
    setValues(initialGroupFormData());
    props.doRefresh && props.doRefresh();
  };

  const handleSuccessEditClick = () => {
    setIsSuccess(false);
  };

  // const checkImageValidity = () => {
  //   const imageNode = document.getElementById('groupImageUrlError');
  //   if (Utility.isEmpty(imageUrl) && imageNode) {
  //     imageNode.innerText = 'Group image is required';
  //     return false;
  //   }
  //   return true;
  // };

  const hideImageError = () => {
    const imageNode = document.getElementById('groupImageUrlError');
    if (imageNode)
      imageNode.innerText = '';
  };

  const checkValidityAndSubmit = (ev) => {
    ev.preventDefault();
     handleSubmit(ev);
  };

  function submit() {
    if (category_name === '') {
      setCategoryIdError('category is required');
      return;
    }
    return isEdit ? updateGroup() : createGroup();
  }

  const groupSuccessView = () => (
    <Success
      innerPosition="position-inherit"
      closeCallOut={() => closeCallOut()}
      calloutHeading='Confirmation'
      imageUrl={!Utility.isEmpty(userData.city_logo_url)
        ? userData.city_logo_url
        : require("assets/images/users/user_no_image.png")}
      forGroup={true}
      createdOrUpdated={isCreate ? ' created ' : ' updated '}
      successOf='group'
      link={`/groups/${groupRes.id}`}
      handleAddClick={handleAddClick}
      handleEditClick={handleSuccessEditClick}
      groupList={groupList}
      card={
        <GroupCard
          link={`/groups/${groupRes.id}`}
          id={groupRes.id}
          imageUrl={groupRes.image_url}
          name={groupRes.name}
          description={groupRes.description}
          goBackLink={props.selectedGroupId ? '/profile' : '/home'}
        />
      }
    />
  );

  const getAllCategories = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
      fromHomePage: true
    };

    Utility.sendRequest(TOPIC_CATEGORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        setCategoriesList(body.data);
      }
    });
  };
  const handleSelectCategory = (e) => {
    setCategoryIdError('');
    const categoryName = categoriesList.filter(data => data.id === e);
    setCategoryName(categoryName[0].name);
    setCategoryId(e);
  };

  return (
    <>
      <Form
        name='group'
        className="shar-form search-form search-form-pwa sidebar-form">
        {(!props.selectedGroupId && Utility.isEmpty(chatFriend)) ?
          <Form.Group className="mb-4 pb-1" >
            <Form.Label className="font-bold display-8 mb-3" >
              Create or search a Group</Form.Label>
            <Dropdown className="article-dropdown form-control h-auto py-0" show={showDropdown}>
              <Dropdown.Toggle
                variant="none"
                className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100"
                id="dropdown-article"
                onClick={toggleDropdown}>
                {selectedGroupLogic()}
                <ChevronDownIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none" >
                <div className="search-inside-dropdown shar-form">
                  <InputField
                    type="text"
                    defaultValue={values.groupName}
                    placeholder='Search for a Group...'
                    handleChange={(ev) => handleSearch(ev)}
                    onBlur={toggleDropdown}
                  />
                </div>
                <DropdownInside
                  isSearch={search}
                  name={search}
                  imageUrl={imageUrl || values.imageUrl}
                  dataList={groupList}
                  groupFormData={values}
                  handleEditClick={handleEditClick}
                  handleCreateClick={handleCreateClick}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          :
          <Form.Label className="font-bold display-8 mb-3" >
            Edit Group</Form.Label>
        }

        <Form.Group className="mb-4 pb-1">
          <Form.Label className="font-bold display-8 mb-3">
            What is the name of your Group? <span className="text-red">*</span>
          </Form.Label>
          <InputField
            type="text"
            name="groupName"
            id="groupName"
            value={values.groupName}
            handleChange={handleChange}
            placeholder="Enter Group Name..."
          />
        </Form.Group>

        {
          categoriesList.length > 0 &&
          <>
            <Form.Group className="mb-4 pb-1" >
              <Form.Label className="font-bold display-8 mb-3" >Select Category</Form.Label>
              <Dropdown onSelect={handleSelectCategory} name="category_id"
                className="article-dropdown form-control h-auto py-0">
                <Dropdown.Toggle
                  variant="none"
                  className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100"
                  id="dropdown-article">
                  {category_name}
                  <ChevronDownIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none" >
                  {categoriesList.map((item, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={item.id}>{item.name}</Dropdown.Item>
                    );
                  })
                  }

                </Dropdown.Menu>
              </Dropdown>
              <div className="error errorIcon">{categoryIdError}</div>
            </Form.Group>
          </>
        }
        <Form.Group className="mb-4 pb-1 profile-image-uploader-outer" >
          <Form.Label className="font-bold display-8 mb-3">Add an Image <span className="text-red">*</span></Form.Label>
          <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
            <label className="post-uplodad-label mb-0 cursor-pointer">
              <UploadFile
                name='image_url'
                inputId="image_url"
                removeText="Remove image"
                categoryName="publisher_campaign_image"
                acceptTypes="image/x-png,image/gif,image/jpeg"
                uploadedFilePreviewUrl={imageUrl}
                handleFileChange={setImage}
                removeUploadedFile={removeImage}
              />
              <div className="error errorIcon text-left pl-2 mb-3" id={'groupImageUrlError'} />
              <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
            </label>
          </div>
          <div className="d-none font-weight-bold show-in-pwa text-center display-8 mt-2 upload-text" >Upload an Image</div>
        </Form.Group>
        <Form.Group className="mb-4 pb-1" >
          <Form.Label className="font-bold display-8 mb-3">
            Add a Group Description <span className="text-red">*</span>
          </Form.Label>
          <Textarea
            required={true}
            type="text"
            id="groupDescription"
            name="groupDescription"
            placeholder="Enter text..."
            value={values.groupDescription}
            handleChange={handleChange}
          />
        </Form.Group>
        <div className="toggle-switch-container toggle-switch-pwa align-items-center" >
          <div className="toggle-switch toggle-switch-sm align-items-center">
            <input
              type="checkbox"
              name="isPrivate"
              id="isPrivate"
              checked={values.isPrivate}
              onChange={handleChange}
            />
            <label htmlFor="isPrivate" className="d-inline-flex mb-0">
              <span className="toggle-track" />
            </label>
          </div>
          <span className="display-7 font-italic ml-3 text-color" >Private</span>
        </div>
        <div className="d-flex mt-2 pt-3">
          <div className="mr-auto d-inline-block text-center" >
            <Button
              disabled={loading}
              type="submit"
              onClick={checkValidityAndSubmit}
              variant="primary"
              className={`btn btn-primary btn-primary-pwa btn-post-size font-14 my-4`} >
              {isEdit ? 'UPDATE ' : 'CREATE '}
              {loading &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              }
            </Button>
            <div
              className="display-8 font-medium-italic cursor-pointer"
              onClick={props.handleIsFormOpen}>
              {CLIENT_ID === GIL_CLIENT_ID ? "Close" : "Cancel"}
            </div>
          </div>
        </div>
      </Form>

      { isSuccess && Utility.isEmpty(chatFriend) && groupSuccessView()}
    </>
  );
}