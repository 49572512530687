import React, { useEffect } from 'react';
import Articles from './articles';
import { useSelector } from 'react-redux';
import Groups from './groups';
import Events from './events';
import Communities from './communities';
import { ChevronUpIcon } from 'shared/icons/icons';
import { ROLE_IDS } from 'config/constants';
import { GIL_CLIENT_ID } from 'config/constants';
import * as Utility from 'utils/utility';
import articles from './articles';
import { CLIENT_ID } from "config/constants/config";

export default function SeeAll(props) {

  useEffect(() => {
    if (Utility.pwaModeEnabled()) {
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').scroll = "no";
    }

    return () => {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').scroll = "yes";
    };
  });

  const userData = useSelector(state => state.userData);
  const  isCitizenSidebarOpen= useSelector(state => state.isCitizenSidebarOpen);
  return (
    <>
      <div
        className={ `article-page-side-outer scroll-y overflow-auto full-sidebar see-all-callout
        ${userData.role_id === ROLE_IDS.USER ? 'see-all-sidebar' : ''}`}
        style={CLIENT_ID === GIL_CLIENT_ID && userData.role_id === ROLE_IDS.USER ? (isCitizenSidebarOpen ? { width: '75%' } : { width: '96%' }) : { width: props.width }}
      >
        <div className="article-page-side h-100" >
          <div className="article-page-side-head py-3 px-4 align-items-center" >
            {/* <span className="display-8 font-bold ml-2" >See All Heading</span> */}
            <ChevronUpIcon
              handleClick={() => props.closeCallout(null)}
              mainClass="chevron-up-icon"
            />
          </div>
          {props.calledFrom === 'article' &&
            <Articles type='suggestedReads' heading='Suggested Articles' closeCallout={() => props.closeCallout(null)} userId={props.selectedUserId} />
          }
          {(props.calledFrom === 'category_articles' || props.calledFrom === 'category_events' || props.calledFrom === 'category_groups' ||
           props.calledFrom === 'category_courses' || props.calledFrom === 'category_experts') &&
            <Articles type={props.calledFrom} category_name={props.category_name} heading='Articles' closeCallout={() => props.closeCallout(null)} userId={props.selectedUserId} />
          }
          {props.calledFrom === 'courses' &&
            <Articles type='courses' heading={props.heading ? props.heading : "Courses"} category_id={props.category_id} closeCallout={() => props.closeCallout(null)} />
          }
          {props.calledFrom === 'profileMyArticle' &&
            <Articles type='myArticles' heading='My Articles' closeCallout={() => props.closeCallout(null)} userId={props.selectedUserId} />
          }
          {props.calledFrom === 'groupArticle' &&
            <Articles type='groupArticles' groupId={props.groupId} closeCallout={() => props.closeCallout(null)} heading='Group Articles' />
          }
          {props.calledFrom === 'community' &&
            <Communities heading={Utility.pwaModeEnabled() ? 'Community Members' : 'Community'} closeCallout={() => props.closeCallout(null)} />
          }
          {props.calledFrom === 'profileGroups' &&
            <Groups type='profileGroups' heading='My Groups' closeCallout={() => props.closeCallout(null)} />
          }
          {props.calledFrom === 'publicGroups' &&
            <Groups type='publicGroups' heading='My Groups' closeCallout={() => props.closeCallout(null)} />
          }
          {props.calledFrom === 'groupEvent' &&
            <Events type="groupEvent" groupId={props.groupId} heading='Group Events' closeCallout={() => props.closeCallout(null)} />
          }
          {props.calledFrom === 'myEvents' &&
            <Events type="myEvents" groupId={props.groupId} heading='My Events' closeCallout={() => props.closeCallout(null)} />
          }
        </div>
      </div>

    </>
  );
}
