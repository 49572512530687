import React, { Component } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Form, Spinner } from 'react-bootstrap';
import { BackButton, Skip, Image } from 'shared/components/pwa';

export class PhoneNo extends Component {
  render() {
    return(
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column">
        <div className="d-flex">
          <BackButton action={this.props.goBack}/>
          <Image />
        </div>
        <h1 className="font-20-bold text-center my-5 ">What is your Mobile Number?</h1>
        <Form className="pwa-form" >
          <Form.Group className="position-relative mobile-input" controlId="formBasicNumber">
            <PhoneInput
              id="phone_cellular"
              placeholder="Mobile Number"
              className="res-font-size-3-5"
              value={this.props.phone_cellular}
              defaultCountry="US"
              onChange={phone => this.props.setValue(phone)}
            />
            <div className="error errorIcon" id="country_code_error"/>
            <div className="error errorIcon" id="phone_cellular_error"/>
          </Form.Group>
          <div className="text-center mt-auto">
            <p className="display-9 mb-5">By providing your phone number and signing up you agree to the Terms of Service, Privacy Policy and confirm you are at least 16 years old.</p>
            <button
              className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
              disabled={this.props.showLoader}
              onClick={(e) => this.props.nextPage(e, 'phone_cellular')}
            >
            {
              this.props.showLoader
              ? <Spinner animation="border" role="status"/>
              : 'CONTINUE'
            }
            </button>
            <Skip skip={this.props.skip} name={'SKIP FOR NOW'} />
          </div>
        </Form>
      </div>
    );
  }
}
