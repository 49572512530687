import React, { useState, useEffect, useRef } from 'react';
import {
  CLIENT_PAYMENT_DETAILS,
  GET_TAX_RATE,
  LICENSE,
} from 'config/constants/api_endpoints';
import { DEFAULT_TAX_PERCENTAGE } from 'config/constants';
import { Dropdown, Button, Spinner, Form } from 'react-bootstrap';
import { Utility } from 'utils';
import { toast } from 'react-toastify';
import { CLIENT_ID, PLAN } from 'config/constants/config';
import StripeCardElement from 'shared/components/v2/campaign/components/stripe_card_elements';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { updatePlanUpgraded } from 'shared/actions';
import { CloseIcon, ChevronDownIcon } from 'shared/icons/icons';

const AccessPass = props => {
  const dispatch = useDispatch();

  const [fullName, setFullName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [licenses, setlicenses] = useState([]);
  const [selectedLicense, setselectedLicense] = useState([]);
  const license = useRef({});
  const [amount, setAmount] = useState(0);
  const [cardType, setCardType] = useState('CARD TYPE');
  const [accept_terms_error, setError] = useState('');

  const getLicenses = () => {
    const params = { clientId: CLIENT_ID };

    Utility.sendRequest(LICENSE, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setlicenses(body);
        setselectedLicense((body && body[0]) || {});
        license.current = (body && body[0]) || {};
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  };

  const getTaxAmount = (baseFee, zipCode) => {
    Utility.sendRequest(
      GET_TAX_RATE,
      1,
      { zip_code: zipCode },
      (error, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          let amount =
            body && body.rate && body.rate.state_rate != '0.0'
              ? body.rate.state_rate
              : DEFAULT_TAX_PERCENTAGE;
          amount = baseFee + (baseFee * amount) / 100;
          localStorage.setItem('taxAmount', amount);
          setAmount(amount);
        } else {
          const defaultAmount =
            baseFee + (baseFee * DEFAULT_TAX_PERCENTAGE) / 100;
          localStorage.setItem('taxAmount', defaultAmount);
          setAmount(defaultAmount);
        }
      }
    );
  };

  useEffect(() => {
    getLicenses();
  }, []);

  const handleSubmit = (stripe, cardNumber) => {
    if (!document.getElementById('access-pass-agreement').checked) {
      setError('Please accept Terms of Services');
      setShowLoader(false);
      return;
    }
    setShowLoader(true);
    stripe
      .createToken(cardNumber)
      .then(function (result) {
        if (result.error) {
          const errorElement = document.getElementById('card-errors');
          if (errorElement) errorElement.textContent = result.error.message;
        } else {
          paymentDetailAPI(result, license.current);
        }
      })
      .catch(err => {
        setShowLoader(false);
      });
  };

  const paymentDetailAPI = (result, license) => {
    const userData = props.userData;
    const fee = license.base_fee;
    const amount = `$${Utility.paintUptoTwoDecimalPlaces(fee)}`;
    const isCardSaved = document.getElementById('isCardSaved').checked;

    const paymentDetails = {
      isCardSaved,
      amount: parseFloat(amount.split('$')[1]),
      is_active: true,
      token: result.token,
      user_id: props.paywall
        ? userData.user_id
        : userData.UserClients[0].user_id,
      user_email: props.paywall
        ? userData.email
        : userData.UserClients[0].email,
      client_id: props.paywall
        ? userData.client_id
        : userData.UserClients[0].client_id,
      sender_user_client_id: props.paywall
        ? userData.id
        : userData.UserClients[0].id,
      receiver_user_client_id: 'a917e0e1-740e-11ea-adc8-b73a2b77d69d',
    };

    Utility.sendRequest(
      CLIENT_PAYMENT_DETAILS,
      2,
      paymentDetails,
      (error, response, body) => {
        body = JSON.parse(body);

        if (body.error) {
          setShowLoader(false);
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          dispatch(updatePlanUpgraded(true));
          setShowLoader(false);
          !props.paywall && props.skip();
          props.showAfterPurchase &&
            props.showAfterPurchase(
              buildSuccessMessage({ ...paymentDetails }, body.id, license)
            );
        }
      }
    );
  };

  const buildSuccessMessage = (paymentDetails, transactionId, license) => {
    const dateTime = generateDateAndTime();
    return {
      date:
        dateTime.date.toString() +
        ' | ' +
        dateTime.time.toString() +
        ' ' +
        dateTime.zone.toString(),
      transaction: transactionId,
      license: license.name,
      plan: PLAN[license.period],
      amount: paymentDetails.amount,
      card:
        paymentDetails.token.card.brand.toUpperCase() +
        ' ending in xxxx-' +
        paymentDetails.token.card.last4,
      sender:
        props.userData &&
        props.userData.first_name + ' ' + props.userData.last_name,
    };
  };

  const generateDateAndTime = () => {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const today = moment().tz(clientTimezone).format();
    return {
      date: moment(today).format('ll'),
      time: moment(today).format('hh:mm:ss A'),
      zone: moment.tz(today, clientTimezone).format('z'),
    };
  };

  const handleSelect = e => {
    const setLicense =
      licenses && licenses.filter(item => item.period === parseInt(e))[0];
    setselectedLicense(setLicense);
    license.current = setLicense;
  };

  const handleCardType = e => {
    if (e === 'debit') {
      setCardType('DEBIT CARD');
    } else if(e === 'credit') {
      setCardType('CREDIT CARD');
    }
  };

  const handleAgreementCheck = e => {
    if (e.target.checked) {
      setError('');
    }
  };

  return (
    <div className='ahura-onboarding-modal py-4 position-relative'>
      <div onClick={props.close} className='modal-close-outer'>
        <CloseIcon fillClass='fill-white' />
      </div>
      <h1 className='font-36 b-500 mb-3'>Select Access Pass</h1>
      <p className='display-6 mb-30'>
        Gain unlimited access to all Premium learning experiences for you and
        your organization.
      </p>
      <Form id='payment-form' className='card-detail ahura-onboarding-form'>
        <Dropdown className='ahura-onboarding-dropdown'>
          <Dropdown.Toggle variant='none' id='dropdown-basic'>
            Annual
            <div className='ml-auto'>
              <ChevronDownIcon />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey='annual'>Annual</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown onSelect={handleSelect} className='ahura-onboarding-dropdown'>
          <Dropdown.Toggle variant='none' id='dropdown-basic'>
            {selectedLicense &&
              `$${selectedLicense.base_fee && Number(selectedLicense.base_fee).toFixed(2) || 0}
               ${PLAN[selectedLicense.period]} (save 25%)`}
            <div className='ml-auto'>
              <ChevronDownIcon />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {licenses &&
              licenses.map((license, key) => (
                <Dropdown.Item
                  key={key}
                  eventKey={license.period}
                >{`$${license.base_fee && Number(license.base_fee).toFixed(2) || 0} ${
                  PLAN[license.period]
                } (save 25%)`}</Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>

        <div className='my-5 pl-3'>
          <div className='font-60 cooper-font'>
            ${selectedLicense.base_fee && Number(selectedLicense.base_fee).toFixed(2) || 0}
          </div>
          <div className='text-primary cooper-font display-6'>Price</div>
        </div>
        <p className='display-6 mb-4'>Enter Payment Information</p>
        <Dropdown
          onSelect={handleCardType}
          className='ahura-onboarding-dropdown dropdown-card-type'
        >
          <Dropdown.Toggle variant='none' id='dropdown-basic'>
            {cardType}
            <div className='ml-auto'>
              <ChevronDownIcon />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey='type'>CARD TYPE</Dropdown.Item>
            <Dropdown.Item eventKey='debit'>DEBIT CARD</Dropdown.Item>
            <Dropdown.Item eventKey='credit'>CREDIT CARD</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <StripeCardElement
          paymentForm='payment-form'
          setZipCode={setZipCode}
          setFullName={setFullName}
          handleSubmit={handleSubmit}
        />
        <div className='d-flex mt-4'>
          <label className='ahura-checbox cursor-pointer' htmlFor='isCardSaved'>
            <input type='checkbox' id='isCardSaved' name='isCardSaved' />
            <div className='ahura-checbox-inner'>
              <img
                src={require('assets/images/icons/input-check.svg')}
              />
            </div>
          </label>
          <span className='ml-4 display-7'>Save Card Details</span>
        </div>

        <div className='d-flex mt-4 mb-5'>
          <label className='ahura-checbox mb-0 cursor-pointer'>
            <input
              id='access-pass-agreement'
              onClick={handleAgreementCheck}
              type='checkbox'
            />
            <div className='ahura-checbox-inner'>
              <img
                src={require('assets/images/icons/input-check.svg')}
              />
            </div>
          </label>
          <span className='ml-4 display-7'>
            By clicking the “Start Membership” button, you agree to our Terms of
            Use, Privacy Statement, that you are over 18, and that Ahura AI will
            automatically continue your membership and charge the annual
            membership fee to your payment method until you cancel. You may
            cancel at any time to avoid future charges. To cancel, go to Account
            and click “Cancel Membership.”
          </span>
        </div>
        <p className='error ml-5 mt-n3 font-weight-500'>{accept_terms_error}</p>

        <div className='text-center mb-4 pb-3'>
          <Button
            disabled={showLoader}
            type='submit'
            variant='primary'
            className={`btn btn-lg btn-ahura-onboarding-main btn-primary radius-15`}
          >
            {showLoader ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Start Membership'
            )}
          </Button>
        </div>
      </Form>
      {!props.paywall && (
        <div
          onClick={props.skip}
          className='text-center display-7 my-5 cursor-pointer'
        >
          Skip for now &amp; go to Home.
        </div>
      )}
    </div>
  );
};

export default AccessPass;
