import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import MyCommunity from 'shared/components/my_community';
import { Utility } from 'utils';
import { STATUS } from 'config/constants';
import { GET_USERS_BY_NETWORKS, GROUP_MEMBERS } from 'config/constants/api_endpoints';
import { CLIENT_ID, COMMUNITY_CARD_LIMIT } from 'config/constants/config';
import { GET_FOLLOWERS_FOLLOWING } from 'config/constants/api_endpoints';
import { AddBoldSmallIcon } from 'shared/icons/icons';
import { useSelector } from 'react-redux';

const pwaModeEnabled = Utility.pwaModeEnabled();

export default function Members(props){
  const userData = useSelector(state => state.userData);
  const [groupMembers, setGroupMembers] = useState([]);
  const [users, setUsers] = useState([]);
  const [userClientId] = useState(props.userClientId);

  useEffect(() => {
    getGroupMember();
  }, [props.refresh]);

  const getGroupMember = () => {
    const params = {
      groupId: props.groupId,
    };

    Utility.sendRequest(GROUP_MEMBERS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        const selectedIds = body.map(e => e.member_id);
        if(selectedIds.includes(userData.id))
          props.setAlreadyAMember(true);
        setGroupMembers(body);
        getUserDetails(selectedIds);
      }
    });
  };

  const getUserDetails = (selectedIds) => {
    const params = {
      client_id: CLIENT_ID,
      type: 'all',
      forGroupMembers: true,
      selectedIds: !Utility.isEmpty(selectedIds) ? selectedIds : [null],
    };

    if(Utility.isEmpty(selectedIds))
      params.limit = COMMUNITY_CARD_LIMIT;

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.registrants)
          setUsers(body.registrants);
      }
    });
  };

  const setDetailsFollowerfollowing = (followingId) => {
    if(userClientId !== followingId){
      const params = { followerId: userClientId, followingId: followingId, clientId: CLIENT_ID, };

      Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 2, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        }
        else {
          getGroupMember();
        }
      });
    }
  };

  const handleOnClick = (id) => {
    setDetailsFollowerfollowing(id);
  };

  const cardList = () => {
    const arr = [];
    users.map(user => {
      let status;
      groupMembers.map(e => {
        if(e.member_id === user.id)
        status = e.status;
      });
      if(status !== STATUS.declined)
        arr.push(
          <MyCommunity
            key={user.id}
            item={user}
            handleOnClick={handleOnClick}
            status={status}
            showCharFollowButtons={user.id !== userData.id}
          />
        );
    });
    return arr;
  };

  return(
    <div className={`community-section mt-42 slider-mob ${Utility.pwaModeEnabled ? '' : 'mb-42'}`}>
      <h1 className="font-24-bold mb-25 home-page-heading" >
        {(Utility.isEmpty(!users) || !props.isMyGroup()) && 'Group Members'}
          <span
            onClick={() => props.setIsFormOpen(true)}
            className="see-all-text display-7 ml-auto">
            {props.isMyGroup ? '+ Invite' : ''}
          </span>
        { pwaModeEnabled && !props.alreadyAMember &&
          <AddBoldSmallIcon
            mainClass="see-all-text display-7 ml-auto"
            fillClass="fill-primary"
            handleClick={props.isMyGroup ?
              () => props.setIsFormOpen(true) :
              () => props.sendInvite()
            }
          />
        }

      </h1>
        <div className="d-flex flex-lg-wrap community-section-inner" >
          { users && cardList()}
        </div>
    </div>
  );
}
