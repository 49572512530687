import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import { GROUP_CAMPAIGNS } from 'config/constants/api_endpoints';
import { CLIENT_ID, GROUPS_EVENTS_LIMIT } from 'config/constants/config';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';
import EventCard from 'shared/components/cards/event_card';
import { STATUS } from 'config/constants';

export default function Events(props){
  const [events, setEvents] = useState([]);
  const [pageLoad, setPageLoad] = useState(1);

  useEffect(() => {
    getEventsDetails();
  }, []);

  useEffect(() => {
    getEventsDetails();
  }, [pageLoad]);

  const getEventsDetails = () => {
    const params = {
      userClientId: props.userData.id,
      groupId: props.groupId,
      clientId: CLIENT_ID,
      limit: GROUPS_EVENTS_LIMIT,
      campaignTypeId: CAMPAIGN_TYPES_IDS.event,
      eventList: true
    };

    if(!Utility.isEmpty(props.userData)){
      Utility.sendRequest(GROUP_CAMPAIGNS, 1, params, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          if(body.length)
            setEvents(body);
        }
      });
    }
  };

  const paintPublicEvents = () => {
    const arr = [];
    events.length &&
    events.map(e => {
      const displayAddBtn = !(e.calendar_details_status);
      arr.push(
        <EventCard
          {...e}
          key={e.id}
          displayAddBtn={displayAddBtn}
          pageLoad={setPageLoad}
          eventDetailLink={true}
          goBackLink={props.goBackLink}/>
      );
    });
    return arr;
  };

  return(
    events.length ?
    <div className="article-section">
      {
        (props.showHeading && props.seeAll) &&
          <h1 className="font-24-bold mb-25 home-page-heading" >
            {props.showHeading && props.heading}
            {
              (props.seeAll) &&
              <span className="see-all-text display-7" onClick={props.setSeeAll}>See All</span>
            }
          </h1>
        }
      <div className="event-container d-flex flex-wrap" >
        {paintPublicEvents()}
      </div>
    </div> : <></>
  );
}
