import React, { memo, useEffect, useState } from "react";
import { NETWORK_TYPES, NETWORK } from "config/constants/config";
import { Utility, Date } from "utils";
import { PERMISSIONS, ROLE_IDS, AHURA_CLIENT_ID, ETHOS_CLIENT_ID } from "config/constants";
import { Link } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { ChevronDownIcon } from "shared/icons/icons";
import { CLIENT_ID } from "config/constants/config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GIL_CLIENT_ID } from "config/constants";
import Select from "react-select";
import moment from "moment";
let TableRows = (props) => {
  return (
    <>
      <tbody>
        {props.data &&
          props.data.map((item, index) => {
            let networkType = "";

            /* Just to remove rank dropdown in network module */
            if (item.role_id === ROLE_IDS.SUPER_OWNER) {
            }
            else if(props.selectedViewData.active === "NeighborhoodUsers" && CLIENT_ID === AHURA_CLIENT_ID){
              return (
                <NetWorkComponent
                  item={item}
                  index={index}
                  networkType={networkType}
                  payload={props}
                  userData={props.userData}
                />
              );
            }
            
            else if (item.hasOwnProperty("network") && item.network) {
              /* Just to remove rank dropdown in network module */
              let network = item.network;
              network = `${network.charAt(0).toUpperCase()}${network.slice(1)}`;
              networkType = NETWORK[network];
              return (
                <NetWorkComponent
                  item={item}
                  index={index}
                  networkType={networkType}
                  payload={props}
                  userData={props.userData}
                />
              );
            } else {
              if (
                (props.userData.role === "CLIENT_OWNER" ||
                  props.userData.role === "CLIENT_ADMIN") &&
                props.selectedMenuItemName !== "course"
              ) {
                return (
                  <NetWorkComponent
                    item={item}
                    index={index}
                    networkType={"Citizen"}
                    payload={props}
                    userData={props.userData}
                    removeUserFromNeighborhood={
                      props.removeUserFromNeighborhood
                    }
                    alterAdminStatusInNeighborhood={
                      props.alterAdminStatusInNeighborhood
                    }
                  />
                );
              } else {
                return (
                  <CampaignComponent
                    item={item}
                    index={index}
                    payload={props}
                  />
                );
              }
            }
          })}
      </tbody>
    </>
  );
};

const ToggleComponent = (props) => {
  let showToggle = true;
  let isDisabled = true;

  if (
    props.setAdminAfterToggle &&
    props.setAdminAfterToggle.id === props.data.id
  ) {
    props.data.is_admin = props.setAdminAfterToggle.value;
  }

  if (props.data && props.data.network) {
    let networkType = props.data.network;
    networkType = networkType.charAt(0).toUpperCase() + networkType.slice(1);

    showToggle = NETWORK_TYPES[networkType] === NETWORK_TYPES.Users;
  }

  if (props.userData.permissions.includes(PERMISSIONS.ADD_ADMIN))
    isDisabled = false;

  return (
    <div className="align-items-center">
      {showToggle && (
        <div className="toggle-switch toggle-switch-sm ml-2 align-items-center">
          <input
            disabled={isDisabled}
            type="checkbox"
            id={`adminStatus${props.index}`}
            checked={props.data.is_admin ? "checked" : ""}
            onChange={
              props.userData.role === "CLIENT_OWNER" ||
              props.userData.role === "CLIENT_ADMIN" ||
              props.type === "NeighborhoodUsers"
                ? (e) => {
                    props.alterAdminStatusInNeighborhood(
                      props.data.user_id,
                      props.data.is_admin,
                      props.data.client_id
                    );
                  }
                : (e) => props.setToggleState(e.target.checked, props.data)
            }
          />
          <label
            htmlFor={`adminStatus${props.index}`}
            className="d-inline-flex mb-0"
          >
            <span className="toggle-track" />
          </label>
        </div>
      )}
    </div>
  );
};

const ImageComponent = (props) => {
  return (
    <img
      src={
        !Utility.isEmpty(props.image_url)
          ? props.image_url
          : require("assets/images/" + props.default_img)
      }
      alt="registrant-img"
      className="registrant-img"
    />
  );
};

const NetWorkComponent = (props) => {
  const { item, index, payload } = props;
  useEffect(() => {
    setSchoolArray([...getSchoolArr()]);
  }, [item]);

  const userData = useSelector((state) => state.userData);
  const selectedMenuItem = useSelector((state) => state.selectedMenuItem);

  const getSchoolArr = () => {
    // return props.payload && props.payload.subCategoriesData && props.payload.subCategoriesData.filter((school)=>  item.school_id && item.school_id.includes(school.id)).map((school)=>{
    return (
      (props.payload &&
        props.payload.subCategoriesData &&
        props.payload.subCategoriesData
          .filter(
            (school) => item.school_id && item.school_id.includes(school.id)
          )
          .map((school) => {
            return {
              value: school.id,
              label: school.name,
            };
          })) ||
      []
    );
  };
  const [schoolArray, setSchoolArray] = useState([...getSchoolArr()]);
  const [showApplyBtn, setShowApplyBtn] = useState(false);
  
  switch(props.payload.selectedViewData.active){
    case "NeighborhoodUsers":
      return(
        <tr key={item.user_id}>
          <td>{item.user_name || item.heading || item.full_name}</td>
          <td>{item.email}</td>
          <td>{moment(item.date_of_joining).format("MM-DD-YYYY")}</td>
          <td>
            <ToggleComponent
              index={index}
              data={item}
              setToggleState={payload.onToggleClick}
              setAdminAfterToggle={payload.setAdminAfterToggle}
              userData={payload.userData}
              alterAdminStatusInNeighborhood={
                props.payload.alterAdminStatusInNeighborhood
              }
              type="NeighborhoodUsers"
            />
          </td>
          <td>
            <button
              value={item.user_id}
              disabled={item.is_admin}
              onClick={(e) =>{
                payload.removeUserFromNeighborhood(e.target.value, item.external_id, item.client_id)
              }
              }
            >
              Remove
            </button>
          </td>
        </tr>
      )
    default:
  return (
    <tr key={index}>
      <td>
        {CLIENT_ID === AHURA_CLIENT_ID ? (
            (props.payload.selectedMenuItemName === "Neighborhoods")  ?
            <div className="cursor-pointer" onClick={()=> props.payload.handleSetSelectedViewData({active:"NeighborhoodUsers", neighborhood_id: item.client_id})}> 
              { item.user_name || item.heading || item.full_name}
            </div>  
            :
          item.user_name || item.heading || item.full_name
        ) : (
          <Link
            to={{
              pathname: `/profile/${item.id}`,
              state: { otherUserProfile: true },
            }}
          >
            <div className="align-items-center">
              {item.hasOwnProperty("profile_image_url") ||
                (item.hasOwnProperty("image_url") && (
                  <ImageComponent
                    image_url={item.profile_image_url || item.image_url}
                    default_img={"users/user_no_image.png"}
                  />
                ))}
              <span className="font-weight-bold text-dark ml-2 cursor-pointer">
                {item.user_name || item.heading || item.full_name}
              </span>
            </div>
          </Link>
        )}
      </td>
      <td>{props.networkType}</td>
      {CLIENT_ID !== AHURA_CLIENT_ID &&
        CLIENT_ID !== GIL_CLIENT_ID && (
          <td>
            {props.userData &&
            props.userData.role !== "CLIENT_OWNER" &&
            props.userData.role !== "CLIENT_ADMIN"
              ? props.payload.allCategories && (
                  <Dropdown
                    onSelect={(e) => payload.handleSelectCategory(item, e)}
                    name="category_id"
                  >
                    <Dropdown.Toggle
                      variant="table-dropdown"
                      id="dropdown-basic"
                    >
                      {CLIENT_ID === ETHOS_CLIENT_ID
                        ? item.category_name
                          ? item.category_name
                          : "Select category"
                        : item.network_category_name
                        ? item.network_category_name
                        : "Select category"}
                      <ChevronDownIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-height-scroll">
                      {props.payload.allCategories.map((item, index) => {
                        return (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )
              : ""}
          </td>
        )}
      {CLIENT_ID !== GIL_CLIENT_ID &&
        CLIENT_ID !== AHURA_CLIENT_ID && (
          <td>
            {CLIENT_ID === ETHOS_CLIENT_ID
              ? props.userData &&
                props.userData.role !== "CLIENT_OWNER" &&
                props.userData.role !== "CLIENT_ADMIN"
                ? props.payload.subCategoriesData && (
                    <>
                      <div className="d-flex ">
                        <Select
                          options={props.payload.subCategoriesData.map(
                            (item) => {
                              return {
                                value: item.id,
                                label: item.name,
                              };
                            }
                          )}
                          isMulti={
                            item.category_name === "Mentor" ||
                            item.category_name === "Teacher" ||
                            item.category_name === "Coach"
                              ? true
                              : false
                          }
                          value={schoolArray}
                          placeholder="Select Sub category"
                          className="react-select-container-school"
                          classNamePrefix="react-select"
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          onChange={(e) => {
                            setSchoolArray(e);
                            setShowApplyBtn(true);
                          }}
                          // onMenuClose={(e) => payload.handleSelectSubCategory({apiCall:true})}
                        />
                        {showApplyBtn && (
                          <div
                            className="applyChangesBtn cursor-pointer ml-2"
                            onClick={() => {
                              payload.handleSelectSubCategory(
                                item,
                                schoolArray
                              );
                              setShowApplyBtn(false);
                            }}
                          >
                            Apply
                          </div>
                        )}
                      </div>
                    </>

                    // <Dropdown
                    //   onSelect={(e) => payload.handleSelectSubCategory(item, e)}
                    //   name="sub_category_id"
                    // >
                    //   <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                    //     {item.school_name
                    //       ? Utility.splitString(item.school_name, 17)
                    //       : Utility.splitString("Select Sub category", 17)}
                    //     <ChevronDownIcon />
                    //   </Dropdown.Toggle>
                    //   <Dropdown.Menu className="dropdown-height-scroll">
                    //     {props.payload.subCategoriesData.map((item, index) => {
                    //       return (
                    //         <Dropdown.Item key={index} eventKey={item.id}>
                    //           {item.name}
                    //         </Dropdown.Item>
                    //       );
                    //     })}
                    //   </Dropdown.Menu>
                    // </Dropdown>
                  )
                : ""
              : item.sub_category_name + " "}
            {/*<IoIosArrowDown className="table-heading-sort ml-2" />*/}
          </td>
        )}
      {AHURA_CLIENT_ID === CLIENT_ID &&
        ROLE_IDS.CLIENT_OWNER === props.userData.role_id && (
          <>
            <td>{item.study_hours}</td>
            <td>{item.sessions}</td>
            <td>{Array.isArray(item.topics) ? item.topics.join(", ") : "-"}</td>
          </>
        )}
      {payload.selectedMenuItemId === "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec" &&
        CLIENT_ID === GIL_CLIENT_ID &&
        payload.selectedMenuItemName === "Story" && (
          <td>
            {/* {item.ranking} */}
            <div className="d-flex">
              {
                <Dropdown>
                  <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                    {!Utility.isEmpty(item.ranking)
                      ? item.ranking
                      : "Select ranking"}
                    <ChevronDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                      return (
                        <Dropdown.Item
                          key={e}
                          onClick={() => payload.editRanking(item.id, e)}
                        >
                          {e}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
          </td>
        )}
      {payload.selectedMenuItemId === "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf" &&
        CLIENT_ID === GIL_CLIENT_ID &&
        payload.selectedMenuItemName === "event" && (
          <td className="action-td-chat">
            <div className="d-flex">
              {
                <Dropdown>
                  <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                    <span>{"------"}</span>
                    <ChevronDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        payload.editStory(item.id, payload.selectedMenuItemId)
                      }
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        payload.remove(item.id, payload.selectedMenuItemId)
                      }
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
          </td>
        )}

      {props.selectedMenuItemName !== "All Applications" &&
        CLIENT_ID !== GIL_CLIENT_ID && (
          <>
            <td>
              {CLIENT_ID === AHURA_CLIENT_ID &&
              props.userData.role === "PARENT_OWNER" &&
              selectedMenuItem.menu_name === "Neighborhoods" ? (
                item.max_licenses &&
                `${item.user_count} of ${item.max_licenses}`
              ) : (
                <ToggleComponent
                  index={index}
                  data={item}
                  setToggleState={payload.onToggleClick}
                  setAdminAfterToggle={payload.setAdminAfterToggle}
                  userData={payload.userData}
                  alterAdminStatusInNeighborhood={
                    props.alterAdminStatusInNeighborhood
                  }
                />
              )}
            </td>
            <td>
              {/* <div className="table-action-icons" >
              <img
                src={require("assets/images/icons/chat_icon.svg")}
                alt="chat-icon"
                className="mr-2 cursor-pointer"
                onClick={() => props.onActionClick(item)}
              /> */}
              {/*<img src={require("assets/images/icons/passport-icon.png")} alt="passport-icon" />*/}
              {/* </div> */}
              {props.userData.role === "CLIENT_OWNER" ||
              props.userData.role === "CLIENT_ADMIN" ? (
                !item.is_admin && (
                  <button
                    value={item.user_id}
                    onClick={(e) =>
                      props.removeUserFromNeighborhood(e.target.value, item.external_id)
                    }
                  >
                    Remove
                  </button>
                )
              ) : (
                <Dropdown>
                  <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                    <span>
                      {item.is_user_account_active === true
                        ? "Active"
                        : "Inactive"}
                    </span>
                    <ChevronDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        props.payload.activateDeactivateUser(item.user_id, true)
                      }
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        props.payload.activateDeactivateUser(
                          item.user_id,
                          false
                        )
                      }
                    >
                      Inactive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </td>
            
          </>
        )}
    </tr>
  )
                    }
};

const CampaignComponent = (props) => {
  const { item, index, payload } = props;
  return (
    <tr key={index} className="">
      <td>
        <div
          className="align-items-center"
          onClick={() => payload.history.push(`/campaign/${item.id}`)}
        >
          {item.hasOwnProperty("image_url") && (
            <ImageComponent
              image_url={
                item.image_url ? item.image_url : item.embedded_image_link
              }
              default_img={"users/user_no_image.png"}
            />
          )}
          <span className="font-weight-bold text-dark ml-2 cursor-pointer">
            {item.heading}
          </span>
        </div>
      </td>
      <td>
        {item.campaign_category}{" "}
        {/*<IoIosArrowDown className="table-heading-sort ml-2" />*/}
      </td>
      {(payload.selectedMenuItemId === "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf" &&
        CLIENT_ID === ETHOS_CLIENT_ID &&
        payload.selectedMenuItemName === "event") ||
        (payload.selectedMenuItemId ===
          "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec" &&
          CLIENT_ID === GIL_CLIENT_ID &&
          payload.selectedMenuItemName === "Story" && (
            <td>
              {/* {item.ranking} */}
              <div className="d-flex">
                {
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="table-dropdown"
                      id="dropdown-basic"
                    >
                      {!Utility.isEmpty(item.ranking)
                        ? item.ranking
                        : "Select ranking"}
                      <ChevronDownIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                        return (
                          <Dropdown.Item
                            key={e}
                            onClick={() => payload.editRanking(item.id, e)}
                          >
                            {e}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
            </td>
          ))}
      
      {payload.selectedMenuItemId === "39ab8d70-3d6b-4f3f-a702-8ead10a90bcf" &&
        (CLIENT_ID === GIL_CLIENT_ID || CLIENT_ID === AHURA_CLIENT_ID) &&
        payload.selectedMenuItemName === "course" && (
          <td className="action-td-chat">
            <div className="d-flex">
              {
                <Dropdown>
                  <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                    <span>{"------"}</span>
                    <ChevronDownIcon />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        payload.editStory(item.id, payload.selectedMenuItemId);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        if (
                          CLIENT_ID === GIL_CLIENT_ID
                        ) {
                          window.confirm(
                            "Sure You want to delete this Course?"
                          ) &&
                            payload.remove(item.id, payload.selectedMenuItemId);
                        } else {
                          payload.remove(item.id, payload.selectedMenuItemId);
                        }
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
          </td>
        )}
    </tr>
  );
};

export default TableRows = memo(TableRows);
